import { useState } from "react";
import Spinner from "app/views/Web/components/Spinner";
import Paginating from "../../components/Pagination";
import styled from "styled-components";
import useAdminGetClientPackages from "app/hooks/analytics/useAdminGetClientPackages";
import OrderTable from "./grid/OrderTable";

const DisplayOrders = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError } = useAdminGetClientPackages(page, 5);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (isError && !isLoading)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred</NoProjectText>
      </SpinnerWrapper>
    );

  if (data && data.data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>No order available category yet</NoProjectText>
      </SpinnerWrapper>
    );
  return (
    <>
      <Wrapper>
        <OrderTable data={data.data} />
        <Footer>
          <Paginating
            totalElements={data.total}
            size={data.per_page}
            pageCount={3}
            currentPage={page}
            onChange={onPageChange}
          />
        </Footer>
      </Wrapper>
    </>
  );
};

export default DisplayOrders;

const Wrapper = styled.div`
  padding: 34px 37px;
  background: #eef5e0;
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminUpgradeStaff } from "app/api/staff";
import { parseError } from "app/utils";
import { AssessmentKeys } from "./types";

const useAdminUpgradeStaff = () => {
  const queryClient = useQueryClient();

  return useMutation(adminUpgradeStaff, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([AssessmentKeys.adminUpgradeStaff]),
  });
};

export default useAdminUpgradeStaff;

import React from "react";
import { Route } from "react-router-dom";
import { Routes, useLocation } from "react-router";
import Layout from "./Layout/Layout";
import LandingPage from "./LandingPage";
import About from "./About";
import Project from "./Project";
import ProjectCategory from "./Project/projectCategory";
import ScheduleTourPage from "./ScheduleTour";
import AuthLayout from "./Layout/AuthLayout";
import SignUp from "./Auth/SignUp";
import Login from "./Auth/Login";
import ForgetPassword from "./Auth/ForgetPassword";
import PasswordRecovery from "./Auth/PasswordRecovery";
import Assessment from "./Assessment";
import Community from "./Community";
import PostDetails from "./Community/PostDetails";
import TC from "./LandingPage/Legal";
import FAQ from "./LandingPage/FAQ";

const WebRoutes: React.FC = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/project" element={<Project />} />
        <Route path="/project/:categoryId" element={<ProjectCategory />} />
        <Route
          path="/project/:categoryId/package/:id"
          element={<ProjectCategory />}
        />
        <Route path="/schedule-a-tour" element={<ScheduleTourPage />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/community" element={<Community />} />
        <Route
          path={`/community/blogpost/:postTitle`}
          element={<PostDetails />}
        />
        <Route path={`/legal/:policy`} element={<TC />} />
        <Route path={`/FAQs`} element={<FAQ />} />
      </Route>
      <Route path="/" element={<AuthLayout />}>
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="/admin-login" element={<Login isAdminStaff />} />
        <Route
          path="/admin-login/forget-password"
          element={<ForgetPassword isAdminStaff />}
        />
      </Route>
    </Routes>
  );
};

export default WebRoutes;

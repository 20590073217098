import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import {
  CreateBtn,
  ErrorText,
  FormWrapper,
  SpinnerWrapper,
} from "./components/styles";
import { Form, Formik } from "formik";
import InputField from "../components/formik inputs/InputField";
import SelectField from "../components/formik inputs/SelectField";
import { labelValueType } from "app/types/common";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import CoverImage from "./components/CoverImage";
import { CreateBlogValidation } from "./components/validation";
import BodyField from "./components/BodyField";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import debounce from "app/hooks/useDebounce";
import { searchTags } from "app/api/community";
import { parseError } from "app/utils";
import usePostBlogpost from "app/hooks/community/useCreateBlogpost";
import Spinner from "app/views/Web/components/Spinner";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import useGetPostByTitle from "app/hooks/community/useGetPostByTitle";
import useUpdatePost from "app/hooks/community/useUpdatePost";

const CreateBlogPost = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState<labelValueType[]>([]);
  const [bodyState, setBodyState] = useState(EditorState.createEmpty());
  const [coverImageId, setCoverImageId] = useState<number>();
  const [searchParams] = useSearchParams();
  const editTitle = searchParams.get("edit");

  const { mutate, isLoading } = usePostBlogpost();
  const { mutate: updateMutate, isLoading: updateIsLoading } = useUpdatePost();
  const {
    data: postData,
    isFetching: postFetching,
    isError: postError,
  } = useGetPostByTitle(editTitle as string);

  const loadSearchOptionsDebounced = useCallback(
    debounce((inputText: string, callback: (options: any) => void) => {
      seachForTags(inputText).then((options) => callback(options));
    }, 250),
    []
  );

  const seachForTags = async (inputValue: string) => {
    if (!inputValue || inputValue.length < 3) return;
    try {
      const payload = {
        word: inputValue,
      };
      const res = await searchTags(payload);
      return res.data.data.map((ev, i) => {
        const temp = {
          value: ev.name,
          label: ev.name,
        };
        return temp;
      });
    } catch (error) {
      parseError(error);
    }
  };

  const onSuccess = () => {
    toast.success(`Post ${editTitle ? "Updated" : "Created"}  Successfully!`);
    setTimeout(() => {
      navigate(-1);
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!coverImageId) return;
    const payload = {
      title,
      description,
      file_id: coverImageId,
      tags: tags.map((ev) => ev.value).join(","),
      body: draftToHtml(convertToRaw(bodyState.getCurrentContent())),
    };

    if (editTitle) {
      if (!postData) return;
      const result = {
        ...payload,
        post_id: postData.id,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      mutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editTitle && postData) {
      const { title, description, body, tags, cover_photo } = postData;
      setTitle(title);
      setDescription(description);
      const formattedTags = tags.map((tag) => {
        return {
          value: tag.name,
          label: tag.name,
        };
      });
      setTags(formattedTags);
      const blocksFromHtml = htmlToDraft(body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setBodyState(EditorState.createWithContent(contentState));
      setCoverImageId(cover_photo.id);
    }
  }, [postData, editTitle]);

  return (
    <Container>
      <Nav>
        <ArrowBackIcon onClick={() => navigate(-1)} />
        <h5> {editTitle ? "Update" : "Create"} Blogpost</h5>
      </Nav>

      <Formik
        initialValues={{
          title,
          description,
          tags,
          bodyState,
          coverImageId,
        }}
        validationSchema={CreateBlogValidation}
        enableReinitialize
        onSubmit={() => {
          onSubmitHandler();
        }}
      >
        <Wrapper>
          {editTitle && postFetching && (
            <SpinnerWrapper>
              <Spinner color="black" />
            </SpinnerWrapper>
          )}
          {editTitle && postError && (
            <SpinnerWrapper>
              <ErrorText>An Error Occured</ErrorText>
            </SpinnerWrapper>
          )}
          {(!editTitle || (editTitle && !postError && !postFetching)) && (
            <Form>
              <InputField
                name="title"
                label="Post Title"
                placeholder="Type event title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                isRequired
              />
              <InputField
                name="description"
                label="Description"
                placeholder="Type event description"
                inputType="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                isRequired
              />
              <BodyField
                name="bodyState"
                label="Body"
                value={bodyState}
                onChange={setBodyState}
                isRequired
              />
              <TagWrapper>
                <SelectField
                  name="tags"
                  label="Tags"
                  isMulti
                  value={tags as labelValueType[]}
                  onChange={(e) => setTags(e as labelValueType[])}
                  fetchOptions={loadSearchOptionsDebounced}
                  selectType="asyncCreatable"
                  isRequired
                  height={"unset"}
                />
                <span>Press enter to add tag</span>
              </TagWrapper>

              <CoverImage
                setStateFn={setCoverImageId}
                value={
                  editTitle && postData ? postData?.cover_photo.url : undefined
                }
              />

              <BtnWrapper type="submit">
                {(isLoading || updateIsLoading) && <Spinner color="black" />}
                {editTitle ? "Update" : "Create"}
              </BtnWrapper>
            </Form>
          )}
        </Wrapper>
      </Formik>
    </Container>
  );
};

export default CreateBlogPost;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  padding-bottom: 40px;
`;

const Wrapper = styled(FormWrapper)`
  & > form {
    overflow-y: unset;
    max-height: unset;
    & > div:not(.body-container) {
      width: 40%;
    }
  }
`;

const BtnWrapper = styled(CreateBtn)`
  width: 230px;
  margin-top: 40px;

  & > svg {
    margin-right: 0.5rem;
  }
`;

const Nav = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  color: #181d0b;
  margin-bottom: 40px;

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.3s ease-in-out;
    }
  }

  h5 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;

  & > span {
    font-weight: 450;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #afd352;
  }
`;

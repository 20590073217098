import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { pricingDetailsValidation } from "../validation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { parseError } from "app/utils";
import {
  uploadAPhoto,
  uploadAVideo,
  uploadBrochure,
  uploadMultiplePhoto,
} from "app/api/utilities";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { Months } from "../data";
import Spinner from "app/views/Web/components/Spinner";
import { FileType, photosObj } from "app/api/project/types";
import { packageSelector } from "app/redux/packages/packageSlice";
import { useAppSelector } from "app/redux";
import { useSearchParams } from "react-router-dom";
import ImageUploadBox from "../ImageUploadBox";
import DoaUploadBox from "app/views/Admin/Analytics/components/modals/DoaUploadBox";
import { Box } from "@mui/system";

const initialPricingDetails = {
  squaremetre: "",
  fullPrice: "",
  duration: undefined,
  projectFee: "",
  discount: "",
  minPrice: "",
  availablePlots: "",
};

type DurationOptions = {
  value: string;
  label: string;
};

interface PricingDetailProps {
  id?: number;
  squaremetre: string;
  fullPrice: string;
  duration?: DurationOptions;
  projectFee: string;
  discount: string;
  minPrice: string;
  availablePlots: string;
}

interface PreviewContentProps {
  name: string;
  content: string;
  type: FileType;
  format?: string;
  size?: string;
}

interface FormSizesPrice {
  item_id?: number;
  size: number;
  price: number;
  infrastructure_fee?: number;
  installment_duration: number;
  discount: number;
  min_price: number;
  available_units: number;
}

interface FormPayload {
  main_file_id: number;
  file_ids: number[];
  items: FormSizesPrice[];
  package_brochure_file_id: number;
}

interface Props {
  onSubmitHandler: (value: any) => void;
  isLoading: boolean;
  step: number;
}

const PackagePricingSection: React.FC<Props> = ({
  onSubmitHandler,
  isLoading,
  step,
}) => {
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("edit");
  const [pricingArray, setPricingArray] = useState<PricingDetailProps[]>([
    { ...initialPricingDetails },
  ]);
  const [previewMain, setPreviewMain] = useState<PreviewContentProps>();
  const [previewBrochure, setPreviewBrochure] = useState<PreviewContentProps>();
  const [previewExtra, setPreviewExtra] = useState<PreviewContentProps[]>([]);
  const [mainFileId, setMainFileId] = useState<number>();
  const [brochureFileId, setBrochureFileId] = useState<number>();
  const [extraFileId, setExtraFileId] = useState<number[]>([]);
  const { packageDetails } = useAppSelector(packageSelector);

  const addMorePricing = () => {
    setPricingArray((oldArray) => [
      ...oldArray,
      {
        ...initialPricingDetails,
      },
    ]);
  };

  const cancelPricing = (index: number) => {
    setPricingArray([
      ...pricingArray.slice(0, index),
      ...pricingArray.slice(index + 1),
    ]);
  };

  const MainPhotoUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("photo", file);
      const res = await uploadAPhoto(data);
      setMainFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  const MultiplePhotoUploader = async (files: FormData) => {
    try {
      const res = await uploadMultiplePhoto(files);
      const result = res.data.data.map((ev) => ev.id);
      setExtraFileId((prev) => [...prev, ...result]);
    } catch (error) {
      parseError(error);
    }
  };

  const MainVideoUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("video", file);
      const res = await uploadAVideo(data);
      setMainFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  const BrochureUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("document", file);
      const res = await uploadBrochure(data);
      setBrochureFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  const onMainFileDelete = () => {
    setMainFileId(undefined);
  };

  const onBrochureDelete = () => {
    setBrochureFileId(undefined);
  };

  const onExtraFileDelete = (index: number) => {
    setExtraFileId([
      ...extraFileId.slice(0, index),
      ...extraFileId.slice(index + 1),
    ]);
  };

  useEffect(() => {
    if (!packageDetails || !editId) return;
    const priceArray = packageDetails.items.map((ev) => {
      const temp = {
        id: ev.id,
        squaremetre: String(ev.size),
        fullPrice: String(ev.full_price),
        projectFee: String(ev.infrastructure_fee),
        discount: String(ev.discount),
        duration: Months.filter(
          (month) =>
            month.value.split(" ")[0] === String(ev.installment_duration)
        )[0],
        minPrice: String(ev.min_price),
        availablePlots: String(ev.available_units),
      };
      return temp;
    });

    setPricingArray(priceArray);
    const formatEditImages = (item: photosObj) => {
      const temp = {
        name: item?.filename,
        content: item?.url,
        type: item?.file_type,
      };
      return temp;
    };

    if (packageDetails.main_file) {
      const mainImg = new Array(1)
        .fill(packageDetails.main_file)
        .map((ev) => formatEditImages(ev))[0];
      setPreviewMain(mainImg);
      setMainFileId(packageDetails.main_file.id);
    } else {
      setMainFileId(undefined);
      setPreviewMain(undefined);
    }

    if (packageDetails.photos) {
      const extraImg = packageDetails.photos.map((ev) => formatEditImages(ev));
      setPreviewExtra(extraImg);
      const extraId = packageDetails.photos.map((ev) => ev.id);
      setExtraFileId(extraId);
    } else {
      setExtraFileId([]);
      setPreviewExtra([]);
    }

    if (packageDetails.brochure) {
      const brochureImg = new Array(1)
        .fill(packageDetails.brochure)
        .map((ev) => formatEditImages(ev))[0];
      setPreviewBrochure(brochureImg);
      setBrochureFileId(packageDetails.brochure.id);
    } else {
      setBrochureFileId(undefined);
      setPreviewBrochure(undefined);
    }
  }, [packageDetails, editId]);

  return (
    <Formik
      initialValues={{
        pricingArray,
        mainFileId,
        brochureFileId,
      }}
      validationSchema={pricingDetailsValidation}
      enableReinitialize
      onSubmit={() => {
        const formatedSizePrices = pricingArray?.map(
          (ev: PricingDetailProps) => {
            let temp: FormSizesPrice = {
              size: +ev.squaremetre,
              price: +ev.fullPrice,
              installment_duration: Number(ev.duration?.value.split(" ")[0]),
              discount: +ev.discount,
              min_price: +ev.minPrice,
              available_units: +ev.availablePlots,
            };

            if (ev.projectFee) {
              temp = {
                ...temp,
                infrastructure_fee: +ev.projectFee,
              };
            }
            const result = editId ? { ...temp, item_id: ev.id } : temp;
            return result;
          }
        );
        const payload: FormPayload = {
          main_file_id: mainFileId as number,
          file_ids: extraFileId,
          items: formatedSizePrices,
          package_brochure_file_id: brochureFileId as number,
        };
        onSubmitHandler(payload);
      }}
    >
      <FormWrapper stepNo={step}>
        <Form>
          <PricingWrapper>
            <label>Pricing Details</label>
            {pricingArray.map((item: PricingDetailProps, i) => (
              <div key={i}>
                {i + 1 > 1 && (
                  <div className="cancel" onClick={() => cancelPricing(i)}>
                    <HighlightOffIcon />
                  </div>
                )}
                <InputField
                  name={`pricingArray[${i}].squaremetre`}
                  label="Squaremetre"
                  placeholder="600"
                  value={item.squaremetre}
                  onChange={(e) => {
                    item.squaremetre = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
                <InputField
                  name={`pricingArray[${i}].fullPrice`}
                  label="Full Price"
                  placeholder="5,900,000"
                  value={item.fullPrice}
                  onChange={(e) => {
                    item.fullPrice = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
                <SelectField
                  name={`pricingArray[${i}].duration`}
                  label="Duration"
                  options={Months}
                  value={item.duration as DurationOptions}
                  onChange={(e) => {
                    if (!e) return;
                    item.duration = e as DurationOptions;
                    setPricingArray([...pricingArray]);
                  }}
                  isColored="#28647D"
                  selectType="normal"
                />
                <InputField
                  name="availablePlots"
                  label="Available Plots/Unit"
                  placeholder="200"
                  value={item.availablePlots}
                  onChange={(e) => {
                    item.availablePlots = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
                <InputField
                  name={`pricingArray[${i}].minPrice`}
                  label="Minimum Price"
                  placeholder="10,000"
                  value={item.minPrice}
                  onChange={(e) => {
                    item.minPrice = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
                <InputField
                  name={`pricingArray[${i}].projectFee`}
                  label="Project Infrastuctural Fee"
                  placeholder="5,200"
                  value={item.projectFee}
                  onChange={(e) => {
                    item.projectFee = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
                <InputField
                  name={`pricingArray[${i}].discount`}
                  label="Discount"
                  placeholder="10%"
                  value={item.discount}
                  onChange={(e) => {
                    item.discount = e.target.value;
                    setPricingArray([...pricingArray]);
                  }}
                />
              </div>
            ))}

            <button type="button" onClick={addMorePricing}>
              Add another pricing details
            </button>
          </PricingWrapper>

          <ImageUploadBox
            name="mainFileId"
            imageBoxText="Add Main Image/Video"
            imageBoxHelperText="Note: Video should not be more than 10mb"
            onSingleFileDelete={onMainFileDelete}
            photoPostRequest={MainPhotoUploader}
            videoPostRequest={MainVideoUploader}
            previewSingleFile={previewMain}
          />

          <ImageUploadBox
            name="filesid"
            imageBoxText="Add Extra Project Image(s)"
            allowMultiple
            onlyImages
            onMultpleFileDelete={onExtraFileDelete}
            multiplePhotoPostRequest={MultiplePhotoUploader}
            previewMultipleFile={previewExtra}
          />

          <Box mt="40px">
            <DoaUploadBox
              name="brochureFileId"
              imageBoxText="Upload Brochure"
              photoPostRequest={BrochureUploader}
              onSingleFileDelete={onBrochureDelete}
              previewSingleFile={previewBrochure}
            />
          </Box>

          <SubmitBtn type="submit">
            <>
              {isLoading && <Spinner color="black" />}
              {editId ? "Edit" : "Create"} Package
            </>
          </SubmitBtn>
        </Form>
      </FormWrapper>
    </Formik>
  );
};

export default PackagePricingSection;

interface FormWrapperProps {
  stepNo: number;
}

const FormWrapper = styled.div<FormWrapperProps>`
  display: ${({ stepNo }) => stepNo !== 2 && "none"};
`;

const SubmitBtn = styled.button`
  background: #afd34f;
  border-radius: 8px;
  display: flex;
  gap: 14px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  padding: 13px 40px;
  margin-left: auto;
  margin-top: 150px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const PricingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  & > label {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
  }

  & > div {
    position: relative;
    background: #f7fbfd;
    border-radius: 8px;
    padding: 16px 20px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 24px;
    & > div {
      width: 30%;
    }

    .cancel {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      right: -10px;
      top: -10px;
      background: #fbfbfb;
      cursor: pointer;
      svg {
        width: 25px;
        height: 25px;
        fill: #b3261e;
      }
    }
  }

  & > button {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #afd34f;
    margin-top: 26px;
    width: max-content;
    border: none;
    background-color: transparent;
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      color: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

import { FileType } from "app/api/project/types";
import { bytesForHuman } from "app/utils";
import { DocUpload } from "app/views/Admin/components/AddDocument";
import { PreviewContentProps } from "app/views/Client/components/payment modal/ConfirmPayment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PreviewBox from "./DocPreviewBox";
import PngIcon from "../../../../../images/pdf-icon.png";

interface Props {
  name: string;
  imageBoxText: string;
  photoPostRequest: (file: File) => Promise<void>;
  onSingleFileDelete: () => void;
  previewSingleFile?: PreviewContentProps;
  imageBoxHelperText?: string;
}

const DoaUploadBox: React.FC<Props> = ({
  photoPostRequest,
  onSingleFileDelete,
  imageBoxText,
  imageBoxHelperText,
  name,
  previewSingleFile,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [singleUploadPreview, setSingleUploadPreview] =
    useState<PreviewContentProps>();

  useEffect(() => {
    if (previewSingleFile) {
      setSingleUploadPreview(previewSingleFile);
    }
  }, [previewSingleFile]);

  const ContentReader = (
    file: File,
    fn: (fileContent: PreviewContentProps) => void
  ) => {
    const filetype = file.type.split("/")[0] as FileType; // (image, video)
    const fileFormat = file.name.split(".")[1];

    const fileContent = {
      name: file.name.split(fileFormat)[0],
      type: filetype,
      format: fileFormat,
      size: bytesForHuman(file.size),
    };

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const result = { ...fileContent, content: reader.result as string };
      fn(result);
    };
  };

  const SinglePreviewHandler = (fileContent: PreviewContentProps) => {
    setSingleUploadPreview(fileContent);
  };

  const onSingleDelete = () => {
    setSingleUploadPreview(undefined);
    onSingleFileDelete && onSingleFileDelete();
  };

  const handleSingleUpload = (files: FileList) => {
    Array.from(files).forEach(async (file) => {
      setIsUploading(true);
      await photoPostRequest(file);
      setIsUploading(false);
      ContentReader(file, SinglePreviewHandler);
    });
  };

  const getDocIcon = (fileName?: string) => {
    const extension = fileName?.split(".").pop()?.toLowerCase();

    if (extension === "pdf") return PngIcon;
    else return "";
  };

  return (
    <Wrapper>
      <MiniBox>
        <DocUpload
          name={name} //for formik validation
          handleFile={handleSingleUpload}
          text={imageBoxText}
          isLoading={isUploading}
        />
        {imageBoxHelperText && <span>{imageBoxHelperText}</span>}
      </MiniBox>

      <Content singleUploadPreview={!!singleUploadPreview}>
        {singleUploadPreview && (
          <PreviewBox
            label={singleUploadPreview.name}
            content={getDocIcon(
              singleUploadPreview.format || singleUploadPreview.type
            )}
            size={singleUploadPreview.size}
            format={singleUploadPreview.format}
            onDelete={onSingleDelete}
          />
        )}
      </Content>
    </Wrapper>
  );
};

export default DoaUploadBox;

const MiniBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  span {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: #909090;
  }
`;

interface WrapperProps {
  allowMultiple?: boolean;
  marginTop?: string;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  gap: 24px;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  align-items: flex-start;
`;

interface ContentProps {
  singleUploadPreview: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  display: ${({ singleUploadPreview }) =>
    singleUploadPreview ? "block" : "none"};
`;

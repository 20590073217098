import ClientRoutes from "app/views/Client/routes";
import StaffRoutes from "app/views/Staff/routes";
import HelmetMetaData from "app/views/Web/components/Helmet";
import React from "react";
import AdminRoutes from "../views/Admin/routes";
import WebRoutes from "../views/Web/routes";
import ScrollToTop from "./scrollToTop";

const AppRoutes: React.FC = () => {
  return (
    <>
      <ScrollToTop />
      <HelmetMetaData />
      <>
        <WebRoutes />
        <AdminRoutes />
        <ClientRoutes />
        <StaffRoutes />
      </>
    </>
  );
};

export default AppRoutes;

import { useMutation } from "@tanstack/react-query";
import { adminPaymentSearchByCategory } from "app/api/payment";
import { parseError } from "app/utils";

interface Props {
  result: {
    search_string: string;
  };
  category_id: number;
  page: number;
  per_page: number;
  type: string;
}

const useAdminPaymentSearchByCategory = () => {
  return useMutation(
    (payload: Props) =>
      adminPaymentSearchByCategory(
        payload.result,
        payload.category_id,
        payload.page,
        payload.per_page,
        payload.type
      ),
    {
      onError: (error) => parseError(error),
    }
  );
};

export default useAdminPaymentSearchByCategory;

import { cardTextAnimate } from "app/utils/animationVariants";
import { FormikProps } from "formik";
import { motion } from "framer-motion";
import React, { FC } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";

interface Props {
  formik: FormikProps<any>;
  label: string;
  name: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  borderStyle?: string;
  value?: string;
}

const PhoneFormatter: FC<Props> = ({
  formik,
  label,
  name,
  onChange,
  isDisabled,
  isRequired,
  borderStyle,
  value,
}) => {
  const errorClass = `${isRequired ? "required" : ""}`;

  return (
    <PhoneWrapper variants={cardTextAnimate} borderStyle={borderStyle}>
      <label className={errorClass}>{label}</label>
      <PhoneInput
        country={"ng"}
        disabled={isDisabled}
        countryCodeEditable={false}
        inputProps={{
          name,
        }}
        value={value || null}
        onChange={(value, country, e, formattedValue) => {
          formik.getFieldHelpers(name).setValue(formattedValue);
          onChange(formattedValue);
        }}
      />
      {formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error ? (
        <Error>{formik.getFieldMeta(name).error}</Error>
      ) : null}
    </PhoneWrapper>
  );
};

export default PhoneFormatter;

interface PhoneWrapperProps {
  borderStyle?: string;
}

const PhoneWrapper = styled(motion.div)<PhoneWrapperProps>`
  display: flex;
  align-items: unset;
  justify-content: unset;
  flex-direction: column;
  width: 100%;

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }

  & .react-tel-input {
    height: 3.5rem;
    border: ${({ borderStyle }) =>
      borderStyle ? borderStyle : "1px solid #181d0b"};
    border-radius: 0.5rem;

    & .form-control {
      height: 100%;
      border-radius: 0.5rem;
      border: none;
      width: 100%;
      padding-left: 58px;

      :disabled {
        background-color: rgba(239, 239, 239, 0.7);
      }

      &:focus {
        box-shadow: none;
      }
    }

    & .flag-dropdown {
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      .selected-flag {
        height: 30px;
        border-right: 1px solid #b9b8b8;
      }

      .country-list {
        left: 0;
      }
    }
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import * as Yup from "yup";

export const createStaffValidation = Yup.object({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  address: Yup.string(),
  staffType: Yup.object().required(),
  title: Yup.object().required(),
  gender: Yup.object().required(),
  country: Yup.object().required(),
  postal: Yup.string(),
  referal_code: Yup.string(),
  maritalStatus: Yup.object(),
  dateJoined: Yup.date(),
});

export const SetCommissionValidation = Yup.object({
  installment_direct: Yup.string().required("Required"),
  fullpayment_direct: Yup.string().required("Required"),
});

export const SetCommissionTaxValidation = Yup.object({
  tax: Yup.string().required("Required"),
});

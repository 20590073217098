import { abbreviateNumber } from "app/utils";
import Spinner from "app/views/Web/components/Spinner";
import { FC } from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

interface props {
  label: string;
  value: number;
  icon: any;
  btnLabel?: string;
  removeLink?: boolean;
  applyShadow?: boolean;
  isLoading: boolean;
  maxWidth?: string;
  flexGrow?: number;
  width?: string;
  mediumwidth?: string;
  height?: string;
  labelHeight?: boolean;
  onViewMore?: () => void;
}

const DashboardTotalCard: FC<props> = ({
  icon: Icon,
  label,
  labelHeight,
  value,
  btnLabel,
  removeLink,
  applyShadow,
  maxWidth,
  flexGrow,
  width,
  mediumwidth,
  isLoading,
  height,
  onViewMore,
}) => {
  return (
    <Container
      applyShadow={applyShadow}
      maxWidth={maxWidth}
      width={width}
      height={height}
      flexGrow={flexGrow}
      mediumwidth={mediumwidth}
    >
      <LeftContent>
        <Label labelHeight={labelHeight}>{label}</Label>
        {isLoading ? (
          <SpinnerWrapper>
            <Spinner color="black" />
          </SpinnerWrapper>
        ) : (
          <Value>{abbreviateNumber(value)}</Value>
        )}
        <ViewMoreBtn removeLink={removeLink} onClick={onViewMore}>
          {btnLabel}
        </ViewMoreBtn>
      </LeftContent>
      <Icon />
    </Container>
  );
};

export default DashboardTotalCard;

interface ContainerProps {
  applyShadow?: boolean;
  maxWidth?: string;
  flexGrow?: number;
  width?: string;
  mediumwidth?: string;
  height?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: ${({ width }) => (width ? width : "35%")};
  height: ${({ height }) => (height ? height : "unset")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "360px")};
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  flex: none;
  order: 0;
  flex-grow: ${({ flexGrow }) => (flexGrow ? flexGrow : "0")};
  box-shadow: ${({ applyShadow }) =>
    applyShadow &&
    "0px 0px 1px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #EEEEEE"};

  & > svg {
    width: 48px;
    height: 48px;
    margin-left: auto;
  }

  ${mediaObj.mediumDesktop} {
    width: ${({ mediumwidth }) => (mediumwidth ? mediumwidth : "24%")};
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Label = styled.p<{ labelHeight?: boolean }>`
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 140%;
  height: ${({ labelHeight }) => (labelHeight ? `56px` : `unset`)};
  width: 80%;
  color: #989898;

  ${mediaObj.mediumDesktop} {
    font-size: 1rem;
  }
`;

const Value = styled.h2`
  font-weight: 500;
  font-size: 2.4375rem;
  line-height: 140%;
  color: #2a3313;
`;

interface ViewMoreBtnProps {
  removeLink?: boolean;
}

const ViewMoreBtn = styled.p<ViewMoreBtnProps>`
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 140%;
  margin-top: 15px;
  color: #afd34f;
  cursor: pointer;
  display: ${({ removeLink }) => removeLink && "none"};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
`;

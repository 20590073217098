import { FC } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import {
  CustomTourResponse,
  FixedDate,
  getSiteToursResponse,
} from "app/api/site tour/types";
import Actions from "../Actions";
import useCustomRemoveSchedule from "app/hooks/site tour/useCustomRemoveSchedule";
import { toast } from "react-toastify";

interface SiteProps {
  data: getSiteToursResponse;
  handleTourSelect: (data: getSiteToursResponse, isSelected: boolean) => void;
  isCustom?: never;
  onCloseDetails?: never;
}

interface CustomProps {
  data: CustomTourResponse;
  handleTourSelect: (data: CustomTourResponse, isSelected: boolean) => void;
  isCustom: true;
  onCloseDetails: () => void;
}

interface CommonProps {
  isSelected: boolean;
}

type Props = (SiteProps | CustomProps) & CommonProps;

const TourCard: FC<Props> = ({
  data,
  isSelected,
  handleTourSelect,
  isCustom,
  onCloseDetails,
}) => {
  const { mutate: deleteMutate, isLoading: isDeleting } =
    useCustomRemoveSchedule();

  const onDeleteSuccess = () => {
    isCustom && onCloseDetails();
    toast.success(`Custom Tour Deleted Successfully!`);
  };

  const onDelete = () => {
    const payload = {
      id: data.id,
    };
    deleteMutate(payload, {
      onSuccess: onDeleteSuccess,
    });
  };

  return (
    <Wrapper
      onClick={() => {
        isCustom && handleTourSelect(data, isSelected);
        !isCustom && handleTourSelect(data, isSelected);
      }}
      isSelected={isSelected}
    >
      {isCustom && (
        <CTAWrapper onClick={(e) => e.stopPropagation()}>
          <Actions
            marginRight="0"
            noEdit
            deleteHandler={onDelete}
            isDeleting={isDeleting}
          />
        </CTAWrapper>
      )}

      {isCustom && (
        <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
          <EntryLabel>Date</EntryLabel>
          <EntryDetails>{data.date}</EntryDetails>
        </Box>
      )}
      {isCustom && (
        <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
          <EntryLabel>Time</EntryLabel>
          <EntryDetails>{data.time}</EntryDetails>
        </Box>
      )}
      {!isCustom && (
        <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
          <EntryLabel>Schedule:</EntryLabel>
          <EntryDetails>
            {data.schedules.map((ev, i) => (
              <ScheduleBox key={i}>
                {ev.day.name === FixedDate
                  ? ev.day.name + ": " + ev.day.dates[0]
                  : ev.day.name}
              </ScheduleBox>
            ))}
          </EntryDetails>
        </Box>
      )}
      <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
        <EntryLabel>Property:</EntryLabel>
        <EntryDetails>{data.project}</EntryDetails>
      </Box>
      {!isCustom && (
        <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
          <EntryLabel>Location:</EntryLabel>
          <EntryDetails>
            {data.location}
            {", " + data.address}
          </EntryDetails>
        </Box>
      )}
      <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
        <EntryLabel>Clients:</EntryLabel>
        <EntryDetails>
          {isCustom ? data.clients.length : data.clientsCount}
        </EntryDetails>
      </Box>
    </Wrapper>
  );
};

export default TourCard;

const Wrapper = styled.div<{ isSelected?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 45px 16px 16px;
  gap: 16px;
  width: 362px;
  background: ${({ isSelected }) => (isSelected ? "#cde6f5" : "#FFF")};
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  cursor: pointer;
  text-transform: capitalize;
`;

const CTAWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ScheduleBox = styled.div`
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`;

const EntryLabel = styled.span`
  font-weight: 450;
  font-size: 13px;
  line-height: 140%;
  color: #3d9ac0;
  flex-shrink: 0;
  width: 55px;
`;

const EntryDetails = styled.span`
  font-weight: 450;
  font-size: 13px;
  line-height: 140%;
  color: #181d0b;
`;

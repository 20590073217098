import { clientObj } from "app/api/client/types";
import styled from "styled-components";

const PackageRenderer = ({ data }: { data: clientObj }) => {
  const { packages } = data;
  const gridPackages = packages?.map((x) => x.package) || [];
  const gridArr = gridPackages[0]?.split(" ");
  const gridToDisplay =
    gridArr?.length > 2 ? `${gridArr?.slice(0, 2)?.join(" ")}...` : gridArr;
  const isEmpty = gridPackages.length === 0;
  const countLeft = !isEmpty && gridPackages.length - 1;

  return (
    <Container>
      <Item isEmpty={isEmpty}>{isEmpty ? "None" : gridToDisplay}</Item>
      {!!countLeft && <Count>{`+${countLeft}`}</Count>}
    </Container>
  );
};

export default PackageRenderer;

const Container = styled.div`
  display: flex;
  gap: 4px;
`;

interface itemProps {
  isEmpty?: boolean;
}

const Item = styled.div<itemProps>`
  display: flex;
  align-items: center;
  padding: 4px 14px;
  font-weight: 450;
  font-size: 13px;
  color: ${({ isEmpty }) => (isEmpty ? "#181D0B" : "#fbfbfb")};
  height: 26px;
  background: ${({ isEmpty }) => !isEmpty && "#97b644"};
  border-radius: 10px;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: 450;
  font-size: 10px;
  color: #2a3313;
  background: #d8e8b8;
  border-radius: 50%;
`;

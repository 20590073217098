import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { AdminRecentSalesRes } from "app/api/analytics/types";
import InstallmentRenderer from "./InstallmentRenderer";
import { abbreviateNumber } from "app/utils";

interface GridProps {
  data: AdminRecentSalesRes[];
}

type SalesField = keyof AdminRecentSalesRes | undefined;

export interface GridDef extends ColDef {
  field: SalesField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: AdminRecentSalesRes[];
}

const SalesGrid: React.FC<GridProps> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: AdminRecentSalesRes[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Project name",
      field: "project",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 0.9,
      wrapText: true,
    },
    {
      headerName: "Package type",
      field: "package",
      sortable: false,
      flex: 0.9,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "State",
      field: "location",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "SQM",
      sortable: false,
      field: "size",
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "Buyer's Name",
      sortable: false,
      field: "client",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        color: "#809A39",
      },
      wrapText: true,
    },
    {
      headerName: "Price",
      sortable: false,
      field: "amount_paid",
      flex: 0.6,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      valueFormatter: (params) => {
        const { data }: { data: AdminRecentSalesRes } = params;
        return `${abbreviateNumber(data.amount_paid)}`;
      },
    },
    {
      headerName: "Payment Type",
      sortable: false,
      field: "installment",
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      cellRendererFramework: InstallmentRenderer,
    },
    {
      headerName: "Date",
      sortable: false,
      field: "date",
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 0.6,
      valueFormatter: (params) => {
        const { data }: { data: AdminRecentSalesRes } = params;
        return new Intl.DateTimeFormat("en-US").format(new Date(data.date));
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <>
      {GridData?.length > 0 ? (
        <GridWrapper className="ag-theme-alpine">
          <AgGridReact
            rowClass={rowClass}
            getRowClass={getRowClass}
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={60}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: AdminRecentSalesRes) => {
              return data.id.toString();
            }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>You have no recent sales</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default SalesGrid;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 230px;
  width: 100%;
  margin-bottom: 10px;
  overflow-y: scroll;

  .my-white-class {
    background-color: #eaf4fb;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  & * {
    overflow: visible !important;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

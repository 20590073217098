import { Box, ClickAwayListener } from "@mui/material";
import { assessmentObj } from "app/api/staff/types";
import { FC, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as moreIcon } from "app/images/admin/three-dots.svg";
import { ReactComponent as EditIcon } from "app/images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import Fade from "../../components/fade";
import { useAppDispatch } from "app/redux";
import { setAssessment } from "app/redux/assessment/assessmentSlice";
import { useNavigate } from "react-router";
import DeleteAssessObj from "./modals/deleteAssessObj";
import useAdminDeleteAssessment from "app/hooks/staffs/useAdminDeleteAssessment";
import { toast } from "react-toastify";

interface props {
  data: assessmentObj;
}

const AssessmentCard: FC<props> = ({ data }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<number>();
  const { mutate, isLoading } = useAdminDeleteAssessment();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEdit = () => {
    dispatch(setAssessment(data));
    navigate(`assessment/${data.id}`);
  };

  const handleDelete = (id: number) => {
    mutate(
      { assessment_id: id },
      {
        onSuccess: () => {
          setSelectedAssessmentId(undefined);
          toast.success("Assessment deleted successfully");
        },
        onError: (res) => {
          toast.error(res as string);
        },
      }
    );
  };

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
        <DropDownWrapper>
          <MoreIcon onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
          <Fade isOpen={isDropdownOpen}>
            <Dropdown>
              <DropdownEntry isEdit onClick={handleEdit}>
                <EditIcon /> Edit
              </DropdownEntry>
              <DropdownEntry
                isDelete
                isLast
                onClick={() => setSelectedAssessmentId(data.id)}
              >
                <DeleteIcon /> Delete
              </DropdownEntry>
            </Dropdown>
          </Fade>
        </DropDownWrapper>
      </ClickAwayListener>

      <Box display={"flex"} justifyContent={"center"} gap={"8px"}>
        <Entry>Assessment Title:</Entry>
        <Entry>{data.title}</Entry>
      </Box>
      <Box display={"flex"} justifyContent={"center"} gap={"8px"}>
        <Entry>Number of Questions:</Entry>
        <Entry>{data.questions?.length}</Entry>
      </Box>
      <Box display={"flex"} justifyContent={"center"} gap={"8px"}>
        <Entry>Date Created:</Entry>
        <Entry>
          {data.created_at && new Date(data.created_at).toLocaleDateString()}
        </Entry>
      </Box>
      {(!!selectedAssessmentId || selectedAssessmentId === 0) && (
        <DeleteAssessObj
          onDelete={handleDelete}
          handleClose={() => setSelectedAssessmentId(undefined)}
          isOpen={!!selectedAssessmentId || selectedAssessmentId === 0}
          id={selectedAssessmentId}
          isLoading={isLoading}
        />
      )}
    </Wrapper>
  );
};

export default AssessmentCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 79px 16px 24px;
  gap: 16px;
  width: 349px;
  height: 123px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 4px;
`;

const Entry = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #1b1b1b;
`;

const MoreIcon = styled(moreIcon)`
  cursor: pointer;
`;

const DropDownWrapper = styled.div`
  position: absolute;
  top: 4px;
  right: 12px;
  z-index: 20;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 25px;
  left: calc(100% - 18px);
  display: flex;
  flex-direction: column;
  width: 80px;
  background: #ffffff;
  border: 1px solid #f2eae5;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
`;

interface dropdownEntryProps {
  isEdit?: boolean;
  isDelete?: boolean;
  isLast?: boolean;
}

const DropdownEntry = styled.div<dropdownEntryProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 7px 10px;
  width: 100%;
  font-size: 12px;
  cursor: pointer;

  ${({ isLast }) =>
    !isLast &&
    css`
      border-bottom: 0.3px solid #afd352;
    `}

  svg {
    width: 13px;
    height: 13px;
    fill: ${({ isEdit }) => isEdit && "#43A047"};
    fill: ${({ isDelete }) => isDelete && "#B3261E"};
  }
`;

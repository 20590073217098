import { ForumResponse } from "app/api/community/types";
import useDeleteForum from "app/hooks/community/useDeleteForum";
import Actions from "app/views/Admin/components/Actions";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ContentTypes } from "../..";

interface Props {
  data: ForumResponse;
}

const ForumCard: React.FC<Props> = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { title, description, start_date, start_time, cover_photo, links } =
    data;

  const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteForum();

  const onDelete = () => {
    const payload = {
      forum_id: data.id,
    };
    deleteMutate(payload, {
      onSuccess: onDeleteSuccess,
    });
  };

  const onDeleteSuccess = () => {
    toast.success(`Forum Deleted Successfully!`);
  };

  const onEdit = () => {
    searchParams.set("create", ContentTypes.forum);
    searchParams.set("edit", title);
    setSearchParams(searchParams);
  };

  return (
    <Container>
      <AbsoluteBox>
        <Actions
          editHandler={onEdit}
          deleteHandler={onDelete}
          isDeleting={deleteLoading}
        />
      </AbsoluteBox>
      <ImgWrapper>
        <img src={cover_photo?.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title}</h6>
        <p>{description}</p>
        <ForumLinkWrapper>
          {links.map((link, i) => (
            <ForumLink key={link.id}>
              <div>Forum Link {links.length === 1 ? "" : i + 1}: </div>
              <a href={link.link} target="_blank" rel="noreferrer">
                {link.link}
              </a>
            </ForumLink>
          ))}
        </ForumLinkWrapper>

        <Footer>
          <FlexWrapper>
            <ForumDate>Event Date: {start_date}</ForumDate>
            <ForumBox>
              <span>Forum Time: </span>
              <span>{start_time}</span>
            </ForumBox>
          </FlexWrapper>
        </Footer>
      </ContentWrapper>
    </Container>
  );
};

export default ForumCard;

const Container = styled.div`
  width: 784px;
  height: 228px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin-top: 12px;
    margin-bottom: 7px;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
`;

const ForumLinkWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: auto;
`;

const ForumLink = styled.div`
  font-weight: 450;
  font-size: 0.9rem;
  line-height: 140%;

  & > div {
    color: #2a3313;
  }

  & > a {
    color: #48b6e3;
  }
`;

const ForumBox = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #5e5e5e;
  }
`;

const ForumDate = styled.div`
  color: #2a3313;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

import styled from "styled-components";
import { ReactComponent as ThreeDots } from "app/images/admin/three-dots.svg";
import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import Fade from "app/views/Admin/components/fade";
import { Visibility, Delete } from "@mui/icons-material";
import { eTeamResult } from "app/api/staff/types";
import ApproveRejectModal from "../modals/approveReject.tsx";
import { ETeamActionStatus } from "app/types/common";

const ActionRenderer = ({ data }: { data: eTeamResult }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [status, setStatus] = useState<ETeamActionStatus>(
    ETeamActionStatus.Approve
  );

  const handleOpenModal = (status: ETeamActionStatus) => {
    setStatus(status);
    setIsDropdownOpen(false);
    setIsDeleteModalOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Container openActions={isDropdownOpen}>
        <ThreeDots onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
        <Fade isOpen={isDropdownOpen}>
          <ClientDropdown>
            <DropdownLabel
              onClick={() => {
                handleOpenModal(ETeamActionStatus.Approve);
              }}
            >
              <Visibility width={"10px"} />
              <span>Approve</span>
            </DropdownLabel>
            <DropdownLabel
              onClick={() => {
                handleOpenModal(ETeamActionStatus.Reject);
              }}
              color={"#d32f2f"}
            >
              <Delete color="error" />
              <span>Reject</span>
            </DropdownLabel>
          </ClientDropdown>
        </Fade>
        <ApproveRejectModal
          id={data.id}
          handleClose={() => setIsDeleteModalOpen(false)}
          isOpen={isDeleteModalOpen}
          status={status}
        />
      </Container>
    </ClickAwayListener>
  );
};

export default ActionRenderer;

interface ActionWrapperProps {
  openActions: boolean;
}

const Container = styled.div<ActionWrapperProps>`
  position: relative;
  cursor: pointer;
  z-index: ${({ openActions }) => (openActions ? 99 : "45")};
`;

const ClientDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  z-index: 45;
`;

const DropdownLabel = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 12px;
  font-weight: 450;
  font-size: 12px;
  color: ${({ color }) => (!!color ? color : `#2a3313`)};
  width: 100%;

  &:hover {
    background: #d5d8d820;
  }

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

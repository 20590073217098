import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import api from "app/api";
import { toast } from "react-toastify";
import { labelValueType, SingleOption } from "app/types/common";
import DualRing from "app/views/Web/components/loader";
import {
  genderOptions,
  martialStatusOptions,
  titleOptions,
} from "../Clients/data";
import DatePickerField from "../components/formik inputs/DatePickerField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { createStaffValidation } from "./components/validation";
import InputField from "../components/formik inputs/InputField";
import SelectField from "../components/formik inputs/SelectField";
import { getStaffTypes } from "app/api/users";
import useAdminCreateStaff from "app/hooks/users/useAdminCreateUser";
import { AdminCreateUserPayload } from "app/api/users/types";

const CreateStaff = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState<labelValueType<string, string>[]>(
    []
  );
  const [staffTypes, setStaffTypes] = useState<
    labelValueType<string, number>[]
  >([]);
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [staffType, setStaffType] = useState<SingleOption>();
  const [title, setTitle] = useState<labelValueType>();
  const [gender, setGender] = useState<labelValueType>();
  const [country, setCountry] = useState<SingleOption>();
  const [postal, setPostal] = useState<string>("");
  const [referal_code, setReferal_code] = useState("");
  const [maritalStatus, setMaritalStatus] = useState<labelValueType>();
  const [dateJoined, setDateJoined] = useState<Dayjs | null>(null);

  const { mutate: createMutate, isLoading, error } = useAdminCreateStaff();

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  useEffect(() => {
    async function getCountries() {
      try {
        const res = await api.utilityService.getAllCountries();
        setCountries(
          res.data.data.map((x) => ({ value: x.id.toString(), label: x.name }))
        );
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }

    async function getAllStaffTypes() {
      try {
        const res = await getStaffTypes();
        setStaffTypes(
          res.data.data.map((x) => ({ value: x.id, label: x.name }))
        );
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }

    getCountries();
    getAllStaffTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoBack = () => {
    navigate("/admin/staffs");
  };

  const onSuccess = () => {
    toast.success(`User Created Successfully!`);
    setTimeout(() => {
      handleGoBack();
    }, 1500);
  };

  const handleSubmit = () => {
    try {
      const payload: AdminCreateUserPayload = {
        address,
        country_id: country?.value,
        email,
        date_joined: dayjs(dateJoined).format("YYYY-MM-DD"),
        firstname,
        gender: gender?.value,
        lastname,
        marital_status: maritalStatus?.value,
        phone_number: phoneNumber,
        postal_code: postal,
        referal_code: referal_code,
        staff_type_id: staffType?.value,
        title: title?.value,
      };
      createMutate(payload, {
        onSuccess,
      });
    } catch (error) {
      let errorMessage = error as any;
      toast.error(errorMessage);
    }
  };

  return (
    <Container>
      <Header>
        <ArrowBackIcon onClick={() => handleGoBack()} />
        <HeaderText>Add A New Staff</HeaderText>
      </Header>
      <Formik
        initialValues={{
          firstname,
          lastname,
          email,
          phoneNumber,
          title,
          staffType,
          address,
          gender,
          country,
          postal,
          referal_code,
          maritalStatus,
        }}
        validationSchema={createStaffValidation}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        <StyledForm>
          <ContentWrapper>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              width={"47%"}
            >
              <Box display={"flex"} gap={"24px"}>
                <InputField
                  name="firstname"
                  label="First Name"
                  placeholder="First name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  isRequired
                />
                <InputField
                  name="lastname"
                  label="Last Name"
                  placeholder="Last name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  isRequired
                />
              </Box>
              <Box display={"flex"} gap={"24px"}>
                <InputField
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                />
                <InputField
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  isRequired
                />
              </Box>
              <Box display={"flex"}>
                <InputField
                  name="address"
                  label="Address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Box>
              <Box display={"flex"} gap={"24px"}>
                <SelectField
                  name="staffType"
                  label="Staff Type"
                  options={staffTypes}
                  value={staffType as SingleOption}
                  onChange={(e) => setStaffType(e as SingleOption)}
                  selectType="normal"
                  isRequired
                />
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              width={"47%"}
            >
              <Box display={"flex"} gap={"24px"}>
                <SelectField
                  name="title"
                  label="Title"
                  options={titleOptions}
                  value={title as labelValueType}
                  onChange={(e) => setTitle(e as labelValueType)}
                  selectType="normal"
                  isRequired
                />
                <SelectField
                  name="gender"
                  label="Gender"
                  options={genderOptions}
                  value={gender as labelValueType}
                  onChange={(e) => setGender(e as labelValueType)}
                  selectType="normal"
                  isRequired
                />
              </Box>
              <Box display={"flex"} gap={"24px"}>
                <SelectField
                  name="country"
                  label="Nationality"
                  options={countries}
                  value={country as SingleOption}
                  onChange={(e) => setCountry(e as SingleOption)}
                  selectType="normal"
                  isRequired
                />
              </Box>
              <Box display={"flex"} gap={"24px"}>
                <InputField
                  name="postal"
                  label="Postal Code"
                  placeholder="Postal Code"
                  value={postal}
                  onChange={(e) => setPostal(e.target.value)}
                />
                <SelectField
                  name="maritalStatus"
                  label="Marital Status"
                  options={martialStatusOptions}
                  value={maritalStatus as labelValueType}
                  onChange={(e) => setMaritalStatus(e as labelValueType)}
                  selectType="normal"
                />
              </Box>
              <Box display={"flex"} gap={"24px"}>
                <DatePickerField
                  name="dateJoined"
                  label="Date Joined"
                  placeholder="Select Date"
                  value={dateJoined}
                  onChange={(e) => setDateJoined(e)}
                />
                <InputField
                  name="referal_code"
                  label="Referral code (optional)"
                  placeholder="dhjs32h"
                  value={referal_code}
                  onChange={(e) => setReferal_code(e.target.value)}
                />
              </Box>
            </Box>
          </ContentWrapper>
          <ButtonWrapper>
            <SubmitButton type={"submit"}>
              {isLoading ? (
                <DualRing color="#fff" width={"16px"} height={"16px"} />
              ) : (
                `Submit`
              )}
            </SubmitButton>
          </ButtonWrapper>
        </StyledForm>
      </Formik>
    </Container>
  );
};

export default CreateStaff;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px 40px;
  min-height: 80vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;

  & > svg {
    width: 26px;
    height: 25px;
    cursor: pointer;
  }
`;

const HeaderText = styled.h5`
  font-weight: 500;
  font-size: 25px;
  color: #181d0b;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: auto;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 10px;
  gap: 10px;
  width: 150px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: #697f2f;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  & > div {
    margin: 0 11px 12px 0;
  }
`;

const BackBtn = styled(SubmitButton)`
  background: whitesmoke;
  color: #565555;
  transition: all 0.2s ease-in;

  &:hover {
    background: #f2f0f0;
  }
`;

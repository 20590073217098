import { Box } from "@mui/material";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  price: number;
}

const Valuation: React.FC<Props> = ({ price }) => {
  return (
    <Wrapper>
      <Title>Property Valuation</Title>
      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
        <ValuationLabel>Current Valuation</ValuationLabel>
        <Value>
          {"\u20A6 "}
          {price.toLocaleString("en-US")}
        </Value>
      </Box>
    </Wrapper>
  );
};

export default Valuation;

const Wrapper = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  padding: 1rem;
  background: #ffffff;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 8px;

  ${mediaQueries.mobile} {
    height: auto;
  }
`;

const Title = styled.h3`
  font-weight: 450;
  font-size: 1rem;
  color: #181d0b;
`;

const ValuationLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.8125rem;
  color: #fbfbfb;
  width: 120px;
  height: 26px;
  background: #3d9ac0;
  border-radius: 4px;
  line-height: 140%;
`;

const Value = styled.p`
  font-weight: 500;
  font-size: 1.25rem;
  color: #1e4b5e;
  line-height: 140%;
`;

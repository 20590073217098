import { Box } from "@mui/material";
import CustomModal from "app/views/Admin/components/modal";
import { FC } from "react";
import styled from "styled-components";
import { myClientRes } from "app/api/client/types";
import NameAvatar from "app/views/Admin/components/nameAvatar";
import MyClientPackageCard from "app/views/Admin/components/Cards/myClientPackageCard";

interface props {
  data: myClientRes;
  handleClose: () => void;
  isOpen: boolean;
}

const DetailsModal: FC<props> = ({ data, handleClose, isOpen }) => {
  const {
    address,
    country,
    email,
    phone_number,
    profile_photo,
    packages,
    firstname,
    lastname,
  } = data;

  const fullname = `${firstname} ${lastname}`;

  return (
    <CustomModal
      isOpen={isOpen}
      handleClose={handleClose}
      minWidth="520px"
      maxWidth={"50vw"}
    >
      <Container>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={4}
        >
          <HeaderText>Client Details</HeaderText>
        </Box>
        <ContentWrapper>
          <Box display={"flex"} gap={3}>
            {profile_photo ? (
              <ProfilePic src={profile_photo.url} />
            ) : (
              <NameAvatar name={fullname} width="100px" height="100px" />
            )}
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Title>Personal Information</Title>
              <Box display={"flex"} gap={1} mt={2} mb={1}>
                <Label>Full Name:</Label>
                <Name>{fullname}</Name>
              </Box>
              <Box display={"flex"} gap={1} mb={1}>
                <Label>Email:</Label>
                <Email>{email}</Email>
              </Box>
              <Box display={"flex"} gap={1} mb={1}>
                <Label>Phone Number:</Label>
                <Value>{phone_number}</Value>
              </Box>
              <Box display={"flex"} gap={1} mb={1}>
                <Label>Nationality:</Label>
                <Value>{country}</Value>
              </Box>

              <Box display={"flex"} gap={1} mb={1}>
                <Label>Address:</Label>
                <Value>{address}</Value>
              </Box>
            </Box>
          </Box>
          {packages && packages?.length > 0 && (
            <PackageWrapper>
              {packages.map((x, idx) => (
                <MyClientPackageCard
                  key={idx}
                  data={x}
                  count={packages.length || 0}
                  itemIndex={idx + 1}
                />
              ))}
            </PackageWrapper>
          )}
        </ContentWrapper>
      </Container>
    </CustomModal>
  );
};

export default DetailsModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 12px 35px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const HeaderText = styled.h2`
  font-weight: 450;
  font-size: 20px;
  color: #181d0b;
`;

const ProfilePic = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #181d0b;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #2a3313;
`;

const Value = styled(Label)`
  color: #777777;
`;

const Name = styled(Label)`
  color: #809a39;
`;

const Email = styled(Label)`
  color: #327f9e;
`;

const NextOfKinBtn = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #afd34f;
  margin-top: 10px;
  cursor: pointer;
`;

const PackageWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  max-width: 100%;
  min-height: 265px;
  overflow-x: scroll;
`;

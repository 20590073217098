import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import StartSession from "./sections/start";
import { useState } from "react";
import BioData from "./sections/bioData";
import Test from "./sections/test";
import Summary from "./sections/summary";
import Success from "./sections/success";
import virtualImage from "app/images/web/virtual-image.jpg";

const Assessment = () => {
  const [step, setStep] = useState<number>(0);

  const handleNext = () => {
    setStep(step + 1);
  };

  return (
    <Wrapper>
      <LeftWrapper>
        <Title>
          Join the <GreenTxt>Adbond</GreenTxt> virtual team
        </Title>
        <Description>
          Joining the team isn’t just about earning money, but about growth. We
          have some exciting benefits when you join the team. Visit our careers
          page to learn more.
        </Description>
        <VirtualImage src={virtualImage} />
      </LeftWrapper>
      <RightWrapper>
        {step === 0 && <StartSession handleNext={handleNext} />}
        {step === 1 && <BioData handleNext={handleNext} />}
        {step === 2 && <Summary handleNext={handleNext} />}
        {step === 3 && <Test handleNext={handleNext} />}
        {step === 4 && <Success />}
      </RightWrapper>
    </Wrapper>
  );
};

export default Assessment;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 200px);
  margin: 60px auto 50px;

  ${mediaObj.mobile} {
    flex-direction: column;
    gap: 40px;
  }
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: clamp(300px, 35%, 520px);

  ${mediaObj.mediumDesktop} {
    width: 50%;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 31px;
  line-height: 140%;
  color: #143340;

  ${mediaObj.bigMobile} {
    width: 90%;
    font-size: 25px;
  }
`;

const GreenTxt = styled.span`
  font-weight: 700;
  font-size: 31px;
  line-height: 140%;
  color: #afd34f;

  ${mediaObj.bigMobile} {
    font-size: 25px;
  }
`;

const Description = styled.p`
  font-weight: 450;
  font-size: 1.2rem;
  line-height: 140%;
  color: #5e5e5e;
  width: 90%;

  ${mediaObj.mobile} {
    width: 100%;
    font-size: 1rem;
  }
`;

const VirtualImage = styled.img`
  width: 375px;
  height: 310px;
  margin-top: 20px;
  object-fit: cover;

  ${mediaObj.tablet} {
    width: 300px;
    height: 240px;
  }

  ${mediaObj.bigMobile} {
    width: 350px;
    max-width: 100%;
    height: 280px;
  }
`;

const RightWrapper = styled.div`
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 12px;
  padding: 40px;
  width: clamp(400px, 60%, 850px);
  height: 100%;

  ${mediaObj.mobile} {
    width: 100%;
    box-shadow: none;
    padding: 0;
  }
`;

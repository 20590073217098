import { useEffect, useState } from "react";
import styled from "styled-components";
import { AnalyticsTabs, TabsAnalytics } from "./data";
import DisplayProject from "./components/DisplayProject";
import DisplayPayment from "./components/DisplayPayment";
import DisplaySales from "./components/DisplaySales";
import DisplayOrders from "./components/DisplayOrders";
import DisplayStaff from "./components/DisplayStaff";
import { useSearchParams } from "react-router-dom";

enum AnalyticsQuery {
  activeTab = "activeTab",
}

const Analytics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeParam = searchParams.get(AnalyticsQuery.activeTab);
  const [selectedTab, setSelectedTab] = useState(1);

  useEffect(() => {
    !activeParam && setSearchParams({ activeTab: AnalyticsTabs[0].name });
  }, [setSearchParams, activeParam]);

  useEffect(() => {
    if (activeParam) {
      const result = AnalyticsTabs.filter((ev) => ev.name === activeParam);
      setSelectedTab(result[0].id);
    }
  }, [activeParam]);

  return (
    <Container>
      <H5>Analytics</H5>
      <TabsWrapper>
        {AnalyticsTabs.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedTab}
            onClick={() => {
              setSelectedTab(x.id);
              setSearchParams({ [AnalyticsQuery.activeTab]: x.name });
            }}
          >
            {x.name}
          </Tab>
        ))}
      </TabsWrapper>
      {selectedTab === TabsAnalytics.project && <DisplayProject />}
      {selectedTab === TabsAnalytics.payment && <DisplayPayment />}
      {selectedTab === TabsAnalytics.order && <DisplayOrders />}
      {selectedTab === TabsAnalytics.sales && <DisplaySales />}
      {selectedTab === TabsAnalytics.staff && <DisplayStaff />}
    </Container>
  );
};

export default Analytics;

const Container = styled.div`
  background-color: white;
  padding: 24px;
`;

const H5 = styled.h5`
  font-weight: 450;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #181d0b;
`;

interface tabProps {
  isActive?: boolean;
}

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 24px 0;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 16px;
  height: 38px;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#143340")};
  background: ${({ isActive }) => (isActive ? "#327F9E" : "#CDE6F5")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

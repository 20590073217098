import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { ReactComponent as CalendarIconBlue } from "../../../../images/web/calendar-icon-blue.svg";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import media from "../../../../../styles/utils/media";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "10px",
};
// testing
//testing
interface Props {
  open: boolean;
  handleClose: () => void;
}

const FormModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Container>
          <div className="cancel" onClick={() => handleClose()}>
            <CancelRoundedIcon />
          </div>
          <CalendarIconBlue />
          <p className="middle">
            Thank you for scheduling a site tour with us. A mail has been sent
            to your email address
          </p>
          <p>We can’t wait to take you on a wonderful tour of this project</p>
        </Container>
      </Box>
    </Modal>
  );
};

export default FormModal;

const Container = styled.div`
  min-width: 31.25rem;
  max-width: 33.875rem;
  padding: 3.125rem 4.0625rem;
  text-align: center;
  position: relative;

  .cancel {
    color: #dc362e;
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }

  p {
    text-align: center;
    font-weight: 450;
    font-size: 1.25rem;
    color: #2a3313;

    ${media.bigMobile`
    font-size: 0.875rem;
        `}
  }

  .middle {
    margin-top: 2.125rem;
    margin-bottom: 1.5rem;
  }

  ${media.bigMobile`
  width: 88vw;
  padding: 3.125rem 1.0625rem;
  min-width: unset;
  max-width: unset;
        `}
`;

import { useMutation } from "@tanstack/react-query";
import {
  adminSalesAgro,
  adminSalesHomes,
  adminSalesLands,
} from "app/api/sales";
import { parseError } from "app/utils";

const useAdminSalesLands = () => {
  return useMutation(adminSalesLands, {
    onError: (error) => parseError(error),
  });
};

export default useAdminSalesLands;

export const useAdminSalesHomes = () => {
  return useMutation(adminSalesHomes, {
    onError: (error) => parseError(error),
  });
};

export const useAdminSalesAgro = () => {
  return useMutation(adminSalesAgro, {
    onError: (error) => parseError(error),
  });
};

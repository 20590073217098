import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import NameRenderer from "./nameRenderer";
import StaffTypeRenderer from "./staffTypeRenderer";
import ActionRenderer from "./actionRenderer";
import DualRing from "app/views/Web/components/loader";
import { UserResponse } from "app/types/common";

interface GridProps {
  data: UserResponse[];
  isLoading?: boolean;
}

type StaffField = keyof UserResponse | undefined | "action";

export interface GridDef extends ColDef {
  field: StaffField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: UserResponse[];
}

const StaffGrid: React.FC<GridProps> = ({ data, isLoading }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: UserResponse[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Staff Name",
      field: "firstname",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 1,
      cellRendererFramework: NameRenderer,
      wrapText: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
        color: "#28647D",
        paddingRight: "15px",
      },
      wrapText: true,
    },
    {
      headerName: "Phone Number",
      sortable: false,
      field: "phone_number",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
    },
    {
      headerName: "No of Clients",
      sortable: false,
      field: "clients",
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: UserResponse } = params;
        return data.clients.length > 0 ? String(data.clients.length) : "0";
      },
    },
    {
      headerName: "Staff status",
      sortable: false,
      field: "staff_type",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StaffTypeRenderer,
    },
    {
      sortable: false,
      field: "action",
      flex: 0.2,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {isLoading ? (
        <MainLoaderWrapper height="545px">
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-alphine`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={50}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            suppressRowTransform
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: UserResponse) => {
              return data.id.toString();
            }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>You have no clients</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default StaffGrid;

interface loaderProps {
  height?: string;
}

export const MainLoaderWrapper = styled.div<loaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
`;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "600",
  "letter-spacing": "-0.02em",
  "font-size": "14px",
  "line-height": "18px",
};

const GridWrapper = styled.div`
  height: 400px;
  width: 100%;
  margin: 40px 0 10px;
  & *:not(img) {
    overflow: visible !important;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

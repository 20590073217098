import { ApiResponse } from "app/redux/types";
import { SiteInspectionResponse } from "../project/types";
import request from "../request";
import {
  CustomAddClientsPayload,
  CustomRemoveClientPayload,
  CustomRemoveSchedulePayload,
  CustomTourPayload,
  CustomTourResponse,
  CustomUpdateSchedulePayload,
  getSiteToursResponse,
  NewSiteTourPayload,
  NewSiteTourResponse,
  RemoveInspectionSchedulePayload,
  RequestCustomInspectionPayload,
} from "./types";

export const createNewSiteTour = async (payload: NewSiteTourPayload) => {
  return await request.post<ApiResponse<NewSiteTourResponse>>(
    `/admin/inspection/create`,
    payload
  );
};

export const createCustomTour = async (payload: CustomTourPayload) => {
  return await request.post<ApiResponse<CustomTourResponse>>(
    `/admin/inspection/custom/create`,
    payload
  );
};

export const getSiteTours = async (month: number, year: number) => {
  return await request.get<ApiResponse<getSiteToursResponse[]>>(
    `/admin/inspection/by_projects?month=${month}&year=${year}`
  );
};

export const getCustomTours = async (month: number, year: number) => {
  return await request.get<ApiResponse<CustomTourResponse[]>>(
    `/admin/inspection/custom?month=${month}&year=${year}`
  );
};

export const removeInspectionSchedule = async (
  payload: RemoveInspectionSchedulePayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/inspection/remove_inspection_schedule`,
    payload
  );
};

export const getACustomTour = async (
  id: number,
  month?: number,
  year?: number
) => {
  return await request.get<ApiResponse<CustomTourResponse>>(
    `/admin/inspection/custom/${id}?month=${month}&year=${year}`
  );
};

export const getASiteTour = async (
  id: number,
  month?: number,
  year?: number
) => {
  return await request.get<ApiResponse<getSiteToursResponse>>(
    `/admin/inspection/project/${id}?month=${month}&year=${year}`
  );
};

export const customRemoveClient = async (
  payload: CustomRemoveClientPayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/inspection/custom/remove_client`,
    payload
  );
};

export const customRemoveSchedule = async (
  payload: CustomRemoveSchedulePayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/inspection/custom/remove_schedule`,
    payload
  );
};

export const customUpdateSchedule = async (
  payload: CustomUpdateSchedulePayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/inspection/custom/update_schedule`,
    payload
  );
};

export const customAddClients = async (payload: CustomAddClientsPayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/inspection/custom/add_clients`,
    payload
  );
};

export const requestCustomInspection = async (
  payload: RequestCustomInspectionPayload
) => {
  return await request.post<ApiResponse<string>>(
    `/inspection/request_custom`,
    payload
  );
};

export const approvedInspections = async () => {
  return await request.get<ApiResponse<SiteInspectionResponse[]>>(
    `/inspection/requests`
  );
};

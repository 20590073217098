import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";

interface props {
  label: string;
  url?: string;
  isActive?: boolean;
}

const NavLink: React.FC<props> = ({ label, url, isActive }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];

  const handleNavigate = (url?: string) => {
    if (url) {
      navigate(url);
    }
    return;
  };

  return (
    <Link
      onClick={() => handleNavigate(url)}
      isActive={isActive || url === path}
    >
      {label}
    </Link>
  );
};

export default NavLink;

interface linkProps {
  isActive?: boolean;
}

const Link = styled.div<linkProps>`
  font-weight: 450;
  font-size: 1.1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#1B1B1B")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  text-align: center;
`;

import React, { useEffect, useState } from "react";
import { FixedDate, SchedulesObj } from "app/api/site tour/types";
import styled from "styled-components";
import { Box } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useRemoveInspectionSchedule from "app/hooks/site tour/useRemoveInspectionSchedule";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import DialogModal from "../modals/DialogModal";
import { TourModalsRoute } from "../..";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as EditIcon } from "../../../../../images/admin/edit-icon.svg";
import useCustomRemoveClient from "app/hooks/site tour/useCustomRemoveClient";
import useGetACustomTour from "app/hooks/site tour/useGetACustomTour";
import useGetASiteTour from "app/hooks/site tour/useGetASiteTour";
import { useSearchParams } from "react-router-dom";

interface Props {
  clearSelectedTour: () => void;
  dataId?: number;
  selectedTab: number;
  selectedMonth?: number;
}

const DisplayToursDetails: React.FC<Props> = ({
  dataId,
  clearSelectedTour,
  selectedTab,
  selectedMonth,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isActiveId, setIsActiveId] = useState<number>();
  const [openDialog, setOpenDialog] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [animating, setAnimating] = useState(false);

  const isCustom = selectedTab === 2 ? true : false;

  const { mutate: deleteMutate, isLoading: isDeleting } =
    useRemoveInspectionSchedule();
  const { mutate: deleteClientMutate, isLoading: isClientDeleting } =
    useCustomRemoveClient();

  const currentYear = new Date().getFullYear();

  const { data: singleSiteData } = useGetASiteTour(
    selectedTab === 1 && dataId ? dataId : 0,
    selectedMonth,
    currentYear
  );
  const { data: singleCustomData } = useGetACustomTour(
    selectedTab === 2 && dataId ? dataId : 0,
    selectedMonth,
    currentYear
  );

  const onActiveHandler = (id: number) => {
    if (id === isActiveId) {
      setIsActiveId(undefined);
    } else {
      setIsActiveId(id);
    }
  };

  const isDeletable = (e: any, data: SchedulesObj) => {
    e.stopPropagation();
    if (data.is_booked) setOpenDialog(true);
    else onDelete();
  };

  const onClientDelete = (e: any, id: number) => {
    e.stopPropagation();
    const payload = {
      id,
    };
    deleteClientMutate(payload, {
      onSuccess: () => {
        toast.success(`Client Deleted Successfully!`);
        if (singleCustomData.clients.length === 1) clearSelectedTour();
      },
    });
  };

  const dialogCloseHandler = () => {
    setOpenDialog(false);
  };

  const onDelete = (message?: string) => {
    if (!isActiveId) return;
    const payload = {
      id: isActiveId,
      message: message,
    };
    deleteMutate(payload, {
      onSuccess: () => {
        toast.success(`Tour Deleted Successfully!`);
        if (singleSiteData.schedules.length === 1) clearSelectedTour();
      },
    });
  };

  useEffect(() => {
    if (dataId) {
      setIsHidden(false);
      setTimeout(() => {
        setAnimating(true);
      }, 20);
    } else {
      setAnimating(false);
      setTimeout(() => {
        setIsHidden(true);
      }, 200);
    }
  }, [dataId]);

  return (
    <>
      <Box
        display={isHidden ? "none" : "flex"}
        flexDirection={"column"}
        gap={"10px"}
        flexShrink={"0"}
        padding={"10px"}
        width={"270px"}
        marginLeft={"auto"}
        bgcolor={"#ffffff"}
        boxShadow={
          "0px 0px 1px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #EEEEEE"
        }
        sx={{
          transform: animating ? "translateX(0)" : "translateX(30%)",
          opacity: animating ? 1 : 0,
          transition: "all 0.3s ease-in-out",
        }}
      >
        <ScheduleLabel>Site Tour Schedule</ScheduleLabel>
        {isCustom && (
          <DetailsCard>
            <FlexWrapper>
              <DetailsTitle>Schedule</DetailsTitle>
              <EditBtn
                onClick={() => {
                  searchParams.set("create", TourModalsRoute.customSiteTour);
                  searchParams.set("edit", singleCustomData.id.toString());
                  searchParams.set("page", "1");
                  setSearchParams(searchParams);
                }}
              >
                <EditIcon />
                edit
              </EditBtn>
            </FlexWrapper>

            {singleCustomData && (
              <ScheduleDate>
                {singleCustomData.date + ", " + singleCustomData.time}
              </ScheduleDate>
            )}
          </DetailsCard>
        )}

        {!isCustom && (
          <DetailsCard>
            <DetailsTitle>Schedule</DetailsTitle>

            {singleSiteData?.schedules?.map((ev) => (
              <ScheduleBox
                key={ev.id}
                isActive={isActiveId === ev.id}
                onClick={() => onActiveHandler(ev.id)}
              >
                <Cancel onClick={(e) => isDeletable(e, ev)} className="cancel">
                  {isDeleting && isActiveId === ev.id ? (
                    <Spinner color="#b3261e" width="13px" height="13px" />
                  ) : (
                    <HighlightOffIcon />
                  )}
                </Cancel>

                {ev.day.name === FixedDate
                  ? ev.day.name + ": " + ev.day.dates[0]
                  : ev.day.name}
              </ScheduleBox>
            ))}
          </DetailsCard>
        )}
        <DetailsCard>
          <DetailsTitle>Property</DetailsTitle>
          <DetailsContent>
            <PropertyWrapper>
              {(singleSiteData || singleCustomData)?.project}
            </PropertyWrapper>
          </DetailsContent>
        </DetailsCard>
        {!isCustom && (
          <DetailsCard>
            <DetailsTitle>Location</DetailsTitle>
            <LocationWrapper>
              {singleSiteData?.location + ", " + singleSiteData?.address}
            </LocationWrapper>
          </DetailsCard>
        )}
        <DetailsCard maxHeight={isCustom ? "60%" : "35%"}>
          <FlexWrapper>
            <DetailsTitle>
              Clients(
              {isCustom
                ? singleCustomData?.clients.length
                : singleSiteData?.clientsCount}
              )
            </DetailsTitle>
            {isCustom && (
              <EditBtn
                onClick={() => {
                  searchParams.set("create", TourModalsRoute.customSiteTour);
                  searchParams.set("edit", singleCustomData.id.toString());
                  searchParams.set("page", "2");
                  setSearchParams(searchParams);
                }}
              >
                <AddIcon />
                add
              </EditBtn>
            )}
          </FlexWrapper>

          <DetailsContent>
            {!isCustom &&
              singleSiteData?.clients.map((x, idx) => (
                <ClientsWrapper key={idx}>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo>Name:</ClientsInfo>
                    <ClientsInfo>{x.firstname + " " + x.lastname}</ClientsInfo>
                  </Box>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo>Package:</ClientsInfo>
                    <ClientsInfo color="#697f2f">{x.package}</ClientsInfo>
                  </Box>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo>Phone No:</ClientsInfo>
                    <ClientsInfo>{x.phone_number}</ClientsInfo>
                  </Box>
                </ClientsWrapper>
              ))}
            {isCustom &&
              singleCustomData?.clients.map((x, idx) => (
                <ClientsWrapper
                  key={idx}
                  isActive={isActiveId === x.id}
                  onClick={() => onActiveHandler(x.id)}
                >
                  <Cancel
                    onClick={(e) => onClientDelete(e, x.id)}
                    className="cancel"
                  >
                    {isClientDeleting && isActiveId === x.id ? (
                      <Spinner color="#b3261e" width="13px" height="13px" />
                    ) : (
                      <HighlightOffIcon />
                    )}
                  </Cancel>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo>Name:</ClientsInfo>
                    <ClientsInfo>{x.firstname + " " + x.lastname}</ClientsInfo>
                  </Box>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo isEmail>Email:</ClientsInfo>
                    <ClientsInfo color="#49B7E4">{x.email}</ClientsInfo>
                  </Box>
                  <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
                    <ClientsInfo>Phone No:</ClientsInfo>
                    <ClientsInfo>{x.phone_number}</ClientsInfo>
                  </Box>
                </ClientsWrapper>
              ))}
          </DetailsContent>
        </DetailsCard>
      </Box>
      {openDialog && (
        <DialogModal
          open={openDialog}
          handleClose={dialogCloseHandler}
          onSubmit={onDelete}
        />
      )}
    </>
  );
};

export default DisplayToursDetails;

const ScheduleLabel = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 5px;
`;

interface DetailsCardProps {
  maxHeight?: string;
}

const DetailsCard = styled.div<DetailsCardProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 16px 8px;
  gap: 12px;
  width: 249px;
  height: max-content;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "20%")};
  overflow-y: scroll;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 4px;
  text-transform: capitalize;
`;

const DetailsTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 4px;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #fbfbfb;
  width: 79px;
  height: 26px;
  background: #327f9e;
  border-radius: 4px;
`;

const DetailsContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const ScheduleDate = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #535353;
`;

interface ScheduleBoxProps {
  isActive: boolean;
}

const ScheduleBox = styled.div<ScheduleBoxProps>`
  width: 100%;
  position: relative;
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#97B644" : "#535353")};
  border-left: ${({ isActive }) => (isActive ? "3px solid #97B644" : "none")};
  padding-left: ${({ isActive }) => isActive && "0.3rem"};
  cursor: pointer;

  &:hover {
    color: #97b644;
    transition: all 0.2s ease-out;
  }

  .cancel {
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    right: ${({ isActive }) => (isActive ? "-15px" : "0")};
    transition: all 0.2s ease-out;
  }
`;

const PropertyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #535353;
  height: 26px;
  background: #d8e8b8;
  border-radius: 8px;
`;

const LocationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 4px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  background: #cde6f5;
  color: #143340;
  border-radius: 8px;
`;

interface ClientsWrapperProps {
  isActive?: boolean;
}

const ClientsWrapper = styled.div<ClientsWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 17px;
  gap: 12px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06);
  border-radius: 6px;
  border-left: ${({ isActive }) => (isActive ? "3px solid #97B644" : "none")};
  padding-left: ${({ isActive }) => isActive && "0.3rem"};
  cursor: ${({ isActive }) => isActive !== undefined && "pointer"};

  &:hover {
    transition: all 0.2s ease-out;
  }

  .cancel {
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    right: ${({ isActive }) => (isActive ? "-15px" : "0")};
    transition: all 0.2s ease-out;
  }
`;

const ClientsInfo = styled.p<{
  color?: string;
  width?: string;
  isEmail?: boolean;
}>`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  width: ${({ width }) => width && width};
  color: ${({ color }) => (color ? color : "#0b1d25")};
  text-transform: ${(isEmail) => isEmail && "none"};
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const EditBtn = styled.div`
  background: transparent;
  color: #afd34f;
  height: fit-content;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  &:hover {
    background: transparent;
    text-decoration: underline;
  }

  & > svg {
    color: #afd34f;
    fill: #afd34f;
    width: 15px;
    height: 15px;
  }
`;

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

import useAuth from "app/hooks/auth/useAuth";
import { UserRoutes } from "app/utils/data";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { AnimatePresence, motion } from "framer-motion";
import { useTabletCheck } from "app/hooks/useTabletCheck";
import { mediaObj } from "styles/utils/media";

const ClientLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isLoggedIn = useAuth();
  const isTab = useTabletCheck();

  useEffect(() => {
    !isLoggedIn && navigate("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    pathname === UserRoutes.Root && navigate(UserRoutes.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <AnimatePresence exitBeforeEnter>
      <Container>
        {!isTab && <Sidebar />}
        <LeftContainer key={pathname}>
          <Header />
          <ContentWrapper>
            <motion.div
              initial={{
                opacity: 0,
                x: 100,
              }}
              exit={{
                opacity: 0,
                y: 200,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              transition={{ duration: 0.6 }}
            >
              <Outlet />
            </motion.div>
          </ContentWrapper>
        </LeftContainer>
      </Container>
    </AnimatePresence>
  );
};

export default ClientLayout;

const Container = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  background: #f5f5f5;
`;

const LeftContainer = styled.div`
  width: calc(100vw - 210px);
  margin-left: 210px;
  margin-top: 90px;
  padding: 36px 36px 20px 24px;
  background: transparent;

  ${mediaObj.smallDesktop} {
    margin-left: 0;
    width: 100%;
    padding: 10px 12px;
    overflow: hidden;
  }
`;

const ContentWrapper = styled.div`
  width: clamp(900px, 96%, 1400px);

  ${mediaObj.smallDesktop} {
    width: 100%;
  }
`;

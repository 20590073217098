import BodyField from "app/views/Admin/Community/components/BodyField";
import CustomModal from "app/views/Admin/components/modal";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { options } from "app/api/staff/types";

interface props {
  handleClose: () => void;
  handleSubmit: (option: options) => void;
  isOpen: boolean;
  option: options;
  width: string;
}

const EditOption: FC<props> = ({
  handleClose,
  handleSubmit,
  isOpen,
  width,
  option,
}) => {
  const [value, setValue] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (option.value) {
      const blocksFromHtml = htmlToDraft(option.value);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setValue(EditorState.createWithContent(contentState));
    }
  }, [option]);

  const onSubmit = () => {
    const payload: options = {
      ...option,
      value: draftToHtml(convertToRaw(value.getCurrentContent())),
    };
    handleSubmit(payload);
    handleClose();
  };

  const isValid =
    value.getCurrentContent().hasText() &&
    value.getCurrentContent().getPlainText() !== "";

  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose} width={width}>
      <Wrapper>
        <Formik
          initialValues={{
            option: option,
          }}
          onSubmit={() => {
            onSubmit();
          }}
        >
          <Form>
            <BodyField
              name={"option"}
              label={``}
              value={value}
              onChange={setValue}
              placeholder={"Enter option"}
              fullWidth
            />
            <SaveBtn disabled={!isValid}>Save</SaveBtn>
          </Form>
        </Formik>
      </Wrapper>
    </CustomModal>
  );
};

export default EditOption;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  .rdw-editor-main {
    min-height: 75px;
  }
`;

const SaveBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 17px;
  color: #fbfbfb;
  margin-top: 20px;
  margin-left: auto;
  height: 48px;
  background: #49b7e4;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

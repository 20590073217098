import { uploadPaymentEvidence } from "app/api/payment";
import { FileType } from "app/api/project/types";
import useBankOrderPayment from "app/hooks/payment/useBankOrderPayment";
import useBankPayment, {
  useBankOfferPayment,
} from "app/hooks/payment/useBankPayment";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import useGetBankAccounts from "app/hooks/utilities/useGetBankAccounts";
import { SingleOption } from "app/types/common";
import { parseError } from "app/utils";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import Spinner from "app/views/Web/components/Spinner";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import NumberFormatter from "../NumberFormatter";
import ImageUploadBox from "./ImageUploadBox";
import { ConfirmPaymentValidation } from "./validation";

export interface PreviewContentProps {
  name: string;
  content: string;
  type: FileType;
  format?: string;
  size?: string;
}

interface Props {
  onNext: () => void;
  packageItemId: number;
  amountToPay: number;
  isInstallment?: boolean;
  isReoccuring?: boolean;
  order_id?: number;
  isOfferPayment?: boolean;
  offer_id?: number;
  bid_id?: number;
  plotUnit?: number;
}

const ConfirmPayment: React.FC<Props> = ({
  onNext,
  amountToPay,
  isInstallment,
  packageItemId,
  isReoccuring,
  order_id,
  isOfferPayment,
  offer_id,
  bid_id,
  plotUnit,
}) => {
  const [amountPaid, setAmountPaid] = useState("");
  const [referenceNo, setReferenceNo] = useState("");
  const [accountType, setAccountType] = useState<SingleOption>();
  const [date, setDate] = useState<Dayjs | null>(null);
  const [paymentType, setPaymentType] = useState<SingleOption>();
  const [mainFileId, setMainFileId] = useState<number>();

  const { data: bankAccounts, isLoading: isBankLoading } = useGetBankAccounts();
  const { mutate: saveBank, isLoading: isSaving } = useBankPayment();
  const { mutate: saveOfferBank, isLoading: isOfferSaving } =
    useBankOfferPayment();
  const { mutate: saveOrderBank, isLoading: isOrderSaving } =
    useBankOrderPayment();

  const isMobile = useMobileCheck();

  const formatBankAccounts = () => {
    return bankAccounts?.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.account_name + " (" + ev.account_number + ")",
      };
      return temp;
    });
  };

  useEffect(() => {
    if (isInstallment) {
      setPaymentType({
        value: 1,
        label: "Installment",
      });
    } else {
      setPaymentType({
        value: 0,
        label: "Full Payment",
      });
    }
  }, [isInstallment]);

  const onMainFileDelete = () => {
    setMainFileId(undefined);
  };

  const MainPhotoUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("photo", file);
      const res = await uploadPaymentEvidence(data);
      setMainFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  const onInCorrectAmount = () => {
    if (+amountPaid < amountToPay) {
      toast.error("Amount entered is lesser than amount to be paid", {
        autoClose: false,
      });
      return false;
    } else return true;
  };

  const initialPayment = () => {
    if (isOfferPayment) {
      if (
        !accountType ||
        !paymentType ||
        !mainFileId ||
        !amountToPay ||
        !offer_id ||
        !bid_id
      )
        return;
      const payload = {
        bank_account_id: accountType.value,
        offer_id: offer_id,
        bid_id: bid_id,
        amount: amountToPay,
        evidence_file_id: mainFileId,
        payment_date: dayjs(date).format("YYYY-MM-DD"),
        reference: referenceNo,
        bank: "string",
      };
      saveOfferBank(payload, {
        onSuccess: onNext,
      });
    } else {
      if (!accountType || !paymentType || !mainFileId) return;
      const payload = {
        bank_account_id: accountType.value,
        package_item_id: packageItemId,
        amount: +amountPaid,
        evidence_file_id: mainFileId,
        payment_date: dayjs(date).format("YYYY-MM-DD"),
        reference: referenceNo,
        installment: paymentType.value,
        units: plotUnit,
      };
      if (onInCorrectAmount()) {
        saveBank(payload, {
          onSuccess: onNext,
        });
      }
    }
  };

  const reoccuringPayment = () => {
    if (!accountType || !order_id || !mainFileId || !paymentType) return;
    const payload = {
      bank_account_id: accountType.value,
      order_id: order_id,
      amount: +amountPaid,
      evidence_file_id: mainFileId,
      payment_date: dayjs(date).format("YYYY-MM-DD"),
      reference: referenceNo,
      installment: paymentType.value,
    };
    if (onInCorrectAmount()) {
      saveOrderBank(payload, {
        onSuccess: onNext,
      });
    }
  };

  const onSubmit = () => {
    if (isReoccuring) {
      reoccuringPayment();
    } else {
      initialPayment();
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          accountType,
          amountPaid,
          date,
          mainFileId,
        }}
        validationSchema={ConfirmPaymentValidation}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
            <Wrapper>
              <SelectField
                name="accountType"
                label="Select Account Details You Paid Into"
                value={accountType as SingleOption}
                onChange={(e) => setAccountType(e as SingleOption)}
                options={formatBankAccounts()}
                selectType="normal"
                isLoading={isBankLoading}
                isRequired
              />
              <NumberFormatter
                formik={formik}
                name="amountPaid"
                label="Amount Paid"
                placeholder="Enter Amount Paid"
                onChange={(value) => setAmountPaid(value)}
              />
              <InputField
                name="referenceNo"
                label="Reference Number"
                placeholder="Enter Reference Number"
                value={referenceNo}
                onChange={(e) => setReferenceNo(e.target.value)}
              />
              <DatePickerField
                name="date"
                label="Payment Date"
                placeholder="Select Date"
                value={date}
                onChange={(e) => setDate(e)}
                isRequired
              />
              <SelectField
                name="paymentType"
                label="Payment Type"
                value={paymentType as SingleOption}
                onChange={() => {}}
                options={[]}
                selectType="normal"
                disabled
              />

              <ImageUploadBox
                name="mainFileId"
                imageBoxText={
                  isMobile
                    ? "Upload Proof (PNG, JPEG)"
                    : "Upload Proof of Payment (PNG, JPEG)"
                }
                photoPostRequest={MainPhotoUploader}
                onSingleFileDelete={onMainFileDelete}
              />
            </Wrapper>

            <CreateBtn type="submit">
              {(isSaving || isOrderSaving || isOfferSaving) && (
                <Spinner color="black" />
              )}
              Confirm Payment
            </CreateBtn>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmPayment;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  padding: 0.5rem;
`;

const CreateBtn = styled.button`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin: auto;
  margin-top: 40px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

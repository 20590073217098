import React, { useEffect, useState } from "react";
import { SingleOption } from "app/types/common";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import {
  CreateBtn,
  ErrorText,
  FlexWrapper,
  FormWrapper,
  SpinnerWrapper,
} from "../styles";
import { CreateOfferValidation, EditOfferValidation } from "../validation";
import {
  useAdminCreatePromo,
  useAdminUpdatePromo,
} from "app/hooks/community/usePromo";
import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import {
  AdminCreatePromoPayload,
  PromoCategoryObj,
  PromoPackageObj,
  PromoProjectLocationObj,
  PromoProjectObj,
} from "app/api/offers/types";
import { useSearchParams } from "react-router-dom";
import { useGetAPromo } from "app/hooks/community/useGetPromos";

const OffersSection = () => {
  const [categoryType, setCategoryType] = useState<SingleOption[]>([]);
  const [projectType, setProjectType] = useState<SingleOption[]>([]);
  const [location, setLocation] = useState<SingleOption[]>([]);
  const [packages, setPackages] = useState<SingleOption[]>([]);
  const [title, setTitle] = useState("");
  const [promoDiscount, setPromoDiscount] = useState("");
  const [offerDescription, setOfferDescription] = useState("");
  const [promoStartDate, setPromoStartDate] = useState<Dayjs | null>(null);
  const [promoEndDate, setPromoEndDate] = useState<Dayjs | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const editPromo = searchParams.get("edit_promo");

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType[0]?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType[0]?.value as number);

  const { data: packagePerLocationData, isLoading: packagePerLocationLoading } =
    useGetAllPackagePerLocation(location[0]?.value as number);

  const { mutate: createMutate, isLoading: isCreating } = useAdminCreatePromo();
  const { mutate: updateMutate, isLoading: isUpdating } = useAdminUpdatePromo();
  const {
    data: promoData,
    isFetching: promoFetching,
    isError,
  } = useGetAPromo(Number(editPromo));

  useEffect(() => {
    if (editPromo && promoData) return;
    setProjectType([]);
    setLocation([]);
  }, [categoryType, promoData, editPromo]);

  useEffect(() => {
    if (editPromo && promoData) return;
    setLocation([]);
    setPackages([]);
  }, [projectType, promoData, editPromo]);

  useEffect(() => {
    if (editPromo && promoData) return;
    setPackages([]);
  }, [location, promoData, editPromo]);

  useEffect(() => {
    if (editPromo && promoData) {
      setTitle(promoData.title);
      setPromoDiscount(promoData.discount);
      setPromoStartDate(dayjs(promoData.start));
      setPromoEndDate(dayjs(promoData.end));
      setOfferDescription(promoData.description);

      if (promoData.products[0].type === "category") {
        const catResult = (promoData.products as PromoCategoryObj[]).map(
          (ev) => ({
            label: ev.product.name,
            value: ev.product.id,
          })
        );
        setCategoryType(catResult);
      }

      if (promoData.products[0].type === "project") {
        const catResult = promoData.products[0].product;
        const proResult = (promoData.products as PromoProjectObj[]).map(
          (ev) => ({
            label: ev.product.name,
            value: ev.product.id,
          })
        );
        setCategoryType([
          {
            label: catResult.category,
            value: catResult.category_id,
          },
        ]);
        setProjectType(proResult);
      }

      if (promoData.products[0].type === "project_location") {
        const catResult = promoData.products[0].product;
        const locResult = (promoData.products as PromoProjectLocationObj[]).map(
          (ev) => ({
            label: ev.product.location,
            value: ev.product.id,
          })
        );
        setCategoryType([
          {
            label: catResult.category,
            value: catResult.category_id,
          },
        ]);
        setProjectType([
          {
            label: catResult.project,
            value: +catResult.project_id,
          },
        ]);
        setLocation(locResult);
      }

      if (promoData.products[0].type === "package") {
        const catResult = promoData.products[0].product;
        const proResult = (promoData.products as PromoPackageObj[]).map(
          (ev) => ({
            label: ev.product.name,
            value: ev.product.id,
          })
        );
        setCategoryType([
          {
            label: catResult.category,
            value: catResult.category_id,
          },
        ]);
        setProjectType([
          {
            label: catResult.project,
            value: catResult.project_id,
          },
        ]);
        setLocation([
          {
            label: catResult.location,
            value: +catResult.project_location_id,
          },
        ]);
        setPackages(proResult);
      }
    }
  }, [promoData, editPromo]);

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  const onSuccess = () => {
    toast.success(`Promo ${editPromo ? "Updated" : "Created"} Successfully!`);
    setTimeout(() => {
      const y = searchParams.get("activeTab");
      y && setSearchParams({ activeTab: y });
    }, 1500);
  };

  const onSubmit = () => {
    if (!categoryType) return;

    const formattedCaegory = categoryType.map((ev) => ({
      product_id: ev.value,
    }));
    const formattedProject = projectType.map((ev) => ({
      product_id: ev.value,
    }));

    const formattedLocation = location.map((ev) => ({
      product_id: ev.value,
    }));

    const formattedPackage = packages.map((ev) => ({
      product_id: ev.value,
    }));

    let temp: AdminCreatePromoPayload = {
      title,
      discount: Number(promoDiscount),
      start: dayjs(promoStartDate).format("YYYY-MM-DD"),
      end: dayjs(promoEndDate).format("YYYY-MM-DD"),
      description: offerDescription,
      categories: formattedCaegory,
    };

    if (formattedProject.length > 0) {
      temp = {
        ...temp,
        projects: formattedProject,
      };
    }
    if (formattedLocation.length > 0) {
      temp = {
        ...temp,
        project_locations: formattedLocation,
      };
    }
    if (formattedPackage.length > 0) {
      temp = {
        ...temp,
        packages: formattedPackage,
      };
    }

    if (editPromo) {
      const payload = {
        ...temp,
        promo_id: +editPromo,
      };
      updateMutate(payload, {
        onSuccess,
      });
    } else {
      createMutate(temp, {
        onSuccess,
      });
    }
  };

  return (
    <Formik
      initialValues={{
        title,
        categoryType,
        projectType,
        location,
        packages,
        promoDiscount,
        promoStartDate,
        promoEndDate,
        offerDescription,
      }}
      validationSchema={editPromo ? EditOfferValidation : CreateOfferValidation}
      enableReinitialize
      onSubmit={onSubmit}
    >
      <FormWrapper>
        {editPromo && promoFetching && (
          <SpinnerWrapper>
            <Spinner color="black" />
          </SpinnerWrapper>
        )}
        {editPromo && isError && (
          <SpinnerWrapper>
            <ErrorText>An Error Occured</ErrorText>
          </SpinnerWrapper>
        )}
        {(!editPromo || (editPromo && !isError && !promoFetching)) && (
          <Form>
            <InputField
              name="title"
              label="Title"
              placeholder="Type promo title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              isRequired
            />
            <FlexWrapper>
              <SelectField
                name="categoryType"
                label="Category"
                value={categoryType as SingleOption[]}
                onChange={(e) => setCategoryType(e as SingleOption[])}
                options={formatData(allCategoryData)}
                selectType="normal"
                isLoading={allCategoryLoading}
                isRequired
                isMulti
                height={"unset"}
                minH={56}
                // disabled={!!editData}
              />
              {categoryType?.length <= 1 && (
                <SelectField
                  name="projectType"
                  label="Project"
                  value={projectType as SingleOption[]}
                  onChange={(e) => setProjectType(e as SingleOption[])}
                  disabled={!categoryType}
                  options={formatData(projectPerCategoryData)}
                  selectType="normal"
                  isLoading={projectPerCategoryLoading}
                  isMulti
                  height={"unset"}
                  minH={56}
                />
              )}
            </FlexWrapper>

            <>
              {categoryType?.length <= 1 && projectType?.length <= 1 && (
                <FlexWrapper>
                  <SelectField
                    name="location"
                    label="Location"
                    value={location as SingleOption[]}
                    onChange={(e) => setLocation(e as SingleOption[])}
                    disabled={!projectType}
                    options={formatData(locationPerProjectData)}
                    selectType="normal"
                    isLoading={locationPerProjectLoading}
                    isMulti
                    height={"unset"}
                    minH={56}
                  />
                  {location?.length <= 1 && (
                    <SelectField
                      name="packages"
                      label="Package"
                      value={packages as SingleOption[]}
                      onChange={(e) => setPackages(e as SingleOption[])}
                      disabled={!location}
                      options={formatData(packagePerLocationData)}
                      selectType="normal"
                      isLoading={packagePerLocationLoading}
                      isMulti
                      height={"unset"}
                      minH={56}
                    />
                  )}
                </FlexWrapper>
              )}
            </>
            <InputField
              name="promoDiscount"
              label="Promo Discount(%)"
              placeholder="Type Promo Discount"
              value={promoDiscount}
              onChange={(e) => setPromoDiscount(e.target.value)}
              isRequired
            />

            <FlexWrapper>
              <DatePickerField
                name="promoStartDate"
                label="Promo Start Date"
                placeholder="Select Date"
                value={promoStartDate}
                onChange={(e) => setPromoStartDate(e)}
                isRequired
              />
              <DatePickerField
                name="promoEndDate"
                label="Promo End Date"
                placeholder="Select Date"
                value={promoEndDate}
                onChange={(e) => setPromoEndDate(e)}
                isRequired
              />
            </FlexWrapper>

            <InputField
              name="offerDescription"
              label="Offer Description"
              placeholder="Type Offer Description*"
              value={offerDescription}
              onChange={(e) => setOfferDescription(e.target.value)}
              inputType="textarea"
              isRequired
            />

            <CreateBtn type="submit">
              {(isCreating || isUpdating) && <Spinner color="black" />}
              {editPromo ? "Update" : "Create"}
            </CreateBtn>
          </Form>
        )}
      </FormWrapper>
    </Formik>
  );
};

export default OffersSection;

import { useQuery } from "@tanstack/react-query";
import { getStaffClients } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useGetAllStaffClients = () => {
  return useQuery([UserKeys.getAllStaffClients], getStaffClients, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetAllStaffClients;

import { useMutation } from "@tanstack/react-query";
import { bankOfferPayment, bankPayment } from "app/api/payment";
import { parseError } from "app/utils";

const useBankPayment = () => {
  return useMutation(bankPayment, {
    onError: (error) => parseError(error),
  });
};

export default useBankPayment;

export const useBankOfferPayment = () => {
  return useMutation(bankOfferPayment, {
    onError: (error) => parseError(error),
  });
};

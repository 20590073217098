import { useQuery } from "@tanstack/react-query";
import { adminGetAllAdmins } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useAdminGetAllAdmins = (page: number, perPage: number = 9) => {
  return useQuery(
    [UserKeys.adminGetAllAdmins, page],
    () => adminGetAllAdmins(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetAllAdmins;

import { useQuery } from "@tanstack/react-query";
import { getProjectCount } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useGetProjectCount = () => {
  return useQuery([ProjectsKey.getProjectCount], getProjectCount, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetProjectCount;

import { useQuery } from "@tanstack/react-query";
import { getCommunityOffers } from "app/api/community";
import { adminGetAllOffer } from "app/api/offers";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useAdminGetOffers = () => {
  return useQuery(
    [CommunityQueryKeys.adminGetOffers],
    () => adminGetAllOffer(),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useAdminGetOffers;

export const useCommunityGetOffers = () => {
  return useQuery(
    [CommunityQueryKeys.communityGetOffers],
    () => getCommunityOffers(),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

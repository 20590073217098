import { CommunityContentsProps } from "app/views/Admin/Community/data";

export const communityTabs = [
  {
    name: "News",
    id: CommunityContentsProps.news,
  },
  {
    name: "Promo",
    id: CommunityContentsProps.promo,
  },
  {
    name: "Offers",
    id: CommunityContentsProps.offers,
  },
  {
    name: "Events",
    id: CommunityContentsProps.events,
  },
  {
    name: "Forums",
    id: CommunityContentsProps.forum,
  },
  {
    name: "Blog",
    id: CommunityContentsProps.blogPost,
  },
];

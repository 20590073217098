import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePackage, deleteProjectLocation } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useDeleteProjectLocation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteProjectLocation, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.allProjectPerCatgory]);
    },
  });
};

export default useDeleteProjectLocation;

export const useDeletePackage = () => {
  const queryClient = useQueryClient();

  return useMutation(deletePackage, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.singlePackage]);
    },
  });
};

import { useMutation } from "@tanstack/react-query";
import { adminSetPassmark } from "app/api/staff";
import { parseError } from "app/utils";

const useAdminSetCutOff = () => {
  return useMutation(adminSetPassmark, {
    onError: (error) => parseError(error),
    onSuccess: () => {},
  });
};

export default useAdminSetCutOff;

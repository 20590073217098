import { useQuery } from "@tanstack/react-query";
import { getRoles } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetRoles = () => {
  return useQuery([UtilitiesKeys.getRoles], getRoles, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetRoles;

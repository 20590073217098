import { useGetAllBid } from "app/hooks/offers/useBid";
import Spinner from "app/views/Web/components/Spinner";
import React from "react";
import SalesBidCard from "./cards/SalesBidCard";
import { CardWrapper, NoProjectText, SpinnerWrapper } from "./styles";

const MyBids = () => {
  const { data, isLoading, isError } = useGetAllBid();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>You have no bid yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <CardWrapper>
      {data &&
        data.map((ev, i) => <SalesBidCard key={ev.id} data={ev} isBid />)}
    </CardWrapper>
  );
};

export default MyBids;

import React from "react";
import useGetCustomTours from "app/hooks/site tour/useGetCustomTours";
import Spinner from "app/views/Web/components/Spinner";
import UpcomingTourCard from "../../components/Cards/UpcomingTourCard";
import styled from "styled-components";

const DisplayCustomTours = () => {
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear() - 1;

  const {
    data: customData,
    isLoading: customLoading,
    isError: customIsError,
  } = useGetCustomTours(month, year);

  if (customLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!customLoading && customIsError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (customData && customData.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>No scheduled site tour request</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {customData &&
        customData.map((x, i) => <UpcomingTourCard data={x} key={i} />)}
    </>
  );
};

export default DisplayCustomTours;

const NoProjectText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:nth-child(1) {
    margin-top: 40px;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { getAssessments } from "app/api/assessment";
import { parseError } from "app/utils";
import { AssessmentKeys } from "./types";

const useGetAllAssessments = () => {
  return useQuery([AssessmentKeys.getAllAssessment], getAssessments, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetAllAssessments;

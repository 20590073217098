import * as React from "react";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import styled from "styled-components";
import { useField } from "formik";
import { ThemeProvider, createTheme } from "@mui/material/styles";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  value: Dayjs | null;
  onChange: (ev: Dayjs | null) => void;
  isRequired?: boolean;
}

const TimePickerField: React.FC<Props> = ({
  label,
  placeholder,
  value,
  isRequired,
  onChange,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  const themeGreen = createTheme({ palette: { primary: { main: "#AFD34F" } } });

  return (
    <Container noLabel={!!label}>
      <label htmlFor={props.name} className={errorClass}>
        {label || props.name}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={themeGreen}>
          <TimePicker
            className="time-picker"
            value={value}
            onChange={(newValue) => {
              onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...field}
                {...props}
                sx={{
                  input: {
                    fontFamily: `'Circular Std', sans-serif`,
                    fontWeight: 450,
                    fontSize: "16px",
                    color: "#000000",
                  },
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: `${placeholder}`,
                }}
              />
            )}
          />
        </ThemeProvider>
      </LocalizationProvider>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Container>
  );
};

export default TimePickerField;

interface ContainerProps {
  noLabel?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    font-family: "Circular Std", sans-serif;
    font-weight: 450;
    font-size: 16px;
    color: #000000;
  }

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;
    visibility: ${({ noLabel }) => !noLabel && "hidden"};

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }
  & > div:nth-child(1) {
    width: 100%;
  }

  .time-picker {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    height: 3.5rem;
  }

  .MuiOutlinedInput-root {
    & > fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-root:hover {
    & > fieldset {
      border: none;
    }
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

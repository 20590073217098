import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ClientsPerPackageRes } from "app/api/project/types";
import { abbreviateNumber } from "app/utils";
import React, { useRef } from "react";
import styled from "styled-components";
import InstallmentRenderer from "./InstallmentRenderer";

type TableField = keyof ClientsPerPackageRes;

export interface GridDef extends ColDef {
  field: TableField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: ClientsPerPackageRes[];
}

interface Props {
  data: ClientsPerPackageRes[];
}

const ClientTable: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: ClientsPerPackageRes[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "SQM",
      field: "size",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 0.4,
    },
    {
      headerName: "Client’s Name",
      field: "client",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
      },
    },
    {
      headerName: "Price",
      field: "price",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientsPerPackageRes } = params;
        return `${abbreviateNumber(data.price)}`;
      },
    },
    {
      headerName: "Payment Type",
      field: "installment",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      cellRendererFramework: InstallmentRenderer,
    },
    {
      headerName: "Amount Paid",
      field: "amount_paid",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
        color: "#809A39",
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientsPerPackageRes } = params;
        return `${abbreviateNumber(data.amount_paid)}`;
      },
    },
    {
      headerName: "Amount Remaining",
      field: "balance",
      sortable: false,
      flex: 0.9,
      cellStyle: {
        ...gridCellStyle,
        color: "#B3261E",
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientsPerPackageRes } = params;
        return `${
          abbreviateNumber(data.balance) === "0"
            ? "-"
            : abbreviateNumber(data.balance)
        }`;
      },
    },
    {
      headerName: "Payment Due Date",
      field: "payment_due_date",
      sortable: false,
      flex: 0.9,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientsPerPackageRes } = params;
        return new Intl.DateTimeFormat("en-US").format(
          new Date(data.payment_due_date)
        );
      },
    },
    {
      headerName: "Purchase Date",
      field: "purchase_date",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientsPerPackageRes } = params;
        return new Intl.DateTimeFormat("en-US").format(
          new Date(data.purchase_date)
        );
      },
      flex: 0.7,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <>
      {GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className="ag-theme-alpine">
          <AgGridReact
            rowClass={rowClass}
            getRowClass={getRowClass}
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={43}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            // getRowNodeId={(data: ClientsPerPackageRes) => {
            //   return data.id.toString();
            // }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>You have no recent sales</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default ClientTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "16px",
  "line-height": "140%",
};

const GridWrapper = styled.div`
  height: 300px;
  width: 100%;
  margin-bottom: 10px;
  overflow-y: scroll;

  .my-white-class {
    background-color: #eaf4fb;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  & * {
    overflow: visible !important;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

import React, { useEffect, useState } from "react";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import TimePickerField from "app/views/Admin/components/formik inputs/TimePickerField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { ContentTypes } from "../..";
import CreateWrapper from "../CreateWrapper";
import {
  CreateBtn,
  ErrorText,
  FlexWrapper,
  FormWrapper,
  SpinnerWrapper,
} from "../styles";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CreateForumValidation } from "../validation";
import AddIcon from "@mui/icons-material/Add";
import CoverImage from "../CoverImage";
import useGetForumByTitle from "app/hooks/community/useGetForumByTitle";
import useCreateForum from "app/hooks/community/useCreateForum";
import useUpdateForum from "app/hooks/community/useUpdateForum";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";

const CreateForum = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [forumArray, setForumArray] = useState<string[]>([""]);
  const [coverImageId, setCoverImageId] = useState<number>();
  const [forumStartDate, setForumStartDate] = useState<Dayjs | null>(null);
  const [forumStartTime, setForumStartTime] = useState<Dayjs | null>(null);
  const editTitle = searchParams.get("edit");
  const {
    data: forumData,
    isFetching: forumFetching,
    isError,
  } = useGetForumByTitle(editTitle as string);
  const { mutate: createMutate, isLoading: isPosting } = useCreateForum();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateForum();

  const onSuccess = () => {
    toast.success(`Forum ${editTitle ? "Updated" : "Created"} Successfully!`);
    setTimeout(() => {
      const y = searchParams.get("activeTab");
      y && setSearchParams({ activeTab: y });
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!coverImageId) return;
    const payload = {
      title,
      file_id: coverImageId,
      description,
      start_date: dayjs(forumStartDate).format("YYYY-MM-DD"),
      start_time: dayjs(forumStartTime).format("hh:mm A"),
      links: forumArray,
    };
    if (editTitle) {
      if (!forumData) return;
      const result = {
        ...payload,
        forum_id: forumData.id,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editTitle && forumData) {
      const { title, description, cover_photo, links, start_date, start_time } =
        forumData;
      setTitle(title);
      setDescription(description);
      setCoverImageId(cover_photo.id);
      const formattedLinks = links.map((link) => link.link);
      setForumArray(formattedLinks);
      setForumStartDate(dayjs(start_date));
      setForumStartTime(dayjs(start_time, "hh:mm A"));
    }
  }, [forumData, editTitle]);

  const addMoreForumLinks = () => {
    setForumArray((oldArray) => [...oldArray, ""]);
  };

  const cancelForumLink = (index: number) => {
    setForumArray([
      ...forumArray.slice(0, index),
      ...forumArray.slice(index + 1),
    ]);
  };

  return (
    <CreateWrapper
      open={createType === ContentTypes.forum}
      title={`${editTitle ? "Update" : "Create"}  Forum`}
    >
      <Formik
        initialValues={{
          title,
          description,
          forumStartDate,
          forumStartTime,
          forumArray,
          coverImageId,
        }}
        validationSchema={CreateForumValidation}
        enableReinitialize
        onSubmit={onSubmitHandler}
      >
        <FormWrapper>
          {editTitle && forumFetching && (
            <SpinnerWrapper>
              <Spinner color="black" />
            </SpinnerWrapper>
          )}
          {editTitle && isError && (
            <SpinnerWrapper>
              <ErrorText>An Error Occured</ErrorText>
            </SpinnerWrapper>
          )}
          {(!editTitle || (editTitle && !isError && !forumFetching)) && (
            <Form>
              <InputField
                name="title"
                label="Title"
                placeholder="Type event title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                isRequired
              />
              <InputField
                name="description"
                label="Description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputType="textarea"
                isRequired
              />
              <LinksWrapper>
                {forumArray.map((item, i) => (
                  <div key={i}>
                    {i + 1 > 1 && (
                      <Cancel onClick={() => cancelForumLink(i)}>
                        <HighlightOffIcon />
                      </Cancel>
                    )}
                    <InputField
                      name={`forumArray[${i}]`}
                      label={`Forum Link ${
                        forumArray.length === 1 ? "" : i + 1
                      }`}
                      placeholder="600"
                      value={item}
                      onChange={(e) => {
                        item = e.target.value;
                        setForumArray([
                          ...forumArray.slice(0, i),
                          item,
                          ...forumArray.slice(i + 1),
                        ]);
                      }}
                      isRequired
                    />
                  </div>
                ))}
                <button type="button" onClick={addMoreForumLinks}>
                  <AddIcon /> Add Another Link
                </button>
              </LinksWrapper>

              <FlexWrapper>
                <DatePickerField
                  name="forumStartDate"
                  label="Forum starts"
                  placeholder="Select Date"
                  value={forumStartDate}
                  onChange={(e) => setForumStartDate(e)}
                  isRequired
                />
                <TimePickerField
                  name="forumStartTime"
                  label=""
                  placeholder="Select Time"
                  value={forumStartTime}
                  onChange={(e) => setForumStartTime(e)}
                />
              </FlexWrapper>

              <CoverImage
                setStateFn={setCoverImageId}
                value={
                  editTitle && forumData
                    ? forumData?.cover_photo.url
                    : undefined
                }
              />

              <CreateBtn type="submit">
                {(isPosting || isUpdating) && <Spinner color="black" />}
                {editTitle ? "Update" : "Create"}
              </CreateBtn>
            </Form>
          )}
        </FormWrapper>
      </Formik>
    </CreateWrapper>
  );
};

export default CreateForum;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: 5px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div {
    position: relative;
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #afd34f;
    width: max-content;
    border: none;
    background-color: transparent;
    cursor: pointer;

    & > svg {
      margin-right: 6px;
    }

    &:hover {
      color: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

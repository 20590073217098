import { useQuery } from "@tanstack/react-query";
import { getForumByTitle } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useGetForumByTitle = (title: string) => {
  return useQuery(
    [CommunityQueryKeys.getForumByTitle],
    () => getForumByTitle(title),
    {
      enabled: !!title,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetForumByTitle;

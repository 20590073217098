import { useMutation } from "@tanstack/react-query";
import { adminGetProjectSales } from "app/api/sales";
import { parseError } from "app/utils";

const useAdminGetProjSales = () => {
  return useMutation(adminGetProjectSales, {
    onError: (error) => parseError(error),
  });
};

export default useAdminGetProjSales;

import { Box } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import { upcomingEventsProp, upcomingEventsTab } from "../data";
import DisplayCustomTours from "./DisplayCustomTours";
import DisplayEvents from "./DisplayEvents";

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState<string>(upcomingEventsTab[0].value);

  return (
    <Container>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Title>Upcoming Events</Title>
        <SeeAll
          onClick={() =>
            tabValue === upcomingEventsProp.tour
              ? navigate("/admin/tour")
              : navigate("/admin/community")
          }
        >
          See all
        </SeeAll>
      </Box>
      <TabsWrapper>
        {upcomingEventsTab.map((x, i) => (
          <Tab
            key={i}
            isActive={x.value === tabValue}
            onClick={() => setTabValue(x.value)}
          >
            {x.label}
          </Tab>
        ))}
      </TabsWrapper>
      <CardWrapper>
        {tabValue === upcomingEventsProp.tour && <DisplayCustomTours />}
        {tabValue === upcomingEventsProp.community && <DisplayEvents />}
      </CardWrapper>
    </Container>
  );
};

export default UpcomingEvents;

const Container = styled.div`
  width: 47%;
  max-width: 540px;
  height: 330px;
  padding: 20px 15px 15px;
  background: #fff;
  border-radius: 8px;

  ${mediaObj.mediumDesktop} {
    width: 48.5%;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const SeeAll = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  height: 26px;
  font-weight: 450;
  font-size: 13px;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#535353")};
  background: ${({ isActive }) => (isActive ? "#AFD34F" : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 5px 2px;
  margin-top: 16px;
  max-width: 100%;
  overflow-x: scroll;
`;

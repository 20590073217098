import { labelValueType } from "app/types/common";
import { FC } from "react";
import Select, { ActionMeta } from "react-select";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface props {
  label: string;
  options: labelValueType<string, string>[];
  handleChange: (value: string, name: string) => void;
  width?: string;
  name: string;
  value?: any;
}

const CustomSelect: FC<props> = ({
  handleChange,
  label,
  options,
  width,
  name,
  value,
}) => {
  const onTypeChange = (newValue: any, actionMeta: ActionMeta<any>) => {
    handleChange(newValue.value, name);
  };

  return (
    <Container width={width}>
      <Label>{label}</Label>
      <Select
        options={options}
        onChange={onTypeChange}
        name={name}
        value={value}
      />
    </Container>
  );
};

export default CustomSelect;

interface containerProps {
  width?: string;
}

const Container = styled.div<containerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${({ width }) => (width ? width : "100%")};

  .css-1s2u09g-control {
    height: 55px;
    cursor: pointer;
  }

  .css-1pahdxg-control {
    height: 55px;
  }

  ${mediaQueries.mobile} {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
      height: 48px;
    }
  }
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

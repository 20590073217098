import React, { useEffect, useState } from "react";
import { capitalize } from "lodash";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { salesStatusData } from "../data";
import AcceptedSalesOffer from "./ApprovedSalesOffer";
import PendingSalesOffer from "./PendingSalesOffer";
import RejectedSalesOffer from "./RejectedSalesOffer";
import { useSearchParams } from "react-router-dom";

const DisplaySalesOffer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const statusParam = searchParams.get("status");
  const [selectedTab, setSelectedTab] = useState<number>(1);

  useEffect(() => {
    if (!statusParam) {
      searchParams.set("status", salesStatusData[0].name);
      setSearchParams(searchParams);
    }
  }, [setSearchParams, statusParam, searchParams]);

  useEffect(() => {
    if (statusParam) {
      const result = salesStatusData.filter((ev) => ev.name === statusParam);
      setSelectedTab(result[0].id);
    }
  }, [statusParam]);

  return (
    <div>
      <TabsWrapper>
        {salesStatusData.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedTab}
            onClick={() => {
              setSelectedTab(x.id);
              searchParams.set("status", x.name);
              setSearchParams(searchParams);
            }}
          >
            {capitalize(x.name)}
          </Tab>
        ))}
      </TabsWrapper>
      {selectedTab === 1 && <AcceptedSalesOffer />}
      {selectedTab === 2 && <PendingSalesOffer />}
      {selectedTab === 3 && <RejectedSalesOffer />}
    </div>
  );
};

export default DisplaySalesOffer;

const TabsWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 47px;
  padding-bottom: 5px;

  @media (max-width: 1300px) {
    justify-content: unset;
    max-width: 100%;
    overflow-x: scroll;
    gap: 8px;
  }

  ${mediaQueries.mobile} {
    margin: 24px 0 30px;
  }
`;

export interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;
  height: 38px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#143340")};
  background: ${({ isActive }) => (isActive ? "#327F9E" : "#cde6f5")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;

  ${mediaQueries.mobile} {
    width: fit-content;
    font-size: 13px;
    flex-shrink: 0;
  }
`;

import * as Yup from "yup";

export const BioDataValidation = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  // phone_number: Yup.string().required("Required"),
  phone_number: Yup.string()
    .required("Required")
    .test("min_phonenumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  address: Yup.string().required("Required"),
  occupation: Yup.string().required("Required"),
});

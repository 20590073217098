import { useMutation } from "@tanstack/react-query";
import { searchClient } from "app/api/client";
import { parseError } from "app/utils";

const useSearchClient = () => {
  return useMutation(searchClient, {
    onError: (error) => parseError(error),
  });
};

export default useSearchClient;

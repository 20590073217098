import useAuth from "app/hooks/auth/useAuth";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import Header from "../components/Header";
import { AnimatePresence, motion } from "framer-motion";
import Sidebar from "../components/Sidebar";

const AdminLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const isLoggedIn = useAuth();

  // useEffect(() => {
  //   !isLoggedIn && navigate("/admin-login");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoggedIn]);

  useEffect(() => {
    pathname === "/staff" && navigate("/staff/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <AnimatePresence exitBeforeEnter>
      <Container>
        <Sidebar />
        <LeftContainer key={pathname}>
          <Header />
          <ContentWrapper>
            <motion.div
              initial={{
                opacity: 0,
                x: 100,
              }}
              exit={{
                opacity: 0,
                y: 200,
              }}
              animate={{
                opacity: 1,
                x: 0,
                y: 0,
              }}
              transition={{ duration: 0.55 }}
            >
              <Outlet />
            </motion.div>
          </ContentWrapper>
        </LeftContainer>
      </Container>
    </AnimatePresence>
  );
};

export default AdminLayout;

const Container = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  background: #f5f5f5;
`;

const LeftContainer = styled.div`
  width: calc(100vw - 210px);
  margin-left: 210px;
  margin-top: 90px;
  padding: 36px 36px 20px 24px;
  background: transparent;
`;

const ContentWrapper = styled.div`
  width: clamp(900px, 96%, 1200px);
`;

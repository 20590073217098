import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ClientPaymentHistoryRes } from "app/api/client/types";
import React, { useRef } from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

type TableField = keyof ClientPaymentHistoryRes | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: ClientPaymentHistoryRes[];
}

interface Props {
  data: ClientPaymentHistoryRes[];
}

const PaymentTable: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: ClientPaymentHistoryRes[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Property",
      field: "project",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#97B644",
        ...gridCellStyle,
      },
    },
    {
      headerName: "Package",
      field: "package",
      sortable: false,
      flex: 1.2,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      // autoHeight: true,
    },
    {
      headerName: "Amount paid",
      field: "amount",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: ClientPaymentHistoryRes } = params;
        return `NGN ${Number(data.amount).toLocaleString("en-US")}`;
      },
    },
    {
      headerName: "Channel’",
      field: "payment_type",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        textTransform: "capitalize",
        color: "#49B7E4",
        ...gridCellStyle,
      },
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <div>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowClass={rowClass}
          getRowClass={getRowClass}
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          rowHeight={60}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          // immutableData
          // getRowNodeId={(data: Data) => {
          //   return data.id.toString();
          // }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
    </div>
  );
};

export default PaymentTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 350px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  & * {
    overflow: visible !important;
  }

  ${mediaObj.tablet} {
    overflow-x: scroll;
    & > div {
      min-width: 700px;
    }
  }
`;

import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface Props {
  open: boolean;
  children: JSX.Element;
  handleClose: () => void;
}

const ModalWrapper: React.FC<Props> = ({ children, open, handleClose }) => {
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>
        <Wrapper>{children}</Wrapper>
      </Container>
    </Backdrop>
  );
};

export default ModalWrapper;

const Container = styled.div`
  min-width: 520px;
  padding: 25px 23px 40px 23px;
  position: relative;
  background-color: white;
  border-radius: 16px;
`;

const Wrapper = styled.div`
  max-height: 75vh;
  overflow-y: scroll;
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { adminGetAllStaff } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useAdminGetAllStaff = (page: number, perPage: number = 9) => {
  return useQuery(
    [UserKeys.adminGetAllStaff, page],
    () => adminGetAllStaff(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetAllStaff;

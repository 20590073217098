import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "app/api";
import { parseError } from "app/utils";
import { ClientQueryKeys } from "./types";

const useDeleteClient = () => {
  const queryClient = useQueryClient();

  return useMutation(api.clientService.deleteClient, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ClientQueryKeys.getAllClients]);
    },
  });
};

export default useDeleteClient;

import api from "app/api";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllItemPerPackage from "app/hooks/projects/useGetAllItemsPerPackage";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import { SingleOption } from "app/types/common";
import { formatPrice } from "app/utils";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import DualRing from "app/views/Web/components/loader";
import { Form, Formik } from "formik";
import { capitalize } from "lodash";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import CustomModal from "../../../components/modal";
import { paymentOptionEnum, paymentOptions } from "../../data";
import {
  clientsToPackageValidation,
  inStallmentClientsToPackageValidation,
} from "../validation";

interface props {
  handleClose: () => void;
  isOpen: boolean;
  clientId: number;
}

const AddClientModal: FC<props> = ({ handleClose, isOpen, clientId }) => {
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const [location, setLocation] = useState<SingleOption>();
  const [packages, setPackages] = useState<SingleOption>();
  const [squaremeter, setSquaremeter] = useState<SingleOption>();
  const [payment, setPayment] = useState<SingleOption>();
  const [amountPaid, setAmountPaid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInstallment, setIsInstallment] = useState(false);

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType?.value as number);

  const { data: packagePerLocationData, isLoading: packagePerLocationLoading } =
    useGetAllPackagePerLocation(location?.value as number);

  const { data: itemPerPackageData, isLoading: itemPerPackageLoading } =
    useGetAllItemPerPackage(packages?.value as number);

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: capitalize(
          ev.name ||
            ev.location ||
            `${ev.size}SQM - ${formatPrice(ev.full_price)}`
        ),
      };
      return temp;
    });
  };

  useEffect(() => {
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType]);

  useEffect(() => {
    setLocation(undefined);
    setPackages(undefined);
  }, [projectType]);

  useEffect(() => {
    setPackages(undefined);
  }, [location]);

  useEffect(() => {
    payment?.value === paymentOptionEnum.installment
      ? setIsInstallment(true)
      : setIsInstallment(false);
  }, [payment?.value]);

  const onSubmit = async () => {
    setIsLoading(true);
    if (squaremeter?.value) {
      try {
        await api.clientService.addClientToPackage({
          amount: Number(amountPaid),
          client_id: clientId,
          installment: payment?.value as paymentOptionEnum,
          package_item_id: squaremeter?.value,
        });
        toast.success("Client added successfully");
        handleClose();
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }
    setIsLoading(false);
  };

  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose} width={"37vw"}>
      <Container>
        <Title>Add Client To Package</Title>
        <Formik
          initialValues={{
            categoryType,
            projectType,
            location,
            packages,
            squaremeter,
            payment,
            amountPaid,
          }}
          validationSchema={
            isInstallment
              ? inStallmentClientsToPackageValidation
              : clientsToPackageValidation
          }
          enableReinitialize
          onSubmit={() => {
            onSubmit();
          }}
        >
          <FormWrapper>
            <Form>
              <FlexWrapper>
                <SelectField
                  name="categoryType"
                  label="Category"
                  value={categoryType as SingleOption}
                  onChange={(e) => setCategoryType(e as SingleOption)}
                  options={formatData(allCategoryData)}
                  selectType="normal"
                  isLoading={allCategoryLoading}
                />

                <SelectField
                  name="projectType"
                  label="Project"
                  value={projectType as SingleOption}
                  onChange={(e) => setProjectType(e as SingleOption)}
                  disabled={!categoryType}
                  options={formatData(projectPerCategoryData)}
                  selectType="normal"
                  isLoading={projectPerCategoryLoading}
                />
              </FlexWrapper>
              <FlexWrapper>
                <SelectField
                  name="location"
                  label="Location"
                  value={location as SingleOption}
                  onChange={(e) => setLocation(e as SingleOption)}
                  disabled={!projectType}
                  options={formatData(locationPerProjectData)}
                  selectType="normal"
                  isLoading={locationPerProjectLoading}
                />
                <SelectField
                  name="packages"
                  label="Package"
                  value={packages as SingleOption}
                  onChange={(e) => setPackages(e as SingleOption)}
                  disabled={!location}
                  options={formatData(packagePerLocationData)}
                  selectType="normal"
                  isLoading={packagePerLocationLoading}
                />
              </FlexWrapper>
              <FlexWrapper>
                <SelectField
                  name="squaremeter"
                  label="Squaremeter"
                  value={squaremeter as SingleOption}
                  onChange={(e) => setSquaremeter(e as SingleOption)}
                  disabled={!packages}
                  options={formatData(itemPerPackageData?.items)}
                  selectType="normal"
                  isLoading={itemPerPackageLoading}
                />
                <SelectField
                  name="payment"
                  label="Payment"
                  value={payment as SingleOption}
                  onChange={(e) => setPayment(e as SingleOption)}
                  options={paymentOptions}
                  selectType="normal"
                />
              </FlexWrapper>
              {isInstallment && (
                <FlexWrapper>
                  <InputField
                    name="amountPaid"
                    label="Amount Paid (Naira)"
                    placeholder="Enter Amount Paid"
                    value={amountPaid}
                    onChange={(e) => setAmountPaid(e.target.value)}
                  />
                </FlexWrapper>
              )}
              <SubmitBtn>
                {isLoading ? (
                  <DualRing color="#fff" width={"16px"} height={"16px"} />
                ) : (
                  `Add to Package`
                )}
              </SubmitBtn>
            </Form>
          </FormWrapper>
        </Formik>
      </Container>
    </CustomModal>
  );
};

export default AddClientModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 35px;
`;

const Title = styled.h2``;

const FlexWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const FormWrapper = styled.div`
  margin-top: 30px;
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 100%;
  height: 46px;
  background: #afd34f;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  & > div {
    margin-bottom: 12px;
  }
`;

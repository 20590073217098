import { useMutation } from "@tanstack/react-query";
import { adminResetPassword, clientResetPassword } from "app/api/auth";
import { parseError } from "app/utils";

const useAdminResetPassword = () => {
  return useMutation(adminResetPassword, {
    onError: (error) => parseError(error),
  });
};

export default useAdminResetPassword;

export const useClientResetPassword = () => {
  return useMutation(clientResetPassword, {
    onError: (error) => parseError(error),
  });
};

import { useMutation } from "@tanstack/react-query";
import { adminStaffGrowth } from "app/api/analytics";
import { parseError } from "app/utils";

const useAdminStaffGrowth = () => {
  return useMutation(adminStaffGrowth, {
    onError: (error) => parseError(error),
  });
};

export default useAdminStaffGrowth;

import { ApiResponse } from "app/redux/types";
import request from "../request";
import { assessmentObj } from "../staff/types";
import { gradeAssessment, initiateAssessmentPayload } from "./types";

export const getAssessments = async () => {
  return await request.get<ApiResponse<assessmentObj[]>>(`/assessment/get`);
};

export const gradeAssessments = async (payload: gradeAssessment) => {
  return await request.post<ApiResponse<assessmentObj[]>>(
    `/assessment/grade`,
    payload
  );
};

export const initiateAssessments = async (
  payload: initiateAssessmentPayload
) => {
  return await request.post<ApiResponse<assessmentObj[]>>(
    `/assessment/initiate`,
    payload
  );
};

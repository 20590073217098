import { store } from "app/redux";
import { loginFailed } from "app/redux/auth/authSlice";
import axios, { AxiosError } from "axios";
import { BaseUrl } from "../utils/data";
import storage from "../utils/storage";

const request = axios.create({
  baseURL: BaseUrl,
});

request.interceptors.request.use((config) => {
  const {
    auth: { accessToken },
  } = store.getState();

  const newConfig = { ...config };

  if (accessToken) {
    newConfig.headers = {
      ...newConfig.headers,
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error: AxiosError) {
    if (error.response?.status === 400) {
      return Promise.reject(error.response?.data?.responseMessage);
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        "Sorry! You're not authorized to perform this action"
      );
    }

    // if user's token has expired or has been blacklisted
    if (error.response?.status === 401) {
      // logout
      store.dispatch(loginFailed());
      storage.clear();
    }
    return Promise.reject(error);
  }
);

export default request;

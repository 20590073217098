import React from "react";
import styled from "styled-components";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as BankIcon } from "../../../../images/user/bank-icon.svg";
import { ReactComponent as PaystackLogo } from "../../../../images/user/paystack-logo.svg";
import { usePaystackPayment } from "react-paystack";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import useGetPackageAmount from "app/hooks/payment/useGetPackageAmount";
import Spinner from "app/views/Web/components/Spinner";
import { isEmpty } from "lodash";
import useVerifyTransaction, {
  useVerifyOfferTransaction,
} from "app/hooks/payment/useVerifyTransaction";
import { toast } from "react-toastify";
import useVerifyOrderTransaction from "app/hooks/payment/useVerifyOrderTransaction";

interface paystackResponse {
  reference: string;
  trans: string;
  status: string;
  message: string;
  transaction: string;
  trxref: string;
  redirecturl: string;
}

interface Props {
  addOwner?: boolean;
  addNote?: boolean;
  isInstallment?: boolean;
  onNext: (amount: number) => void;
  packageItemId: number;
  packageName: string;
  projectName: string;
  SQM: string;
  handleClose: () => void;
  amountToPay?: string;
  redirect?: () => void;
  isReoccuring?: boolean;
  order_id?: number;
  isOfferPayment?: boolean;
  offer_id?: number;
  bid_id?: number;
  plotUnit?: number;
}

const Payment: React.FC<Props> = ({
  onNext,
  addOwner,
  addNote,
  isInstallment = false,
  packageItemId,
  packageName,
  projectName,
  SQM,
  handleClose,
  amountToPay,
  redirect,
  isReoccuring,
  order_id,
  isOfferPayment,
  offer_id,
  bid_id,
  plotUnit,
}) => {
  const { user } = useAppSelector(getAuth);

  const {
    data: dataAmount,
    isLoading: dataLoading,
    isError: dataError,
  } = useGetPackageAmount(packageItemId, !isOfferPayment, plotUnit);

  const config = {
    email: user?.email as string,
    amount: amountToPay
      ? Number(amountToPay) * 100
      : (dataAmount?.full_payment as number) * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY as string,
  };

  const initializePayment = usePaystackPayment(config);

  const { mutate: verifyMutate, isLoading: isVerfying } =
    useVerifyTransaction();
  const { mutate: verifyOfferMutate, isLoading: isOfferVerfying } =
    useVerifyOfferTransaction();
  const { mutate: orderMutate, isLoading: isOrdering } =
    useVerifyOrderTransaction();

  const offerPayment = (reference: paystackResponse) => {
    if (!offer_id || !bid_id || !amountToPay) return;
    const payload = {
      reference: reference.reference,
      offer_id: offer_id,
      bid_id: bid_id,
      amount: +amountToPay,
      units: plotUnit,
    };
    verifyOfferMutate(payload, {
      onSuccess: () => {
        toast.success("Your Payment Was Sucessfully!");
        redirect ? redirect() : handleClose();
      },
    });
  };

  const initialPayment = (reference: paystackResponse) => {
    if (isOfferPayment) {
      offerPayment(reference);
    } else {
      if (!plotUnit) return;
      const payload = {
        reference: reference.reference,
        package_item_id: packageItemId,
        amount: isInstallment
          ? Number(amountToPay)
          : (dataAmount?.full_payment as number),
        installment: isInstallment ? 1 : 0,
        units: plotUnit,
      };
      verifyMutate(payload, {
        onSuccess: () => {
          toast.success(
            () => (
              <div>
                Your Payment Was Sucessfully! <br />
                {isInstallment
                  ? "Upon payment completion you will be assigned a deed of agreement."
                  : "A deed of agreement will be assigned to you soon."}
              </div>
            ),
            {
              autoClose: false,
            }
          );

          redirect ? redirect() : handleClose();
        },
      });
    }
  };

  const reoccuringPayment = (reference: paystackResponse) => {
    if (!order_id || !plotUnit) return;
    const payload = {
      reference: reference.reference,
      order_id: order_id,
      units: plotUnit,
    };
    orderMutate(payload, {
      onSuccess: () => {
        toast.success("Your Payment Was Sucessfully!");
        redirect ? redirect() : handleClose();
      },
    });
  };

  const onSuccess = (reference: paystackResponse) => {
    if (isReoccuring) {
      reoccuringPayment(reference);
    } else {
      initialPayment(reference);
    }
  };

  const getPrice = () => {
    if (isOfferPayment) return Number(amountToPay).toLocaleString("en-US");
    else if (isInstallment)
      return Number(dataAmount?.installment).toLocaleString("en-US");
    else return Number(dataAmount?.full_payment).toLocaleString("en-US");
  };

  const getBankAmount = () => {
    if (isOfferPayment) return Number(amountToPay);
    else if (isInstallment) return Number(amountToPay);
    else return Number(dataAmount?.full_payment);
  };

  if (dataLoading && !isOfferPayment)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!dataLoading && dataError && !isOfferPayment)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (dataAmount && isEmpty(dataAmount) && !isOfferPayment)
    return (
      <SpinnerWrapper>
        <NoProjectText>No Content</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <div>
      <LabelBox bgColor={isInstallment ? "#3D9AC0" : "#AFD34F"}>
        {isInstallment ? "Pay in Installment" : "Full Payment (1O% off)"}
      </LabelBox>

      <DetailsBox>
        <TagBox>
          <Tag>Property: </Tag>
          {projectName}
        </TagBox>
        <TagBox>
          <Tag>Package: </Tag>
          {packageName}
        </TagBox>
        <TagBox>
          <Tag>Sqm: </Tag>
          {SQM}
        </TagBox>
        <TagBox>
          <Tag>Units: </Tag>
          {plotUnit}
        </TagBox>
        <TagBox>
          <Tag>Price: </Tag>
          {"\u20A6 "}
          {getPrice()}
        </TagBox>
        {isInstallment && (
          <TagBox>
            <Tag>Amount: </Tag>
            {"\u20A6 "}
            {Number(amountToPay).toLocaleString("en-US")}
          </TagBox>
        )}

        {addOwner && (
          <TagBox>
            <Tag>Owner: </Tag>
            Babatunde Olakunle
          </TagBox>
        )}
        {addNote && <Note>Note: Payment will be made to Adbond Property</Note>}
      </DetailsBox>

      <PaymentBox isDisabled={isVerfying || isOrdering || isOfferVerfying}>
        <LogoBox>
          <PaystackLogo />
        </LogoBox>
        <PayTypeDetails
          onClick={() => {
            initializePayment(onSuccess as any);
          }}
        >
          <h6>Paystack</h6>
          <span>Make payment using Paystack </span>
        </PayTypeDetails>
        {isVerfying || isOrdering || isOfferVerfying ? (
          <Spinner color="black" />
        ) : (
          <ChevronRightIcon />
        )}
      </PaymentBox>

      <PaymentBox bg="#E3F2FD" onClick={() => onNext(getBankAmount())}>
        <LogoBox>
          <BankIcon />
        </LogoBox>
        <PayTypeDetails>
          <h6>Bank Transfer</h6>
          <span>Make payment using offline payment(bank transfer)</span>
        </PayTypeDetails>
        <ChevronRightIcon />
      </PaymentBox>
    </div>
  );
};

export default Payment;

interface LabelBoxProps {
  bgColor: string;
}

const LabelBox = styled.div<LabelBoxProps>`
  width: max-content;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #fbfbfb;
  background-color: ${({ bgColor }) => bgColor};
  padding: 7px 8px;
  border-radius: 4px;
`;

const DetailsBox = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 14px 18px;
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: 18px;
`;

const TagBox = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  color: #5e5e5e;
`;

const Tag = styled.div`
  color: #49b7e4;
`;

interface PaymentBoxProps {
  bg?: string;
  isDisabled?: boolean;
}

const PaymentBox = styled.div<PaymentBoxProps>`
  display: grid;
  grid-template-columns: 36px 1fr max-content;
  column-gap: 20px;
  align-items: center;
  background: ${({ bg }) => (bg ? bg : "#f0f6e4")};
  border-radius: 10px;
  padding: 8px 5px;
  padding-right: 24px;
  margin-top: 24px;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

const PayTypeDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;

  h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #281103;
  }

  span {
    font-weight: 450;
    font-size: 0.75rem;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #757575;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background: #ffffff;
  border-radius: 100%;

  svg {
    width: 18px;
    height: 18px;
    color: #281103;
  }
`;

const Note = styled.div`
  background: #e6f2fa;
  border-radius: 4px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #464646;
  padding: 9px 13px;
`;

export const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

import React, { useEffect, useState } from "react";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import { SingleOption } from "app/types/common";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import TimePickerField from "app/views/Admin/components/formik inputs/TimePickerField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { DayData, ReoccuranceData } from "../../data";
import { RepeatitiveValidation } from "../validation";
import { CreateBtn, FlexWrapper, FormWrapper } from "./styles";
import useCreateNewSiteTour from "app/hooks/site tour/useCreateNewSiteTour";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { DayRange, WeekRange } from "app/api/site tour/types";
import Spinner from "app/views/Web/components/Spinner";

interface ReoccuranceProps {
  label: string;
  value: WeekRange;
}

interface DayProps {
  label: string;
  value: DayRange;
}

const RepeatitiveSchedule = () => {
  const navigate = useNavigate();
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const [location, setLocation] = useState<SingleOption>();
  const [time, setTime] = useState<Dayjs | null>(null);
  const [day, setDay] = useState<DayProps>();
  const [reoccurance, setReoccurance] = useState<ReoccuranceProps>();

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType?.value as number);

  const { mutate: createMutate, isLoading: isCreating } =
    useCreateNewSiteTour();

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  useEffect(() => {
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType]);

  useEffect(() => {
    setLocation(undefined);
  }, [projectType]);

  const onSuccess = () => {
    toast.success(`Tour Created Successfully!`);
    setTimeout(() => {
      navigate("/admin/tour");
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!location || !day || !reoccurance) return;
    const payload = {
      reoccur: 1 as 1,
      project_location_id: location.value,
      time: dayjs(time).format("hh:mm A"),
      day: day.value,
      week: reoccurance.value,
    };
    createMutate(payload, {
      onSuccess,
    });
  };

  return (
    <Formik
      initialValues={{
        categoryType,
        projectType,
        location,
        time,
        day,
        reoccurance,
      }}
      validationSchema={RepeatitiveValidation}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      <FormWrapper>
        <Form>
          <FlexWrapper>
            <SelectField
              name="categoryType"
              label="Category"
              value={categoryType as SingleOption}
              onChange={(e) => setCategoryType(e as SingleOption)}
              options={formatData(allCategoryData)}
              selectType="normal"
              isLoading={allCategoryLoading}
            />

            <SelectField
              name="projectType"
              label="Property"
              value={projectType as SingleOption}
              onChange={(e) => setProjectType(e as SingleOption)}
              disabled={!categoryType}
              options={formatData(projectPerCategoryData)}
              selectType="normal"
              isLoading={projectPerCategoryLoading}
            />
          </FlexWrapper>
          <SelectField
            name="location"
            label="Location"
            value={location as SingleOption}
            onChange={(e) => setLocation(e as SingleOption)}
            disabled={!projectType}
            options={formatData(locationPerProjectData)}
            selectType="normal"
            isLoading={locationPerProjectLoading}
          />

          <FlexWrapper>
            <SelectField
              name={`day`}
              label="Day"
              options={DayData}
              value={day as DayProps}
              onChange={(e) => {
                setDay(e as DayProps);
              }}
              selectType="normal"
            />
            <SelectField
              name={`reoccurance`}
              label="Reoccurance"
              options={ReoccuranceData}
              value={reoccurance as ReoccuranceProps}
              onChange={(e) => {
                setReoccurance(e as ReoccuranceProps);
              }}
              selectType="normal"
            />
          </FlexWrapper>

          <TimePickerField
            name="time"
            label="Time"
            placeholder="Select Time"
            value={time}
            onChange={(e) => setTime(e)}
          />

          <CreateBtn type="submit">
            {isCreating && <Spinner color="black" />}
            Create
          </CreateBtn>
        </Form>
      </FormWrapper>
    </Formik>
  );
};

export default RepeatitiveSchedule;

import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import { useAdminDeleteBankAccount } from "app/hooks/analytics/useBankAccount";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import { BankResponse } from "app/api/analytics/types";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  open: boolean;
  bankData: BankResponse;
  handleClose: () => void;
}

const DialogModal: React.FC<Props> = ({ open, handleClose, bankData }) => {
  const { mutate: deleteMutate, isLoading: isDeleting } =
    useAdminDeleteBankAccount();

  const onDelete = () => {
    const payload = {
      bank_account_id: bankData.id,
    };
    deleteMutate(payload, {
      onSuccess: () => {
        toast.success("Action Successfull");
        handleClose();
      },
    });
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content>
          Are you sure you want to <Reject>Delete</Reject> this bank account?
        </Content>
        <Field>
          <Text>
            <BlueText>Account Title</BlueText>: {bankData.account_name}
          </Text>
          <Text>
            <BlueText>Account Number</BlueText>: {bankData.account_number}
          </Text>
          <Text>
            <BlueText>Bank Name</BlueText>: {bankData.bank}
          </Text>
        </Field>
        <BtnWrapper>
          <CancelBtn onClick={handleClose} type="button">
            No, Cancel
          </CancelBtn>
          <ConfirmBtn onClick={onDelete}>
            {isDeleting && <Spinner color="black" />}
            Yes, Delete
          </ConfirmBtn>
        </BtnWrapper>
      </Container>
    </Backdrop>
  );
};

export default DialogModal;

const Container = styled.div`
  max-width: 50vw;
  min-width: 520px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaQueries.mobile} {
    min-width: unset;
    max-width: 100vw;
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 1rem;
`;

const Field = styled.span`
  display: flex;
  flex-direction: column;
`;

const BlueText = styled.span`
  color: #3d9ac0;
`;

const Text = styled.span`
  color: #143340;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 48px;
`;

const Reject = styled.span`
  color: #b40909;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 24px;
`;

const Btn = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
`;
const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #97b644;
  border: none;
  display: flex;
  gap: 8px;
  align-items: center;
  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

const CancelBtn = styled(Btn)`
  color: #dd1717;
  border: 1px solid #dd1717;
  background-color: transparent;
`;

import { GetPostResponse } from "app/api/community/types";
import React from "react";
import styled from "styled-components";
import CommentBox from "./CommentBox";
import CommentField from "./CommentField";
import { ErrorText, SpinnerWrapper } from "./styles";

interface Props {
  postData: GetPostResponse;
}

const PostComments: React.FC<Props> = ({ postData }) => {
  return (
    <Container>
      <Header>Comments</Header>
      <CommentField open={true} postId={postData.id} handleClose={() => {}} />
      {postData.comments.length > 0 ? (
        postData.comments.map((comment) => (
          <CommentBox data={comment} key={comment.id} />
        ))
      ) : (
        <SpinnerWrapper>
          <Text>
            There are currently no comment yet. Be the first to comment.
          </Text>
        </SpinnerWrapper>
      )}
    </Container>
  );
};

export default PostComments;

const Container = styled.div`
  padding: 24px;
  overflow: scroll;
  max-height: 100vh;
`;

const Header = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 2rem;
`;

const Text = styled(ErrorText)`
  text-align: center;
`;

import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box } from "@mui/material";
import { ReactComponent as ClientsIcon } from "app/images/staff-clients.svg";
import { ReactComponent as CommissionIcon } from "app/images/staff-commission.svg";
import { ReactComponent as ProjectsIcon } from "app/images/staff-projects.svg";
import { ReactComponent as SalesIcon } from "app/images/staff-sales.svg";
import useAdminGetAUser from "app/hooks/users/useAdminGetAUser";
import DualRing from "app/views/Web/components/loader";
import { MainLoaderWrapper } from "app/views/Admin/Staff/components/grid";
import StaffTotalCard from "app/views/Admin/Staff/components/staffTotalCard";
import ClientList from "app/views/Admin/Staff/components/clientList";
import { staffRoutes } from "app/utils/data";

const ETeamDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useAdminGetAUser(Number(id));

  const totalSales = String(
    data?.sales.map((x) => Number(x.amount_paid)).reduce((a, b) => a + b, 0)
  );

  const handleGoBack = () => {
    navigate(staffRoutes.VirtualStaff);
  };

  return (
    <Container>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"40px"}
      >
        <Header>
          <ArrowBackIcon onClick={() => handleGoBack()} />
          <HeaderText>Virtual Staff Details</HeaderText>
        </Header>
      </Box>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="35px" height="35px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <ContentWrapper>
          <InfoBox>
            {!!data?.profile_photo ? (
              <ProfilePic src={data.profile_photo.url} />
            ) : (
              <AccountCircleIcon />
            )}
            <InfoWrapper>
              <InfoEntry>
                <InfoLabel>Full Name</InfoLabel>
                <InfoValue>{`${data?.firstname} ${data?.lastname}`}</InfoValue>
              </InfoEntry>
              <InfoEntry>
                <InfoLabel>Gender</InfoLabel>
                <InfoValue>{data?.gender}</InfoValue>
              </InfoEntry>
              <InfoEntry>
                <InfoLabel>Email Address</InfoLabel>
                <InfoValue>{data?.email}</InfoValue>
              </InfoEntry>
              <InfoEntry>
                <InfoLabel>Phone Number</InfoLabel>
                <InfoValue>{data?.phone_number}</InfoValue>
              </InfoEntry>
              <InfoEntry>
                <InfoLabel>Staff Type</InfoLabel>
                <InfoValue>{data?.staff_type?.name}</InfoValue>
              </InfoEntry>

              <InfoEntry>
                <InfoLabel>Date Joined</InfoLabel>
                <InfoValue>
                  {data?.date_joined &&
                    new Date(data?.date_joined).toLocaleDateString()}
                </InfoValue>
              </InfoEntry>
            </InfoWrapper>
          </InfoBox>
          <RightSideWrapper>
            <Box display={"flex"} gap={"24px"} flexWrap={"wrap"} width={"100%"}>
              <StaffTotalCard
                label={"Total Sales"}
                value={totalSales}
                icon={SalesIcon}
              />
              <StaffTotalCard
                label={"Total Commission"}
                value={data?.commission_balance || 0}
                icon={CommissionIcon}
              />
              <StaffTotalCard
                label={"Clients"}
                value={data?.clients.length || 0}
                icon={ClientsIcon}
              />
              <StaffTotalCard
                label={"Projects"}
                value={data?.sales.length || 0}
                icon={ProjectsIcon}
              />
            </Box>
            <ClientList clients={data?.clients || []} />
          </RightSideWrapper>
        </ContentWrapper>
      )}
    </Container>
  );
};

export default ETeamDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px 40px;
  min-height: 80vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > svg {
    width: 26px;
    height: 25px;
    cursor: pointer;
  }
`;

const HeaderText = styled.h5`
  font-weight: 500;
  font-size: 25px;
  color: #181d0b;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 471px;
  background: #fbfbfb;
  padding: 10px 24px 49px;

  & > svg {
    width: 140px;
    height: 140px;
    fill: #949292;
  }
`;

const ProfilePic = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 32px;
`;

const InfoEntry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 12px;
  gap: 4px;
  width: 400px;
  height: 58px;
  background: #ffffff;
`;

const InfoLabel = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #767676;
`;

const InfoValue = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2a3313;
  text-transform: capitalize;
`;

const RightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
`;

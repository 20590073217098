import React from "react";
import { ErrorText, NoProjectText, SpinnerWrapper } from "./styles";
import Spinner from "app/views/Web/components/Spinner";
import PromoCard from "./cards/PromoCard";
import useGetPromos from "app/hooks/community/useGetPromos";

const DisplayPromos = () => {
  const { data, isLoading, isError } = useGetPromos();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <ErrorText>An error occurred. Please try again later</ErrorText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no content for this yet</NoProjectText>
      </SpinnerWrapper>
    );

  return <>{data && data.map((ev) => <PromoCard key={ev.id} data={ev} />)}</>;
};

export default DisplayPromos;

import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

interface Props {
  name: string;
  link?: string;
  url?: string;
  img: string;
}

const ContentCard: React.FC<Props> = ({ name, link, url, img }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onClose = () => {
    if (link) {
      searchParams.set("create", link);
      setSearchParams(searchParams);
    }
    url && navigate(`${url}`);
  };

  return (
    <Container onClick={onClose}>
      <ImgWrapper>
        <img src={img} alt="news" />
      </ImgWrapper>
      <h6>{name}</h6>
    </Container>
  );
};

export default ContentCard;

const Container = styled.div`
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 12px;
  padding: 12px 19px;
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;

  & > h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #809a39;
    flex: 1;
    text-decoration: underline;
    text-decoration-color: transparent;
  }

  &:hover h6 {
    text-decoration-color: currentColor;
    transition: all 0.2s ease-in;
  }
`;

const ImgWrapper = styled.div`
  width: 100px;
  border-radius: 8px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

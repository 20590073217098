import styled, { css } from "styled-components";

export interface FlexProps {
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "initial"
    | "inherit";
  alignItems?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "baseline"
    | "initial"
    | "inherit";
  fullWidth?: boolean;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  gap?: string;
}

const Flex = styled("div").withConfig({
  shouldForwardProp: (prop) =>
    ![
      "direction",
      "float",
      "fullWidth",
      "alignItems",
      "justifyContent",
    ].includes(prop),
})<FlexProps>`
  display: flex;
  align-items: ${(props) => props.alignItems || "unset"};
  justify-content: ${(props) => props.justifyContent || "unset"};
  flex-direction: ${(props) => props.direction || "row"};
  gap: ${(props) => props.gap};

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};
`;

export default Flex;

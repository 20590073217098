import React from "react";
import DynamicRenderer from "./DynamicRenderer";

interface Props {
  data: any;
  handleOpenDetailsModal: () => void;
}

const CommissionRenderer: React.FC<Props> = ({
  data,
  handleOpenDetailsModal,
}) => {
  const Obj = [
    {
      name: "View",
      onClick: handleOpenDetailsModal,
    },
  ];

  return <DynamicRenderer data={data} dataArray={Obj} />;
};

export default CommissionRenderer;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createNews } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useCreateNews = () => {
  const queryClient = useQueryClient();

  return useMutation(createNews, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetNews]);
    },
  });
};

export default useCreateNews;

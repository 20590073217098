import { parseError } from "app/utils";
import { useMutation } from "@tanstack/react-query";
import { initiateAssessments } from "app/api/assessment";

const useInitiateAssessment = () => {
  return useMutation(initiateAssessments, {
    onError: (error) => parseError(error),
  });
};

export default useInitiateAssessment;

import { ApiResponse, PaginatedResponse } from "app/redux/types";
import request from "../request";
import {
  adminClientDoaPayload,
  AdminConfirmSalesPayload,
  adminGetClientPackagesRes,
  AdminRecentSalesRes,
  AdminRejectSalesPayload,
  adminSaveBankAccountPayload,
  adminToggleBankAccountPayload,
  AdminTransferPackagePayload,
  adminUpdateBankAccountPayload,
  BankResponse,
  ClientAgeGroupBarChart,
  StaffCommissionPayload,
  StaffCommissionRes,
  StaffGrowthPayload,
  StaffGrowthRes,
} from "./types";

export const adminSaveBankAccount = async (
  payload: adminSaveBankAccountPayload
) => {
  return await request.post<ApiResponse<BankResponse>>(
    "/admin/bank_account/save",
    payload
  );
};

export const adminUpdateBankAccount = async (
  payload: adminUpdateBankAccountPayload
) => {
  return await request.post<ApiResponse<BankResponse>>(
    "/admin/bank_account/update",
    payload
  );
};

export const adminToggleBankAccount = async (
  payload: adminToggleBankAccountPayload
) => {
  return await request.post<ApiResponse<BankResponse>>(
    "/admin/bank_account/toggle",
    payload
  );
};

export const adminDeleteBankAccount = async (
  payload: adminToggleBankAccountPayload
) => {
  return await request.post<ApiResponse<BankResponse>>(
    "/admin/bank_account/delete",
    payload
  );
};

export const adminGetBankAccounts = async () => {
  return await request.get<ApiResponse<BankResponse[]>>(
    "/admin/bank_account/all"
  );
};

export const adminGetASingleBankAccount = async (bank_account_id: number) => {
  return await request.get<ApiResponse<BankResponse>>(
    `/admin/bank_account/get/${bank_account_id}`
  );
};

export const adminGetClientPackages = async (page: number, perPage: number) => {
  return await request.get<PaginatedResponse<adminGetClientPackagesRes[]>>(
    `/admin/client/packages/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const adminClientDoa = async (payload: adminClientDoaPayload) => {
  return await request.post<PaginatedResponse<adminGetClientPackagesRes[]>>(
    `/admin/client/save_doa`,
    payload
  );
};

export const adminStaffGrowth = async (payload: StaffGrowthPayload) => {
  return await request.post<ApiResponse<StaffGrowthRes[]>>(
    "/admin/user/staff_type/bar_chart",
    payload
  );
};

export const adminStaffCommisssion = async (
  payload: StaffCommissionPayload,
  page: number
) => {
  return await request.post<PaginatedResponse<StaffCommissionRes[]>>(
    `/admin/commission/by_month/${page}`,
    payload
  );
};

export const exportAdminStaffCommisssion = async (
  payload: StaffCommissionPayload,
  page: number
) => {
  return await request.post<string>(
    `/admin/commission/export/by_month/${page}`,
    payload,
    {
      responseType: "arraybuffer",
    }
  );
};

export const adminTransferPackage = async (
  payload: AdminTransferPackagePayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/client/transfer_package`,
    payload
  );
};

export const adminConfirmSales = async (payload: AdminConfirmSalesPayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/offer/payment/confirm`,
    payload
  );
};

export const adminRejectSales = async (payload: AdminRejectSalesPayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/offer/payment/reject`,
    payload
  );
};

export const adminRecentSales = async () => {
  return await request.get<ApiResponse<AdminRecentSalesRes[]>>(
    `/admin/sales/latest`
  );
};

export const getAgeGroupChartData = async () => {
  return await request.get<ApiResponse<ClientAgeGroupBarChart>>(
    `/admin/client/client_age_group_barchart`
  );
};

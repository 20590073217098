import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateForum } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useUpdateForum = () => {
  const queryClient = useQueryClient();

  return useMutation(updateForum, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetForums]);
    },
  });
};

export default useUpdateForum;

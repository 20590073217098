import useBankReferencePayment from "app/hooks/payment/useBankReferencePayment";
import useGetBankAccounts from "app/hooks/utilities/useGetBankAccounts";
import Spinner from "app/views/Web/components/Spinner";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import media, { mediaObj } from "styles/utils/media";

interface Props {
  onNext: () => void;
  packageItemId: number;
  amountToPay: number;
}

const AccountDetails: React.FC<Props> = ({
  onNext,
  packageItemId,
  amountToPay,
}) => {
  const [refData, setRefData] = useState("");
  const {
    data: bankAccounts,
    isLoading: isBankLoading,
    isError: isBankError,
  } = useGetBankAccounts();
  const { mutate } = useBankReferencePayment();

  const getBankReference = useCallback(() => {
    const payload = {
      package_item_id: packageItemId,
      amount: Number(amountToPay),
    };
    mutate(payload, {
      onSuccess: (data) => setRefData(data.data.data),
    });
  }, [amountToPay, packageItemId, mutate]);

  useEffect(() => {
    getBankReference();
  }, [getBankReference]);

  return (
    <div>
      <Header>
        <h6> Bank Transfer</h6>
        <span>Make payment using any of the bank details below</span>
        {refData && (
          <Note>
            Note: Add reference <NoteTag>{refData}</NoteTag> to narration for
            faster payment confirmation.
          </Note>
        )}
      </Header>

      {isBankLoading && !isBankError && (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {!isBankLoading && isBankError && (
        <SpinnerWrapper>
          <NoProjectText>
            An error occurred. Please try again later
          </NoProjectText>
        </SpinnerWrapper>
      )}

      {!isBankLoading && !isBankError && bankAccounts?.length === 0 && (
        <SpinnerWrapper>
          <NoProjectText>We have no new listing yet!</NoProjectText>
        </SpinnerWrapper>
      )}
      {!isBankLoading && !isBankError && bankAccounts && (
        <>
          <GridWrapper>
            {bankAccounts.map((ev, i) => (
              <>
                <AccountCard key={i}>
                  <div>Account Details {i + 1}</div>
                  <div>
                    <Tag>Account Title</Tag> : {ev.account_name}
                  </div>
                  <div>
                    <Tag>Account Number</Tag>: {ev.account_number}
                  </div>
                  <div>
                    <Tag>Bank Name </Tag>: {ev.bank}
                  </div>
                </AccountCard>
              </>
            ))}
          </GridWrapper>

          <ConfirmBtn onClick={onNext}>Confirm Payment</ConfirmBtn>
        </>
      )}
    </div>
  );
};

export default AccountDetails;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 27px;

  h6 {
    color: #49b7e4;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
  }

  span {
    font-weight: 450;
    font-size: 0.75rem;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: #757575;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 25px;
  row-gap: 24px;
  padding: 0.5rem;

  ${media.bigMobile`
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0;
  `}
`;

const AccountCard = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 24px;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 4px;
`;

const Tag = styled.span`
  color: #49b7e4;
`;

const ConfirmBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 85%;
  height: 46px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  margin: 40px auto 0 auto;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
    height: 54px;
  }
`;

const Note = styled.div`
  /* width: max-content; */
  background: #e6f2fa;
  border-radius: 4px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #464646;
  padding: 9px 13px;

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;

const NoteTag = styled.div`
  display: inline-block;
  color: #49b7e4;
  font-weight: bold;
`;

const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

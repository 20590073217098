import { abbreviateNumber } from "app/utils";
import ReactApexChart from "react-apexcharts";

interface Props {
  label: string[];
  data: number[];
}

const PieChart: React.FC<Props> = ({ label, data }) => {
  const series: any = data.reduce((acc, curr) => acc + curr, 0) ? data : [];

  const state = {
    series,
    options: {
      chart: {
        width: 400,
        type: "pie",
      },
      labels: label,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      //
      stroke: {
        width: 5,
      },
      dataLabels: {
        enabled: true,
        textAnchor: "center",
        formatter: function (val: any, { seriesIndex, w }: any) {
          const temp = abbreviateNumber(w.config.series[seriesIndex] * 1000000);
          return temp;
        },
      },
      noData: {
        text: "No data",
        align: "center",
        offsetX: -100,
        offsetY: -70,
        style: {
          color: "#5e5e5e",
          fontSize: "14px",
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={state.options}
      series={state.series}
      type="pie"
      width={400}
    />
  );
};

export default PieChart;

import MethodRenderer from "./methodRenderer";
import StatusRenderer from "./StatusRenderer";
import { formatPrice } from "app/utils";
import { paymentData } from "./paymentGrid";
import { ColDef } from "ag-grid-community";

type TableField = keyof paymentData;

interface GridDef extends ColDef {
  field: TableField;
}

export const columnDefsDesktop = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: paymentData } = params;
        return formatPrice(data.amountPaid);
      },
    },
    {
      headerName: "Method",
      field: "paymentMethod",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: MethodRenderer,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: paymentData } = params;
        return new Intl.DateTimeFormat("en-US").format(new Date(data.date));
      },
    },
  ];

  return columnDefs;
};

export const columnDefsMobile = () => {
  const columnDefs: GridDef[] = [
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.3,
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: paymentData } = params;
        return formatPrice(data.amountPaid);
      },
    },
    {
      headerName: "Status",
      field: "status",
      sortable: false,
      flex: 0.6,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: paymentData } = params;
        return new Intl.DateTimeFormat("en-US").format(new Date(data.date));
      },
    },
  ];

  return columnDefs;
};

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

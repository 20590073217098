import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { userResendEmailToken, userValidateEmail } from "../../../api/auth";
import { parseError } from "../../../utils";
import Spinner from "../components/Spinner";
import { TextField } from "./components/TextField";
import { verificationValidation } from "./vaildation";

interface Props {
  customerId: number;
}

const AccountVerification: React.FC<Props> = ({ customerId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const Timer = 30;
  const [timeInterval, setTimeInterval] = useState(Timer);

  const onSubmit = async (values: { verificationToken: string }) => {
    try {
      setLoading(true);
      const formResult = {
        customer_id: customerId,
        token: values.verificationToken,
      };
      await userValidateEmail(formResult);
      setLoading(false);
      navigate("/login");
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  };

  const onResend = async () => {
    try {
      setLoading(true);
      const formResult = {
        customer_id: customerId,
      };
      const res = await userResendEmailToken(formResult);
      toast.success(res.data.message, {
        autoClose: 10000,
      });
      setLoading(false);
      setTimeInterval(Timer);
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  };

  const timeIntervalHandler = useCallback(() => {
    timeInterval > 0 &&
      setTimeout(() => setTimeInterval((prev) => prev - 1), 1000);
  }, [timeInterval]);

  useEffect(() => {
    timeIntervalHandler();
  }, [timeIntervalHandler]);

  return (
    <>
      <h4>Create Account</h4>
      <p>Let’s get you started</p>
      <InfoText>
        An email has been sent to your email address. Check the inbox of your
        email account for the verification token provided.
      </InfoText>
      <FormWrapper>
        <Formik
          initialValues={{
            verificationToken: "",
          }}
          validationSchema={verificationValidation}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <Form>
            <TextField
              name="verificationToken"
              label="Verification Token"
              placeholder="************"
              type="text"
              onChange={() => {}}
            />
            <SubmitBtn type="submit">
              {loading ? <Spinner color="white" /> : "Continue"}
            </SubmitBtn>
            <ResendBox>
              Didn’t receive an email?...{" "}
              <button
                type="button"
                disabled={timeInterval > 0}
                onClick={onResend}
              >
                Resend {timeInterval > 0 && `in ${timeInterval} sec`}
              </button>
            </ResendBox>
          </Form>
        </Formik>
      </FormWrapper>
    </>
  );
};

export default AccountVerification;

const InfoText = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #464646;
`;

const FormWrapper = styled.div`
  width: 100%;
  margin-top: 64px;
`;

const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 80%;
  background: #afd34f;
  border-radius: 8px;
  padding: 10px 24px;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  margin-top: 64px;
  margin-bottom: 24px;
  cursor: pointer;
`;

const ResendBox = styled.div`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
  text-align: center;

  button {
    cursor: pointer;
    color: #afd34f;
    background-color: transparent;
    border: none;

    :disabled {
      color: #bbd588;
      cursor: default;
    }
  }
`;

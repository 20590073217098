import { useMutation } from "@tanstack/react-query";
import { clientIncreasePostViews, increasePostViews } from "app/api/community";
import { parseError } from "app/utils";

const useIncreasePostViews = () => {
  return useMutation(increasePostViews, {
    onError: (error) => parseError(error),
  });
};

export default useIncreasePostViews;

export const useClientIncreasePostViews = () => {
  return useMutation(clientIncreasePostViews, {
    onError: (error) => parseError(error),
  });
};

import { Backdrop } from "@mui/material";
import React from "react";
import styled from "styled-components";
import ClientsTable from "./ClientTable";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { packagesObj } from "app/api/project/types";
import useClientsPerPackage from "app/hooks/projects/useClientsPerPackage";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  open: boolean;
  handleClose: () => void;
  packageData: packagesObj;
}

const ClientModal: React.FC<Props> = ({ open, handleClose, packageData }) => {
  const { data, isLoading } = useClientsPerPackage(packageData.id.toString());
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <CancelIcon onClick={handleClose}>
          <HighlightOffIcon />
        </CancelIcon>
        <h6>{packageData.name}</h6>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner color="AFD34F" />
          </SpinnerWrapper>
        )}
        {!isLoading && data && <ClientsTable data={data} />}
      </Container>
    </Backdrop>
  );
};

export default ClientModal;

const Container = styled.div`
  min-width: 1180px;
  background: #ffffff;
  padding: 24px;
  padding-bottom: 45px;
  border-radius: 8px;
  position: relative;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 39px;
  }
`;

const CancelIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #b3261e;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

import { ApiResponse, PaginatedResponse } from "app/redux/types";
import request from "../request";
import {
  AdminCreateAdminPayload,
  AdminCreateUserPayload,
  AdminDeleteUserPayload,
  AdminEditUserPayload,
  AdminRateUserPayload,
  ClientUpdateNokPayload,
  ClientUpdatePayload,
  ClientUserResponse,
} from "./types";
import { StaffTypeObj, UserResponse } from "app/types/common";
import { clientObj, myClientRes } from "../client/types";

export const adminGetProfile = async () => {
  return await request.get<ApiResponse<UserResponse>>("/admin/get_profile");
};

export const adminCreateStaff = async (payload: AdminCreateUserPayload) => {
  return await request.post<ApiResponse<UserResponse>>(
    "/admin/user/create_staff",
    payload
  );
};

export const adminCreateAdmin = async (payload: AdminCreateAdminPayload) => {
  return await request.post<ApiResponse<UserResponse>>(
    "/admin/user/create_admin",
    payload
  );
};

export const adminEditUser = async (payload: AdminEditUserPayload) => {
  return await request.post<ApiResponse<UserResponse>>(
    "/admin/user/update",
    payload
  );
};

export const adminDeleteUser = async (payload: AdminDeleteUserPayload) => {
  return await request.post<ApiResponse<string>>("/admin/user/delete", payload);
};

export const adminRateUser = async (payload: AdminRateUserPayload) => {
  return await request.post<ApiResponse<string>>("/admin/rating/rate", payload);
};

export const adminGetAllStaff = async (page: number, perPage: number) => {
  return await request.get<PaginatedResponse<UserResponse[]>>(
    `/admin/user/staffs/${page}?per_page=${perPage}`
  );
};

export const adminGetAllAdmins = async (page: number, perPage: number) => {
  return await request.get<PaginatedResponse<UserResponse[]>>(
    `/admin/user/admins/${page}?per_page=${perPage}`
  );
};

export const adminGetAUser = async (id: number) => {
  return await request.get<ApiResponse<UserResponse>>(
    `/admin/get_profile_by_id/${id}`
  );
};

export const clientGetProfile = async () => {
  return await request.get<ApiResponse<ClientUserResponse>>("/client/profile");
};

export const clientUpdate = async (payload: Partial<ClientUpdatePayload>) => {
  return await request.post<ApiResponse<ClientUserResponse>>(
    "/client/update",
    payload
  );
};

export const getStaffTypes = async () => {
  return await request.get<ApiResponse<StaffTypeObj[]>>("/admin/staff_types");
};

export const clientUpdateNok = async (
  payload: Partial<ClientUpdateNokPayload>
) => {
  return await request.post<ApiResponse<ClientUserResponse>>(
    "/client/update_next_of_kin",
    payload
  );
};

export const getVirtualStaff = async () => {
  return await request.get<ApiResponse<UserResponse[]>>(
    "/admin/user/my_virtual_staffs"
  );
};

export const getStaffClients = async () => {
  return await request.get<ApiResponse<myClientRes[]>>(
    "/admin/user/my_clients"
  );
};

import {
  cardImageAnimate,
  cardTextAnimate,
  cardVariant,
  staggerContainer,
} from "app/utils/animationVariants";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";

interface props {
  icon?: string;
  title: string;
  description: string;
  width?: string;
}

const AboutCard: React.FC<props> = ({ description, title, icon, width }) => {
  return (
    <CardWrapper width={width} variants={cardVariant} custom={0.8}>
      <ImageWrapper variants={cardImageAnimate}>
        <img src={icon} alt="" />
      </ImageWrapper>
      <ContentWrapper variants={staggerContainer} custom={0.5}>
        <motion.h1 variants={cardTextAnimate}>{title}</motion.h1>
        <motion.p variants={cardTextAnimate}>{description}</motion.p>
      </ContentWrapper>
    </CardWrapper>
  );
};

export default AboutCard;

interface cardWrapperProps {
  width?: string;
}

export const CardWrapper = styled(motion.div)<cardWrapperProps>`
  display: flex;
  flex-direction: column;
  padding: 26px 18px 16px;
  gap: 24px;
  width: ${({ width }) => (width ? width : "390px")};
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 20px;
  transition: all 0.25s ease;
  /* margin: auto; */

  &:hover {
    transform: translateY(-10px) !important;
  }

  ${mediaObj.mediumDesktop} {
    width: 350px;
  }

  ${mediaObj.smallDesktop} {
    width: 47%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    margin: unset;
  }
`;

const ImageWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  flex-shrink: 0;

  & > img {
    width: 45%;
    height: 45%;
  }
`;

export const ContentWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > h1 {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 140%;
    color: #143340;
  }

  & > p {
    font-weight: 400;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
  }

  ${mediaQueries.mobile} {
    & > p {
      font-size: 0.95rem;
    }
  }
`;

export const heroAnimate = {
  opacity: [0, 1],
  transform: ["translateY(50px)", "translateY(0px)"],
  transition: { type: "string", stiffness: 150, ease: "easeOut" },
};

export const listItem = {
  hidden: { opacity: 0, transform: "translateY(50px)" },
  show: { opacity: 1, transform: "translateY(0px)" },
};

export const listContainer = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.07,
    },
  },
};

export const scaleText = {
  hidden: { opacity: 1, transform: "scale(0.8)" },
  animate: { opacity: 1, transform: "scale(1)" },
};

export const searchAnimate = {
  opacity: [0, 1],
  transform: ["translateX(-50px)", "translateX(0px)"],
  transition: { type: "string", stiffness: 150, ease: "easeOut" },
};

import React from "react";
import DualRing from "./loader";

interface Props {
  color: string;
  width?: string;
  height?: string;
}

const Spinner: React.FC<Props> = ({ color, width, height }) => {
  return (
    <DualRing
      width={width || "20px"}
      height={height || "20px"}
      color={color}
      spinnerSizing="border-box"
      spinnerLeft="0"
      spinnerTop="0"
      margin="0"
    />
  );
};

export default Spinner;

import React, { useState } from "react";
import agroImage from "../../../../../images/hero-frame-2.png";
import styled from "styled-components";
import AvatarContainer from "../AvatarContainer";
import DialogModal from "../modals/DialogModal";
import { OfferPackageObj, OfferResponse } from "app/api/offers/types";
import dayjs from "dayjs";

interface Props {
  packageData: OfferPackageObj;
  data: OfferResponse;
}

type DialogProps = {
  userName: string;
  type: "accept" | "reject";
  offerId: number;
};

const OfferCard: React.FC<Props> = ({ data, packageData }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState<DialogProps>();

  const ApproveHandler = () => {
    setOpenDialog(true);
    setDialogInfo({
      userName: `${data.client.firstname} ${data.client.lastname}`,
      type: "accept",
      offerId: data.id,
    });
  };

  const RejectHandler = () => {
    setOpenDialog(true);
    setDialogInfo({
      userName: `${data.client.firstname} ${data.client.lastname}`,
      type: "reject",
      offerId: data.id,
    });
  };

  const dialogCloseHandler = () => {
    setOpenDialog(false);
  };

  return (
    <Container>
      <AbsoluteBox>
        <Tag>Sales offer</Tag>
      </AbsoluteBox>
      <ImgWrapper>
        <img src={agroImage} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <GridWrapper>
          <GridItem type="project">
            <span>Category: </span>
            <span>{packageData?.category.name}</span>
          </GridItem>
          <GridItem>
            <span>Project Type: </span>
            <span>{packageData.project_location.project}</span>
          </GridItem>
          <GridItem>
            <span>Location: </span>
            <span>{packageData.state} State</span>
          </GridItem>
          <GridItem type="package">
            <span>Package: </span>
            <span>{packageData.name}</span>
          </GridItem>
          <GridItem>
            <span>Squaremeter: </span>
            <span>{data.customerPackageItem.packageItem.size}</span>
          </GridItem>

          <GridItem type="price">
            <span>Starting Price: </span>
            <span>
              {"\u20A6 "}
              {Number(data.offer_price).toLocaleString("en-US")}
            </span>
          </GridItem>

          <Footer>
            <AvatarContainer
              name={data.client.firstname + " " + data.client.lastname}
              time={String(dayjs(data.created_at).format("DD MMM YYYY"))}
              value={data.client?.profile_photo?.url}
            />
          </Footer>

          <Footer>
            <BtnWrapper>
              <FooterBtn onClick={ApproveHandler}>Approve</FooterBtn>
              <FooterBtn isDecline onClick={RejectHandler}>
                Reject
              </FooterBtn>
            </BtnWrapper>
          </Footer>
        </GridWrapper>
      </ContentWrapper>
      {openDialog && dialogInfo && (
        <DialogModal
          open={openDialog}
          handleClose={dialogCloseHandler}
          type={dialogInfo.type}
          userName={dialogInfo.userName}
          offerId={dialogInfo.offerId}
        />
      )}
    </Container>
  );
};

export default OfferCard;

const Container = styled.div`
  width: 784px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  & > h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: #5e5e5e;
    margin: 20px 0;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  background: #535353;
  color: #fbfbfb;
  border-radius: 0px 8px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
`;

interface GridItemProps {
  type?: "package" | "price" | "promo-price" | "project";
}

const GridItem = styled.div<GridItemProps>`
  width: ${({ type }) => type === "project" && "max-content"};
  padding: ${({ type }) => type === "project" && "4px 8px"};
  background-color: ${({ type }) => type === "project" && "#CDE6F5"};
  border-radius: ${({ type }) => type === "project" && "4px"};
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #777777;
    color: ${({ type }) => type === "project" && "#2A3313"};
    color: ${({ type }) => type === "package" && "#AFD34F"};
    color: ${({ type }) => type === "price" && "#697F2F"};
    color: ${({ type }) => type === "promo-price" && "#49B7E4"};
    text-transform: capitalize;
  }
`;

const Footer = styled.div`
  margin-top: 32px;
`;

const BtnWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

interface FooterBtnProps {
  isDecline?: boolean;
}

const FooterBtn = styled.div<FooterBtnProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: ${({ isDecline }) => (isDecline ? "1px solid #8C1D18" : "none")};
  height: 38px;
  padding: 0 24px;
  background: ${({ isDecline }) => (isDecline ? "transparent" : "#afd34f")};
  color: ${({ isDecline }) => (isDecline ? "#b3261e" : "#FBFBFB")};
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;

  &:hover {
    background-color: ${({ isDecline }) => !isDecline && "#697f2f"};
    transition: all 0.2s ease-out;
  }
`;

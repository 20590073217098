import { getTotalPromo } from "app/utils";
import { delayStagger } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "../../../../../styles/utils/mediaQueries";
import { packagesObj } from "../../../../api/project/types";
import PackageCard from "../../components/cards/packageCard";

interface props {
  data: packagesObj[];
}

const PackageList: React.FC<props> = ({ data }) => {
  return (
    <Container
      variants={delayStagger(2)}
      custom={0.8}
      animate={"animate"}
      initial={"initial"}
    >
      {data.length > 0 ? (
        data.map((x, i) => (
          <PackageCard
            packageData={x}
            key={i}
            isOnPromo={x.onPromo}
            isSoldOut={x.sold_out}
            promoDiscount={getTotalPromo(x.promos)}
          />
        ))
      ) : (
        <NoContentWrapper>
          <NoContentText>No Package Found</NoContentText>
        </NoContentWrapper>
      )}
    </Container>
  );
};

export default PackageList;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    gap: 30px;
    margin-top: 20px;
    width: 100%;
  }
`;

const NoContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;

const NoContentText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
`;

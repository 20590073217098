import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteEvent } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useDeleteEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteEvent, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetEvents]);
    },
  });
};

export default useDeleteEvent;

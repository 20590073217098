import React, { useEffect, useState } from "react";
import { PieChartRes } from "app/api/sales/types";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useAdminSalesPieChart from "app/hooks/sales/useAdminSalesPieChart";
import { labelValueType } from "app/types/common";
import Spinner from "app/views/Web/components/Spinner";
import styled from "styled-components";
import DropDown from "../../components/dropDown";
import { salesMonthOptions, yearDropDownOptions } from "../data";
import PieChart from "./PieChart";

const PackageSales = () => {
  const [year, setYear] = useState<number>(yearDropDownOptions[0].value);
  const [month, setMonth] = useState<number>(salesMonthOptions[0].value);
  const [category, setCategory] = useState<number>();
  const [analyticsData, setAnalyticsData] = useState<PieChartRes>();

  const { data: allCategory, isLoading } = useGetAllCategories();
  const { mutate, isIdle } = useAdminSalesPieChart();

  const handleYear = (value: number) => {
    setYear(value);
  };

  const handleMonth = (value: number) => {
    setMonth(value);
  };

  const handleCategory = (value: number) => {
    setCategory(value);
  };

  useEffect(() => {
    if (allCategory) {
      setCategory(allCategory[0].id);
    }
  }, [allCategory]);

  useEffect(() => {
    if (year && month && category) {
      const payload = {
        start: Number(month),
        year: Number(year),
        category_id: category,
      };
      mutate(payload, {
        onSuccess: (data) => setAnalyticsData(data.data.data),
      });
    }
  }, [year, month, category, mutate]);

  const formatAllCategory = (): labelValueType<any, any>[] => {
    if (!allCategory) return [];
    return allCategory.map((ev) => ({
      label: ev.name,
      value: ev.id,
    }));
  };

  return (
    <Container>
      <Header>
        <h5>Insights for packages</h5>
        <DropdownContainer>
          <DropDown
            dropDownOptions={salesMonthOptions}
            handleChange={handleMonth}
            bgColor={"#3E4B1C"}
            height="28px"
          />
          <DropDown
            dropDownOptions={yearDropDownOptions}
            handleChange={handleYear}
            height="28px"
          />
        </DropdownContainer>
      </Header>
      <Wrapper>
        {isLoading ? (
          <Spinner color="black" />
        ) : (
          <DropDown
            dropDownOptions={formatAllCategory()}
            handleChange={handleCategory}
            bgColor={"#AFD34F"}
            height="28px"
          />
        )}
      </Wrapper>

      {isIdle ? (
        <SpinnerWrapper>
          <Spinner color="black" />
        </SpinnerWrapper>
      ) : (
        analyticsData && (
          <PieChart
            label={analyticsData?.projects}
            data={analyticsData?.data}
          />
        )
      )}
    </Container>
  );
};

export default PackageSales;

const Container = styled.div`
  background-color: white;
  padding: 16px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  flex-grow: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

const Wrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 40px;
  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
`;

import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import PackageRenderer from "./packageRenderer";
import NameRenderer from "./nameRenderer";
import { UserClientsObj } from "app/types/common";

interface GridProps {
  data: UserClientsObj[];
}

type ClientField = keyof UserClientsObj | undefined;

export interface GridDef extends ColDef {
  field: ClientField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: UserClientsObj[];
}

const ClientGrid: React.FC<GridProps> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: UserClientsObj[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Client Name",
      field: "firstname",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 1,
      cellRendererFramework: NameRenderer,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Address",
      field: "address",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: UserClientsObj } = params;
        return !data.address
          ? `---`
          : data.address?.length > 30
          ? `${data.address.substring(0, 30)}...`
          : data.address;
      },
    },
    {
      headerName: "Phone Number",
      field: "phone_number",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: UserClientsObj } = params;
        return !data.phone_number ? `---` : data.phone_number;
      },
    },
    {
      headerName: "Packages",
      field: "packages",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: PackageRenderer,
      wrapText: true,
      autoHeight: true,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {GridData?.length > 0 ? (
        <GridWrapper className="ag-theme-alpine">
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: UserClientsObj) => {
              return data.id.toString();
            }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>You have no recent clients</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default ClientGrid;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "600",
  "letter-spacing": "-0.02em",
  "font-size": "14px",
  "line-height": "18px",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 300px;
  width: 100%;
  margin-bottom: 10px;

  &.ag-theme-alpine .ag-row-odd {
    background-color: #eaf4fb;
  }

  & * {
    overflow: visible !important;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

export enum OffersQueryKeys {
  getAllBid = "get-all-bid",
  getAllClientOffers = "get-all-client-offers",
  getClientAcceptedOffers = "get-client-accepted-offers",
  getClientPendingOffers = "get-client-pending-offers",
  getClientRejectedOffers = "get-client-rejected-offers",
  getASingleBid = "get-a-single-bid",
  getASingleOffer = "get-a-single-offer",
  adminGetAllOffer = "admin-get-all-offer",
}

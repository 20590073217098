import { Box } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";
import CustomInput from "../../components/customInput";
import CustomSelect from "../../components/customSelect";
import { genderOptions, titleOptions } from "../data";
import { labelValueType } from "app/types/common";
import { createClientPayload } from "app/api/client/types";
interface props {
  handleChange: (value: string, name: string) => void;
  countries: labelValueType<string, string>[];
  formData: createClientPayload | undefined;
}

const NextOfKin: FC<props> = ({ handleChange, countries, formData }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"column"} gap={3} width={"47%"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <CustomInput
              label={"First Name"}
              handleChange={handleChange}
              name={"kin_firstname"}
              width={"47%"}
              value={formData?.kin_firstname || ""}
            />
            <CustomInput
              label={"Last Name"}
              handleChange={handleChange}
              name={"kin_lastname"}
              width={"47%"}
              value={formData?.kin_lastname || ""}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <CustomInput
              label={"Email Address"}
              handleChange={handleChange}
              name={"kin_email"}
              width={"47%"}
              value={formData?.kin_email || ""}
            />
            <CustomInput
              label={"Phone Number"}
              handleChange={handleChange}
              name={"kin_phone_number"}
              width={"47%"}
              value={formData?.kin_phone_number || ""}
            />
          </Box>
          <Box display={"flex"}>
            <CustomInput
              label={"Address"}
              handleChange={handleChange}
              name={"kin_address"}
              width={"100%"}
              value={formData?.kin_address || ""}
            />
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={3} width={"47%"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <CustomSelect
              label={"Title"}
              handleChange={handleChange}
              name={"kin_title"}
              options={titleOptions}
              width={"47%"}
              value={titleOptions.filter(
                (x) => x.value === formData?.kin_title
              )}
            />
            <CustomSelect
              label={"Gender"}
              handleChange={handleChange}
              name={"kin_gender"}
              options={genderOptions}
              width={"47%"}
              value={genderOptions.filter(
                (x) => x.value === formData?.kin_gender
              )}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <CustomSelect
              label={"Nationality"}
              handleChange={handleChange}
              name={"kin_country_id"}
              options={countries}
              width={"100%"}
              value={countries.filter(
                (x) => x.value === formData?.kin_country_id?.toString()
              )}
            />
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <CustomInput
              label={"Relationship with client"}
              handleChange={handleChange}
              name={"kin_relationship"}
              width={"100%"}
              value={formData?.kin_relationship || ""}
            />
          </Box>
        </Box>
      </Box>
      {/* <Box mt={4}>
        <AttachDocBtn>
          <AttachFileIcon />
          Attach Document
        </AttachDocBtn>
      </Box> */}
    </Box>
  );
};

export default NextOfKin;

const AddPackageBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 450;
  font-size: 15px;
  color: #181d0b;
  width: 145px;
  height: 55px;
  background: #d8e8b8;
  border-radius: 4px;
  cursor: pointer;
`;

const AttachDocBtn = styled(AddPackageBtn)`
  width: 207px;
  height: 56px;

  & > svg {
    transform: rotateZ(45deg);
    width: 18px;
    height: 19px;
  }
`;

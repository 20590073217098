import { useQuery } from "@tanstack/react-query";
import { getClientPaymentHistory } from "app/api/client";
import { parseError } from "app/utils";

const useGetClientPaymentHistory = (
  page: number,
  per_page: number,
  searchValue?: string
) => {
  return useQuery(
    [page, per_page, searchValue],
    () => getClientPaymentHistory(page, per_page, searchValue),
    {
      enabled: !!per_page,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useGetClientPaymentHistory;

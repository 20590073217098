import { useQuery } from "@tanstack/react-query";
import { getAllLocationPerProject } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useGetAllLocationPerProject = (id: number) => {
  return useQuery(
    [ProjectsKey.locationPerProject, id],
    () => getAllLocationPerProject(id),
    {
      enabled: !!id,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetAllLocationPerProject;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchPackageRes } from "app/api/utilities/types";
import { RootState } from "../index";

interface Props {
  searchData?: SearchPackageRes[];
}

const homeState: Props = {
  searchData: undefined,
};

const homeSlice = createSlice({
  name: "home",
  initialState: homeState,
  reducers: {
    resetHome: () => homeState,
    setSearchData: (state, action: PayloadAction<SearchPackageRes[]>) => {
      state.searchData = action.payload;
    },
  },
});

export const { setSearchData, resetHome } = homeSlice.actions;

export const homeSelector = (state: RootState) => state.home;
export default homeSlice.reducer;

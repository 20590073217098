import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminDeleteAssessment } from "app/api/staff";
import { parseError } from "app/utils";
import { AssessmentKeys } from "./types";

const useAdminDeleteAssessment = () => {
  const queryClient = useQueryClient();

  return useMutation(adminDeleteAssessment, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([AssessmentKeys.adminGetAllAssessment]),
  });
};

export default useAdminDeleteAssessment;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { capitalize } from "lodash";
import ContentModal from "./components/modals/ContentModal";
import { useSearchParams } from "react-router-dom";
import CreateNewsAndOffers from "./components/modals/CreateNewsAndOffers";
import CreateEvent from "./components/modals/CreateEvent";
import CreateForum from "./components/modals/CreateForum";
import { CommunityContentsProps, communityTabs } from "./data";
import DisplayNews from "./components/DisplayNews";
import DisplayOffers from "./components/DisplayOffers";
import DisplayEvents from "./components/DisplayEvents";
import DisplayForums from "./components/DisplayForums";
import DisplayBlogposts from "./components/DisplayBlogposts";
import DisplayPromos from "./components/DisplayPromos";

export enum ContentTypes {
  newsAndPromos = "news-and-promos",
  events = "events",
  forum = "forum",
  blogpost = "blogpost",
}

const Community = () => {
  const [selectedCategory, setSelectedCategory] = useState<number>(1);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const activeParam = searchParams.get("activeTab");

  useEffect(() => {
    if (!!createType) {
      handleClose();
    }
  }, [createType]);

  useEffect(() => {
    !activeParam && setSearchParams({ activeTab: communityTabs[0].name });
  }, [setSearchParams, activeParam]);

  useEffect(() => {
    if (activeParam) {
      const result = communityTabs.filter((ev) => ev.name === activeParam);
      setSelectedCategory(result[0].id);
    }
  }, [activeParam]);

  return (
    <Container>
      <HeaderWrapper>
        <h5> Publish posts and updates on Adbond community.</h5>

        <CreateBtn onClick={handleOpen}>
          <AddIcon />
          Create a new post
        </CreateBtn>
      </HeaderWrapper>
      <TabsWrapper>
        {communityTabs.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedCategory}
            onClick={() => {
              setSelectedCategory(x.id);
              setSearchParams({ activeTab: x.name });
            }}
          >
            {capitalize(x.name)}
          </Tab>
        ))}
      </TabsWrapper>

      <CardWrapper>
        {selectedCategory === CommunityContentsProps.news && <DisplayNews />}
        {selectedCategory === CommunityContentsProps.promo && <DisplayPromos />}
        {selectedCategory === CommunityContentsProps.offers && (
          <DisplayOffers />
        )}
        {selectedCategory === CommunityContentsProps.events && (
          <DisplayEvents />
        )}
        {selectedCategory === CommunityContentsProps.forum && <DisplayForums />}
        {selectedCategory === CommunityContentsProps.blogPost && (
          <DisplayBlogposts />
        )}
      </CardWrapper>

      <ContentModal open={open} handleClose={handleClose} />
      {createType === ContentTypes.newsAndPromos && <CreateNewsAndOffers />}
      {createType === ContentTypes.events && <CreateEvent />}
      {createType === ContentTypes.forum && <CreateForum />}
    </Container>
  );
};

export default Community;

const Container = styled.div`
  padding: 20px 24px;
  background-color: white;
  min-height: 80vh;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-weight: 450;
    font-size: 25px;
    line-height: 140%;
    color: #181d0b;
    text-align: center;
  }
`;

const CreateBtn = styled.button`
  width: 200px;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #697f2f;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 40px 0;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 16px;
  height: 38px;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#535353")};
  background: ${({ isActive }) => (isActive ? "#464646" : "#E2E2E2")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

import { useMutation } from "@tanstack/react-query";
import { adminStaffCommisssion } from "app/api/analytics";
import { StaffCommissionPayload } from "app/api/analytics/types";
import { parseError } from "app/utils";

interface Props {
  payload: StaffCommissionPayload;
}

const useAdminStaffCommisssion = (page: number) => {
  return useMutation(
    (payload: Props) => adminStaffCommisssion(payload.payload, page),
    {
      onError: (error) => parseError(error),
    }
  );
};

export default useAdminStaffCommisssion;

import { ApiResponse } from "../../redux/types";
import { photosObj } from "../project/types";
import request from "../request";
import {
  ageGroupRes,
  AllStatesResponse,
  BankAccountResponse,
  companyInfoRes,
  getAdminBankListRes,
  homepageNumbersRes,
  markAsReadPayload,
  notificationRes,
  RolesResponse,
  SearchPackagePayload,
  SearchPackageRes,
  updateCompanySettingsPayload,
  UpdatePhotosPayload,
  UploadFileResponse,
} from "./types";

const homepageNumbers = async () => {
  return await request.get<ApiResponse<homepageNumbersRes>>(`/homepage_data`);
};

export const uploadAPhoto = async (payload: FormData) => {
  return await request.post<ApiResponse<UploadFileResponse>>(
    `/admin/file/save_photo`,
    payload
  );
};

export const uploadMultiplePhoto = async (payload: FormData) => {
  return await request.post<ApiResponse<UploadFileResponse[]>>(
    `/admin/file/save_photos`,
    payload
  );
};

export const updatePhotos = async (payload: UpdatePhotosPayload) => {
  return await request.post<ApiResponse<UploadFileResponse>>(
    `/admin/file/update_photos`,
    payload
  );
};

export const uploadAVideo = async (payload: FormData) => {
  return await request.post<ApiResponse<UploadFileResponse>>(
    `/admin/file/save_video`,
    payload
  );
};

export const uploadMultipleVideo = async (payload: FormData) => {
  return await request.post<ApiResponse<UploadFileResponse[]>>(
    `/admin/file/save_videos`,
    payload
  );
};

export const getAllStates = async () => {
  return await request.get<ApiResponse<AllStatesResponse[]>>(`/get_states`);
};

export const getAllCountries = async () => {
  return await request.get<ApiResponse<AllStatesResponse[]>>(`/get_countries`);
};

export const getRoles = async () => {
  return await request.get<ApiResponse<RolesResponse[]>>(`/admin/roles`);
};

export const clientUploadAPhoto = async (payload: FormData) => {
  return await request.post<ApiResponse<UploadFileResponse>>(
    `/file/save_photo`,
    payload
  );
};

export const getBankAccounts = async () => {
  return await request.get<ApiResponse<BankAccountResponse[]>>(
    "/bank_accounts"
  );
};

export const getTotalActiveProject = async () => {
  return await request.get<ApiResponse<number>>("/total_projects");
};

export const getTotalClients = async () => {
  return await request.get<ApiResponse<number>>("/total_clients");
};

export const uploadDoa = async (payload: FormData) => {
  return await request.post<ApiResponse<photosObj>>(
    "/admin/file/upload_doa",
    payload
  );
};

export const uploadBrochure = async (payload: FormData) => {
  return await request.post<ApiResponse<photosObj>>(
    "/admin/file/upload_brochure",
    payload
  );
};

export const searchPackage = async (payload: SearchPackagePayload) => {
  return await request.post<ApiResponse<SearchPackageRes[]>>(
    "/package/search",
    payload
  );
};

export const adminMarkAsRead = async (payload: markAsReadPayload) => {
  return await request.post<ApiResponse<boolean>>(
    "/admin/notification/mark_as_read",
    payload
  );
};

export const getAdminNotification = async () => {
  return await request.get<ApiResponse<notificationRes[]>>(
    "/admin/notification/get"
  );
};

export const getCompanyInfo = async () => {
  return await request.get<ApiResponse<companyInfoRes>>("/company_info");
};

export const updateCompanySettings = async (
  payload: Partial<updateCompanySettingsPayload>
) => {
  return await request.post<ApiResponse<string>>(
    "/admin/update_company_settings",
    payload
  );
};

export const getAgeGroups = async () => {
  return await request.get<ApiResponse<ageGroupRes[]>>("/age_groups");
};

export const getAdminBankList = async () => {
  return await request.get<ApiResponse<getAdminBankListRes[]>>("/admin/banks");
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  homepageNumbers,
  getAllCountries,
};

import { useGetClientAcceptedOffer } from "app/hooks/offers/useOffer";
import { useTabletCheck } from "app/hooks/useTabletCheck";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import SaleOfferCard from "../components/Cards/saleOffer";
import {
  CommunitySection,
  HeroSection,
  ListingSection,
  PropertyValuationSection,
} from "./sections";

const Dashboard = () => {
  const { data } = useGetClientAcceptedOffer();
  const isTab = useTabletCheck();

  return (
    <Wrapper>
      <LeftContent>
        <HeroSection />
        {isTab && <PropertyValuationSection isTab={isTab} />}
        <ListingSection />
      </LeftContent>
      <RightContent>
        <PropertyValuationSection />
        {data && data?.length > 0 && (
          <SaleOfferCard width={"100%"} offer={data[0]} />
        )}
        <CommunitySection fullHeight={!(data && data?.length > 0)} />
      </RightContent>
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: clamp(900px, 103%, 1200px);

  ${mediaObj.smallDesktop} {
    width: 100%;
    flex-direction: column;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 35px;
    width: 100%;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 67.6%;

  ${mediaObj.mediumDesktop} {
    width: 63%;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 30%;
  max-height: 682px;

  ${mediaObj.mediumDesktop} {
    width: 35%;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }
`;

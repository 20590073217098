import { useQuery } from "@tanstack/react-query";
import { clientGetPost, getPost } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useAdminGetBlogpost = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.adminGetBlogpost, page],
    () => getPost(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetBlogpost;

export const useClientGetBlogpost = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.clientGetBlogpost, page],
    () => clientGetPost(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

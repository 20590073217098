import * as Yup from "yup";

export const basicDetailsValidation = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .test("min_phonenumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),

  propertyType: Yup.object().required("Property Type is required"),
  category: Yup.object().required("Category is required"),
  packages: Yup.object().required("Package is required"),
  locations: Yup.object().required("Location is required"),
});

export const tourDatesValidation = Yup.object({
  selectedTourDate: Yup.object().required("dates is required!"),
});

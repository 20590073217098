import {
  AdmminSettingsTabEnum,
  labelValueType,
  modules,
} from "../../../types/common";

export const settingsRoute = [
  {
    title: "Account Security",
    link: AdmminSettingsTabEnum.AccountSecurity,
    value: modules.AccountSecurity,
  },
  {
    title: "All Admins",
    link: AdmminSettingsTabEnum.Admins,
    value: modules.Admins,
  },
  // {
  //   title: "Profile",
  //   link: "profile",
  //   value: modules.Profile,
  // },
  {
    title: "App Settings",
    link: AdmminSettingsTabEnum.AppSettings,
    value: modules.AppSettings,
  },
];

export const martialStatusOptions: labelValueType[] = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Engaged",
    value: "engaged",
  },
];

export const titleOptions: labelValueType[] = [
  {
    label: "Mr",
    value: "mr",
  },
  {
    label: "Mrs",
    value: "mrs",
  },
  {
    label: "Master",
    value: "ms",
  },
  {
    label: "Miss",
    value: "miss",
  },
];

export interface GenderProps {
  label: string;
  value: "male" | "female";
}

export const genderData: GenderProps[] = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const nationalityOptions: labelValueType[] = [
  {
    label: "Nigeria",
    value: "nigeria",
  },
  {
    label: "Ghana",
    value: "ghana",
  },
  {
    label: "Kenya",
    value: "kenya",
  },
];

export const stateOptions: labelValueType[] = [
  {
    label: "Enugu",
    value: "enugu",
  },
  {
    label: "Lagos",
    value: "lagos",
  },
  {
    label: "Ogun",
    value: "ogun",
  },
];

export const employmentStatusOptions: labelValueType[] = [
  {
    label: "Employed",
    value: "employed",
  },
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Self-Employed",
    value: "self",
  },
];

export const paymentOptions: labelValueType[] = [
  {
    label: "Full Payment",
    value: "full",
  },
  {
    label: "Installments",
    value: "installments",
  },
];

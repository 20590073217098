import { notificationRes } from "app/api/utilities/types";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import Entry from "./entry";

interface props {
  onClose: () => void;
  data: notificationRes[];
}

const DropDown: React.FC<props> = ({ onClose, data }) => {
  return (
    <Container>
      <Header>
        <p>Notifications</p>
        {/* <CountWrapper>
          <RedBall />
          <p>5 New</p>
        </CountWrapper> */}
      </Header>
      <NotificationsWrapper>
        {data.map((x, i) => (
          <Entry data={x} key={i} onClose={onClose} />
        ))}
      </NotificationsWrapper>
    </Container>
  );
};

export default DropDown;

const Container = styled.div`
  position: absolute;
  top: 55px;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 300px;
  max-height: 285px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 7px 25px #0000000f;
  border: 0.5px solid #d1d1d1;

  ${mediaQueries.mobile} {
    position: fixed;
    left: 0;
    width: 100vw;
    height: auto;
    max-height: 90vh;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 20px;

  & > p {
    letter-spacing: -0.12px;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
  }
`;

const CountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > p {
    letter-spacing: -0.09px;
    color: #000000;
    font-size: 11px;
    font-weight: 600;
  }
`;

const RedBall = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ed3838;
`;

const NotificationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  overflow-y: scroll;
  z-index: 13;
`;

import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import { useDeactivatePackage } from "app/hooks/projects/useDeactivate";
import { useActivatePackage } from "app/hooks/projects/useActivate";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import { packagesObj } from "app/api/project/types";
import { useDeletePackage } from "app/hooks/projects/useDelete";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  open: boolean;
  handleClose: () => void;
  packageData: packagesObj;
}

const DialogModal: React.FC<Props> = ({ open, handleClose, packageData }) => {
  const { mutate: deactivateMutate, isLoading: isDeactivating } =
    useDeactivatePackage();

  const { mutate: activateMutate, isLoading: isActivating } =
    useActivatePackage();

  const { mutate: deleteMutate, isLoading: isDeleting } = useDeletePackage();

  const onDeactivate = () => {
    const payload = {
      package_id: packageData.id,
    };
    deactivateMutate(payload, {
      onSuccess: () => {
        handleClose();
        toast.success("Package Deactivated Successfully!");
      },
    });
  };

  const onActivate = () => {
    const payload = {
      package_id: packageData.id,
    };
    activateMutate(payload, {
      onSuccess: () => {
        handleClose();
        toast.success("Package Activated Successfully!");
      },
    });
  };

  const onDelete = () => {
    const payload = {
      package_id: packageData.id,
    };
    deleteMutate(payload, {
      onSuccess: () => {
        handleClose();
        toast.success("Package Deleted Successfully!");
      },
    });
  };

  const ActionHandler = () => {
    if (packageData.active) {
      onDeactivate();
    } else {
      packageData.canDelete ? onDelete() : onActivate();
    }
  };

  const getTypeText = () => {
    if (packageData.active) {
      return "Deactivate";
    } else {
      return packageData.canDelete ? "Delete" : "Activate";
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content>
          Are you sure you want to{" "}
          <TypeBox isBgRed={packageData.canDelete || packageData.active}>
            {getTypeText()}
          </TypeBox>{" "}
          this package
        </Content>
        <BtnWrapper isDisabled={isDeactivating || isActivating || isDeleting}>
          <ConfirmBtn onClick={ActionHandler}>
            {(isDeactivating || isActivating) && <Spinner color="#fbfbfb" />}
            Yes, {getTypeText()}
          </ConfirmBtn>
          <CancelBtn onClick={handleClose} type="button">
            No, Cancel
          </CancelBtn>
        </BtnWrapper>
      </Container>
    </Backdrop>
  );
};

export default DialogModal;

const Container = styled.div`
  max-width: 50vw;
  min-width: 520px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaQueries.mobile} {
    min-width: unset;
    max-width: 100vw;
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 24px;
`;

interface TypeBoxProps {
  isBgRed: boolean;
}

const TypeBox = styled.span<TypeBoxProps>`
  color: ${({ isBgRed }) => (isBgRed ? "#b40909" : "#afd34f")};
`;

interface BtnWrapperProps {
  isDisabled: boolean;
}

const BtnWrapper = styled.div<BtnWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 24px;

  & > button {
    cursor: ${({ isDisabled }) => isDisabled && "not-allowed"};
  }
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
`;

const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #afd34f;
  border: none;
`;

const CancelBtn = styled(Btn)`
  color: #2a3313;
  border: 1px solid #2a3313;
  background-color: transparent;
`;

import { Checkbox } from "@mui/material";
import { clientObj } from "app/api/client/types";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface Props {
  data: clientObj;
  selectClient: (data: clientObj, type: boolean) => void;
  selectedClient: () => clientObj[];
}

const ActionRenderer: React.FC<Props> = ({
  data,
  selectClient,
  selectedClient,
}) => {
  const isSelected = () => {
    const temp = selectedClient().filter((ev) => ev.email === data.email);
    return temp.length === 0 ? false : true;
  };
  return (
    <Checkbox
      onChange={(e) => selectClient(data, e.target.checked)}
      defaultChecked={isSelected()}
      {...label}
      sx={{
        "&.Mui-checked": {
          color: "#AFD34F",
        },
      }}
    />
  );
};

export default ActionRenderer;

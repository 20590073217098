export const Months = [
  {
    value: "1 Month",
    label: "1 Month",
  },
  {
    value: "2 Months",
    label: "2 Months",
  },
  {
    value: "3 Months",
    label: "3 Months",
  },
  {
    value: "4 Months",
    label: "4 Months",
  },
  {
    value: "5 Months",
    label: "5 Months",
  },
  {
    value: "6 Months",
    label: "6 Months",
  },
  {
    value: "7 Months",
    label: "7 Months",
  },
  {
    value: "8 Months",
    label: "8 Months",
  },
  {
    value: "9 Months",
    label: "9 Months",
  },
  {
    value: "10 Months",
    label: "10 Months",
  },
  {
    value: "11 Months",
    label: "11 Months",
  },
  {
    value: "12 Months",
    label: "12 Months",
  },
  {
    value: "18 Months",
    label: "18 Months",
  },
  {
    value: "24 Months",
    label: "24 Months",
  },
];

export enum projectQueryString {
  activeCategory = "activeCategory",
  activeProject = "activeProject",
  activeLocation = "activeLocation",
}

import { useAppDispatch, useAppSelector } from "app/redux";
import { getAuth, setUser } from "app/redux/auth/authSlice";
import styled from "styled-components";
import { toast } from "react-toastify";
import AddImage from "app/views/Admin/components/AddImage";
import useClientUpdate from "app/hooks/users/useClientUpdate";
import { Form, Formik } from "formik";
import { clientUploadAPhoto } from "app/api/utilities";
import { ClientUserResponse } from "app/api/users/types";
import mediaQueries from "styles/utils/mediaQueries";
import Spinner from "app/views/Web/components/Spinner";
import { motion } from "framer-motion";
import { mediaObj } from "styles/utils/media";

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(getAuth) as {
    user: ClientUserResponse | undefined;
  };
  const { mutate: updateMutate, isLoading: isUpdating } = useClientUpdate();

  const onUpdateImage = (id: number) => {
    const payload = {
      ...user,
      photo_id: id,
    };
    updateMutate(payload, {
      onSuccess: (data: any) => {
        toast.success(`Image Updated Successfully!`);
        dispatch(setUser(data.data.data));
      },
    });
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <H1>Users profile</H1>
      <ProfileCard>
        <Header>
          <h5>Profile details</h5>
        </Header>

        <Content>
          <Formik initialValues={{}} onSubmit={() => {}}>
            <Form>
              <ImageWrapper>
                {isUpdating ? (
                  <Spinner color="black" />
                ) : (
                  <AddImage
                    name="profileImageId"
                    text="Add image"
                    handleFileId={(id) => onUpdateImage(id)}
                    width="116px"
                    height="116px"
                    iconWidth="20px"
                    iconHeight="20px"
                    borderRadius="100%"
                    uploadPhotoAsyncFn={clientUploadAPhoto}
                    value={user?.profile_photo?.url}
                  />
                )}
              </ImageWrapper>
            </Form>
          </Formik>

          <div>
            <FlexWrapper>
              <BlueTag>Full name</BlueTag>
              <span>{`${user?.firstname} ${user?.lastname}`}</span>
            </FlexWrapper>
            <FlexWrapper>
              <BlueTag>Email address</BlueTag>
              <span>{user?.email}</span>
            </FlexWrapper>
            <FlexWrapper>
              <BlueTag>Gender</BlueTag>
              <span>{user?.gender}</span>
            </FlexWrapper>
            <FlexWrapper>
              <BlueTag>Phone number</BlueTag>
              <span>{user?.phone_number}</span>
            </FlexWrapper>
          </div>
        </Content>
      </ProfileCard>
    </motion.div>
  );
};

export default UserProfile;

const H1 = styled.div`
  font-weight: 450;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 52px;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    color: #000000;
    margin-bottom: 52px;
  }

  ${mediaQueries.mobile} {
    margin-bottom: 20px;
  }
`;

const ProfileCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 20px;
  padding: 24px;

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #181d0b;
  }

  ${mediaQueries.mobile} {
    padding: 0px;
    margin-top: 28px;

    h5 {
      font-size: 16px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  gap: 82px;

  ${mediaObj.smallDesktop} {
    gap: 15px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 15px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
  span {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
    word-break: break-all;
  }

  ${mediaQueries.mobile} {
    gap: 10px;
    span {
      font-size: 14px;
    }
  }
`;

const BlueTag = styled.div`
  background: #49b7e4;
  border-radius: 4px;
  color: white;
  padding: 4px;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 140%;

  ${mediaQueries.mobile} {
    font-size: 12px;
    padding: 4px 8px;
  }
`;

const ImageWrapper = styled.div`
  width: 116px;
  height: 116px;
  background-color: #f1f1f1;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

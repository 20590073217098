import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminCreateAssessment } from "app/api/staff";
import { parseError } from "app/utils";
import { AssessmentKeys } from "./types";

const useAdminCreateAssessment = () => {
  const queryClient = useQueryClient();

  return useMutation(adminCreateAssessment, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([AssessmentKeys.adminGetAllAssessment]),
  });
};

export default useAdminCreateAssessment;

import React, { useState } from "react";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { ReactComponent as SuccessIcon } from "../../../images/admin/success-icon.svg";
import { useClientSendPasswordResetLink } from "app/hooks/auth/useSendPasswordResetLink";
import Spinner from "app/views/Web/components/Spinner";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import mediaQueries from "styles/utils/mediaQueries";
import { PasswordRecoveryValidation } from "./vaildation";
import { motion } from "framer-motion";
import { popUp, slideIn } from "app/utils/animationVariants";

const PasswordRecovery = () => {
  const [email, setEmail] = useState("");
  const [active, setActive] = useState(1);

  const { mutate: createMutate, isLoading: isCreating } =
    useClientSendPasswordResetLink();

  return (
    <>
      {active === 1 && (
        <Formik
          initialValues={{
            email,
          }}
          validationSchema={PasswordRecoveryValidation}
          enableReinitialize
          onSubmit={() => {
            const payload = {
              email,
            };

            createMutate(payload, {
              onSuccess: () => setActive(2),
            });
          }}
        >
          <Form>
            <Nav
              variants={slideIn("left")}
              animate={"animate"}
              initial={"initial"}
            >
              <H6>Password Recovery</H6>
            </Nav>
            <P
              variants={slideIn("right")}
              animate={"animate"}
              initial={"initial"}
            >
              Type your email address to get a link to reset your password
            </P>
            <FormWrapper initial={"initial"} animate={"animate"}>
              <InputField
                name="currentPassword"
                label="Email"
                placeholder="Enter your email address"
                inputType="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormWrapper>

            <SaveBtn
              type="submit"
              variants={popUp}
              custom={1}
              initial={"initial"}
              animate={"animate"}
            >
              {isCreating && <Spinner color="black" />}
              Password Recovery
            </SaveBtn>
          </Form>
        </Formik>
      )}
      {active === 2 && (
        <SucessWrapper>
          <SuccessIcon />
          <p>
            An email has been sent to your email address. Check the inbox of
            your email account for the account passord recovery link provided.
          </p>
        </SucessWrapper>
      )}
    </>
  );
};

export default PasswordRecovery;

const Nav = styled(motion.div)`
  display: flex;
  gap: 20px;
  align-items: center;
  color: #181d0b;
  margin-bottom: 24px;

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.3s ease-in-out;
    }
  }

  ${mediaQueries.mobile} {
    gap: 10px;
    margin: 28px 0 10px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const H6 = styled.div`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #181d0b;

  ${mediaQueries.mobile} {
    font-size: 1.25rem;
  }
`;

const P = styled(motion.div)`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin-bottom: 27px;

  ${mediaQueries.mobile} {
    font-size: 0.875rem;
    margin-bottom: 0px;
  }
`;

const FormWrapper = styled(motion.div)`
  margin-top: 40px;
  margin-bottom: 58px;

  ${mediaQueries.mobile} {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

const SaveBtn = styled(motion.button)`
  height: 44px;
  padding: 24px 43px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    margin-top: 20px;
    height: 56px;
  }
`;

const SucessWrapper = styled.div`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin-top: 5rem;

  p {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    text-align: center;
    color: #464646;
  }

  ${mediaQueries.mobile} {
    margin-top: 2.5rem;
    gap: 25px;

    & > svg {
      width: 180px;
      height: 180px;
    }

    & > p {
      font-size: 0.95rem;
    }
  }
`;

import { useQuery } from "@tanstack/react-query";
import { adminGetOfferPaymentsByCategory } from "app/api/payment";
import { parseError } from "app/utils";
import { PaymentQueryKeys } from "./types";

const useAdminGetOfferPaymentsByCategory = (
  category_id: number,
  page: number,
  per_page: number,
  type: string
) => {
  return useQuery(
    [
      PaymentQueryKeys.adminGetOfferPaymentsByCategory,
      category_id,
      page,
      per_page,
      type,
    ],
    () => adminGetOfferPaymentsByCategory(category_id, page, per_page, type),
    {
      enabled: !!category_id && !!per_page && !!type,
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetOfferPaymentsByCategory;

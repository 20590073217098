import React from "react";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Backdrop } from "@mui/material";
import { mediaObj } from "styles/utils/media";
import { PromoObj, PromoPackageObj } from "app/api/offers/types";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

interface Props {
  open: boolean;
  handleClose: () => void;
  promoData: PromoObj;
}

const OfferModal: React.FC<Props> = ({ open, handleClose, promoData }) => {
  const packagePromoData = promoData.products as PromoPackageObj[];
  const navigate = useNavigate();

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>
        <h6>Our Promo Offer</h6>
        <ContentWrapper>
          <H2>{promoData.title}</H2>
          <Para>{promoData.description}</Para>
          <GridItem>
            <span>Category: </span>
            <span>{packagePromoData[0].product.category}</span>
          </GridItem>
          <GridWrapper>
            <Items>
              <DarkText>Project: </DarkText>
              <GreyText>{packagePromoData[0].product.project}</GreyText>
            </Items>
            <Items>
              <DarkText>Package: </DarkText>
              <GreenText>
                {packagePromoData.length === 1 ? (
                  <span>{packagePromoData[0].product.name}</span>
                ) : (
                  <span>
                    {packagePromoData.map((ev) => ev.product.name).join(", ")}
                  </span>
                )}
              </GreenText>
            </Items>
            <Items>
              <DarkText>Location: </DarkText>
              <GreyText>{packagePromoData[0].product.location} State</GreyText>
            </Items>

            <Items>
              <DarkText>Promo Discount: </DarkText>
              <BlueText>{promoData.discount}%</BlueText>
            </Items>
            <Items>
              <GreenText>Promo Start Date: </GreenText>
              <GreenText>
                {dayjs(promoData.start).format("DD/MM/YYYY")}
              </GreenText>
            </Items>
            <Items>
              <RedText>Promo End Date: </RedText>
              <RedText> {dayjs(promoData.end).format("DD/MM/YYYY")}</RedText>
            </Items>
          </GridWrapper>
          <FlexWrapper>
            <div>Have an account with Adbond?</div>
            <LoginBtn
              onClick={() => {
                handleClose();
                navigate("/login");
              }}
            >
              Login to Purchase
            </LoginBtn>
          </FlexWrapper>
          <FlexWrapper>
            <div>Don’t have an account with Adbond?</div>
            <SignUpBtn
              onClick={() => {
                handleClose();
                navigate("/login");
              }}
            >
              Signup to Purchase
            </SignUpBtn>
          </FlexWrapper>
        </ContentWrapper>
      </Container>
    </Backdrop>
  );
};

export default OfferModal;

const Container = styled.div`
  width: 522px;
  max-height: 80vh;
  /* min-height: 774px; */
  padding: 24px;
  position: relative;
  background-color: white;
  border-radius: 8px;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 24px;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
    padding: 5%;

    & > h6 {
      font-size: 1.125rem;
    }
  }
`;

const ContentWrapper = styled.div`
  padding-right: 0.5rem;
  /* max-height: 70vh; */
  height: calc(80vh - 98px);
  /* min-height: calc(774px - 98px); */
  overflow-y: scroll;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding-bottom: 24px;
  margin-top: 24px;
  border-bottom: 1px solid #cfcfcf;

  ${mediaObj.bigMobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const H2 = styled.h2`
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #2a3313;
  margin-bottom: 24px;
  text-transform: capitalize;

  ${mediaObj.bigMobile} {
    font-size: 0.875rem;
  }
`;

const Para = styled.p`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #5e5e5e;
  margin: 24px 0;
`;

const GridItem = styled.div`
  width: max-content;
  padding: 4px 8px;
  background-color: #cde6f5;
  border-radius: 4px;
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #2a3313;
    text-transform: capitalize;
  }

  ${mediaObj.bigMobile} {
    font-size: 0.875rem;
  }
`;

const Items = styled.span`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-transform: capitalize;

  ${mediaObj.bigMobile} {
    font-size: 0.875rem;
  }
`;

const DarkText = styled(Items)`
  color: #2a3313;
`;

const GreyText = styled(Items)`
  color: #777777;
`;

const GreenText = styled(Items)`
  color: #afd34f;
`;

const BlueText = styled(Items)`
  color: #49b7e4;
`;

const RedText = styled(Items)`
  color: #b3261e;
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  margin: 24px 0;

  & > div:nth-child(1) {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
    flex-grow: 1;

    ${mediaObj.bigMobile} {
      font-size: 0.875rem;
    }
  }
`;

const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 54px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  border: none;
  cursor: pointer;
  ${mediaObj.bigMobile} {
    font-size: 0.875rem;
    padding: 5%;
  }
`;

const LoginBtn = styled(Btn)`
  background: #afd34f;
`;

const SignUpBtn = styled(Btn)`
  background: #49b7e4;
`;

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { GlobalStyle } from "../styles/global-styles";
import AppRoutes from "./routes";

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer
        newestOnTop={true}
        closeButton={false}
        autoClose={4000}
        hideProgressBar={true}
        pauseOnHover={true}
        limit={1}
        rtl={false}
        position="bottom-right"
      />
      <AppRoutes />
    </>
  );
};

export default App;

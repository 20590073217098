import { useCommunityGetOffers } from "app/hooks/community/useGetOffers";
import DashboardCommunityCard from "app/views/Client/components/Cards/dashboardCommunity";
import Spinner from "app/views/Web/components/Spinner";
import { FC } from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import { ErrorText } from "../../Community/components/styles";

interface props {
  fullHeight?: boolean;
}

export const Community: FC<props> = ({ fullHeight }) => {
  const { data, isLoading, isError } = useCommunityGetOffers();

  const getContent = () => {
    if (isLoading)
      return (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      );

    if (!isLoading && isError)
      return (
        <SpinnerWrapper>
          <ErrorText>An error occurred. Please try again later</ErrorText>
        </SpinnerWrapper>
      );

    if (data && data.length === 0)
      return (
        <SpinnerWrapper>
          <NoProjectText>You have no content for this yet</NoProjectText>
        </SpinnerWrapper>
      );

    if (data && data.length > 0)
      return (
        <CardsWrapper>
          {data?.map((x, idx) => (
            <DashboardCommunityCard data={x} key={idx} />
          ))}
        </CardsWrapper>
      );
  };

  return (
    <Wrapper fullHeight={fullHeight}>
      <Title>Community Offers</Title>
      {getContent()}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  height: ${({ fullHeight }) => (fullHeight ? `520px` : `334px`)};
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;

  ${mediaObj.smallDesktop} {
    height: auto;
    max-height: 520px;
    padding-bottom: 15px;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #2a3313;
  margin: 16px 0 0 16px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 15px;
  width: 100%;
  margin: 15px 0 0 16px;
  max-height: calc(100% - 68px);
  overflow-y: scroll;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

const NoProjectText = styled.p`
  font-size: 15px;
  font-weight: 600;
  color: #5e5e5e;

  ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

import { ForumResponse } from "app/api/community/types";
import { cardVariant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  data: ForumResponse;
}

const ForumCard: React.FC<Props> = ({ data }) => {
  const { title, description, start_date, start_time, cover_photo, links } =
    data;

  return (
    <Container variants={cardVariant} custom={0.8}>
      <ImgWrapper>
        <img src={cover_photo?.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title}</h6>
        <p>{description}</p>
        <ForumLinkWrapper>
          {links.map((link, i) => (
            <ForumLink key={link.id}>
              <div>Forum Link {links.length === 1 ? "" : i + 1}: </div>
              <a href={link.link} target="_blank" rel="noreferrer">
                {link.link}
              </a>
            </ForumLink>
          ))}
        </ForumLinkWrapper>

        <Footer>
          <FlexWrapper>
            <ForumDate>Event Date: {start_date}</ForumDate>
            <ForumBox>
              <span>Forum Time: </span>
              <span>{start_time}</span>
            </ForumBox>
          </FlexWrapper>
        </Footer>
      </ContentWrapper>
    </Container>
  );
};

export default ForumCard;

const Container = styled(motion.div)`
  width: 784px;
  height: 228px;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;

  ${mediaObj.smallDesktop} {
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaQueries.mobile} {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;

    ${mediaQueries.mobile} {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin-top: 12px;
    margin-bottom: 7px;

    ${mediaQueries.mobile} {
      font-size: 12px;
      line-height: 15px;
      margin: 5px 0 7px;
    }
  }
`;

const ForumLinkWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: auto;

  ${mediaQueries.mobile} {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
`;

const ForumLink = styled.div`
  font-weight: 450;
  font-size: 0.9rem;
  line-height: 140%;

  & > div {
    color: #2a3313;
  }

  & > a {
    color: #48b6e3;
  }
`;

const ForumBox = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #5e5e5e;
  }
`;

const ForumDate = styled.div`
  color: #2a3313;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 7px;
  }
`;

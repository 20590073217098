import { modules, SideBarObj } from "../types/common";
import { ReactComponent as DashboardIcon } from "../images/admin/dashboard.svg";
import { ReactComponent as ProjectsIcon } from "../images/admin/projects.svg";
import { ReactComponent as ClientsIcon } from "../images/admin/clients.svg";
import { ReactComponent as StaffsIcon } from "../images/admin/staffs.svg";
import { ReactComponent as SiteTourIcon } from "../images/admin/site-tour.svg";
import { ReactComponent as CommunityIcon } from "../images/admin/community.svg";
import { ReactComponent as AnalyticsIcon } from "../images/admin/analytics.svg";
import { ReactComponent as SettingsIcon } from "../images/admin/settings.svg";
import { ReactComponent as PaymentIcon } from "../images/user/payment-icon.svg";

// export const BaseUrl = "https://adbond.zizix6host.com/api/v1/";
export const BaseUrl = "https://api.adbondharvestandhomes.com/api/v1";

export const domainUrl = "https://www.adbondharvestandhomes.com";

export const UserRoutes = {
  Dashboard: "/user/dashboard",
  Properties: "/user/properties",
  Projects: "/user/projects",
  SiteTour: "/user/tour",
  Community: {
    Index: "/user/community",
    BlogPost: "/user/community/blogpost",
    Offers: "/user/community/offers",
  },
  Settings: {
    Index: "/user/settings",
    Details: "/user/settings/details",
    Profile: "/user/settings/profile",
    Security: "/user/settings/security",
  },
  Payments: "/user/payments",
  Root: "/user",
};

export const staffRoutes = {
  Dashboard: "/staff/dashboard",
  Clients: "/staff/clients",
  VirtualStaff: "/staff/virtual-staff",
  Community: {
    Index: "/staff/community",
    BlogPost: "/staff/community/blogpost",
    Offers: "/staff/community/offers",
  },
  Settings: "/staff/settings",
  Root: "/staff",
};

export const PropertiesRoutes = {
  Installment: "installment",
  Complete: "complete",
  Offers: "offers",
  Bids: "bids",
};

export const sidebarData: SideBarObj[] = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    link: "/admin/dashboard",
    value: modules.Dashboard,
  },
  {
    title: "Projects",
    icon: ProjectsIcon,
    link: "/admin/projects",
    value: modules.Projects,
  },
  {
    title: "Clients’ Database",
    icon: ClientsIcon,
    link: "/admin/clients",
    value: modules.Clients,
  },
  {
    title: "Staff",
    icon: StaffsIcon,
    link: "/admin/staffs",
    value: modules.Staff,
  },
  {
    title: "Site Tour",
    icon: SiteTourIcon,
    link: "/admin/tour",
    value: modules.SiteTour,
  },
  {
    title: "Community",
    icon: CommunityIcon,
    link: "/admin/community",
    value: modules.Community,
  },
  {
    title: "Analytics",
    icon: AnalyticsIcon,
    link: "/admin/analytics",
    value: modules.Analytics,
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    link: "/admin/settings",
    value: modules.Settings,
  },
];

export const clientSidebarData: SideBarObj[] = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    link: UserRoutes.Dashboard,
    value: "dashboard",
  },
  {
    title: "My assets",
    icon: DashboardIcon,
    link: UserRoutes.Properties,
    value: "properties",
  },
  {
    title: "Projects",
    icon: ProjectsIcon,
    link: UserRoutes.Projects,
    value: "projects",
  },
  {
    title: "Site tour",
    icon: SiteTourIcon,
    link: UserRoutes.SiteTour,
    value: "tour",
  },
  {
    title: "Community",
    icon: CommunityIcon,
    link: UserRoutes.Community.Index,
    value: "community",
  },
  {
    title: "Payments history",
    icon: PaymentIcon,
    link: UserRoutes.Payments,
    value: "payments",
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    link: UserRoutes.Settings.Index,
    value: "settings",
  },
];

export const staffSidebarData: SideBarObj[] = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    link: staffRoutes.Dashboard,
    value: "dashboard",
  },
  {
    title: "My Clients",
    icon: ClientsIcon,
    link: staffRoutes.Clients,
    value: "clients",
  },
  {
    title: "My Virtual Staff",
    icon: StaffsIcon,
    link: staffRoutes.VirtualStaff,
    value: "staff",
  },
  {
    title: "Community",
    icon: CommunityIcon,
    link: staffRoutes.Community.Index,
    value: "community",
  },
  {
    title: "Settings",
    icon: SettingsIcon,
    link: staffRoutes.Settings,
    value: "settings",
  },
];

export const footerLinks = [
  {
    label: "Our project",
    link: "/project",
  },
  {
    label: "About us",
    link: "/about",
  },
  {
    label: "Schedule a site tour",
    link: "/schedule-a-tour",
  },
  {
    label: "Community",
    link: "/community",
  },
  {
    label: "Privacy Policy",
    link: "/legal/privacy",
  },
  {
    label: "Terms & Conditions",
    link: "/legal/tc",
  },
  {
    label: "FAQs",
    link: "/FAQs",
  },
];

import { adminGetPaymentsByCategoryResponse } from "app/api/payment/types";
import { capitalize } from "lodash";
import React from "react";
import styled from "styled-components";
import ModalWrapper from "./ModalWrapper";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: adminGetPaymentsByCategoryResponse;
}

const OnlineModal: React.FC<Props> = ({ open, handleClose, data }) => {
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Title>Payment Information</Title>
        <Wrapper>
          <Text>
            <BlueText>Reference</BlueText>: {data.reference}
          </Text>
          <Text>
            <BlueText>Client Name</BlueText>:{" "}
            {data.client.firstname + " " + data.client.lastname}
          </Text>
          <Text>
            <BlueText>Payment Getway</BlueText>:{" "}
            {capitalize(data.payment_channel)}
          </Text>
          <Text>
            <BlueText>Amount Paid</BlueText>: NGN
            {Number(data.amount).toLocaleString("en-US")}
          </Text>
          <Text>
            <BlueText>Payment Type</BlueText>:{" "}
            {data.order.installment ? "Installment" : "Full Payment"}
          </Text>
          <Text>
            <BlueText>Date</BlueText>:{" "}
            {new Intl.DateTimeFormat("en-US").format(
              new Date(data.order.order_date)
            )}
          </Text>
        </Wrapper>
      </Container>
    </ModalWrapper>
  );
};

export default OnlineModal;

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Title = styled.div`
  margin-bottom: 21px;
`;

const BlueText = styled.span`
  color: #3d9ac0;
`;

const Text = styled.span`
  color: #143340;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 48px;
`;

import React, { useEffect, useState } from "react";
import { clientObj } from "app/api/client/types";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TourModalsRoute } from "../..";
import AddClient from "../sections/AddClient";
import AllClient from "../sections/AllClient";
import CreateCustom from "../sections/CreateCustom";
import useCreateCustomTour from "app/hooks/site tour/useCreateCustomTour";
import { toast } from "react-toastify";
import { clientsTourObj } from "app/api/site tour/types";
import ModalWrapper from "./ModalWrapper";
import useGetACustomTour from "app/hooks/site tour/useGetACustomTour";
import { NoProjectText, SpinnerWrapper } from "../sections/styles";
import Spinner from "app/views/Web/components/Spinner";
import useCustomAddClients from "app/hooks/site tour/useCustomAddClients";

const CustomSiteTour = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const editId = searchParams.get("edit");
  const editPage = searchParams.get("page");
  const [activePage, setActivePage] = useState(1);
  const [formData, setFormData] = useState<any>({});
  const [selectedClient, setSelectedClient] = useState<clientObj[]>([]);

  const {
    data: singleData,
    isFetching,
    isError,
  } = useGetACustomTour(Number(editId));
  const { mutate: createMutate, isLoading: isCreating } = useCreateCustomTour();
  const { mutate: clientUpdateMutate, isLoading: isClientUpdating } =
    useCustomAddClients();

  const goBack = () => setActivePage((prev) => prev - 1);
  const goToAddClient = () => setActivePage(2);
  const goToAllClient = () => setActivePage(3);

  const selectClient = (data: clientObj, type: boolean) => {
    type
      ? setSelectedClient((oldArray) => [
          ...oldArray,
          {
            ...data,
          },
        ])
      : setSelectedClient((oldArray) =>
          oldArray.filter((ev) => ev.id !== data.id)
        );
  };

  const pushToFormData = (data: any) =>
    setFormData((prev: any) => ({ ...prev, ...data }));

  const getModalTitle = () => {
    if (editId) return `Edit Custom Site Tour`;
    else if (activePage === 1) return `Create Custom Site Tour`;
    else if (activePage === 2) return `Add a Client to a Site tour`;
    else return "Select Client";
  };

  const getSelectedDetails = (
    data: clientObj[] | undefined
  ): clientsTourObj[] => {
    if (!data || data.length === 0) return [];
    const temp = data.map((ev) => ({
      firstname: ev.fullname?.split(" ")[0],
      lastname: ev.fullname?.split(" ")[1],
      email: ev.email,
      phone_number: ev.phone_number,
    }));
    return temp;
  };

  const onSuccess = () => {
    toast.success(
      `Custom Tour ${singleData ? "Edited" : "Created"} Successfully!`
    );
    setTimeout(
      () => {
        // searchParams.delete("selected");
        searchParams.delete("create");
        searchParams.delete("edit");
        searchParams.delete("page");
        setSearchParams(searchParams);
      },
      singleData ? 0 : 1500
    );
  };

  const onSubmitHandler = (data: any) => {
    if (singleData) {
      const payload = {
        id: singleData.id,
        package_id: singleData.clients[0].package_id,
        clients: getSelectedDetails(selectedClient).concat(data),
      };
      clientUpdateMutate(payload, {
        onSuccess,
      });
    } else {
      const payload = {
        ...formData,
        clients: getSelectedDetails(selectedClient).concat(data),
      };

      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editId && editPage) setActivePage(+editPage);
  }, [editPage, editId]);

  return (
    <ModalWrapper
      open={createType === TourModalsRoute.customSiteTour}
      title={getModalTitle()}
      goBack={goBack}
      applyBackBtn={editId ? activePage === 3 : activePage !== 1}
    >
      <>
        {editId && isFetching && (
          <SpinnerWrapper>
            <Spinner color="#AFD34F" />
          </SpinnerWrapper>
        )}
        {editId && !isFetching && isError && (
          <SpinnerWrapper>
            <NoProjectText>An Error Occured</NoProjectText>
          </SpinnerWrapper>
        )}
        {(!editId || (editId && !isFetching && !isError)) && (
          <>
            <Wrapper activePage={activePage} idNo={1}>
              <CreateCustom
                setNextStep={goToAddClient}
                pushToFormData={pushToFormData}
                editData={singleData}
              />
            </Wrapper>
            <Wrapper activePage={activePage} idNo={2}>
              <AddClient
                setNextStep={goToAllClient}
                selectedClient={selectedClient}
                onCreate={onSubmitHandler}
                isCreating={isCreating || isClientUpdating}
                editId={editId}
              />
            </Wrapper>
            <Wrapper activePage={activePage} idNo={3}>
              <AllClient
                selectClient={selectClient}
                setNextStep={goBack}
                isActivePage={activePage === 3}
                editData={singleData}
                selectedClient={selectedClient}
              />
            </Wrapper>
          </>
        )}
      </>
    </ModalWrapper>
  );
};

export default CustomSiteTour;

interface WrapperProps {
  idNo: number;
  activePage: number;
}

export const Wrapper = styled.div<WrapperProps>`
  display: ${({ idNo, activePage }) => idNo !== activePage && "none"};
`;

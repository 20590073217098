import React from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import ModalWrapper from "../ModalWrapper";
import CreateProject from "../../../CreateProject";
import { useNavigate, useSearchParams } from "react-router-dom";
import Allcontent from "./Allcontent";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export enum CreateProjectUrl {
  project = "project",
}

const ViewProject: React.FC<Props> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const handleCreateModalClose = () => navigate(`/admin/projects`);
  const setCreateUrl = () => {
    searchParams.set("create", CreateProjectUrl.project);
    setSearchParams(searchParams);
  };

  const [searchParams] = useSearchParams();
  const createType = searchParams.get("create");

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Header>
          <H6>Projects</H6>
          <CreateProjectBtn onClick={setCreateUrl}>
            <AddIcon />
            Create Project
          </CreateProjectBtn>
        </Header>
        <Allcontent />
        {createType === CreateProjectUrl.project && (
          <CreateProject
            open={createType === CreateProjectUrl.project}
            handleClose={handleCreateModalClose}
          />
        )}
      </Container>
    </ModalWrapper>
  );
};

export default ViewProject;

const Container = styled.div`
  min-width: 720px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const H6 = styled.h6`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  color: #2a3313;
`;

const CreateProjectBtn = styled.button`
  width: 188px;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

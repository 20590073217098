import React, { useCallback, useEffect, useState } from "react";
import { SingleOption } from "app/types/common";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { Form, Formik } from "formik";
import styled from "styled-components";
import {
  AmountSqmValidation,
  AmountValidation,
  SqmValidation,
  plotUnitValidation,
} from "../validation";
import ModalWrapper from "./ModalWrapper";
import { packagesObj } from "app/api/project/types";
import PaymentModal from "app/views/Client/components/payment modal";
import NumberFormatter from "app/views/Client/components/NumberFormatter";
import { toast } from "react-toastify";
import InputField from "app/views/Admin/components/formik inputs/InputField";

interface Props {
  open: boolean;
  handleClose: () => void;
  onPaymentSuccess: () => void;
  isInstallment: boolean;
  packageData: packagesObj;
}

const SelectSqmModal: React.FC<Props> = ({
  open,
  handleClose,
  isInstallment,
  packageData,
  onPaymentSuccess,
}) => {
  const [sqm, setSqm] = useState<SingleOption>();
  const [amount, setAmount] = useState("");
  const [plotUnit, setPlotUnit] = useState("");
  const [openPayment, setOpenPayment] = useState(false);
  const handlePaymentClose = () => setOpenPayment(false);

  const { items } = packageData;
  const isSingleItem = items.length === 1 ? true : false;

  const getSqmData = useCallback(() => {
    const result = items.filter((ev) => ev.sold_out === false);

    return result.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.size,
      };
      return temp;
    });
  }, [items]);

  useEffect(() => {
    if (isSingleItem) {
      const temp = getSqmData();
      setSqm(temp[0]);
    }
  }, [isSingleItem, getSqmData]);

  const getMinPriceData = () => {
    return items.map((ev) => {
      const temp = {
        value: ev.id,
        label: String(ev.min_price),
      };
      return temp;
    });
  };

  const getTitle = () => {
    if (isSingleItem && isInstallment) return "Enter Amount";
    if (!isSingleItem && isInstallment) return "Select";
    return "Select Squaremeter";
  };

  const getInitialState = () => {
    let temp: {
      plotUnit: string;
      sqm?: SingleOption;
      amount?: string;
    } = { plotUnit };
    if (!isSingleItem) {
      temp = { ...temp, sqm };
    }

    if (isInstallment) {
      temp = { ...temp, amount };
    }

    return temp;
  };

  const getSchema = () => {
    if (isSingleItem && !isInstallment) return plotUnitValidation;
    if (isSingleItem && isInstallment) return AmountValidation;
    if (!isSingleItem && isInstallment) return AmountSqmValidation;
    return SqmValidation;
  };

  const getMinPrice = () => {
    let minPriceData = getMinPriceData();
    if (isSingleItem) return +minPriceData[0].label * +plotUnit;
    return sqm
      ? +minPriceData.filter((ev) => ev.value === sqm.value)[0].label *
          +plotUnit
      : "";
  };

  const onInCorrectAmount = () => {
    if (+amount < Number(getMinPrice())) {
      toast.error("Amount entered is lesser than amount to be paid");
      return false;
    } else return true;
  };

  const onSubmit = () => {
    if (isSingleItem && isInstallment && onInCorrectAmount())
      return setOpenPayment(true);
    if (!isSingleItem && isInstallment && onInCorrectAmount())
      return setOpenPayment(true);
    if (!isInstallment) return setOpenPayment(true);
    return setOpenPayment(false);
  };

  return (
    <>
      {!openPayment && (
        <ModalWrapper open={open} title={getTitle()} handleClose={handleClose}>
          <>
            <Formik
              initialValues={getInitialState()}
              validationSchema={getSchema()}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form>
                  <Wrapper>
                    <InputField
                      name="plotUnit"
                      label="Units"
                      placeholder="Enter the number of units you want to buy"
                      value={plotUnit}
                      onChange={(e) => setPlotUnit(e.target.value)}
                      numberInput="number"
                      min={1}
                    />
                    {!isSingleItem && (
                      <SelectField
                        name="sqm"
                        label="Squaremeter"
                        value={sqm as SingleOption}
                        onChange={(e) => setSqm(e as SingleOption)}
                        options={getSqmData()}
                        selectType="normal"
                      />
                    )}

                    {isInstallment && sqm && (
                      <NumberFormatter
                        formik={formik}
                        name="amount"
                        label={`Amount (minimum price - \u20A6${Number(
                          getMinPrice()
                        ).toLocaleString("en-US")})`}
                        placeholder="Enter Amount"
                        onChange={(value) => setAmount(value)}
                      />
                    )}
                  </Wrapper>

                  <CreateBtn type="submit">Continue</CreateBtn>
                </Form>
              )}
            </Formik>
          </>
        </ModalWrapper>
      )}
      {openPayment && sqm && (
        <PaymentModal
          open={openPayment}
          handleClose={handlePaymentClose}
          isInstallment={isInstallment}
          packageItemId={sqm.value}
          packageName={packageData.name}
          projectName={packageData.project}
          amountToPay={amount}
          SQM={sqm.label}
          redirect={onPaymentSuccess}
          plotUnit={+plotUnit}
        />
      )}
    </>
  );
};

export default SelectSqmModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`;

const CreateBtn = styled.button`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin: auto;
  margin-top: 40px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

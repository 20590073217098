import React from "react";
import styled from "styled-components";
import ContentCard from "../cards/ContentCard";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Backdrop } from "@mui/material";
import { ContentTypes } from "../..";
import EventPic from "../../../../../images/admin/event-pic.svg";
import NewsPic from "../../../../../images/admin/news-pic.svg";
import ForumPic from "../../../../../images/admin/forum-pic.svg";
import BlogPic from "../../../../../images/admin/blog-pic.svg";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ContentModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>
        <h6>Choose a category that suits your new post.</h6>
        <GridWrapper>
          <ContentCard
            name="Adbond News and Promos"
            link={`${ContentTypes.newsAndPromos}`}
            img={NewsPic}
          />
          <ContentCard
            name="Events"
            link={`${ContentTypes.events}`}
            img={EventPic}
          />
          <ContentCard
            name="Forums"
            link={`${ContentTypes.forum}`}
            img={ForumPic}
          />
          <ContentCard name="Blog" url="blogpost/new" img={BlogPic} />
        </GridWrapper>
      </Container>
    </Backdrop>
  );
};

export default ContentModal;

const Container = styled.div`
  padding: 40px 80px 52px 80px;
  position: relative;
  background-color: white;
  border-radius: 16px;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 40px;
    text-align: center;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 24px;
  row-gap: 32px;
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

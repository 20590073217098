import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCompanySettings } from "app/api/utilities";
import { parseError } from "app/utils";
import { UserKeys } from "../users/types";
import { UtilitiesKeys } from "./types";

const useUpdateCompanySettings = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCompanySettings, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([UtilitiesKeys.getCompanyInfo]);
      queryClient.invalidateQueries([UserKeys.adminGetAUser]);
    },
  });
};

export default useUpdateCompanySettings;

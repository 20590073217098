import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import { heroAnimate } from "../components/cards/framer-variants";

const NewsLetter = () => {
  return (
    <Container whileInView={heroAnimate} viewport={{ once: true, amount: 0.5 }}>
      <H4>Be the first to know what we are up to!</H4>
      <Btn href="https://birdsend.page/forms/4821/8qWD7hTQGS" target={"_blank"}>
        Subscribe
      </Btn>
      {/* <Form>
        <Input placeholder="Enter your Email Address" />
      </Form> */}
    </Container>
  );
};

export default NewsLetter;

const Container = styled(motion.div)`
  display: flex;
  gap: 30px;
  align-items: center;
  flex-direction: column;
  padding: 53px 157px 35px 145px;
  background-color: #fbfdf7;
  box-shadow: 0 0 0 100vmax #fbfdf7;
  clip-path: inset(0 -100vmax);
  margin-bottom: 80px;
  min-height: 200px;

  ${mediaObj.mediumDesktop} {
    flex-direction: column;
    gap: 24px;
  }

  ${mediaObj.tablet} {
    padding: 53px 70px 35px 70px;
  }

  ${mediaObj.bigMobile} {
    padding: 53px 16px 35px 16px;
  }
`;

const H4 = styled.h4`
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 140%;
  color: #071e11;

  @media (max-width: 1440px) {
    font-size: 2rem;
  }

  ${mediaObj.mediumDesktop} {
    text-align: center;
  }

  ${mediaObj.tablet} {
    font-size: 1.7rem;
    text-align: center;
  }
`;

const Btn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 56px;
  background: #afd34f;
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 140%;
  color: #fbfbfb;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

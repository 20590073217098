import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import ActionRenderer from "./ActionRenderer";

interface Data {
  id: number;
  clientName: string;
  phone: string;
  amountOffered: string;
  date: string;
}

type TableField = keyof Data | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: Data[];
}

interface Props {
  data: Data[];
}

const SalesOfferTable: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: Data[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Name",
      field: "clientName",
      sortable: false,
      flex: 1.2,
      cellStyle: {
        color: "#3D9AC0",
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Phone no",
      field: "phone",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#143340",
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Amount",
      field: "amountOffered",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
      valueFormatter: (params) => {
        const { data }: { data: Data } = params;
        return `\u20A6 ${Number(data.amountOffered).toLocaleString("en-US")}`;
      },
    },
    {
      headerName: "Date",
      field: "date",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: Data } = params;
        return new Intl.DateTimeFormat("en-US").format(new Date(data.date));
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "",
      field: "action",
      sortable: false,
      width: 70,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const getRowStyle = (params: any): any => {
    return { borderBottom: "1px solid #ABABAB" };
  };

  return (
    <>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          rowHeight={50}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          getRowStyle={getRowStyle}
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          immutableData
          // getRowNodeId={(data: Data) => {
          //   return data.id.toString();
          // }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
    </>
  );
};

export default SalesOfferTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 300px;
  width: 100%;
  margin-bottom: 10px;
  overflow-y: scroll;
  overflow-x: scroll;

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  & * {
    overflow: visible !important;
  }
`;

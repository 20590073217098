import React from "react";
import { Route } from "react-router-dom";
import { Routes, useLocation } from "react-router";
import NotFound from "../Admin/notFound";
import ClientLayout from "./components/Layout";
import Dashboard from "./Dashboard";
import Settings from "./Settings";
import UserProfile from "./Settings/UserProfile";
import Security from "./Settings/Security";
import { PropertiesRoutes, UserRoutes } from "app/utils/data";
import Details from "./Settings/details";
import Properties from "./Properties";
import Projects from "./Projects";
import ProjectDetails from "./Projects/PackageDetails";
import Community from "./Community";
import PostDetails from "./Community/PostDetails";
import SiteTour from "./SiteTour";
import DetailsLayout from "./Properties/DetailsLayout";
import InstallmentPage from "./Properties/InstallmentPage";
import CompletePage from "./Properties/CompletePage";
import OffersPage from "./Properties/OffersPage";
import BidsPage from "./Properties/BidsPage";
import CommunityOffers from "./Community/CommunityOffers";
import Payment from "./Payment";

const ClientRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route path={UserRoutes.Root} element={<ClientLayout />}>
          <Route path={UserRoutes.Dashboard} element={<Dashboard />} />
          <Route path={UserRoutes.Properties} element={<Properties />} />
          <Route path={`${UserRoutes.Properties}`} element={<DetailsLayout />}>
            <Route
              path={`${UserRoutes.Properties}/${PropertiesRoutes.Installment}/:id`}
              element={<InstallmentPage />}
            />
            <Route
              path={`${UserRoutes.Properties}/${PropertiesRoutes.Complete}/:id`}
              element={<CompletePage />}
            />
            <Route
              path={`${UserRoutes.Properties}/${PropertiesRoutes.Offers}/:id`}
              element={<OffersPage />}
            />
            <Route
              path={`${UserRoutes.Properties}/${PropertiesRoutes.Bids}/:id`}
              element={<BidsPage />}
            />
          </Route>
          <Route path={UserRoutes.Settings.Index} element={<Settings />}>
            <Route path={UserRoutes.Settings.Details} element={<Details />} />
            <Route
              path={UserRoutes.Settings.Profile}
              element={<UserProfile />}
            />
            <Route path={UserRoutes.Settings.Security} element={<Security />} />
          </Route>
          <Route path={UserRoutes.Projects} element={<Projects />} />
          <Route
            path={`${UserRoutes.Projects}/:id`}
            element={<ProjectDetails />}
          />
          <Route path={UserRoutes.Community.Index} element={<Community />} />

          <Route
            path={`${UserRoutes.Community.BlogPost}/:postTitle`}
            element={<PostDetails />}
          />
          <Route
            path={`${UserRoutes.Community.Offers}`}
            element={<DetailsLayout />}
          >
            <Route
              path={`${UserRoutes.Community.Offers}/:id`}
              element={<CommunityOffers />}
            />
          </Route>
          <Route path={UserRoutes.SiteTour} element={<SiteTour />} />
          <Route path={UserRoutes.Payments} element={<Payment />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default ClientRoutes;

import { useNavigate, useParams } from "react-router";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import CreateSubject from "./components/createSubject";
import CreateQuestions from "./components/createQuestions";
import { assessmentObj } from "app/api/staff/types";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  setAssessment,
} from "app/redux/assessment/assessmentSlice";
import useAdminGetAssessment from "app/hooks/staffs/useAdminGetAssessment";

const CreateAssessment = () => {
  const [formData, setFormData] = useState<assessmentObj>();
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { assessment } = useAppSelector(assessmentSelector);
  const { data } = useAdminGetAssessment(Number(id));

  useEffect(() => {
    assessment && setFormData(assessment);
  }, [assessment]);

  useEffect(() => {
    if (!assessment && id && data) {
      setFormData(data);
      dispatch(setAssessment(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleGoBack = () => {
    navigate("/admin/staffs?activeTab=assessment");
  };

  return (
    <Container>
      <Header>
        <ArrowBackIcon onClick={() => handleGoBack()} />
        <HeaderText>Create an Assessment</HeaderText>
      </Header>
      {step === 1 && (
        <CreateSubject
          formData={formData}
          handleChange={handleChange}
          setStep={setStep}
        />
      )}
      {step === 2 && <CreateQuestions />}
    </Container>
  );
};

export default CreateAssessment;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px 40px;
  min-height: 80vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 35px;

  & > svg {
    width: 26px;
    height: 25px;
    cursor: pointer;
  }
`;

const HeaderText = styled.h5`
  font-weight: 500;
  font-size: 25px;
  color: #181d0b;
`;

export const Btn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  height: 46px;
  width: 130px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: #697f2f;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  & > div {
    margin: 0 11px 12px 0;
  }
`;

import { motion } from "framer-motion";
import {
  Anchor,
  BlueHr,
  Bold,
  Content,
  Li,
  Title,
  TitleBox,
  Ul,
} from "./styles";

const Privacy = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <TitleBox>
        <Title>The information we collect</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        When you visit our website, we may collect the following types of
        information:
        <Ul>
          <Li>
            <Bold>Personal information:</Bold> This includes your name, email
            address, phone number, and other contact information you provide to
            us through our website's contact form or other means of
            communication.
          </Li>

          <Li>
            <Bold>Device and usage information:</Bold>
            This includes your IP address, browser type, operating system, and
            other technical information about your device and how you use our
            website.
          </Li>

          <Li>
            <Bold>Cookies and other tracking technologies:</Bold>
            We use cookies and similar technologies to collect information about
            your browsing behaviour and preferences on our website.
          </Li>
        </Ul>
      </Content>

      <TitleBox>
        <Title>How we use your information</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We may use the information we collect for the following purposes:
        <Ul>
          <Li>To respond to your inquiries and provide customer support.</Li>

          <Li>To improve our website and the user experience.</Li>

          <Li>
            To send you promotional emails and other marketing communications if
            you have opted-in to receive them.
          </Li>

          <Li>
            To comply with legal obligations and protect our rights and
            interests.
          </Li>
        </Ul>
      </Content>

      <TitleBox>
        <Title>How we protect your information</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We take appropriate measures to protect your personal information from
        unauthorised access, disclosure, or misuse. These measures include
        encryption, firewalls, and access controls to secure our website and its
        databases.
      </Content>

      <TitleBox>
        <Title>What are your rights?</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        You have the following rights concerning your personal information:
        <Ul>
          <Li>
            To access and review the personal information we have collected
            about you.
          </Li>
          <Li>
            To request that we correct or delete any inaccurate or outdated
            information.
          </Li>
          <Li>
            To withdraw your consent to processing your personal information at
            any time.
          </Li>
          <Li>
            To object to the processing of your personal information for direct
            marketing purposes.
          </Li>
          <Li>
            File a complaint with a data protection authority if you believe
            your rights have been violated.
          </Li>
        </Ul>
        Be aware that you have the right to make a request as you wish to do. If
        you ever want us to fulfil any of your request, simply send an email to{" "}
        <Anchor href="mailTo: support@adbondharvestandhomes.com">
          support@adbondharvestandhomes.com
        </Anchor>{" "}
        and we’ll respond within the hour.
      </Content>

      <TitleBox>
        <Title>Are there third-party links and services?</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        Our website may contain links to third-party websites or services that
        are not under our control. We are not responsible for these websites or
        services' privacy practices or content.
      </Content>

      <TitleBox>
        <Title>Do we change our Privacy Policy?</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We may update this privacy policy from time to time. If we make any
        material changes to how we treat your date, we will notify you through a
        notice on the website homepage. The date the privacy policy was last
        revised will be identified at the top of the page. You are responsible
        for ensuring we have an up-to-date active and deliverable email address
        for you, and for periodically visiting our website and this privacy
        policy to check for any changes.
      </Content>

      <TitleBox>
        <Title>How does Adbond protect clients’ data?</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        Adbond Harvests and Homes believes that privacy and security are very
        vital. We use appropriate physical, technical, and administrative
        controls to protect the data we are responsible for. That includes
        encryption and other enhanced security measures, where appropriate or
        required. We also take appropriate steps to make sure that our clients
        and partners adopt consistent standards of protection for the data that
        Adbond is responsible for.  
        <br /> <br />
        However, it is important to note that the safety and security of your
        information also depends on you. Where you have chosen a password for
        access to our web-app you are responsible for keeping this password
        confidential.{" "}
        <Bold>We ask you not to share your password with anyone.</Bold>
      </Content>

      <TitleBox>
        <Title>Contact Us</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        For questions or concerns about our privacy policy, please contact us at{" "}
        <Anchor href="https://www.adbondharvestandhomes.com">
          https://www.adbondharvestandhomes.com
        </Anchor>{" "}
         or via <Anchor href="tel:+2349123978725"> +2349123978725</Anchor>. You
        can also visit us at our office on the 3rd and 4th-floor centage plaza,
        Allen avenue, Ikeja, Lagos state Nigeria.
      </Content>
    </motion.div>
  );
};

export default Privacy;

import { Box } from "@mui/material";
import { Dispatch, FC, SetStateAction, useState } from "react";
import styled from "styled-components";
import { ReactComponent as OptionIcon } from "app/images/option.svg";
import { ReactComponent as EditIcon } from "app/images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { questionPayload } from "app/api/staff/types";
import DeleteAssessObj from "./modals/deleteAssessObj";
import { useAppDispatch } from "app/redux";
import { deleteQuestion } from "app/redux/assessment/assessmentSlice";

interface props {
  data: questionPayload;
  index: number;
  editHandler: Dispatch<
    SetStateAction<
      | {
          data: questionPayload;
          index: number;
        }
      | undefined
    >
  >;
}

const SetQuestion: FC<props> = ({ data, index, editHandler }) => {
  const { options, question } = data;
  const [deleteQuestionId, setDeleteQuestionId] = useState<number>();

  const dispatch = useAppDispatch();

  const handleDelete = (id: number) => {
    dispatch(deleteQuestion(id));
  };

  return (
    <Wrapper>
      <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title>Question {index}</Title>
          <IconWrapper>
            <EditIcon onClick={() => editHandler({ data, index })} />
            <DeleteIcon
              onClick={() => setDeleteQuestionId(data.id || data.idx)}
            />
          </IconWrapper>
        </Box>
        {question && (
          <Question
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          />
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"9px"}>
        {options &&
          options.map(({ value, answer: isAnswer }, idx) => (
            <Box display={"flex"} alignItems={"center"} gap={"90px"} key={idx}>
              <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                <OptionIcon />
                {value && (
                  <Option
                    dangerouslySetInnerHTML={{
                      __html: value,
                    }}
                  />
                )}
              </Box>
              {isAnswer && <Answer>Answer</Answer>}
            </Box>
          ))}
      </Box>
      {(!!deleteQuestionId || deleteQuestionId === 0) && (
        <DeleteAssessObj
          onDelete={handleDelete}
          handleClose={() => setDeleteQuestionId(undefined)}
          isOpen={!!deleteQuestionId || deleteQuestionId === 0}
          id={deleteQuestionId}
        />
      )}
    </Wrapper>
  );
};

export default SetQuestion;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 12px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 144%;
  color: #181d0b;
`;

const Question = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: #2a3313;
`;

const Option = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #464646;
`;

const Answer = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #48b6e3;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > svg:first-of-type {
    fill: #43a047;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  & > svg:last-of-type {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { BioDataValidation } from "../components/validation";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { useAppDispatch } from "app/redux";
import { setMemberEmail } from "app/redux/assessment/assessmentSlice";
import useInitiateAssessment from "app/hooks/assessment/useInitiateAssessment";
import { initiateAssessmentPayload } from "app/api/assessment/types";
import { toast } from "react-toastify";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { genderOptions } from "app/views/Admin/Clients/data";
import { labelValueType } from "app/types/common";
import DualRing from "../../components/loader";
import { mediaObj } from "styles/utils/media";
import storage from "app/utils/storage";
import PhoneFormatter from "app/views/Admin/components/formik inputs/PhoneFormatter";

interface props {
  handleNext: () => void;
}

const BioData: FC<props> = ({ handleNext }) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<initiateAssessmentPayload>();
  const [gender, setGender] = useState<labelValueType>();
  const { mutate, error, isLoading } = useInitiateAssessment();
  const ref = storage.get("userRef") as string;

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  const handleChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = () => {
    if (formData) {
      mutate(
        { ...formData, gender: gender?.value, referal_code: ref },
        {
          onSuccess: () => {
            handleNext();
            storage.remove("userRef");
          },
        }
      );
    }
    dispatch(setMemberEmail(formData?.email));
  };

  return (
    <Wrapper>
      <TopContent>
        <Box display={"flex"} alignItems={"center"} gap={"22px"}>
          <GreenLine />
          <Title>Fill in your bio-data</Title>
        </Box>
        <PhaseCounter>Phase 1 of 2</PhaseCounter>
      </TopContent>
      <Formik
        initialValues={{
          firstname: formData?.firstname,
          lastname: formData?.lastname,
          email: formData?.email,
          phone_number: formData?.phone_number,
          address: formData?.address,
          occupation: formData?.occupation,
        }}
        validationSchema={BioDataValidation}
        onSubmit={onSubmitHandler}
        enableReinitialize
      >
        {(formik) => (
          <Form>
            <FlexWrapper>
              <InputField
                name="firstname"
                label="First name"
                placeholder="First Name"
                value={formData?.firstname || ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                isRequired
              />
              <InputField
                name="lastname"
                label="Last name"
                placeholder="Last Name"
                value={formData?.lastname || ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                isRequired
              />
            </FlexWrapper>
            <InputField
              name="email"
              label="Email address"
              inputType="email"
              placeholder="Email Address"
              value={formData?.email || ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              isRequired
            />
            {/* <InputField
            name="phone_number"
            label="Phone number"
            inputType="phone"
            placeholder="Phone Number"
            value={formData?.phone_number || ""}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            isRequired
          /> */}
            <PhoneFormatter
              name="phone_number"
              label="Phone number"
              formik={formik}
              value={formData?.phone_number}
              onChange={(value: string) => handleChange("phone_number", value)}
              isRequired
              borderStyle={"1px solid #bdbdbd"}
            />
            <InputField
              name="address"
              label="Home address"
              placeholder="Home Address"
              value={formData?.address || ""}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              isRequired
            />
            <FlexWrapper>
              <InputField
                name="occupation"
                label="Occupation"
                placeholder="Occupation"
                value={formData?.occupation || ""}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                isRequired
              />
              <SelectField
                name="gender"
                label="Gender"
                options={genderOptions}
                value={gender as labelValueType}
                onChange={(e) => setGender(e as labelValueType)}
                selectType="normal"
                isRequired
              />
            </FlexWrapper>
            <SubmitBtn type="submit">
              {isLoading ? (
                <DualRing color="#fff" width={"16px"} height={"16px"} />
              ) : (
                `Next`
              )}
            </SubmitBtn>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default BioData;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  & form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 85%;

    ${mediaObj.mobile} {
      width: 100%;
    }
  }
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GreenLine = styled.span`
  width: 120px;
  height: 8px;
  background: #afd34f;
  border-radius: 2px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #303030;
`;

const PhaseCounter = styled.span`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #535353;

  ${mediaObj.mobile} {
    font-size: 12px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${mediaObj.mobile} {
    flex-direction: column;
    gap: 24px;
  }
`;

const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 10px auto 0;
  width: 100%;
  height: 3.4rem;
  border: none;
  outline: none;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;

  & > div {
    margin: 0 11px 12px 0;
  }
`;

import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { mediaObj } from "../../../../../styles/utils/media";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { photosObj } from "app/api/project/types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "10px",
  padding: "1rem",
};

interface Props {
  open: boolean;
  handleClose: () => void;
  imageArray: photosObj[];
  packageName: string;
}

const ProjectImages: React.FC<Props> = ({
  open,
  handleClose,
  imageArray,
  packageName,
}) => {
  const [curSlide, setCurSlide] = useState(0);
  const slideLength = imageArray.length;

  const nextSlide = () => {
    if (curSlide === slideLength - 1) return;
    setCurSlide((prev) => prev + 1);
  };

  const prevSlide = () => {
    if (curSlide === 0) return;
    setCurSlide((prev) => prev - 1);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Container>
          <div className="cancel" onClick={() => handleClose()}>
            <CancelRoundedIcon />
          </div>

          <h5>Images of {packageName} Package </h5>

          <div className="content">
            <button onClick={prevSlide} disabled={curSlide === 0}>
              <ArrowBackIcon />
            </button>
            <ImageContainer>
              {imageArray.map((ev, i) => (
                <Image
                  key={i}
                  src={ev.url}
                  no={curSlide === 0 ? i : i - curSlide}
                />
              ))}
            </ImageContainer>
            <button onClick={nextSlide} disabled={curSlide === slideLength - 1}>
              <ArrowForwardIcon />
            </button>
          </div>

          <div className="mobile-footer">
            <button onClick={prevSlide} disabled={curSlide === 0}>
              <ArrowBackIcon />
            </button>

            <button onClick={nextSlide} disabled={curSlide === slideLength - 1}>
              <ArrowForwardIcon />
            </button>
          </div>
        </Container>
      </Box>
    </Modal>
  );
};

export default ProjectImages;

const Container = styled.div`
  padding: 3.125rem 4.0625rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h5 {
    align-self: flex-start;
    font-weight: 500;
    font-size: 25px;
    line-height: 140%;
    color: #2a3313;
    margin-bottom: 27px;
    text-transform: capitalize;
  }

  .cancel {
    color: #dc362e;
    position: absolute;
    top: 0.125rem;
    right: 0.125rem;
    cursor: pointer;
    width: max-content;
    height: max-content;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 5.0625rem;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
      width: 40px;
      height: 40px;
      background-color: #afd34f;
      border-radius: 50%;
      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .mobile-footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: white;
      width: 40px;
      height: 40px;
      background-color: #afd34f;
      border-radius: 50%;
      display: none;

      :disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  ${mediaObj.tablet} {
    padding: 3.125rem 1rem;
    .content {
      gap: 1rem;
    }
  }

  ${mediaObj.bigMobile} {
    width: 88vw;

    h5 {
      font-size: 20px;
    }

    .content {
      button {
        display: none;
      }
    }

    .mobile-footer {
      button {
        display: block;
      }
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 45vw;
  max-width: 900px;
  height: 500px;
  overflow: hidden;
  transition: transform 1s ease-in-out;
  border-radius: 8px;

  ${mediaObj.smallDesktop} {
    height: 400px;
  }
  ${mediaObj.tablet} {
    height: 300px;
  }

  ${mediaObj.bigMobile} {
    width: 70vw;
    height: 200px;
  }
`;

interface ImageProps {
  no: number;
}

const Image = styled.img<ImageProps>`
  transform: ${({ no }) => no && `translateX(${100 * no}%)`};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
  opacity: ${({ no }) => no !== 0 && 0};
`;

import React from "react";
import agroImage from "../../../../../images/hero-frame-2.png";
import Actions from "app/views/Admin/components/Actions";
import styled from "styled-components";
import AvatarContainer from "../AvatarContainer";
import { OfferResponse } from "app/api/offers/types";
import { useNavigate } from "react-router";
import { UserRoutes } from "app/utils/data";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { cardVariant } from "app/utils/animationVariants";
import { mediaObj } from "styles/utils/media";

interface Props {
  salesType?: "admin" | "user";
  data: OfferResponse;
}

const OfferCard: React.FC<Props> = ({ salesType = "user", data }) => {
  const navigate = useNavigate();

  return (
    <Container variants={cardVariant} custom={0.8}>
      <AbsoluteBox>
        <Tag>Sales offer</Tag>
        {salesType === "admin" && <Actions />}
      </AbsoluteBox>
      <ImgWrapper>
        <img src={agroImage} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <GridWrapper>
          <GridItem type="project">
            <span>Category: </span>
            <span>
              {data.customerPackageItem.packageItem.package.category.name}
            </span>
          </GridItem>
          <GridItem>
            <span>Project Type: </span>
            <span>
              {
                data.customerPackageItem.packageItem.package.project_location
                  .project
              }
            </span>
          </GridItem>
          <GridItem>
            <span>Location: </span>
            <span>
              {data.customerPackageItem.packageItem.package.state} State
            </span>
          </GridItem>
          <GridItem type="package">
            <span>Package: </span>
            <span>{data.customerPackageItem.packageItem.package.name}</span>
          </GridItem>
          <GridItem>
            <span>Squaremeter: </span>
            <span>{data.customerPackageItem.packageItem.size} </span>
          </GridItem>
          {salesType === "admin" ? (
            <div></div>
          ) : (
            <GridItem type="price">
              <span>Starting Price: </span>
              <span>
                {"\u20A6 "}
                {Number(data.offer_price).toLocaleString("en-US")}
              </span>
            </GridItem>
          )}

          {salesType === "admin" && (
            <>
              <GridItem type="price">
                <span>Old Price: </span>
                <span>3,000,000</span>
              </GridItem>

              <GridItem type="promo-price">
                <span>Promo Price: </span>
                <span>3,000,000</span>
              </GridItem>
            </>
          )}
          <Footer>
            <AvatarContainer
              name={data.client.firstname + " " + data.client.lastname}
              time={String(dayjs(data.created_at).format("DD MMM YYYY"))}
              value={data.client?.profile_photo?.url}
            />
          </Footer>

          <Footer>
            {salesType === "admin" ? (
              <PromoWrapper>
                <div>Promo Start Date: 01/10/2022</div>
                <div>Promo End Date: 01/10/2022</div>
              </PromoWrapper>
            ) : (
              <SeeDetailsBtn
                onClick={() =>
                  navigate(`${UserRoutes.Community.Offers}/${data.id}`)
                }
              >
                Learn more
              </SeeDetailsBtn>
            )}
          </Footer>
        </GridWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default OfferCard;

const Container = styled(motion.div)`
  width: 784px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;

  ${mediaObj.smallDesktop} {
    width: 100%;
    gap: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaObj.smallDesktop} {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  & > h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: #5e5e5e;
    margin: 20px 0;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  background: #535353;
  color: #fbfbfb;
  border-radius: 0px 8px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;

  ${mediaObj.tablet} {
    grid-template-columns: 1fr;
  }
`;

interface GridItemProps {
  type?: "package" | "price" | "promo-price" | "project";
}

const GridItem = styled.div<GridItemProps>`
  width: ${({ type }) => type === "project" && "max-content"};
  padding: ${({ type }) => type === "project" && "4px 8px"};
  background-color: ${({ type }) => type === "project" && "#CDE6F5"};
  border-radius: ${({ type }) => type === "project" && "4px"};
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #777777;
    color: ${({ type }) => type === "project" && "#2A3313"};
    color: ${({ type }) => type === "package" && "#AFD34F"};
    color: ${({ type }) => type === "price" && "#697F2F"};
    color: ${({ type }) => type === "promo-price" && "#49B7E4"};
    text-transform: capitalize;
  }

  ${mediaObj.bigMobile} {
    font-size: 0.875rem;
  }
`;

const Footer = styled.div`
  margin-top: 32px;

  ${mediaObj.bigMobile} {
    margin-top: 10px;
  }
`;

const SeeDetailsBtn = styled.button`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  margin: auto;

  &:hover {
    color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const PromoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
  }

  & > div:nth-child(1) {
    color: #809a39;
  }

  & > div:nth-child(2) {
    color: #b3261e;
  }
`;

import { Box } from "@mui/material";
import {
  ApprovedStatus,
  BidsResponse,
  OfferResponse,
} from "app/api/offers/types";
import { useDeleteBid } from "app/hooks/offers/useBid";
import { useDeleteOffer } from "app/hooks/offers/useOffer";
import { PropertiesRoutes, UserRoutes } from "app/utils/data";
import Actions from "app/views/Admin/components/Actions";
import { FC } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface BidsProps {
  isBid: true;
  data: BidsResponse;
}

interface OfferProps {
  isBid: false;
  data: OfferResponse;
}

type Props = BidsProps | OfferProps;

const SalesBidCard: FC<Props> = ({ isBid, data }) => {
  const navigate = useNavigate();

  const { mutate: deleteOfferMutate, isLoading: isOfferDeleting } =
    useDeleteOffer();
  const { mutate: deleteBidMutate, isLoading: isBidDeleting } = useDeleteBid();

  const onEdit = () => {
    isBid
      ? navigate(
          `${UserRoutes.Community.Offers}/${data.offer_id}?edit=${data.id}`
        )
      : navigate(
          `${UserRoutes.Properties}/${PropertiesRoutes.Complete}/${data.customerPackageItem.id}?edit=${data.id}`
        );
  };

  const onOfferDelete = () => {
    const payload = {
      offer_id: data.id,
    };
    deleteOfferMutate(payload, {
      onSuccess: () => toast.success("Offer deleted sucessfully!"),
    });
  };

  const onBidDelete = () => {
    const payload = {
      bid_id: data.id,
    };
    deleteBidMutate(payload, {
      onSuccess: () => toast.success("Bid deleted sucessfully!"),
    });
  };

  return (
    <Wrapper
      applyCursor={
        isBid ? data.accepted : data.approved_status === ApprovedStatus.Approved
      }
      onClick={() =>
        isBid
          ? data.accepted &&
            navigate(
              `${UserRoutes.Properties}/${PropertiesRoutes.Bids}/${data.id}`
            )
          : data.approved_status === ApprovedStatus.Approved &&
            navigate(
              `${UserRoutes.Properties}/${PropertiesRoutes.Offers}/${data.id}`
            )
      }
    >
      <AbsoluteBox
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <AbsoluteWrapper>
          <Actions
            deleteText="Delete"
            editText="Edit Price"
            width="max-content"
            isDeleting={isBidDeleting || isOfferDeleting}
            deleteHandler={isBid ? onBidDelete : onOfferDelete}
            editHandler={onEdit}
            noEdit={
              isBid
                ? data.accepted
                : data.approved_status === ApprovedStatus.Rejected ||
                  data.approved_status === ApprovedStatus.Approved
            }
          />

          {isBid ? (
            <BidStatus bidStatus={data.accepted}>
              {data.accepted ? "Accepted" : "Pending"}
            </BidStatus>
          ) : (
            <OfferStatus offerStatus={data.approved_status}>
              {data.approved_status}
            </OfferStatus>
          )}
        </AbsoluteWrapper>
      </AbsoluteBox>
      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
        <Label>Property</Label>
        <Detail>{data.project}</Detail>
      </Box>
      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
        <Label>Package</Label>
        <Detail width="150px">
          {data.customerPackageItem.packageItem.package.name}
        </Detail>
      </Box>
      <Box display={"flex"} gap={"8px"} alignItems={"center"}>
        <Label>Starting price</Label>
        <Detail>
          {"\u20A6 "}
          {Number(data.offer_price).toLocaleString("en-US")}
        </Detail>
      </Box>
      {isBid ? (
        <Box display={"flex"} gap={"8px"} alignItems={"center"}>
          <Label bg="#49B7E4">Your Bid</Label>
          <Detail>
            {"\u20A6 "}
            {Number(data.bid__price).toLocaleString("en-US")}
          </Detail>
        </Box>
      ) : data.approved_status === "rejected" ? (
        <Box display={"flex"} gap={"8px"} alignItems={"flex-start"}>
          <Label bg="#DD1717" color="white">
            Rejected Reason
          </Label>
          <Detail>{data.rejected_reason}</Detail>
        </Box>
      ) : (
        <Offers>{data.bids.length} offers made</Offers>
      )}
    </Wrapper>
  );
};

export default SalesBidCard;

interface WrapperProps {
  applyCursor: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  cursor: ${({ applyCursor }) => applyCursor && "pointer"};

  ${mediaQueries.mobile} {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
`;

const AbsoluteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

interface LabelProps {
  bg?: string;
  color?: string;
}

const Label = styled.span<LabelProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 12px;
  color: ${({ color }) => (color ? color : "#fbfbfb")};
  background: ${({ bg }) => (bg ? bg : "#143340")};
  border-radius: 2px;
  flex-shrink: 0;
`;

interface DetailProps {
  width?: string;
}

const Detail = styled.p<DetailProps>`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #5e5e5e;
  width: ${({ width }) => width};
`;

const Offers = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #48b6e3;
  margin-top: 8px;
`;

interface OfferStatusProps {
  offerStatus: ApprovedStatus;
}

const OfferStatus = styled.span<OfferStatusProps>`
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ offerStatus }) =>
    offerStatus === ApprovedStatus.Pending && "#777777"};
  color: ${({ offerStatus }) =>
    offerStatus === ApprovedStatus.Approved && "#97B644"};
  color: ${({ offerStatus }) =>
    offerStatus === ApprovedStatus.Rejected && "#DD1717"};
  margin-right: 16px;
  text-transform: capitalize;
`;

interface BidStatusProps {
  bidStatus: boolean;
}

const BidStatus = styled.span<BidStatusProps>`
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  color: ${({ bidStatus }) => (bidStatus ? "#97B644" : "#777777")};
  margin-right: 16px;
  text-transform: capitalize;
`;

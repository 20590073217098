import {
  PromoCategoryObj,
  PromoObj,
  PromoProjectObj,
  PromoProjectLocationObj,
  PromoPackageObj,
} from "app/api/offers/types";
import { cardVariant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import AvatarContainer from "../AvatarContainer";
import PromoCategory from "../PromoCategory";
import PromoLocation from "../PromoLocation";
import PromoPackage from "../PromoPackage";
import PromoProject from "../PromoProject";

enum TypeProps {
  category = "category",
  project = "project",
  project_location = "project_location",
  package = "package",
}

interface Props {
  data: PromoObj;
}

const PromoCard: React.FC<Props> = ({ data }) => {
  return (
    <Container variants={cardVariant} custom={0.8}>
      <AbsoluteBox>
        <Tag>Promo offer</Tag>
      </AbsoluteBox>
      <ContentWrapper>
        <Title>{data.title}</Title>
        <Description>{data.description}</Description>
        <BottomWrapper>
          <GridWrapper>
            {data.products[0].type === TypeProps.category && (
              <PromoCategory data={data.products as PromoCategoryObj[]} />
            )}

            {data.products[0].type === TypeProps.project && (
              <PromoProject data={data.products as PromoProjectObj[]} />
            )}

            {data.products[0].type === TypeProps.project_location && (
              <PromoLocation
                data={data.products as PromoProjectLocationObj[]}
              />
            )}

            {data.products[0].type === TypeProps.package && (
              <PromoPackage data={data.products as PromoPackageObj[]} />
            )}

            <GridItem type="promo-price">
              <span>Promo Discount: </span>
              <span>{data.discount}%</span>
            </GridItem>
          </GridWrapper>
          <Footer>
            <AvatarContainer
              name={data.user.firstname + " " + data.user.lastname}
              time={""}
              value={data.user?.profile_photo?.url}
            />

            <PromoWrapper>
              <div>Promo Start Date: {data.start}</div>
              <div>Promo End Date: {data.end}</div>
            </PromoWrapper>
          </Footer>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default PromoCard;

const Container = styled(motion.div)`
  width: 784px;
  min-height: 340px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;

  ${mediaObj.smallDesktop} {
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    padding: 10px;
  }

  ${mediaQueries.mobile} {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;

  & > h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: #5e5e5e;
    margin: 20px 0;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: auto;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  background: #535353;
  color: #fbfbfb;
  border-radius: 0px 8px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 140%;
  color: #2a3313;
  text-transform: capitalize;
`;

const Description = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #5e5e5e;
  margin: 16px 0 24px;
`;

interface GridItemProps {
  type?: "package" | "price" | "promo-price" | "project";
}

const GridItem = styled.div<GridItemProps>`
  width: ${({ type }) => type === "project" && "max-content"};
  padding: ${({ type }) => type === "project" && "4px 8px"};
  background-color: ${({ type }) => type === "project" && "#CDE6F5"};
  border-radius: ${({ type }) => type === "project" && "4px"};
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #777777;
    color: ${({ type }) => type === "project" && "#2A3313"};
    color: ${({ type }) => type === "package" && "#AFD34F"};
    color: ${({ type }) => type === "price" && "#697F2F"};
    color: ${({ type }) => type === "promo-price" && "#49B7E4"};
    text-transform: capitalize;
  }
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  align-items: center;
`;

const PromoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > div {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
  }

  & > div:nth-child(1) {
    color: #809a39;
  }

  & > div:nth-child(2) {
    color: #b3261e;
  }
`;

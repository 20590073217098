import { useQuery } from "@tanstack/react-query";
import { getCompanyInfo } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetCompanyInfo = () => {
  return useQuery([UtilitiesKeys.getCompanyInfo], getCompanyInfo, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetCompanyInfo;

import { Box } from "@mui/material";
import DashboardTotalCard from "../components/Cards/dashboardTotal";
import IncomeSection from "./components/incomeSection";
import NewProjects from "./components/newProjects";
import ProjectSales from "../components/projectSales";
import RecentSales from "./components/recentSales";
import UpcomingEvents from "./components/upcomingEvents";
import { ReactComponent as ProjectIcon } from "../../../images/admin/total-projects.svg";
import { ReactComponent as ActiveProjectIcon } from "../../../images/admin/active-projects.svg";
import { ReactComponent as TotalClientIcon } from "../../../images/admin/total-clients.svg";
import useGetProjectCount from "app/hooks/projects/useGetProjectCount";
import useGetTotalActiveProject from "app/hooks/utilities/useGetTotalActiveProject";
import useGetTotalClients from "app/hooks/utilities/useGetTotalClients";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const navigate = useNavigate();
  const { data: projectCount, isLoading: projectCountLoading } =
    useGetProjectCount();

  const { data: activeCount, isLoading: activeCountLoading } =
    useGetTotalActiveProject();

  const { data: clientCount, isLoading: clientCountLoading } =
    useGetTotalClients();

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <DashboardTotalCard
          label={"Total Projects"}
          btnLabel={"View all projects"}
          icon={ProjectIcon}
          value={projectCount || 0}
          height={"164px"}
          isLoading={projectCountLoading}
          onViewMore={() => navigate("/admin/projects")}
        />
        <DashboardTotalCard
          label={"Active Projects"}
          btnLabel={"View active projects"}
          icon={ActiveProjectIcon}
          value={activeCount || 0}
          height={"164px"}
          isLoading={activeCountLoading}
          onViewMore={() => navigate("/admin/projects")}
        />
        <DashboardTotalCard
          label={"Total Existing Clients"}
          btnLabel={"View all clients"}
          icon={TotalClientIcon}
          value={clientCount || 0}
          height={"164px"}
          isLoading={clientCountLoading}
          onViewMore={() => navigate("/admin/clients")}
        />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} mt={5}>
        <ProjectSales />
        <IncomeSection />
      </Box>
      <Box display={"flex"} justifyContent={"space-between"} mt={6}>
        <UpcomingEvents />
        <NewProjects />
      </Box>
      <RecentSales />
    </Box>
  );
};

export default Dashboard;

import styled from "styled-components";
import media from "../../../../styles/utils/media";

import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

const ScheduleTourPage = () => {
  return (
    <Container>
      <LeftSection />
      <RightSection />
    </Container>
  );
};

export default ScheduleTourPage;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  margin: 9rem auto 4.25rem;

  ${media.mediumDesktop`
  width: 100%;
  `}

  ${media.smallDesktop`
  grid-template-columns: 1fr;
  width: 80%;
        `}


    ${media.bigMobile`
        margin-left: unset;
        margin-right: unset;
        width: 100%;
        margin-top: 7rem;
        `}
`;

import { OfferResponse } from "app/api/offers/types";
import React, { useEffect, useState } from "react";
import { useOutletContext, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import MakeOfferModal from "./components/modals/MakeOfferModal";
import { ReactComponent as ImgLogo } from "../../../images/web/Adbond Logo 1.svg";

const CommunityOffers = () => {
  const [searchParams] = useSearchParams();
  const editType = searchParams.get("edit");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { offerData }: { offerData: OfferResponse } = useOutletContext();

  useEffect(() => {
    if (editType) {
      setOpen(true);
    }
  }, [editType]);

  return (
    <Wrapper>
      <FlexWrapper>
        <PropertyLabel>Property Owner:</PropertyLabel>
        <PropertyValue>
          <AvatarImg hasValue={!!offerData.client.profile_photo}>
            {offerData.client.profile_photo ? (
              <img src={offerData.client.profile_photo.url} alt="avatar" />
            ) : (
              <ImgLogo />
            )}
          </AvatarImg>
          <span>
            {offerData.client.firstname + " " + offerData.client.lastname}
          </span>
        </PropertyValue>
      </FlexWrapper>
      <FlexWrapper>
        <SqmLabel>Squaremeter:</SqmLabel>
        <SqmValue>{offerData.customerPackageItem.packageItem.size}</SqmValue>
      </FlexWrapper>
      <FlexWrapper>
        <ValuationLabel>Sale Price:</ValuationLabel>
        <ValuationValue>
          {" "}
          {"\u20A6 "}
          {Number(offerData.offer_price).toLocaleString("en-US")}
        </ValuationValue>
      </FlexWrapper>

      <BuyBtn onClick={() => setOpen(true)}>Make Offer</BuyBtn>
      {open && (
        <MakeOfferModal
          open={open}
          handleClose={handleClose}
          isEdit={editType}
        />
      )}
    </Wrapper>
  );
};

export default CommunityOffers;

const Wrapper = styled.div`
  padding: 24px 13px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PropertyLabel = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #181d0b;
`;

const PropertyValue = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

interface AvatarImgProps {
  hasValue: boolean;
}

const AvatarImg = styled.div<AvatarImgProps>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${({ hasValue }) => !hasValue && "#DDEEF8"};
  img,
  svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }

  ${mediaQueries.mobile} {
    width: 30px;
    height: 30px;
  }
`;

const SqmLabel = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #181d0b;
`;

const SqmValue = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #2a3313;
`;

const ValuationLabel = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #2a3313;
`;

const ValuationValue = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #697f2f;
`;

const BuyBtn = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 40px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 230px;
    margin: 0 auto;
  }
`;

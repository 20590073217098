import { ItemsPricesObj } from "app/api/project/types";
import React from "react";
import styled from "styled-components";

interface Props {
  item: ItemsPricesObj;
  index: number;
}

const PackageItems: React.FC<Props> = ({ item, index }) => {
  const fullPrice = Number(item.full_price).toLocaleString("en-US");
  const fullPromoPrice = Number(item.promo.full_payment).toLocaleString(
    "en-US"
  );
  const discountPrice = Number(
    item.full_price / item.installment_duration
  ).toLocaleString("en-US");
  const discountPromoPrice = Number(item.promo.installment).toLocaleString(
    "en-US"
  );
  const isOnPromo = item.onPromo;
  const isSoldOut = item.sold_out;

  return (
    <PricingContainer colorId={index + 1} key={index} isSoldOut={isSoldOut}>
      {isSoldOut && <SoldOut>Sold Out</SoldOut>}
      {!isSoldOut && isOnPromo && (
        <OnPromo colorId={index + 1}>Promo offer</OnPromo>
      )}

      <h3>{item.size}sqm</h3>
      <span>{item.discount}% Discount on Outright Purchase </span>
      <PromoFullPrice>
        {isOnPromo && <OldFullPrice>#{fullPrice}</OldFullPrice>}
        <FullPrice>#{isOnPromo ? fullPromoPrice : fullPrice}</FullPrice>
      </PromoFullPrice>
      <h6>or</h6>

      <DiscountBox>
        <PromoDiscountPrice>
          {isOnPromo && <OldDiscountPrice>#{discountPrice}</OldDiscountPrice>}

          <DiscountPrice>
            #{isOnPromo ? discountPromoPrice : discountPrice}
          </DiscountPrice>
        </PromoDiscountPrice>
        for {item.installment_duration} months
      </DiscountBox>
      <span className="note">
        Note: Project Infrastructural Fee (#
        {item.infrastructure_fee} per sqm)
      </span>
      <div className="circle_1"></div>
      <div className="circle_2"></div>
    </PricingContainer>
  );
};

export default PackageItems;

interface PricingContainerProps {
  colorId: number;
  isSoldOut: boolean;
}

const PricingContainer = styled.div<PricingContainerProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  background: ${({ colorId }) => (colorId % 2 === 0 ? "#AFD34F" : "#3d9ac0")};
  background: ${({ isSoldOut }) => isSoldOut && "#77777780"};
  color: ${({ colorId }) => (colorId % 2 === 0 ? "#2D2D2D" : "#fbfbfb")};
  color: ${({ isSoldOut }) => isSoldOut && "#2D2D2D"};
  border-radius: 8px;
  padding: 13px;
  overflow: hidden;

  h3 {
    font-weight: 500;
    font-size: 31px;
    line-height: 140%;
    text-transform: lowercase;
  }

  span {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
  }

  h6 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
  }
  .circle_1,
  .circle_2 {
    position: absolute;
    width: 74.62px;
    height: 66.33px;
    background: ${({ colorId }) => (colorId % 2 !== 0 ? "#AFD34F" : "#3d9ac0")};
    background: ${({ isSoldOut }) => isSoldOut && "#536425"};
    border-radius: 100%;
  }

  .circle_1 {
    right: -38px;
    top: -16.38px;
  }
  .circle_2 {
    left: -38px;
    bottom: -16.38px;
  }
  .note {
    z-index: 1;
  }
`;

const PromoFullPrice = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`;

const PromoDiscountPrice = styled.span`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`;

const DiscountBox = styled.h5`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const OldFullPrice = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  margin-top: 7px;
  text-decoration: line-through;
  align-self: flex-end;
  margin-top: -0.5rem;
`;

const OldDiscountPrice = styled.h5`
  font-weight: 450;
  font-size: 11px;
  line-height: 140%;
  text-decoration: line-through;
  align-self: flex-end;
  margin-top: -0.4rem;
`;

const FullPrice = styled.h4`
  font-weight: 500;
  font-size: 25px;
  line-height: 140%;
  margin-top: 7px;
`;

const DiscountPrice = styled.h5`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
`;

interface OnPromoProps {
  colorId: number;
}

const OnPromo = styled.div<OnPromoProps>`
  position: absolute;
  left: 0;
  top: 0;
  background: #afd34f;
  border-radius: 8px 0px 0px 0px;
  padding: 4px 8px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;
  background: ${({ colorId }) => (colorId % 2 !== 0 ? "#AFD34F" : "#3d9ac0")};
`;

const SoldOut = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: #181d0b;
  border-radius: 8px 0px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

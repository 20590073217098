import { useMutation } from "@tanstack/react-query";
import { verifyOfferTransaction, verifyTransaction } from "app/api/payment";
import { parseError } from "app/utils";

const useVerifyTransaction = () => {
  return useMutation(verifyTransaction, {
    onError: (error) => parseError(error),
  });
};

export default useVerifyTransaction;

export const useVerifyOfferTransaction = () => {
  return useMutation(verifyOfferTransaction, {
    onError: (error) => parseError(error),
  });
};

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import media, { mediaObj } from "../../../../styles/utils/media";
import Flex from "../../../../styles/elements/Flex";
import InputField from "../components/form/InputField";
import FormModal from "../components/modal/FormModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScheduleBox from "../components/form/ScheduleBox";
import { Form, Formik } from "formik";
import { basicDetailsValidation, tourDatesValidation } from "./data";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { toast } from "react-toastify";
import { SingleOption } from "app/types/common";
import Spinner from "../components/Spinner";
import { motion } from "framer-motion";
import { staggerContainer } from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import useGetInspectionDaysPerLocation from "app/hooks/site tour/useGetInspectionDaysPerLocation";
import usePostSiteInspection from "app/hooks/site tour/usePostSiteInspection";
import PhoneFormatter from "app/views/Admin/components/formik inputs/PhoneFormatter";

const RightSection = () => {
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState<SingleOption>();
  const [propertyType, setPropertyType] = useState<SingleOption>();
  const [packages, setPackages] = useState<SingleOption>();
  const [locations, setLocations] = useState<SingleOption>();
  const [selectedTourDate, setSelectedTourDate] = useState<{
    label: string;
    value: number;
  }>();

  const isMobile = useMobileCheck();

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(category?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(propertyType?.value as number);

  const { data: packagePerLocationData, isLoading: packagePerLocationLoading } =
    useGetAllPackagePerLocation(locations?.value as number);

  const { data: inspectionDaysData, isLoading: isInspecting } =
    useGetInspectionDaysPerLocation(locations?.value as number);

  const { mutate: createMutate, isLoading: isCreating } =
    usePostSiteInspection();

  const formatDate = (date: string) => {
    const result = new Date(date);
    return result.toISOString().substring(0, 10);
  };

  useEffect(() => {
    setPropertyType(undefined);
    setLocations(undefined);
  }, [category]);

  useEffect(() => {
    setLocations(undefined);
    setPackages(undefined);
  }, [propertyType]);

  useEffect(() => {
    setPackages(undefined);
  }, [locations]);

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setCategory(undefined);
    setPropertyType(undefined);
    setPackages(undefined);
    setLocations(undefined);
    setSelectedTourDate(undefined);
    setStep(1);
  };

  const goBackHandler = () => {
    setSelectedTourDate(undefined);
    setStep(1);
  };

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  const onSubmit = async () => {
    if (!selectedTourDate || !packages) return;
    const formResult = {
      firstname: firstName,
      lastname: lastName,
      email,
      package_id: packages.value,
      proposed_date: formatDate(selectedTourDate.label),
      inspection_day_id: selectedTourDate.value,
      phone_number: phone,
    };
    createMutate(formResult, {
      onSuccess: (data) => {
        toast.success(data.data.message);
        setOpen(true);
      },
    });
  };

  const isAvailableDate = inspectionDaysData && inspectionDaysData.length > 0;

  return (
    <Formik
      initialValues={
        step === 2
          ? {
              selectedTourDate,
            }
          : {
              firstName,
              lastName,
              phone,
              email,
              propertyType,
              category,
              packages,
              locations,
            }
      }
      validationSchema={
        step === 2 ? tourDatesValidation : basicDetailsValidation
      }
      enableReinitialize
      onSubmit={() => {
        if (step === 2) {
          onSubmit();
        } else {
          setStep(2);
        }
      }}
    >
      {(formik) => (
        <Form>
          <RightContainer>
            {step === 1 ? (
              <InputWrapper
                variants={staggerContainer}
                custom={isMobile ? 0.8 : 0.5}
                initial="initial"
                whileInView="animate"
                viewport={{ amount: isMobile ? 0.2 : 0.5, once: true }}
              >
                <Flex gap="17px">
                  <InputField
                    name="firstName"
                    label="First name"
                    onChange={(e: any) => setFirstName(e.target.value)}
                  />
                  <InputField
                    name="lastName"
                    label="Last name"
                    onChange={(e: any) => setLastName(e.target.value)}
                  />
                </Flex>
                <PhoneFormatter
                  name="phone"
                  label="Phone number"
                  formik={formik}
                  onChange={(value: string) => setPhone(value)}
                />
                <InputField
                  name="email"
                  label="Email address"
                  onChange={(e: any) => setEmail(e.target.value)}
                />
                <SelectField
                  name="category"
                  label="Category"
                  value={category as SingleOption}
                  onChange={(e) => setCategory(e as SingleOption)}
                  options={formatData(allCategoryData)}
                  selectType="normal"
                  isLoading={allCategoryLoading}
                  borderStyle="1px solid #181d0b"
                  applyVariant
                />

                <SelectField
                  name="propertyType"
                  label="Project Type"
                  value={propertyType as SingleOption}
                  onChange={(e) => setPropertyType(e as SingleOption)}
                  disabled={!category}
                  options={formatData(projectPerCategoryData)}
                  selectType="normal"
                  isLoading={projectPerCategoryLoading}
                  borderStyle="1px solid #181d0b"
                  applyVariant
                />

                <Flex gap="17px">
                  <SelectField
                    name="locations"
                    label="Locations"
                    value={locations as SingleOption}
                    onChange={(e) => setLocations(e as SingleOption)}
                    disabled={!propertyType}
                    options={formatData(locationPerProjectData)}
                    selectType="normal"
                    isLoading={locationPerProjectLoading}
                    borderStyle="1px solid #181d0b"
                    applyVariant
                  />
                  <SelectField
                    name="packages"
                    label="Package"
                    value={packages as SingleOption}
                    onChange={(e) => setPackages(e as SingleOption)}
                    disabled={!locations}
                    options={formatData(packagePerLocationData)}
                    selectType="normal"
                    isLoading={packagePerLocationLoading}
                    borderStyle="1px solid #181d0b"
                    applyVariant
                  />
                </Flex>
              </InputWrapper>
            ) : (
              <>
                <Header>
                  <Back>
                    <ArrowBackIcon onClick={goBackHandler} />
                    <span onClick={goBackHandler}>Back</span>
                  </Back>

                  <h6>Complete site tour scheduling</h6>
                </Header>
                {!isAvailableDate ? (
                  <EmptyContent>
                    We have no site tour for this package yet
                  </EmptyContent>
                ) : (
                  <Content>
                    <h6>Choose from the available site tour date </h6>
                    <ScheduleWrapper>
                      {inspectionDaysData?.map((item) => (
                        <ScheduleBox
                          key={item.id}
                          value={item.id}
                          label={new Date(item.day.dates[0]).toLocaleString(
                            "en-us",
                            {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            }
                          )}
                          selectedValue={selectedTourDate?.value}
                          setSelectedTourDate={setSelectedTourDate}
                          name="selectedTourDate"
                        />
                      ))}
                    </ScheduleWrapper>
                  </Content>
                )}

                <NoteWrapper>
                  Note: You can reach out to us on{" "}
                  <InfoLink href="mailTo: sitetour@adbondharvestandhomes.com">
                    {" "}
                    sitetour@adbondharvestandhomes.com{" "}
                  </InfoLink>{" "}
                  for any information or to schedule a special site tour
                </NoteWrapper>
              </>
            )}

            <Button type="submit" disabled={!isAvailableDate && step === 2}>
              {/* {loading ? (
                <Spinner color="white" />
              ) : step === 1 ? (
                "Continue"
              ) : (
                "Submit"
              )} */}
              {((step === 2 && isInspecting) || isCreating) && (
                <Spinner color="black" />
              )}
              {step === 1 ? "Continue" : "Submit"}
            </Button>

            <FormModal
              open={open}
              handleClose={() => {
                setOpen(false);
                clearForm();
              }}
            />
          </RightContainer>
        </Form>
      )}
    </Formik>
  );
};

export default RightSection;

const RightContainer = styled(motion.div)`
  padding: 3rem 2.6875rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #3d9ac0;
  border-radius: 0px 0.75rem 0.75rem 0px;
  min-height: 720px;

  ${media.smallDesktop`
  background: transparent;
  border-radius: 0;
  padding: 0 1.25rem;
  border:none;
        `}

  ${media.bigMobile`
        padding: 0;
        `}
`;

const InputWrapper = styled(motion.div)`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  width: 100%;
  height: 52px;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 1rem;
  background: #afd34f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;

  :disabled {
    background: #d8e8b8;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 56px;

  h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
  }

  ${mediaObj.mobile} {
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }
`;

const Back = styled.button`
  display: flex;
  align-items: center;
  gap: 14px;
  color: #afd34f;
  cursor: pointer;
  background-color: transparent;
  border: none;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
  }

  ${mediaObj.mobile} {
    gap: 5%;
  }
`;

const EmptyContent = styled.div`
  background: #cde6f5;
  border-radius: 8px;
  padding: 24px;
  margin-top: 42px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.bigMobile} {
    margin-top: 25px;
  }
`;

const Content = styled.div`
  background: #cde6f5;
  border-radius: 8px;
  padding: 24px;
  margin-top: 42px;
  margin-bottom: 25px;

  h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 24px;
  }

  ${mediaObj.mobile} {
    padding: 16px;
  }
`;

const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const NoteWrapper = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #2a3313;
`;

const InfoLink = styled.a`
  color: #afd34f;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deactivatePackage, deactivateProjectLocation } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useDeactivateProjectLocation = () => {
  const queryClient = useQueryClient();

  return useMutation(deactivateProjectLocation, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.allProjectPerCatgory]);
    },
  });
};

export default useDeactivateProjectLocation;

export const useDeactivatePackage = () => {
  const queryClient = useQueryClient();

  return useMutation(deactivatePackage, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.singlePackage]);
    },
  });
};

import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import AllCategory from "./components/AllCategory";

const Projects = () => {
  return (
    <Container>
      <Header>Projects</Header>
      <AllCategory />
    </Container>
  );
};

export default Projects;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    padding: 0 0 10px;
    background: transparent;
  }
`;

const Header = styled.h5`
  font-weight: 500;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;

  ${mediaQueries.mobile} {
    font-size: 20px;
  }
`;

export interface tabProps {
  isActive?: boolean;
}

export const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;

  ${mediaQueries.mobile} {
    font-size: 18px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;

  ${mediaQueries.mobile} {
    height: 30vh;
  }
`;

import { usePlaceBid, useUpdateBid } from "app/hooks/offers/useBid";
import { UserRoutes } from "app/utils/data";
import NumberFormatter from "app/views/Client/components/NumberFormatter";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { MakeOfferValidation } from "../validation";
import ModalWrapper from "./ModalWrapper";

interface Props {
  isEdit: string | null;
  open: boolean;
  handleClose: () => void;
}

const MakeOfferModal: React.FC<Props> = ({ open, handleClose, isEdit }) => {
  const navigate = useNavigate();
  const { id: packageId } = useParams();
  const [amount, setAmount] = useState("");

  const { mutate: createMutate, isLoading: isCreating } = usePlaceBid();
  const { mutate: updateMutate, isLoading: isEditing } = useUpdateBid();

  const onNavigateProperties = () => navigate(`${UserRoutes.Properties}`);

  const onMakeOffer = () => {
    if (isEdit) {
      const payload = {
        bid_id: Number(isEdit),
        bid_price: Number(amount),
      };
      updateMutate(payload, {
        onSuccess: () => {
          toast.success("Offer Edited Sucessfully");
          handleClose();
          onNavigateProperties();
        },
      });
    } else {
      const payload = {
        offer_id: Number(packageId),
        bid_price: Number(amount),
      };
      createMutate(payload, {
        onSuccess: () => {
          toast.success("Offer Made Sucessfully");
          handleClose();
          onNavigateProperties();
        },
      });
    }
  };

  return (
    <ModalWrapper
      open={open}
      title={isEdit ? "Edit Offer" : "Make Offer"}
      handleClose={handleClose}
    >
      <>
        <Formik
          initialValues={{
            amount,
          }}
          validationSchema={MakeOfferValidation}
          enableReinitialize
          onSubmit={onMakeOffer}
        >
          {(formik) => (
            <Form>
              <Wrapper>
                <NumberFormatter
                  formik={formik}
                  name="amount"
                  label="Amount"
                  placeholder="Enter Amount"
                  onChange={(value) => setAmount(value)}
                />
              </Wrapper>

              <CreateBtn type="submit">
                {(isCreating || isEditing) && <Spinner color="" />}
                {isEdit ? "Edit" : "Make"} Offer
              </CreateBtn>
            </Form>
          )}
        </Formik>
      </>
    </ModalWrapper>
  );
};

export default MakeOfferModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
`;

const CreateBtn = styled.button`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin: auto;
  margin-top: 40px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

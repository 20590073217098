import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TextField } from "./components/TextField";
import { SignUpValidation } from "./vaildation";
import { ReactComponent as GoogleIcon } from "../../../images/google-icon.svg";
import { Link } from "react-router-dom";
import { userInitiateGoogleLogin, userSignUp } from "../../../api/auth";
import { parseError } from "../../../utils";
import AccountVerification from "./AccountVerification";
import Spinner from "../components/Spinner";
import storage from "app/utils/storage";
import { motion } from "framer-motion";
import {
  cardTextAnimate,
  delayStagger,
  fadeIn,
  popUp,
  slideIn,
} from "app/utils/animationVariants";
import mediaQueries from "styles/utils/mediaQueries";

const SignUp = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referal_code, setReferal_code] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [customerId, setCustomerId] = useState<number | undefined>();
  const ref = storage.get("userRef") as string;

  useEffect(() => {
    ref && setReferal_code(ref);
  }, [ref]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const formResult = {
        firstname: firstName,
        lastname: lastName,
        email,
        password,
        password_confirmation: password,
        referal_code: referal_code,
      };
      const res = await userSignUp(formResult);
      setCustomerId(res.data.data.id);
      setLoading(false);
      setStep(2);
      storage.remove("userRef");
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  };

  const initiateGoogle = async () => {
    try {
      setGoogleLoading(true);
      const res = await userInitiateGoogleLogin();
      setGoogleLoading(false);
      window.open(res.data.data, "_blank");
    } catch (error) {
      parseError(error);
      setGoogleLoading(false);
    }
  };

  return (
    <>
      {step === 1 && (
        <>
          <motion.h4 variants={slideIn("left")}>Create an Account</motion.h4>
          <motion.p variants={slideIn("right")}>
            Get started with Adbond harvest and homes by creating your personal
            account.
          </motion.p>
          <AuthBtn onClick={initiateGoogle} variants={fadeIn("up")} custom={4}>
            {googleLoading ? <Spinner color="black" /> : <GoogleIcon />}
            <span>Sign up Google</span>
          </AuthBtn>
          <Divider>
            <Liner variants={slideIn("left")} custom={4} />
            <motion.span variants={popUp} custom={3}>
              or
            </motion.span>
            <Liner variants={slideIn("right")} custom={4} />
          </Divider>
          <Formik
            initialValues={{
              firstName,
              lastName,
              email,
              password,
              confirmPassword,
              referal_code,
            }}
            validationSchema={SignUpValidation}
            enableReinitialize
            onSubmit={() => {
              onSubmit();
            }}
          >
            <Form>
              <FormWrapper
                variants={delayStagger(1.5)}
                custom={0.6}
                viewport={{ once: true, amount: 0.2 }}
                whileInView={"animate"}
                initial={"initial"}
              >
                <SplitWrapper>
                  <TextField
                    name="firstName"
                    label="First name"
                    placeholder="First Name"
                    type="text"
                    onChange={(e: any) => setFirstName(e.target.value)}
                  />
                  <TextField
                    name="lastName"
                    label="Last name"
                    placeholder="Last Name"
                    type="text"
                    onChange={(e: any) => setLastName(e.target.value)}
                  />
                </SplitWrapper>

                <TextField
                  name="email"
                  label="Email address"
                  placeholder="Enter your email address"
                  type="email"
                  onChange={(e: any) => setEmail(e.target.value)}
                />
                <TextField
                  name="password"
                  label="Password"
                  placeholder="************"
                  type="password"
                  onChange={(e: any) => setPassword(e.target.value)}
                />
                <TextField
                  name="confirmPassword"
                  label="Confirm password"
                  placeholder="************"
                  type="password"
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                />
                <TextField
                  name="referal_code"
                  label="Referral code (optional)"
                  placeholder="dhjs32h"
                  type="text"
                  value={ref}
                  disabled={!!ref}
                  onChange={(e: any) => setReferal_code(e.target.value)}
                />
              </FormWrapper>
              <Agree
                variants={cardTextAnimate}
                viewport={{ once: true, amount: 0.2 }}
                whileInView={"animate"}
                initial={"initial"}
              >
                By clicking the button button, you agree to our{" "}
                <AnchorLink to="/legal/tc">terms of use</AnchorLink> &{" "}
                <AnchorLink to="/legal/privacy">privacy policy.</AnchorLink>
              </Agree>
              <SubmitBtn
                type="submit"
                variants={cardTextAnimate}
                viewport={{ once: true, amount: 0.2 }}
                whileInView={"animate"}
                initial={"initial"}
              >
                {loading ? <Spinner color="white" /> : "Sign Up"}
              </SubmitBtn>
              <InfoWrapper
                variants={cardTextAnimate}
                viewport={{ once: true, amount: 0.2 }}
                whileInView={"animate"}
                initial={"initial"}
              >
                Already have an account?{" "}
                <Link to="/login">
                  <span>Login here</span>{" "}
                </Link>
              </InfoWrapper>
            </Form>
          </Formik>
        </>
      )}

      {step === 2 && customerId && (
        <AccountVerification customerId={customerId} />
      )}
    </>
  );
};

export default SignUp;

const AuthBtn = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 18px 32px;
  background: #ffffff;
  border: 1px solid #253f66;
  border-radius: 8px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
  }

  ${mediaQueries.mobile} {
    gap: 12px;
    padding: 10px 16px;

    span {
      font-size: 15px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 16px;

  span {
    font-weight: 500;
    font-size: 25px;
    line-height: 140%;
    color: #253f66;
  }

  ${mediaQueries.mobile} {
    span {
      font-size: 20px;
    }
  }
`;

const Liner = styled(motion.div)`
  width: 100%;
  height: 1px;
  background-color: #253f66;
`;

const SplitWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
`;

const FormWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 18px;

  ${mediaQueries.mobile} {
    padding-top: 10px;
  }
`;

const SubmitBtn = styled(motion.button)`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 80%;
  background: #afd34f;
  border-radius: 8px;
  padding: 10px 24px;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  margin-top: 43px;
  margin-bottom: 24px;
  cursor: pointer;
  height: 42px;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #697f2f;
  }

  ${mediaQueries.mobile} {
    align-items: center;
    width: 100%;
    height: 56px;
    margin-top: 30px;
  }
`;

const InfoWrapper = styled(motion.p)`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;

  span {
    color: #afd34f;
    &:hover {
      color: #697f2f;
      transition: all 0.2s ease-out;
    }
  }
`;

const Agree = styled(motion.div)`
  margin-top: 1rem;
  font-size: 0.875rem;

  ${mediaQueries.mobile} {
    text-align: center;
    width: 80%;
    margin: 1.3rem auto 0;
  }
`;

export const AnchorLink = styled(Link)`
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 140%;
  color: #afd34f;
  word-break: break-all;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
  }
`;

import React from "react";
import Actions from "app/views/Admin/components/Actions";
import styled from "styled-components";
import AvatarContainer from "../AvatarContainer";
import PostTag from "../PostTag";
import { GetPostResponse } from "app/api/community/types";
import { truncateTxt } from "app/utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useDeletePost from "app/hooks/community/useDeletePost";
import { ContentTypes } from "../..";

interface Props {
  data: GetPostResponse;
}

const BlogCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();

  const {
    title,
    slug,
    description,
    views,
    likesCount,
    user,
    created_at,
    tags,
    cover_photo,
    commentsCount,
  } = data;

  const { mutate: deleteMutate, isLoading: deleteLoading } = useDeletePost();

  const onDelete = () => {
    const payload = {
      post_id: data.id,
    };
    deleteMutate(payload, {
      onSuccess: onDeleteSuccess,
    });
  };

  const onDeleteSuccess = () => {
    toast.success(`Post Deleted Successfully!`);
  };

  const onEdit = () => {
    navigate(`${ContentTypes.blogpost}/new?edit=${slug}`);
  };

  return (
    <Container onClick={() => navigate(`blogpost/${slug}`)}>
      <AbsoluteBox
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Actions
          editHandler={onEdit}
          deleteHandler={onDelete}
          isDeleting={deleteLoading}
        />
      </AbsoluteBox>
      <ImgWrapper>
        <img src={cover_photo?.url} alt="blogpost" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{truncateTxt(title, 38)} </h6>
        <Para>{truncateTxt(description || " ", 190)}</Para>
        <TagWrapper>
          {tags.map((tag) => (
            <PostTag key={tag.id} label={tag.name} />
          ))}
        </TagWrapper>
        <Footer>
          <AvatarContainer
            name={
              (user?.firstname || "admin") + " " + (user?.lastname || "admin")
            }
            time={created_at}
            value={user?.profile_photo?.url}
          />
          <FlexWrapper>
            <span>{`${views} Views`}</span>
            <span>{`${likesCount} Likes`}</span>
            <span>{`${commentsCount} Comments`}</span>
          </FlexWrapper>
        </Footer>
      </ContentWrapper>
    </Container>
  );
};

export default BlogCard;

const Container = styled.div`
  width: 784px;
  min-height: 248px;
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }
`;

const Para = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #5e5e5e;
  margin-top: 7px;
  margin-bottom: 10px;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #777777;
`;

const Footer = styled.div`
  display: flex;
  gap: 64px;
  margin-top: auto;
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
`;

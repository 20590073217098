import React, { useCallback, useEffect, useState } from "react";
import {
  getAllCategories,
  getAllLocationPerProject,
  getAllProjectPerCategory,
  getALocationById,
  getASingleProject,
  getProjectsByCategoryId,
  searchBenefits,
} from "app/api/project";
import debounce from "app/hooks/useDebounce";
import { parseError } from "app/utils";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { PackageInfoValidation } from "../validation";
import { ReactComponent as ArrowRight } from "../../../../../images/admin/arrow-right.svg";
import { labelValueType, SingleOption } from "app/types/common";
import { useAppSelector } from "app/redux";
import { packageSelector } from "app/redux/packages/packageSlice";
import { useSearchParams } from "react-router-dom";

interface Props {
  onSubmitHandler: (value: any) => void;
  step: number;
}

interface FormPayload {
  name: string;
  project_location_id: number;
  description: string;
  benefits: string;
}

const PackageInfoSection: React.FC<Props> = ({ onSubmitHandler, step }) => {
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("edit");
  const [packageTitle, setPackageTitle] = useState("");
  const [packageSummary, setPackageSummary] = useState("");
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [packageBenefits, setPackageBenefits] = useState<labelValueType[]>([]);
  const [location, setLocation] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const { packageDetails } = useAppSelector(packageSelector);

  const fetchAllPackageLocation = async () => {
    if (!projectType) return;
    try {
      const res = await getAllLocationPerProject(projectType?.value);
      return res.data.data.map((ev) => {
        const temp = {
          value: ev.id,
          label: ev.location,
        };
        return temp;
      });
    } catch (error) {
      parseError(error);
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await getAllCategories();
      return res.data.data.map((ev) => {
        const temp = {
          value: ev.id,
          label: ev.name,
        };
        return temp;
      });
    } catch (error) {
      parseError(error);
    }
  };

  const fetchProjectByCategory = async () => {
    if (!categoryType) return;
    try {
      const res = await getAllProjectPerCategory(categoryType?.value);
      const result = res.data.data.map((ev) => {
        const temp = {
          value: ev.id,
          label: ev.name,
        };
        return temp;
      });
      return result;
    } catch (error) {
      parseError(error);
    }
  };

  const fetchCategoryById = useCallback(async () => {
    if (!packageDetails || !editId) return;
    try {
      const res = await getProjectsByCategoryId(+packageDetails.category_id);
      const result = {
        value: res.data.data.id,
        label: res.data.data.name,
      };
      setCategoryType(result);
    } catch (error) {
      parseError(error);
    }
  }, [packageDetails, editId]);

  const fetchProjectById = useCallback(async () => {
    if (!packageDetails || !editId) return;
    try {
      const res = await getASingleProject(packageDetails.project_id);
      const result = {
        value: res.data.data.id,
        label: res.data.data.name,
      };
      setProjectType(result);
    } catch (error) {
      parseError(error);
    }
  }, [packageDetails, editId]);

  const fetchLocationById = useCallback(async () => {
    if (!packageDetails || !editId) return;
    try {
      const res = await getALocationById(packageDetails.project_location_id);
      const result = {
        value: res.data.data.id,
        label: res.data.data.location,
      };
      setLocation(result);
    } catch (error) {
      parseError(error);
    }
  }, [packageDetails, editId]);

  const seachForBenefits = async (inputValue: string) => {
    if (!inputValue || inputValue.length < 3) return;
    try {
      const payload = {
        word: inputValue,
      };
      const res = await searchBenefits(payload);
      return res.data.data.map((ev, i) => {
        const temp = {
          value: ev.name,
          label: ev.name,
        };
        return temp;
      });
    } catch (error) {
      parseError(error);
    }
  };

  const loadSearchOptionsDebounced = useCallback(
    debounce((inputText: string, callback: (options: any) => void) => {
      seachForBenefits(inputText).then((options) => callback(options));
    }, 250),
    []
  );

  const setBenefits = useCallback(async (value: string) => {
    const result = await seachForBenefits(value);
    if (!result) return;
    const temp = result.filter((ev) => ev.value === value);
    setPackageBenefits((prev) => [...prev, ...temp]);
  }, []);

  useEffect(() => {
    if (!packageDetails || !editId) return;
    setPackageTitle(packageDetails.name);
    setPackageSummary(packageDetails.description);
    packageDetails?.benefits?.split(",").map((ev) => setBenefits(ev));
    fetchCategoryById();
    fetchProjectById();
    fetchLocationById();
  }, [
    packageDetails,
    editId,
    setBenefits,
    fetchCategoryById,
    fetchProjectById,
    fetchLocationById,
  ]);

  useEffect(() => {
    if (editId) return;
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType, packageDetails, editId]);

  useEffect(() => {
    if (editId) return;
    setLocation(undefined);
  }, [projectType, packageDetails, editId]);

  return (
    <Formik
      initialValues={{
        packageTitle,
        location,
        packageSummary,
        categoryType,
        projectType,
        packageBenefits,
      }}
      validationSchema={PackageInfoValidation}
      enableReinitialize
      onSubmit={() => {
        const formatedBenefits = packageBenefits
          ?.map((ev) => ev.value)
          .join(",");

        const payload: FormPayload = {
          name: packageTitle,
          project_location_id: location?.value as number,
          description: packageSummary,
          benefits: formatedBenefits,
        };
        onSubmitHandler(payload);
      }}
    >
      <FormWrapper stepNo={step}>
        <Form>
          <Wrapper>
            <div>
              <InputField
                name="packageTitle"
                label="Package Title"
                placeholder="Title"
                value={packageTitle}
                onChange={(e) => setPackageTitle(e.target.value)}
              />
              <InputField
                name="packageSummary"
                label="Description"
                placeholder="Summary"
                value={packageSummary}
                onChange={(e) => setPackageSummary(e.target.value)}
                inputType="textarea"
              />
            </div>
            <div>
              <MiniWrapper>
                <SelectField
                  name="categoryType"
                  label="Category"
                  value={categoryType as SingleOption}
                  onChange={(e) => setCategoryType(e as SingleOption)}
                  fetchOptions={fetchCategories}
                  selectType="async"
                />
                <SelectField
                  name="projectType"
                  label="Project Type"
                  value={projectType as SingleOption}
                  onChange={(e) => setProjectType(e as SingleOption)}
                  fetchOptions={fetchProjectByCategory}
                  variable={categoryType}
                  disabled={!categoryType}
                  selectType="async"
                />
              </MiniWrapper>
              <SelectField
                name="location"
                label="State"
                value={location as SingleOption}
                onChange={(e) => setLocation(e as SingleOption)}
                fetchOptions={fetchAllPackageLocation}
                variable={projectType}
                disabled={!projectType}
                selectType="async"
              />
              <SelectField
                name="packageBenefits"
                label="Package Benefit(s)"
                isMulti
                value={packageBenefits as labelValueType[]}
                onChange={(e) => setPackageBenefits(e as labelValueType[])}
                fetchOptions={loadSearchOptionsDebounced}
                selectType="asyncCreatable"
                minH={56}
                height="unset"
              />
            </div>
          </Wrapper>
          <SubmitBtn type="submit">
            <>
              Next <ArrowRight />
            </>
          </SubmitBtn>
        </Form>
      </FormWrapper>
    </Formik>
  );
};

export default PackageInfoSection;

interface FormWrapperProps {
  stepNo: number;
}

const FormWrapper = styled.div<FormWrapperProps>`
  display: ${({ stepNo }) => stepNo !== 1 && "none"};
`;

const Wrapper = styled.div`
  display: flex;
  gap: 71px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 50%;
  }
`;

const MiniWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const SubmitBtn = styled.button`
  background: #afd34f;
  border-radius: 8px;
  display: flex;
  gap: 14px;
  cursor: pointer;
  border: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  padding: 13px 40px;
  margin-left: auto;
  margin-top: 150px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

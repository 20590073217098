import { labelValueType, MonthsValue } from "../../../types/common";

export const yearDropDownOptions: labelValueType<string, number>[] = [
  {
    label: String(new Date().getFullYear()),
    value: new Date().getFullYear(),
  },
  {
    label: String(new Date().getFullYear() - 1),
    value: new Date().getFullYear() - 1,
  },
];

export const monthDropDownOptions: labelValueType<string, number>[] = [
  {
    label: "Jan - Dec",
    value: 1,
  },
  {
    label: "Jan - June",
    value: 2,
  },
  {
    label: "July - Dec",
    value: 3,
  },
];

export enum upcomingEventsProp {
  tour = "tour",
  community = "community",
}

export const upcomingEventsTab: labelValueType[] = [
  {
    label: "Site Tours",
    value: upcomingEventsProp.tour,
  },
  {
    label: "Community",
    value: upcomingEventsProp.community,
  },
];

export const newProjectsTab: labelValueType[] = [
  {
    label: "Land",
    value: "land",
  },
  {
    label: "Home",
    value: "home",
  },
  {
    label: "Agro",
    value: "agro",
  },
];

export const newProjectsCardData = [
  {
    location: "Obafemi Owode, Ogun state",
    packageName: "Premium Executive",
    size: "300, 600",
  },
  {
    location: "Obafemi Owode, Ogun state",
    packageName: "Premium Executive",
    size: "300, 600",
  },
  {
    location: "Obafemi Owode, Ogun state",
    packageName: "Premium Executive",
    size: "300, 600",
  },
];

export enum MonthRange {
  FirstQuarter = 1.6, // value would be split into 7 => start, 12 => end before use
  LastQuarter = 7.12,
}

export const projectMonthDropDownOptions: labelValueType<string, number>[] = [
  {
    label: "Jan - June",
    value: MonthRange.FirstQuarter,
  },
  {
    label: "July - Dec",
    value: MonthRange.LastQuarter,
  },
];

export const salesFullMonthOptions: labelValueType<string, string>[] = [
  {
    label: "Jan - Dec",
    value: MonthsValue.jan + " " + MonthsValue.dec,
  },
];

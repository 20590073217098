import { useField } from "formik";
import React from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import AsyncSelect from "react-select/async";
import Select, { components, MultiValue, SingleValue } from "react-select";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../../images/web/arrow-down.svg";
import { ReactComponent as ArrowDownWhite } from "../../../../images/admin/arrow-down-white.svg";
import { labelValueType } from "app/types/common";
import { cardTextAnimate } from "app/utils/animationVariants";
import { motion } from "framer-motion";

type SelectProps = labelValueType<string | number, string | number>;

interface Props {
  name: string;
  label: string;
  isMulti?: boolean;
  value: SelectProps | SelectProps[];
  onChange: (ev: SingleValue<SelectProps> | MultiValue<SelectProps>) => void;
  fetchOptions?: (inputValue: string) => Promise<any> | void;
  variable?: SelectProps | undefined;
  disabled?: boolean;
  selectType: "async" | "asyncCreatable" | "normal";
  options?: SelectProps[];
  isColored?: string;
  isSearchable?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  height?: number | string;
  minH?: number | string;
  borderStyle?: string;
  applyVariant?: boolean;
}

const SelectField: React.FC<Props> = ({
  label,
  value,
  onChange,
  isMulti,
  fetchOptions,
  variable,
  disabled,
  options,
  selectType,
  isColored,
  isRequired,
  isSearchable = false,
  isLoading,
  height,
  minH,
  borderStyle,
  applyVariant,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  const getBg = () => {
    return (
      isColored && {
        backgroundColor: isColored,
        color: "white",
      }
    );
  };

  const getColor = () => {
    return (
      isColored && {
        color: "white",
      }
    );
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: height ? height : 56,
      minHeight: minH && minH,
      border: borderStyle
        ? borderStyle
        : isColored
        ? "none"
        : "1px solid #BDBDBD",
      borderRadius: "8px",
      padding: "0 11px",
      boxShadow: "none",
      ...getBg(),
      "&:hover": {
        border: "1px solid #BDBDBD",
      },
      "&.error": {
        border: "1px solid red",
      },
    }),
    indicatorSeparator: () => ({ display: "none" }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#949292",
        fontWeight: "450",
        fontSize: "14px",
        lineHeight: "140%",
      };
    },
    singleValue: (provided: any) => {
      return { ...provided, ...getColor() };
    },
    option: (provided: any) => {
      return { ...provided, textTransform: "capitalize" };
    },
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {isColored ? <ArrowDownWhite /> : <ArrowDown />}
      </components.DropdownIndicator>
    );
  };

  const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <SelectContainer variants={applyVariant ? cardTextAnimate : undefined}>
      <label htmlFor={props.name} className={errorClass}>
        {label}
      </label>
      {selectType === "normal" && (
        <Select
          {...field}
          {...props}
          value={!value ? null : value}
          classNamePrefix={errorClass}
          className={errorClass}
          onChange={(ev) => {
            onChange(ev);
            field.value = ev;
          }}
          maxMenuHeight={150}
          isMulti={isMulti}
          isDisabled={disabled}
          isLoading={isLoading}
          placeholder={`Select ${label}`}
          options={options}
          styles={customStyles}
          components={{ DropdownIndicator }}
        />
      )}
      {selectType === "asyncCreatable" && (
        <AsyncCreatableSelect
          {...field}
          {...props}
          value={value}
          onChange={(ev) => {
            onChange(ev);
            field.value = ev;
          }}
          maxMenuHeight={150}
          onKeyDown={keyDownHandler}
          isMulti={isMulti}
          cacheOptions
          defaultOptions
          loadOptions={fetchOptions}
          styles={customStyles}
          classNamePrefix={errorClass}
          components={{ DropdownIndicator }}
        />
      )}

      {selectType === "async" && (
        <AsyncSelect
          {...field}
          {...props}
          value={!value ? null : value}
          onChange={(ev) => {
            onChange(ev);
            field.value = ev;
          }}
          maxMenuHeight={150}
          cacheOptions
          defaultOptions
          loadOptions={fetchOptions}
          styles={customStyles}
          classNamePrefix={errorClass}
          components={{ DropdownIndicator }}
          isDisabled={disabled}
          key={JSON.stringify(variable)}
          isSearchable={isSearchable}
          isMulti={isMulti}
        />
      )}

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </SelectContainer>
  );
};

export default SelectField;

const SelectContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }

  .error__control {
    border: 1px solid red;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PackageInfoSection from "./components/Sections/PackageInfoSection";
import {
  CreatePackagePayload,
  EditPackagePayload,
} from "app/api/project/types";
import { parseError } from "app/utils";
import { createPackage, editPackage, getSinglePackage } from "app/api/project";
import PackagePricingSection from "./components/Sections/PackagePricingSection";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  packageSelector,
  setPackageDetails,
} from "app/redux/packages/packageSlice";
import Spinner from "app/views/Web/components/Spinner";

const CreatePackage = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formPayload, setFormPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [packageLoading, setPackageLoading] = useState(false);
  const editId = searchParams.get("edit");
  const { packageDetails } = useAppSelector(packageSelector);

  const goBack = () => {
    if (step === 2) setStep(1);
    else navigate(-1);
  };

  const nextHandler = (payload: any) => {
    setFormPayload((prev) => ({ ...prev, ...payload }));
    setStep(2);
  };

  const pricingHandler = (payload: any) => {
    setFormPayload((prev) => ({ ...prev, ...payload }));
    const result = {
      ...formPayload,
      ...payload,
    };

    createPackageHandler(result);
  };

  const createPackageHandler = async (
    payload: CreatePackagePayload | EditPackagePayload
  ) => {
    try {
      setLoading(true);
      editId
        ? await editPackage({
            ...payload,
            package_id: Number(editId),
          } as EditPackagePayload)
        : await createPackage(payload);
      toast.success(`Package Successfully ${editId ? "Edited" : "Created"}!`);
      setTimeout(() => {
        navigate("/admin/projects");
      }, 1500);
      setLoading(false);
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  };

  const fetchPackage = useCallback(async () => {
    if (!editId) return;
    try {
      setPackageLoading(true);
      const res = await getSinglePackage(+editId);
      dispatch(setPackageDetails(res.data.data));
      setPackageLoading(false);
    } catch (error) {
      parseError(error);
      setPackageLoading(false);
    }
  }, [editId, dispatch]);

  useEffect(() => {
    fetchPackage();
  }, [fetchPackage]);

  return (
    <Container>
      <Nav>
        <ArrowBackIcon onClick={goBack} />
        <h5>{editId ? "Edit" : "Create"} Package</h5>
      </Nav>
      {packageLoading && (
        <SpinnerWrapper>
          <Spinner width="40px" height="40px" color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {editId && !packageDetails && !packageLoading && (
        <SpinnerWrapper>
          <NoPackageText>Package Not Found</NoPackageText>
        </SpinnerWrapper>
      )}

      {(packageDetails || !editId) && !packageLoading && (
        <>
          <PackageInfoSection step={step} onSubmitHandler={nextHandler} />
          <PackagePricingSection
            isLoading={loading}
            onSubmitHandler={pricingHandler}
            step={step}
          />
        </>
      )}
    </Container>
  );
};

export default CreatePackage;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  min-height: 80vh;
`;

const Nav = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  color: #181d0b;
  margin-bottom: 44px;

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.3s ease-in-out;
    }
  }

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const NoPackageText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

import styled from "styled-components";
import { ReactComponent as ThreeDots } from "app/images/admin/three-dots.svg";
import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import Fade from "app/views/Admin/components/fade";
import { Visibility, Delete, Upgrade } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { UserResponse } from "app/types/common";
import DeleteStaffModal from "../modals/delete";
import UpgradeStaffModal from "../modals/upgradeStaff";

const ActionRenderer = ({ data }: { data: UserResponse }) => {
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Container>
        <ThreeDots onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
        <Fade isOpen={isDropdownOpen}>
          <ClientDropdown>
            <DropdownLabel
              onClick={() => {
                setIsDropdownOpen(false);
                navigate(`${data.id}`);
              }}
            >
              <Visibility width={"10px"} />
              <span>View Details</span>
            </DropdownLabel>
            {data.staff_type.name === "virtual-staff" && (
              <DropdownLabel
                onClick={() => {
                  setIsDropdownOpen(false);
                  setIsUpgradeModalOpen(true);
                }}
              >
                <Upgrade />
                <span>Upgrade Staff</span>
              </DropdownLabel>
            )}
            <DropdownLabel
              onClick={() => {
                setIsDropdownOpen(false);
                setIsDeleteModalOpen(true);
              }}
              color={"#d32f2f"}
            >
              <Delete color="error" />
              <span>Delete Staff</span>
            </DropdownLabel>
          </ClientDropdown>
        </Fade>
        <DeleteStaffModal
          id={data.id}
          handleClose={() => setIsDeleteModalOpen(false)}
          isOpen={isDeleteModalOpen}
        />
        <UpgradeStaffModal
          id={data.id}
          handleClose={() => setIsUpgradeModalOpen(false)}
          isOpen={isUpgradeModalOpen}
        />
      </Container>
    </ClickAwayListener>
  );
};

export default ActionRenderer;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 45;
`;

const ClientDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + -10px);
  right: -140px;
  background: #fff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  z-index: 45;
`;

const DropdownLabel = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 12px;
  font-weight: 450;
  font-size: 12px;
  color: ${({ color }) => (!!color ? color : `#2a3313`)};
  width: 100%;

  &:hover {
    background: #d5d8d820;
  }

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import media from "../utils/media";
interface Props {
  h1Medium?: boolean;
  h2Medium?: boolean;
  h3Medium?: boolean;
  h4Medium?: boolean;
  h5Medium?: boolean;
  h6Medium?: boolean;
  h1Bold?: boolean;
  h2Bold?: boolean;
  h3Bold?: boolean;
  h4Bold?: boolean;
  h5Bold?: boolean;
  h6Bold?: boolean;
  bodybutton?: boolean;
  bodyMedium13?: boolean;
  bodyMedium10?: boolean;
  color?: string;
}

const P = styled(motion.p)<Props>`
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${(props) =>
    props.h1Medium &&
    css`
      /* 61px */
      font-weight: 500;
      font-size: 61px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h2Medium &&
    css`
      /* 49px */
      font-weight: 500;
      font-size: 49px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h3Medium &&
    css`
      /* 39px */
      font-weight: 500;
      font-size: 39px;
      line-height: 140%;
      /* ${media.bigMobile`
      // 20px 
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
        `} */
    `};
  ${(props) =>
    props.h4Medium &&
    css`
      /* 31px */
      font-weight: 500;
      font-size: 31px;
      line-height: 140%;
      /* ${media.bigMobile`
      // 20px 
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
        `} */
    `};
  ${(props) =>
    props.h5Medium &&
    css`
      /* 25px */
      font-weight: 500;
      font-size: 25px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h6Medium &&
    css`
      /* 20px */
      font-weight: 500;
      font-size: 20px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h1Bold &&
    css`
      /* 61px */
      font-weight: 700;
      font-size: 61px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h2Bold &&
    css`
      /* 49px */
      font-weight: 700;
      font-size: 49px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h3Bold &&
    css`
      /* 39px */
      font-weight: 700;
      font-size: 39px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h4Bold &&
    css`
      /* 31px */
      font-weight: 700;
      font-size: 31px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h5Bold &&
    css`
      /* 25px */
      font-weight: 700;
      font-size: 25px;
      line-height: 140%;
    `};
  ${(props) =>
    props.h6Bold &&
    css`
      /* 20px */
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
    `};
  ${(props) =>
    props.bodybutton &&
    css`
      /* 16px */
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
    `};
  ${(props) =>
    props.bodyMedium13 &&
    css`
      /* 13px */
      font-weight: 500;
      font-size: 13px;
      line-height: 140%;
    `};
  ${(props) =>
    props.bodyMedium10 &&
    css`
      /* 10px */
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
    `};
`;

export default P;

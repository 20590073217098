import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import Question from "../components/question";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  setTimerState,
} from "app/redux/assessment/assessmentSlice";
import { assessmentObj } from "app/api/staff/types";
import {
  gradeAssessment,
  gradeAssessmentObj,
  gradeQuestionObj,
  questionToDisplay,
} from "app/api/assessment/types";
import CountDown from "../components/countDown";
import useGradeAssessment from "app/hooks/assessment/useGradeAssessment";
import _ from "lodash";
import { toast } from "react-toastify";
import { mediaObj } from "styles/utils/media";

interface props {
  handleNext: () => void;
}

const Test: FC<props> = ({ handleNext }) => {
  const dispatch = useAppDispatch();
  const [currentQuestion, setCurrentQuestion] = useState<questionToDisplay>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [questionTotal, setQuestionTotal] = useState<number>(0);
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(false);
  const [currentAssessment, setCurrentAssessment] = useState<assessmentObj>();
  const [questionsToDisplay, setQuestionsToDisplay] = useState<
    questionToDisplay[]
  >([]);
  const { assessments, isTimeUp, memberEmail } =
    useAppSelector(assessmentSelector);
  const [formData, setFormData] = useState<gradeQuestionObj[]>([]);
  const [selectedOptionId, setSelectedOptionId] = useState<number>();
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const { mutate, error } = useGradeAssessment();
  const isLastQuestion = currentIndex === questionTotal - 1;

  useEffect(() => {
    const handleBlur = () => {
      setTabHasFocus(false);
      dispatch(setTimerState(true));
    };

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (!tabHasFocus || isTimeUp) && setSelectedOptionId(undefined);
  }, [isTimeUp, tabHasFocus]);

  useEffect(() => {
    handleGetQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  useEffect(() => {
    handleGetAssessment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  useEffect(() => {
    const arr = parseAssessmentToQuestion();
    setQuestionsToDisplay(arr);
    setQuestionTotal(arr.length);
    setCurrentQuestion(arr[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessments]);

  useEffect(() => {
    if (shouldSubmit && isLastQuestion && memberEmail) {
      const payload: gradeAssessment = {
        email: memberEmail,
        assessments: parseToAssessmentPayload(formData),
      };
      mutate(payload, {
        onSuccess: () => handleNext(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSubmit]);

  const parseToAssessmentPayload = (arr: gradeQuestionObj[]) => {
    const tempArr: gradeAssessmentObj[] = [];
    const uniquArr = _.uniqBy(arr, "assessment_id").map((x) => x.assessment_id);
    uniquArr.forEach((x) => {
      const arrById = arr
        .filter((g) => g.assessment_id === x)
        .map((x) => x.questions);
      tempArr.push({ assessment_id: x, questions: arrById });
    });
    return tempArr;
  };

  const parseAssessmentToQuestion = () => {
    const arr: questionToDisplay[] = [];
    assessments?.forEach((x) => {
      x?.questions?.forEach((y) => {
        arr.push({
          assessmentId: x.id,
          questionObj: y,
        });
      });
    });
    return arr;
  };

  const handleGetAssessment = () => {
    const assessment = assessments?.find(
      (x) => x.id === currentQuestion?.assessmentId
    );
    setCurrentAssessment(assessment);
  };

  const handleGetQuestion = () => {
    const curQuestion = questionsToDisplay?.filter(
      (_x, idx) => idx === currentIndex
    )[0];
    setCurrentQuestion(curQuestion);
  };

  const handleAddToForm = () => {
    const payload: gradeQuestionObj = {
      assessment_id: currentQuestion?.assessmentId,
      questions: {
        question_id: currentQuestion?.questionObj.id,
        answer: selectedOptionId,
      },
    };
    setFormData([...formData, payload]);
  };

  const onNext = () => {
    if (!isLastQuestion) {
      handleAddToForm();
      setCurrentIndex(currentIndex + 1);
      setSelectedOptionId(undefined);
    } else {
      handleAddToForm();
      setShouldSubmit(true);
    }
  };

  return (
    <Wrapper>
      <TopContent>
        <Box display={"flex"} alignItems={"center"} gap={"22px"}>
          <GreenLine />
          <Title>Aptitude Test</Title>
        </Box>
        <CountDown />
        <PhaseCounter>Phase 2 of 2</PhaseCounter>
      </TopContent>
      <ContentWrapper>
        <Subject>{currentAssessment?.title}</Subject>
        <Description>{currentAssessment?.description}</Description>
        {currentQuestion ? (
          <Question
            data={currentQuestion}
            index={currentIndex + 1}
            setSelectedOptionId={setSelectedOptionId}
            selectedOptionId={selectedOptionId}
          />
        ) : null}
        <SubmitBtn disabled={!selectedOptionId} onClick={onNext}>{`${
          isLastQuestion ? `Submit` : `Next`
        } (${currentIndex + 1}/${questionTotal})`}</SubmitBtn>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Test;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GreenLine = styled.span`
  width: 120px;
  height: 8px;
  background: #afd34f;
  border-radius: 2px;

  ${mediaObj.mobile} {
    width: 50px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #303030;

  ${mediaObj.mobile} {
    display: none;
  }
`;

const PhaseCounter = styled.span`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #535353;

  ${mediaObj.mobile} {
    font-size: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;

  ${mediaObj.mobile} {
    width: 100%;
  }
`;

const Subject = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #afd34f;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #535353;
  margin-bottom: 20px;
`;

const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 10px auto 0;
  width: 100%;
  height: 42px;
  outline: none;
  border: none;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

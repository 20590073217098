export const cubicSliderConfig = {
  infinite: true,
  centerPadding: "0px",
  slidesToShow: 3,
  slidesToScroll: 3,
  speed: 1300,
  swipeToSlide: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000,
  dotsClass: "dots",
  customPaging: function (i: any) {
    return <div className="dot" />;
  },
  cssEase: "cubic-bezier(0.34, 1.56, 0.64, 1)",
  responsive: [
    {
      breakpoint: 1350,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        centerPadding: "0px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "0px",
      },
    },
  ],
};

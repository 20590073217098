import { useEffect, useState } from "react";

const useCountdown = (targetTime: number): Array<string> => {
  const [countDown, setCountDown] = useState<number>(targetTime);

  useEffect(() => {
    if (countDown > 0) {
      const interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  if (countDown < 0) {
    return [`00`, `00`, `00`];
  }

  return getReturnValues(countDown);
};

const getReturnValues = (countDown: number): Array<string> => {
  // calculate time left

  const h = Math.floor(countDown / 3600)
      .toString()
      .padStart(2, "0"),
    m = Math.floor((countDown % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(countDown % 60)
      .toString()
      .padStart(2, "0");

  return [h, m, s];
};

export { useCountdown };

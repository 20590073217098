import { useQuery } from "@tanstack/react-query";
import { getBankAccounts } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetBankAccounts = () => {
  return useQuery([UtilitiesKeys.getBankAccounts], getBankAccounts, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetBankAccounts;

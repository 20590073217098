import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminSetCommission } from "app/api/staff";
import { parseError } from "app/utils";
import { UserKeys } from "../users/types";

const useAdminSetCommission = () => {
  const queryClient = useQueryClient();

  return useMutation(adminSetCommission, {
    onError: (error) => parseError(error),
    onSuccess: () => queryClient.invalidateQueries([UserKeys.adminGetAUser]),
  });
};

export default useAdminSetCommission;

import React, { useCallback, useEffect, useState } from "react";
import P from "../../../../../styles/elements/P";
import SelectBox, { SelectBoxOption } from "../../LandingPage/SelectBox";
import { SearchButton, SearchContainer } from "../../LandingPage/style";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useGetAllStates from "app/hooks/utilities/useGetAllStates";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import { capitalize } from "lodash";
import useSearchPackage from "app/hooks/utilities/useSearchPackage";
import Spinner from "../Spinner";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "app/redux";
import { setSearchData } from "app/redux/home/HomeSlice";
import {
  SearchPackagePayload,
  SearchPackageRes,
} from "app/api/utilities/types";
import { searchAnimate } from "../cards/framer-variants";
import { slideIn } from "app/utils/animationVariants";

const priceData: SelectBoxOption[] = [
  {
    value: "0-500000",
    name: "N0 - N500,000",
  },
  {
    value: "500000-2000000",
    name: "N500,000 -  N2M",
  },
  {
    value: "2000000-5000000",
    name: "N2M -  N5M",
  },
  {
    value: "5000000-10000000",
    name: "N5M -  N10M",
  },
  {
    value: "10000000-",
    name: "N10M and above",
  },
];

interface Props {
  goToSearchElement: () => void;
}

const SearchSelectBox: React.FC<Props> = ({ goToSearchElement }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const locationParams = searchParams.get("location");
  const categoryParams = searchParams.get("category");
  const priceParams = searchParams.get("price");
  const [locationState, setLocationState] = useState<number | string>();
  const [categoryState, setCategoryState] = useState<number | string>();
  const [priceState, setPriceState] = useState<number | string>();
  const [isLocationError, setIsLocationError] = useState(false);
  const [isCategoryError, setIsCategoryError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);

  const { data: allStateData, isLoading: allStateLoading } = useGetAllStates();
  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();
  const { mutate: searchMutate, isLoading: isSearching } = useSearchPackage();

  const onSuccessfulSearch = useCallback(
    (data: SearchPackageRes[]) => {
      dispatch(setSearchData(data));
      goToSearchElement();
    },
    [dispatch, goToSearchElement]
  );

  useEffect(() => {
    if (!locationParams || !categoryParams || !priceParams) {
      setLocationState(undefined);
      setCategoryState(undefined);
      setPriceState(undefined);
      return;
    }
  }, [categoryParams, locationParams, priceParams]);

  useEffect(() => {
    if (!locationParams || !categoryParams || !priceParams) return;
    if (!allStateData || !allCategoryData || !priceData) return;

    const getSelectedLocation = allStateData.filter(
      (ev) => ev.name === locationParams
    )[0].id;
    const getSelectedCategory = allCategoryData.filter(
      (ev) => ev.name === categoryParams
    )[0].id;

    const getSelectedPrice = priceData.filter(
      (ev) => ev.name === priceParams
    )[0].value;

    setLocationState(getSelectedLocation);
    setCategoryState(getSelectedCategory);
    setPriceState(getSelectedPrice);

    const price = (getSelectedPrice as string).split("-");
    let payload: SearchPackagePayload = {
      state_id: getSelectedLocation as number,
      category_id: getSelectedCategory as number,
      min: +price[0],
    };

    if (+price[1]) {
      payload = {
        ...payload,
        max: +price[1],
      };
    }

    searchMutate(payload, {
      onSuccess: (data) => {
        if (!data) return;
        onSuccessfulSearch(data.data.data);
      },
    });
  }, [
    allCategoryData,
    allStateData,
    categoryParams,
    locationParams,
    priceParams,
    searchMutate,
    onSuccessfulSearch,
  ]);

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        name: capitalize(ev.name),
      };
      return temp;
    });
  };

  const onLocationChange = (data: number | string) => {
    setLocationState(data);
    setIsLocationError(false);
  };

  const onCategoryChange = (data: number | string) => {
    setCategoryState(data);
    setIsCategoryError(false);
  };

  const onPriceChange = (data: number | string) => {
    setPriceState(data);
    setIsPriceError(false);
  };

  const onSearch = () => {
    if (!locationState) {
      setIsLocationError(true);
    }
    if (!categoryState) {
      setIsCategoryError(true);
    }
    if (!priceState) {
      setIsPriceError(true);
    }

    if (!locationState || !categoryState || !priceState) {
      return;
    }

    if (!allStateData || !allCategoryData || !priceData) return;

    const locationQuery = allStateData?.filter(
      (ev) => ev.id === locationState
    )[0].name;
    const categoryQuery = allCategoryData?.filter(
      (ev) => ev.id === categoryState
    )[0].name;

    const priceQuery = priceData?.filter((ev) => ev.value === priceState)[0]
      .name;

    if (
      locationQuery === locationParams &&
      categoryQuery === categoryParams &&
      priceQuery === priceParams
    ) {
      goToSearchElement();
      return;
    }
    navigate(
      `?location=${locationQuery}&category=${categoryQuery}&price=${priceQuery}`
    );
  };

  return (
    <SearchContainer variants={slideIn("right")} custom={2}>
      <div className={isLocationError ? "error" : ""}>
        <SelectBox
          data={formatData(allStateData)}
          label="Location"
          stateHandler={onLocationChange}
          selectedValue={locationState}
          isLoading={allStateLoading}
        />
      </div>
      <div className={isCategoryError ? "error" : ""}>
        <SelectBox
          data={formatData(allCategoryData)}
          label="Category"
          stateHandler={onCategoryChange}
          selectedValue={categoryState}
          isLoading={allCategoryLoading}
        />
      </div>
      <div className={isPriceError ? "error" : ""}>
        <SelectBox
          data={priceData}
          label="Estimated Price"
          stateHandler={onPriceChange}
          selectedValue={priceState}
        />
      </div>
      <SearchButton
        disabled={isSearching || (allCategoryLoading && allStateLoading)}
        onClick={onSearch}
      >
        {isSearching ? <Spinner color="white" /> : <SearchOutlinedIcon />}
        <P bodybutton>Search</P>
      </SearchButton>
    </SearchContainer>
  );
};

export default SearchSelectBox;

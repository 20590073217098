import React, { useState } from "react";
import { ClickAwayListener } from "@mui/base";
import styled from "styled-components";
import Spinner from "app/views/Web/components/Spinner";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useAcceptBid } from "app/hooks/offers/useBid";
import { toast } from "react-toastify";

interface Data {
  id: number;
  clientName: string;
  phone: string;
  amountOffered: string;
  date: string;
}

const ActionRenderer = ({ data }: { data: Data }) => {
  const [openActions, setOpenActions] = useState(false);

  const { mutate: acceptBid, isLoading: isAccepting } = useAcceptBid();

  const onAcceptBid = () => {
    const payload = {
      bid_id: data.id,
    };

    acceptBid(payload, {
      onSuccess: () => toast.success("Bid Accepted Sucessfully!"),
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenActions(false)}>
      <ActionWrapper openActions={openActions}>
        <MoreVertIcon
          onClick={() => {
            setOpenActions((prev) => !prev);
          }}
        />
        {openActions && (
          <ActionBox>
            <ActionItem onClick={onAcceptBid}>
              {isAccepting && (
                <Spinner color="black" width="16px" height="16px" />
              )}{" "}
              <span>Accept Bid</span>
            </ActionItem>
          </ActionBox>
        )}
      </ActionWrapper>
    </ClickAwayListener>
  );
};

export default ActionRenderer;

interface ActionWrapperProps {
  openActions: boolean;
}

const ActionWrapper = styled.div<ActionWrapperProps>`
  position: relative;
  z-index: ${({ openActions }) => (openActions ? 99 : "unset")};

  & > svg {
    cursor: pointer;
  }
`;

const ActionBox = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(25%) translateY(100%);
  background-color: white;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 4px;
`;

const ActionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  cursor: pointer;

  span {
    font-weight: 450;
    font-size: 0.875rem;
    line-height: 140%;
    color: #0c0500;
    color: #afd34f;
  }
  &:hover {
    background-color: #fbfbfb;
  }
`;

import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TextField } from "./components/TextField";
import { AdminLoginValidation, LoginValidation } from "./vaildation";
import { ReactComponent as GoogleIcon } from "../../../images/google-icon.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  userGoogleAuth,
  userInitiateGoogleLogin,
  userLogin,
} from "../../../api/auth";
import { parseError } from "../../../utils";
import { useAppDispatch } from "../../../redux";
import { loginFailed, setUser } from "../../../redux/auth/authSlice";
import Spinner from "../components/Spinner";
import api from "app/api";
import { setToken } from "app/redux/auth/actions";
import { motion } from "framer-motion";
import {
  AuthConeAnim,
  cardTextAnimate,
  delayStagger,
  fadeIn,
  popUp,
  slideIn,
} from "app/utils/animationVariants";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  isAdminStaff?: boolean;
}

const Login: React.FC<Props> = ({ isAdminStaff }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [searchParams] = useSearchParams();

  const initiateGoogle = async () => {
    try {
      setGoogleLoading(true);
      const res = await userInitiateGoogleLogin();
      setGoogleLoading(false);
      window.open(res.data.data, "_blank");
    } catch (error) {
      parseError(error);
      setGoogleLoading(false);
    }
  };

  const googleSignIn = useCallback(async () => {
    const code = searchParams.get("code");
    if (!code) return;
    try {
      setGoogleLoading(true);
      const res = await userGoogleAuth({ auth_code: code });
      setGoogleLoading(false);
      dispatch(setUser(res.data.data.user));
      dispatch(setToken({ accessToken: res.data.data.token }));
      navigate("/user/dashboard");
    } catch (error) {
      parseError(error);
      setGoogleLoading(false);
    }
  }, [searchParams, navigate, dispatch]);

  useEffect(() => {
    googleSignIn();
  }, [googleSignIn]);

  const onUserSubmit = async () => {
    try {
      setLoading(true);
      const formResult = {
        email,
        password,
      };
      const res = await userLogin(formResult);
      dispatch(setUser(res.data.data.customer));
      dispatch(setToken({ accessToken: res.data.data.token }));
      navigate("/user/dashboard");
      setLoading(false);
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  };

  const onAdminSubmit = async () => {
    try {
      setLoading(true);
      const formResult = {
        email,
        password,
      };
      const res = await api.authService.login(formResult);
      dispatch(setUser(res.data.data.user));
      dispatch(setToken({ accessToken: res.data.data.token }));
      !res.data.data.user.role
        ? navigate("/staff/dashboard")
        : navigate("/admin/dashboard");
      setLoading(false);
    } catch (error) {
      dispatch(loginFailed());
      parseError(error);
      setLoading(false);
    }
  };

  return (
    <>
      <motion.h4 variants={slideIn("left")}>Welcome Back</motion.h4>
      <motion.p variants={slideIn("right")}>
        Log in to your account with your registered email address and password
      </motion.p>
      {!isAdminStaff && (
        <>
          <AuthBtn onClick={initiateGoogle} variants={fadeIn("up")} custom={4}>
            {googleLoading ? <Spinner color="black" /> : <GoogleIcon />}
            <span>Log in with Google</span>
          </AuthBtn>
          <Divider>
            <Liner variants={slideIn("left")} custom={4} />
            <motion.span variants={popUp} custom={3}>
              or
            </motion.span>
            <Liner variants={slideIn("right")} custom={4} />
          </Divider>
        </>
      )}
      <Formik
        initialValues={{
          email,
          password,
        }}
        validationSchema={isAdminStaff ? AdminLoginValidation : LoginValidation}
        enableReinitialize
        onSubmit={(values) => {
          !isAdminStaff && onUserSubmit();
          isAdminStaff && onAdminSubmit();
        }}
      >
        <Form>
          <FormWrapper
            variants={delayStagger(1.5)}
            custom={0.6}
            viewport={{ once: true, amount: 0.2 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            <TextField
              name="email"
              label="Email address"
              placeholder="Enter your email address"
              type="email"
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <TextField
              name="password"
              label="Password"
              placeholder="************"
              type="password"
              onChange={(e: any) => setPassword(e.target.value)}
            />
          </FormWrapper>
          <HelperWrapper
            variants={cardTextAnimate}
            viewport={{ once: true, amount: 0.2 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            <CheckWrapper>
              <input
                type="checkbox"
                id="reminder"
                checked={isChecked}
                onChange={() => setIsChecked((prev) => !prev)}
              />
              <label htmlFor="reminder">Remember me</label>
            </CheckWrapper>

            <Link to="/password-recovery">
              <span>Forgot Password?</span>
            </Link>
          </HelperWrapper>

          <SubmitBtn
            type="submit"
            variants={cardTextAnimate}
            viewport={{ once: true, amount: 0.2 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            {loading ? <Spinner color="white" /> : "Login"}
          </SubmitBtn>
          {!isAdminStaff && (
            <>
              <InfoWrapper
                variants={cardTextAnimate}
                transition={{ delay: 4 }}
                animate={"animate"}
                initial={"initial"}
              >
                Don’t have an account?{" "}
                <Link to="/signup">
                  <span>Sign up for free</span>
                </Link>
              </InfoWrapper>

              <StaffLoginBtn
                onClick={() => navigate("/admin-login")}
                variants={AuthConeAnim}
                custom={2}
                viewport={{ once: true, amount: 0.2 }}
                whileInView={"animate"}
                initial={"initial"}
              >
                Sign in as Staff
              </StaffLoginBtn>
            </>
          )}
        </Form>
      </Formik>
    </>
  );
};

export default Login;

const AuthBtn = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 18px 32px;
  background: #ffffff;
  border: 1px solid #253f66;
  border-radius: 8px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
  }

  ${mediaQueries.mobile} {
    gap: 12px;
    padding: 10px 16px;

    span {
      font-size: 15px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 16px;

  span {
    font-weight: 500;
    font-size: 25px;
    line-height: 140%;
    color: #253f66;
  }

  ${mediaQueries.mobile} {
    span {
      font-size: 20px;
    }
  }
`;

const Liner = styled(motion.div)`
  width: 100%;
  height: 1px;
  background-color: #253f66;
`;

const FormWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const SubmitBtn = styled(motion.button)`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 80%;
  background: #afd34f;
  border-radius: 8px;
  padding: 10px 24px;
  border: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  margin-top: 43px;
  margin-bottom: 24px;
  cursor: pointer;
  height: 42px;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: #697f2f;
  }

  ${mediaQueries.mobile} {
    align-items: center;
    width: 100%;
    height: 56px;
    margin-top: 30px;
  }
`;

const InfoWrapper = styled(motion.p)`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;

  span {
    color: #afd34f;
    &:hover {
      color: #697f2f;
      transition: all 0.2s ease-out;
    }
  }
`;

const StaffLoginBtn = styled(motion.div)`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  padding: 8px 15px;
  color: #181d0b;
  width: fit-content;
  margin: 50px auto 0;
  border: 1px solid #253f66;
  border-radius: 8px;
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.25s ease-in;

  &:hover {
    opacity: 1;
  }
`;

const HelperWrapper = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
  margin-top: 16px;

  span {
    color: #181d0b;
  }
`;

const CheckWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;

  input {
    width: 14px;
    height: 14px;
    border: 1px solid #181d0b;
    border-radius: 5px;
    accent-color: #afd34f;
    cursor: pointer;
  }
`;

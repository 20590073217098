import useGetSiteTours from "app/hooks/site tour/useGetSiteTours";
import TourCard from "app/views/Admin/components/Cards/tour";
import Spinner from "app/views/Web/components/Spinner";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { NoProjectText, SpinnerWrapper } from "./styles";

interface Props {
  handleTourSelect: (data: any, isSelected: boolean) => void;
  selectedTourId?: number;
  selectedMonth: number;
}

const DisplaySiteTours: React.FC<Props> = ({
  handleTourSelect,
  selectedTourId,
  selectedMonth,
}) => {
  const currentYear = new Date().getFullYear();
  const [searchParams] = useSearchParams();
  const selectedParam = searchParams.get("selected");

  const {
    data: siteTourData,
    isLoading: tourLoading,
    isError: tourIsError,
  } = useGetSiteTours(selectedMonth, currentYear);

  useEffect(() => {
    if (selectedParam && siteTourData) {
      const result = siteTourData.filter((ev) => ev.id === +selectedParam);
      handleTourSelect(result[0], false);
    }
  }, [selectedParam, siteTourData, handleTourSelect]);

  if (tourLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!tourLoading && tourIsError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (siteTourData && siteTourData.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>No site tour available yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {siteTourData &&
        siteTourData.map((x) => (
          <TourCard
            key={x.id}
            data={x}
            handleTourSelect={handleTourSelect}
            isSelected={selectedTourId === x.id}
          />
        ))}
    </>
  );
};

export default DisplaySiteTours;

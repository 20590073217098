import BodyField from "app/views/Admin/Community/components/BodyField";
import CustomModal from "app/views/Admin/components/modal";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Form, Formik } from "formik";
import { FC, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { ReactComponent as EditIcon } from "app/images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppDispatch } from "app/redux";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { QuestionValidation } from "../../validation";
import { editQuestion } from "app/redux/assessment/assessmentSlice";
import Option from "../option";
import EditOption from "./editOption";
import DeleteAssessObj from "./deleteAssessObj";
import { options, questionObj, questionPayload } from "app/api/staff/types";

interface props {
  closeHandler: () => void;
  isOpen: boolean;
  width?: string;
  data?: questionPayload;
  index: number;
}

const EditQuestionModal: FC<props> = ({
  closeHandler,
  isOpen,
  width,
  data,
  index,
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<questionObj>();
  const [formOptions, setFormOptions] = useState<options[]>();
  const [option, setOption] = useState(EditorState.createEmpty());
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const [editOption, setEditOption] = useState<options>();
  const [deleteOptionId, setDeleteOptionId] = useState<number>();

  const convertedOptionValue = draftToHtml(
    convertToRaw(option?.getCurrentContent())
  );

  useEffect(() => {
    if (data?.question) {
      const blocksFromHtml = htmlToDraft(data.question);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      setFormData({
        ...data,
        question: EditorState.createWithContent(contentState),
      });
      setFormOptions(data?.options);
    }
  }, [data]);

  useEffect(() => {
    const newOptions =
      formOptions && (JSON.parse(JSON.stringify(formOptions)) as options[]);

    if ((selectedOptionIndex || selectedOptionIndex === 0) && newOptions) {
      newOptions.forEach((item) => (item.answer = false));
      const selectedOption = newOptions[selectedOptionIndex];
      selectedOption.answer = true;
      newOptions.splice(selectedOptionIndex, 1, selectedOption);
      setFormOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionIndex]);

  const onClose = () => {
    setFormData({});
    setSelectedOptionIndex(undefined);
    setFormOptions([]);
    setDeleteOptionId(undefined);
    closeHandler();
  };

  const handleQuestionChange = (value: SetStateAction<EditorState>) => {
    setFormData({ ...formData, question: value as EditorState });
  };

  const handleAddQuestion = () => {
    if (!formOptions || formOptions?.length === 0) {
      toast.info("Options cannot be empty");
      return;
    } else if (
      formData?.question?.getCurrentContent().hasText() &&
      formData?.question?.getCurrentContent().getPlainText()
    ) {
      const questionInHtml =
        formData?.question &&
        draftToHtml(convertToRaw(formData?.question?.getCurrentContent()));

      dispatch(
        editQuestion({
          ...formData,
          question: questionInHtml,
          options: formOptions,
        })
      );
      onClose();
    } else {
      toast.info("Please enter question");
      return;
    }
  };

  const handleAddOptions = () => {
    if (
      option.getCurrentContent().hasText() &&
      option.getCurrentContent().getPlainText() !== ""
    ) {
      if (formOptions && formOptions?.length > 0) {
        const greatestCurrentId = formOptions
          ?.filter((x) => !isNaN(Number(x.id)))
          .map((x) => x.id) as number[];
        setFormOptions([
          ...formOptions,
          {
            value: convertedOptionValue,
            idx: Math.max.apply(null, greatestCurrentId) + 1,
          },
        ]);
      } else {
        setFormOptions([{ value: convertedOptionValue, idx: 0 }]);
      }
    } else {
      toast.info("Please enter option");
      return;
    }

    setOption(EditorState.createEmpty());
  };

  const handleOptionsEdit = (option: options) => {
    const newOptionsArr = JSON.parse(JSON.stringify(formOptions)) as options[];
    const oldOptionIndex = newOptionsArr?.findIndex((x) =>
      x.id ? x.id === option.id : x.idx === option.idx
    );

    (oldOptionIndex || oldOptionIndex === 0) &&
      oldOptionIndex > -1 &&
      newOptionsArr?.splice(oldOptionIndex, 1, option);
    setFormOptions(newOptionsArr);
  };

  const handleOptionDelete = (id: number) => {
    const newOptionsArr = JSON.parse(JSON.stringify(formOptions)) as options[];
    const oldOptionIndex = newOptionsArr?.findIndex((x) =>
      x.id ? x.id === id : x.idx === id
    );

    (oldOptionIndex || oldOptionIndex === 0) &&
      oldOptionIndex > -1 &&
      newOptionsArr?.splice(oldOptionIndex, 1);
    setFormOptions(newOptionsArr);
  };

  return (
    <CustomModal isOpen={isOpen} handleClose={closeHandler} width={width}>
      <Wrapper>
        <Formik
          initialValues={{
            question: formData?.question,
          }}
          validationSchema={QuestionValidation}
          enableReinitialize
          onSubmit={() => {}}
        >
          <Form>
            <BodyWrapper>
              <BodyField
                name={"question"}
                label={`Question ${index}`}
                value={formData?.question}
                onChange={handleQuestionChange}
                placeholder={"Enter your Question"}
                fullWidth
              />
            </BodyWrapper>
            {formOptions && formOptions?.length > 0 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"10px"}
                mt={"25px"}
              >
                <OptionsTitle>Select Correct Answer below</OptionsTitle>
                <OptionEntryWrapper>
                  {formOptions?.map(
                    (x, idx) =>
                      x.value && (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"flex-start"}
                          width={"100%"}
                          key={idx}
                        >
                          <OptionEntry
                            key={idx}
                            onClick={() => setSelectedOptionIndex(idx)}
                            isSelected={idx === selectedOptionIndex || x.answer}
                          >
                            {`${String.fromCharCode(idx + 65).toLowerCase()}.`}
                            <Entry
                              dangerouslySetInnerHTML={{
                                __html: x?.value,
                              }}
                            />
                          </OptionEntry>
                          <IconWrapper>
                            <EditIcon onClick={() => setEditOption(x)} />
                            <DeleteIcon
                              onClick={() => setDeleteOptionId(x.id || x.idx)}
                            />
                          </IconWrapper>
                        </Box>
                      )
                  )}
                </OptionEntryWrapper>
              </Box>
            )}
            <Option
              setBodyState={setOption}
              value={option}
              handleAddOptions={handleAddOptions}
              fullWidth
            />
            <SaveBtn onClick={handleAddQuestion}>Save Question</SaveBtn>
          </Form>
        </Formik>
        {editOption && (
          <EditOption
            handleClose={() => setEditOption(undefined)}
            handleSubmit={handleOptionsEdit}
            isOpen={!!editOption}
            option={editOption}
            width={"50vw"}
          />
        )}
        {(!!deleteOptionId || deleteOptionId === 0) && (
          <DeleteAssessObj
            onDelete={handleOptionDelete}
            handleClose={() => setDeleteOptionId(undefined)}
            isOpen={!!deleteOptionId || deleteOptionId === 0}
            id={deleteOptionId}
          />
        )}
      </Wrapper>
    </CustomModal>
  );
};

export default EditQuestionModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 10px 10px 0 0;
  max-height: 80vh;
  overflow-y: scroll;
`;

const SaveBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 17px;
  color: #fbfbfb;
  margin-top: 20px;
  margin-left: auto;
  height: 48px;
  background: #49b7e4;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
`;

const BodyWrapper = styled.div`
  width: 100%;

  .rdw-editor-main {
    min-height: 91px;
  }
`;

const OptionEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OptionsTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 144%;
  color: #181d0b;
`;

const OptionEntry = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 5px;
  width: fit-content;
  max-width: 80%;
  padding: 8px 12px;
  color: ${({ isSelected }) => (isSelected ? `#fbfbfb` : `#181d0b`)};
  background: ${({ isSelected }) => (isSelected ? `#22a2d6c7` : `#f1f1f1`)};
  cursor: pointer;
  transition: all 0.2s ease-in;
`;

const Entry = styled.span`
  font-weight: 500;
  font-size: 15px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > svg:first-of-type {
    fill: #43a047;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  & > svg:last-of-type {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  AdminChangePasswordPayload,
  adminResetPasswordPayload,
  AdminSendPasswordResetLink,
  AdminVerifyResetHashPayload,
  AdminVerifyResetHashResponse,
  GooglePayload,
  GoogleResponse,
  LoginPayload,
  LoginResponse,
  UserLoginResponse,
  UserResendEmailToken,
  UserSignUpPayload,
  UserSignUpResponse,
  UserValidateEmail,
  UserValidateEmailResponse,
} from "./types";

const login = async (payload: LoginPayload) => {
  return await request.post<ApiResponse<LoginResponse>>(
    "/admin/auth/login",
    payload
  );
};

export const userSignUp = async (payload: UserSignUpPayload) => {
  return await request.post<ApiResponse<UserSignUpResponse>>(
    "/auth/register",
    payload
  );
};

export const userLogin = async (payload: LoginPayload) => {
  return await request.post<ApiResponse<UserLoginResponse>>(
    "/auth/login",
    payload
  );
};

export const userValidateEmail = async (payload: UserValidateEmail) => {
  return await request.post<ApiResponse<UserValidateEmailResponse>>(
    "/auth/validate_email_token",
    payload
  );
};

export const userResendEmailToken = async (payload: UserResendEmailToken) => {
  return await request.post<ApiResponse>("/auth/resend_email_token", payload);
};

export const userInitiateGoogleLogin = async () => {
  return await request.get<ApiResponse<string>>("/auth/google/get_url");
};

export const userGoogleAuth = async (payload: GooglePayload) => {
  return await request.post<ApiResponse<GoogleResponse>>(
    "/auth/google/auth",
    payload
  );
};

export const adminChangePassword = async (
  payload: AdminChangePasswordPayload
) => {
  return await request.post<ApiResponse<string>>(
    "/admin/user/change_password",
    payload
  );
};

export const adminSendPasswordResetLink = async (
  payload: AdminSendPasswordResetLink
) => {
  return await request.post<ApiResponse<string>>(
    "/admin/auth/send_password_reset_link",
    payload
  );
};

export const adminVerifyResetHash = async (
  payload: AdminVerifyResetHashPayload
) => {
  return await request.post<AdminVerifyResetHashResponse>(
    "/admin/auth/verify_reset_hash",
    payload
  );
};

export const adminResetPassword = async (
  payload: adminResetPasswordPayload
) => {
  return await request.post<ApiResponse<AdminVerifyResetHashResponse>>(
    "/admin/auth/reset_password",
    payload
  );
};

export const clientChangePassword = async (
  payload: AdminChangePasswordPayload
) => {
  return await request.post<ApiResponse<string>>(
    "/client/change_password",
    payload
  );
};

export const clientVerifyResetHash = async (
  payload: AdminVerifyResetHashPayload
) => {
  return await request.post<AdminVerifyResetHashResponse>(
    "/auth/verify_reset_hash",
    payload
  );
};

export const clientResetPassword = async (
  payload: adminResetPasswordPayload
) => {
  return await request.post<ApiResponse<AdminVerifyResetHashResponse>>(
    "/auth/reset_password",
    payload
  );
};

export const clientSendPasswordResetLink = async (
  payload: AdminSendPasswordResetLink
) => {
  return await request.post<ApiResponse<string>>(
    "/auth/send_password_reset_link",
    payload
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
};

import React from "react";
import styled from "styled-components";
import AddImage from "../../components/AddImage";

interface Props {
  setStateFn: React.Dispatch<React.SetStateAction<number | undefined>>;
  value?: string;
}

const CoverImage: React.FC<Props> = ({ setStateFn, value }) => {
  return (
    <CoverWrapper>
      <label className="required">Add Cover Image</label>
      <AddImage
        name="coverImageId"
        value={value}
        text="Click here to upload image"
        handleFileId={(id) => setStateFn(id)}
      />
    </CoverWrapper>
  );
};

export default CoverImage;

export const CoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > label {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 0.5rem;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }
`;

import { useQuery } from "@tanstack/react-query";
import { getASingleProject } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useGetASingleProject = (projectId: number) => {
  return useQuery(
    [ProjectsKey.singleProject],
    () => getASingleProject(projectId),
    {
      enabled: !!projectId,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetASingleProject;

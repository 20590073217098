import * as Yup from "yup";

export const CreateUserValidation = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  role: Yup.object().required("This field is required"),
});

export const EditProfileValidation = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
});

export const PasswordChangeValidation = Yup.object({
  currentPassword: Yup.string()
    .required("Required")
    .min(6)
    .label("current password"),
  newPassword: Yup.string()
    .required("Required")
    .min(6)
    .label("new password")
    .notOneOf([Yup.ref("currentPassword")], "passwords must be different"),
  confirmNewPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
});

export const PasswordRecoveryValidation = Yup.object({
  email: Yup.string().required("Required").min(6),
});

export const PasswordResetValidation = Yup.object({
  newPassword: Yup.string().required("Required").min(6).label("new password"),
  confirmNewPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
});

export const BankDetailsValidation = Yup.object({
  accountName: Yup.string().required("Required"),
  accountNumber: Yup.string().required("Required"),
  bank: Yup.object().required("This field is required"),
});

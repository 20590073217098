import React, { useRef, useState } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import DialogModal from "../modals/DialogModal";
import { BankResponse } from "app/api/analytics/types";
import DynamicRenderer from "./DynamicRenderer";
import { ReactComponent as EditIcon } from "../../../../../images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSearchParams } from "react-router-dom";
import { DisplayPaymentQuery } from "../DisplayPayment";

type TableField = keyof BankResponse | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: BankResponse[];
}

interface Props {
  data: BankResponse[];
}

const AccountTable: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: BankResponse[] = data;
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<BankResponse>();
  const handleClose = () => setOpen(undefined);
  const handleOpen = (data: BankResponse) => setOpen(data);

  const onEdit = (data: BankResponse) => {
    searchParams.set(DisplayPaymentQuery.edit_bank, data.id.toString());
    setSearchParams(searchParams);
  };

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Account Title",
      field: "account_name",
      sortable: false,
      flex: 1,
      cellStyle: {
        color: "#97B644",
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "Account Number",
      field: "account_number",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
    },

    {
      headerName: "Bank Name",
      field: "bank",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "Account Status",
      field: "active",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#143340",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: BankResponse } = params;
        return data.active ? "Active" : "Inactive";
      },
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: DynamicRenderer,
      cellRendererParams: {
        dataArray: [
          {
            name: "Edit",
            nameColor: "#43A047",
            onClick: onEdit,
            icon: <EditIcon />,
          },
          {
            name: "Delete",
            nameColor: "#DD1717",
            onClick: handleOpen,
            icon: <DeleteIcon />,
          },
        ],
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowClass={rowClass}
          getRowClass={getRowClass}
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          rowHeight={60}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          immutableData
          getRowNodeId={(data: BankResponse) => {
            return data.id.toString();
          }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
      {open && (
        <DialogModal open={!!open} bankData={open} handleClose={handleClose} />
      )}
    </>
  );
};

export default AccountTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 350px;
  width: 100%;
  margin-bottom: 10px;
  overflow-y: scroll;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  & * {
    overflow: visible !important;
  }
`;

import useGetClientPaymentHistory from "app/hooks/clients/useGetClientPaymentHistory";
import Paginating from "app/views/Admin/components/Pagination";
import Spinner from "app/views/Web/components/Spinner";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../images/admin/search-icon.svg";
import PaymentTable from "./grid/PaymentTable";

const PERPAGE = 5;

const Payment = () => {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const { data, isLoading, isError } = useGetClientPaymentHistory(
    page,
    PERPAGE,
    searchValue
  );

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSearch = () => {
    setSearchValue(searchText);
  };

  return (
    <Wrapper>
      <H5>Payments</H5>
      <Middle>
        <SearchBox
          onSubmit={(event) => {
            event.preventDefault();
            onSearch();
          }}
        >
          <input
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              setPage(1);
              setSearchText(e.target.value);
            }}
          />
          {false ? (
            <Spinner color="black" />
          ) : (
            <SearchIcon
              onClick={() => {
                onSearch();
              }}
            />
          )}
        </SearchBox>
      </Middle>

      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#97B644" />
        </SpinnerWrapper>
      )}

      {isError && !isLoading && (
        <SpinnerWrapper>
          <NoProjectText>An error occurred</NoProjectText>
        </SpinnerWrapper>
      )}

      {data && !isError && !isLoading && (
        <>
          <PaymentTable data={data.data} />

          {data.data.length > 0 && (
            <Footer>
              <Show>Showing 1 to 5</Show>

              <Paginating
                totalElements={data.total}
                size={data.per_page}
                pageCount={3}
                currentPage={page}
                onChange={onPageChange}
              />
            </Footer>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Payment;

const Wrapper = styled.div`
  padding: 24px;
  background: #ffffff;
`;

const H5 = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 41px;
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const Middle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 23px;
`;

const SearchBox = styled.form`
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  border-radius: 12px;
  width: 475px;
  height: 52px;

  input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }
  & > svg {
    width: 18px;
    height: 18px;
    color: #303030;
    cursor: pointer;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

import { motion } from "framer-motion";
import {
  Anchor,
  BlueHr,
  Bold,
  Content,
  Li,
  Title,
  TitleBox,
  Ul,
} from "./styles";

const Cookies = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <TitleBox>
        <Title>Introduction</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        Adbond Harvest and Homes ("we," "us," "our") respects your privacy and
        is committed to protecting your personal information. This Cookies
        Policy explains how we use cookies and similar technologies on our
        website (the "Site").
      </Content>

      <TitleBox>
        <Title>What are Cookies?</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        Cookies are small text files placed on your device when you visit a
        website. They are widely used to enable websites to work more
        efficiently and provide information to the Site's owners. Cookies can be
        "persistent" or "session" cookies. Persistent cookies remain on your
        device for a set period, while session cookies are deleted when you
        close your browser.
      </Content>

      <TitleBox>
        <Title>How we use Cookies</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We use cookies on our Site to:
        <Ul>
          <Li>Personalise your experience on the Site;</Li>
          <Li>Help us understand how you use the Site;</Li>
          <Li>Monitor and analyse the performance of the Site;</Li>
          <Li>Provide you with relevant advertising.</Li>
        </Ul>
        We use both session and persistent cookies on our Site. The types of
        cookies we use on our Site include:
        <Ul>
          <Li>
            <Bold>Strictly necessary Cookies:</Bold>
            These cookies are essential to enable you to browse the Site and use
            its features. Without these cookies, some services cannot be
            provided.
          </Li>
          <Li>
            <Bold>Functionality Cookies: </Bold>
            These cookies allow the Site to remember your choices (such as your
            language preference) and provide enhanced, more personalised
            features.
          </Li>
          <Li>
            {" "}
            <Bold>Performance Cookies: </Bold>
            These cookies collect information about how visitors use the Site,
            such as which pages they visit most often and if they receive error
            messages from web pages. These cookies are used to improve how the
            Site works.
          </Li>
          <Li>
            {" "}
            <Bold>Advertising Cookies: </Bold>
            These cookies deliver relevant advertisements to you and your
            interests. They are also used to limit the number of times you see
            an advertisement and to help measure the effectiveness of
            advertising campaigns.
          </Li>
        </Ul>
      </Content>

      <TitleBox>
        <Title>Third-Party Cookies</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We may also use third-party cookies on our Site. For example, we use
        Google Analytics to help us analyse how visitors use the Site. Google
        Analytics uses cookies to collect information about how visitors use the
        Site.
        <br />
        <br />
        The information generated by the cookie about your use of the Site
        (including your IP address) will be transmitted to and stored by Google
        on servers in the United States. Google will use this information to
        evaluate your use of the Site, compile reports on website activity for
        website operators, and provide other services relating to website
        activity and internet usage.
        <br />
        <br />
        Google may also transfer this information to third parties where
        required by law or where such third parties process the information on
        Google's behalf. For more information about Google's privacy policy,
        please visit{" "}
        <Anchor href="https://policies.google.com/privacy">
           https://policies.google.com/privacy
        </Anchor>{" "}
        <br />
        <br />
        We also use social media plugins on our Site, such as Facebook, Twitter,
        and LinkedIn. These plugins may use cookies to track your browsing
        behaviour.
      </Content>

      <TitleBox>
        <Title>How to manage Cookies</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        Most web browsers allow you to manage your cookie preferences. For
        example, you can set your browser to refuse cookies or to alert you when
        cookies are being sent. You can also delete cookies that have already
        been set. If you do not want to receive cookies, please refer to your
        browser's help menu to learn how to manage your cookies. <br />
        <br />
        Please note that if you disable cookies, some site features may not work
        correctly.
      </Content>

      <TitleBox>
        <Title>Changes to this Cookies’ policy</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        We may update this Cookies Policy from time to time. We will post any
        changes on this page with an updated revision date. If we make any
        material changes to this Cookies Policy, we will notify you via email or
        a notice on the Site.
      </Content>

      <TitleBox>
        <Title>Contact Us</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        If you have any questions about this Cookies Policy, please contact us
        at{" "}
        <Anchor href="https://www.adbondharvestandhomes.com">
          https://www.adbondharvestandhomes.com
        </Anchor>{" "}
         or via <Anchor href="tel:+2349123978725"> +2349123978725</Anchor>. You
        can also visit us at our office on the 3rd and 4th-floor centage plaza,
        Allen avenue, Ikeja, Lagos state Nigeria.
      </Content>
    </motion.div>
  );
};

export default Cookies;

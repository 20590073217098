import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import NameRenderer from "./nameRenderer";
import ActionRenderer from "./actionRenderer";
import { clientObj } from "app/api/client/types";
import Spinner from "app/views/Web/components/Spinner";

interface GridProps {
  data?: clientObj[];
  isLoading: boolean;
  selectClient: (data: clientObj, type: boolean) => void;
  selectedClient: clientObj[];
}

type ClientField = keyof clientObj | undefined | "action";

export interface GridDef extends ColDef {
  field: ClientField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: clientObj[];
}

const AllClientGrid: React.FC<GridProps> = ({
  data,
  isLoading,
  selectClient,
  selectedClient,
}) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: clientObj[] | undefined = data;
  const dataRef = useRef<any>();
  dataRef.current = selectedClient;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "",
      sortable: false,
      field: "action",
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
      cellRendererParams: {
        selectedClient: () => dataRef.current,
        selectClient: selectClient,
      },
    },
    {
      headerName: "Client Name",
      field: "fullname",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 1.4,
      cellRendererFramework: NameRenderer,
      wrapText: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.5,
      cellStyle: {
        ...gridCellStyle,
        color: "#28647D",
      },
      wrapText: true,
    },
    {
      headerName: "Phone Number",
      field: "phone_number",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData as clientObj[],
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {isLoading ? (
        <MainLoaderWrapper height="200px">
          <Spinner color="#AFD34F" />
        </MainLoaderWrapper>
      ) : GridData && GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={50}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: clientObj) => {
              return data.id.toString();
            }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>You have no clients</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default AllClientGrid;

interface loaderProps {
  height?: string;
}

export const MainLoaderWrapper = styled.div<loaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
`;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "1rem",
  "line-height": "140%",
};

const GridWrapper = styled.div`
  height: 305px;
  width: 100%;
  margin: 0px 0 10px;
  overflow-y: scroll;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 5rem 0;

  & > p {
    color: #5e5e5e;
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BankDetailsValidation } from "./components/validation";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { SingleOption, UserResponse } from "app/types/common";
import useGetAdminBankList from "app/hooks/utilities/useGetAdminBankList";
import useAdminEditUser from "app/hooks/users/useAdminEditUser";
import { useAppSelector } from "app/redux";
import { getAuth } from "app/redux/auth/authSlice";

const BankDetails = () => {
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState<SingleOption>();
  const user = useAppSelector(getAuth).user as UserResponse;
  const { mutate: updateMutate, isLoading: isUpdating } = useAdminEditUser();
  const { data: bankList, isLoading: bankListLoading } = useGetAdminBankList();

  useEffect(() => {
    if (user && bankList) {
      const { account_name, account_number, bank } = user;
      setAccountName(account_name || "");
      setAccountNumber(account_number || "");
      if (!bank) return;
      const selectedBank = bankList?.filter((ev) => ev.name === bank)[0];
      selectedBank &&
        setBank({
          value: selectedBank.id,
          label: selectedBank.name,
        });
    }
  }, [user, bankList]);

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name,
      };
      return temp;
    });
  };

  const onSubmit = () => {
    if (!bank) return;
    const payload = {
      user_id: user.id,
      account_name: accountName,
      bank_id: bank.value,
      account_number: accountNumber,
    };

    updateMutate(payload, {
      onSuccess: () => toast.success("Update Successful!"),
    });
  };

  return (
    <Container
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <Formik
        initialValues={{
          accountName,
          accountNumber,
          bank,
        }}
        enableReinitialize
        validationSchema={BankDetailsValidation}
        onSubmit={onSubmit}
      >
        <Form>
          <H6>Bank Details</H6>
          <P>Provide your bank details here.</P>
          <FormWrapper>
            <InputField
              name="accountName"
              label="Account Name"
              placeholder="Enter your account name"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
            <InputField
              name="accountNumber"
              label="Account Number"
              placeholder="Enter your account number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            />
            <SelectField
              name="bank"
              label="Bank Name"
              value={bank as SingleOption}
              onChange={(e) => setBank(e as SingleOption)}
              options={formatData(bankList)}
              selectType="normal"
              isLoading={bankListLoading}
            />
          </FormWrapper>
          <SaveBtn type="submit" disabled={isUpdating}>
            {isUpdating && <Spinner color="white" />}
            Save Changes
          </SaveBtn>
        </Form>
      </Formik>
    </Container>
  );
};

export default BankDetails;

const Container = styled(motion.div)`
  & > form {
    width: 60%;
  }
`;

const H6 = styled.div`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 24px;
`;

const P = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin-bottom: 27px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px 62px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  &:disabled {
    pointer-events: none;
    background-color: #d8e8b8;
  }
`;

import React from "react";
import styled from "styled-components";
import AvatarContainer from "../AvatarContainer";
import { NewsResponse } from "app/api/community/types";
import mediaQueries from "styles/utils/mediaQueries";
import { mediaObj } from "styles/utils/media";
import { motion } from "framer-motion";
import { cardVariant } from "app/utils/animationVariants";

interface Props {
  data: NewsResponse;
}

const NewsCard: React.FC<Props> = ({ data }) => {
  const { title, description, cover_photo, user, created_at } = data;

  return (
    <Container variants={cardVariant} custom={0.8}>
      <AbsoluteBox>
        <Tag>News</Tag>
      </AbsoluteBox>
      <ImgWrapper>
        <img src={cover_photo.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title} </h6>
        <p>{description}</p>
        <AvatarContainer
          name={
            (user?.firstname || "admin") + " " + (user?.lastname || "admin")
          }
          time={created_at}
          value={user?.profile_photo?.url}
        />
      </ContentWrapper>
    </Container>
  );
};

export default NewsCard;

const Container = styled(motion.div)`
  align-self: center;
  width: 784px;
  position: relative;
  display: flex;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;

  ${mediaObj.smallDesktop} {
    gap: 15px;
    width: 100%;
    padding: 10px;
  }

  ${mediaObj.tablet} {
    flex-direction: column;
    height: max-content;
    padding: 24px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaObj.tablet} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    height: 150px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin: 6px 0 15px;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaObj.tablet} {
    gap: 8px;
  }

  ${mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;

    & > h6 {
      font-size: 14px;
      line-height: 18px;
      width: 75%;
    }

    & > p {
      font-size: 12px;
      line-height: 15px;
      margin: 5px 0 0;
    }
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  background: #48b6e3;
  color: #fbfbfb;
  border-radius: 0px 8px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;

  ${mediaQueries.mobile} {
    font-size: 10px;
    height: 20px;
  }
`;

import { Box } from "@mui/material";
import { OfferResponse } from "app/api/offers/types";
import { FC } from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { formatPrice } from "app/utils";
import { useNavigate } from "react-router";
import { PropertiesRoutes, UserRoutes } from "app/utils/data";

interface props {
  width?: string;
  offer: OfferResponse;
}

const SaleOfferCard: FC<props> = ({ width, offer }) => {
  const navigate = useNavigate();

  return (
    <Wrapper width={width}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"20px"}
      >
        <Title>Your Sale Offer</Title>
        <DetailsBtn
          onClick={() =>
            navigate(
              `${UserRoutes.Properties}/${PropertiesRoutes.Offers}/${offer.id}`
            )
          }
        >
          See details
        </DetailsBtn>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"14px"}>
        <Box display={"flex"} gap={"8px"} alignItems={"center"}>
          <Label>Property</Label>
          <Detail>{offer.project}</Detail>
        </Box>
        <Box display={"flex"} gap={"8px"} alignItems={"center"}>
          <Label>Package</Label>
          <Detail>{offer.customerPackageItem.packageItem.package.name}</Detail>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box display={"flex"} gap={"8px"} alignItems={"center"}>
            <Label>Starting price</Label>
            <Detail>{formatPrice(offer.offer_price)}</Detail>
          </Box>
          <Offers>{offer.bids.length} offers made</Offers>
        </Box>
      </Box>
    </Wrapper>
  );
};

export default SaleOfferCard;

const Wrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : "334px")};
  height: 170px;
  padding: 16px;
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;

  ${mediaQueries.mobile} {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #2a3313;
`;

const DetailsBtn = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
`;

const Label = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 5px;
  font-weight: 500;
  font-size: 12px;
  color: #fbfbfb;
  background: #143340;
  border-radius: 2px;
`;

const Detail = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #5e5e5e;
`;

const Offers = styled.span`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #48b6e3;
`;

import { ClientAgeGroupObj } from "app/api/analytics/types";
import useGetAgeGroups from "app/hooks/utilities/useGetAgeGroups";
import DualRing from "app/views/Web/components/loader";
import { MainLoaderWrapper } from "app/views/Web/Project/projectCategory";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { toast } from "react-toastify";
import styled from "styled-components";

export interface chartType {
  options: {
    dataLabels: {
      enabled: boolean;
    };
    stroke: {
      curve: string;
    };
    noData: {
      text: string;
    };
    xaxis: {
      categories: string[];
    };
    plotOptions: any;
    legend: {
      position: string;
    };
    title: any;
    colors: string[];
  };
  series: any[];
}

interface props {
  data: ClientAgeGroupObj[];
  isLoading: boolean;
  legendPosition?: "top" | "right" | "bottom" | "left";
  colors?: string[];
}

const AgeGroupChart: React.FC<props> = ({
  data,
  isLoading,
  legendPosition = "bottom",
  colors,
}) => {
  const { data: ageGroups, error } = useGetAgeGroups();
  const ageGroupArr = ageGroups?.map((x) => x.group) || [];

  const parseData = () => {
    const ageGroupChartData: number[] = [];
    ageGroupArr.forEach((group) => {
      const index = data.findIndex((x) => x.age_group === group);
      if (index > -1) {
        ageGroupChartData.push(Number(data[index].total));
      } else {
        ageGroupChartData.push(0);
      }
    });
    return [{ name: "Age Group", data: ageGroupChartData }];
  };

  useEffect(() => {
    error && toast.error("Failed to fetch age groups");
  }, [error]);

  const initialChartData: chartType = {
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        categories: ["18-24", "25-34", "35-44", "45-54", "55-64", "65-100"],
      },
      plotOptions: {
        bar: {
          columnWidth: "35%",
        },
      },
      legend: {
        position: legendPosition,
      },
      title: {
        text: "",
        floating: false,
        offsetY: 0,
        align: "left",
        style: {
          color: "#181D0B",
          fontWeight: "500",
          fontSize: "13px",
          lineHeight: "140%",
        },
      },
      colors: colors || ["#49B7E4"],
    },
    series: [
      {
        name: "Age Group",
        data: [0, 0, 0, 0, 0, 0],
      },
    ],
  };
  const [chartConfig, setChartConfig] = useState(initialChartData);

  useEffect(() => {
    setChartConfig({
      ...chartConfig,
      options: {
        ...chartConfig.options,
        xaxis: {
          ...chartConfig.options.xaxis,
          categories: ageGroupArr,
        },
      },
      series: parseData(),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ageGroups, data]);

  return (
    <Container>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <Chart
          series={chartConfig.series}
          height={300}
          options={chartConfig.options}
          type={"bar"}
        />
      )}
    </Container>
  );
};

export default AgeGroupChart;

const Container = styled.div`
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

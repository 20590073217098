import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./types";
import { setToken, signIn } from "./actions";
import { RootState } from "..";
import storage from "app/utils/storage";

const accessToken = storage.get("adbondAccessToken");

const initialState: AuthState = {
  accessToken: accessToken,
  user: undefined,
  login: {
    error: null,
    pending: false,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUser: (state, action: PayloadAction<AuthState["user"]>) => {
      state.user = action.payload;
    },
    loginFailed: (state): any => {
      state.user = undefined;
      state.accessToken = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(setToken.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });
    builder.addCase(signIn.pending, (state) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: true,
          error: null,
          response: undefined,
        },
      };
    });
    builder.addCase(signIn.fulfilled, (state, { payload }) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: null,
          response: payload,
        },
      };
    });
    builder.addCase(signIn.rejected, (state, action) => {
      return {
        ...state,
        login: {
          ...state.login,
          pending: false,
          error: action.payload,
          response: null,
        },
      };
    });
  },
});

export const { resetAuth, setUser, loginFailed } = authSlice.actions;

export const getAuth = (state: RootState): AuthState => state.auth;

export default authSlice.reducer;

import { useQuery } from "@tanstack/react-query";
import { getAgeGroupChartData } from "app/api/analytics";
import { parseError } from "app/utils";
import { AnalyticsQueryKeys } from "./types";

const useAdminAgeGroup = () => {
  return useQuery(
    [AnalyticsQueryKeys.adminAgeGroup],
    () => getAgeGroupChartData(),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useAdminAgeGroup;

import { useState } from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import PasswordChange from "./components/PasswordChange";
import PasswordRecovery from "./components/PasswordRecovery";
import { motion } from "framer-motion";

const Security = () => {
  const [step, setStep] = useState(1);

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <H1>Account security</H1>
      {step === 1 && <PasswordChange setStep={setStep} />}
      {step === 2 && <PasswordRecovery setStep={setStep} />}
    </motion.div>
  );
};

export default Security;

const H1 = styled.div`
  font-weight: 450;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 32px;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

import { EventResponse } from "app/api/community/types";
import { cardVariant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  data: EventResponse;
}

const EventCard: React.FC<Props> = ({ data }) => {
  const {
    title,
    description,
    location,
    cover_photo,
    start_date,
    start_time,
    end_date,
    end_time,
  } = data;

  return (
    <Container variants={cardVariant} custom={0.8}>
      <ImgWrapper>
        <img src={cover_photo.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title}</h6>
        <p>{description}</p>
        <BottomWrapper>
          <EventLocation>
            <span>Event Location: </span>
            <span>{location.name}</span>
          </EventLocation>
          <GridWrapper>
            <EventBox>
              <span>Start Date:</span>
              <span> {start_date}</span>
            </EventBox>
            <EventBox>
              <span>Start Time: </span>
              <span>{start_time}</span>
            </EventBox>

            <EventBox>
              <span>End Date:</span>
              <span> {end_date}</span>
            </EventBox>
            <EventBox>
              <span>End Time: </span>
              <span>{end_time}</span>
            </EventBox>
          </GridWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default EventCard;

const Container = styled(motion.div)`
  align-self: center;
  width: 784px;
  height: 228px;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;

  ${mediaObj.smallDesktop} {
    height: max-content;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    padding: 10px;
  }

  ${mediaObj.tablet} {
    flex-direction: column;
    height: max-content;
  }

  ${mediaQueries.mobile} {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaQueries.mobile} {
    width: 110px;
    height: 110px;
    flex-shrink: 0;
  }

  ${mediaObj.tablet} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    height: 150px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;

    ${mediaQueries.mobile} {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin-top: 12px;
    margin-bottom: 7px;

    ${mediaQueries.mobile} {
      font-size: 12px;
      line-height: 15px;
      margin: 5px 0 7px;
    }
  }

  ${mediaObj.tablet} {
    gap: 8px;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
`;

const EventBox = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  display: flex;
  flex-direction: column;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #5e5e5ee0;
  }
`;

const EventLocation = styled(EventBox)`
  display: unset;
  flex-direction: unset;
  font-size: 0.9rem;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 32px;

  ${mediaObj.smallDesktop} {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 20px;
    margin-top: 10px;
  }

  ${mediaQueries.mobile} {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 20px;
    margin-top: 10px;
  }
`;

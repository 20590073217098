import styled from "styled-components";

enum Status {
  approved = "approved",
  pending = "pending",
  rejected = "rejected",
}

const StatusRenderer = ({ data }: { data: any }) => {
  return <Container status={data?.confirmed}>{data?.confirmed}</Container>;
};

export default StatusRenderer;

interface ContainerProps {
  status?: string;
}

const Container = styled.div<ContainerProps>`
  width: max-content;
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  text-transform: capitalize;
  background-color: ${({ status }) => status === Status.approved && "#43a047"};
  background-color: ${({ status }) => status === Status.rejected && "#DD1717"};
  background-color: ${({ status }) => status === Status.pending && "#757575"};
`;

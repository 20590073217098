import { labelValueType } from "../../../types/common";

export const martialStatusOptions: labelValueType[] = [
  {
    label: "Single",
    value: "single",
  },
  {
    label: "Married",
    value: "married",
  },
  {
    label: "Divorced",
    value: "divorced",
  },
];

export const titleOptions: labelValueType[] = [
  {
    label: "Mr.",
    value: "mr",
  },
  {
    label: "Mrs.",
    value: "mrs",
  },
  {
    label: "Miss",
    value: "miss",
  },
];

export const genderOptions: labelValueType[] = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const nationalityOptions: labelValueType[] = [
  {
    label: "Nigeria",
    value: "nigeria",
  },
  {
    label: "Ghana",
    value: "ghana",
  },
  {
    label: "Kenya",
    value: "kenya",
  },
];

export const stateOptions: labelValueType[] = [
  {
    label: "Enugu",
    value: "enugu",
  },
  {
    label: "Lagos",
    value: "lagos",
  },
  {
    label: "Ogun",
    value: "ogun",
  },
];

export const employmentStatusOptions: labelValueType[] = [
  {
    label: "Employed",
    value: "employed",
  },
  {
    label: "UnEmployed",
    value: "unemployed",
  },
  {
    label: "Enterprenuer",
    value: "enterprenuer",
  },
];

export enum paymentOptionEnum {
  fullPayment = 0,
  installment,
}

export const paymentOptions: labelValueType<string, paymentOptionEnum>[] = [
  {
    label: "Full Payment",
    value: paymentOptionEnum.fullPayment,
  },
  {
    label: "Installment",
    value: paymentOptionEnum.installment,
  },
];

import { EventResponse } from "app/api/community/types";
import useDeleteEvent from "app/hooks/community/useDeleteEvent";
import Actions from "app/views/Admin/components/Actions";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { ContentTypes } from "../..";

interface Props {
  data: EventResponse;
}

const EventCard: React.FC<Props> = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    title,
    description,
    location,
    cover_photo,
    start_date,
    start_time,
    end_date,
    end_time,
  } = data;

  const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteEvent();

  const onDelete = () => {
    const payload = {
      event_id: data.id,
    };
    deleteMutate(payload, {
      onSuccess: onDeleteSuccess,
    });
  };

  const onDeleteSuccess = () => {
    toast.success(`Event Deleted Successfully!`);
  };

  const onEdit = () => {
    searchParams.set("create", ContentTypes.events);
    searchParams.set("edit", title);
    setSearchParams(searchParams);
  };

  return (
    <Container>
      <AbsoluteBox>
        <Actions
          editHandler={onEdit}
          deleteHandler={onDelete}
          isDeleting={deleteLoading}
        />
      </AbsoluteBox>
      <ImgWrapper>
        <img src={cover_photo.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title}</h6>
        <p>{description}</p>
        <BottomWrapper>
          <EventLocation>
            <span>Event Location: </span>
            <span>{location.name}</span>
          </EventLocation>
          <GridWrapper>
            <EventBox>
              <span>Start Date:</span>
              <span> {start_date}</span>
            </EventBox>
            <EventBox>
              <span>Start Time: </span>
              <span>{start_time}</span>
            </EventBox>

            <EventBox>
              <span>End Date:</span>
              <span> {end_date}</span>
            </EventBox>
            <EventBox>
              <span>End Time: </span>
              <span>{end_time}</span>
            </EventBox>
          </GridWrapper>
        </BottomWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default EventCard;

const Container = styled.div`
  width: 784px;
  height: 228px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin-top: 12px;
    margin-bottom: 7px;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: auto;
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 16px;
`;

const EventBox = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  display: flex;
  flex-direction: column;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #5e5e5ee0;
  }
`;

const EventLocation = styled(EventBox)`
  display: unset;
  flex-direction: unset;
  font-size: 0.9rem;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 32px;
`;

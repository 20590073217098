import * as Yup from "yup";

export const QuestionValidation = Yup.object({
  question: Yup.object()
    .test("has text", "Cannot save an empty body", (value: any) => {
      const contentState = value.getCurrentContent();
      return (
        contentState.hasText() && contentState.getPlainText().trim() !== ""
      );
    })
    .required("This field is required.")
    .nullable(),
});

export const OptionValidation = Yup.object({
  option: Yup.object()
    .test("has text", "Cannot save an empty body", (value: any) => {
      const contentState = value.getCurrentContent();
      return (
        contentState.hasText() && contentState.getPlainText().trim() !== ""
      );
    })
    .required("This field is required.")
    .nullable(),
});

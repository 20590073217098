import { useMutation } from "@tanstack/react-query";
import { bankReferencePayment } from "app/api/payment";
import { parseError } from "app/utils";

const useBankReferencePayment = () => {
  return useMutation(bankReferencePayment, {
    onError: (error) => parseError(error),
  });
};

export default useBankReferencePayment;

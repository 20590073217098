import useAdminGetBankAccounts from "app/hooks/analytics/useBankAccount";
import Spinner from "app/views/Web/components/Spinner";
import React from "react";
import styled from "styled-components";
import AccountTable from "./grid/AccountTable";

const DisplayAccountTable = () => {
  const { data, isLoading, isError } = useAdminGetBankAccounts();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>You have no data yet</NoProjectText>
      </SpinnerWrapper>
    );

  return <>{data && <AccountTable data={data} />}</>;
};

export default DisplayAccountTable;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

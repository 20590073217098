import React, { useEffect, useState } from "react";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { Form, Formik } from "formik";
import { CreateBtn, ErrorText, FormWrapper, SpinnerWrapper } from "../styles";
import { CreateNewsValidation } from "../validation";
import CoverImage from "../CoverImage";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import { useSearchParams } from "react-router-dom";
import useGetNewsByTitle from "app/hooks/community/useGetNewsByTitle";
import useUpdateNews from "app/hooks/community/useUpdateNews";
import useCreateNews from "app/hooks/community/useCreateNews";

const NewsSection = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [coverImageId, setCoverImageId] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const editTitle = searchParams.get("edit");
  const {
    data: newsData,
    isFetching: newsFetching,
    isError,
  } = useGetNewsByTitle(editTitle as string);
  const { mutate: createMutate, isLoading: isPosting } = useCreateNews();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateNews();

  const onSuccess = () => {
    toast.success(`News ${editTitle ? "Updated" : "Created"} Successfully!`);
    setTimeout(() => {
      const y = searchParams.get("activeTab");
      y && setSearchParams({ activeTab: y });
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!coverImageId) return;
    const payload = {
      title,
      file_id: coverImageId,
      description,
    };
    if (editTitle) {
      if (!newsData) return;
      const result = {
        ...payload,
        news_id: newsData.id,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editTitle && newsData) {
      const { title, description, cover_photo } = newsData;
      setTitle(title);
      setDescription(description);
      setCoverImageId(cover_photo.id);
    }
  }, [newsData, editTitle]);

  return (
    <Formik
      initialValues={{
        title,
        description,
        coverImageId,
      }}
      validationSchema={CreateNewsValidation}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      <FormWrapper>
        {editTitle && newsFetching && (
          <SpinnerWrapper>
            <Spinner color="black" />
          </SpinnerWrapper>
        )}
        {editTitle && isError && (
          <SpinnerWrapper>
            <ErrorText>An Error Occured</ErrorText>
          </SpinnerWrapper>
        )}
        {(!editTitle || (editTitle && !isError && !newsFetching)) && (
          <Form>
            <InputField
              name="title"
              label="Title"
              placeholder="Type event title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              isRequired
            />
            <InputField
              name="description"
              label="Description"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputType="textarea"
              isRequired
            />

            <CoverImage
              setStateFn={setCoverImageId}
              value={
                editTitle && newsData ? newsData?.cover_photo.url : undefined
              }
            />

            <CreateBtn type="submit">
              {(isPosting || isUpdating) && <Spinner color="black" />}
              {editTitle ? "Update" : "Create"}
            </CreateBtn>
          </Form>
        )}
      </FormWrapper>
    </Formik>
  );
};

export default NewsSection;

import { useQuery } from "@tanstack/react-query";
import { adminGetOfferPaymentExport } from "app/api/payment";
import { parseError } from "app/utils";
import { PaymentQueryKeys } from "./types";
import FileDownload from "js-file-download";

const useAdminGetOfferPaymentExport = (
  category_id: number,
  type: string,
  isEnabled: boolean
) => {
  return useQuery(
    [PaymentQueryKeys.adminGetOfferPaymentExport, category_id, type],
    () => adminGetOfferPaymentExport(category_id, type),
    {
      enabled: !!category_id && !!type && !!isEnabled,
      onError: (error) => parseError(error),
      onSuccess: (data) => {
        FileDownload(data.data, `sales-report.xls`);
      },
    }
  );
};

export default useAdminGetOfferPaymentExport;

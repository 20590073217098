import { FC } from "react";
import styled from "styled-components";
import { CustomTourResponse } from "app/api/site tour/types";

export interface upcomingEvent {
  data: CustomTourResponse;
}

const UpcomingTourCard: FC<upcomingEvent> = ({ data }) => {
  return (
    <Container>
      <Entry>
        <EntryLabel>Name:</EntryLabel>
        <EntryValue>{data.category}</EntryValue>
      </Entry>
      <Entry>
        <EntryLabel>Package:</EntryLabel>
        <EntryValue>{data.project}</EntryValue>
      </Entry>
      <Entry>
        <EntryLabel>Location:</EntryLabel>
        <EntryValue>{data.location}</EntryValue>
      </Entry>
      <Entry>
        <EntryLabel>Date:</EntryLabel>
        <EntryValue>{data.date}</EntryValue>
      </Entry>
      <Entry>
        <EntryLabel>Time:</EntryLabel>
        <EntryValue>{data.time}</EntryValue>
      </Entry>
    </Container>
  );
};

export default UpcomingTourCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 12px;
  padding: 18px 12px;
  width: 245px;
  height: 199px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 6px;
`;

const Entry = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

const EntryLabel = styled.p`
  font-weight: 450;
  font-size: 13px;
  line-height: 140%;
  color: #0b1d25;
`;

const EntryValue = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

import { PromoCategoryObj } from "app/api/offers/types";
import React from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  data: PromoCategoryObj[];
}

const PromoCategory: React.FC<Props> = ({ data }) => {
  return (
    <GridItem>
      <span>Category: </span>
      {data.length === 1 ? (
        <span>{data[0].product.name}</span>
      ) : (
        <span>{data.map((ev) => ev.product.name).join(", ")}</span>
      )}
    </GridItem>
  );
};

export default PromoCategory;

const GridItem = styled.div`
  width: max-content;
  padding: 4px 8px;
  background-color: #cde6f5;
  border-radius: 4px;
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: "#2A3313";
    text-transform: capitalize;
  }

  ${mediaQueries.mobile} {
    font-size: 0.75rem;
  }
`;

import { motion } from "framer-motion";
import { BlueHr, Content, Li, Title, TitleBox, Ul } from "./styles";

const TC = () => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <TitleBox>
        <Title>Use of Site</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        The Site is provided for informational purposes only. You may use the
        Site only for lawful purposes and in accordance with these Terms and
        Conditions. You agree not to use the Site if it;
        <Ul>
          <Li>
            Violates applicable federal, state, local, or international law or
            regulation.
          </Li>

          <Li>
            Exploits harm or attempts to exploit or harm minors by exposing them
            to inappropriate content, asking for personally identifiable
            information, or otherwise.
          </Li>

          <Li>
            Transmits or procures the sending of any advertising or promotional
            material, including any "junk mail," "chain letter" "spam," or any
            other similar solicitation.
          </Li>

          <Li>
            To impersonate or attempt to impersonate AdBond Harvest and Homes,
            an AdBond Harvest and Homes employee, another user, or any other
            person or entity (including, without limitation, by using e-mail
            addresses or screen names associated with any of the foregoing).
          </Li>

          <Li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Site, or which, as determined by AdBond
            Harvest and Homes, may harm AdBond Harvest and Homes or users of the
            Site or expose them to liability.
          </Li>
        </Ul>
      </Content>

      <TitleBox>
        <Title>Intellectual Property</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        All content, designs, graphics, logos, page headers, button icons,
        scripts, and service names included in or made available through the
        Site are the property of Adbond Harvest and Homes or its content
        suppliers and are protected by United States and international copyright
        laws. Accordingly, you may not use, copy, reproduce, distribute,
        transmit, broadcast, display, sell, license, or otherwise exploit any
        content on the Site for any other purposes without the prior written
        consent of AdBond Harvest and Homes.
      </Content>

      <TitleBox>
        <Title>Disclaimer of Warranties</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        The Site and its content are provided "as is" and without warranties of
        any kind, either express or implied, including, but not limited to,
        implied warranties of merchantability, fitness for a particular purpose,
        or non-infringement. AdBond Harvest and Homes does not warrant that the
        Site or its content will be uninterrupted or error-free, that defects
        will be corrected, or that the Site or its server is free of viruses or
        other harmful components. Adbond Harvest and Homes does not warrant or
        make any representations regarding the use or the results of the use of
        the Site or its content in terms of their correctness, accuracy,
        reliability, or otherwise.
      </Content>

      <TitleBox>
        <Title>Limitation of Liability</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        AdBond Harvest and Homes and its affiliates, officers, directors,
        employees, agents, suppliers, or licensors shall not be liable for any
        damages, whether direct, indirect, incidental, consequential, or
        punitive, arising from or related to the use of, or the inability to
        use, the Site or its content, even if AdBond Harvest and Homes has been
        advised of the possibility of such damages.
      </Content>

      <TitleBox>
        <Title>Indemnification</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        You agree to indemnify, defend, and hold harmless AdBond Harvest and
        Homes and its affiliates, officers, directors, employees, agents,
        suppliers, or licensors from and against all claims, liabilities,
        damages, losses, costs, expenses, fees (including reasonable attorneys'
        fees), arising from or related to your use of the Site or its content or
        your violation of these Terms and Conditions.
      </Content>

      <TitleBox>
        <Title>Modification and termination of the Site</Title>
        <BlueHr />
      </TitleBox>
      <Content>
        AdBond Harvest and Homes reserves the right to modify, suspend, or
        terminate the Site.
      </Content>
    </motion.div>
  );
};

export default TC;

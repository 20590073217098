export enum propertiesTabPath {
  myProperties = 1,
  mySalesOffer,
  myBids,
}

export const propertiesTabData = [
  {
    name: "My assets",
    id: propertiesTabPath.myProperties,
  },
  {
    name: "Resale order",
    id: propertiesTabPath.mySalesOffer,
  },
  {
    name: "Bids history",
    id: propertiesTabPath.myBids,
  },
];

export const salesStatusData = [
  {
    name: "Approved",
    id: 1,
  },
  {
    name: "Pending",
    id: 2,
  },
  {
    name: "Rejected",
    id: 3,
  },
];

export enum CommunityQueryKeys {
  getEventByTitle = "get-event-by-title",
  getForumByTitle = "get-forum-by-title",
  getNewsByTitle = "get-news-by-title",
  getPostByTitle = "get-post-by-title",
  clientGetPostByTitle = "client-get-post-by-title",
  getPostComments = "get-post-comments",
  adminGetNews = "admin-get-news",
  getPromos = "get-promos",
  adminGetEvents = "admin-get-events",
  adminGetForums = "admin-get-forums",
  adminGetOffers = "admin-get-offer",
  adminGetBlogpost = "admin-get-blogpost",
  clientGetNews = "client-get-news",
  clientGetEvents = "client-get-events",
  clientGetForums = "client-get-forums",
  communityGetOffers = "community-get-offer",
  clientGetBlogpost = "client-get-blogpost",
  getAPromo = "get-a-promo",
}

import styled from "styled-components";
// import successImg from "app/images/completeAssessment.png";
import { useNavigate } from "react-router";
import { mediaObj } from "styles/utils/media";
import YouTube from "react-youtube";

const Success = () => {
  const navigate = useNavigate();
  const videoUrl = "https://www.youtube.com/watch?v=B-EUzed_SWA";
  const title = "THANK YOU FOR PARTICIPATING";
  return (
    <Wrapper>
      <VideoBox videoId={videoUrl.split("=")[1]} title={title} />
      {/* <SuccessImg src={successImg} />
      <Text>
        Thank you for participating in this recruitment process, your results
        will be communicated via e-mail, and if successful, a HR personnel will
        contact you for your video interview session.
      </Text> */}
      <HomeBtn onClick={() => navigate("/")}>Go Back Home</HomeBtn>
    </Wrapper>
  );
};

export default Success;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  ${mediaObj.mobile} {
    margin-top: 20px;
  }
`;

const SuccessImg = styled.img`
  width: 248.13px;
  height: 269.27px;
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  width: 90%;
  text-align: center;
  color: #181d0b;

  ${mediaObj.mobile} {
    font-size: 15px;
  }
`;

const HomeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 20px auto 0;
  width: 200px;
  height: 42px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
`;

const VideoBox = styled(YouTube)`
  width: 100%;
  height: 325.06px;
  border-radius: 8px;

  & > iframe {
    width: 100%;
    height: 100%;
  }
`;

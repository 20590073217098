import { Box } from "@mui/material";
import useAdminSetCommission from "app/hooks/staffs/useAdminSetCommission";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import CustomModal from "../../../components/modal";
import { SetCommissionValidation } from "../validation";

interface props {
  handleClose: () => void;
  isOpen: boolean;
  staffTypeId: string;
  installDirect?: string;
  installIndirect?: string;
  fullDirect?: string;
  fullIndirect?: string;
}

const SetCommissionModal: FC<props> = ({
  handleClose,
  isOpen,
  staffTypeId,
  installDirect,
  installIndirect,
  fullDirect,
  fullIndirect,
}) => {
  const [directValueInstall, setDirectValueInstall] = useState(
    installDirect || ""
  );
  const [indirectValueInstall, setIndirectValueInstall] = useState(
    installIndirect || ""
  );
  const [directValueFull, setDirectValueFull] = useState(fullDirect || "");
  const [indirectValueFull, setIndirectValueFull] = useState(
    fullIndirect || ""
  );
  const { mutate, isLoading } = useAdminSetCommission();

  const onSuccess = () => {
    toast.success(`Updated Successfully!`);
    handleClose();
  };

  const onSubmitHandler = (values: any) => {
    const payload = {
      staff_type_id: +staffTypeId,
      installment: {
        direct: +directValueInstall,
        indirect: +indirectValueInstall,
      },
      fullpayment: {
        direct: +directValueFull,
        indirect: +indirectValueFull,
      },
    };
    mutate(payload, {
      onSuccess,
    });
  };

  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose}>
      <Wrapper>
        <Title>Set Commission</Title>
        <Formik
          initialValues={{
            installment_direct: directValueInstall,
            installment_indirect: indirectValueInstall,
            fullpayment_direct: directValueFull,
            fullpayment_indirect: indirectValueFull,
          }}
          validationSchema={SetCommissionValidation}
          enableReinitialize
          onSubmit={onSubmitHandler}
        >
          <Form>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} mb={3}>
              <InputField
                name="installment_direct"
                label="Installment direct(%)"
                placeholder="0"
                numberInput={"number"}
                min={1}
                value={directValueInstall}
                onChange={(e) => setDirectValueInstall(e.target.value)}
                isRequired
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} mb={3}>
              <InputField
                name="installment_indirect"
                label="Installment indirect(%)"
                placeholder="0"
                numberInput={"number"}
                min={1}
                value={indirectValueInstall}
                onChange={(e) => setIndirectValueInstall(e.target.value)}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} mb={3}>
              <InputField
                name="fullpayment_direct"
                label="Full Payment direct(%)"
                placeholder="0"
                numberInput={"number"}
                min={1}
                value={directValueFull}
                onChange={(e) => setDirectValueFull(e.target.value)}
              />
            </Box>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} mb={3}>
              <InputField
                name="fullpayment_indirect"
                label="Fullpayment indirect(%)"
                placeholder="0"
                numberInput={"number"}
                min={1}
                value={indirectValueFull}
                onChange={(e) => setIndirectValueFull(e.target.value)}
              />
            </Box>
            <RatingBtn>
              {isLoading && <Spinner color="white" />}
              Confirm Commission
            </RatingBtn>
          </Form>
        </Formik>
      </Wrapper>
    </CustomModal>
  );
};

export default SetCommissionModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.p`
  font-weight: 450;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const RatingBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 100%;
  height: 46px;
  background: #afd34f;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;

  & > div {
    /* margin: 0 10px 12px 0; */
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

import { Variants } from "framer-motion";

export const pageTransition: Variants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 4,
      type: "spring",
      bounce: 0.25,
      delayChildren: 3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 1,
    },
  },
};

export const cardVariant: Variants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: (i = 1) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: i,
      type: "spring",
      bounce: 0.45,
    },
  }),
};

export const imageVariant: Variants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
};

export const popUp: Variants = {
  initial: {
    opacity: 0,
  },
  animate: (i = 1) => ({
    opacity: 1,
    scale: [0.9, 1.1, 1],
    transition: {
      delay: i * 0.3,
      duration: 1,
      type: "spring",
    },
  }),
};

export const slideIn = (direction: "left" | "right" = "left"): Variants => {
  return {
    initial: {
      x: direction === "left" ? -70 : 70,
      opacity: 0,
    },
    animate: (i = 1) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
        duration: 1.4,
        type: "spring",
        bounce: 0.55,
      },
    }),
  };
};

export const fadeIn = (direction: "up" | "down" = "up"): Variants => {
  return {
    initial: {
      y: direction === "up" ? 40 : -10,
      opacity: 0,
    },
    animate: (i = 1) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.3,
        duration: 1,
        type: "spring",
        bounce: 0.6,
      },
    }),
  };
};

export const staggerContainer: Variants = {
  initial: {},
  animate: (i = 1) => ({
    transition: {
      staggerChildren: i * 0.5,
      delayChildren: 0.2,
    },
  }),
  closed: {},
};

export const delayStagger = (delay: number = 1): Variants => {
  return {
    initial: {},
    animate: (i = 1) => ({
      transition: {
        staggerChildren: i * 0.5,
        delayChildren: delay * 0.5,
        when: "beforeChildren",
      },
    }),
    closed: {},
  };
};

export const sidebar: Variants = {
  animate: {
    transform: "translateX(0%)",
    transition: {
      type: "tween",
      duration: 0.7,
    },
  },
  closed: {
    transform: "translateX(100%)",
    transition: {
      delay: 1,
      type: "spring",
      stiffness: 60,
      damping: 10,
    },
  },
};

export const variant: Variants = {
  animate: {
    scale: [0.95, 1.15, 1],
    transition: {
      type: "spring",
      stiffness: 60,
      damping: 10,
    },
  },
  closed: {
    transition: {
      type: "spring",
      stiffness: 60,
      damping: 10,
    },
  },
};

export const navWrapper = {
  animate: {
    transition: { staggerChildren: 0.2, delayChildren: 0.2 },
  },
  closed: {
    transition: {
      staggerChildren: 0.07,
      staggerDirection: -1,
      when: "afterChildren",
    },
  },
};

export const inViewSlideUp = {
  initial: {
    opacity: 0,
  },
  animate: (i = 1) => ({
    // delay: i * 0.5,
    opacity: 1,
    transform: ["translateY(60px)", "translateY(0px)"],
    transition: { type: "spring", stiffness: 100, ease: "easeOut" },
  }),
};

export const inViewSlideIn = (direction: "left" | "right" = "left") => {
  return {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transform:
        direction === "left"
          ? ["translateX(-200px)", "translateX(0px)"]
          : ["translateX(200px)", "translateX(0px)"],
      duration: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        ease: "cubic-bezier(0.17, 0.55, 0.55, 1)",
      },
    },
  };
};

export const cardTextAnimate: Variants = {
  initial: {
    y: 80,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 1.5,
    },
  },
};

export const delayCardText: Variants = {
  initial: {
    y: 80,
    opacity: 0,
  },
  animate: (i = 1) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i,
      type: "spring",
      bounce: 0.4,
      duration: 1.5,
    },
  }),
};

export const cardImageAnimate: Variants = {
  initial: {
    x: -100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    rotate: [0, 90, 0],
    transition: {
      type: "spring",
      bounce: 0.45,
      duration: 1.5,
    },
  },
};

export const slideToTop: Variants = {
  initial: {
    y: 450,
    // opacity: 0,
  },
  animate: (i = 1) => ({
    y: 0,
    opacity: 1,
    transition: {
      delay: i * 0.3,
      duration: 1.2,
      type: "spring",
      bounce: 0.45,
    },
  }),
};

export const slideInShake: Variants = {
  initial: {
    x: -70,
    opacity: 0,
  },
  animate: (i = 1) => ({
    x: 0,
    opacity: 1,
    rotate: [-31.84, 20, -31.84],
    transition: {
      delay: i * 0.3,
      duration: 1.4,
      type: "spring",
      bounce: 0.55,
    },
  }),
};

export const slideInShakeMobile: Variants = {
  initial: {
    x: -70,
    opacity: 0,
  },
  animate: (i = 1) => ({
    x: 0,
    opacity: 1,
    rotate: [-31.84, 20, -31.84, 0],
    transition: {
      delay: i * 0.3,
      duration: 1.4,
      type: "spring",
      bounce: 0.55,
    },
  }),
};

export const AuthConeAnim: Variants = {
  initial: {
    opacity: 0,
  },
  animate: (i = 3) => ({
    opacity: 1,
    transition: {
      delay: i,
      duration: 1,
      type: "spring",
      bounce: 0.2,
    },
  }),
};

export const opacityAnim: Variants = {
  initial: {
    opacity: 0,
  },
  animate: (i = 1) => ({
    opacity: i,
    transition: {
      type: "spring",
      bounce: 0.4,
    },
  }),
};

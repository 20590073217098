import { adminGetOfferPaymentsByCategoryResponse } from "app/api/payment/types";
import styled from "styled-components";

const PaymentRenderer = ({
  data,
}: {
  data: adminGetOfferPaymentsByCategoryResponse;
}) => {
  return (
    <Container isOnline={data.online}>
      {data.online ? "Online" : "Offline"}
    </Container>
  );
};

export default PaymentRenderer;

interface ContainerProps {
  isOnline: boolean;
}

const Container = styled.div<ContainerProps>`
  font-size: 14px;
  color: ${({ isOnline }) => (isOnline ? "#49B7E4" : "#5E5E5E")};
`;

import styled from "styled-components";
import homeImage from "app/images/home-image.png";
import { Box } from "@mui/material";
import mediaQueries from "styles/utils/mediaQueries";
import { OfferResponse } from "app/api/offers/types";
import { FC } from "react";
import { useNavigate } from "react-router";
import { UserRoutes } from "app/utils/data";
import { formatPrice } from "app/utils";

interface props {
  data: OfferResponse;
}

const DashboardCommunityCard: FC<props> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Image src={homeImage} />
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          <CardTxt>Type: {data.project}</CardTxt>
          <Box display={"flex"} gap={"5px"}>
            <CardTxt>Property: </CardTxt>
            <CardTxt color="#767676">
              {data.customerPackageItem.packageItem.package.name}
            </CardTxt>
          </Box>
          <Box display={"flex"} gap={"5px"}>
            <CardTxt>Starting Price: </CardTxt>
            <CardTxt color="#7da70c">{formatPrice(data.offer_price)}</CardTxt>
          </Box>
        </Box>
        <SeeMoreBtn
          onClick={() => navigate(`${UserRoutes.Community.Offers}/${data.id}`)}
        >
          See details
        </SeeMoreBtn>
      </Box>
      <CategoryLabel>Sales Offer</CategoryLabel>
    </Wrapper>
  );
};

export default DashboardCommunityCard;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  width: 93%;
  height: 115px;
  padding: 11px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;

  ${mediaQueries.mobile} {
    height: auto;
  }
`;

const Image = styled.img`
  width: 80px;
  height: 89px;
  border-radius: 4px;
`;

const CardTxt = styled.p<{ color?: string }>`
  font-weight: 450;
  font-size: 12px;
  color: ${({ color }) => (color ? color : `#2a3313`)};
`;

const SeeMoreBtn = styled.div`
  font-weight: 500;
  font-size: 13px;
  color: #afd34f;
  width: max-content;
  cursor: pointer;
  transition: all 0.25s ease-out;

  &:hover {
    color: #8bbe00;
  }
`;

const CategoryLabel = styled.span`
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  font-weight: 450;
  font-size: 11px;
  color: #fbfbfb;
  height: 19px;
  background: #48b6e3;
  border-radius: 0px 8px;
`;

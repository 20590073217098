import { useQuery } from "@tanstack/react-query";
import api from "app/api";
import { parseError } from "app/utils";
import { ClientQueryKeys } from "./types";

const useGetAllClients = (page: number, perPage: number = 9) => {
  return useQuery(
    [ClientQueryKeys.getAllClients, page],
    () => api.clientService.getAllClients(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetAllClients;

import walletIcon from "../../../images/web/wallet.svg";
import privacyIcon from "../../../images/web/privacy.svg";
import moneyIcon from "../../../images/web/money-bag.svg";
import ayo from "../../../images/web/ayo.png";
import olayemi from "../../../images/web/olayemi.png";
import quinette from "../../../images/web/quinette.png";
import stephen from "../../../images/web/stephen.png";

export const cardData = [
  {
    title: "Property selection guide",
    description:
      "Knowing where to start can be a real challenge. But we're here to help. We provide a wide selection of properties at the fairest prices tailored to your land, agriculture & real-estate needs.",
    icon: walletIcon,
  },
  {
    title: "Seamless documentation & property security",
    description:
      "Ensuring proper legal registration, certification and documentation of properties is our priority.  We also secure properties against unlawful acts such as squatters, tree cutting, and trespassing.",
    icon: privacyIcon,
  },
  {
    title: "Wealth creation & Financial security",
    description:
      "Creating wealth is vital in eradicating poverty. We assist in improving lifestyles by engaging your properties with agricultural activities, thereby earning an income.",
    icon: moneyIcon,
  },
];

export const testimonialData = [
  {
    testimonialText:
      "So far, I like what I've seen.  I love that Adbond assures me of the returns on investment attached to the agricultural activities currently going on, on my land. And If the opportunity arises, I will not hesitate to bring more customers to partner with Adbond",
    avatar: olayemi,
    avatarName: "Olayemi",
  },
  {
    testimonialText:
      "Adbond has exceeded my expectations, and I advise everyone to invest in the real estate services Adbond offers. Adbond doesn’t disappoint as the team provides the necessary documentation and enforces security measures on all properties.",
    avatar: stephen,
    avatarName: "Stephen",
  },
  {
    testimonialText:
      "Adbond Harvest and Homes Limited is indeed a trusted company. I have seen and am very convinced about their “agro-to-home” services. Visiting the sites I’m currently investing in, shows that Adbond team are expert, and they should keep up the excellent work.",
    avatar: quinette,
    avatarName: "Quinette",
  },
  {
    testimonialText:
      "Adbond’s “agro-to-home” is the future, and if you love to invest in land, farming, and real estate, Adbond is home to you. I’d love you to join me, as I enjoy partnering with the team. So, come on board.",
    avatar: ayo,
    avatarName: "Ayo Joseph",
  },
];

export const homeFAQData = [
  {
    title: "Who are we?",
    renderContent: () => (
      <>
        Adbond is the first and leading “agro-to-home” development company for
        both agricultural and home purposes.
      </>
    ),
  },
  {
    title: "Is Adbond registered?",
    renderContent: () => (
      <>
        Yes, Adbond is a registered company. Our registration number is RC:
        1368601.
      </>
    ),
  },
  {
    title: "Who can buy our products?",
    renderContent: () => (
      <>
        Anyone can get our products - Individuals, Undergraduates, Corps
        members, Pensioners, Business associates, Co-operative societies and
        lots more.
      </>
    ),
  },
  {
    title: "Where are Adbond lands located?",
    renderContent: () => (
      <>
        We currently have lands within the following local government areas:
        <br />
        <ul style={{ listStyle: "unset", listStylePosition: "inside" }}>
          <li>Obafemi-Owode LGA, Ogun State.</li>
          <li>Ogunmakin, Ajebo, Ogun State.</li>
          <li>Lagelu LGA, Ibadan, Oyo State.</li>
          <li>Majoda, Epe, Lagos State.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Are site tours free of charge?",
    renderContent: () => (
      <>
        Pre-registration for site tours requires a non-refundable deposit of
        N5,000 for vistors who do not purchase a property upon completion of the
        tour. However, vistors who become customers through property purchases
        receive a refund.
      </>
    ),
  },
];

export const mainFAQData = [
  {
    title: "Who are we?",
    renderContent: () => (
      <>
        Adbond is the first and leading “agro-to-home” development company for
        both agricultural and home purposes.
      </>
    ),
  },
  {
    title: "What’s Adbond’s office address?",
    renderContent: () => (
      <>
        Our head office is located at CENTAGE PLAZA. 14, Allen Avenue, Ikeja,
        Lagos. However, you can visit our experience center at Grand mall,
        Bodija, Ibadan.
      </>
    ),
  },
  {
    title: "Is Adbond registered?",
    renderContent: () => (
      <>
        Yes, Adbond is a registered company. Our registration number is RC:
        1368601.
      </>
    ),
  },
  {
    title: "Who can buy our products?",
    renderContent: () => (
      <>
        Anyone can get our products - Individuals, Undergraduates, Corps
        members, Pensioners, Business associates, Co-operative societies and
        lots more.
      </>
    ),
  },
  {
    title: "Where are Adbond lands located?",
    renderContent: () => (
      <>
        We currently have lands within the following local government areas:
        <br />
        <ul style={{ listStyle: "unset", listStylePosition: "inside" }}>
          <li>Obafemi-Owode LGA, Ogun State.</li>
          <li>Ogunmakin, Ajebo, Ogun State.</li>
          <li>Lagelu LGA, Ibadan, Oyo State.</li>
          <li>Majoda, Epe, Lagos State.</li>
        </ul>
      </>
    ),
  },
  {
    title: "Are site tours free of charge?",
    renderContent: () => (
      <>
        Pre-registration for site tours requires a non-refundable deposit of
        N5,000 for vistors who do not purchase a property upon completion of the
        tour. However, vistors who become customers through property purchases
        receive a refund.
      </>
    ),
  },
  {
    title: "What “agro-to-home” plans are available?",
    renderContent: () => (
      <>
        We currently offer different packages for land, agro and home. Contact
        our sales team via{" "}
        <a
          style={{ color: "#afd34f" }}
          href="mailTo: Sales@adbondharvestandhomes.com{"
        >
          Sales@adbondharvestandhomes.com{" "}
        </a>{" "}
        for more information on the packages available for different products.
      </>
    ),
  },
];

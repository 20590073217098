import auth from "./auth";
import project from "./project";
import utilities from "./utilities";
import client from "./client";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  authService: auth,
  projectService: project,
  utilityService: utilities,
  clientService: client,
};

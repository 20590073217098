import { useQuery } from "@tanstack/react-query";
import { downloadClientPackageDoa } from "app/api/client";
import { parseError } from "app/utils";
import { ClientQueryKeys } from "./types";
import FileDownload from "js-file-download";

const useDownloadClientPackageDoa = (clientPackageId: string) => {
  return useQuery(
    [ClientQueryKeys.downloadClientPackageBrochure, clientPackageId],
    () => downloadClientPackageDoa(clientPackageId),
    {
      enabled: false,
      onError: (error) => parseError(error),
      onSuccess: (data) => {
        FileDownload(data.data, `brochure.pdf`);
      },
    }
  );
};

export default useDownloadClientPackageDoa;

import * as React from "react";
import Drawer from "@mui/material/Drawer";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as ImgLogo } from "../../../../images/web/Adbond Logo 1.svg";
import CloseIcon from "@mui/icons-material/Close";
import NavLink from "./navLink";
import P from "../../../../../styles/elements/P";
import { useAppSelector } from "app/redux";
import useAuth from "app/hooks/auth/useAuth";
import { getDashboardUrl } from "app/redux/dashboard/dashboardSlice";
import DashboardIcon from "@mui/icons-material/Dashboard";

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TemporaryDrawer: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  // const location = useLocation();
  const isLoggedIn = useAuth();
  const dashboardUrl = useAppSelector(getDashboardUrl).dashboardUrl;
  // const isLegalPath = location.pathname.split("/").at(-2) === "legal";

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      onClose={() => setIsOpen((prev) => !prev)}
    >
      <DrawerBody>
        <DrawerHeader>
          <Link to="/" onClick={() => setIsOpen((prev) => !prev)}>
            <LogoContainer>
              <ImgLogo />
            </LogoContainer>
          </Link>
          <div onClick={() => setIsOpen((prev) => !prev)} className="close-btn">
            <CloseIcon />
          </div>
        </DrawerHeader>
        <LinksContainer>
          <div onClick={() => setIsOpen((prev) => !prev)}>
            <NavLink label="Our project" url="project" />
          </div>
          <div onClick={() => setIsOpen((prev) => !prev)}>
            <NavLink label="About us" url="about" />
          </div>
          <div onClick={() => setIsOpen((prev) => !prev)}>
            <NavLink label="Schedule a site tour" url="schedule-a-tour" />
          </div>
          <div onClick={() => setIsOpen((prev) => !prev)}>
            <NavLink label="Community" url="community" />
          </div>
        </LinksContainer>

        {isLoggedIn && dashboardUrl ? (
          <DashboardWrapper>
            <Link to={dashboardUrl}>
              <DashboardIcon />
              Dashboard
            </Link>
          </DashboardWrapper>
        ) : (
          <ButtonGroup>
            <Link to={"/signup"} onClick={() => setIsOpen((prev) => !prev)}>
              <SignButton>
                <P bodybutton>Sign up</P>
              </SignButton>
            </Link>
            <Link to={"/login"} onClick={() => setIsOpen((prev) => !prev)}>
              <P bodybutton className="btn">
                Login
              </P>
            </Link>
          </ButtonGroup>
        )}
      </DrawerBody>
    </Drawer>
  );
};

export default TemporaryDrawer;

const LogoContainer = styled.div`
  & > svg {
    width: 109px;
    height: 40px;
  }
`;

const DrawerBody = styled.div`
  width: 375px;
  height: 100%;
  background: #f4f9ec;
  padding: 40px 20px;

  @media (max-width: 374px) {
    width: 320px;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .close-btn {
    cursor: pointer;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 44px;
  font-weight: 450;
  font-size: 1.25rem;
  color: #1b1b1b;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.625rem;
  margin-top: 2.75rem;

  & > button,
  .btn {
    cursor: pointer;
  }
  & > button {
    color: white;
  }
  .btn {
    color: #1b1b1b;
  }
`;

const SignButton = styled.button`
  padding: 0.625rem 4rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: #afd34f;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: black;
  margin-top: 2.75rem;

  svg {
    color: #afd34f;
  }

  a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

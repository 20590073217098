import React from "react";
import useApprovedInspections from "app/hooks/site tour/useApprovedInspections";
import Spinner from "app/views/Web/components/Spinner";
import TourCard from "../TourCard";
import { NoProjectText, SpinnerWrapper } from "./styles";

const DisplaySiteTours = () => {
  const {
    data: siteTourData,
    isLoading: tourLoading,
    isError: tourIsError,
  } = useApprovedInspections();

  if (tourLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!tourLoading && tourIsError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (siteTourData && siteTourData.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>You have no tour yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {siteTourData &&
        siteTourData.map((x) => (
          <TourCard key={x.id} data={x} isCustom={x.custom} />
        ))}
    </>
  );
};

export default DisplaySiteTours;

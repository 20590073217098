import { UserClientsObj } from "app/types/common";
import { FC } from "react";
import styled from "styled-components";

interface props {
  data: UserClientsObj;
}

const ListEntryCard: FC<props> = ({ data }) => {
  const packages = data.packages?.map((x) => x.packageItem.package.name) || [];
  const isEmpty = packages.length === 0;
  const countLeft = !isEmpty && packages.length - 1;
  return (
    <Wrapper>
      <Name>{`${data.firstname} ${data.lastname}`}</Name>
      <Email>{data.email}</Email>
      <PackageWrapper>
        <Package>{isEmpty ? "None" : packages[0]}</Package>
        {!!countLeft && <Count>{`+${countLeft}`}</Count>}
      </PackageWrapper>
    </Wrapper>
  );
};

export default ListEntryCard;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 12px;
  width: 100%;
  height: 39px;
  background: #ffffff;
  border-radius: 8px;
`;

const Name = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #809a39;
`;

const Email = styled(Name)`
  color: #28647d;
`;

const PackageWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const Package = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 8px;
  height: 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #fbfbfb;
  background: #97b644;
  border-radius: 10px;
`;

const Count = styled.div`
  position: absolute;
  top: -10px;
  right: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: 450;
  font-size: 10px;
  color: #2a3313;
  background: #d8e8b8;
  border-radius: 50%;
`;

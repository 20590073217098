import styled from "styled-components";
import { ReactComponent as FacebookIcon } from "../../../images/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../images/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../images/instagram.svg";
import { ContactUs } from "./style";
import P from "../../../../styles/elements/P";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { ClickAwayListener } from "@mui/base";

const ContactBox = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Container isOpen={isOpen}>
        <ContactUs onClick={() => setIsOpen((prev) => !prev)}>
          <P bodybutton>Contact us</P>
          <IconContainer isOpen={isOpen}>
            <ArrowDropUpIcon />
          </IconContainer>
        </ContactUs>
        <ContentContainer>
          <LeftContainer>
            <a
              href="https://www.facebook.com/adbondharvest?mibextid=ZbWKwL"
              target={"_blank"}
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>

            <a
              href="https://twitter.com/adbondharvest?t=n3JxEmUlOkwXgT6TwaV1Cg&s=08"
              target={"_blank"}
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://instagram.com/adbondharvestandhomes?igshid=ZDdkNTZiNTM="
              target={"_blank"}
              rel="noreferrer"
            >
              <InstagramIcon />
            </a>

            <a
              href="https://youtube.com/@adbondharvest1176"
              target={"_blank"}
              rel="noreferrer"
            >
              <YoutubeIcon />
            </a>
          </LeftContainer>
          <RightContainer>
            <ItemsContainer>
              <span>
                <LocationOnIcon />
              </span>
              <span>
                3rd & 4th Floor, Centage Plaza, 14 Allen Ave, Allen, Ikeja
              </span>
            </ItemsContainer>
            <ItemsContainer>
              <span>
                <EmailIcon />
              </span>
              <span>Inquiry@adbondharvestandhomes.com</span>
            </ItemsContainer>
            <ItemsContainer>
              <span>
                <PhoneInTalkIcon />
              </span>
              <span>(+234) 904 357 3621</span>
            </ItemsContainer>
            <ItemsContainer>
              <span>
                <WhatsAppIcon />
              </span>
              <span>(+234) 912 397 8725</span>
            </ItemsContainer>
          </RightContainer>
        </ContentContainer>
      </Container>
    </ClickAwayListener>
  );
};

export default ContactBox;

interface ContainerProps {
  isOpen: boolean;
}

const IconContainer = styled.div<ContainerProps>`
  transform: ${(props) => props.isOpen && "rotate(180deg)"};
  transition: transform 0.5s;
`;

const Container = styled.div<ContainerProps>`
  max-width: 85%;
  position: fixed;
  top: 250px;
  right: 0;
  transform: ${(props) => !props.isOpen && "translateX(100%)"};
  border-radius: 0px 0px 0px 20px;
  transition: transform 0.5s;
  z-index: 999;
`;

const ContentContainer = styled.div`
  background: #ffffff;
  border-radius: 0px 0px 0px 20px;
  display: flex;
  /* height: 350px; */
  height: 300px;
  row-gap: 28px;
`;

const LeftContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 49px 17px;
  justify-content: space-between;
  border-radius: 0px 0px 0px 20px;
`;

const RightContainer = styled.div`
  background: #cde6f5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 49px 26px;
  padding-right: 10vw;
`;

const ItemsContainer = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 450;
  font-size: 1rem;
  text-align: center;
  color: #1b1b1b;

  & > span:nth-child(2) {
    word-break: break-word;
    @media (max-width: 370px) {
      font-size: 0.875rem;
    }
  }
`;

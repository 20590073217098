import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteReplyComment } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useDeleteReplyComment = (replyId: number) => {
  const queryClient = useQueryClient();

  return useMutation(deleteReplyComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.getPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                comments: [
                  ...oldQueryData.data.data.comments.map((comment: any) => {
                    const temp =
                      comment.id === successData.data.data.id
                        ? {
                            ...comment,
                            replies: comment.replies.filter(
                              (reply: any) => reply.id !== replyId
                            ),
                          }
                        : { ...comment };
                    return temp;
                  }),
                ],
              },
            },
          };
        }
      );
    },
  });
};

export default useDeleteReplyComment;

import { useQuery } from "@tanstack/react-query";
import { approvedInspections } from "app/api/site tour";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const useApprovedInspections = () => {
  return useQuery([siteTourKeys.approvedInspections], approvedInspections, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useApprovedInspections;

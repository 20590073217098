import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DashboardTotalCard from "../../components/Cards/dashboardTotal";
import IncomeCard from "../../components/Cards/incomeCard";
import { ReactComponent as ProjectIcon } from "../../../../images/admin/total-projects.svg";
import { ReactComponent as ActiveProjectIcon } from "../../../../images/admin/active-projects.svg";
import { ReactComponent as TotalClientIcon } from "../../../../images/admin/total-clients.svg";
import PackageSales from "./PackageSales";
import { ReactComponent as DarkBlueBar } from "../../../../images/admin/dark-blue-bar.svg";
import { ReactComponent as LightBlueBar } from "../../../../images/admin/light-blue-bar.svg";
import { ReactComponent as GreenBar } from "../../../../images/admin/green-bar.svg";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import useAdminSalesLands, {
  useAdminSalesAgro,
  useAdminSalesHomes,
} from "app/hooks/sales/useSales";
import { SalesRes } from "app/api/sales/types";
import { salesMonthOptions, yearDropDownOptions } from "../data";
import { abbreviateNumber } from "app/utils";
import useGetProjectCount from "app/hooks/projects/useGetProjectCount";
import useGetTotalActiveProject from "app/hooks/utilities/useGetTotalActiveProject";
import useGetTotalClients from "app/hooks/utilities/useGetTotalClients";
import AgeGroup from "./ageGroup";

const DisplayProject = () => {
  const [landYear, setLandYear] = useState<number>(
    yearDropDownOptions[0].value
  );
  const [landMonth, setLandMonth] = useState<number>(
    salesMonthOptions[0].value
  );

  const [homeYear, setHomeYear] = useState<number>(
    yearDropDownOptions[0].value
  );
  const [homeMonth, setHomeMonth] = useState<number>(
    salesMonthOptions[0].value
  );

  const [agroYear, setAgroYear] = useState<number>(
    yearDropDownOptions[0].value
  );
  const [agroMonth, setAgroMonth] = useState<number>(
    salesMonthOptions[0].value
  );

  const [landData, setLandData] = useState<SalesRes>();
  const [homeData, setHomeData] = useState<SalesRes>();
  const [agroData, setAgroData] = useState<SalesRes>();

  const { data: projectCount, isLoading: projectCountLoading } =
    useGetProjectCount();

  const { data: activeCount, isLoading: activeCountLoading } =
    useGetTotalActiveProject();

  const { data: clientCount, isLoading: clientCountLoading } =
    useGetTotalClients();
  const { mutate: landMutate, isIdle: landLoading } = useAdminSalesLands();
  const { mutate: homeMutate, isIdle: homeLoading } = useAdminSalesHomes();
  const { mutate: agroMutate, isIdle: agroLoading } = useAdminSalesAgro();

  const handleLandYear = (value: number) => {
    setLandYear(value);
  };

  const handleLandMonth = (value: number) => {
    setLandMonth(value);
  };

  const handleHomeYear = (value: number) => {
    setHomeYear(value);
  };
  const handleHomeMonth = (value: number) => {
    setHomeMonth(value);
  };

  const handleAgroYear = (value: number) => {
    setAgroYear(value);
  };
  const handleAgroMonth = (value: number) => {
    setAgroMonth(value);
  };

  useEffect(() => {
    if (landYear && landMonth) {
      const payload = {
        start: Number(landMonth),
        year: Number(landYear),
      };
      landMutate(payload, {
        onSuccess: (data) => setLandData(data.data.data),
      });
    }
  }, [landYear, landMonth, landMutate]);

  useEffect(() => {
    if (homeYear && homeMonth) {
      const payload = {
        start: Number(homeMonth),
        year: Number(homeYear),
      };
      homeMutate(payload, {
        onSuccess: (data) => setHomeData(data.data.data),
      });
    }
  }, [homeYear, homeMonth, homeMutate]);

  useEffect(() => {
    if (agroYear && agroMonth) {
      const payload = {
        start: Number(agroMonth),
        year: Number(agroYear),
      };
      agroMutate(payload, {
        onSuccess: (data) => setAgroData(data.data.data),
      });
    }
  }, [agroYear, agroMonth, agroMutate]);

  return (
    <>
      <Box display={"flex"} gap="24px">
        <DashboardTotalCard
          label={"Total Projects"}
          icon={ProjectIcon}
          value={projectCount || 0}
          isLoading={projectCountLoading}
          removeLink
          applyShadow
          maxWidth="unset"
          width="unset"
          flexGrow={1}
          height={"145px"}
        />
        <DashboardTotalCard
          label={"Active Projects"}
          icon={ActiveProjectIcon}
          value={activeCount || 0}
          isLoading={activeCountLoading}
          removeLink
          applyShadow
          maxWidth="unset"
          width="unset"
          flexGrow={1}
          height={"145px"}
        />
        <DashboardTotalCard
          label={"Total Existing Clients"}
          icon={TotalClientIcon}
          value={clientCount || 0}
          isLoading={clientCountLoading}
          removeLink
          applyShadow
          maxWidth="unset"
          width="unset"
          flexGrow={1}
          height={"145px"}
        />
      </Box>
      <IncomeContainer>
        <IncomeCard
          label={"Land Sales Income"}
          value={
            landData?.amount ? abbreviateNumber(landData.amount * 1000000) : "0"
          }
          count={landData?.total || 0}
          icon={DarkBlueBar}
          applyShadow
          minWidth="338px"
          height="140px"
          handleYearChange={handleLandYear}
          handleMonthChange={handleLandMonth}
          monthOptions={salesMonthOptions}
          yearOptions={yearDropDownOptions}
          isLoading={landLoading}
          subLabel="sold lands"
        />
        <IncomeCard
          label={"Agro Sales Income"}
          value={
            agroData?.amount ? abbreviateNumber(agroData.amount * 1000000) : "0"
          }
          count={agroData?.total || 0}
          icon={GreenBar}
          applyShadow
          minWidth="338px"
          height="140px"
          handleYearChange={handleAgroYear}
          handleMonthChange={handleAgroMonth}
          monthOptions={salesMonthOptions}
          yearOptions={yearDropDownOptions}
          isLoading={agroLoading}
          subLabel="cultivated lands"
        />
        <IncomeCard
          label={"Home Sales Income"}
          value={
            homeData?.amount ? abbreviateNumber(homeData.amount * 1000000) : "0"
          }
          count={homeData?.total || 0}
          icon={LightBlueBar}
          applyShadow
          minWidth="338px"
          height="140px"
          handleYearChange={handleHomeYear}
          handleMonthChange={handleHomeMonth}
          monthOptions={salesMonthOptions}
          yearOptions={yearDropDownOptions}
          isLoading={homeLoading}
          subLabel="sold homes"
        />
      </IncomeContainer>
      <Box display={"flex"} gap={"21px"}>
        <AgeGroup applyShadow />
        <PackageSales />
      </Box>
    </>
  );
};

export default DisplayProject;

const IncomeContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin: 24px 0;
  ${mediaObj.mediumDesktop} {
    flex-wrap: wrap;
  }
`;

import React, { useRef, useState } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import DualRing from "app/views/Web/components/loader";
import NameRenderer from "./NameRenderer";
import StaffTypeRenderer from "./StaffTypeRenderer";
import { StaffCommissionRes } from "app/api/analytics/types";
import CommissionDetails from "../modals/CommissionDetails";
import CommissionRenderer from "./CommissionRenderer";

interface GridProps {
  data: StaffCommissionRes[];
  isLoading?: boolean;
}

type StaffField = keyof StaffCommissionRes | undefined | "action";

export interface GridDef extends ColDef {
  field: StaffField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: StaffCommissionRes[];
}

const StaffCommission: React.FC<GridProps> = ({ data, isLoading }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: StaffCommissionRes[] = data;

  const [isDetailsOpen, setIsDetailsOpen] = useState<StaffCommissionRes>();

  const handleOpenDetails = (data: StaffCommissionRes) =>
    setIsDetailsOpen(data);

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Staff Name",
      field: "firstname",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 1,
      cellRendererFramework: NameRenderer,
      wrapText: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
        color: "#28647D",
      },
      wrapText: true,
    },
    {
      headerName: "Phone Number",
      sortable: false,
      field: "phone_number",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
    },

    {
      headerName: "Staff Type",
      sortable: false,
      field: "staff_type",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StaffTypeRenderer,
    },
    {
      sortable: false,
      field: "commission",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: StaffCommissionRes } = params;
        return `₦ ${Number(data.commission).toLocaleString("en-US")}`;
      },
      wrapText: true,
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: CommissionRenderer,
      cellRendererParams: {
        handleOpenDetailsModal: handleOpenDetails,
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <>
      {isLoading ? (
        <MainLoaderWrapper height="545px">
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : GridData?.length > 0 ? (
        <>
          <GridWrapper
            id="OpenOrdersGridWrapper"
            className={`ag-theme-alphine`}
          >
            <AgGridReact
              rowClass={rowClass}
              getRowClass={getRowClass}
              onGridReady={gridRendered}
              animateRows
              headerHeight={50}
              rowHeight={50}
              columnDefs={gridConfig.columnDefs}
              rowData={gridConfig.rowData}
              suppressRowTransform
              defaultColDef={{
                sortable: true,
                filter: false,
                cellStyle: {
                  ...gridCellStyle,
                },
              }}
              immutableData
              getRowNodeId={(data: StaffCommissionRes) => {
                return data.id.toString();
              }}
              gridOptions={{
                onSortChanged(e: AgGridEvent) {
                  e.api.refreshCells();
                },
                onFilterChanged(e: AgGridEvent) {
                  e.api.refreshCells();
                },
              }}
            />
          </GridWrapper>

          {isDetailsOpen && (
            <CommissionDetails
              open={!!isDetailsOpen}
              data={isDetailsOpen}
              handleClose={() => setIsDetailsOpen(undefined)}
            />
          )}
        </>
      ) : (
        <EmptyWrapper>
          <p>No commission has been assigned to any staff.</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default StaffCommission;

interface loaderProps {
  height?: string;
}

export const MainLoaderWrapper = styled.div<loaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "1rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 350px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #eaf4fb;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    margin-bottom: 0.5rem;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  & *:not(img) {
    overflow: visible !important;
  }
`;

import dayjs from "dayjs";
import * as Yup from "yup";

const yesterday = new Date(Date.now() - 86400000);
const today = new Date();

export const OneTimeValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  date: Yup.date()
    .required("Required")
    .nullable()
    .min(yesterday, "Date cannot be in the past"),
  time: Yup.string()
    .required("Required")
    .nullable()
    .test("time", "Time can't be in the past", function (value) {
      const { date } = this.parent;
      if (
        dayjs(date).format("ddd MMM DD YYYY") ===
        dayjs(today).format("ddd MMM DD YYYY")
      ) {
        return dayjs(value).isAfter(dayjs(today));
      } else {
        return true;
      }
    }),
});

export const RepeatitiveValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  time: Yup.string().required("Required").nullable(),
  day: Yup.object().required("This field is required"),
  reoccurance: Yup.object().required("This field is required"),
});

export const NewClientValidation = Yup.object({
  newClientArray: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().required("Required"),
      phone: Yup.string().required("Required"),
    })
  ),
  selectedClient: Yup.array().required("Required"),
});

export const customSiteTourValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  packages: Yup.object().required("This field is required"),
  date: Yup.date()
    .required("Required")
    .nullable()
    .min(yesterday, "Date cannot be in the past"),
  time: Yup.string()
    .required("Required")
    .nullable()
    .test("time", "Time can't be in the past", function (value) {
      const { date } = this.parent;
      if (
        dayjs(date).format("ddd MMM DD YYYY") ===
        dayjs(today).format("ddd MMM DD YYYY")
      ) {
        return dayjs(value).isAfter(dayjs(today));
      } else {
        return true;
      }
    }),
});

export const DialogModalValidation = Yup.object({
  reasonText: Yup.string().required("Required"),
});

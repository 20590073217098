import useGetLatestPackage from "app/hooks/projects/useGetLatestPackage";
import { truncateTxt } from "app/utils";
import { UserRoutes } from "app/utils/data";
import Spinner from "app/views/Web/components/Spinner";
import { useNavigate } from "react-router";
import styled from "styled-components";
import PropertyCard from "../../components/Cards/property";
import Slider from "react-slick";
import { cubicSliderConfig } from "../../components/sliderConfig";
import { Box } from "@mui/material";
import { mediaObj } from "styles/utils/media";

export const Listing = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetLatestPackage();

  return (
    <Wrapper>
      <Title>New Listing</Title>
      {isLoading && !isError && (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {!isLoading && isError && (
        <SpinnerWrapper>
          <NoProjectText>
            An error occurred. Please try again later
          </NoProjectText>
        </SpinnerWrapper>
      )}

      {!isLoading && !isError && data?.length === 0 && (
        <SpinnerWrapper>
          <NoProjectText>We have no new listing yet!</NoProjectText>
        </SpinnerWrapper>
      )}

      {!isLoading && !isError && data && (
        <SliderWrapper>
          <Slider {...cubicSliderConfig}>
            {data.map((ev) => (
              <PropertyCard
                key={ev.id}
                categoryName={ev.category.name}
                packageName={ev.name}
                propertyName={ev.project_location.project}
                locationAddress={truncateTxt(
                  ev.project_location?.location +
                    " " +
                    (ev?.project_location?.address || ""),
                  50
                )}
                packagePrice={ev.items[0]?.discounted_price}
                mainFile={ev.main_file}
                onCardClick={() => navigate(`${UserRoutes.Projects}/${ev.id}`)}
                isSoldOut={ev.sold_out}
              />
            ))}
          </Slider>
        </SliderWrapper>
      )}
    </Wrapper>
  );
};

export const SliderWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 40px;

  .slick-list {
    padding: 5px 0;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .dots {
    display: flex !important;
    width: 100%;
    justify-content: center;
    gap: 5px;
    margin-top: 20px;

    & li {
      width: 12px;
      height: 7px;
      border-radius: 8px;
      background: rgba(158, 158, 158, 0.56);
      transition: all 0.3s ease-in-out;
      will-change: width;

      & > button {
        display: none !important;
      }
    }

    & .slick-active {
      width: 24px;
      background: #afd34f;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 397px;
  background-color: white;
  padding: 16px 23px;
  overflow: hidden;

  ${mediaObj.smallDesktop} {
    padding: 15px;
  }

  ${mediaObj.mobile} {
    background-color: transparent;
    padding: 0;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #143340;
  margin-bottom: 16px;

  ${mediaObj.mobile} {
    margin-bottom: 10px;
  }
`;

const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

import { Box } from "@mui/material";
import { assessmentObj } from "app/api/staff/types";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  setAssessment,
} from "app/redux/assessment/assessmentSlice";
import { Dispatch, FC, SetStateAction } from "react";
import CustomInput from "../../components/customInput";
import CustomTextArea from "../../components/customTextArea";
import { Btn } from "../createAssessment";

interface props {
  handleChange: (value: string, name: string) => void;
  setStep: Dispatch<SetStateAction<number>>;
  formData?: assessmentObj;
}

const CreateSubject: FC<props> = ({ handleChange, setStep, formData }) => {
  const dispatch = useAppDispatch();
  const { assessment } = useAppSelector(assessmentSelector);

  const isValid = !!formData?.title && !!formData?.description;

  const handleSubmit = () => {
    dispatch(
      setAssessment(
        !!assessment
          ? {
              ...assessment,
              title: formData?.title,
              description: formData?.description,
            }
          : {
              title: formData?.title,
              description: formData?.description,
            }
      )
    );
    setStep(2);
  };

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"40px"}
        mt={"50px"}
        mb={"100px"}
      >
        <Box width={"475px"}>
          <CustomInput
            label="Assessment Title"
            name="title"
            value={formData?.title || ""}
            handleChange={handleChange}
          />
        </Box>
        <Box width={"650px"}>
          <CustomTextArea
            label="Assessment Description"
            name="description"
            value={formData?.description || ""}
            handleChange={handleChange}
          />
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Btn onClick={handleSubmit} disabled={!isValid}>
          Next
        </Btn>
      </Box>
    </>
  );
};

export default CreateSubject;

import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { TourModalsRoute } from "../..";
import OneTimeSchedule from "../sections/OneTimeSchedule";
import RepeatitiveSchedule from "../sections/RepeatitiveSchedule";
import ModalWrapper from "./ModalWrapper";

const NewSiteTour = () => {
  const [searchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const [activeNav, setActiveNav] = useState(1);
  const editTitle = searchParams.get("edit");
  return (
    <ModalWrapper
      open={createType === TourModalsRoute.newSiteTour}
      title={`${editTitle ? "Update site tour" : "Schedule a new site tour"}`}
    >
      <>
        <NavWrapper>
          <Nav isActive={activeNav === 1} onClick={() => setActiveNav(1)}>
            One-time Schedule
          </Nav>
          <Nav isActive={activeNav === 2} onClick={() => setActiveNav(2)}>
            Repeatitive Schedule
          </Nav>
        </NavWrapper>
        {activeNav === 1 ? <OneTimeSchedule /> : <RepeatitiveSchedule />}
      </>
    </ModalWrapper>
  );
};

export default NewSiteTour;

const NavWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
`;

interface NavProps {
  isActive?: boolean;
}

const Nav = styled.div<NavProps>`
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  border-bottom: ${({ isActive }) => isActive && "3px solid #AFD34F"};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

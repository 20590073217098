import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useState } from "react";
import DropDown from "../components/dropDown";
import { TourNavData } from "./data";
import { useSearchParams } from "react-router-dom";
import NewSiteTour from "./components/modals/NewSiteTour";
import CustomSiteTour from "./components/modals/CustomSiteTour";
import DisplaySiteTours from "./components/sections/DisplaySiteTours";
import {
  CustomTourResponse,
  getSiteToursResponse,
} from "app/api/site tour/types";
import DisplayCustomTours from "./components/sections/DisplayCustomTours";
import DisplayToursDetails from "./components/sections/DisplayToursDetails";

export enum TourModalsRoute {
  newSiteTour = "new-site-tour",
  customSiteTour = "custom-site-tour",
}

interface MonthProps {
  value: number;
  label: string;
}

const SiteTour = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTour, setSelectedTour] = useState<
    getSiteToursResponse | CustomTourResponse
  >();
  const [monthValue, setMonthValue] = useState<MonthProps[]>();
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const activeParam = searchParams.get("activeTab");

  const handleMonthChange = (value: any) => {
    setSelectedMonth(value);
    onCloseDetails();
  };

  const handleTabChange = (id: number) => {
    setSelectedTab(id);
  };

  const onCloseDetails = useCallback(() => {
    searchParams.delete("selected");
    setSearchParams(searchParams);
    setSelectedTour(undefined);
  }, [searchParams, setSearchParams]);

  const handleTourSelect = useCallback(
    (data: any, isSelected: boolean) => {
      if (selectedTour && isSelected) {
        onCloseDetails();
      } else {
        searchParams.set("selected", data.id);
        setSearchParams(searchParams);
        setSelectedTour(data);
      }
    },
    [onCloseDetails, searchParams, selectedTour, setSearchParams]
  );

  useEffect(() => {
    !activeParam && setSearchParams({ activeTab: TourNavData[0].name });
  }, [setSearchParams, activeParam]);

  useEffect(() => {
    if (activeParam) {
      const result = TourNavData.filter((ev) => ev.name === activeParam);
      setSelectedTab(result[0].id);
    }
  }, [activeParam]);

  useEffect(() => {
    const temp = [];
    const minDate = new Date();

    const addMonths = (date: any, months: any) => {
      var result = new Date(date),
        expectedMonth = (((date.getMonth() + months) % 12) + 12) % 12;
      result.setMonth(result.getMonth() + months);
      if (result.getMonth() !== expectedMonth) {
        result.setDate(0);
      }
      return result;
    };
    for (let index = 0; index < 3; index++) {
      const element = addMonths(minDate, index);
      temp.push({
        value: +new Date(element).toLocaleString("en-us", {
          month: "2-digit",
        }),
        label: new Date(element).toLocaleString("en-us", {
          month: "long",
        }),
      });
    }
    setMonthValue(temp);
    setSelectedMonth(temp[0].value);
  }, []);

  return (
    <Container>
      <Box mr={"20px"} flexGrow={"1"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          width={"100%"}
        >
          <Header>Manage Site tours</Header>
          <Box display={"flex"} alignItems={"center"} gap={"15px"}>
            <CustomBtn
              onClick={() => {
                searchParams.set("create", TourModalsRoute.customSiteTour);
                setSearchParams(searchParams);
              }}
            >
              <AddIcon />
              Custom Site Tour
            </CustomBtn>
            <CreateBtn
              onClick={() => {
                searchParams.set("create", TourModalsRoute.newSiteTour);
                setSearchParams(searchParams);
              }}
            >
              <AddIcon />
              Schedule a tour
            </CreateBtn>
          </Box>
        </Box>
        <DropDownWrapper>
          {monthValue && (
            <DropDown
              dropDownOptions={monthValue}
              handleChange={handleMonthChange}
              bgColor={"#49B7E4"}
            />
          )}
        </DropDownWrapper>
        <Box display={"flex"} alignItems={"center"} gap={"24px"} mt={"20px"}>
          {TourNavData.map((x, idx) => (
            <Tab
              key={idx}
              onClick={() => {
                onCloseDetails();
                handleTabChange(x.id);
                setSearchParams({ activeTab: x.name });
              }}
              isActive={x.id === selectedTab}
            >
              {x.name}
            </Tab>
          ))}
        </Box>
        <TourWrapper>
          {selectedTab === 1 && selectedMonth && (
            <DisplaySiteTours
              handleTourSelect={handleTourSelect}
              selectedTourId={selectedTour?.id}
              selectedMonth={selectedMonth}
            />
          )}
          {selectedTab === 2 && selectedMonth && (
            <DisplayCustomTours
              handleTourSelect={handleTourSelect}
              selectedTourId={selectedTour?.id}
              selectedMonth={selectedMonth}
              onCloseDetails={onCloseDetails}
            />
          )}
        </TourWrapper>
      </Box>
      <DisplayToursDetails
        dataId={selectedTour?.id}
        clearSelectedTour={onCloseDetails}
        selectedTab={selectedTab}
        selectedMonth={selectedMonth}
      />

      {createType === TourModalsRoute.newSiteTour && <NewSiteTour />}
      {createType === TourModalsRoute.customSiteTour && <CustomSiteTour />}
    </Container>
  );
};

export default SiteTour;

const Container = styled.div`
  display: flex;
  background: #ffffff;
  padding: 24px;
  min-height: 700px;
  height: 80vh;
  width: 100%;
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  color: #181d0b;
`;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 188px;
  height: 44px;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #697f2f;
  }
`;

const CustomBtn = styled(CreateBtn)`
  background: transparent;
  color: #afd34f;
  height: fit-content;

  &:hover {
    background: transparent;
    text-decoration: underline;
  }

  & > svg {
    color: #afd34f;
  }
`;

const DropDownWrapper = styled.div`
  margin-top: 20px;
  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 3px solid #afd34f;
    `}
`;

const TourWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 12px 2px;
  margin-top: 20px;
  max-height: 70%;
  overflow-y: scroll;
`;

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import DisplaySalesOffer from "./components/DisplaySalesOffer";
import MyBids from "./components/MyBids";
import MyProperties from "./components/myProperties";
import { propertiesTabData } from "./data";

const Properties = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeParam = searchParams.get("activeTab");
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabChange = (id: number) => {
    setSelectedTab(id);
  };

  useEffect(() => {
    !activeParam && setSearchParams({ activeTab: propertiesTabData[0].name });
  }, [setSearchParams, activeParam]);

  useEffect(() => {
    if (activeParam) {
      const result = propertiesTabData.filter((ev) => ev.name === activeParam);
      setSelectedTab(result[0].id);
    }
  }, [activeParam]);

  return (
    <Wrapper>
      <TabWrapper>
        {propertiesTabData.map((x, idx) => (
          <Tab
            key={idx}
            onClick={() => {
              handleTabChange(x.id);
              setSearchParams({ activeTab: x.name });
            }}
            isActive={x.id === selectedTab}
          >
            {x.name}
          </Tab>
        ))}
      </TabWrapper>
      {selectedTab === 1 && <MyProperties />}
      {selectedTab === 2 && <DisplaySalesOffer />}
      {selectedTab === 3 && <MyBids />}
    </Wrapper>
  );
};

export default Properties;

const Wrapper = styled.div`
  padding: 36px 28px;
  background: #ffffff;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    padding: 0.5rem;
    background: transparent;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#181d0b")};
  font-size: 1.15rem;
  line-height: 140%;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 3px solid #afd34f;
    `}

  ${mediaQueries.mobile} {
    font-size: 16px;
  }
`;

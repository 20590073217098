import { Skeleton } from "@mui/material";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import { slideIn, staggerContainer } from "app/utils/animationVariants";
import CursorGradient from "app/utils/cursorGradient";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "../../../../styles/utils/mediaQueries";
import api from "../../../api";
import { categoryResponse } from "../../../api/project/types";
import ProjectCard from "../components/cards/projectCard";

const Project = () => {
  const [categories, setCategories] = useState<categoryResponse[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMobileCheck();

  useEffect(() => {
    async function getCategories() {
      setIsLoading(true);
      try {
        const res = await api.projectService.getAllCategories();
        setCategories(res.data.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }

    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <TextWrapper initial={"initial"} animate={"animate"}>
        <Title variants={slideIn("left")}>
          Promoting wealth creation and securing the future space you desire,
          through “agro-to-home” products within Africa.
        </Title>
        {/* <Subtitle variants={slideIn("right")} custom={1}>
          Tristique lacus ut pellentesque nec nam. Quis dictum sit et mauris
          tristique dolor pellentesque id odio.
        </Subtitle> */}
      </TextWrapper>
      <CardsWrapper
        variants={staggerContainer}
        custom={0.5}
        animate={isLoading ? "initial" : "animate"}
        initial={"initial"}
      >
        {!isLoading ? (
          categories.map((x, i) => <ProjectCard key={i} data={x} />)
        ) : (
          <>
            <Skeleton
              variant="rectangular"
              width={isMobile ? `100%` : 240}
              height={isMobile ? 300 : 450}
            />
            <Skeleton
              variant="rectangular"
              width={isMobile ? `100%` : 240}
              height={isMobile ? 300 : 450}
            />
            <Skeleton
              variant="rectangular"
              width={isMobile ? `100%` : 240}
              height={isMobile ? 300 : 450}
            />
          </>
        )}
      </CardsWrapper>
      <CursorGradient />
    </Container>
  );
};

export default Project;

const Container = styled.div`
  position: relative;
  display: flex;
  gap: 30px;
  margin: 150px auto 70px;
  width: clamp(750px, 1200px, 1400px);

  ${mediaObj.smallDesktop} {
    flex-direction: column;
    width: 100%;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    margin: 0 auto 70px;
  }
`;

const TextWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 2;
`;

const Title = styled(motion.p)`
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  color: #143340;

  ${mediaObj.smallDesktop} {
    font-size: 30px;
  }

  ${mediaQueries.mobile} {
    font-size: 22px;
    font-weight: 500;
  }
`;

// const Subtitle = styled(motion.p)`
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 140%;
//   color: #5e5e5e;

//   ${mediaQueries.mobile} {
//     font-size: 18px;
//   }
// `;

const CardsWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  flex-shrink: 0;
  gap: 16px;
  width: 752px;
  z-index: 2;

  ${mediaObj.smallDesktop} {
    width: 100%;
    gap: 10px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

import React, { useState } from "react";
import Spinner from "app/views/Web/components/Spinner";
import {
  ErrorText,
  NoProjectText,
  PaginatedWrapper,
  SpinnerWrapper,
} from "./styles";
import Paginating from "../../components/Pagination";
import useAdminGetEvents from "app/hooks/community/useGetEvents";
import EventCard from "./cards/EventCard";

const DisplayEvents = () => {
  const [page, setPage] = useState(1);
  const { data: paginatedData, isLoading, isError } = useAdminGetEvents(page);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <ErrorText>An error occurred. Please try again later</ErrorText>
      </SpinnerWrapper>
    );

  if (paginatedData && paginatedData.data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no content for this yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {paginatedData &&
        paginatedData.data.map((x) => <EventCard key={x.id} data={x} />)}

      {paginatedData && paginatedData.total > 5 && (
        <PaginatedWrapper>
          <Paginating
            totalElements={paginatedData.total}
            size={paginatedData.per_page}
            pageCount={3}
            currentPage={page}
            onChange={onPageChange}
          />
        </PaginatedWrapper>
      )}
    </>
  );
};

export default DisplayEvents;

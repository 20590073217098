import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { packagesObj } from "app/api/project/types";
import { RootState } from "../index";

interface Props {
  packageDetails?: packagesObj;
}

const packageState: Props = {
  packageDetails: undefined,
};

const packageSlice = createSlice({
  name: "packages",
  initialState: packageState,
  reducers: {
    setPackageDetails: (state, action: PayloadAction<packagesObj>) => {
      state.packageDetails = action.payload;
    },
  },
});

export const { setPackageDetails } = packageSlice.actions;

export const packageSelector = (state: RootState) => state.packages;
export default packageSlice.reducer;

import { UserClientsObj } from "app/types/common";
import { FC } from "react";
import styled from "styled-components";
import ListEntryCard from "./listEntryCard";

interface props {
  clients: UserClientsObj[];
}

const ClientList: FC<props> = ({ clients }) => {
  return (
    <Wrapper>
      <Title>Clients List ({clients.length})</Title>
      <CardWrapper>
        {clients.length > 0 ? (
          clients.map((x) => <ListEntryCard key={x.id} data={x} />)
        ) : (
          <NoContentWrapper>No clients found</NoContentWrapper>
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default ClientList;

const Wrapper = styled.div`
  height: 301px;
  padding: 12px 19px 0;
  background: #fbfbfb;
  flex-grow: 1;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #2a3313;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 10px;
  max-height: calc(100% - 34px);
  padding: 5px 0;
  overflow-y: scroll;
`;

const NoContentWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #2a3313;
`;

import { Box } from "@mui/material";
import { getAllCategories, getAllProjectPerCategory } from "app/api/project";
import {
  categoryResponse,
  packagesObj,
  projectObj,
} from "app/api/project/types";
import { getTotalPromo, parseError } from "app/utils";
import Spinner from "app/views/Web/components/Spinner";
import { capitalize } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import ProjectCard from "../../components/Cards/projectCard";

const NewProjects = () => {
  const navigate = useNavigate();
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [categoryData, setCategoryData] = useState<categoryResponse[]>([]);
  const [projectData, setProjectData] = useState<projectObj[]>();
  const [packageData, setPackageData] = useState<packagesObj[]>();
  const [selectedProject, setSelectedProject] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState<number>();

  const fetchAllCategories = useCallback(async () => {
    try {
      setCategoryLoading(true);
      const res = await getAllCategories();
      setCategoryData(res.data.data);
      setSelectedCategory(res.data.data[0].id);
      setCategoryLoading(false);
    } catch (error) {
      parseError(error);
      setCategoryLoading(false);
    }
  }, []);

  const fetchAllProjectByCategory = useCallback(async () => {
    if (!selectedCategory) return;
    try {
      setLoading(true);
      const res = await getAllProjectPerCategory(selectedCategory);
      setProjectData(res.data.data);
      setSelectedProject(res.data.data[0].id);
      setLoading(false);
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  }, [selectedCategory]);

  const filterAllLocationByProject = useCallback(() => {
    if (!projectData || !projectData.length) return;
    const singleProject = projectData.filter(
      (item) => item.id === selectedProject
    )[0];
    const locations = singleProject.locations.map((data) => {
      const temp = {
        id: data.id,
        name: data.location,
      };
      return temp;
    });
    const packages = singleProject.locations.filter(
      (data) => data.id === locations[0].id
    )[0]?.packages;

    setPackageData(packages);
  }, [projectData, selectedProject]);

  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  useEffect(() => {
    fetchAllProjectByCategory();
  }, [fetchAllProjectByCategory]);

  useEffect(() => {
    filterAllLocationByProject();
  }, [filterAllLocationByProject]);

  return (
    <Container>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Title>New Projects</Title>
        <SeeAll onClick={() => navigate("/admin/projects")}>See all</SeeAll>
      </Box>
      {categoryLoading && (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      )}
      {!categoryData && !categoryLoading && (
        <SpinnerWrapper>
          <NoProjectText>Category Not Found</NoProjectText>
        </SpinnerWrapper>
      )}
      {categoryData && !categoryLoading && (
        <>
          <TabsWrapper>
            {categoryData.map((x, i) => (
              <Tab
                key={i}
                isActive={x.id === selectedCategory}
                onClick={() => setSelectedCategory(x.id)}
              >
                {capitalize(x.name)}
              </Tab>
            ))}
          </TabsWrapper>
          <CardWrapper>
            {loading && (
              <SpinnerWrapper>
                <Spinner color="#AFD34F" />
              </SpinnerWrapper>
            )}
            {!packageData && !loading && (
              <SpinnerWrapper>
                <NoProjectText>
                  {" "}
                  We have no package for this project
                </NoProjectText>
              </SpinnerWrapper>
            )}
            {!loading &&
              packageData &&
              packageData.length > 0 &&
              packageData.map((x, i) => (
                <ProjectCard
                  {...x}
                  id={x.id}
                  key={i}
                  packageName={x.name}
                  addClientField={false}
                  clientsNo={x.clients}
                  plots={""}
                  mainFile={x.main_file}
                  location={x.project_location.location}
                  address={x.project_location.address}
                  isSoldOut={x.sold_out}
                  isOnPromo={x.onPromo}
                  promoDiscount={getTotalPromo(x.promos)}
                />
              ))}
          </CardWrapper>
        </>
      )}
    </Container>
  );
};

export default NewProjects;

const Container = styled.div`
  width: 47%;
  height: 330px;
  padding: 20px 15px 15px;
  background: #fff;
  border-radius: 8px;

  ${mediaObj.mediumDesktop} {
    width: 49%;
  }
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const SeeAll = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 15px;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  height: 26px;
  font-weight: 450;
  font-size: 13px;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#535353")};
  background: ${({ isActive }) => (isActive ? "#AFD34F" : "transparent")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  padding: 5px 2px;
  margin-top: 16px;
  max-width: 100%;
  overflow-x: scroll;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:nth-child(1) {
    margin-top: 40px;
  }
`;

const NoProjectText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

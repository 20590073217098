import { css } from "styled-components";

export const AgGridStyles = css`
  .ag-root-wrapper {
    background-color: transparent !important;
    border: none !important;
    * {
      overflow-x: hidden;
    }
  }
  .ag-theme-balham .ag-header {
    background-color: transparent !important;
    border: none;
    .ag-header-cell-text {
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      color: #5e5e5e;
    }
    .ag-header-viewport {
      background: rgba(38, 44, 78, 0.5);
      border-radius: 5px;
      .ag-icon {
        display: block;
        color: #656473;
      }
    }
  }

  .ag-theme-balham .ag-header .ag-header-viewport {
    background: none !important;
  }
  /* .ag-center-cols-clipper {
    height: 600px !important;
  } */
  .ag-theme-balham .ag-ltr .ag-header-cell:after,
  .ag-theme-balham .ag-ltr .ag-header-group-cell:after {
    right: 0;
    display: none;
  }
  .ag-theme-balham .ag-row {
    border: none;
    background-color: transparent;
    height: 42px !important;
  }
  .ag-theme-balham .ag-cell {
    outline: none !important;
    border: none !important;
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #303030;
  }

  .ag-body-viewport.ag-layout-normal {
    overflow: scroll;
  }

  .ag-theme-balham .ag-menu {
    padding: 4px 2px;
  }

  .ag-theme-balham .ag-picker-field-wrapper {
    cursor: pointer;
    border-radius: 0;
    margin-bottom: 15px;
  }

  .ag-theme-balham .ag-ltr .ag-filter-apply-panel-button {
    padding: 1px 5px 3px;
    cursor: pointer;
  }

  .ag-theme-balham .ag-row-hover {
    background-color: #d5d8d820;
  }

  .ag-theme-balham .ag-ltr input[class^="ag-"][type="text"] {
    height: 24px;
  }

  .ag-theme-balham .ag-select:not(.ag-cell-editor) {
    margin-top: 5px;
  }
`;

export const rowsAreClickable = css`
  .ag-row {
    border: none !important;
    &:hover {
      background-color: #282658 !important;
    }
  }
`;

import React from "react";
import { projectObj } from "app/api/project/types";
import styled from "styled-components";

interface Props {
  data: projectObj;
  type?: "address" | "location";
}

const MultipleRenderer: React.FC<Props> = ({ data, type }) => {
  return (
    <Wrapper length={data.locations.length}>
      {type === "address" &&
        data.locations.map((ev) => <div key={ev.id}>{ev.address}</div>)}
      {type === "location" &&
        data.locations.map((ev) => <div key={ev.id}>{ev.location}</div>)}
    </Wrapper>
  );
};

export default MultipleRenderer;

interface WrapperProps {
  length: number;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 50px;
  height: max-content;

  & > div {
    display: flex;
    align-items: center;
    height: 29px;
    flex: ${({ length }) => length === 1 && 1};
  }
`;

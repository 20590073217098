import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  adminDeleteBankAccount,
  adminGetASingleBankAccount,
  adminGetBankAccounts,
  adminSaveBankAccount,
  adminToggleBankAccount,
  adminUpdateBankAccount,
} from "app/api/analytics";
import { parseError } from "app/utils";
import { AnalyticsQueryKeys } from "./types";

const useAdminGetBankAccounts = () => {
  return useQuery(
    [AnalyticsQueryKeys.getAllBankAccounts],
    adminGetBankAccounts,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useAdminGetBankAccounts;

export const useAdminGetASingleBankAccount = (bank_account_id: number) => {
  return useQuery(
    [AnalyticsQueryKeys.getASingleBankAccount],
    () => adminGetASingleBankAccount(bank_account_id),
    {
      enabled: !!bank_account_id,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAdminSaveBankAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(adminSaveBankAccount, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([AnalyticsQueryKeys.getAllBankAccounts]);
    },
  });
};

export const useAdminUpdateBankAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(adminUpdateBankAccount, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([AnalyticsQueryKeys.getAllBankAccounts]);
    },
  });
};

export const useAdminToggleBankAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(adminToggleBankAccount, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([AnalyticsQueryKeys.getAllBankAccounts]);
    },
  });
};

export const useAdminDeleteBankAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(adminDeleteBankAccount, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([AnalyticsQueryKeys.getAllBankAccounts]);
    },
  });
};

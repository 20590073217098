import * as Yup from "yup";

export const CreateProjectValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  project: Yup.string().required("Required"),
  revertedInterestRate: Yup.string().required("Required"),
  locationArray: Yup.array().of(
    Yup.object().shape({
      location: Yup.object().required("This field is required"),
      address: Yup.string().required("Required"),
    })
  ),
});

export const PackageInfoValidation = Yup.object({
  packageTitle: Yup.string().required("Required"),
  location: Yup.object().required("This field is required"),
  packageSummary: Yup.string().required("Required"),
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  packageBenefits: Yup.array()
    .min(1, "at least 1")
    .required("This field is required"),
});

export const pricingDetailsValidation = Yup.object({
  pricingArray: Yup.array().of(
    Yup.object().shape({
      squaremetre: Yup.number()
        .positive()
        .required("Required")
        .min(1)
        .label("squaremetre"),
      fullPrice: Yup.number()
        .positive()
        .required("Required")
        .min(1)
        .label("full price"),
      duration: Yup.object().required("This field is required"),
      // projectFee: Yup.number()
      //   .positive()
      //   .required("Required")
      //   .min(1)
      //   .label("project fee"),
      discount: Yup.number()
        .positive()
        .required("Required")
        .min(1)
        .label("discount"),
      minPrice: Yup.number()
        .positive()
        .required("Required")
        .min(1)
        .label("minimum price"),
      availablePlots: Yup.number()
        .positive()
        .required("Required")
        .min(1)
        .label("available plots"),
    })
  ),
  mainFileId: Yup.string().required("Required"),
  // brochureFileId: Yup.string().required("Required"),
});

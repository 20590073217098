import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { settingsRoute } from "./data";
import {
  AdmminSettingsTabEnum,
  permissions,
  UserResponse,
} from "app/types/common";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import { useEffect } from "react";
import Security from "./Security";
import Admins from "./Admins";
import CompanySettings from "./CompanySettings";

const Settings = () => {
  const { user } = useAppSelector(getAuth) as {
    user: UserResponse | undefined;
  };
  const [searchParams, setSearchParams] = useSearchParams({});
  const searchParam = searchParams.get("activeTab");

  useEffect(() => {
    !searchParam &&
      setSearchParams({ activeTab: AdmminSettingsTabEnum.AccountSecurity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActive = (link: string) => {
    return searchParam === link;
  };

  const handleTabChange = (id: AdmminSettingsTabEnum) => {
    setSearchParams({ activeTab: id.toString() });
  };

  const getTabs = (role: string = "") => {
    const modules = permissions.find(
      (x) => x.role.toString() === role
    )?.modules;
    return settingsRoute.filter((x) =>
      modules?.map((x) => x.toString()).includes(x.value)
    );
  };

  return (
    <Container>
      <SideNav>
        <h6>Admin Settings</h6>
        <NavWrapper>
          {getTabs(user?.role?.name).map((item, idx) => (
            <LinkWrapper
              key={idx}
              isActive={getActive(item.link)}
              onClick={() => handleTabChange(item.link)}
            >
              {item.title}
            </LinkWrapper>
          ))}
        </NavWrapper>
      </SideNav>
      <Content>
        {searchParam === AdmminSettingsTabEnum.AccountSecurity && <Security />}
        {searchParam === AdmminSettingsTabEnum.Admins && <Admins />}
        {searchParam === AdmminSettingsTabEnum.AppSettings && (
          <CompanySettings />
        )}
      </Content>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  display: flex;
  gap: 15px;
  min-height: 80vh;
`;

const SideNav = styled.div`
  padding: 48px 16px;
  background-color: white;
  min-width: 190px;
  & > h6 {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
    margin-bottom: 48px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

interface LinkWrapperProps {
  isActive: boolean;
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#97B644" : "#3e4b1c")};
  border-left: ${({ isActive }) => (isActive ? "5px solid #97B644" : "none")};
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    color: #97b644;
  }
`;

const Content = styled.div`
  background-color: white;
  padding: 24px;
  flex: 1;
`;

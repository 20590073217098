import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

export const ContactUs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;
  position: absolute;
  left: 0;
  width: 155px;
  height: 50px;
  color: white;
  background: #49b7e4;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px 8px 0px 0px;
  border: none;
  cursor: pointer;
  transform: translate(-105px, 53px) rotate(-90deg);

  ${mediaObj.bigMobile} {
    height: 35px;
    width: 140px;
    transform: translate(-86px, 52px) rotate(-90deg);
  }
`;

import { Box } from "@mui/material";
import { useAppSelector } from "app/redux";
import { getAuth } from "app/redux/auth/authSlice";
import { UserResponse } from "app/types/common";
import { staffRoutes } from "app/utils/data";
import { useNavigate } from "react-router";
import styled from "styled-components";
import ClientGrid from "../grid";

const RecentClients = () => {
  const navigate = useNavigate();
  const userData = useAppSelector(getAuth).user as UserResponse;

  return (
    <Container>
      <Box
        display={"flex"}
        alignItems={"baseline"}
        justifyContent={"space-between"}
      >
        <Title>Recent Clients</Title>
        <ViewAllBtn onClick={() => navigate(staffRoutes.Clients)}>
          View All
        </ViewAllBtn>
      </Box>
      <ClientGrid data={userData?.clients?.slice(0, 4)} />
    </Container>
  );
};

export default RecentClients;

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px 15px 15px;
  margin-top: 10px;
`;

const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 15px;
`;

const ViewAllBtn = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #afd34f;
  cursor: pointer;

  &:hover {
    color: #829b3f;
    transition: all 0.25s ease-in;
  }
`;

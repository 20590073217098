import { useQuery } from "@tanstack/react-query";
import { getLatestPackage } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useGetLatestPackage = () => {
  return useQuery([ProjectsKey.latestPackage], getLatestPackage, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetLatestPackage;

import { FC } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import ModalWrapper from "./ModalWrapper";
import { adminGetOfferPaymentsByCategoryResponse } from "app/api/payment/types";

interface Props {
  data: adminGetOfferPaymentsByCategoryResponse;
  handleClose: () => void;
  open: boolean;
}

const SalesDetailModal: FC<Props> = ({ open, handleClose, data }) => {
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Title>Payment Information</Title>
        <ContentWrapper>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Reference:</Label>
            <Value>{data.reference}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Owner’s Name:</Label>
            <Value>{data.owner.firstname + " " + data.owner.lastname}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Package:</Label>
            <Value>{data.package}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Buyer’s Name:</Label>
            <Value>{data.buyer.firstname + " " + data.buyer.lastname}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Payment:</Label>
            <Value>{data.online ? "Online" : "Offline"}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Amount Paid:</Label>
            <Value>NGN{Number(data.amount).toLocaleString("en-US")}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Date:</Label>
            <Value>
              {new Intl.DateTimeFormat("en-US").format(
                new Date(data.created_at)
              )}
            </Value>
          </Box>
        </ContentWrapper>
      </Container>
    </ModalWrapper>
  );
};

export default SalesDetailModal;

const Container = styled.div`
  width: 465px;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #181d0b;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 48px;
  color: #3d9ac0;
`;

const Value = styled(Label)`
  color: #000000;
`;

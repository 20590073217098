import { Box } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as percentRise } from "app/images/user/percent-rise.svg";
import { ReactComponent as percentFall } from "app/images/user/percent-fall.svg";
import { useAppSelector } from "app/redux";
import { getAuth } from "app/redux/auth/authSlice";
import { ClientUserResponse } from "app/api/users/types";
import { formatPrice } from "app/utils";
import { useNavigate } from "react-router";
import { UserRoutes } from "app/utils/data";
import { FC } from "react";
import { mediaObj } from "styles/utils/media";

interface props {
  isTab?: boolean;
}

export const PropertyValuation: FC<props> = ({ isTab }) => {
  const profile = useAppSelector(getAuth).user as ClientUserResponse;
  const isPositive = profile?.percentage_deviation > 0;
  const packageLength = profile?.packages?.length || 0;
  const navigate = useNavigate();

  return (
    <Wrapper isTab={isTab}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"10px"}
      >
        <Title>Your properties’ performance</Title>
        <ViewAllBtn onClick={() => navigate(UserRoutes.Projects)}>
          View all
        </ViewAllBtn>
      </Box>
      <PropertyCount>
        Gained on{" "}
        {packageLength > 1
          ? `${packageLength} properties`
          : `${packageLength} property`}
      </PropertyCount>
      <Box display={"flex"} alignItems={"center"} gap={"16px"}>
        <Valuation>{formatPrice(profile?.total_property_value)}</Valuation>
        {profile?.percentage_deviation && (
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            |
            <Percent
              isPositive={isPositive}
            >{`${profile.percentage_deviation.toPrecision(3)}%`}</Percent>
            {isPositive ? <PercentRiseIcon /> : <PercentFallIcon />}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isTab?: boolean }>`
  width: 100%;
  height: 146px;
  padding: 17px 16px 26px;
  background: #fbfbfb;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;

  ${mediaObj.smallDesktop} {
    display: ${({ isTab }) => (isTab ? "block" : "none")};
  }

  ${mediaObj.bigMobile} {
    display: ${({ isTab }) => (isTab ? "none" : "block")};
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  color: #2a3313;
`;

const ViewAllBtn = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
`;

const PropertyCount = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #909090;
  margin-bottom: 19px;
`;

const Valuation = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 140%;
  color: #1e4b5e;
`;

const Percent = styled.p<{ isPositive?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${({ isPositive }) => (isPositive ? `#809a39` : `#b3261e`)};
`;

const PercentRiseIcon = styled(percentRise)`
  width: 20px;
  height: 11px;
`;

const PercentFallIcon = styled(percentFall)`
  width: 20px;
  height: 11px;
  path {
    fill: #b3261e;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { getEventByTitle } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useGetEventByTitle = (title: string) => {
  return useQuery(
    [CommunityQueryKeys.getEventByTitle],
    () => getEventByTitle(title),
    {
      enabled: !!title,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetEventByTitle;

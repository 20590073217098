import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as CalendarIcon } from "../../../../images/web/calendar-icon.svg";
import styled from "styled-components";
import { useField } from "formik";
import { Dayjs } from "dayjs";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  value: Dayjs | null;
  onChange: (ev: Dayjs | null) => void;
  isRequired?: boolean;
  isDisabled?: boolean;
}

const DatePickerField: React.FC<Props> = ({
  label,
  placeholder,
  value,
  isRequired,
  onChange,
  isDisabled,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  return (
    <Container>
      <label htmlFor={props.name} className={errorClass}>
        {label}
      </label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="date-picker"
          value={value}
          disabled={isDisabled}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...field}
                {...props}
                sx={{
                  backgroundColor: isDisabled
                    ? "rgba(239, 239, 239, 0.9)"
                    : "transparent",
                  input: {
                    fontFamily: `'Circular Std', sans-serif`,
                    fontWeight: 450,
                    fontSize: "16px",
                    color: "#000000",
                  },
                }}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: `${placeholder}`,
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </Container>
  );
};

export default DatePickerField;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    font-family: "Circular Std", sans-serif;
    font-weight: 450;
    font-size: 16px;
    color: #000000;
  }

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }
  & > div:nth-child(1) {
    width: 100%;
  }
  .date-picker {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    height: 3.5rem;
  }

  .MuiOutlinedInput-root {
    & > fieldset {
      border: none;
    }
  }

  .MuiOutlinedInput-root:hover {
    & > fieldset {
      border: none;
    }
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

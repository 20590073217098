import React from "react";
import Spinner from "app/views/Web/components/Spinner";
import useAdminGetEvents from "app/hooks/community/useGetEvents";
import styled from "styled-components";
import UpcomingEventCard from "./UpcomingEventCard";

const DisplayEvents = () => {
  const { data: paginatedData, isLoading, isError } = useAdminGetEvents(1);

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (paginatedData && paginatedData.data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no content for this yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {paginatedData &&
        paginatedData.data.map((x) => (
          <UpcomingEventCard key={x.id} data={x} />
        ))}
    </>
  );
};

export default DisplayEvents;

const NoProjectText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &:nth-child(1) {
    margin-top: 40px;
  }
`;

import { cardTextAnimate } from "app/utils/animationVariants";
import { useField } from "formik";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";

interface Props {
  name: string;
  label: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const InputField: React.FC<Props> = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;

  return (
    <InputContainer variants={cardTextAnimate}>
      <label htmlFor={props.name} className={errorClass}>
        {label}
      </label>
      <input
        type="text"
        className={errorClass}
        id={props.name}
        {...field}
        {...props}
        onChange={(ev) => {
          props.onChange(ev);
          field.onChange(ev);
        }}
      />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </InputContainer>
  );
};

export default InputField;

const InputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;
  }

  & > input {
    border: 1px solid #181d0b;
    border-radius: 0.5rem;
    height: 3.5rem;
    padding: 0 0.5rem;

    :focus-visible {
      outline: none;
    }

    &.error {
      border: 1px solid red;
    }
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface props {
  handleClose: () => void;
  isOpen: boolean;
  children: any;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
}

const CustomModal: React.FC<props> = ({
  handleClose,
  isOpen,
  children,
  width,
  maxWidth,
  minWidth,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 350,
      }}
    >
      <Fade in={isOpen}>
        <ContentWrapper width={width} minWidth={minWidth} maxWidth={maxWidth}>
          {children}
          <Close onClick={handleClose}>
            <HighlightOffIcon />
          </Close>
        </ContentWrapper>
      </Fade>
    </Modal>
  );
};

export default CustomModal;

interface contentProps {
  width?: string;
  maxWidth?: string;
  minWidth?: string;
}

const ContentWrapper = styled.div<contentProps>`
  width: ${({ width }) => (width ? width : "400px")};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  max-height: 90vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 24px;
  border-radius: 8px;
`;

const Close = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

import {
  cardVariant,
  opacityAnim,
  slideIn,
  staggerContainer,
} from "app/utils/animationVariants";
import { motion } from "framer-motion";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "../../../../styles/utils/mediaQueries";
import api from "../../../api";
import {
  locationObj,
  projectObj,
  projectResponse,
} from "../../../api/project/types";
import DualRing from "../components/loader";
import PackageList from "./components/packageList";
import PackageDetails from "./packageDetails";

const initialData: projectResponse = {
  id: 0,
  description: "",
  name: "",
};

const ProjectCategory = () => {
  const [projectData, setProjectData] = useState<projectResponse>(initialData);
  const [selectedProject, setSelectedProject] = useState<projectObj>();
  const [selectedLocation, setSelectedLocation] = useState<locationObj>();
  const [isLoading, setIsLoading] = useState(false);
  const { categoryId, id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    async function getProjectsByCategory(id: number) {
      setIsLoading(true);
      try {
        const res = await api.projectService.getProjectsByCategoryId(id);
        setProjectData(res.data.data);
        res?.data?.data?.projects &&
          setSelectedProject(res?.data?.data?.projects[0]);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
      setIsLoading(false);
    }

    if (isNaN(Number(categoryId))) {
      toast.error("Invalid category id");
    } else {
      getProjectsByCategory(Number(categoryId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectedProject && setSelectedLocation(selectedProject?.locations[0]);
  }, [selectedProject]);

  const handleProjectTabClick = (idNo: number) => {
    const selectedProj = projectData?.projects?.find((x) => x.id === idNo);
    setSelectedProject(selectedProj);
    id && navigate(`/project/${categoryId}`);
  };

  const handleLocationTabClick = (location: locationObj) => {
    setSelectedLocation(location);
  };

  return (
    <Container>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="35px" height="35px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <>
          <TopContent initial={"initial"} animate={"animate"}>
            <motion.h1 variants={slideIn("left")} custom={1}>
              {capitalize(projectData.name)}
            </motion.h1>
            <motion.p variants={slideIn("right")} custom={1}>
              {projectData.description}
            </motion.p>
          </TopContent>
          <TabsWrapper
            variants={staggerContainer}
            custom={0.5}
            viewport={{ once: true, amount: 0.5 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            {projectData?.projects &&
              projectData?.projects?.length > 0 &&
              projectData.projects.map((x, i) => (
                <Tab
                  key={i}
                  isActive={x.id === selectedProject?.id}
                  onClick={() => handleProjectTabClick(x.id)}
                  variants={cardVariant}
                >
                  {x.name}
                </Tab>
              ))}
            {projectData?.projects && projectData?.projects?.length === 0 && (
              <NoProjectText>
                We have no project for this category
              </NoProjectText>
            )}
          </TabsWrapper>
          {selectedProject?.locations &&
            selectedProject?.locations.length > 1 && (
              <LocationTabsWrapper
                variants={staggerContainer}
                custom={0.9}
                animate={"animate"}
                initial={"initial"}
              >
                {selectedProject?.locations.map((x, i) => (
                  <LocationTab
                    key={i}
                    isActive={x.id === selectedLocation?.id}
                    onClick={() => handleLocationTabClick(x)}
                    variants={opacityAnim}
                  >
                    {capitalize(x.location)}
                  </LocationTab>
                ))}
              </LocationTabsWrapper>
            )}
          {projectData?.projects &&
            projectData?.projects?.length > 0 &&
            (id ? (
              <PackageDetails />
            ) : (
              <PackageList data={selectedLocation?.packages || []} />
            ))}
        </>
      )}
    </Container>
  );
};

export default ProjectCategory;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px auto 70px;
  width: 80%;

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    margin: 20px auto 70px;
  }
`;

const TopContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 50%;

  & > h1 {
    font-weight: 500;
    font-size: 2.4375rem;
    line-height: 140%;
    color: #000000;
  }

  & > p {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #5e5e5e;
    text-align: center;
  }

  ${mediaObj.smallDesktop} {
    width: 90%;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    gap: 15px;

    & > h1 {
      font-size: 25px;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

const TabsWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin: 30px 0 40px;

  @media (max-width: 1300px) {
    max-width: 100%;
    justify-content: flex-start;
    overflow-x: scroll;
    gap: 14px;
  }
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled(motion.span)<tabProps>`
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;
  height: 38px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#143340")};
  background: ${({ isActive }) => (isActive ? "#327F9E" : "#cde6f5")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;

  ${mediaQueries.mobile} {
    width: fit-content;
    font-size: 13px;
    flex-shrink: 0;
  }
`;

const LocationTabsWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  width: 100%;

  ${mediaQueries.mobile} {
    gap: 20px;
  }
`;

const LocationTab = styled(motion.span)<tabProps>`
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#28647D" : "#909090")};
  border-bottom: ${({ isActive }) => (isActive ? "4px solid #AFD34F" : "none")};
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${mediaQueries.mobile} {
    font-size: 16px;
  }
`;

export const MainLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
`;

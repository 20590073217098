import styled from "styled-components";

const NotFound = () => {
  return (
    <Container>
      <h1>Page Not Found</h1>
    </Container>
  );
};

export default NotFound;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  & > h1 {
    margin: 300px auto;
  }
`;

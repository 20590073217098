import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getASiteTour } from "app/api/site tour";
import { getSiteToursResponse } from "app/api/site tour/types";
import { ApiResponse } from "app/redux/types";
import { parseError } from "app/utils";
import { AxiosResponse } from "axios";
import { siteTourKeys } from "./types";

type IProps = AxiosResponse<ApiResponse<getSiteToursResponse>, any>;

const useGetASiteTour = (id: number, month?: number, year?: number) => {
  const queryClient = useQueryClient();

  return useQuery(
    [siteTourKeys.getASiteTour, id, month, year],
    () => getASiteTour(id, month, year),
    {
      enabled: !!id,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      staleTime: 30 * 1000,
      initialData: () => {
        const queryData = queryClient.getQueryData([
          siteTourKeys.getSiteTours,
          month,
          year,
        ]) as any;

        const singleData = queryData?.data?.data?.find(
          (d: getSiteToursResponse) => d.id === id
        ) as IProps;

        const temp = {
          ...queryData,
          data: {
            ...queryData?.data,
            data: singleData,
          },
        };

        return temp as IProps;
      },
      initialDataUpdatedAt: () =>
        queryClient.getQueryState([siteTourKeys.getSiteTours, month, year])
          ?.dataUpdatedAt,
    }
  );
};

export default useGetASiteTour;

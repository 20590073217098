import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import HeroImage1 from "../../../images/web/hero-image1.png";
import HeroImage2 from "../../../images/web/hero-image2.png";
import { ReactComponent as TwoCirclesImg } from "../../../images/web/two-circles.svg";
import { ReactComponent as StarImg } from "../../../images/web/star.svg";
import { ReactComponent as ChatIcon } from "../../../images/web/chat-icon.svg";
import {
  fadeIn,
  slideIn,
  slideInShake,
  slideInShakeMobile,
} from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  agroToHomeRef: (node?: Element | null | undefined) => void;
}

const AgroToHome: React.FC<Props> = ({ agroToHomeRef }) => {
  const isMobile = useMobileCheck();

  return (
    <Wrapper
      ref={agroToHomeRef}
      viewport={{ once: true, amount: isMobile ? 0.2 : 0.5 }}
      whileInView={"animate"}
      initial={"initial"}
    >
      <LeftSide variants={isMobile ? slideInShakeMobile : slideInShake}>
        <Img2>
          <img src={HeroImage2} alt="landing" />
          <BgShadow2 />
        </Img2>

        <Img1>
          <img src={HeroImage1} alt="landing" />
          <BgShadow1 />
        </Img1>
        <TwoCirclesImg className="extras extra_1" />
        <StarImg className="extras extra_2" />
      </LeftSide>
      <RightSide>
        <Title variants={slideIn()} custom={2}>
          Our SDG Targets
        </Title>

        <Para variants={slideIn("right")} custom={3}>
          At Adbond Harvest and Homes, we have aligned our "agro-to-home"
          products with Sustainable Development Goals 1 and 2 - to end poverty
          and hunger in all its forms through sustainable agriculture across the
          globe. As part of our process, we enable enable individuals and
          businesses to invest in lands, cultivate the lands with the farming
          options of their choice, and then build structures.
        </Para>

        <ChatBtn variants={fadeIn("up")} custom={4}>
          <ChatIcon />

          <TextWrapper
            href="https://chat.whatsapp.com/GvCMEGUmvWk649A7GOK4P6"
            target={"_blank"}
          >
            Contact us
          </TextWrapper>
        </ChatBtn>
      </RightSide>
    </Wrapper>
  );
};

export default AgroToHome;

const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 90px;
  padding: 40px 70px;
  min-height: 540px;
  background-color: #fbfdf7;
  box-shadow: 0 0 0 100vmax #fbfdf7;
  clip-path: inset(0 -100vmax);

  ${mediaObj.mediumDesktop} {
    gap: 20px;
  }

  ${mediaObj.smallDesktop} {
    padding: 40px 0;
  }

  ${mediaObj.bigMobile} {
    flex-direction: column;
    padding: 50px 0px;
  }
`;

const LeftSide = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 30px;
  transform: rotate(-31.84deg);
  flex-shrink: 0;

  .extras {
    position: absolute;
  }

  .extra_1 {
    top: -30%;
    left: 40%;
  }

  .extra_2 {
    bottom: -30%;
    left: 60%;
  }

  ${mediaObj.mediumDesktop} {
    margin: 5rem;
    width: 40%;
  }

  ${mediaObj.smallDesktop} {
    width: 40%;
    margin: 0 3rem 0 1.5rem;
  }

  ${mediaObj.bigMobile} {
    gap: 15px;
    margin: 0.5rem 0;
    width: 100%;
    transform: rotate(0deg);
  }
`;

const Img1 = styled.div`
  position: relative;
  display: flex;
  border-radius: 8px;
  width: 250px;
  height: 250px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
    height: 100%;
  }
`;

const Img2 = styled.div`
  position: relative;
  display: flex;
  width: 200px;
  height: 200px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
    height: 100%;
  }
`;

const BgShadow = styled.div`
  z-index: -1;
  position: absolute;
  left: 4px;
  top: -4px;
  border-radius: 8px;
`;

const BgShadow1 = styled(BgShadow)`
  width: 100%;
  height: 100%;
  background-color: #49b7e4;
`;

const BgShadow2 = styled(BgShadow)`
  width: 100%;
  height: 100%;
  background-color: #afd34f;
`;

const RightSide = styled.div`
  ${mediaObj.smallDesktop} {
    width: 47%;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;
const Title = styled(motion.h2)`
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 140%;
  color: #071e11;

  ${mediaObj.tablet} {
    font-size: 1.9375rem;
  }
`;

const Para = styled(motion.p)`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 140%;
  color: #5e5e5e;
  margin-top: 24px;
  margin-bottom: 40px;

  ${mediaObj.tablet} {
    font-size: 1rem;
  }

  ${mediaObj.mobile} {
    margin-top: 12px;
    margin-bottom: 25px;
  }
`;

const ChatBtn = styled(motion.button)`
  width: max-content;
  height: 56px;
  padding: 16px 40px;
  border: none;
  border-radius: 8px;
  background: #afd34f;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 13px;
  margin-top: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  transition: all 0.2s ease-out;

  & > svg {
    width: 24px;
    height: 24px;
  }

  &:hover {
    background-color: #697f2f;
  }

  ${mediaQueries.mobile} {
    margin-top: 30px;
  }
`;

const TextWrapper = styled.a`
  text-decoration: none;
  color: unset;
`;

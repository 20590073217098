import { useMutation } from "@tanstack/react-query";
import { searchPackage } from "app/api/utilities";
import { parseError } from "app/utils";

const useSearchPackage = () => {
  return useMutation(searchPackage, {
    onError: (error) => parseError(error),
  });
};

export default useSearchPackage;

import React, { useState } from "react";
import AccountDetails from "./AccountDetails";
import ConfirmPayment from "./ConfirmPayment";
import ModalWrapper from "./ModalWrapper";
import NotifyDialog from "./NotifyDialog";
import Payment from "./Payment";

interface Props {
  open: boolean;
  handleClose: () => void;
  addOwner?: boolean;
  addNote?: boolean;
  isInstallment?: boolean;
  packageItemId: number;
  packageName: string;
  projectName: string;
  SQM: string;
  amountToPay?: string;
  redirect?: () => void;
  isReoccuring?: boolean;
  order_id?: number;
  isOfferPayment?: boolean;
  offer_id?: number;
  bid_id?: number;
  plotUnit?: number;
}

const PaymentModal: React.FC<Props> = ({
  open,
  handleClose,
  addOwner,
  addNote,
  isInstallment,
  packageItemId,
  packageName,
  projectName,
  SQM,
  amountToPay,
  redirect,
  isReoccuring,
  order_id,
  isOfferPayment,
  offer_id,
  bid_id,
  plotUnit,
}) => {
  const [bankAmount, setBankAmount] = useState<number>();
  const [step, setStep] = useState(1);
  const onStep2 = () => setStep(2);
  const onStep3 = () => setStep(3);
  const onStep4 = () => setStep(4);

  const onBankTransfer = (amount: number) => {
    setBankAmount(amount);
    onStep2();
  };

  return (
    <>
      {step !== 4 && (
        <ModalWrapper
          open={open}
          title={step === 3 ? "Confirm Payment" : "Make Payment"}
          goBack={() => {}}
          applyBackBtn={false}
          handleClose={handleClose}
        >
          <>
            {step === 1 && (
              <Payment
                addOwner={addOwner}
                addNote={addNote}
                onNext={onBankTransfer}
                isInstallment={isInstallment}
                packageItemId={packageItemId}
                packageName={packageName}
                projectName={projectName}
                SQM={SQM}
                amountToPay={amountToPay}
                redirect={redirect}
                handleClose={handleClose}
                isReoccuring={isReoccuring}
                order_id={order_id}
                isOfferPayment={isOfferPayment}
                offer_id={offer_id}
                bid_id={bid_id}
                plotUnit={plotUnit}
              />
            )}
            {step === 2 && bankAmount && (
              <AccountDetails
                onNext={onStep3}
                packageItemId={packageItemId}
                amountToPay={bankAmount}
              />
            )}
            {step === 3 && bankAmount && (
              <ConfirmPayment
                onNext={onStep4}
                packageItemId={packageItemId}
                amountToPay={bankAmount}
                isInstallment={isInstallment}
                isReoccuring={isReoccuring}
                order_id={order_id}
                isOfferPayment={isOfferPayment}
                offer_id={offer_id}
                bid_id={bid_id}
                plotUnit={plotUnit}
              />
            )}
          </>
        </ModalWrapper>
      )}
      {step === 4 && (
        <NotifyDialog
          open={true}
          handleClose={() => {
            redirect && redirect();
            handleClose();
          }}
        />
      )}
    </>
  );
};

export default PaymentModal;

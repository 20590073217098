import styled from "styled-components";

interface props {
  handleClick: () => void;
  isOpen: boolean;
}

const Hamburger: React.FC<props> = ({ handleClick, isOpen }) => {
  return (
    <StyledBurger open={isOpen} onClick={handleClick} id="mobile_hamburger">
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Hamburger;

interface buttonProps {
  open?: boolean;
}

const StyledBurger = styled.button<buttonProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.2rem;
  height: 1.1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 1.2rem;
    height: 0.2rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: #afd34f;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

import { ClickAwayListener } from "@mui/base";
import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  data: any;
  dataArray: {
    name: string;
    nameColor?: string;
    onClick: (data: any) => void;
    icon?: string;
    isLoading?: string;
  }[];
}

const DynamicRenderer: React.FC<Props> = ({ data, dataArray }) => {
  const [openActions, setOpenActions] = useState(false);
  const handleClose = () => setOpenActions(false);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <ActionWrapper openActions={openActions}>
        <ArrowDropDownIcon
          onClick={() => {
            setOpenActions((prev) => !prev);
          }}
        />
        {openActions && (
          <ActionBox>
            {dataArray.map((ev, i) => (
              <>
                <ActionItem
                  onClick={() => {
                    ev.onClick(data);
                    handleClose();
                  }}
                  nameColor={ev.nameColor}
                >
                  {ev.isLoading ? <Spinner color="black" /> : ev.icon}{" "}
                  <span>{ev.name}</span>
                </ActionItem>
                {i < dataArray.length && <HR />}
              </>
            ))}
          </ActionBox>
        )}
      </ActionWrapper>
    </ClickAwayListener>
  );
};

export default DynamicRenderer;

interface ActionWrapperProps {
  openActions: boolean;
}

const ActionWrapper = styled.div<ActionWrapperProps>`
  position: relative;
  z-index: ${({ openActions }) => (openActions ? 99 : "unset")};
  height: 60px;
  display: flex;
  align-items: center;

  & > svg {
    cursor: pointer;
  }
`;

const ActionBox = styled.div`
  min-width: 130px;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateX(50%) translateY(100%);
  background-color: white;
  border: 1px solid #f2eae5;
  box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.04);
`;

const HR = styled.div`
  height: 0.3px;
  background-color: #afd352;
`;

interface ActionItemProps {
  nameColor?: string;
}

const ActionItem = styled.div<ActionItemProps>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 15px 16px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ nameColor }) => nameColor && nameColor};
  }

  span {
    font-weight: 450;
    font-size: 14px;
    line-height: 140%;
    color: ${({ nameColor }) => (nameColor ? nameColor : "#0c0500")};
  }

  &:hover {
    background-color: #fbfbfb;
  }
`;

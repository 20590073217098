import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminConfirmSales } from "app/api/analytics";
import { parseError } from "app/utils";
import { PaymentQueryKeys } from "../payment/types";

const useAdminConfirmSales = () => {
  const queryClient = useQueryClient();

  return useMutation(adminConfirmSales, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([
        PaymentQueryKeys.adminGetOfferPaymentsByCategory,
      ]);
    },
  });
};

export default useAdminConfirmSales;

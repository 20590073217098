import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  adminCreatePromo,
  adminDeletePromo,
  adminUpdatePromo,
} from "app/api/offers";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

export const useAdminCreatePromo = () => {
  const queryClient = useQueryClient();
  return useMutation(adminCreatePromo, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.getPromos]);
    },
  });
};

export const useAdminUpdatePromo = () => {
  const queryClient = useQueryClient();

  return useMutation(adminUpdatePromo, {
    onError: (error) => parseError(error),

    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.getPromos]);
    },
  });
};

export const useAdminDeletePromo = () => {
  const queryClient = useQueryClient();

  return useMutation(adminDeletePromo, {
    onError: (error) => parseError(error),

    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.getPromos]);
    },
  });
};

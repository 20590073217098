import React, { useEffect, useState } from "react";
import { UserRoutes } from "app/utils/data";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import SetPriceModal from "./components/modals/SetPriceModal";
import Valuation from "./components/valuation";
import { mediaObj } from "styles/utils/media";
import { ClientPackageResponse } from "app/api/client/types";

const CompletePage = () => {
  const { clientPackageData }: { clientPackageData: ClientPackageResponse } =
    useOutletContext();
  const [searchParams] = useSearchParams();
  const editType = searchParams.get("edit");
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (editType) {
      setOpen(true);
    }
  }, [editType]);

  return (
    <>
      <BtnWrapper>
        <Link to={UserRoutes.Projects}>
          <BuyBtn>Buy More</BuyBtn>
        </Link>
        <SaleBtn onClick={() => setOpen(true)}>Put up for Sales</SaleBtn>
      </BtnWrapper>
      <Valuation price={+clientPackageData.price} />
      {open && (
        <SetPriceModal
          open={open}
          handleClose={handleClose}
          isEdit={editType}
        />
      )}
    </>
  );
};

export default CompletePage;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  ${mediaObj.bigMobile} {
    flex-wrap: wrap;
  }
`;

const BuyBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 160px;
  height: 45px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 230px;
  }
`;

const SaleBtn = styled(BuyBtn)`
  background: #49b7e4;
  &:hover {
    background-color: #327f9e;
    transition: all 0.2s ease-out;
  }
`;

import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../../images/admin/search-icon.svg";
import { ReactComponent as NotificationIcon } from "../../../../images/admin/bell.svg";
import { ReactComponent as SupportIcon } from "../../../../images/admin/support.svg";
import useScrollHandler from "../../../../hooks/useScrollHandler";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { getAuth, setUser } from "../../../../redux/auth/authSlice";
import { useEffect, useState } from "react";
import mediaQueries from "styles/utils/mediaQueries";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import ImgLogo from "../../../../images/web/Adbond Logo 1.svg";
import Hamburger from "app/views/Client/components/hamburger";
import MobileNavOverlay from "app/views/Client/components/mobileNavOverlay";
import NameAvatar from "../nameAvatar";
import { Link, useNavigate } from "react-router-dom";
import { setDashboardUrl } from "app/redux/dashboard/dashboardSlice";
import { UserRoutes } from "app/utils/data";
import useClientGetProfile from "app/hooks/users/useClientGetProfile";
import { whatsAppLink } from "app/types/common";
import { useTabletCheck } from "app/hooks/useTabletCheck";
import { mediaObj } from "styles/utils/media";

const Header = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const dispatch = useAppDispatch();
  const { scrolledDown } = useScrollHandler();
  const { user } = useAppSelector(getAuth);
  const isMobile = useMobileCheck();
  const isTab = useTabletCheck();
  const navigate = useNavigate();
  const { data: profileData } = useClientGetProfile();

  const formatName = () => {
    if (!user) return;
    return user?.firstname.charAt(0) + ". " + user?.lastname;
  };

  useEffect(() => {
    if (profileData) dispatch(setUser(profileData));
  }, [profileData, dispatch]);

  const goHome = () => {
    navigate("/");
    dispatch(setDashboardUrl(UserRoutes.Root));
  };

  const handleNavBarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <Container isInFocus={scrolledDown}>
      <ContentWrapper>
        {isTab && <Logo src={ImgLogo} onClick={goHome} />}
        {!isMobile && (
          <SearchBar isInFocus={scrolledDown}>
            <Input
              type="text"
              placeholder="Search for projects, clients, locations…………"
              isInFocus={scrolledDown}
            />
            <SearchIcon />
          </SearchBar>
        )}
        <RightContentWrapper>
          <IconsWrapper>
            {/* <IconsBg>
              <NotificationIcon />
            </IconsBg> */}
            <IconsBg href={whatsAppLink} target={"_blank"}>
              <SupportIcon />
            </IconsBg>
          </IconsWrapper>
          <ProfileWrapper to={UserRoutes.Settings.Profile}>
            {user?.profile_photo ? (
              <ProfileImg src={user.profile_photo.url} />
            ) : (
              <NameAvatar
                name={user?.firstname + " " + user?.lastname}
                width="40px"
                height="40px"
              />
            )}
            <ProfileName>{formatName()}</ProfileName>
          </ProfileWrapper>
        </RightContentWrapper>
        {isTab && (
          <Hamburger handleClick={handleNavBarToggle} isOpen={isNavbarOpen} />
        )}
      </ContentWrapper>
      <MobileNavOverlay
        isOpen={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
      />
    </Container>
  );
};

export default Header;

interface headerProps {
  isInFocus?: boolean;
}

const Container = styled.div<headerProps>`
  position: fixed;
  top: 0;
  left: 211px;
  display: flex;
  width: calc(100vw - 211px);
  height: 70px;
  padding: 15px 90px;
  background: ${({ isInFocus }) => (isInFocus ? "#ffffff" : "transparent")};
  z-index: 999;
  transition: all 0.2s ease-in;

  ${mediaObj.smallDesktop} {
    width: 100%;
    left: 0;
    padding: 10px 15px;
    background: #ffffff;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled.img`
  width: 90px;
  height: 35px;
  cursor: pointer;
  object-fit: cover;

  ${mediaObj.smallDesktop} {
    width: 130px;
    height: 50px;
  }

  ${mediaObj.bigMobile} {
    width: 110px;
    height: 45px;
    margin-top: -4px;
  }
`;

const SearchBar = styled.div<headerProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 40%;
  max-width: 475px;
  height: 50px;
  background: ${({ isInFocus }) => (isInFocus ? "#f5f5f5" : "#ffffff")};
  border-radius: 20px;
  transition: all 0.2s ease-in;

  & > svg {
    position: absolute;
    top: 13px;
    right: 25px;
    width: 22.17px;
    height: 22.17px;
    cursor: pointer;
  }
`;

const Input = styled.input<headerProps>`
  width: 100%;
  height: 80%;
  padding: 3px 60px 0 20px;
  font-size: 16px;
  background: ${({ isInFocus }) => (isInFocus ? "#f5f5f5" : "#ffffff")};
  outline: none;
  border: none;
  border-radius: 20px;
  transition: all 0.2s ease-in;
`;

const RightContentWrapper = styled.div`
  display: flex;
  gap: 65px;
  align-items: center;

  ${mediaObj.smallDesktop} {
    gap: 50px;
  }

  ${mediaQueries.mobile} {
    gap: 25px;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconsBg = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #eef5e0;
  border-radius: 50%;
  cursor: pointer;
`;

const ProfileWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  cursor: pointer;
`;

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  object-fit: cover;
`;

const ProfileName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #143340;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

import { popUp, slideIn } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import media from "../../../../styles/utils/media";
// import landImage from "../../../images/hero-frame-1.png";
// import agroImage from "../../../images/hero-frame-2.png";
import HeroImage1 from "../../../images/web/uncompleted-home.jpg";
import heroFrame3 from "../../../images/web/cow-image.jpg";
import heroFrame2 from "../../../images/web/pinapple-image.jpg";
import { ReactComponent as DummyImage } from "../../../images/web/dummies.svg";

const LeftSection = () => {
  return (
    <LeftContainer initial={"initial"} animate={"animate"}>
      <motion.h4 variants={slideIn()}>Schedule a site tour</motion.h4>
      <motion.p variants={slideIn("right")}>
        Our experiential tour helps you understand our brand promise to secure
        future spaces through “agro-to-home” development projects.
      </motion.p>
      <ImageGroup variants={popUp}>
        <ImageContent src={heroFrame2} />
        <ImageContent src={heroFrame3} />
        <ImageContent src={HeroImage1} />
      </ImageGroup>
      <DummyImage className="dummy-image" />
    </LeftContainer>
  );
};

export default LeftSection;

const LeftContainer = styled(motion.div)`
  padding: 6.0625rem 2.75rem 9.5rem 2.375rem;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 0.75rem 0px 0px 0.75rem;
  position: relative;

  & > h4 {
    font-weight: 450;
    font-size: 1.9375rem;
    color: #143340;

    ${mediaQueries.mobile} {
      font-size: 1.8rem;
    }
  }

  & > p {
    font-weight: 450;
    font-size: 1.2rem;
    line-height: 140%;
    margin-top: 1.3125rem;
    margin-bottom: 3.9375rem;
    color: #5e5e5e;
  }

  .dummy-image {
    position: absolute;
    left: 0;
    bottom: 0;
    ${media.smallDesktop`
    display: none;
        `}
  }

  ${media.smallDesktop`
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0 1.25rem;

  & > p {
    margin-bottom: 2.5rem;
  }
        `}

  ${media.bigMobile`
      padding: 0;

      & > p {
        font-size: 16px;
      }
  `}
`;

const ImageGroup = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    object-fit: cover;
  }

  & > img:nth-child(2) {
    transform: translate(-25px, 50%);
    z-index: 3;
  }

  & > img:nth-child(3) {
    transform: translate(-50px, 100%);
    z-index: 4;
  }

  ${media.mediumDesktop`
  & > img:nth-child(1) {
    transform: translateX(20px);
  }
        `}

  ${media.smallDesktop`
    display: none;
        `}
`;

const ImageContent = styled(motion.img)`
  width: 9.935rem;
  height: 10.4763rem;
  border-radius: 0.4244rem;
  border: 0.4375rem solid white;

  ${media.mediumDesktop`
  /* width: 100%; */
        `}
`;

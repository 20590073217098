import React from "react";
import Pagination from "react-paginating";
import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface Props {
  size: number;
  totalElements: number;
  currentPage: number;
  pageCount?: number;
  onChange: (page: number) => void;
}

const Paginating: React.FC<Props> = ({
  size,
  totalElements,
  pageCount = 3,
  currentPage,
  onChange,
}) => {
  return (
    <Pagination
      total={totalElements}
      limit={size}
      pageCount={pageCount}
      currentPage={currentPage}
    >
      {({
        pages,
        currentPage,
        hasNextPage,
        hasPreviousPage,
        previousPage,
        nextPage,
        totalPages,
        getPageItemProps,
      }) => (
        <PaginateContainer>
          {hasPreviousPage && (
            <button
              className="prev-btn"
              {...getPageItemProps({
                pageValue: previousPage,
                onPageChange: (page) => {
                  if (page) {
                    onChange(page);
                  }
                },
                total: totalPages,
              })}
            >
              <div>
                <ChevronLeftIcon />
              </div>
            </button>
          )}

          {pages.map((page, i) => {
            let activePage = null;
            if (currentPage === page) {
              activePage = {
                borderBottom: "1px solid  #281103",
              };
            }
            return (
              <button
                key={i}
                {...getPageItemProps({
                  pageValue: page,
                  style: activePage ? activePage : undefined,
                  onPageChange: (page) => {
                    if (page) {
                      onChange(page);
                    }
                  },
                  total: totalPages,
                })}
              >
                {page}
              </button>
            );
          })}

          {hasNextPage && (
            <button
              className="next-btn"
              {...getPageItemProps({
                pageValue: nextPage,
                onPageChange: (page) => {
                  if (page) {
                    onChange(page);
                  }
                },
                total: totalPages,
              })}
            >
              <div>
                <ChevronRightIcon />
              </div>
            </button>
          )}
        </PaginateContainer>
      )}
    </Pagination>
  );
};

export default Paginating;

const PaginateContainer = styled.div`
  display: flex;
  .prev-btn,
  .next-btn {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      color: #809a39;
      width: 23px;
      height: 23px;
      background: #d8e8b8;
      cursor: pointer;
      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  button {
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: #281103;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    cursor: pointer;
  }
`;

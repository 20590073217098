import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "../../../../styles/utils/media";
import TestimonialCard from "../components/cards/TestimonialCard";
import { scaleText } from "../components/cards/framer-variants";
import { staggerContainer } from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import { testimonialData } from "./data";

const Testimonial = () => {
  const isMobile = useMobileCheck();

  return (
    <Container>
      <Header
        variants={scaleText}
        initial="hidden"
        whileInView="animate"
        viewport={{ once: true }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        What clients have to say
      </Header>

      <Content
        variants={staggerContainer}
        custom={isMobile ? 0.8 : 0.5}
        viewport={{ once: true, amount: isMobile ? 0.2 : 0.7 }}
        whileInView={"animate"}
        initial={"initial"}
      >
        {testimonialData.map((card, i) => (
          <TestimonialCard
            key={i}
            avatar={card.avatar}
            testimonialText={card.testimonialText}
            avatarName={card.avatarName}
          />
        ))}
      </Content>
    </Container>
  );
};

export default Testimonial;

const Container = styled.div`
  background-color: #eef7fc;
  box-shadow: 0 0 0 100vmax #eef7fc;
  clip-path: inset(0 -100vmax);
  padding: 40px 70px;
  margin-bottom: 40px;

  ${mediaObj.mediumDesktop} {
    padding: 40px 50px;
  }

  ${mediaObj.smallDesktop} {
    padding: 40px 0;
  }

  ${mediaObj.bigMobile} {
    padding: 40px 16px;
    margin-bottom: 20px;
  }
`;

const Content = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;

  ${mediaObj.mediumDesktop} {
    /* grid-template-columns: repeat(2, 1fr); */
    display: flex;
    justify-content: space-between;
    gap: 25px 15px;
    flex-wrap: wrap;
  }

  /* ${mediaObj.tablet} {
    .content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
      gap: unset;
      flex-wrap: unset;
    }
  } */

  ${mediaObj.tablet} {
    grid-template-columns: repeat(1, 1fr);
  }

  /* ${mediaObj.bigMobile} {
    flex-direction: column;
  } */
`;

const Header = styled(motion.h1)`
  font-weight: 500;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 40px;
  text-align: center;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clientReplyComment, replyComment } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useCreateReplyComment = () => {
  const queryClient = useQueryClient();

  return useMutation(replyComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.getPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                comments: [
                  ...oldQueryData.data.data.comments.map((comment: any) => {
                    const temp =
                      comment.id === successData.data.data.comment_id
                        ? {
                            ...comment,
                            replies: comment.replies.concat([
                              successData.data.data,
                            ]),
                          }
                        : { ...comment };
                    return temp;
                  }),
                ],
              },
            },
          };
        }
      );
    },
  });
};

export default useCreateReplyComment;

export const useClientCreateReplyComment = () => {
  const queryClient = useQueryClient();

  return useMutation(clientReplyComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.clientGetPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                comments: [
                  ...oldQueryData.data.data.comments.map((comment: any) => {
                    const temp =
                      comment.id === successData.data.data.comment_id
                        ? {
                            ...comment,
                            replies: comment.replies.concat([
                              successData.data.data,
                            ]),
                          }
                        : { ...comment };
                    return temp;
                  }),
                ],
              },
            },
          };
        }
      );
    },
  });
};

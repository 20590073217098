import { Box } from "@mui/material";
import { formatPriceWithoutCurrency } from "app/utils";
import { FC } from "react";
import styled from "styled-components";

interface props {
  label: string;
  value: string | number;
  icon: any;
}

const StaffTotalCard: FC<props> = ({ icon: Icon, label, value }) => {
  return (
    <Wrapper>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        <Label>{label}</Label>
        <Value>
          {typeof value === "string"
            ? formatPriceWithoutCurrency(value)
            : value}
        </Value>
      </Box>
      <Icon />
    </Wrapper>
  );
};

export default StaffTotalCard;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px 16px;
  width: 45%;
  height: 138px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;

  & > svg {
    width: 40px;
    height: 40px;
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #989898;
`;

const Value = styled.p`
  font-weight: 500;
  font-size: 31px;
  line-height: 140%;
  color: #2a3313;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clientLikePost } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useClientLikePost = () => {
  const queryClient = useQueryClient();

  return useMutation(clientLikePost, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.clientGetPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...successData.data.data,
              },
            },
          };
        }
      );
    },
  });
};

export default useClientLikePost;

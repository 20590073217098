import * as Yup from "yup";

export const tourDetailsValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  packages: Yup.object().required("This field is required"),
});

export const tourDatesValidation = Yup.object({
  selectedTourDate: Yup.object().required("dates is required!"),
});

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DashboardTotalCard from "../../components/Cards/dashboardTotal";
import { ReactComponent as TotalClientIcon } from "../../../../images/admin/total-clients.svg";
import { ReactComponent as ActiveProjectIcon } from "../../../../images/admin/active-projects.svg";
import { Box } from "@mui/system";
import StaffGrowth from "./StaffGrowth";
import { salesMonthOptions, yearDropDownOptions } from "../data";
import DropDown from "../../components/dropDown";
import StaffCommission from "./grid/StaffCommission";
import Paginating from "../../components/Pagination";
import useGetTotalClients from "app/hooks/utilities/useGetTotalClients";
import useGetTotalActiveProject from "app/hooks/utilities/useGetTotalActiveProject";
import useAdminStaffCommisssion from "app/hooks/analytics/useAdminStaffCommisssion";
import { toast } from "react-toastify";
import useExportAdminStaffCommisssion from "app/hooks/analytics/useExportAdminStaffCommisssion";
import Spinner from "app/views/Web/components/Spinner";

const MonthValue = new Date().getMonth();

const DisplayStaff = () => {
  const [yearValue, setYearValue] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(
    salesMonthOptions[MonthValue].value
  );
  const [page, setPage] = useState(1);

  const { data: clientCount, isLoading: clientCountLoading } =
    useGetTotalClients();
  const { data: activeCount, isLoading: activeCountLoading } =
    useGetTotalActiveProject();

  const {
    data: commissionData,
    error: commisssionError,
    mutate: commissionMutate,
    isLoading: isCommissionLoading,
  } = useAdminStaffCommisssion(page);

  const {
    mutate: exportCommissionMutate,
    isLoading: isexportCommissionLoading,
  } = useExportAdminStaffCommisssion(page);

  const onExportCommission = () => {
    const payload = {
      start: Number(month),
      year: Number(yearValue),
    };
    exportCommissionMutate({ payload: payload });
  };

  useEffect(() => {
    if (yearValue && month) {
      const payload = {
        start: Number(month),
        year: Number(yearValue),
      };
      commissionMutate({ payload: payload });
    }
  }, [yearValue, month, commissionMutate]);

  useEffect(() => {
    commisssionError && toast.error("Failed to fetch data");
  }, [commisssionError]);

  const handleMonthChange = (value: number) => {
    setMonth(value);
  };

  const handleYearChange = (value: number) => {
    setYearValue(value);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div>
      <MiniLayout>
        <DashboardTotalCard
          label={"Total Existing Clients"}
          icon={TotalClientIcon}
          value={clientCount || 0}
          isLoading={clientCountLoading}
          removeLink
          applyShadow
          maxWidth="unset"
          mediumwidth="unset"
          width="unset"
          flexGrow={1}
          height={"100%"}
        />

        <DashboardTotalCard
          label={"Active Projects"}
          icon={ActiveProjectIcon}
          value={activeCount || 0}
          isLoading={activeCountLoading}
          removeLink
          applyShadow
          maxWidth="unset"
          width="unset"
          mediumwidth="unset"
          flexGrow={1}
          height={"100%"}
        />
        <Box gridColumn={"2/-1"} gridRow={"1/-1"}>
          <StaffGrowth />
        </Box>
      </MiniLayout>
      <CommissionLayout>
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          mb="18px"
        >
          <StaffHeader>Staff Commissions</StaffHeader>
          <DropdownContainer>
            <Print onClick={onExportCommission}>
              {isexportCommissionLoading ? (
                <Spinner color="white" />
              ) : (
                "Export as excel sheet"
              )}
            </Print>

            <DropDown
              dropDownOptions={yearDropDownOptions}
              handleChange={handleYearChange}
              height="28px"
            />
            <DropDown
              dropDownOptions={salesMonthOptions}
              handleChange={handleMonthChange}
              initialValue={MonthValue}
              bgColor={"#3E4B1C"}
              height="28px"
            />
          </DropdownContainer>
        </Box>
        <StaffCommission
          data={commissionData?.data.data || []}
          isLoading={isCommissionLoading}
        />
        {commissionData && commissionData.data.total > 0 && (
          <Footer>
            <Show>
              {" "}
              Showing 1 to{" "}
              {commissionData.data.total > commissionData.data.per_page
                ? commissionData.data.per_page
                : commissionData.data.total}{" "}
              of {commissionData.data.total}
            </Show>

            <Paginating
              totalElements={commissionData.data.total || 0}
              size={commissionData.data.per_page || 0}
              pageCount={3}
              currentPage={page}
              onChange={onPageChange}
            />
          </Footer>
        )}
      </CommissionLayout>
    </div>
  );
};

export default DisplayStaff;

const MiniLayout = styled.div`
  display: grid;
  grid-template-columns: 325px 1fr;
  gap: 32px;
  grid-template-rows: repeat(2, 170px);
`;

const CommissionLayout = styled.div`
  padding: 15px 11px;
  margin-top: 24px;
  background: #fbfbfb;
`;

const StaffHeader = styled.h5`
  font-weight: 450;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

const Print = styled.div`
  padding: 7px 12px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  color: #ffffff;
  background: #3d9ac0;
  border-radius: 4px;
  height: 100%;
  opacity: 0.9;
  cursor: pointer;
`;

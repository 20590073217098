import React, { useRef } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useField } from "formik";
import Spinner from "app/views/Web/components/Spinner";
import { mediaObj } from "styles/utils/media";

interface Props {
  text: string;
  name: string;
  handleFile: (files: FileList) => void;
  isLoading: boolean;
}

export const ImageUpload: React.FC<Props> = ({
  text,
  handleFile,
  isLoading,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [, meta] = useField(props);

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return;
    const fileUploaded = event.currentTarget.files;
    handleFile(fileUploaded);
  };

  const imageFormat = "image/png, image/gif, image/jpeg";

  return (
    <>
      <AddingBox onClick={handleClick}>
        <input
          type="file"
          ref={inputRef}
          onChange={(e) => {
            onFileChange(e);
            e.target.value = "";
          }}
          accept={imageFormat}
        />
        {isLoading ? <Spinner color="black" /> : <AddIcon />}
        {text}
      </AddingBox>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

const AddingBox = styled.div`
  display: flex;
  gap: 24px;
  padding: 25px 16px;
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
  background: #d8e8b8;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  input {
    display: none;
  }

  &:hover {
    background-color: #697f2f;
    color: white;
    transition: all 0.2s ease-in-out;
  }

  ${mediaObj.bigMobile} {
    gap: 14px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import useGetAllVirtualStaff from "app/hooks/users/useGetAllVirtualStaff";
import { useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import EStaffGrid from "./grid";

const VirtualStaff = () => {
  const { data: userData, isLoading, error } = useGetAllVirtualStaff();

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  return (
    <Container>
      <Header>My Virtual Staff</Header>
      <EStaffGrid data={userData || []} isLoading={isLoading} />
    </Container>
  );
};

export default VirtualStaff;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  height: 80vh;
  width: 95%;
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
`;

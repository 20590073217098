import { FC } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import ModalWrapper from "./ModalWrapper";
import { StaffCommissionRes } from "app/api/analytics/types";
import dayjs from "dayjs";

interface Props {
  data: StaffCommissionRes;
  handleClose: () => void;
  open: boolean;
}

const CommissionDetails: FC<Props> = ({ open, handleClose, data }) => {
  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Title>Staff Commission Details</Title>
        <ContentWrapper>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Name:</Label>
            <Value>{data.firstname + " " + data.lastname}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Email:</Label>
            <Value>{data.email}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Phone number:</Label>
            <Value>{data.phone_number}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Package:</Label>
            <Value>{data.order.package_item}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Sqm:</Label>
            <Value>{data.order.package_size}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Commission:</Label>
            <Value>{`₦ ${Number(data.commission).toLocaleString(
              "en-US"
            )}`}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Account Name:</Label>
            <Value>{data.account_name}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Account Number:</Label>
            <Value>{data.account_number}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Bank Name:</Label>
            <Value>{data.bank}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Date:</Label>
            <Value>{String(dayjs(data.date).format("DD MMM, YYYY"))}</Value>
          </Box>
        </ContentWrapper>
      </Container>
    </ModalWrapper>
  );
};

export default CommissionDetails;

const Container = styled.div`
  width: 465px;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #181d0b;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 48px;
  color: #3d9ac0;
`;

const Value = styled(Label)`
  color: #000000;
`;

import * as Yup from "yup";

export const SqmValidation = Yup.object({
  plotUnit: Yup.string().required("This field is required"),
  sqm: Yup.object().required("This field is required"),
});

export const plotUnitValidation = Yup.object({
  plotUnit: Yup.string().required("This field is required"),
});

export const AmountValidation = Yup.object({
  plotUnit: Yup.string().required("This field is required"),
  amount: Yup.number().positive().required("Required").min(1).label("amount"),
});

export const AmountSqmValidation = Yup.object({
  plotUnit: Yup.string().required("This field is required"),
  sqm: Yup.object().required("This field is required"),
  amount: Yup.number().positive().required("Required").min(1).label("amount"),
});

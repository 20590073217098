import { Box, Grid } from "@mui/material";
import { RefObject, useCallback, useEffect, useState } from "react";
import { genderData, titleOptions } from "../data";
import { labelValueType } from "app/types/common";
import { toast } from "react-toastify";
import {
  ClientUpdateNokPayload,
  ClientUserResponse,
} from "app/api/users/types";
import { SubmitBtn } from "./personalInfo";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import useClientUpdateNok from "app/hooks/users/useClientUpdateNok";
import { Form, Formik } from "formik";
import { NokInfoValidation } from "./validation";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import useGetAllCountries from "app/hooks/utilities/useGetAllCountries";
import PhoneFormatter from "app/views/Admin/components/formik inputs/PhoneFormatter";

interface Props {
  isEditable: boolean;
  saveBtn: RefObject<HTMLButtonElement>;
  resetBtn: RefObject<HTMLButtonElement>;
  isLoading: (value: boolean) => void;
  onSetToNotEditable: () => void;
}

const NextOfKin: React.FC<Props> = ({
  isEditable,
  saveBtn,
  isLoading,
  resetBtn,
  onSetToNotEditable,
}) => {
  const [formData, setFormData] = useState<Partial<ClientUpdateNokPayload>>();
  const [countries, setCountries] = useState<labelValueType<string, string>[]>(
    []
  );

  const { user } = useAppSelector(getAuth);
  const { data: countriesData, isLoading: countriesLoading } =
    useGetAllCountries();
  const { mutate: updateMutate, isLoading: isUpdating } = useClientUpdateNok();

  useEffect(() => {
    if (countriesData) {
      setCountries(
        countriesData.map((x) => ({ value: x.id.toString(), label: x.name }))
      );
    }
  }, [countriesData]);

  const settingInitial = useCallback(() => {
    if (user && countries.length > 0) {
      const { country, next_of_kins } = user as ClientUserResponse;
      const temp = countries.filter((x) => x.label === country)[0];
      const nok = !!next_of_kins?.length ? next_of_kins[0] : [];
      setFormData({
        ...nok,
        kin_country_id: +temp?.value,
      });
    }
  }, [user, countries]);

  useEffect(() => {
    settingInitial();
  }, [settingInitial]);

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = () => {
    if (!formData) return;
    updateMutate(formData, {
      onSuccess: (data: any) => {
        toast.success(`Details Updated Successfully!`);
        onSetToNotEditable();
      },
    });
  };

  useEffect(() => {
    isLoading(isUpdating);
  }, [isUpdating, isLoading]);

  return (
    <Formik
      initialValues={{
        kin_firstname: formData?.kin_firstname,
        kin_lastname: formData?.kin_lastname,
        kin_gender: formData?.kin_gender,
        kin_phone_number: formData?.kin_phone_number,
        kin_relationship: formData?.kin_relationship,
        kin_address: formData?.kin_address,
      }}
      enableReinitialize
      validationSchema={NokInfoValidation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={3}
            width={"100%"}
            mt={"50px"}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputField
                  name="kin_firstname"
                  label={"First name"}
                  placeholder={"First Name"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.kin_firstname || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name="kin_lastname"
                  label={"Last name"}
                  placeholder={"Last Name"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.kin_lastname || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectField
                  name="title"
                  label={"Title"}
                  onChange={(e: any) => handleChange(e.value, "title")}
                  options={titleOptions}
                  value={titleOptions.filter(
                    (x) => x.value === formData?.title
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <SelectField
                  name="kin_gender"
                  label={"Gender"}
                  onChange={(e: any) => handleChange(e.value, "kin_gender")}
                  options={genderData}
                  value={genderData.filter(
                    (x) => x.value === formData?.kin_gender
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name="kin_email"
                  label={"Email address"}
                  placeholder={"Email Address"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.kin_email || ""}
                  isDisabled={!isEditable}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PhoneFormatter
                  name={"kin_phone_number"}
                  label={"Phone number"}
                  formik={formik}
                  value={formData?.kin_phone_number}
                  onChange={(value: string) =>
                    handleChange(value, "kin_phone_number")
                  }
                  isDisabled={!isEditable}
                  isRequired
                  borderStyle={"1px solid #bdbdbd"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <SelectField
                  name={"kin_country_id"}
                  label={"Nationality"}
                  onChange={(e: any) => handleChange(e.value, "kin_country_id")}
                  options={countries}
                  value={countries.filter(
                    (x) => x.value === formData?.kin_country_id?.toString()
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isLoading={countriesLoading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name={"kin_address"}
                  label={"Home/office address"}
                  placeholder={"Address"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.kin_address || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name={"kin_relationship"}
                  label={"Relationship"}
                  placeholder={"Relationship"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.kin_relationship || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>
            </Grid>

            <Box width={"100%"} mt={"20px"} display="none">
              <SubmitBtn ref={saveBtn}>Save</SubmitBtn>
              <SubmitBtn ref={resetBtn} onClick={settingInitial}>
                Save
              </SubmitBtn>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default NextOfKin;

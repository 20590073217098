import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

interface Props {
  title?: string;
  image?: string;
  hashtag?: string;
  description?: string;
}

const HelmetMetaData: React.FC<Props> = (props) => {
  let location = useLocation();
  let currentUrl = "https://www.adbondharvestandhomes.com" + location.pathname;

  let title =
    props.title !== undefined ? props.title : "Adbond Harvest and Homes";
  let image =
    props.image !== undefined
      ? props.image
      : "https://ik.imagekit.io/adbond01/Adbond_Logo_1.png";
  let description =
    props.description !== undefined
      ? props.description
      : "We are an “agro-to-home” development company. We help you secure a future space you can call home. At Adbond, you buy, we engage and you earn.";
  let hashtag =
    props.hashtag !== undefined ? props.hashtag : "#adbondharvestandhomes";

  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="csrf_token" content="" />
      <meta property="type" content="website" />
      <meta property="url" content={currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="_token" content="" />
      <meta name="robots" content="noodp" />
      <meta property="title" content={title} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />

      {/* Open Graph / Facebook */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="CampersTribe" />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={currentUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
    </Helmet>
  );
};

export default HelmetMetaData;

import { ApiResponse, PaginatedResponse } from "../../redux/types";
import request from "../request";
import {
  addClientToPackagePayload,
  clientObj,
  ClientPackageResponse,
  ClientPaymentHistoryRes,
  clientRes,
  createClientPayload,
  getClientPackagesResponse,
  SearchClientPayload,
} from "./types";

export const getAllClients = async (page: number, perPage: number) => {
  return await request.get<ApiResponse<clientRes>>(
    `/admin/client/get/${page}?per_page=${perPage}`
  );
};

export const getPaginatedAllClients = async (page: number, perPage: number) => {
  return await request.get<ApiResponse<clientRes>>(
    `/admin/client/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const searchClient = async (payload: SearchClientPayload) => {
  return await request.post<ApiResponse<clientObj[]>>(
    `/admin/client/search`,
    payload
  );
};

const createClient = async (payload: createClientPayload) => {
  return await request.post<ApiResponse<any>>(`/admin/client/create`, payload);
};

const addClientToPackage = async (payload: addClientToPackagePayload) => {
  return await request.post<ApiResponse<any>>(
    `/admin/client/add_package`,
    payload
  );
};

const deleteClient = async (payload: number) => {
  return await request.post<ApiResponse<any>>(`/admin/client/delete`, {
    client_id: payload,
  });
};

export const getClientPackages = async () => {
  return await request.get<ApiResponse<getClientPackagesResponse[]>>(
    `/client/packages`
  );
};

export const getASingleClientPackage = async (id: number) => {
  return await request.get<ApiResponse<ClientPackageResponse>>(
    `/client/package/${id}`
  );
};

export const downloadClientPackageDoa = async (clientPackageId: string) => {
  return await request.get<string>(`/client/download_doa/${clientPackageId}`, {
    responseType: "arraybuffer",
  });
};

export const getClientPaymentHistory = async (
  page: number,
  per_page: number,
  searchValue?: string
) => {
  return await request.get<PaginatedResponse<ClientPaymentHistoryRes[]>>(
    `/payment/my_payments/${page}`,
    {
      params: {
        per_page,
        search: searchValue,
      },
    }
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllClients,
  createClient,
  addClientToPackage,
  deleteClient,
};

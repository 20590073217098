import { FC } from "react";
import styled from "styled-components";

interface props {
  label: string;
  handleChange: (value: string, name: string) => void;
  width?: string;
  name: string;
  value?: string;
}

const CustomTextArea: FC<props> = ({
  handleChange,
  label,
  width,
  name,
  value,
}) => {
  return (
    <Container width={width}>
      <Label>{label}</Label>
      <TextArea
        name={name}
        value={value}
        rows={5}
        cols={10}
        onChange={(e) => handleChange(e.target.value, name)}
      />
    </Container>
  );
};

export default CustomTextArea;

interface containerProps {
  width?: string;
}

const Container = styled.div<containerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${({ width }) => (width ? width : "100%")};
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

const TextArea = styled.textarea`
  padding: 20px 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  color: #949292;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  outline: none;
`;

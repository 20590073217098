import { PromoObj } from "app/api/offers/types";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import storage from "./storage";

export const truncateTxt = (value: string, length: number) => {
  return value.length > length ? `${value.substring(0, length)}...` : value;
};

const parseErrorObj = (error: AxiosError<any, any>) => {
  return Object.values(error.response?.data.errors).forEach((val: any) =>
    toast.error(val[0])
  );
};

export const parseError = (error: any) => {
  const err = error as any as AxiosError;
  if (err.response?.data.errors) parseErrorObj(err); // 422
  else toast.error(err.response?.data.message); // 402 or 500
};

export const setAccessToken = (payload: string) => {
  storage.set("adbondAccessToken", payload);
};

export const bytesForHuman = (bytes: number, decimals = 2) => {
  let units = ["B", "KB", "MB", "GB", "TB", "PB"];

  let i = 0;

  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }

  return parseFloat(bytes.toFixed(decimals)) + " " + units[i];
};

export const formatPrice = (price: number | string = "0") => {
  return `₦${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const formatPriceWithoutCurrency = (price: number | string = "0") => {
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
};

export const abbreviateNumber = (value: number) => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 1,
  }).format(value);
};

export const getTotalPromo = (data: PromoObj[]) => {
  let temp = 0;
  data.forEach((ev) => {
    if (ev.active) {
      temp = temp + +ev.discount;
    }
  });
  return temp;
};

export function replaceEmptyPTagWithBrTa(htmlString: string) {
  return htmlString.replace(/<p><\/p>/gi, "<br/>");
}

import { adminGetPaymentsByCategoryResponse } from "app/api/payment/types";
import React, { useState } from "react";
import styled from "styled-components";
import DynamicRenderer from "../grid/DynamicRenderer";
import StatusRenderer from "../grid/StatusRenderer";
import ModalWrapper from "./ModalWrapper";
import OfflineDialogModal from "./OfflineDialogModal";

type DialogProps = {
  openStatus: boolean;
  type: "accept" | "reject";
  payment_id: number;
};

interface Props {
  open: boolean;
  handleClose: () => void;
  data: adminGetPaymentsByCategoryResponse;
}

const OfflineModal: React.FC<Props> = ({ open, handleClose, data }) => {
  const [openDialog, setOpenDialog] = useState<DialogProps>();
  const handleDialogClose = () => setOpenDialog(undefined);

  const onClose = () => {
    handleDialogClose();
    handleClose();
  };

  const onApprove = (data: adminGetPaymentsByCategoryResponse) => {
    setOpenDialog({
      openStatus: true,
      type: "accept",
      payment_id: data.id,
    });
  };

  const onDecline = (data: adminGetPaymentsByCategoryResponse) => {
    setOpenDialog({
      openStatus: true,
      type: "reject",
      payment_id: data.id,
    });
  };

  const optionArray = [
    {
      name: "Approve",
      nameColor: "#43A047",
      onClick: onApprove,
    },
    {
      name: "Decline",
      nameColor: "#DD1717",
      onClick: onDecline,
    },
  ];

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Header>
          <h6>Transaction Status:</h6>

          <StatusWrapper>
            <StatusRenderer data={data} />
            <DynamicRenderer dataArray={optionArray} data={data} />
          </StatusWrapper>
        </Header>
        <Wrapper>
          <PayWrapper>
            <Title>Payment Information</Title>
            <PayInfo>
              <Text>
                <BlueText>Reference</BlueText>: {data.reference}
              </Text>
              <Text>
                <BlueText>Client Name</BlueText>:{" "}
                {(data.client?.firstname || "--") +
                  " " +
                  (data.client?.lastname || "--")}
              </Text>
              <Text>
                <BlueText>Bank Account Title</BlueText>:{" "}
                {data.bank_account?.account_name}
              </Text>
              <Text>
                <BlueText>Bank Account Number</BlueText>:{" "}
                {data.bank_account?.account_number}
              </Text>
              <Text>
                <BlueText> Bank Name</BlueText>: {data.bank_account?.bank}
              </Text>
              <Text>
                <BlueText> Amount Paid</BlueText>: NGN{" "}
                {Number(data.amount).toLocaleString("en-US")}
              </Text>
              <Text>
                <BlueText>Payment Type</BlueText>:{" "}
                {data.order.installment ? "Installment" : "Full Payment"}
              </Text>
            </PayInfo>
          </PayWrapper>
          <ProveWrapper>
            <h6>Prove of Payment</h6>
            <div>
              <img src={data.evidence?.url} alt="" />
            </div>
          </ProveWrapper>
        </Wrapper>
        {openDialog && (
          <OfflineDialogModal
            open={openDialog.openStatus}
            handleClose={onClose}
            type={openDialog.type}
            paymentId={openDialog.payment_id}
          />
        )}
      </Container>
    </ModalWrapper>
  );
};

export default OfflineModal;

const Container = styled.div`
  width: 70vw;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  margin-bottom: 32px;
  h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 32px;
    color: #000000;
  }
`;

const Wrapper = styled.div`
  display: grid;
  column-gap: 32px;
  grid-template-columns: 1fr 1fr;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
`;

const PayWrapper = styled.div`
  background: #f4f9ec;
  border-radius: 8px;
  padding: 18px 21px;
`;

const PayInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ProveWrapper = styled.div`
  background: #eef7fc;
  border-radius: 12px;
  overflow-y: scroll;
  padding: 20px 24px;

  h6 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: #281103;
    margin-bottom: 30px;
  }
  div {
    background-color: white;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
  }
`;

const Title = styled.div`
  margin-bottom: 21px;
`;

const BlueText = styled.span`
  color: #3d9ac0;
`;

const Text = styled.span`
  color: #143340;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 48px;
`;

import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { paymentData } from "./paymentGrid";

const StatusRenderer = ({ data }: { data: paymentData }) => {
  return <Container status={data.status}>{data.status}</Container>;
};

export default StatusRenderer;

interface ContainerProps {
  status: "approved" | "pending" | "rejected";
}

const Container = styled.div<ContainerProps>`
  width: max-content;
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  background-color: ${({ status }) => status === "approved" && "#43a047"};
  background-color: ${({ status }) => status === "rejected" && "#DD1717"};
  background-color: ${({ status }) => status === "pending" && "#757575"};
  text-transform: capitalize;

  ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

import styled from "styled-components";
import { ReactComponent as LogoutIcon } from "../../../../images/admin/logout.svg";
import ImgLogo from "../../../../images/web/Adbond Logo 1.svg";
import CustomLink from "./components/customLink";
import { clientSidebarData, UserRoutes } from "../../../../utils/data";
import storage from "../../../../utils/storage";
import { useAppDispatch } from "../../../../redux";
import { loginFailed } from "../../../../redux/auth/authSlice";
import { useNavigate } from "react-router";
import { setDashboardUrl } from "app/redux/dashboard/dashboardSlice";

const Sidebar = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
    dispatch(setDashboardUrl(UserRoutes.Root));
  };

  const onLogout = () => {
    dispatch(loginFailed());
    storage.clear();
    navigate("/login");
  };

  return (
    <Container>
      <Logo src={ImgLogo} onClick={goHome} />

      <ContentWrapper>
        <LinksWrapper>
          {clientSidebarData.map((item, index) => (
            <CustomLink key={index} {...item} />
          ))}
        </LinksWrapper>
        <CustomLink action={onLogout} icon={LogoutIcon} title="Logout" />
      </ContentWrapper>
    </Container>
  );
};

export default Sidebar;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 210px;
  padding-top: 40px;
  height: 100vh;
  max-height: 100vh;
  background: #ffffff;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  width: 100%;
  margin-top: 50px;
  padding: 10px 0;
  max-height: calc(100vh - 129px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

const Logo = styled.img`
  width: 106px;
  height: 39px;
  cursor: pointer;
  object-fit: cover;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5vh;
  width: 100%;
`;

import { useQuery } from "@tanstack/react-query";
import { clientGetNews, getNews } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useAdminGetNews = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.adminGetNews, page],
    () => getNews(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetNews;

export const useClientGetNews = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.clientGetNews, page],
    () => clientGetNews(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

import React, { useState } from "react";
import { Backdrop } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styled from "styled-components";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { Form, Formik } from "formik";
import { CreateUserValidation } from "../validation";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { SingleOption } from "app/types/common";
import useGetRoles from "app/hooks/utilities/useGetRoles";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import useAdminCreateAdmin from "app/hooks/users/useAdminCreateAdmin";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CreateUserModal: React.FC<Props> = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState<SingleOption>();

  const { data: rolesData, isLoading: rolesLoading } = useGetRoles();
  const { mutate: createMutate, isLoading: isCreating } = useAdminCreateAdmin();

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name,
      };
      return temp;
    });
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>

        <h6>Add a new admin</h6>

        <Formik
          initialValues={{
            name,
            email,
            role,
          }}
          validationSchema={CreateUserValidation}
          enableReinitialize
          onSubmit={() => {
            if (!role) return;
            const payload = {
              email,
              name,
              role_id: role.value,
            };
            const onSuccess = () => {
              toast.success(`Admin Created Successfully!`);
              setTimeout(() => {
                handleClose();
              }, 1500);
            };
            createMutate(payload, {
              onSuccess,
            });
          }}
        >
          <Form>
            <Wrapper>
              <FormWrapper>
                <InputField
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isRequired
                />
                <InputField
                  name="email"
                  label="Email Address"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isRequired
                />
                <SelectField
                  name="role"
                  label="Role"
                  value={role as SingleOption}
                  onChange={(e) => setRole(e as SingleOption)}
                  options={formatData(rolesData)}
                  selectType="normal"
                  isLoading={rolesLoading}
                  isRequired
                />
                <CreateBtn type="submit">
                  {isCreating && <Spinner color="black" />}
                  Save
                </CreateBtn>
              </FormWrapper>
            </Wrapper>
          </Form>
        </Formik>
      </Container>
    </Backdrop>
  );
};

export default CreateUserModal;

const Container = styled.div`
  width: 683px;
  background-color: white;
  padding: 23px 25px;
  border-radius: 12px;
  position: relative;

  h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 34px;
  }

  & > form {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-start;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  label {
    font-weight: 450;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #181d0b;
    text-align: center;
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const CreateBtn = styled.button`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin: auto;
  margin-top: 30px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

import React from "react";
import styled from "styled-components";
import Header from "../components/header";
import SimpleAccordion from "./ContactBox";
import { Outlet, useLocation } from "react-router";
import { mediaObj } from "../../../../styles/utils/media";
import { ReactComponent as DummyImage } from "../../../images/web/dummies.svg";
import { AnimatePresence, motion } from "framer-motion";
import { AuthConeAnim, popUp, slideIn } from "app/utils/animationVariants";
import HeroImage1 from "../../../images/web/uncompleted-home.jpg";
import heroFrame3 from "../../../images/web/cow-image.jpg";
import heroFrame2 from "../../../images/web/pinapple-image.jpg";
import mediaQueries from "styles/utils/mediaQueries";

const AuthLayout = () => {
  const { pathname } = useLocation();
  return (
    <ContentWrapper>
      <Wrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>

        <AuthContainer>
          <LeftSection animate={"animate"} initial={"initial"}>
            <TopConeImg variants={AuthConeAnim} custom={3}>
              <DummyImage />
            </TopConeImg>
            <FlexWrapper>
              <ImageContent variants={popUp} custom={1}>
                <img src={heroFrame2} alt="" />
              </ImageContent>
            </FlexWrapper>
            <FlexWrapper className="middle">
              <ImageContent variants={popUp} custom={3}>
                <img src={heroFrame3} alt="" />
              </ImageContent>
              <ImageContent variants={popUp} custom={5}>
                <img src={HeroImage1} alt="" />
              </ImageContent>
            </FlexWrapper>
            <Text variants={slideIn("left")}>
              Adbond harvest and homes gives you an opportunity to own
              properties through “agro-to-home” products within Africa.
            </Text>
            <BottomConeImg variants={AuthConeAnim} custom={2}>
              <DummyImage />
            </BottomConeImg>
          </LeftSection>
          <RightSection initial={"initial"} animate={"animate"}>
            <AnimatePresence exitBeforeEnter key={pathname}>
              <Outlet />
            </AnimatePresence>
          </RightSection>
        </AuthContainer>
        <SimpleAccordion />
      </Wrapper>
    </ContentWrapper>
  );
};

export default AuthLayout;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  position: relative;
  @media (min-width: 1536px) {
    width: 1536px;
    margin: auto;
  }
`;

const HeaderWrapper = styled.div`
  padding: 0 2rem;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  ${mediaObj.bigMobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const AuthContainer = styled.div`
  display: flex;
`;

const LeftSection = styled(motion.div)`
  width: 50%;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  background: linear-gradient(
    0.56deg,
    #cde6f5 -3.45%,
    rgba(205, 230, 245, 0) 116.04%
  );
  padding: 90px;
  padding-top: 10rem;

  ${mediaObj.smallDesktop} {
    display: none;
  }
`;

const BottomConeImg = styled(motion.div)`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const TopConeImg = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
`;

const ImageContent = styled(motion.div)`
  height: 10.4763rem;
  border-radius: 0.4244rem;
  border: 0.4375rem solid white;
  width: 40%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.4244rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;

  &.middle {
    margin-top: 16px;
    margin-bottom: 40px;
    gap: 13px;
  }
`;

const Text = styled(motion.h6)`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #143340;
`;

const RightSection = styled(motion.div)`
  width: 50%;
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8rem;

  h4 {
    font-weight: 700;
    font-size: 31px;
    line-height: 140%;
    color: #181d0b;
    text-align: center;
  }

  p {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  form {
    width: 100%;
  }

  ${mediaObj.smallDesktop} {
    width: 80%;
    margin: auto;
  }
  ${mediaObj.tablet} {
    width: 100%;
    margin: unset;
  }
  ${mediaObj.bigMobile} {
    padding-left: 16px;
    padding-right: 16px;

    h4 {
      font-size: 25px;
    }
  }

  ${mediaQueries.mobile} {
    padding-top: 6rem;
    padding-bottom: 1rem;

    p {
      margin-top: 0;
    }
  }
`;

import useGetCompanyInfo from "app/hooks/utilities/useGetCompanyInfo";
import useUpdateCompanySettings from "app/hooks/utilities/useUpdateCompanySettings";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import InputField from "../components/formik inputs/InputField";
import { CompanySettingsValidation } from "./components/validation";

const CompanySettings = () => {
  const [yearFounded, setYearFounded] = useState("");
  const [virtualStaffMark, setVirtualStaffMark] = useState("");
  const [assessmentDuration, setAssessmentDuration] = useState("");
  const [commissionTax, setCommissionTax] = useState("");
  const [loyaltyDiscount, setLoyaltyDiscount] = useState("");
  const { data, isLoading, isError } = useGetCompanyInfo();
  const { mutate, isLoading: isUpdating } = useUpdateCompanySettings();

  useEffect(() => {
    if (data) {
      const {
        years,
        virtual_staff_assessment_cut_off_mark,
        virtual_staff_assessment_time_limit,
        commission_tax,
        loyalty_discount,
      } = data;

      setYearFounded(years.toString());
      setAssessmentDuration(virtual_staff_assessment_time_limit);
      setVirtualStaffMark(virtual_staff_assessment_cut_off_mark);
      setCommissionTax(commission_tax);
      setLoyaltyDiscount(loyalty_discount);
    }
  }, [data]);

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  const onSubmit = () => {
    const payload = {
      year_founded: +yearFounded,
      virtual_staff_assessment_cut_off_mark: +virtualStaffMark,
      virtual_staff_assessment_time_limit: +assessmentDuration,
      commission_tax: +commissionTax,
      loyalty_discount: +loyaltyDiscount,
    };

    mutate(payload, {
      onSuccess: () => toast.success("Update Successful!"),
    });
  };

  return (
    <Container
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <Formik
        initialValues={{
          yearFounded,
          virtualStaffMark,
          commissionTax,
          loyaltyDiscount,
        }}
        enableReinitialize
        validationSchema={CompanySettingsValidation}
        onSubmit={onSubmit}
      >
        <Form>
          <H6>Company Settings</H6>
          <P>Change company settings here.</P>
          <FormWrapper>
            <InputField
              name="yearFounded"
              label="Year Founded"
              placeholder="0"
              value={yearFounded}
              onChange={(e) => setYearFounded(e.target.value)}
            />
            <InputField
              name="virtualStaffMark"
              label="Virtual staff assessment cut-off mark"
              placeholder="0"
              value={virtualStaffMark}
              onChange={(e) => setVirtualStaffMark(e.target.value)}
            />
            <InputField
              name="assessmentDuration"
              label="Assessment Duration"
              placeholder="Enter duration number in minutes"
              value={assessmentDuration}
              onChange={(e) => setAssessmentDuration(e.target.value)}
            />
            <InputField
              name="commissionTax"
              label="Commission Tax"
              placeholder="0"
              value={commissionTax}
              onChange={(e) => setCommissionTax(e.target.value)}
            />
            <InputField
              name="loyaltyDiscount"
              label="Loyalty Discount"
              placeholder="0"
              value={loyaltyDiscount}
              onChange={(e) => setLoyaltyDiscount(e.target.value)}
            />
          </FormWrapper>
          <SaveBtn type="submit" disabled={isUpdating || isLoading}>
            {isUpdating && <Spinner color="white" />}
            Save Changes
          </SaveBtn>
        </Form>
      </Formik>
    </Container>
  );
};

export default CompanySettings;

const Container = styled(motion.div)`
  & > form {
    width: 60%;
  }
`;

const H6 = styled.div`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 24px;
`;

const P = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin-bottom: 27px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px 62px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  &:disabled {
    pointer-events: none;
    background-color: #d8e8b8;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

import React, { useState } from "react";
import { Backdrop } from "@mui/material";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";
import { OfflineDialogModalValidation } from "../validation";
import useAdminConfirmSales from "app/hooks/analytics/useAdminConfirmSales";
import useAdminRejectSales from "app/hooks/analytics/useAdminRejectSales";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  open: boolean;
  handleClose: () => void;
  type: "accept" | "reject";
  paymentId: number;
}

const SalesOfflineModal: React.FC<Props> = ({
  open,
  handleClose,
  type,
  paymentId,
}) => {
  const [reasonText, setReasonText] = useState("");

  const { mutate: approveMutate, isLoading: isApproving } =
    useAdminConfirmSales();

  const { mutate: rejectMutate, isLoading: isRejecting } =
    useAdminRejectSales();

  const onSuccess = () => {
    toast.success(
      `Sale ${type === "accept" ? "approved" : "rejected"} successfully!`
    );
    handleClose();
  };

  const onApprove = () => {
    const payload = {
      payment_id: paymentId,
    };

    approveMutate(payload, {
      onSuccess,
    });
  };

  const onReject = () => {
    const payload = {
      payment_id: paymentId,
      reason: reasonText,
    };
    rejectMutate(payload, {
      onSuccess,
    });
  };

  const onConfirm = () => {
    if (type === "accept") {
      onApprove();
    }

    if (type === "reject") {
      onReject();
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Formik
          initialValues={{
            reasonText,
          }}
          validationSchema={type === "reject" && OfflineDialogModalValidation}
          enableReinitialize
          onSubmit={onConfirm}
        >
          <Form>
            <Content>
              Are you sure you want to{" "}
              {type === "accept" ? (
                <Accept>confirm</Accept>
              ) : (
                <Reject>decline</Reject>
              )}{" "}
              this sale?
            </Content>
            {type === "reject" && (
              <InputField
                name="reasonText"
                label=""
                value={reasonText}
                placeholder="State reason for declining"
                onChange={(e) => setReasonText(e.target.value)}
                inputType="textarea"
                textareaBg="#F7F6F6"
              />
            )}
            <BtnWrapper type={type} isDisabled={isApproving || isRejecting}>
              <ConfirmBtn type="submit">
                {(isApproving || isRejecting) && <Spinner color="black" />}
                Yes, {type === "accept" ? "Confirm" : "Decline"}{" "}
              </ConfirmBtn>
              <CancelBtn onClick={handleClose} type="button">
                Cancel
              </CancelBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Container>
    </Backdrop>
  );
};

export default SalesOfflineModal;

const Container = styled.div`
  max-width: 50vw;
  min-width: 520px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaQueries.mobile} {
    min-width: unset;
    max-width: 100vw;
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 24px;
`;

const Accept = styled.span`
  color: #afd34f;
`;

const Reject = styled.span`
  color: #b40909;
`;

interface BtnWrapperProps {
  type: "accept" | "reject";
  isDisabled: boolean;
}

const BtnWrapper = styled.div<BtnWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: ${({ type }) => (type === "accept" ? "40px" : "24px")};

  & > button {
    cursor: ${({ isDisabled }) => isDisabled && "not-allowed"};
  }
`;
const ConfirmBtn = styled.button`
  color: #fbfbfb;
  background: #afd34f;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CancelBtn = styled.button`
  color: #2a3313;
  border: 1px solid #2a3313;
  background-color: transparent;
`;

import { SingleOption } from "app/types/common";
import { useField } from "formik";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "../../../../../styles/utils/media";

interface Props {
  name: string;
  label: string;
  value: number;
  selectedValue?: number;
  setSelectedTourDate: React.Dispatch<
    React.SetStateAction<SingleOption | undefined>
  >;
}

const ScheduleBox: React.FC<Props> = ({
  label,
  value,
  selectedValue,
  setSelectedTourDate,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;

  return (
    <SchedulingBox>
      <input
        {...field}
        {...props}
        type="radio"
        id={label}
        className={errorClass}
        value={value}
        checked={selectedValue === value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSelectedTourDate({
            label: label,
            value: +e.target.value,
          })
        }
      />
      <label htmlFor={label} className={errorClass}>
        {label}
      </label>
    </SchedulingBox>
  );
};

export default ScheduleBox;

const SchedulingBox = styled.div`
  background: #e5f3fb;
  border-radius: 12px;
  padding: 4px 16px;
  width: max-content;

  input {
    cursor: pointer;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #000000;
    border-radius: 50%;
    outline: none;

    &.error {
      border-color: red;
    }
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }
  input[type="radio"]:checked:before {
    background: black;
  }

  label {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
    margin-left: 16px;

    &.error {
      color: red;
    }
  }

  ${mediaObj.mobile} {
    width: unset;

    label {
      margin-left: 8px;
    }
  }
`;

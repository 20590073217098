import {
  useAdminGetASingleBankAccount,
  useAdminSaveBankAccount,
  useAdminUpdateBankAccount,
} from "app/hooks/analytics/useBankAccount";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DisplayPaymentQuery } from "../DisplayPayment";
import { CreateBankAccountValidation } from "../validation";
import ModalWrapper from "./ModalWrapper";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CreateBankAccount: React.FC<Props> = ({ open, handleClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const editBank = searchParams.get(DisplayPaymentQuery.edit_bank);
  const [accountTitle, setAccountTitle] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");

  const { mutate: createMutate, isLoading: isCreating } =
    useAdminSaveBankAccount();
  const { mutate: updateMutate, isLoading: isUpdating } =
    useAdminUpdateBankAccount();

  const {
    data: bankData,
    isFetching: bankFetching,
    isError: bankIsError,
  } = useAdminGetASingleBankAccount(Number(editBank));

  const onSuccess = () => {
    toast.success(
      `Bank Account ${editBank ? "Updated" : "Created"} Successfully!`
    );
    if (editBank) {
      searchParams.delete(DisplayPaymentQuery.edit_bank);
      setSearchParams(searchParams);
    }
    handleClose();
  };

  const onSubmitHandler = () => {
    const payload = {
      account_name: accountTitle,
      account_number: accountNo,
      bank: bankName,
    };
    if (editBank) {
      if (!bankData) return;
      const result = {
        ...payload,
        bank_account_id: bankData.id,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editBank && bankData) {
      const { account_name, account_number, bank } = bankData;
      setAccountTitle(account_name);
      setAccountNo(account_number);
      setBankName(bank);
    }
  }, [bankData, editBank]);

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <h6>{editBank ? "Edit" : "Create"} Bank Account</h6>
        <Formik
          initialValues={{
            accountTitle,
            accountNo,
            bankName,
          }}
          validationSchema={CreateBankAccountValidation}
          enableReinitialize
          onSubmit={onSubmitHandler}
        >
          <Form>
            {editBank && bankFetching && (
              <SpinnerWrapper>
                <Spinner color="black" />
              </SpinnerWrapper>
            )}
            {editBank && bankIsError && (
              <SpinnerWrapper>
                <ErrorText>An Error Occured</ErrorText>
              </SpinnerWrapper>
            )}
            {(!editBank || (editBank && !bankIsError && !bankFetching)) && (
              <>
                <Wrapper>
                  <InputField
                    name="accountTitle"
                    label="Account Title"
                    placeholder="Enter your Account Title"
                    value={accountTitle}
                    onChange={(e) => setAccountTitle(e.target.value)}
                    isRequired
                  />
                  <InputField
                    name="accountNo"
                    label="Account Number"
                    placeholder="Enter your Account Number"
                    value={accountNo}
                    onChange={(e) => setAccountNo(e.target.value)}
                    isRequired
                    numberInput="number"
                  />
                  <InputField
                    name="bankName"
                    label="Bank Name"
                    placeholder="Enter your Bank Name"
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)}
                    isRequired
                  />
                </Wrapper>
                <CreateBtn type="submit">
                  {(isCreating || isUpdating) && <Spinner color="black" />}
                  Create Account
                </CreateBtn>
              </>
            )}
          </Form>
        </Formik>
      </Container>
    </ModalWrapper>
  );
};

export default CreateBankAccount;

const Container = styled.div`
  h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 40px;
  }
  & > form {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-start;
  padding: 0.5rem;
`;

const CreateBtn = styled.button`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin: auto;
  margin-top: 40px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

export const SpinnerWrapper = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
`;

export interface SideBarObj {
  link: string;
  title: string;
  icon: any;
  value: string;
}

export interface labelValueType<T = string, V = string> {
  label: T;
  value: V;
}

export type SingleOption = {
  value: number;
  label: string;
};

export interface ProfilePhotoObj {
  id: number;
  filename: string;
  file_type: string;
  url: string;
}

export enum StaffTypeEnum {
  FullTime = "full-staff",
  Virtual = "virtual-staff",
  EStaff = "e-staff",
}

interface RoleObj {
  id: number;
  name: string;
}

export interface StaffTypeObj {
  id: number;
  name: StaffTypeEnum;
  commissionRates: {
    id: number;
    installment: boolean;
    direct: string;
    indirect: string;
  }[];
}

interface RegisteredByObj {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  role: RoleObj;
  staff_type: StaffTypeObj;
  phone_number: string;
  address: string;
  gender: string;
}

interface UserClientsPackagesObj {
  packageItem: {
    package: {
      id: number;
      name: string;
      available_units: string;
    };
  };
}

export interface UserClientsObj {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  address: string;
  profile_photo?: ProfilePhotoObj;
  phone_number: string;
  packages: UserClientsPackagesObj[];
}

interface UserClientSalesObj {
  id: number;
  amount_paid: string;
}

export interface UserResponse {
  id: number;
  title: string;
  firstname: string;
  lastname: string;
  email: string;
  profile_photo?: ProfilePhotoObj;
  role?: RoleObj;
  staff_type: StaffTypeObj;
  phone_number: string;
  address: string;
  gender: string;
  postal_code: string;
  country: string;
  marital_status: string;
  registeredBy: RegisteredByObj;
  clients: UserClientsObj[];
  sales: UserClientSalesObj[];
  commission_balance: string;
  commission: string;
  commission_before_tax: string;
  date_joined: Date | string;
  rating: {
    rating: number;
    hr: {
      id: number;
      email: string;
      firstname: string;
      lastname: string;
      role: {
        id: number;
        name: string;
      };
      staff_type: {
        id: number;
        name: string;
      };
    };
  };
  ratingOpen: boolean;
  canRate: boolean;
  referer_code: string;
  commission_tax: string;
  account_name?: string;
  bank?: string;
  account_number?: string;
}

export enum OfferStatusEnum {
  Rejected = -1,
  Pending,
  Approved,
}

export enum BooleanEnum {
  True = 1,
  False,
}

export enum MonthsValue {
  jan = 1,
  feb,
  mar,
  apr,
  may,
  jun,
  july,
  aug,
  sept,
  oct,
  nov,
  dec,
}

export enum StaffTabEnum {
  StaffList = "staff-list",
  ETeamResult = "e-team",
  Assessment = "assessment",
}

export enum SettingsTabEnum {
  UserProfile = "profile",
  Security = "security",
  Details = "details",
  BankDetails = "bank-details",
}

export enum AdmminSettingsTabEnum {
  AccountSecurity = "accountSecurity",
  Admins = "admins",
  AppSettings = "appSettings",
}

export enum ETeamActionStatus {
  Approve = 1,
  Reject,
}

export enum notificationType {
  GIR = "general inspection request",
  CIR = "custom inspection request",
  ORDER = "order",
  PAYMENT = "payment",
  OFFERPAYMENT = "offer payment",
  OFFER = "offer",
  BID = "bid",
  ASSESSMENT = "assessment",
}

export const whatsAppLink = "https://chat.whatsapp.com/GvCMEGUmvWk649A7GOK4P6";

export enum roles {
  OperationAndAccounting = "operation and accounting",
  ContentManagement = "content management",
  HumanResource = "human resource",
  Logistics = "logistics",
  CustomerRelation = "customer relation",
  Admin = "admin",
  SuperAdmin = "super admin",
}

export enum modules {
  Dashboard = "dashboard",
  Projects = "projects",
  Clients = "clients",
  Staff = "staff",
  SiteTour = "tour",
  Community = "community",
  Analytics = "analytics",
  Admins = "admins",
  Settings = "settings",
  AppSettings = "appSettings",
  AccountSecurity = "accountSecurity",
  Profile = "profile",
}

export const permissions = [
  {
    role: roles.OperationAndAccounting,
    modules: [
      modules.Dashboard,
      modules.Projects,
      modules.Analytics,
      modules.Staff,
      modules.Clients,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.CustomerRelation,
    modules: [
      modules.Dashboard,
      modules.Staff,
      modules.SiteTour,
      modules.Clients,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.HumanResource,
    modules: [
      modules.Dashboard,
      modules.Staff,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.ContentManagement,
    modules: [
      modules.Dashboard,
      modules.Community,
      modules.Projects,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.Logistics,
    modules: [
      modules.Dashboard,
      modules.SiteTour,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.Admin,
    modules: [
      modules.Dashboard,
      modules.Projects,
      modules.Clients,
      modules.Staff,
      modules.SiteTour,
      modules.Community,
      modules.Analytics,
      modules.AppSettings,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
  {
    role: roles.SuperAdmin,
    modules: [
      modules.Dashboard,
      modules.Projects,
      modules.Clients,
      modules.Staff,
      modules.SiteTour,
      modules.Community,
      modules.Analytics,
      modules.Admins,
      modules.AppSettings,
      modules.Settings,
      modules.AccountSecurity,
      modules.Profile,
    ],
  },
];

import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import React, { useRef } from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import { columnDefsDesktop, columnDefsMobile } from "./columnDef";

export interface paymentData {
  id: number;
  amountPaid: number;
  paymentMethod: "online" | "offline";
  status: "pending" | "approved" | "rejected";
  date: string;
}

interface Props {
  data: paymentData[];
}

type TableField = keyof paymentData;

export interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: paymentData[];
}

const PaymentGrid: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const isMobile = useMobileCheck();

  const columnDefs: GridDef[] = isMobile
    ? columnDefsMobile()
    : columnDefsDesktop();

  const GridData: paymentData[] = data;

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  const gridConfig: GridConfigTypes = {
    columnDefs: columnDefs,
    rowData: GridData,
  };

  return (
    <GridWrapper className="ag-theme-alpine">
      <AgGridReact
        rowClass={rowClass}
        getRowClass={getRowClass}
        onGridReady={gridRendered}
        animateRows
        headerHeight={45}
        rowHeight={50}
        columnDefs={gridConfig.columnDefs}
        rowData={gridConfig.rowData}
        defaultColDef={{
          sortable: true,
          filter: false,
        }}
        immutableData
        getRowNodeId={(data: paymentData) => {
          return data.id.toString();
        }}
        gridOptions={{
          onSortChanged(e: AgGridEvent) {
            e.api.refreshCells();
          },
          onFilterChanged(e: AgGridEvent) {
            e.api.refreshCells();
          },
        }}
      />
    </GridWrapper>
  );
};

export default PaymentGrid;

const GridWrapper = styled.div`
  height: 300px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  ${mediaObj.tablet} {
    overflow-x: scroll;
    & > div {
      /* min-width: 400px; */
    }
  }
`;

import { projectObj } from "app/api/project/types";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import DualRing from "app/views/Web/components/loader";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { NoProjectText, SpinnerWrapper, tabProps } from "..";
import AllLocation from "./AllLocation";
import { projectQueryString } from "./data";

interface Props {
  selectedCategory: number;
}

const AllProjects: React.FC<Props> = ({ selectedCategory }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getSearchParams = searchParams.get(projectQueryString.activeProject);
  const {
    data: fetchedProjectsPerCategory,
    isLoading: projectsPerCategoryIsLoading,
  } = useGetAllProjectPerCategory(selectedCategory);

  const [selectedProject, setSelectedProject] = useState<number>();

  useEffect(() => {
    if (
      fetchedProjectsPerCategory &&
      fetchedProjectsPerCategory.length > 0 &&
      !getSearchParams
    ) {
      setSelectedProject(fetchedProjectsPerCategory[0].id);
      searchParams.set(
        projectQueryString.activeProject,
        fetchedProjectsPerCategory[0].name
      );
      setSearchParams(searchParams);
    }
  }, [
    fetchedProjectsPerCategory,
    setSearchParams,
    getSearchParams,
    searchParams,
  ]);

  useEffect(() => {
    if (getSearchParams && fetchedProjectsPerCategory) {
      const result = fetchedProjectsPerCategory.filter(
        (ev) => ev.name === getSearchParams
      );
      setSelectedProject(result[0].id);
    }
  }, [fetchedProjectsPerCategory, getSearchParams]);

  const onTabChange = (x: projectObj) => {
    setSelectedProject(x.id);
    searchParams.set(projectQueryString.activeProject, x.name);
    searchParams.delete(projectQueryString.activeLocation);
    setSearchParams(searchParams);
  };

  if (projectsPerCategoryIsLoading)
    return (
      <SpinnerWrapper>
        <DualRing width="20px" height="20px" color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedProjectsPerCategory && fetchedProjectsPerCategory.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no available project yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      <ProjectTabsWrapper>
        {fetchedProjectsPerCategory &&
          fetchedProjectsPerCategory.map((x, i) => (
            <ProjectTab
              key={i}
              isActive={x.id === selectedProject}
              onClick={() => onTabChange(x)}
            >
              {x.name}
            </ProjectTab>
          ))}
      </ProjectTabsWrapper>
      {selectedProject && <AllLocation selectedProject={selectedProject} />}
    </>
  );
};

export default AllProjects;

const ProjectTabsWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 32px;
  margin-bottom: 1rem;

  @media (max-width: 1300px) {
    justify-content: unset;
    max-width: 100%;
    overflow-x: scroll;
    gap: 8px;
  }
`;

const ProjectTab = styled.span<tabProps>`
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;
  height: 38px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#143340")};
  background: ${({ isActive }) => (isActive ? "#327F9E" : "#cde6f5")};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;

  ${mediaQueries.mobile} {
    width: fit-content;
    font-size: 13px;
    flex-shrink: 0;
  }
`;

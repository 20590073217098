import React, { useEffect, useState } from "react";
import { getAllStates } from "app/api/utilities";
import { SingleOption } from "app/types/common";
import { parseError } from "app/utils";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { useSearchParams } from "react-router-dom";
import { ContentTypes } from "../..";
import CreateWrapper from "../CreateWrapper";
import { CreateEventValidation } from "../validation";
import {
  CreateBtn,
  ErrorText,
  FlexWrapper,
  FormWrapper,
  SpinnerWrapper,
} from "../styles";
import TimePickerField from "app/views/Admin/components/formik inputs/TimePickerField";
import CoverImage from "../CoverImage";
import useGetEventByTitle from "app/hooks/community/useGetEventByTitle";
import useCreateEvent from "app/hooks/community/useCreateEvent";
import useUpdateEvent from "app/hooks/community/useUpdateEvent";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";

const CreateEvent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState<SingleOption>();
  const [address, setAddress] = useState("");
  const [coverImageId, setCoverImageId] = useState<number>();
  const [eventStartDate, setEventStartDate] = useState<Dayjs | null>(null);
  const [eventEndDate, setEventEndDate] = useState<Dayjs | null>(null);
  const [eventStartTime, setEventStartTime] = useState<Dayjs | null>(null);
  const [eventEndTime, setEventEndTime] = useState<Dayjs | null>(null);
  const editTitle = searchParams.get("edit");
  const {
    data: eventData,
    isFetching: eventFetching,
    isError,
  } = useGetEventByTitle(editTitle as string);
  const { mutate: createMutate, isLoading: isPosting } = useCreateEvent();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateEvent();

  const onSuccess = () => {
    toast.success(`Event ${editTitle ? "Updated" : "Created"} Successfully!`);
    setTimeout(() => {
      const y = searchParams.get("activeTab");
      y && setSearchParams({ activeTab: y });
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!coverImageId || !location) return;
    const payload = {
      title,
      file_id: coverImageId,
      description,
      address,
      state_id: location.value,
      start_date: dayjs(eventStartDate).format("YYYY-MM-DD"),
      start_time: dayjs(eventStartTime).format("hh:mm A"),
      end_date: dayjs(eventEndDate).format("YYYY-MM-DD"),
      end_time: dayjs(eventEndTime).format("hh:mm A"),
    };
    if (editTitle) {
      if (!eventData) return;
      const result = {
        ...payload,
        event_id: eventData.id,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editTitle && eventData) {
      const {
        title,
        description,
        cover_photo,
        start_date,
        start_time,
        end_date,
        end_time,
        address,
        location,
      } = eventData;
      setTitle(title);
      setDescription(description);
      setAddress(address);
      const formatted = {
        value: location.id,
        label: location.name,
      };
      setLocation(formatted);
      setCoverImageId(cover_photo.id);
      setEventStartDate(dayjs(start_date));
      setEventStartTime(dayjs(start_time, "hh:mm A"));
      setEventEndDate(dayjs(end_date));
      setEventEndTime(dayjs(end_time, "hh:mm A"));
    }
  }, [eventData, editTitle]);

  const fetchAllStates = async () => {
    try {
      const res = await getAllStates();
      return res.data.data.map((ev) => {
        const temp = {
          value: ev.id,
          label: ev.name,
        };
        return temp;
      });
    } catch (error) {
      parseError(error);
    }
  };

  return (
    <CreateWrapper
      open={createType === ContentTypes.events}
      title={`${editTitle ? "Update" : "Create"}  Event`}
    >
      <Formik
        initialValues={{
          title,
          description,
          location,
          address,
          eventStartDate,
          eventEndDate,
          eventStartTime,
          eventEndTime,
          coverImageId,
        }}
        validationSchema={CreateEventValidation}
        enableReinitialize
        onSubmit={onSubmitHandler}
      >
        <FormWrapper>
          {editTitle && eventFetching && (
            <SpinnerWrapper>
              <Spinner color="black" />
            </SpinnerWrapper>
          )}
          {editTitle && isError && (
            <SpinnerWrapper>
              <ErrorText>An Error Occured</ErrorText>
            </SpinnerWrapper>
          )}
          {(!editTitle || (editTitle && !isError && !eventFetching)) && (
            <Form>
              <InputField
                name="title"
                label="Title"
                placeholder="Type event title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                isRequired
              />
              <InputField
                name="description"
                label="Description"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                inputType="textarea"
                isRequired
              />
              <SelectField
                name="location"
                label="Location"
                value={location as SingleOption}
                onChange={(e) => setLocation(e as SingleOption)}
                fetchOptions={fetchAllStates}
                selectType="async"
                isRequired
              />
              <InputField
                name="address"
                label="Address"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                isRequired
              />
              <FlexWrapper>
                <DatePickerField
                  name="eventStartDate"
                  label="Event starts"
                  placeholder="Select Date"
                  value={eventStartDate}
                  onChange={(e) => setEventStartDate(e)}
                  isRequired
                />
                <TimePickerField
                  name="eventStartTime"
                  label=""
                  placeholder="Select Time"
                  value={eventStartTime}
                  onChange={(e) => setEventStartTime(e)}
                />
              </FlexWrapper>
              <FlexWrapper>
                <DatePickerField
                  name="eventEndDate"
                  label="Event ends"
                  placeholder="Select Date"
                  value={eventEndDate}
                  onChange={(e) => setEventEndDate(e)}
                  isRequired
                />
                <TimePickerField
                  name="eventEndTime"
                  label=""
                  placeholder="Select Time"
                  value={eventEndTime}
                  onChange={(e) => setEventEndTime(e)}
                />
              </FlexWrapper>

              <CoverImage
                setStateFn={setCoverImageId}
                value={
                  editTitle && eventData
                    ? eventData?.cover_photo.url
                    : undefined
                }
              />

              <CreateBtn type="submit">
                {(isPosting || isUpdating) && <Spinner color="black" />}
                {editTitle ? "Update" : "Create"}
              </CreateBtn>
            </Form>
          )}
        </FormWrapper>
      </Formik>
    </CreateWrapper>
  );
};

export default CreateEvent;

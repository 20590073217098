import { useQuery } from "@tanstack/react-query";
import { getTotalClients } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetTotalClients = () => {
  return useQuery([UtilitiesKeys.getTotalClients], getTotalClients, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetTotalClients;

import { FC } from "react";
import styled from "styled-components";

const getRandomColor = (name: string = ""): string => {
  const firstAlphabet: string = name?.charAt(0).toLowerCase();
  const lastAlphabet: string = name?.charAt(name.length - 1).toLowerCase();
  const midAlphaber: string = name
    ?.charAt(Math.ceil(name.length / 2))
    .toLowerCase();

  const asciiCode: number = firstAlphabet?.charCodeAt(0);
  const asciiCodeMid: number = midAlphaber?.charCodeAt(0);
  const asciiCodeLast: number = lastAlphabet?.charCodeAt(0);

  const colorNum: string =
    asciiCodeLast?.toString() +
    asciiCode?.toString() +
    asciiCodeMid?.toString();

  var num: number = Math.round(0x1f1111 * parseInt(colorNum));
  var r: number = (num >> 16) & 255;
  var g: number = (num >> 8) & 255;
  var b: number = num & 255;

  return "rgb(" + r + ", " + g + ", " + b + ", 1)";
};

const getInitials = (name: string) => {
  const nameArray = name.split(" ");
  return nameArray.length === 1
    ? `${nameArray[0].charAt(0).toLowerCase()}${nameArray[0]
        .charAt(1)
        .toLowerCase()}`
    : `${nameArray[0].charAt(0).toLowerCase()}${nameArray[1]
        .charAt(0)
        .toLowerCase()}`;
};

interface props {
  name: string;
  width?: string;
  height?: string;
  fontSize?: string;
}

const NameAvatar: FC<props> = ({ name, height, width, fontSize }) => {
  return (
    <Wrapper width={width} height={height} name={name} fontSize={fontSize}>
      {getInitials(name)}
    </Wrapper>
  );
};

export default NameAvatar;

const Wrapper = styled.div<{
  width?: string;
  height?: string;
  name?: string;
  fontSize?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ width }) => (width ? width : "24px")};
  height: ${({ height }) => (height ? height : "24px")};
  border-radius: 50%;
  color: #fff;
  background: ${({ name }) => getRandomColor(name)};
  text-transform: uppercase;
  font-size: ${({ fontSize }) => fontSize && fontSize};
`;

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import P from "../../../../../styles/elements/P";
import media from "../../../../../styles/utils/media";
import { ReactComponent as ImgLogo } from "../../../../images/web/Adbond Logo 1.svg";
import NavLink from "./navLink";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import TemporaryDrawer from "./Drawer";
import DashboardIcon from "@mui/icons-material/Dashboard";
import useAuth from "app/hooks/auth/useAuth";
import { useAppSelector } from "app/redux";
import { getDashboardUrl } from "app/redux/dashboard/dashboardSlice";
import useScrollHandler from "app/hooks/useScrollHandler";
import mediaQueries from "styles/utils/mediaQueries";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const isLoggedIn = useAuth();
  const { scrolledDown, scrollingUp } = useScrollHandler();
  const dashboardUrl = useAppSelector(getDashboardUrl).dashboardUrl;

  return (
    <Container leftTop={scrolledDown} scrollUp={scrollingUp}>
      <ContentWrapper>
        <LogoWrapper>
          <Link to="/">
            <ImgLogo />
          </Link>
        </LogoWrapper>
        {/* smaller screen drawer  */}
        <div className="menu" onClick={() => setIsOpen((prev) => !prev)}>
          <MenuIcon />
        </div>
        <TemporaryDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        {/*  */}
        <ListContainer>
          <NavLink label="Our project" url="project" />
          <NavLink label="About us" url="about" />
          <NavLink label="Schedule a site tour" url="schedule-a-tour" />
          <NavLink label="Community" url="community" />
        </ListContainer>
        {isLoggedIn && dashboardUrl ? (
          <DashboardWrapper>
            <Link to={dashboardUrl}>
              <DashboardIcon />
              Dashboard
            </Link>
          </DashboardWrapper>
        ) : (
          <ButtonGroup>
            <Link to={"/signup"}>
              <SignButton>
                <P bodybutton>Sign up</P>
              </SignButton>
            </Link>
            <Link to={"/login"}>
              <P bodybutton className="btn">
                Login
              </P>
            </Link>
          </ButtonGroup>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Header;

interface props {
  scrollUp?: boolean;
  leftTop?: boolean;
}

const Container = styled.div<props>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ leftTop }) => (leftTop ? `#fbfbfb` : `transparent`)};
  box-shadow: ${({ leftTop }) =>
    leftTop ? `0 10px 15px -3px rgba(0, 0, 0, 0.1)` : `none`};
  z-index: 1000;
  transition: all 300ms ease-in-out;

  ${(props) =>
    props.scrollUp &&
    css`
      transform: translateY(-96px);
      box-shadow: none;
    `}

  ${mediaQueries.mobile} {
    background-color: #fbfbfb;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 128px;
  padding: 10px 0;
  align-items: center;
  width: clamp(1200px, 90%, 1500px);
  margin: auto;

  .menu {
    display: none;
    cursor: pointer;

    ${media.smallDesktop`
      display:block
      `}
  }

  ${media.mediumDesktop`
  width: 100%;
     gap: 12px;
     padding-right: 1rem;
     `}

  ${media.smallDesktop`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 0 0.5rem;
        `}

  ${mediaQueries.mobile} {
    height: 50px;
    width: 100%;
    padding: 0 12px 0 0;
  }
`;

const LogoWrapper = styled.div`
  width: 153px;
  height: 56px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${mediaQueries.mobile} {
    width: 145px;
    height: 45px;
    margin-top: -4px;
  }
`;

const ListContainer = styled.div`
  display: flex;
  gap: 48px;
  margin-left: auto;

  ${media.mediumDesktop`
  margin: auto;
        `}
  ${media.smallDesktop`
  display:none
        `}
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
  ${media.smallDesktop`
  display:none
        `}

  & > button,
  .btn {
    cursor: pointer;
  }
  & > button {
    color: white;
  }
  .btn {
    color: #1b1b1b;
  }
`;

const SignButton = styled.button`
  padding: 10px 24px;
  color: white;
  border: none;
  border-radius: 8px;
  background: #afd34f;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const DashboardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: black;

  svg {
    color: #afd34f;
  }

  a {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  ${media.smallDesktop`
  display:none
        `}
`;

import { UserClientsObj } from "app/types/common";
import styled from "styled-components";

const PackageRenderer = ({ data }: { data: UserClientsObj }) => {
  const { packages } = data;
  const gridPackages = packages?.map((x) => x.packageItem.package.name) || [];
  const isEmpty = gridPackages.length === 0;
  const countLeft = !isEmpty && gridPackages.length - 1;

  return (
    <Container>
      <Item isEmpty={isEmpty}>{isEmpty ? "None" : gridPackages[0]}</Item>
      {!!countLeft && <Count>{`+${countLeft}`}</Count>}
    </Container>
  );
};

export default PackageRenderer;

const Container = styled.div`
  display: flex;
  gap: 4px;
  min-height: 50px;
  height: max-content;
`;

interface itemProps {
  isEmpty?: boolean;
}

const Item = styled.div<itemProps>`
  display: flex;
  align-items: center;
  padding: 4px 14px;
  font-weight: 450;
  font-size: 13px;
  color: ${({ isEmpty }) => (isEmpty ? "#181D0B" : "#fbfbfb")};
  height: 26px;
  background: ${({ isEmpty }) => !isEmpty && "#97b644"};
  border-radius: 10px;
  height: 40px;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-weight: 450;
  font-size: 10px;
  color: #2a3313;
  background: #d8e8b8;
  border-radius: 50%;
`;

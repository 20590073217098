import { locationObj } from "app/api/project/types";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import DualRing from "app/views/Web/components/loader";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { NoProjectText, SpinnerWrapper, tabProps } from "..";
import AllPackage from "./AllPackage";
import { projectQueryString } from "./data";

interface Props {
  selectedProject: number;
}

const AllLocation: React.FC<Props> = ({ selectedProject }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getSearchParams = searchParams.get(projectQueryString.activeLocation);
  const {
    data: fetchedLocationPerProject,
    isLoading: locationPerProjectIsLoading,
  } = useGetAllLocationPerProject(selectedProject);

  const [selectedLocation, setSelectedLocation] = useState<number>();

  useEffect(() => {
    if (
      fetchedLocationPerProject &&
      fetchedLocationPerProject.length > 0 &&
      !getSearchParams
    ) {
      setSelectedLocation(fetchedLocationPerProject[0].id);
      searchParams.set(
        projectQueryString.activeLocation,
        fetchedLocationPerProject[0].location
      );
      setSearchParams(searchParams);
    }
  }, [
    fetchedLocationPerProject,
    setSearchParams,
    getSearchParams,
    searchParams,
  ]);

  useEffect(() => {
    if (getSearchParams && fetchedLocationPerProject) {
      const result = fetchedLocationPerProject.filter(
        (ev) => ev.location === getSearchParams
      );
      setSelectedLocation(result[0].id);
    }
  }, [fetchedLocationPerProject, getSearchParams]);

  const onTabChange = (x: locationObj) => {
    setSelectedLocation(x.id);
    searchParams.set(projectQueryString.activeLocation, x.location);
    setSearchParams(searchParams);
  };

  if (locationPerProjectIsLoading)
    return (
      <SpinnerWrapper>
        <DualRing width="20px" height="20px" color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedLocationPerProject && fetchedLocationPerProject.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>No available location for this project</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {fetchedLocationPerProject && fetchedLocationPerProject.length > 1 && (
        <LocationTabsWrapper>
          {fetchedLocationPerProject.map((x, i) => (
            <LocationTab
              key={i}
              isActive={x.id === selectedLocation}
              onClick={() => onTabChange(x)}
            >
              {capitalize(x.location)}
            </LocationTab>
          ))}
        </LocationTabsWrapper>
      )}
      {selectedLocation && <AllPackage selectedLocation={selectedLocation} />}
    </>
  );
};

export default AllLocation;

const LocationTabsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  margin-bottom: 1rem;
`;

const LocationTab = styled.span<tabProps>`
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#28647D" : "#909090")};
  border-bottom: ${({ isActive }) => (isActive ? "4px solid #AFD34F" : "none")};
  cursor: pointer;
  transition: all 0.2s ease-in;
`;

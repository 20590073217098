import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import ActionRenderer from "./actionRenderer";
import DualRing from "app/views/Web/components/loader";
import { eTeamResult } from "app/api/staff/types";

interface GridProps {
  data: eTeamResult[];
  isLoading?: boolean;
}

type ETeamField = keyof eTeamResult | undefined | "action";

export interface GridDef extends ColDef {
  field: ETeamField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: eTeamResult[];
}

const ETeamGrid: React.FC<GridProps> = ({ data, isLoading }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: eTeamResult[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Name",
      field: "firstname",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
      },
      flex: 0.8,
      valueFormatter: (params) => {
        const { data }: { data: eTeamResult } = params;
        return `${data.firstname} ${data.lastname}`;
      },
      wrapText: true,
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.1,
      cellStyle: {
        ...gridCellStyle,
        color: "#28647D",
        paddingRight: "15px",
      },
      wrapText: true,
    },
    {
      headerName: "Gender",
      sortable: false,
      field: "gender",
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
    },
    {
      headerName: "Address",
      sortable: false,
      field: "address",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      // valueFormatter: (params) => {
      //   const { data }: { data: eTeamResult } = params;
      //   return data.address.length > 45
      //     ? `${data.address.substring(0, 45)}...`
      //     : data.address;
      // },
    },
    {
      headerName: "Phone Number",
      sortable: false,
      field: "phone_number",
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: eTeamResult } = params;
        return data.phone_number.split(" ").join("");
      },
    },
    {
      headerName: "Grade",
      sortable: false,
      field: "score",
      flex: 0.4,
      cellStyle: {
        ...gridCellStyle,
      },
    },
    {
      sortable: false,
      field: "action",
      flex: 0.2,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <>
      {isLoading ? (
        <MainLoaderWrapper height="545px">
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : GridData?.length > 0 ? (
        <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-alphine`}>
          <AgGridReact
            onGridReady={gridRendered}
            animateRows
            headerHeight={50}
            rowHeight={50}
            columnDefs={gridConfig.columnDefs}
            rowData={gridConfig.rowData}
            suppressRowTransform
            defaultColDef={{
              sortable: true,
              filter: false,
              cellStyle: {
                ...gridCellStyle,
              },
            }}
            immutableData
            getRowNodeId={(data: eTeamResult) => {
              return data.id.toString();
            }}
            gridOptions={{
              onSortChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
              onFilterChanged(e: AgGridEvent) {
                e.api.refreshCells();
              },
            }}
          />
        </GridWrapper>
      ) : (
        <EmptyWrapper>
          <p>No records found</p>
        </EmptyWrapper>
      )}
    </>
  );
};

export default ETeamGrid;

interface loaderProps {
  height?: string;
}

export const MainLoaderWrapper = styled.div<loaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "300px")};
`;

const isSmallerScreen = window.matchMedia("(max-width: 1400px)").matches;

const gridCellStyle = isSmallerScreen
  ? {
      display: "flex",
      "align-items": "center",
      "font-weight": "600",
      "letter-spacing": "-0.02em",
      "font-size": "12px",
      "line-height": "16px",
      padding: "0 0.5rem",
    }
  : {
      display: "flex",
      "align-items": "center",
      "font-weight": "600",
      "letter-spacing": "-0.02em",
      "font-size": "14px",
      "line-height": "18px",
      padding: "0 0.5rem",
    };

const GridWrapper = styled.div`
  height: 420px;
  width: 100%;
  margin: 40px 0 10px;
  overflow-y: scroll;
  padding-right: 0.5rem;

  & *:not(img) {
    overflow: visible !important;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 130px;
  height: 300px;
  margin-bottom: 10px;

  & > p {
    letter-spacing: 0px;
    color: #faa84f;
    font-size: 20px;
    font-weight: bold;
  }
`;

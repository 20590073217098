import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import ETeamGrid from "../components/eTeamGrid";
import useGetAssessmentResults from "app/hooks/users/useGetAssessmentResult";
import CutOffMarkModal from "../components/modals/cutOffMark";
import Paginating from "../../components/Pagination";
import { eTeamResult } from "app/api/staff/types";

const ETeamResult = () => {
  const { data, isLoading, error } = useGetAssessmentResults();
  const [isCutOffModalOpen, setIsCutOffModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataToDisplay, setDataToDisplay] = useState<eTeamResult[]>([]);

  const size = 7;
  const total = data?.length || 0;
  const pageCount = data && Math.floor(total / size) + 1;
  const startCount = size * (currentPage - 1) + 1;
  const endCount = size * (currentPage - 1) + size;

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    data &&
      setDataToDisplay(
        data?.slice((currentPage - 1) * size, currentPage * size)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, data]);

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <CreateBtn onClick={() => setIsCutOffModalOpen(true)}>
          <EditIcon />
          Set CutOff Mark
        </CreateBtn>
      </Box>
      <ETeamGrid data={dataToDisplay} isLoading={isLoading} />
      {data && (
        <Footer>
          <Show>
            Showing {startCount} to{" "}
            {total && endCount < total ? endCount : total} of {total}
          </Show>

          <Paginating
            totalElements={total}
            size={size}
            pageCount={pageCount}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </Footer>
      )}
      <CutOffMarkModal
        handleClose={() => setIsCutOffModalOpen(false)}
        isOpen={isCutOffModalOpen}
      />
    </>
  );
};

export default ETeamResult;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 188px;
  height: 44px;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

import { FC } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";
import { SiteInspectionResponse } from "app/api/project/types";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  data: SiteInspectionResponse;
  isCustom?: boolean;
}

const TourCard: FC<Props> = ({ data, isCustom }) => {
  return (
    <Wrapper>
      {isCustom && <CustomTag>Custom</CustomTag>}
      <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
        <EntryLabel>Schedule:</EntryLabel>
        <EntryDetails>{data.proposed_date}</EntryDetails>
      </Box>
      <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
        <EntryLabel>Property:</EntryLabel>
        <EntryDetails>{data.project}</EntryDetails>
      </Box>
      <Box display={"flex"} alignItems={"flex-start"} gap={"8px"}>
        <EntryLabel>Location:</EntryLabel>
        <EntryDetails>
          {data.location}
          {/* {", " + data.address} */}
        </EntryDetails>
      </Box>
    </Wrapper>
  );
};

export default TourCard;

const Wrapper = styled.div<{ isSelected?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 45px 16px 16px;
  gap: 16px;
  width: 362px;
  background: ${({ isSelected }) => (isSelected ? "#cde6f5" : "#FFF")};
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  transition: all 0.2s ease-in;
  text-transform: capitalize;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

const CustomTag = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #afd34f;
  font-size: 0.75rem;
  font-weight: 450;
  line-height: 140%;
`;

const EntryLabel = styled.span`
  font-weight: 450;
  font-size: 13px;
  line-height: 140%;
  color: #3d9ac0;
  flex-shrink: 0;
  width: 55px;
`;

const EntryDetails = styled.span`
  font-weight: 450;
  font-size: 13px;
  line-height: 140%;
  color: #181d0b;
`;

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/redux";
import { getAuth, setUser } from "app/redux/auth/authSlice";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { ReactComponent as EditIcon } from "../../../images/admin/edit-icon.svg";
import { EditProfileValidation } from "./components/validation";
import useAdminEditUser from "app/hooks/users/useAdminEditUser";
import { toast } from "react-toastify";
import { genderData, GenderProps } from "./data";
import Spinner from "app/views/Web/components/Spinner";
import { capitalize } from "lodash";
import { UserResponse } from "app/types/common";
import { domainUrl } from "app/utils/data";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AddImage from "app/views/Admin/components/AddImage";
import NameAvatar from "app/views/Admin/components/nameAvatar";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { motion } from "framer-motion";

const UserProfile = () => {
  const dispatch = useAppDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState<GenderProps>();
  const [profileImageId, setProfileImageId] = useState<number>();
  const [isCopied, setIsCopied] = useState(false);
  const [isCopiedLink, setIsCopiedLink] = useState(false);
  const user = useAppSelector(getAuth).user as UserResponse;

  const { mutate: updateMutate, isLoading: isUpdating } = useAdminEditUser();

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname);
      setLastName(user.lastname);
      setEmail(user.email);
      setPhone(user.phone_number);
      if (user.gender === "male" || user.gender === "female") {
        const temp: GenderProps = {
          label: capitalize(user.gender),
          value: user.gender,
        };
        setGender(temp);
      }
      if (user.profile_photo) setProfileImageId(user.profile_photo.id);
    }
  }, [user]);

  const handleCopy = (type: "link" | "code") => {
    if (type === "code") {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } else {
      setIsCopiedLink(true);
      setTimeout(() => {
        setIsCopiedLink(false);
      }, 2000);
    }
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          phone,
          profileImageId,
        }}
        validationSchema={EditProfileValidation}
        enableReinitialize
        onSubmit={() => {
          if (!user) return;
          const payload = {
            user_id: user.id,
            firstname: firstName,
            lastname: lastName,
            phone_number: phone,
            file_id: profileImageId,
            gender: gender?.value,
            // role_id: (user as UserResponse).role.id,
          };

          const onSuccess = (data: any) => {
            toast.success(`User Updated Successfully!`);
            setIsEditable(false);
            dispatch(setUser(data.data.data));
          };

          updateMutate(payload, {
            onSuccess,
          });
        }}
      >
        <Form>
          <H1>Users</H1>
          <ProfileCard>
            <Header>
              <h5>Profile details</h5>

              <BtnWrapper>
                {isEditable && (
                  <EditBox onClick={() => setIsEditable(false)}>Cancel</EditBox>
                )}
                {isEditable ? (
                  <SaveBtn type="submit">
                    {isUpdating && <Spinner color="black" />}
                    Save Changes
                  </SaveBtn>
                ) : (
                  <EditBox onClick={() => setIsEditable(true)}>
                    <EditIcon />
                    Edit
                  </EditBox>
                )}
              </BtnWrapper>
            </Header>

            <Content>
              {isEditable ? (
                <AddImage
                  name="profileImageId"
                  text="Add image"
                  handleFileId={(id) => setProfileImageId(id)}
                  width="116px"
                  height="116px"
                  iconWidth="20px"
                  iconHeight="20px"
                  borderRadius="100%"
                  value={
                    user && user.profile_photo
                      ? user.profile_photo.url
                      : undefined
                  }
                />
              ) : user?.profile_photo ? (
                <ImgWrapper>
                  <img src={user?.profile_photo?.url} alt="profile" />
                </ImgWrapper>
              ) : (
                <NameAvatar
                  name={user?.firstname + " " + user?.lastname}
                  width="116px"
                  height="116px"
                  fontSize="1.8rem"
                />
              )}

              <div>
                <FlexWrapper>
                  <BlueTag>First name</BlueTag>
                  <InputBox isEditable={isEditable}>
                    <InputField
                      name="firstName"
                      label=""
                      placeholder=""
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </InputBox>
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Last name</BlueTag>
                  <InputBox isEditable={isEditable}>
                    <InputField
                      name="lastName"
                      label=""
                      placeholder=""
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                    />
                  </InputBox>
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Email Address</BlueTag>
                  <span>{user?.email}</span>
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Gender</BlueTag>
                  {isEditable ? (
                    <InputBox isEditable={isEditable}>
                      <SelectField
                        name="gender"
                        label=""
                        value={gender as GenderProps}
                        onChange={(e) => setGender(e as GenderProps)}
                        options={genderData}
                        selectType="normal"
                        height={40}
                      />
                    </InputBox>
                  ) : (
                    <span>{user?.gender}</span>
                  )}
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Phone number</BlueTag>
                  <InputBox isEditable={isEditable}>
                    <InputField
                      label=""
                      name="phone"
                      placeholder=""
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                  </InputBox>
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Referral code</BlueTag>
                  <span>{user?.referer_code}</span>
                  <CopyToClipboard text={user?.referer_code}>
                    <CopyWrapper onClick={() => handleCopy("code")}>
                      <ContentCopyIcon />
                      <CopyText>{isCopied ? "Copied" : "Copy"}</CopyText>
                    </CopyWrapper>
                  </CopyToClipboard>
                </FlexWrapper>
                <FlexWrapper>
                  <BlueTag>Referral link</BlueTag>
                  <span>{`${domainUrl}?ref=${user?.referer_code}`}</span>
                  <CopyToClipboard
                    text={`${domainUrl}?ref=${user?.referer_code}`}
                  >
                    <CopyWrapper onClick={() => handleCopy("link")}>
                      <ContentCopyIcon />
                      <CopyText>{isCopiedLink ? "Copied" : "Copy"}</CopyText>
                    </CopyWrapper>
                  </CopyToClipboard>
                </FlexWrapper>
              </div>
            </Content>
          </ProfileCard>
        </Form>
      </Formik>
    </motion.div>
  );
};

export default UserProfile;

const H1 = styled.div`
  font-weight: 450;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 52px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  h6 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    color: #000000;
    margin-bottom: 52px;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const ProfileCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 20px;
  padding: 24px;

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

const Content = styled.div`
  display: flex;
  gap: 82px;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
  span {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
  }

  svg {
    fill: #afd34f;
    width: 14px;
    height: 16px;
  }
`;

// const Role = styled.span`
//   font-weight: 450;
//   font-size: 1rem;
//   line-height: 140%;
//   color: #2a3313;
//   text-transform: capitalize;
// `;

const ImgWrapper = styled.div`
  width: 116px;
  height: 116px;
  border-radius: 100%;
  border: 2px solid #afd34f;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
`;

const BlueTag = styled.div`
  background: #49b7e4;
  border-radius: 4px;
  color: white;
  padding: 4px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
`;

interface InputBoxProps {
  isEditable: boolean;
}

const InputBox = styled.div<InputBoxProps>`
  label {
    display: none;
  }
  input {
    border: ${({ isEditable }) => !isEditable && "none"};
    padding-left: ${({ isEditable }) => !isEditable && "0"};
    height: 2rem;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
  }
`;

const EditBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
  svg {
    fill: #afd34f;
  }
  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
    & > svg {
      fill: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-left: 15px;
  cursor: pointer;
`;

const CopyText = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #afd34f;
`;

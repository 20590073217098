import * as Yup from "yup";

export const PersonalInfoValidation = Yup.object({
  firstname: Yup.string().required("Required"),
  lastname: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  title: Yup.string().required("Required").nullable(),
  gender: Yup.string().required("Required").nullable(),
  phone_number: Yup.string()
    .required("Phone number is required")
    .test("min_phonenumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  address: Yup.string().required("Required").nullable(),
  postal_code: Yup.string().required("Required").nullable(),
  marital_status: Yup.string().required("Required").nullable(),
  employment_status: Yup.string().required("Required").nullable(),
  occupation: Yup.string().required("Required").nullable(),
  age_group_id: Yup.string().required("Required").nullable(),
});

export const NokInfoValidation = Yup.object({
  kin_firstname: Yup.string().required("Required"),
  kin_lastname: Yup.string().required("Required"),
  kin_gender: Yup.string().required("Required"),
  kin_phone_number: Yup.string()
    .required("Phone number is required")
    .test("min_phonenumber", "Invalid Phone number", function (value) {
      return value?.split(" ").length === 1 ? false : true;
    }),
  kin_relationship: Yup.string().required("Required"),
  kin_address: Yup.string().required("Required"),
});

export const PasswordChangeValidation = Yup.object({
  currentPassword: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .label("current password"),
  newPassword: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .label("new password")
    .notOneOf([Yup.ref("currentPassword")], "passwords must be different"),
  confirmNewPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
});

export const PasswordRecoveryValidation = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

export const PasswordResetValidation = Yup.object({
  newPassword: Yup.string()
    .required("Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    )
    .label("new password"),
  confirmNewPassword: Yup.string()
    .required("Please confirm your password.")
    .oneOf([Yup.ref("newPassword")], "Your passwords do not match."),
});

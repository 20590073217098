import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { adminGetOfferPaymentsByCategoryResponse } from "app/api/payment/types";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import SalesDetailModal from "../modals/salesDetail";
import SalesOfflineModal from "../modals/SalesOfflineModal";
import TransferModal from "../modals/transfer";
import PaymentRenderer from "./paymentRenderer";
import SalesRenderer from "./SalesRenderer";
import StatusRenderer from "./StatusRenderer";

type TableField = keyof adminGetOfferPaymentsByCategoryResponse | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: adminGetOfferPaymentsByCategoryResponse[];
}

interface Props {
  data: adminGetOfferPaymentsByCategoryResponse[];
}

type DialogProps = {
  openStatus: boolean;
  type: "accept" | "reject";
  payment_id: number;
};

const SalesTable = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: adminGetOfferPaymentsByCategoryResponse[] = data;
  const [isOpenTransferModal, setIsOpenTransferModal] =
    useState<adminGetOfferPaymentsByCategoryResponse>();
  const [isOpenDetailsModal, setIsOpenDetailsModal] =
    useState<adminGetOfferPaymentsByCategoryResponse>();
  const handleOpenTransferModal = (
    data: adminGetOfferPaymentsByCategoryResponse
  ) => setIsOpenTransferModal(data);
  const handleOpenDetailsModal = (
    data: adminGetOfferPaymentsByCategoryResponse
  ) => setIsOpenDetailsModal(data);
  const [openDialog, setOpenDialog] = useState<DialogProps>();
  const handleDialogClose = () => setOpenDialog(undefined);

  const onApprove = (data: adminGetOfferPaymentsByCategoryResponse) => {
    setOpenDialog({
      openStatus: true,
      type: "accept",
      payment_id: data.id,
    });
  };

  const onDecline = (data: adminGetOfferPaymentsByCategoryResponse) => {
    setOpenDialog({
      openStatus: true,
      type: "reject",
      payment_id: data.id,
    });
  };

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Owner's Name",
      field: "owner",
      sortable: false,
      flex: 1,
      cellStyle: {
        color: "#97B644",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetOfferPaymentsByCategoryResponse } =
          params;
        return data.owner.firstname + " " + data.owner.lastname;
      },
      wrapText: true,
    },
    {
      headerName: "Package Type",
      field: "package",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
    },
    {
      headerName: "Buyer's Name",
      field: "buyer",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#143340",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetOfferPaymentsByCategoryResponse } =
          params;
        return data.buyer.firstname + " " + data.buyer.lastname;
      },
      wrapText: true,
    },
    {
      headerName: "Amount Paid",
      field: "amount",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetOfferPaymentsByCategoryResponse } =
          params;
        return `N ${Number(data.amount).toLocaleString("en-US")}`;
      },
      wrapText: true,
    },
    {
      headerName: "Channel",
      field: "payment_mode",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: PaymentRenderer,
    },
    {
      headerName: "Status",
      field: "confirmed",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Date",
      field: "created_at",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetOfferPaymentsByCategoryResponse } =
          params;
        return new Intl.DateTimeFormat("en-US").format(
          new Date(data.created_at)
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: SalesRenderer,
      cellRendererParams: {
        handleOpenDetailsModal: handleOpenDetailsModal,
        onApprove: onApprove,
        onDecline: onDecline,
        handleOpenTransferModal: handleOpenTransferModal,
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <div ref={ref}>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowClass={rowClass}
          getRowClass={getRowClass}
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          rowHeight={60}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          immutableData
          getRowNodeId={(data: adminGetOfferPaymentsByCategoryResponse) => {
            return data.id.toString();
          }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
      {isOpenTransferModal && (
        <TransferModal
          open={!!isOpenTransferModal}
          data={isOpenTransferModal}
          handleClose={() => setIsOpenTransferModal(undefined)}
        />
      )}

      {isOpenDetailsModal && (
        <SalesDetailModal
          open={!!isOpenDetailsModal}
          data={isOpenDetailsModal}
          handleClose={() => setIsOpenDetailsModal(undefined)}
        />
      )}

      {openDialog && (
        <SalesOfflineModal
          open={openDialog.openStatus}
          handleClose={handleDialogClose}
          type={openDialog.type}
          paymentId={openDialog.payment_id}
        />
      )}
    </div>
  );
});

export default SalesTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  min-height: 350px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: max-content;
  }

  & * {
    overflow: visible !important;
  }
`;

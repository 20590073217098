import { useQuery } from "@tanstack/react-query";
import { clientGetForum, getForum } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useAdminGetForums = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.adminGetForums, page],
    () => getForum(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetForums;

export const useClientGetForums = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.clientGetForums, page],
    () => clientGetForum(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

import { useQuery } from "@tanstack/react-query";
import { downloadPackageBrochure } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";
import FileDownload from "js-file-download";

const useDownloadPackageBrochure = (packageId: string) => {
  return useQuery(
    [ProjectsKey.downloadPackageBrochure, packageId],
    () => downloadPackageBrochure(packageId),
    {
      enabled: false,
      onError: (error) => parseError(error),
      onSuccess: (data) => {
        FileDownload(data.data, `brochure.pdf`);
      },
    }
  );
};

export default useDownloadPackageBrochure;

import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import styled from "styled-components";
import useAdminGetAllStaff from "app/hooks/users/useAdminGetAllUsers";
import { toast } from "react-toastify";
import StaffGrid from "../components/grid";
import { useEffect, useState } from "react";
import Paginating from "../../components/Pagination";

const StaffList = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: userData,
    isLoading,
    error,
  } = useAdminGetAllStaff(currentPage, 7);
  const size = 7;
  // const pageCount = userData && Math.floor(userData.total / size) + 1;
  const startCount = size * (currentPage - 1) + 1;
  const endCount = size * (currentPage - 1) + size;

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <CreateBtn onClick={() => navigate("new")}>
          <AddIcon />
          Add a new staff
        </CreateBtn>
      </Box>
      <StaffGrid
        data={userData?.data?.slice(0, 7) || []}
        isLoading={isLoading}
      />
      {userData?.data && (
        <Footer>
          <Show>
            Showing {startCount} to{" "}
            {userData?.total && endCount < userData?.total
              ? endCount
              : userData?.total}{" "}
            of {userData?.total}
          </Show>

          <Paginating
            totalElements={userData?.total || 0}
            size={size}
            pageCount={3}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </Footer>
      )}
    </>
  );
};

export default StaffList;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 188px;
  height: 44px;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

import { useQuery } from "@tanstack/react-query";
import { getPaginatedAllClients } from "app/api/client";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const useGetPaginatedAllClients = (
  page: number,
  perPage: number,
  isActivePage: boolean
) => {
  return useQuery(
    [siteTourKeys.getPaginatedAllClients, page, perPage],
    () => getPaginatedAllClients(page, perPage),
    {
      enabled: !!isActivePage,
      keepPreviousData: true,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetPaginatedAllClients;

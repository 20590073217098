import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminTransferPackage } from "app/api/analytics";
import { parseError } from "app/utils";
import { PaymentQueryKeys } from "../payment/types";

const useAdminTransferPackage = () => {
  const queryClient = useQueryClient();

  return useMutation(adminTransferPackage, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([
        PaymentQueryKeys.adminGetOfferPaymentsByCategory,
      ]);
    },
  });
};

export default useAdminTransferPackage;

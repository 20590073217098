import React, { useState } from "react";
import { Backdrop } from "@mui/material";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { Form, Formik } from "formik";
import styled from "styled-components";
import { DialogModalValidation } from "../validation";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (message: string) => void;
}

const DialogModal: React.FC<Props> = ({ open, handleClose, onSubmit }) => {
  const [reasonText, setReasonText] = useState("");
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Formik
          initialValues={{
            reasonText,
          }}
          validationSchema={DialogModalValidation}
          enableReinitialize
          onSubmit={() => {
            onSubmit(reasonText);
            handleClose();
          }}
        >
          <Form>
            <Content>
              Clients are booked to this schedule.
              <br /> Are you sure you want to <Reject>delete</Reject> this
              schedule
            </Content>
            <InputField
              name="reasonText"
              label=""
              value={reasonText}
              placeholder="State reason for deleting this schedule"
              onChange={(e) => setReasonText(e.target.value)}
              inputType="textarea"
              textareaBg="#F7F6F6"
            />
            <BtnWrapper>
              <ConfirmBtn type="submit">Yes, Delete</ConfirmBtn>
              <CancelBtn onClick={handleClose} type="button">
                No, Cancel
              </CancelBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Container>
    </Backdrop>
  );
};

export default DialogModal;

const Container = styled.div`
  max-width: 50vw;
  min-width: 520px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaQueries.mobile} {
    min-width: unset;
    max-width: 100vw;
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 24px;
`;

const Reject = styled.span`
  color: #b40909;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 24px;
`;

const Btn = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
`;

const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #afd34f;
  border: none;
`;

const CancelBtn = styled(Btn)`
  color: #2a3313;
  border: 1px solid #2a3313;
  background-color: transparent;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { activatePackage, activateProjectLocation } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useActivateProjectLocation = () => {
  const queryClient = useQueryClient();

  return useMutation(activateProjectLocation, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.allProjectPerCatgory]);
    },
  });
};

export default useActivateProjectLocation;

export const useActivatePackage = () => {
  const queryClient = useQueryClient();

  return useMutation(activatePackage, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.singlePackage]);
    },
  });
};

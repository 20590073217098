import React from "react";
import { Editor, EditorState } from "react-draft-wysiwyg";
import styled from "styled-components";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useField } from "formik";

interface Props {
  name: string;
  value: EditorState | undefined;
  onChange: (value: React.SetStateAction<EditorState>) => void;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  fullWidth?: boolean;
}

const BodyField: React.FC<Props> = ({
  value,
  onChange,
  label,
  placeholder,
  isRequired,
  fullWidth,
  ...props
}) => {
  const [, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  return (
    <BodyContainer className="body-container" fullWidth={fullWidth}>
      <label className={errorClass}>{label}</label>
      <Editor
        editorState={value}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName={`editorClassName`}
        onEditorStateChange={(editorState: EditorState) =>
          onChange(editorState)
        }
        placeholder={placeholder || "Write your story..."}
        spellCheck={true}
      />
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </BodyContainer>
  );
};

export default BodyField;

export const BodyContainer = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? `100%` : `80%`)};
  height: max-content;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }
  }

  .rdw-editor-toolbar {
    border: 1px solid #bdbdbd;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
    margin-bottom: 0;
    background: #f1f1f1;
  }
  .rdw-option-wrapper,
  .rdw-dropdown-selectedtext {
    background: #f1f1f1;
  }
  .rdw-editor-main {
    min-height: 300px;
    border: 1px solid #bdbdbd;
    border-radius: 0 0 8px 8px;
    border-top: none;
    padding: 0 1rem;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import Spinner from "app/views/Web/components/Spinner";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FilterIcon from "@mui/icons-material/Filter";
import { useField } from "formik";
import { uploadAPhoto } from "app/api/utilities";
import { parseError } from "app/utils";
import { AxiosResponse } from "axios";
import { ApiResponse } from "app/redux/types";
import { UploadFileResponse } from "app/api/utilities/types";

interface Props {
  text: string;
  name: string;
  value?: string;
  handleFileId: (id: number) => void;
  width?: string;
  height?: string;
  iconWidth?: string;
  iconHeight?: string;
  borderRadius?: string;
  uploadPhotoAsyncFn?: (
    payload: FormData
  ) => Promise<AxiosResponse<ApiResponse<UploadFileResponse>, any>>;
}

const AddImage: React.FC<Props> = ({
  text,
  handleFileId,
  value,
  width,
  height,
  borderRadius,
  iconWidth,
  iconHeight,
  uploadPhotoAsyncFn,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [, meta] = useField(props);
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState<string>();

  useEffect(() => {
    if (value) {
      setPreviewImage(value);
    }
  }, [value]);

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const SinglePhotoUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("photo", file);
      const res = uploadPhotoAsyncFn
        ? await uploadPhotoAsyncFn(data)
        : await uploadAPhoto(data);
      handleFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  const ContentReader = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewImage(reader.result as string);
    };
  };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return;
    const fileUploaded = event.currentTarget.files[0];
    setIsLoading(true);
    await SinglePhotoUploader(fileUploaded);
    setIsLoading(false);
    ContentReader(fileUploaded);
  };

  return (
    <>
      <Container
        onClick={handleClick}
        width={width}
        height={height}
        borderRadius={borderRadius}
        iconHeight={iconHeight}
        iconWidth={iconWidth}
      >
        <input
          type="file"
          ref={inputRef}
          onChange={(e) => {
            onFileChange(e);
            e.target.value = "";
          }}
          accept="image/png, image/gif, image/jpeg"
        />
        {previewImage ? (
          isLoading ? (
            <Spinner color="black" />
          ) : (
            <ImageWrapper borderRadius={borderRadius}>
              <img src={previewImage} alt="" />
              <div>
                <span>upload image</span>
              </div>
            </ImageWrapper>
          )
        ) : (
          <>
            {isLoading ? <Spinner color="black" /> : <FilterIcon />}
            <span>{text}</span>
          </>
        )}
      </Container>
      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </>
  );
};

export default AddImage;

interface ContainerProps {
  width?: string;
  height?: string;
  iconWidth?: string;
  iconHeight?: string;
  borderRadius?: string;
}

const Container = styled.div<ContainerProps>`
  height: ${({ height }) => (height ? height : "152px")};
  width: ${({ width }) => width && width};
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "8px")};
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "8px"};
  }

  input {
    display: none;
  }

  svg {
    width: ${({ iconWidth }) => (iconWidth ? iconWidth : "40px")};
    height: ${({ iconHeight }) => (iconHeight ? iconHeight : "40px")};
  }

  span {
    font-weight: 450;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #5e5e5e;
  }
`;
const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

interface ImageWrapperProps {
  borderRadius?: string;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  position: relative;
  width: 100%;
  height: 100%;

  img:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  div {
    opacity: 0;
  }

  &:hover div {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000066;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    text-decoration-line: underline;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "8px"};
    transition: all 0.3s ease-out;
    cursor: pointer;
    span {
      color: #afd34f;
      text-decoration: underline;
    }
    &:hover span {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
`;

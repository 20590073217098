import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteComment } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useDeleteComment = (commentid: number) => {
  const queryClient = useQueryClient();

  return useMutation(deleteComment, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.getPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                commentsCount: oldQueryData.data.data.commentsCount - 1,
                comments: oldQueryData.data.data.comments.filter(
                  (ev: any) => ev.id !== commentid
                ),
              },
            },
          };
        }
      );
    },
  });
};

export default useDeleteComment;

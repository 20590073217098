import { useMutation } from "@tanstack/react-query";
import { exportAdminStaffCommisssion } from "app/api/analytics";
import { StaffCommissionPayload } from "app/api/analytics/types";
import { parseError } from "app/utils";
import dayjs from "dayjs";
import FileDownload from "js-file-download";

interface Props {
  payload: StaffCommissionPayload;
}

const useExportAdminStaffCommisssion = (page: number) => {
  return useMutation(
    (payload: Props) => exportAdminStaffCommisssion(payload.payload, page),
    {
      onError: (error) => parseError(error),
      onSuccess: (data) => {
        FileDownload(data.data, `staff-commission-${dayjs(new Date())}.xls`);
      },
    }
  );
};

export default useExportAdminStaffCommisssion;

import { Box } from "@mui/material";
import { adminGetOfferPaymentsByCategoryResponse } from "app/api/payment/types";
import useAdminTransferPackage from "app/hooks/analytics/useAdminTransferPackage";
import Spinner from "app/views/Web/components/Spinner";
import { FC } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import ModalWrapper from "./ModalWrapper";

interface Props {
  data: adminGetOfferPaymentsByCategoryResponse;
  open: boolean;
  handleClose: () => void;
}

const TransferModal: FC<Props> = ({ open, handleClose, data }) => {
  const { mutate: approveMutate, isLoading: isApproving } =
    useAdminTransferPackage();

  const onSuccess = () => {
    toast.success(`Property transfered successfully!`);
    handleClose();
  };

  const onTransferPackage = () => {
    const payload = {
      client_id: data.buyer.id,
      client_package_id: data.client_package_id,
      offer_id: data.offer.id,
    };

    approveMutate(payload, {
      onSuccess,
    });
  };

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Title>Confirm Property Transfer</Title>
        <ContentWrapper>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Property:</Label>
            <Value>{data.project}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Package:</Label>
            <Value>{data.package}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Price:</Label>
            <Value>NGN{Number(data.amount).toLocaleString("en-US")}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Transfer From:</Label>
            <TransferFrom>
              {data.owner.firstname + " " + data.owner.lastname}
            </TransferFrom>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={"6px"}>
            <Label>Transfer To:</Label>
            <TransferTo>
              {data.buyer.firstname + " " + data.buyer.lastname}
            </TransferTo>
          </Box>
        </ContentWrapper>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={"16px"}
          width={"100%"}
        >
          <CancelBtn onClick={handleClose}>Cancel</CancelBtn>
          <ConfirmBtn onClick={onTransferPackage}>
            {isApproving && <Spinner color="black" />}
            Confirm
          </ConfirmBtn>
        </Box>
      </Container>
    </ModalWrapper>
  );
};

export default TransferModal;

const Container = styled.div`
  width: 465px;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #181d0b;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 394px;
  height: 234px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 18px 14px 27px;
  margin: 20px 0 40px;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #49b7e4;
`;

const Value = styled(Label)`
  color: #5e5e5e;
  text-transform: capitalize;
`;

const TransferFrom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  height: 27px;
  font-weight: 450;
  font-size: 16px;
  line-height: 19px;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 12px;
`;

const TransferTo = styled(TransferFrom)`
  background: #28647d;
`;

const CancelBtn = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 40px;
  font-weight: 500;
  font-size: 14px;
  color: #dd1717;
  height: 40px;
  border: 1px solid #dd1717;
  border-radius: 8px;
  cursor: pointer;
`;

const ConfirmBtn = styled(CancelBtn)`
  background: #97b644;
  border: none;
  color: #ffffff;
`;

import React, { useEffect, useState } from "react";
import { SingleOption } from "app/types/common";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import DatePickerField from "../../../components/formik inputs/DatePickerField";
import SelectField from "../../../components/formik inputs/SelectField";
import TimePickerField from "../../../components/formik inputs/TimePickerField";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import { OneTimeValidation } from "../validation";
import { CreateBtn, FlexWrapper, FormWrapper } from "./styles";
import useCreateNewSiteTour from "app/hooks/site tour/useCreateNewSiteTour";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Spinner from "app/views/Web/components/Spinner";

const OneTimeSchedule = () => {
  const navigate = useNavigate();
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const [location, setLocation] = useState<SingleOption>();
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(null);

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType?.value as number);

  const { mutate: createMutate, isLoading: isCreating } =
    useCreateNewSiteTour();

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  useEffect(() => {
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType]);

  useEffect(() => {
    setLocation(undefined);
  }, [projectType]);

  const onSuccess = () => {
    toast.success(`Tour Created Successfully!`);
    setTimeout(() => {
      navigate("/admin/tour");
    }, 1500);
  };

  const onSubmitHandler = () => {
    if (!location) return;
    const payload = {
      reoccur: 0 as 0,
      project_location_id: location.value,
      date: dayjs(date).format("YYYY-MM-DD"),
      time: dayjs(time).format("hh:mm A"),
    };
    createMutate(payload, {
      onSuccess,
    });
  };

  return (
    <Formik
      initialValues={{
        categoryType,
        projectType,
        location,
        date,
        time,
      }}
      validationSchema={OneTimeValidation}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      <FormWrapper>
        <Form>
          <FlexWrapper>
            <SelectField
              name="categoryType"
              label="Category"
              value={categoryType as SingleOption}
              onChange={(e) => setCategoryType(e as SingleOption)}
              options={formatData(allCategoryData)}
              selectType="normal"
              isLoading={allCategoryLoading}
            />

            <SelectField
              name="projectType"
              label="Property"
              value={projectType as SingleOption}
              onChange={(e) => setProjectType(e as SingleOption)}
              disabled={!categoryType}
              options={formatData(projectPerCategoryData)}
              selectType="normal"
              isLoading={projectPerCategoryLoading}
            />
          </FlexWrapper>
          <SelectField
            name="location"
            label="Location"
            value={location as SingleOption}
            onChange={(e) => setLocation(e as SingleOption)}
            disabled={!projectType}
            options={formatData(locationPerProjectData)}
            selectType="normal"
            isLoading={locationPerProjectLoading}
          />

          <FlexWrapper>
            <DatePickerField
              name="date"
              label="Date"
              placeholder="Select Date"
              value={date}
              onChange={(e) => setDate(e)}
            />

            <TimePickerField
              name="time"
              label="Time"
              placeholder="Select Time"
              value={time}
              onChange={(e) => setTime(e)}
            />
          </FlexWrapper>

          <CreateBtn type="submit">
            {isCreating && <Spinner color="black" />}
            Create
          </CreateBtn>
        </Form>
      </FormWrapper>
    </Formik>
  );
};

export default OneTimeSchedule;

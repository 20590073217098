import useGetPromos from "app/hooks/community/useGetPromos";
import { useAppSelector } from "app/redux";
import { homeSelector } from "app/redux/home/HomeSlice";
import { useCallback, useEffect, useRef, useState } from "react";
import AgroToHome from "./AgroToHome";
import OfferModal from "./components/OfferModal";
import FAQ from "./FAQ";
import Hero from "./Hero";
import NewsLetter from "./NewsLetter";
import PageCards from "./PageCards";
import Search from "./Search";
import Testimonial from "./Testimonial";
import VirtualSection from "./VirtualSection";
import { useInView } from "react-intersection-observer";

enum TypeProps {
  package = "package",
}

const LandingPage = () => {
  const { searchData } = useAppSelector(homeSelector);
  const searchElementRef = useRef<HTMLDivElement>(null);
  const { ref: agroToHomeRef, inView: agroToHomeInView } = useInView({
    threshold: 0.9,
  });

  const { data } = useGetPromos();
  const latestPromoData = data?.filter(
    (ev) => ev.products[0].type === TypeProps.package
  )[data.length - 1];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const isOfferConstent = document.cookie
    .split("; ")
    .find((row) => row.startsWith("offer_consent="))
    ?.split("=")[1];

  const onOfferClose = () => {
    document.cookie = "offer_consent=close;";
    setOpen(false);
  };

  const goToSearchElement = useCallback(() => {
    const element = searchElementRef.current;
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  useEffect(() => {
    goToSearchElement();
  }, [goToSearchElement]);

  useEffect(() => {
    if (!isOfferConstent && latestPromoData && agroToHomeInView) {
      handleOpen();
    }
  }, [isOfferConstent, latestPromoData, agroToHomeInView]);

  return (
    <>
      <Hero goToSearchElement={goToSearchElement} />
      {searchData && (
        <Search
          searchElementRef={searchElementRef}
          goToSearchElement={goToSearchElement}
        />
      )}
      <PageCards />
      <AgroToHome agroToHomeRef={agroToHomeRef} />
      <VirtualSection />
      <Testimonial />
      <NewsLetter />
      <FAQ mini />
      {open && latestPromoData && (
        <OfferModal
          open={open}
          handleClose={onOfferClose}
          promoData={latestPromoData}
        />
      )}
    </>
  );
};

export default LandingPage;

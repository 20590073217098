import styled from "styled-components";
import { useEffect, useRef } from "react";
import mediaQueries from "styles/utils/mediaQueries";
import { clientSidebarData, UserRoutes } from "app/utils/data";
import CustomLink from "./Sidebar/components/customLink";
import ImgLogo from "../../../images/web/Adbond Logo 1.svg";
import { useNavigate } from "react-router";
import { useAppDispatch } from "app/redux";
import { loginFailed } from "app/redux/auth/authSlice";
import storage from "app/utils/storage";
import { ReactComponent as LogoutIcon } from "../../../images/admin/logout.svg";
import { setDashboardUrl } from "app/redux/dashboard/dashboardSlice";
import { mediaObj } from "styles/utils/media";
import { useMobileCheck } from "app/hooks/useMobileCheck";

interface props {
  isOpen: boolean;
  setIsNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileNavOverlay: React.FC<props> = ({ isOpen, setIsNavbarOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const node = useRef<HTMLDivElement>(null);
  const isMobile = useMobileCheck();

  const handleOutsideClick = (e: any) => {
    if (
      node.current &&
      !node.current.contains(e.target) &&
      e.target.parentNode.id !== "mobile_hamburger"
    ) {
      setIsNavbarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNav = () => {
    setIsNavbarOpen(false);
  };

  const goHome = () => {
    navigate("/");
    dispatch(setDashboardUrl(UserRoutes.Root));
  };

  const onLogout = () => {
    dispatch(loginFailed());
    storage.clear();
    navigate("/login");
  };

  return (
    <Container isOpen={isOpen} ref={node}>
      <Logo src={ImgLogo} onClick={goHome} />

      <NavWrapper>
        <>
          {clientSidebarData.map((item, index) => (
            <CustomLink key={index} {...item} handleCloseNav={handleCloseNav} />
          ))}
        </>
        <Logout>
          <CustomLink action={onLogout} icon={LogoutIcon} title="Logout" />
        </Logout>
      </NavWrapper>
    </Container>
  );
};

export default MobileNavOverlay;

interface navProps {
  isOpen?: boolean;
}

const Container = styled.div<navProps>`
  display: none;

  ${mediaObj.smallDesktop} {
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: fixed;
    top: 0;
    left: 0;
    transform: ${({ isOpen }) =>
      isOpen ? `translateX(40%)` : `translateX(100%)`};
    background-color: #fff;
    width: 100vh;
    height: 100vh;
    padding-top: 18px;
    z-index: 9;
    transition: all 0.3s linear;
  }

  ${mediaObj.bigMobile} {
    transform: ${({ isOpen }) =>
      isOpen ? `translateX(0%)` : `translateX(100%)`};
  }
`;

const Logo = styled.img`
  opacity: 0;
  width: 90px;
  height: 35px;
  margin-left: 15px;
  cursor: pointer;
  object-fit: cover;

  ${mediaObj.bigMobile} {
    opacity: 1;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const StyledNav = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;

  & > span {
    font-size: 18px;
    color: #afd34f;
  }
`;

const Logout = styled.div`
  & > div {
    & > div {
      width: 100%;
      padding-left: 20px;
      margin-left: 0;
    }
  }
`;

import { DayRange, WeekRange } from "app/api/site tour/types";

export const TourNavData = [
  {
    name: "General Site Tours",
    id: 1,
  },
  {
    name: "Custom Site Tours",
    id: 2,
  },
];

export const DayData: {
  label: string;
  value: DayRange;
}[] = [
  {
    value: 0,
    label: "Sunday",
  },
  {
    value: 1,
    label: "Monday",
  },
  {
    value: 2,
    label: "Tuesday",
  },
  {
    value: 3,
    label: "Wednesday",
  },
  {
    value: 4,
    label: "Thursday",
  },
  {
    value: 5,
    label: "Friday",
  },
  {
    value: 6,
    label: "Saturday",
  },
];

export const ReoccuranceData: {
  label: string;
  value: WeekRange;
}[] = [
  {
    value: 1,
    label: "Every First",
  },
  {
    value: 2,
    label: "Every Second",
  },
  {
    value: 3,
    label: "Every Third",
  },
  {
    value: 4,
    label: "Every Fourth",
  },
  {
    value: 5,
    label: "Every Fifth",
  },
];

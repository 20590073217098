import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  open: boolean;
  children: JSX.Element;
  title: string;
  handleClose: () => void;
  applyBackBtn?: boolean;
  goBack?: () => void;
}

const ModalWrapper: React.FC<Props> = ({
  children,
  title,
  open,
  handleClose,
  applyBackBtn,
  goBack,
}) => {
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>
        <Header>
          {applyBackBtn && <ArrowBackIcon onClick={goBack} />}
          <h6>{title}</h6>
        </Header>
        <Wrapper>{children}</Wrapper>
      </Container>
    </Backdrop>
  );
};

export default ModalWrapper;

const Container = styled.div`
  max-width: 50vw;
  min-width: 520px;
  padding: 25px 23px 40px 23px;
  position: relative;
  background-color: white;
  border-radius: 16px;

  ${mediaQueries.mobile} {
    min-width: unset;
    max-width: 100vw;
    width: 90vw;
  }
`;

const Wrapper = styled.div`
  max-height: 75vh;
  overflow-y: scroll;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;

  & > svg {
    cursor: pointer;
    &:hover {
      color: #afd34f;
      transition: 0.3s ease-out;
    }
  }

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

import { labelValueType } from "app/types/common";
import Spinner from "app/views/Web/components/Spinner";
import { FC } from "react";
import styled from "styled-components";
import DropDown from "../dropDown";

interface props {
  label: string;
  value: string;
  count: number;
  icon: any;
  applyShadow?: boolean;
  minWidth?: string;
  height?: string;
  yearOptions: labelValueType<any, any>[];
  monthOptions: labelValueType<any, any>[];
  handleYearChange?: (value: number) => void;
  handleMonthChange?: (value: any) => void;
  isLoading: boolean;
  subLabel: string;
}

const IncomeCard: FC<props> = ({
  count,
  label,
  value,
  icon: Icon,
  applyShadow,
  minWidth,
  height,
  yearOptions,
  monthOptions,
  handleYearChange,
  handleMonthChange,
  isLoading,
  subLabel,
}) => {
  return (
    <Container applyShadow={applyShadow} height={height} minWidth={minWidth}>
      <TopWrapper>
        <Label>{label}</Label>
        <DropdownContainer>
          <DropDown
            dropDownOptions={yearOptions}
            handleChange={handleYearChange}
          />
          <DropDown
            dropDownOptions={monthOptions}
            handleChange={handleMonthChange}
            bgColor={"#3E4B1C"}
          />
        </DropdownContainer>
      </TopWrapper>
      <BottomWrapper>
        <DetailsWrapper>
          {isLoading ? (
            <Spinner color="black" />
          ) : (
            <>
              <Value>{`₦${value}`}</Value>
              <Count>
                {count} {subLabel || "Sales"}
              </Count>
            </>
          )}
        </DetailsWrapper>
        <Icon />
      </BottomWrapper>
    </Container>
  );
};

export default IncomeCard;

interface ContainerProps {
  applyShadow?: boolean;
  minWidth?: string;
  height?: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "400px")};
  height: ${({ height }) => (height ? height : "120px")};
  background: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: ${({ applyShadow }) =>
    applyShadow &&
    "0px 0px 1px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #EEEEEE"};
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > svg {
    margin-right: 25px;
    align-self: flex-end;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Value = styled.h2`
  font-weight: 500;
  font-size: 31px;
  line-height: 140%;
  color: #2a3313;
`;

const Count = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #989898;
`;

import React, { useState } from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import PaymentModal from "../components/payment modal";
import Valuation from "./components/valuation";
import { mediaObj } from "styles/utils/media";
import { useOutletContext } from "react-router";
import { BidsResponse } from "app/api/offers/types";

const BidsPage = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { bidData }: { bidData: BidsResponse } = useOutletContext();

  return (
    <>
      <InfoWrapper>
        {bidData.payment_status === "pending" && (
          <>
            <BuyBtn onClick={() => setOpen(true)}>Make Payment</BuyBtn>
            <Content>
              You must make payment within 48 hours, or lose right to this offer{" "}
            </Content>
          </>
        )}

        {bidData.payment_status === "awaiting confirmation" && (
          <Content>Please await confirmation of payment.</Content>
        )}

        {bidData.payment_status === "confirmed" && !bidData.completed && (
          <Content>
            Your payment was successful. Please await transfer of ownership.
          </Content>
        )}
      </InfoWrapper>
      <Valuation price={+bidData.current_valuation} />
      {open && (
        <PaymentModal
          open={open}
          handleClose={handleClose}
          addNote
          addOwner
          SQM={bidData.customerPackageItem.packageItem.size}
          amountToPay={bidData.bid__price}
          packageItemId={bidData.customerPackageItem.packageItem.id}
          packageName={bidData.package}
          projectName={bidData.project}
          isOfferPayment
          offer_id={bidData.offer_id}
          bid_id={bidData.id}
        />
      )}
    </>
  );
};

export default BidsPage;

const InfoWrapper = styled.div`
  display: flex;
  gap: 24px;

  ${mediaObj.mobile} {
    flex-wrap: wrap;
  }
`;

const BuyBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 160px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  min-height: 54px;
  text-align: center;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 230px;
    margin: 0 auto;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #464646;
  padding: 11px 13px;
  background: #e6f2fa;
  border-radius: 4px;
`;

import { useQuery } from "@tanstack/react-query";
import { getAllPromo, getAPromo } from "app/api/offers";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useGetPromos = () => {
  return useQuery([CommunityQueryKeys.getPromos], () => getAllPromo(), {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetPromos;

export const useGetAPromo = (promo_id: number) => {
  return useQuery(
    [CommunityQueryKeys.getAPromo, promo_id],
    () => getAPromo(promo_id),
    {
      enabled: !!promo_id,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

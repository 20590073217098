import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminRateUser } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useAdminRateUser = () => {
  const queryClient = useQueryClient();

  return useMutation(adminRateUser, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([UserKeys.adminGetAUser]);
    },
  });
};

export default useAdminRateUser;

type CreateArrayWithLengthX<
  LENGTH extends number,
  ACC extends unknown[] = []
> = ACC["length"] extends LENGTH
  ? ACC
  : CreateArrayWithLengthX<LENGTH, [...ACC, 1]>;

type NumericRange<
  START_ARR extends number[],
  END extends number,
  ACC extends number = never
> = START_ARR["length"] extends END
  ? ACC | END
  : NumericRange<[...START_ARR, 1], END, ACC | START_ARR["length"]>;

interface OneTimeProps {
  reoccur: 0;
  date: string;
  week?: never;
  day?: never;
}

export type WeekRange = NumericRange<CreateArrayWithLengthX<1>, 5>;
export type DayRange = NumericRange<CreateArrayWithLengthX<0>, 6>;

interface RepeatitiveProps {
  reoccur: 1;
  week: WeekRange;
  day: DayRange;
  date?: never;
}

interface CommonProps {
  project_location_id: number;
  time: string;
}

export type NewSiteTourPayload = (OneTimeProps | RepeatitiveProps) &
  CommonProps;

export const FixedDate = "Fixed Date";

interface DayObj {
  id: number;
  name: string;
  dates: string[];
}

export interface NewSiteTourResponse {
  id: number;
  project: string;
  day: DayObj;
  time: string;
}

export interface SchedulesObj {
  id: number;
  type: "reoccuring" | "fixed";
  day: DayObj;
  time: string;
  is_booked: boolean;
}

export interface TourClientsResponse {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone_number: string;
  package: string;
  package_id: number;
}

export interface getSiteToursResponse {
  id: number;
  project: string;
  location: string;
  address: string;
  schedules: SchedulesObj[];
  clientsCount: number;
  clients: TourClientsResponse[];
}

export interface clientsTourObj {
  firstname: string;
  lastname: string;
  email: string;
  phone_number: string;
}

export interface CustomTourPayload {
  package_id: number;
  proposed_date: string;
  proposed_time: string;
  clients: clientsTourObj[];
}

export interface CustomTourResponse {
  id: number;
  project: string;
  location: string;
  category: string;
  date: string;
  time: string;
  clients: TourClientsResponse[];
}

export interface RemoveInspectionSchedulePayload {
  id: number;
  message?: string;
}

export interface CustomRemoveClientPayload {
  id: number;
}

export interface CustomRemoveSchedulePayload {
  id: number;
}

export interface CustomUpdateSchedulePayload {
  id: number;
  proposed_date: string;
  proposed_time: string;
}

export interface CustomAddClientsPayload {
  id: number;
  package_id: number;
  clients: clientsTourObj[];
}

export interface RequestCustomInspectionPayload {
  firstname: string;
  lastname: string;
  email: string;
  package_id: number;
  proposed_date: string;
  proposed_time: string;
}

import styled from "styled-components";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { FC } from "react";
import { Box } from "@mui/material";
import mediaQueries from "styles/utils/mediaQueries";
import { photosObj } from "app/api/project/types";
import { mediaObj } from "styles/utils/media";

interface props {
  isMain?: boolean;
  isInstallmental?: boolean;
  width?: string;
  packagePrice: number;
  categoryName: string;
  packageName: string;
  propertyName: string;
  locationAddress: string;
  mainFile: photosObj;
  onCardClick: () => void;
  isSoldOut?: boolean;
  isTab?: boolean;
}

const PropertyCard: FC<props> = ({
  isMain,
  isInstallmental,
  width,
  packagePrice,
  categoryName,
  packageName,
  propertyName,
  locationAddress,
  mainFile,
  onCardClick,
  isSoldOut,
  isTab,
}) => {
  return (
    <Container width={width} isSoldOut={isSoldOut} isTab={isTab}>
      <ImageWrapper>
        {isSoldOut && <SoldOut>Sold Out</SoldOut>}
        {mainFile?.file_type === "image" && <Image src={mainFile.url} />}
        {mainFile?.file_type === "video" && (
          <VideoWrapper>
            <video autoPlay muted loop>
              <source src={mainFile.url} type="video/mp4" />
            </video>
          </VideoWrapper>
        )}
        {isInstallmental && (
          <InstallmentWrapper>Installment</InstallmentWrapper>
        )}
      </ImageWrapper>
      {!isSoldOut && <CategoryWrapper>{categoryName}</CategoryWrapper>}
      <ContentWrapper>
        <Entry>
          <LocationOnRoundedIcon />
          <EntryValue>{locationAddress}</EntryValue>
        </Entry>
        <Entry>
          <EntryLabel>Property</EntryLabel>
          <EntryValue>{propertyName}</EntryValue>
        </Entry>
        <Entry>
          <EntryLabel>Package type</EntryLabel>
          <EntryValue>{packageName}</EntryValue>
        </Entry>
        <Box display={"flex"} alignItems={"center"} gap={"20px"}>
          <DetailsBtn disabled={isSoldOut} onClick={() => onCardClick()}>
            See details
          </DetailsBtn>

          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            {isMain && <PriceLabel>Current Valuation</PriceLabel>}
            <Price>
              {"\u20A6 "}
              {Number(packagePrice).toLocaleString("en-US")}
            </Price>
            {!isMain && (
              <PriceLabel>Installment payment options available</PriceLabel>
            )}
          </Box>
        </Box>
      </ContentWrapper>
    </Container>
  );
};

export default PropertyCard;

interface cardType {
  isMain?: boolean;
  width?: string;
  isSoldOut?: boolean;
  isTab?: boolean;
}

const Container = styled.div<cardType>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => (width ? width : "245px")};
  height: 290px;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgb(66 71 76 / 15%), 0px 3px 4px rgb(66 71 76 / 4%),
    0px 5px 55px #eeeeee;
  border-radius: 6.99348px;
  background: ${({ isSoldOut }) => isSoldOut && "#f1f1f1"};
  cursor: ${({ isSoldOut }) => isSoldOut && "not-allowed"};

  ${mediaObj.mediumDesktop} {
    width: 285px;
  }

  ${mediaObj.smallDesktop} {
    width: ${({ isTab }) => (isTab ? `330px` : `238px`)};
  }

  ${mediaQueries.mobile} {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
`;

const SoldOut = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #181d0b;
  border-radius: 0px 8px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 121px;

  ${mediaQueries.mobile} {
    height: 150px;
  }
`;

const Image = styled.img<cardType>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;
`;

const VideoWrapper = styled.div<cardType>`
  width: 100%;
  height: 100%;
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5.99348px;
    border-top-right-radius: 5.99348px;
  }
`;

const ContentWrapper = styled.div<cardType>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 2px 12px 12px 14px;
`;

const Entry = styled.div<cardType>`
  display: flex;
  align-items: center;
  gap: 9px;

  & > svg {
    width: 14px;
    height: 16px;
  }
`;

const EntryLabel = styled.div<cardType>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  color: #fbfbfb;
  height: 26px;
  background: #143340;
  border-radius: 4px;
`;

const EntryValue = styled.p<cardType>`
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #5e5e5e;
`;

const DetailsBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 108px;
  height: 33px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  background: #afd34f;
  border-radius: 4.63492px;
  cursor: pointer;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const Price = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #1e4b5e;
`;

const PriceLabel = styled.p`
  font-weight: 450;
  font-size: 10px;
  line-height: 140%;
  text-align: center;
  color: #1e4b5e;
`;

const CategoryWrapper = styled.div`
  position: absolute;
  right: 7px;
  top: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 12px;
  color: #fbfbfb;
  width: 60px;
  height: 25px;
  background: #49b7e4;
  border-radius: 12px;
  text-transform: capitalize;
`;

const InstallmentWrapper = styled.span`
  position: absolute;
  left: 0.4px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #fbfbfb;
  width: 76px;
  height: 25px;
  background: #601410;
  border-radius: 0px 8px 0px 0px;
`;

import { useQuery } from "@tanstack/react-query";
import { clientGetEvent, getEvent } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useAdminGetEvents = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.adminGetEvents, page],
    () => getEvent(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetEvents;

export const useClientGetEvents = (page: number, perPage: number = 5) => {
  return useQuery(
    [CommunityQueryKeys.clientGetEvents, page],
    () => clientGetEvent(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

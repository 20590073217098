import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageDisplay from "./components/ImageDisplay";
import { useNavigate, useParams } from "react-router";
import ProjectImages from "../../Web/components/modal/ProjectImages";
import { packagesObj } from "app/api/project/types";
import { getSinglePackage } from "app/api/project";
import { parseError } from "app/utils";
import Spinner from "app/views/Web/components/Spinner";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import mediaQueries from "styles/utils/mediaQueries";
import { mediaObj } from "styles/utils/media";
import SelectSqmModal from "./components/modals/SelectSqmModal";
import { UserRoutes } from "app/utils/data";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import { ClientUserResponse } from "app/api/users/types";
import KycModal from "./components/modals/KycModal";
import PackageItems from "./components/PackageItems";
import useDownloadPackageBrochure from "app/hooks/projects/useDownloadPackageBrochure";

interface SelectModalProps {
  openStatus: boolean;
  isInstallment: boolean;
}

const PackageDetails = () => {
  const navigate = useNavigate();
  const { id: packageId } = useParams();
  const [packageData, setPackageData] = useState<packagesObj>();
  const [openImages, setOpenImages] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [openPayment, setOpenPayment] = useState(false);
  // const handlePaymentClose = () => setOpenPayment(false);
  const [openKyc, setOpenKyc] = useState(false);
  const [openSqmModal, setOpenSqmModal] = useState<SelectModalProps>();
  const profile = useAppSelector(getAuth).user as ClientUserResponse;
  const { isFetching: isExporting, refetch } = useDownloadPackageBrochure(
    packageId as string
  );

  useEffect(() => {
    if (packageData?.sold_out) navigate(-1);
  }, [navigate, packageData?.sold_out]);

  const goBack = () => {
    navigate(-1);
  };

  const fetchPackage = useCallback(async () => {
    if (!packageId) return;
    try {
      setLoading(true);
      const res = await getSinglePackage(+packageId);
      setPackageData(res.data.data);
      setLoading(false);
    } catch (error) {
      parseError(error);
      setLoading(false);
    }
  }, [packageId]);

  useEffect(() => {
    fetchPackage();
  }, [fetchPackage]);

  const formatImages = () => {
    const temp = [];
    temp.push((packageData as packagesObj).main_file);
    const result = packageData?.photos
      ? [...temp, ...packageData.photos]
      : temp;

    return result;
  };

  const formatModalImages = () => {
    const temp = formatImages().filter((ev) => ev.file_type === "image");
    return temp;
  };

  const onBuyNow = () => {
    if (!profile.kyc_completed) {
      setOpenKyc(true);
      return;
    }
    if (!packageData) return;
    if (packageData?.items.length === 1) {
      // setOpenPayment(true);
      setOpenSqmModal({
        openStatus: true,
        isInstallment: false,
      });
    }
    if (packageData?.items.length > 1) {
      setOpenSqmModal({
        openStatus: true,
        isInstallment: false,
      });
    }
  };

  const onPayInInstallment = () => {
    if (!profile.kyc_completed) {
      setOpenKyc(true);
      return;
    }
    if (!packageData) return;
    setOpenSqmModal({
      openStatus: true,
      isInstallment: true,
    });
  };

  const onPaymentSuccess = () => navigate(`${UserRoutes.Properties}`);
  const discount = Number(packageData?.items[0]?.discount)?.toFixed(0) || 0;

  return (
    <Container>
      <Nav>
        <ArrowBackIcon onClick={goBack} />
        <h5>Package Details</h5>
      </Nav>

      {loading && (
        <SpinnerWrapper>
          <Spinner width="40px" height="40px" color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {!packageData && !loading && (
        <SpinnerWrapper>
          <NoPackageText>Package Not Found</NoPackageText>
        </SpinnerWrapper>
      )}

      {packageData && !loading && (
        <>
          <Wrapper>
            <h6>{packageData?.name}</h6>

            <BtnBox
              className="download-btn"
              onClick={() => refetch()}
              disabled={!packageData.brochure}
            >
              {isExporting ? (
                <Spinner color="white" />
              ) : (
                <DownloadOutlinedIcon />
              )}

              <span>Download Brochure</span>
            </BtnBox>
          </Wrapper>

          <ContentWrapper>
            <div>
              {packageData?.main_file && (
                <ImageDisplay
                  setOpen={setOpenImages}
                  imageData={formatImages()}
                />
              )}
              <p>{packageData?.description}</p>
              <ItemBody>
                <PackageBox>Location</PackageBox>{" "}
                <span className="location">
                  {packageData.project}
                  {", "}
                  {packageData.state}
                </span>
              </ItemBody>
              {packageData.items.map((ev) => (
                <ItemBody>
                  <PackageBox>Plots Available ({ev.size} sqm)</PackageBox>{" "}
                  <span className="plots">{ev.available_units} Plots</span>
                </ItemBody>
              ))}
            </div>

            <div>
              {packageData?.benefits && (
                <BenefitContainer>
                  <h2>Benefits</h2>
                  <BenefitContent
                    benefitNo={Math.ceil(
                      packageData.benefits.split(",").length / 3
                    )}
                  >
                    {packageData?.benefits.split(",").map((ev) => (
                      <BenefitItem>{ev}</BenefitItem>
                    ))}
                  </BenefitContent>
                </BenefitContainer>
              )}
              <Pricing>
                {packageData.items &&
                  packageData.items.length > 0 &&
                  packageData.items.map((item, i) => (
                    <PackageItems item={item} index={i} />
                  ))}
              </Pricing>
              <PricingFooter>
                <BtnBox onClick={onBuyNow}>
                  Buy Now ({`${discount}%`} off)
                </BtnBox>
                <BtnBox bgBlue onClick={onPayInInstallment}>
                  Pay in Installment
                </BtnBox>
              </PricingFooter>
            </div>
          </ContentWrapper>
          {packageData?.main_file && openImages && (
            <ProjectImages
              open={openImages}
              handleClose={() => setOpenImages(false)}
              imageArray={formatModalImages()}
              packageName={packageData.name}
            />
          )}

          {openSqmModal && (
            <SelectSqmModal
              open={openSqmModal.openStatus}
              handleClose={() => setOpenSqmModal(undefined)}
              isInstallment={openSqmModal.isInstallment}
              packageData={packageData}
              onPaymentSuccess={onPaymentSuccess}
            />
          )}

          {/* {openPayment && (
            <PaymentModal
              open={openPayment}
              handleClose={handlePaymentClose}
              packageItemId={packageData.items[0].id}
              packageName={packageData.name}
              projectName={packageData.project}
              SQM={packageData.items[0].size}
              redirect={onPaymentSuccess}
              // plotUnit={plot}
            />
          )} */}
          {openKyc && (
            <KycModal open={openKyc} handleClose={() => setOpenKyc(false)} />
          )}
        </>
      )}
    </Container>
  );
};

export default PackageDetails;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    padding: 1rem 0.5rem;
  }
`;

const Nav = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  color: #181d0b;

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.3s ease-in-out;
    }
  }

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
  }

  ${mediaQueries.mobile} {
    h5 {
      font-size: 1rem;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 24px;

  h6 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #181d0b;
  }

  ${mediaQueries.mobile} {
    h6 {
      font-size: 18px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div:nth-child(1) {
    width: 60%;
    p {
      margin-top: 10px;
      width: 70%;
      font-weight: 450;
      font-size: 1rem;
      line-height: 140%;
      color: #464646;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }

  & > div:nth-child(2) {
    min-width: 370px;
    width: 40%;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;

    & > div:nth-child(1) {
      width: 100%;
      p {
        width: 100%;
      }
    }

    & > div:nth-child(2) {
      min-width: unset;
      width: 100%;
    }
  }
`;

const ItemBody = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  margin-top: 1.5rem;

  .location {
    color: #143340;
  }

  .plots {
    color: #3e4b1c;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;

    span {
      flex-shrink: 0;
    }
  }
`;

const PackageBox = styled.div`
  background: #143340;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fbfbfb;
`;

const BenefitContainer = styled.div`
  background-color: #d8e8b8;
  padding: 12px 16px;
  border-radius: 4px;

  h2 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: #303030;
    text-align: center;
  }
`;

interface BenefitContentProps {
  benefitNo: number;
}

const BenefitContent = styled.div<BenefitContentProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: ${({ benefitNo }) => `repeat(${benefitNo}, 5rem)`};
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
`;

const BenefitItem = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28647d;
  border-radius: 2px;
  color: #fbfbfb;
  text-align: center;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  word-break: normal;
  overflow-y: scroll;
  text-transform: capitalize;
`;

const Pricing = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 14px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const NoPackageText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

interface BtnBoxProps {
  bgBlue?: boolean;
}

const BtnBox = styled.button<BtnBoxProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: ${({ bgBlue }) => (bgBlue ? "#49B7E4" : "#AFD34F")};
  border: none;
  color: white;
  padding: 17px 40px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover:not(:disabled) {
    background-color: ${({ bgBlue }) => (bgBlue ? "#3D9AC0" : "#697f2f")};
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    padding: 5%;
    gap: 5%;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: none;
    }

    &.download-btn {
      font-size: 14px;
      width: max-content;
    }
  }

  ${mediaObj.mobile} {
    font-size: 14px;

    &.download-btn {
      font-size: 12px;
    }
  }
`;

const PricingFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  gap: 0.75rem;
`;

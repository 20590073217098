import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { capitalize } from "lodash";
import { communityTabs } from "./data";
import mediaQueries from "styles/utils/mediaQueries";
import DisplayNews from "./components/DisplayNews";
import DisplayPromos from "./components/DisplayPromos";
import DisplayOffers from "./components/DisplayOffers";
import DisplayEvents from "./components/DisplayEvents";
import DisplayForums from "./components/DisplayForums";
import DisplayBlogposts from "./components/DisplayBlogposts";
import { CommunityContentsProps } from "app/views/Admin/Community/data";
import { useSearchParams } from "react-router-dom";

const Community = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeParam = searchParams.get("activeTab");
  const [selectedCategory, setSelectedCategory] = useState<number>(1);

  useEffect(() => {
    !activeParam && setSearchParams({ activeTab: communityTabs[0].name });
  }, [setSearchParams, activeParam]);

  useEffect(() => {
    if (activeParam) {
      const result = communityTabs.filter((ev) => ev.name === activeParam);
      setSelectedCategory(result[0].id);
    }
  }, [activeParam]);

  return (
    <Container>
      <HeaderWrapper>
        <h5> Publish posts and updates on Adbond community.</h5>
      </HeaderWrapper>
      <TabsWrapper>
        {communityTabs.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedCategory}
            onClick={() => {
              setSelectedCategory(x.id);
              setSearchParams({ activeTab: x.name });
            }}
          >
            {capitalize(x.name)}
          </Tab>
        ))}
      </TabsWrapper>

      <CardWrapper>
        {selectedCategory === CommunityContentsProps.news && <DisplayNews />}
        {selectedCategory === CommunityContentsProps.promo && <DisplayPromos />}
        {selectedCategory === CommunityContentsProps.offers && (
          <DisplayOffers />
        )}
        {selectedCategory === CommunityContentsProps.events && (
          <DisplayEvents />
        )}
        {selectedCategory === CommunityContentsProps.forum && <DisplayForums />}
        {selectedCategory === CommunityContentsProps.blogPost && (
          <DisplayBlogposts />
        )}
      </CardWrapper>
    </Container>
  );
};

export default Community;

const Container = styled.div`
  padding: 20px 24px;
  background-color: white;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    padding: 0;
    background: transparent;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    font-weight: 450;
    font-size: 25px;
    line-height: 140%;
    color: #181d0b;
        text-align: center;
  }

  ${mediaQueries.mobile} {
    h5 {
      font-size: 20px;
    }
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 40px 0;

  ${mediaQueries.mobile} {
    gap: 10px;
    width: 100%;
    margin: 25px 0;
    padding-bottom: 5px;
    overflow-x: scroll;
  }
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 8px 16px;
  height: 38px;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#FBFBFB" : "#535353")};
  background: ${({ isActive }) => (isActive ? "#464646" : "#E2E2E2")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

const CardWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 10px;
`;

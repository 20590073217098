import styled from "styled-components";
import { paymentData } from "./paymentGrid";

const MethodRenderer = ({ data }: { data: paymentData }) => {
  return <Container mode={data.paymentMethod}>{data.paymentMethod}</Container>;
};

export default MethodRenderer;

export interface ContainerProps {
  mode: "online" | "offline";
}

const Container = styled.div<ContainerProps>`
  width: max-content;
  padding: 4px 8px;
  border-radius: 8px;
  color: white;
  background-color: ${({ mode }) => mode === "online" && "#1E4B5E"};
  background-color: ${({ mode }) => mode === "offline" && "#3E4B1C"};
  text-transform: capitalize;
`;

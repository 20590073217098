import { useMutation } from "@tanstack/react-query";
import { clientUpdate } from "app/api/users";
import { parseError } from "app/utils";

const useClientUpdate = () => {
  return useMutation(clientUpdate, {
    onError: (error) => parseError(error),
  });
};

export default useClientUpdate;

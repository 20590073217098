import { Box } from "@mui/material";
import useGetAllAssessments from "app/hooks/assessment/useGetAssessments";
import useGetCompanyInfo from "app/hooks/utilities/useGetCompanyInfo";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  setAssessments,
} from "app/redux/assessment/assessmentSlice";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

interface props {
  handleNext: () => void;
}

const Summary: FC<props> = ({ handleNext }) => {
  const dispatch = useAppDispatch();
  const { data, error } = useGetAllAssessments();
  const assessments = useAppSelector(assessmentSelector).assessments;
  const { data: companyInfo } = useGetCompanyInfo();
  const duration =
    Number(companyInfo?.virtual_staff_assessment_time_limit) || 0;

  useEffect(() => {
    data && dispatch(setAssessments(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  return (
    <Wrapper>
      <TopContent>
        <Box display={"flex"} alignItems={"center"} gap={"22px"}>
          <GreenLine />
          <Title>Complete an aptitude test</Title>
        </Box>
        <PhaseCounter>Phase 2 of 2</PhaseCounter>
      </TopContent>
      <ContentWrapper>
        <ContentHead>Assessment Summary</ContentHead>
        <Description>
          Instructions: Do not close this screen, or open through a new tab. If
          you do, your test will be automatically terminated and you will be
          unable to retake the assessment for the next 7 days.{" "}
          <strong>
            Also, remember to submit your assessment before the timer runs out.
          </strong>
        </Description>
        {assessments?.map((x, idx) => (
          <Box display={"flex"} gap={"10px"}>
            <Subject>
              {idx + 1}. {x.title}
            </Subject>
            <QuestionCount>
              ({x.questions?.length} questions in total)
            </QuestionCount>
          </Box>
        ))}
        <Subject>Total Time: {`${duration} mins`}</Subject>
        <SubmitBtn onClick={handleNext}>Start Now</SubmitBtn>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Summary;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const GreenLine = styled.span`
  width: 120px;
  height: 8px;
  background: #afd34f;
  border-radius: 2px;

  ${mediaObj.mobile} {
    width: 50px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #303030;
`;

const PhaseCounter = styled.span`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #535353;

  ${mediaObj.mobile} {
    font-size: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;

  ${mediaObj.mobile} {
    width: 100%;
  }
`;

const ContentHead = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #afd34f;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #535353;
  margin-bottom: 15px;
`;

const Subject = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 144%;
  color: #181d0b;
`;

const QuestionCount = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #535353;
`;

const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 10px auto 0;
  width: 100%;
  height: 42px;
  outline: none;
  border: none;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
`;

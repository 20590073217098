import React, { useState } from "react";
import { CommentsObj, RepliesObj } from "app/api/community/types";
import styled from "styled-components";
import CommentField from "./CommentField";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { useClientLikeComment } from "app/hooks/community/useLikeComment";
import Spinner from "app/views/Web/components/Spinner";
import { useClientRemoveReaction } from "app/hooks/community/useRemoveReaction";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import { useClientDislikeComment } from "app/hooks/community/useDislikeComment";
import NameAvatar from "app/views/Client/components/nameAvatar";
import useAuth from "app/hooks/auth/useAuth";
import LoginModal from "./modals/LoginModal";

interface Optional {
  data: RepliesObj;
  type: "reply";
}

interface ConditionalProps {
  data: CommentsObj;
  type?: never;
}

type Props = Optional | ConditionalProps;

const CommentBox: React.FC<Props> = ({ data, type }) => {
  const [openCommentField, setOpenCommentField] = useState(false);
  const [openReplies, setOpenReplies] = useState(false);
  const [isRemovingLike, setIsRemovingLike] = useState(false);
  const [isRemovingDislike, setIsRemovingDislike] = useState(false);
  const { user } = useAppSelector(getAuth);
  const isLoggedIn = useAuth();
  const [open, setOpen] = useState(false);

  const { mutate: likeMutate, isLoading: likeLoading } = useClientLikeComment();
  const { mutate: dislikeMutate, isLoading: dislikeLoading } =
    useClientDislikeComment();
  const { mutate: removeReactionMutate } = useClientRemoveReaction();

  const onCommentFieldToggle = () => {
    setOpenCommentField((prev) => !prev);
  };

  const onOpenRepliesToggle = () => {
    setOpenReplies((prev) => !prev);
  };

  const getLikeReactionId = (data: CommentsObj) => {
    const userName = user?.firstname + " " + user?.lastname;
    const temp = data.likes.filter((ev) => ev.user.trim() === userName.trim());
    return temp[0]?.id;
  };

  const getDislikeReactionId = (data: CommentsObj) => {
    const userName = user?.firstname + " " + user?.lastname;
    const temp = data.dislikes.filter(
      (ev) => ev.user.trim() === userName.trim()
    );
    return temp[0]?.id;
  };

  const onLike = () => {
    const reactionId = type !== "reply" && getLikeReactionId(data);
    const payload = {
      comment_id: data.id,
    };
    if (reactionId) {
      setIsRemovingLike(true);
      removeReactionMutate(payload, {
        onSettled: () => setIsRemovingLike(false),
      });
    } else {
      likeMutate(payload);
    }
  };

  const onDislike = () => {
    const reactionId = type !== "reply" && getDislikeReactionId(data);

    const payload = {
      comment_id: data.id,
    };
    if (reactionId) {
      setIsRemovingDislike(true);
      removeReactionMutate(payload, {
        onSettled: () => setIsRemovingDislike(false),
      });
    } else {
      dislikeMutate(payload);
    }
  };

  return (
    <Container>
      <Avatar>
        {data.user?.profile_photo ? (
          <img src={data.user?.profile_photo?.url} alt="profile" />
        ) : (
          <NameAvatar
            name={data.user?.firstname + " " + data.user?.lastname}
            width="100%"
            height="100%"
          />
        )}
      </Avatar>
      <Content>
        <Header>
          <h6>{data.user.firstname + " " + data.user.lastname}</h6>
          <TimeActionBox>
            <span>{data.created_at}</span>
          </TimeActionBox>
        </Header>
        <Para>{data.body}</Para>

        {type !== "reply" && (
          <>
            <StatsBox>
              {data.replies.length > 0 && (
                <span onClick={onOpenRepliesToggle}>
                  {data.replies?.length} replies
                </span>
              )}

              {data.likes.length > 0 && (
                <span>
                  {data.likes.length} like{data.likes.length > 1 && "s"}{" "}
                </span>
              )}
              {data.dislikes.length > 0 && (
                <span>
                  {data.dislikes.length} dislike{data.likes.length > 1 && "s"}{" "}
                </span>
              )}
            </StatsBox>
            <ReplyLikeBox>
              <Reply
                onClick={
                  isLoggedIn ? onCommentFieldToggle : () => setOpen(true)
                }
              >
                Reply
              </Reply>
              <Like isLiked={!!getLikeReactionId(data)}>
                {likeLoading || isRemovingLike ? (
                  <Spinner width="13px" height="13px" color="#97b644" />
                ) : (
                  <ThumbUpIcon
                    onClick={isLoggedIn ? onLike : () => setOpen(true)}
                  />
                )}
              </Like>
              <DisLike isDisliked={!!getDislikeReactionId(data)}>
                {dislikeLoading || isRemovingDislike ? (
                  <Spinner width="13px" height="13px" color="#97b644" />
                ) : (
                  <ThumbDownIcon
                    onClick={isLoggedIn ? onDislike : () => setOpen(true)}
                  />
                )}
              </DisLike>
            </ReplyLikeBox>
          </>
        )}

        {openCommentField && (
          <CommentField
            type="reply"
            commentId={data.id}
            handleClose={onCommentFieldToggle}
            open={openCommentField}
          />
        )}
        {openReplies &&
          type !== "reply" &&
          data.replies.map((reply) => (
            <CommentBox data={reply} key={reply.id} type="reply" />
          ))}

        {open && (
          <LoginModal
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
          />
        )}
      </Content>
    </Container>
  );
};

export default CommentBox;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 9px;
  margin: 1rem 0;
`;

const Avatar = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  flex-shrink: 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    font-weight: 450;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #2d2d2d;
  }
`;

const TimeActionBox = styled.div`
  display: flex;
  gap: 23px;
  align-items: center;
  & > span {
    font-weight: 450;
    font-size: 0.75rem;
    line-height: 140%;
    color: #777777;
  }
`;

const Para = styled.p`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #464646;
  margin-top: 11px;
  margin-bottom: 8px;
`;

const ReplyLikeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  margin-bottom: 8px;
  & > div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      font-size: 0.8125rem;
    }
  }
`;

const Reply = styled.div`
  color: #97b644;
`;

interface LikeProps {
  isLiked: boolean;
}

const Like = styled.div<LikeProps>`
  & > svg {
    color: ${({ isLiked }) => (isLiked ? "#97b644" : "#303030")};
  }
`;

interface DisLikeProps {
  isDisliked: boolean;
}

const DisLike = styled.div<DisLikeProps>`
  & > svg {
    color: ${({ isDisliked }) => (isDisliked ? "#97b644" : "#303030")};
  }
`;

const StatsBox = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 450;
  font-size: 0.75rem;
  line-height: 140%;
  color: #777777;
  margin-bottom: 8px;

  & > span:nth-child(1) {
    cursor: pointer;
  }
`;

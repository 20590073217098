import { cardVariant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

interface Props {
  testimonialText: string;
  avatar: string;
  avatarName: string;
}

const TestimonialCard: React.FC<Props> = ({
  testimonialText,
  avatar,
  avatarName,
}) => {
  return (
    <Card variants={cardVariant}>
      <p>{testimonialText}</p>
      <div className="footer">
        <img src={avatar} alt="" />
        <h2>{avatarName}</h2>
      </div>
    </Card>
  );
};

export default TestimonialCard;

const Card = styled(motion.div)`
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 450;
    font-size: 1rem;
    line-height: 20px;
    color: #5e5e5e;
    margin-bottom: 20px;
    flex: 1;
    /* height: 180px; */
  }

  .footer {
    display: flex;
    align-items: center;
    gap: 16px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      object-fit: cover;
    }

    h2 {
      font-weight: 450;
      font-size: 18px;
      line-height: 140%;
      color: #000000;
    }
  }

  ${mediaObj.mediumDesktop} {
    width: 48%;
  }

  ${mediaObj.bigMobile} {
    width: 100%;

    p {
      font-size: 16px;
    }
  }
`;

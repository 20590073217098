import React from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
import PostTag from "./PostTag";
import { GetPostResponse } from "app/api/community/types";
import { ErrorText, SpinnerWrapper } from "./styles";
import Spinner from "app/views/Web/components/Spinner";
import { replaceEmptyPTagWithBrTa } from "app/utils";
import { mediaObj } from "styles/utils/media";

interface Props {
  setOpenComment: React.Dispatch<React.SetStateAction<boolean>>;
  postData?: GetPostResponse;
  isFetching: boolean;
  isError: boolean;
}

const PostContent: React.FC<Props> = ({
  setOpenComment,
  postData,
  isFetching,
  isError,
}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Nav onClick={() => navigate(-1)}>
        <ArrowBackIcon />
        <h5>Back</h5>
      </Nav>

      {isFetching && (
        <SpinnerWrapper>
          <Spinner color="#97B644" />
        </SpinnerWrapper>
      )}

      {isError && !isFetching && (
        <SpinnerWrapper>
          <ErrorText>An error occurred</ErrorText>
        </SpinnerWrapper>
      )}

      {postData && !isError && !isFetching && (
        <>
          <H6>{postData?.title} </H6>
          <ImgWrapper>
            <img src={postData?.cover_photo.url} alt="" />
          </ImgWrapper>
          <Content>
            {parse(replaceEmptyPTagWithBrTa(postData?.body || ""))}
          </Content>
          <TagWrapper>
            {postData?.tags.map((tag) => (
              <PostTag key={tag.id} label={tag.name} />
            ))}
          </TagWrapper>
          <FlexWrapper>
            <span>{`${postData?.views} Views`}</span>
            <span>{`${postData?.likesCount} Likes`}</span>
            <span
              onClick={() => setOpenComment((prev) => !prev)}
            >{`${postData?.commentsCount} Comments`}</span>
          </FlexWrapper>
        </>
      )}
    </Container>
  );
};

export default PostContent;

const Container = styled.div`
  padding: 35px 62px 35px 40px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32);
  flex: 2;

  ${mediaObj.bigMobile} {
    padding: 5%;
  }
`;

const H6 = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  color: #2a3313;
  margin-bottom: 32px;
`;

const ImgWrapper = styled.div`
  height: 500px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`;

const Nav = styled.div`
  width: max-content;
  display: flex;
  gap: 15px;
  align-items: center;
  color: #767676;
  margin-bottom: 30px;
  cursor: pointer;

  svg {
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.1s ease-out;
    }
  }

  h5 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
  }

  &:hover {
    color: #afd34f;
    transition: all 0.1s ease-out;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #777777;
  margin-top: 24px;

  & > span:nth-child(3) {
    cursor: pointer;
  }
`;

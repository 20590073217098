import { ProfilePhotoObj } from "app/types/common";
import { categoryResponse, photosObj } from "../project/types";
import { RolesResponse } from "../utilities/types";

export interface CreateOfferPayload {
  client_package_id: number;
  price: number;
}

export interface CreateOfferResponse {
  id: number;
  project: string;
  package: string;
  purchase_price?: number;
  offer_price: number;
  active: boolean;
  approved: boolean;
  completed: boolean;
  created_at: string;
}

export interface UpdateOfferPayload {
  offer_id: number;
  price: number;
}

export interface OfferIdPayload {
  offer_id: number;
}

export interface OfferClientObj {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone_number?: string;
  address?: string;
  referer?: string;
  profile_photo?: photosObj;
  total_property_value: number;
}

interface OfferBidObj {
  id: number;
  project: string;
  package: string;
  client: OfferClientObj;
  customerPackageItem: OfferCustomerPackageItemObj;
  offer_price: string;
  bid__price: string;
  active: boolean;
  accepted: boolean;
  completed: boolean;
  created_at: string;
}

interface OfferCategoryObj {
  id: number;
  name: string;
}

interface OfferOrderObj {
  amount_paid: string;
  balance: number;
  installment: boolean;
}

export interface OfferPackageObj {
  id: number;
  name: string;
  available_units: string;
  description: string;
  benefits: string;
  state: string;
  main_file: photosObj;
  photos: photosObj[];
  category: categoryResponse;
  project_location: {
    id: number;
    project: string;
    location: string;
    address?: string;
    active: boolean;
    canDelete: boolean;
  };
  active: boolean;
  canDelete: boolean;
  brochure: photosObj;
  sold_out: boolean;
}

interface OfferPackageItemObj {
  id: number;
  package: OfferPackageObj;
  size: string;
}

export interface OfferCustomerPackageItemObj {
  category: OfferCategoryObj;
  id: number;
  location: string;
  order: OfferOrderObj;
  packageItem: OfferPackageItemObj;
}

export enum ApprovedStatus {
  Approved = "approved",
  Pending = "pending",
  Rejected = "rejected",
}

export interface OfferResponse {
  active: boolean;
  bid_accepted: boolean;
  approved_by?: string;
  approved_status: ApprovedStatus;
  client: OfferClientObj;
  bids: OfferBidObj[];
  completed: boolean;
  created_at: string;
  id: number;
  offer_price: number;
  customerPackageItem: OfferCustomerPackageItemObj;
  project: string;
  purchase_price: number;
  rejected_reason?: string;
  current_valuation: string;
}

export interface BidsResponse {
  id: number;
  project: string;
  package: string;
  customerPackageItem: OfferCustomerPackageItemObj;
  offer_id: number;
  offer_price: string;
  current_valuation: string;
  bid__price: string;
  active: boolean;
  accepted: boolean;
  completed: boolean;
  created_at: string;
  payment_status: "awaiting confirmation" | "pending" | "confirmed";
}

export interface PlaceBidPayload {
  offer_id: number;
  bid_price: number;
}

export interface UpdateBidPayload {
  bid_id: number;
  bid_price: number;
}

export interface BidIdPayload {
  bid_id: number;
}

interface ProductIdObj {
  product_id: number;
}

export interface AdminCreatePromoPayload {
  title: string;
  discount: number;
  start: string;
  end: string;
  description: string;
  categories: ProductIdObj[];
  projects?: ProductIdObj[];
  project_locations?: ProductIdObj[];
  packages?: ProductIdObj[];
}

export interface AdminUpdatePromoPayload {
  promo_id: number;
  title: string;
  discount: number;
  start: string;
  end: string;
  description: string;
}

export interface PromoCategoryObj {
  id: number;
  type: "category";
  product: {
    id: number;
    name: string;
  };
}

export interface PromoProjectObj {
  id: number;
  type: "project";
  product: {
    category: string;
    category_id: number;
    id: number;
    name: string;
  };
}

export interface PromoProjectLocationObj {
  id: number;
  type: "project_location";
  product: {
    address?: string;
    category: string;
    category_id: number;
    id: number;
    location: string;
    project: string;
    project_id: string;
  };
}

export interface PromoPackageObj {
  id: number;
  type: "package";
  product: {
    benefits: string;
    category: string;
    category_id: number;
    description: string;
    id: number;
    name: string;
    project: string;
    project_id: number;
    project_location_id: string;
    location: string;
  };
}

export interface PromoObj {
  id: number;
  title: string;
  discount: string;
  start: string;
  end: string;
  active: boolean;
  description: string;
  products: (
    | PromoCategoryObj
    | PromoProjectObj
    | PromoProjectLocationObj
    | PromoPackageObj
  )[];
  user: {
    id: number;
    title?: number;
    firstname: string;
    lastname: string;
    profile_photo: ProfilePhotoObj;
    email: string;
    role: RolesResponse;
    staff_type: RolesResponse;
    phone_number: string;
  };
}

export interface DeletePromoPayload {
  promo_id: number;
}

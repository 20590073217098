import { useQuery } from "@tanstack/react-query";
import { adminGetClientPackages } from "app/api/analytics";
import { parseError } from "app/utils";
import { AnalyticsQueryKeys } from "./types";

const useAdminGetClientPackages = (page: number, perPage: number) => {
  return useQuery(
    [AnalyticsQueryKeys.adminGetClientPackages, page, perPage],
    () => adminGetClientPackages(page, perPage),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data,
    }
  );
};

export default useAdminGetClientPackages;

import { useEffect } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import useAdminAgeGroup from "app/hooks/analytics/useAdminAgeGroup";
import AgeGroupChart from "./ageGroupChart";

interface Props {
  applyShadow?: boolean;
}

const AgeGroup: React.FC<Props> = ({ applyShadow }) => {
  const { data, error, isLoading } = useAdminAgeGroup();

  useEffect(() => {
    error && toast.error("Failed to fetch age group data");
  }, [error]);

  return (
    <Container applyShadow={applyShadow}>
      <TopWrapper>
        <Label>Age group chart</Label>
      </TopWrapper>
      <AgeGroupChart data={data?.groups || []} isLoading={isLoading} />
    </Container>
  );
};

export default AgeGroup;

interface ContainerProps {
  applyShadow?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 60%;
  height: 380px;
  padding: 20px 15px 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: ${({ applyShadow }) =>
    applyShadow &&
    "0px 0px 0.849738px rgba(66, 71, 76, 0.32), 0px 3.39895px 6.79791px rgba(66, 71, 76, 0.06), 0px 6.79791px 40.7874px #EEEEEE"};
`;

const TopWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  z-index: 13;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

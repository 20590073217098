import { ApiResponse } from "app/redux/types";
import request from "../request";
import {
  assessmentObj,
  assessmentIdPayload,
  updateAssessmentPayload,
  approveRejectPayload,
  setPassMarkPayload,
  eTeamResult,
  SetCommissionRatePayload,
  SetCommissionTaxPayload,
} from "./types";

export const adminGetAssessments = async () => {
  return await request.get<ApiResponse<assessmentObj[]>>(
    `/admin/assessment/get`
  );
};

export const adminGetSingleAssessment = async (payload: number) => {
  return await request.get<ApiResponse<assessmentObj>>(
    `/admin/assessment/get/${payload}`
  );
};

export const adminCreateAssessment = async (payload: assessmentObj) => {
  return await request.post<ApiResponse<assessmentObj>>(
    `/admin/assessment/create`,
    payload
  );
};

export const adminEditAssessment = async (payload: updateAssessmentPayload) => {
  return await request.post<ApiResponse<assessmentObj>>(
    `/admin/assessment/update`,
    payload
  );
};

export const adminDeleteAssessment = async (payload: assessmentIdPayload) => {
  return await request.post<ApiResponse<{ message: string }>>(
    `/admin/assessment/delete`,
    payload
  );
};

export const adminApproveCandidate = async (payload: approveRejectPayload) => {
  return await request.post<ApiResponse<{ message: string }>>(
    `/admin/assessment/approve_candidate`,
    payload
  );
};

export const adminRejectCandidate = async (payload: approveRejectPayload) => {
  return await request.post<ApiResponse<{ message: string }>>(
    `/admin/assessment/reject_candidate`,
    payload
  );
};

export const adminSetPassmark = async (payload: setPassMarkPayload) => {
  return await request.post<ApiResponse<{ message: string }>>(
    `/admin/assessment/set_pass_mark`,
    payload
  );
};

export const adminGetAssessmentResult = async () => {
  return await request.get<ApiResponse<eTeamResult[]>>(
    `/admin/assessment/results`
  );
};

export const adminSetCommission = async (payload: SetCommissionRatePayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/set_commission_rate`,
    payload
  );
};

export const adminSetCommissionTax = async (
  payload: SetCommissionTaxPayload
) => {
  return await request.post<ApiResponse<string>>(
    `/admin/set_commission_tax`,
    payload
  );
};

export const adminUpgradeStaff = async (id: number) => {
  const payload = { user_id: id };
  return await request.post<ApiResponse<assessmentObj>>(
    `/admin/user/upgrade_to_hybrid`,
    payload
  );
};

import React, { useState } from "react";
import { Box } from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import AllCategory from "./components/AllCategory";
import ViewProject from "./components/Modals/ViewProject";

const Projects = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen((prev) => !prev);

  return (
    <Container>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header>Projects</Header>
        <CreateWrapper>
          <CreateProjectBtn onClick={handleToggle}>
            <AddIcon />
            View All Project
          </CreateProjectBtn>

          <CreatePackageBtn onClick={() => navigate("new")}>
            <AddIcon />
            Create a Package
          </CreatePackageBtn>
        </CreateWrapper>
      </Box>

      <AllCategory />

      {open && <ViewProject open={open} handleClose={handleClose} />}
    </Container>
  );
};

export default Projects;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  min-height: 80vh;
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
`;

const CreateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 51px;
`;

const CreatePackageBtn = styled.button`
  width: 188px;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const CreateProjectBtn = styled.button`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  color: #afd34f;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

export interface tabProps {
  isActive?: boolean;
}

export const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

import React, { useState } from "react";
import { ClientPackageResponse } from "app/api/client/types";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import PaymentModal from "../components/payment modal";
import PaymentGrid from "./components/gridComponents/paymentGrid";
import NumberFormatter from "../components/NumberFormatter";

const InstallmentPage = () => {
  const { clientPackageData }: { clientPackageData: ClientPackageResponse } =
    useOutletContext();
  const [amount, setAmount] = useState("");
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleClear = () => {
    handleClose();
    setStep(1);
    setAmount("");
  };

  const getStatus = (data: string | boolean) => {
    if (typeof data === "string") return data;
    else return data ? "approved" : "pending";
  };

  const tableData = clientPackageData.payments.map((ev, i) => {
    const temp = {
      id: i + 1,
      amountPaid: +ev.amount,
      paymentMethod: (ev.payment_channel === "paystack"
        ? "online"
        : "offline") as "online" | "offline",
      status: getStatus(ev.confirmed) as "pending" | "approved" | "rejected",
      date: ev.order.order_date,
    };
    return temp;
  });

  return (
    <>
      {step === 1 ? (
        clientPackageData.outstanding_payment && (
          <BuyBtn onClick={() => setStep(2)}>Make Payment</BuyBtn>
        )
      ) : (
        <FlexWrapper>
          <NumberFormatter
            name="amount"
            placeholder="Enter Amount"
            noLabel
            onChange={(value) => setAmount(value)}
          />
          <BuyBtn
            type="submit"
            onClick={() => setOpen(true)}
            isDisabled={!amount}
            disabled={!amount}
          >
            Continue
          </BuyBtn>
        </FlexWrapper>
      )}

      <TableWrapper>
        <h6>Payment</h6>
        <PaymentGrid data={tableData} />
      </TableWrapper>

      {open && (
        <PaymentModal
          open={open}
          handleClose={handleClear}
          isInstallment
          packageItemId={clientPackageData.packageItem.id}
          packageName={clientPackageData.packageItem.package.name}
          projectName={
            clientPackageData.packageItem.package.project_location.project
          }
          SQM={clientPackageData.packageItem.size}
          amountToPay={amount}
          isReoccuring
          order_id={clientPackageData.payments[0].order.id}
          plotUnit={+clientPackageData.units}
        />
      )}
    </>
  );
};

export default InstallmentPage;

interface BuyBtnProps {
  isDisabled?: boolean;
}

const BuyBtn = styled.button<BuyBtnProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 160px;
  height: 56px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  border: none;

  :disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${({ isDisabled }) => !isDisabled && "#697f2f"};
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 230px;
    margin: 0 auto;
  }
`;

const TableWrapper = styled.div`
  padding: 19px 17px;
  background: #ffffff;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 8px;

  h6 {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 24px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
  width: 70%;

  & > input {
    height: 100%;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    width: 70%;

    &:focus-visible {
      outline: none;
    }
  }
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import ClientGrid from "./components/grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useGetAllClients from "app/hooks/clients/useGetAllClients";
import Paginating from "../components/Pagination";
import { mediaObj } from "styles/utils/media";

const Clients = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { data, isLoading, error } = useGetAllClients(currentPage);
  const size = 9;
  // const pageCount = data && Math.floor(data.total / size) + 1;
  const startCount = size * (currentPage - 1) + 1;
  const endCount = size * (currentPage - 1) + size;

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Container>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header>Clients</Header>
        <CreateBtn onClick={() => navigate("new")}>
          <AddIcon />
          Add a new client
        </CreateBtn>
      </Box>
      <ClientGrid data={data?.clients || []} isLoading={isLoading} />
      {data?.clients && (
        <Footer>
          <Show>
            Showing {startCount} to{" "}
            {data?.total && endCount < data?.total ? endCount : data?.total} of{" "}
            {data?.total}
          </Show>

          <Paginating
            totalElements={data?.total || 0}
            size={size}
            pageCount={3}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </Footer>
      )}
    </Container>
  );
};

export default Clients;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  height: 80vh;
  width: 95%;

  ${mediaObj.mediumDesktop} {
    height: 83vh;
  }
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
`;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 188px;
  height: 44px;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

import { FileType } from "app/api/project/types";
import { bytesForHuman } from "app/utils";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImageUpload } from "./ImageUpload";
import PreviewBox from "./PreviewBox";

interface PreviewContentProps {
  name: string;
  content: string;
  type: FileType;
  format?: string;
  size?: string;
}

interface CommonProps {
  name: string;
  onlyImages?: boolean;
  imageBoxText: string;
  imageBoxHelperText?: string;
}

interface SingleProps {
  photoPostRequest?: (file: File) => Promise<void>;
  videoPostRequest?: (file: File) => Promise<void>;
  onSingleFileDelete: () => void;
  previewSingleFile?: PreviewContentProps;
  previewMultipleFile?: never;
  multiplePhotoPostRequest?: never;
  multipleVideoPostRequest?: never;
  onMultpleFileDelete?: never;
  allowMultiple?: never;
}

interface MultipleProps {
  photoPostRequest?: never;
  videoPostRequest?: never;
  onSingleFileDelete?: never;
  previewSingleFile?: never;
  previewMultipleFile?: PreviewContentProps[];
  multiplePhotoPostRequest?: (files: FormData) => Promise<void>;
  multipleVideoPostRequest?: (files: FormData) => Promise<void>;
  onMultpleFileDelete?: (index: number) => void;
  allowMultiple?: boolean;
}

type ConditionalProps = SingleProps | MultipleProps;

type Props = CommonProps & ConditionalProps;

const ImageUploadBox: React.FC<Props> = ({
  previewSingleFile,
  previewMultipleFile,
  photoPostRequest,
  videoPostRequest,
  onSingleFileDelete,
  onMultpleFileDelete,
  multiplePhotoPostRequest,
  multipleVideoPostRequest,
  allowMultiple,
  onlyImages,
  imageBoxText,
  imageBoxHelperText,
  name,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [singleUploadPreview, setSingleUploadPreview] =
    useState<PreviewContentProps>();
  const [multipleUploadPreview, setMultipleUploadPreview] = useState<
    PreviewContentProps[]
  >([]);

  useEffect(() => {
    if (previewSingleFile) {
      setSingleUploadPreview(previewSingleFile);
    }

    if (previewMultipleFile) {
      setMultipleUploadPreview(previewMultipleFile);
    }
  }, [previewSingleFile, previewMultipleFile]);

  const ContentReader = (
    file: File,
    fn: (fileContent: PreviewContentProps) => void
  ) => {
    const filetype = file.type.split("/")[0] as FileType; // (image, video)
    const fileFormat = file.type.split("/")[1];

    const fileContent = {
      name: file.name.split(fileFormat)[0],
      type: filetype,
      format: fileFormat,
      size: bytesForHuman(file.size),
    };

    if (filetype === "image") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const result = { ...fileContent, content: reader.result as string };
        fn(result);
      };
    } else if (filetype === "video") {
      const url = URL.createObjectURL(file);
      const result = { ...fileContent, content: url as string };
      fn(result);
    }
  };

  const SinglePreviewHandler = (fileContent: PreviewContentProps) => {
    setSingleUploadPreview(fileContent);
  };

  const MultiplePreviewHandler = (fileContent: PreviewContentProps) => {
    setMultipleUploadPreview((oldArray) => [
      ...(oldArray as PreviewContentProps[]),
      fileContent,
    ]);
  };

  const onSingleDelete = () => {
    setSingleUploadPreview(undefined);
    onSingleFileDelete && onSingleFileDelete();
  };

  const onMultipleDelete = (index: number) => {
    if (!multipleUploadPreview) return;
    setMultipleUploadPreview([
      ...multipleUploadPreview.slice(0, index),
      ...multipleUploadPreview.slice(index + 1),
    ]);
    onMultpleFileDelete && onMultpleFileDelete(index);
  };

  const handleSingleUpload = (files: FileList) => {
    Array.from(files).forEach(async (file) => {
      const filetype = file.type.split("/")[0] as FileType; // (image, video)
      setIsUploading(true);
      filetype === "image" &&
        photoPostRequest &&
        (await photoPostRequest(file));
      filetype === "video" &&
        videoPostRequest &&
        (await videoPostRequest(file));
      setIsUploading(false);
      ContentReader(file, SinglePreviewHandler);
    });
  };

  const handleMultipleUpload = async (files: FileList) => {
    const previewFiles = Array.from(files);
    const videoData = new FormData();
    const photoData = new FormData();
    Array.from(files).forEach((file) => {
      const filetype = file.type.split("/")[0] as FileType; // (image, video)
      filetype === "image" && photoData.append(`photos[]`, file);
      filetype === "video" && videoData.append(`photos[]`, file);
    });
    setIsUploading(true);
    multiplePhotoPostRequest && (await multiplePhotoPostRequest(photoData));
    multipleVideoPostRequest && (await multipleVideoPostRequest(videoData));
    setIsUploading(false);
    Array.from(previewFiles).forEach((file) => {
      ContentReader(file, MultiplePreviewHandler);
    });
  };

  return (
    <Wrapper allowMultiple={allowMultiple}>
      <MiniBox>
        <ImageUpload
          name={name} //for formik validation
          handleFile={allowMultiple ? handleMultipleUpload : handleSingleUpload}
          text={imageBoxText}
          isLoading={isUploading}
          onlyImages={onlyImages}
        />
        {imageBoxHelperText && <span>{imageBoxHelperText}</span>}
      </MiniBox>

      <Content>
        {singleUploadPreview && (
          <PreviewBox
            type={singleUploadPreview.type}
            label={singleUploadPreview.name}
            content={singleUploadPreview.content}
            size={singleUploadPreview.size}
            format={singleUploadPreview.format}
            onDelete={onSingleDelete}
          />
        )}

        {multipleUploadPreview.length > 0 &&
          multipleUploadPreview.map((item, i) => (
            <PreviewBox
              key={i}
              type={item.type}
              label={item.name}
              content={item.content}
              size={item.size}
              isExtra
              format={item.format}
              onDelete={() => onMultipleDelete(i)}
            />
          ))}
      </Content>
    </Wrapper>
  );
};

export default ImageUploadBox;

const MiniBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: #909090;
  }
`;

interface WrapperProps {
  allowMultiple?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  gap: ${({ allowMultiple }) => (allowMultiple ? "18px" : "80px")};
  margin-top: ${({ allowMultiple }) => (allowMultiple ? "40px" : "50px")};
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
`;

import { useMutation } from "@tanstack/react-query";
import { adminChangePassword, clientChangePassword } from "app/api/auth";
import { parseError } from "app/utils";

const useAdminChangePassword = () => {
  return useMutation(adminChangePassword, {
    onError: (error) => parseError(error),
  });
};

export default useAdminChangePassword;

export const useClientChangePassword = () => {
  return useMutation(clientChangePassword, {
    onError: (error) => parseError(error),
  });
};

import { ApiResponse } from "app/redux/types";
import request from "../request";
import {
  AdminCreatePromoPayload,
  AdminUpdatePromoPayload,
  BidIdPayload,
  BidsResponse,
  CreateOfferPayload,
  CreateOfferResponse,
  DeletePromoPayload,
  OfferIdPayload,
  OfferResponse,
  PlaceBidPayload,
  PromoObj,
  UpdateBidPayload,
  UpdateOfferPayload,
} from "./types";

export const createOffer = async (payload: CreateOfferPayload) => {
  return await request.post<ApiResponse<CreateOfferResponse>>(
    "/offer/create",
    payload
  );
};

export const updateOffer = async (payload: UpdateOfferPayload) => {
  return await request.post<ApiResponse<OfferResponse>>(
    "/offer/update",
    payload
  );
};

export const deleteOffer = async (payload: OfferIdPayload) => {
  return await request.post<ApiResponse<string>>("/offer/delete", payload);
};

export const getAllClientOffer = async () => {
  return await request.get<ApiResponse<OfferResponse[]>>(`/offer/my_offers`);
};

export const getClientAcceptedOffer = async () => {
  return await request.get<ApiResponse<OfferResponse[]>>(`/offer/active`);
};

export const getClientPendingOffer = async () => {
  return await request.get<ApiResponse<OfferResponse[]>>(`/offer/pending`);
};

export const getClientRejectedOffer = async () => {
  return await request.get<ApiResponse<OfferResponse[]>>(`/offer/rejected`);
};

export const getASingleOffer = async (offer_id: number) => {
  return await request.get<ApiResponse<OfferResponse>>(
    `/offer/get/${offer_id}`
  );
};

export const adminGetAllOffer = async () => {
  return await request.get<ApiResponse<OfferResponse[]>>(`/admin/offer/all`);
};

export const adminApproveOffer = async (payload: OfferIdPayload) => {
  return await request.post<ApiResponse<OfferResponse[]>>(
    `/admin/offer/approve`,
    payload
  );
};

export const adminRejectOffer = async (payload: OfferIdPayload) => {
  return await request.post<ApiResponse<OfferResponse[]>>(
    `/admin/offer/reject`,
    payload
  );
};

export const getAllBid = async () => {
  return await request.get<ApiResponse<BidsResponse[]>>(`/offer/my_bids`);
};

export const getASingleBid = async (bid_id: number) => {
  return await request.get<ApiResponse<BidsResponse>>(`/offer/bid/${bid_id}`);
};

export const acceptBid = async (payload: BidIdPayload) => {
  return await request.post<ApiResponse<BidsResponse>>(
    "/offer/accept_bid",
    payload
  );
};

export const placeBid = async (payload: PlaceBidPayload) => {
  return await request.post<ApiResponse<BidsResponse>>(
    "/offer/place_bid",
    payload
  );
};

export const updateBid = async (payload: UpdateBidPayload) => {
  return await request.post<ApiResponse<BidsResponse>>(
    "/offer/update_bid",
    payload
  );
};

export const deleteBid = async (payload: BidIdPayload) => {
  return await request.post<ApiResponse<string>>("/offer/delete_bid", payload);
};

export const adminCreatePromo = async (payload: AdminCreatePromoPayload) => {
  return await request.post<ApiResponse<PromoObj>>(
    "/admin/promo/create",
    payload
  );
};

export const adminUpdatePromo = async (payload: AdminUpdatePromoPayload) => {
  return await request.post<ApiResponse<string>>(
    "/admin/promo/update",
    payload
  );
};

export const adminDeletePromo = async (payload: DeletePromoPayload) => {
  return await request.post<ApiResponse<string>>(
    "/admin/promo/delete",
    payload
  );
};

export const getAllPromo = async () => {
  return await request.get<ApiResponse<PromoObj[]>>(`/promo/get`);
};

export const getAPromo = async (promo_id: number) => {
  return await request.get<ApiResponse<PromoObj>>(`/promo/get/${promo_id}`);
};

import React from "react";
import styled from "styled-components";
import AvatarContainer from "../AvatarContainer";
import PostTag from "../PostTag";
import { GetPostResponse } from "app/api/community/types";
import { truncateTxt } from "app/utils";
import { useNavigate } from "react-router-dom";
import { useClientIncreasePostViews } from "app/hooks/community/useIncreasePostViews";
import mediaQueries from "styles/utils/mediaQueries";
import { mediaObj } from "styles/utils/media";
import { motion } from "framer-motion";
import { cardVariant } from "app/utils/animationVariants";

interface Props {
  data: GetPostResponse;
}

const BlogCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  const { mutate: increaseViewsMutate } = useClientIncreasePostViews();

  const {
    title,
    slug,
    description,
    views,
    likesCount,
    user,
    created_at,
    tags,
    cover_photo,
    commentsCount,
  } = data;

  const onClickHandler = () => {
    const payload = {
      post_id: data.id,
    };
    increaseViewsMutate(payload);
    navigate(`blogpost/${slug}`);
  };

  return (
    <Container variants={cardVariant} custom={0.8} onClick={onClickHandler}>
      <ImgWrapper>
        <img src={cover_photo?.url} alt="blogpost" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{truncateTxt(title, 38)} </h6>
        <Para>{truncateTxt(description, 190)}</Para>
        <TagWrapper>
          {tags.map((tag) => (
            <PostTag key={tag.id} label={tag.name} />
          ))}
        </TagWrapper>
        <Footer>
          <AvatarContainer
            name={
              (user?.firstname || "admin") + " " + (user?.lastname || "admin")
            }
            time={created_at}
            value={user?.profile_photo?.url}
          />
          <FlexWrapper>
            <span>{`${views} Views`}</span>
            <span>{`${likesCount} Likes`}</span>
            <span>{`${commentsCount} Comments`}</span>
          </FlexWrapper>
        </Footer>
      </ContentWrapper>
    </Container>
  );
};

export default BlogCard;

const Container = styled(motion.div)`
  width: 784px;
  min-height: 248px;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
  cursor: pointer;

  ${mediaObj.smallDesktop} {
    align-items: flex-start;
    gap: 20px;
    width: 100%;
    padding: 20px;
    height: 240px;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    height: max-content;
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ImgWrapper = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaQueries.mobile} {
    height: 150px;
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    img {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    padding: 0 10px 10px;

    & > h6 {
      font-size: 15px;
      line-height: 18px;
      /* width: 75%; */
    }
  }
`;

const Para = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #5e5e5e;
  margin-top: 7px;
  margin-bottom: 10px;

  ${mediaQueries.mobile} {
    margin-top: 5px;
    margin-bottom: 8px;
    font-size: 0.875rem;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #777777;

  ${mediaObj.smallDesktop} {
    gap: 25px;
  }

  ${mediaQueries.mobile} {
    gap: 10px;
  }
`;

const Footer = styled.div`
  display: flex;
  gap: 64px;
  margin-top: auto;

  ${mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    margin-top: 15px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
`;

import { useQuery } from "@tanstack/react-query";
import { getSiteTours } from "app/api/site tour";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const useGetSiteTours = (month: number, year: number) => {
  return useQuery(
    [siteTourKeys.getSiteTours, month, year],
    () => getSiteTours(month, year),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetSiteTours;

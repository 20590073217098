import visionIcon from "../../../images/vision.png";
import missionIcon from "../../../images/mission.png";
import logManager from "../../../images/Team/Log_manager.jpeg";
import teamLead from "../../../images/Team/team_lead.jpeg";
import coFounder from "../../../images/Team/Co_founder.jpeg";
import mediaTeamLead from "../../../images/Team/media_team_lead.jpeg";
import director from "../../../images/Team/director.jpeg";
import adminTeamLead from "../../../images/Team/admin_team_lead.jpeg";
import headOfOperations from "../../../images/Team/head_of_operations.jpeg";
import humanTeamLead from "../../../images/Team/HR.jpg";

export const cardData = [
  {
    title: "Our Vision",
    description: `Our vision is to be the first “agro-to-home” project development company in Africa, securing future spaces to promote unity through agriculture.`,
    icon: visionIcon,
  },
  {
    title: "Our Mission",
    description:
      "Engaging professionals to generate wealth and secure future spaces through in-bond agricultural practices and home development projects.",
    icon: missionIcon,
  },
];

export const teamCard = [
  {
    image: teamLead,
    name: "Adekoya Oluwagbemiga",
    position: "CEO/ Adbond Team Lead",
  },
  {
    image: coFounder,
    name: "Joy Adebayo-Onikeku",
    position: "Co-founder/ Facilities Management Team Lead",
  },
  {
    image: director,
    name: "Awoyemi Stephen",
    position: "Director/Agro Developers' Team Lead",
  },
  {
    image: mediaTeamLead,
    name: "Omowunmi Oluwagbemiga",
    position: "Executive Partner/ Media and Creative Team Lead",
  },
  {
    image: logManager,
    name: "Aigbiremhon Cyril Okonofua",
    position: "Logistics Manager",
  },
  {
    image: adminTeamLead,
    name: "Kate Nwagu",
    position: "Administrative Team Lead",
  },
  {
    image: headOfOperations,
    name: "Felix Anineh",
    position: "Head of Operations",
  },
  {
    image: humanTeamLead,
    name: "Woyengitombara Jessica Williams",
    position: "Human Resource Team Lead",
  },
];

import { UserResponse } from "app/types/common";
import useAdminDeleteUser from "app/hooks/users/useAdminDeleteUser";
import Actions from "app/views/Admin/components/Actions";
import { toast } from "react-toastify";

const ActionRenderer = ({ data }: { data: UserResponse }) => {
  const { mutate: deleteMutate, isLoading: isDeleting } = useAdminDeleteUser();

  const onDelete = () => {
    const payload = {
      user_id: data.id,
    };

    deleteMutate(payload, {
      onSuccess: () => toast.success("User deleted successfully!"),
    });
  };

  return (
    <Actions
      position="horizontal"
      noEdit
      deleteHandler={onDelete}
      isDeleting={isDeleting}
    />
  );
};

export default ActionRenderer;

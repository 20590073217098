import { useMutation } from "@tanstack/react-query";
import { adminMarkAsRead } from "app/api/utilities";
import { parseError } from "app/utils";

const useAdminMarkAsRead = () => {
  return useMutation(adminMarkAsRead, {
    onError: (error) => parseError(error),
  });
};

export default useAdminMarkAsRead;

import { useState } from "react";
import SelectUnstyled, {
  SelectUnstyledProps,
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import { styled as muiStyled } from "@mui/system";
import { labelValueType } from "../../../types/common";
import styled from "styled-components";

interface dropDownProps {
  handleChange?: (value: any) => void;
  dropDownOptions: labelValueType<any, any>[];
  bgColor?: string;
  height?: string;
  initialValue?: number;
}

function CustomSelect(props: SelectUnstyledProps<string | number>) {
  const components: SelectUnstyledProps<string | number>["components"] = {
    Root: StyledButton,
    Listbox: StyledListbox,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

const DropDown: React.FC<dropDownProps> = ({
  dropDownOptions,
  handleChange,
  bgColor,
  height,
  initialValue,
}) => {
  const [value, setValue] = useState<string | number | null>(
    dropDownOptions[initialValue || 0].value
  );

  const handleDropDownChange = (defaultValue: string | number | null) => {
    if (defaultValue) {
      handleChange && handleChange(defaultValue);
      setValue(defaultValue);
    }
  };

  return (
    <DropdownWrapper bgColor={bgColor} height={height}>
      <CustomSelect value={value} onChange={handleDropDownChange}>
        {dropDownOptions.map((option, index) => (
          <StyledOption key={index} value={option.value}>
            {option.label}
          </StyledOption>
        ))}
      </CustomSelect>
    </DropdownWrapper>
  );
};

export default DropDown;

interface dropdownProps {
  bgColor?: string;
  height?: string;
}

const DropdownWrapper = styled.div<dropdownProps>`
  & button {
    background: ${({ bgColor }) => bgColor && bgColor};
    height: ${({ height }) => (height ? height : "20px")};
  }
`;

const StyledButton = muiStyled("button")(
  ({ theme }) => `
  font-size: 0.8125rem;
  box-sizing: border-box;
  width: fit-content;
  border-radius: 5px;
  padding: 0px 8px;
  gap: 4px;
  border: none;
  background: #49B7E4;
  border-radius: 4px;
  text-align: left;
  line-height: 1.5;
  color: #FBFBFB;
  cursor: pointer;
  z-index: 9;
  position: relative;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid #DAECFF;
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
      color: #FBFBFB;
      margin-left: 5px;
    }
  }

  &::after {
    content: '▾';
    color: #FBFBFB;
    float: right;
    margin-left: 5px;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  min-width: 65px;
  background: white;
  border: 1px solid #CDD2D7;
  border-radius: 6px;
  color: #1A2027;
  overflow: auto;
  outline: 0px;
  z-index: 999;
  position: relative;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 3px 8px;
  border-radius: 4px;
  z-index: 999;
  cursor: pointer;
  position: relative;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #ffffff;
    color: #1A2027;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #a4a4a4;
    color: #ffffff;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #B2BAC2;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}, .${optionUnstyledClasses.highlighted}) {
    background-color: #f0f0f0;
    color: #1C1F24;
  }
  `
);

import React from "react";
import { Route } from "react-router-dom";
import { Routes, useLocation } from "react-router";
import AdminLayout from "./Layout";
import Dashboard from "./Dashboard";
import NotFound from "./notFound";
import Projects from "./Projects";
import ProjectDetails from "./Projects/PackageDetails";
import CreateProject from "./Projects/CreatePackage";
import Clients from "./Clients";
import CreateClient from "./Clients/create";
import RequireAuth from "../../hooks/RequireAuth";
import Community from "./Community";
import CreateBlogPost from "./Community/CreateBlogPost";
import Settings from "./Settings";
import SiteTour from "./SiteTour";
import PostDetails from "./Community/PostDetails";
import Analytics from "./Analytics";
import Staff from "./Staff";
import CreateStaff from "./Staff/create";
import StaffDetails from "./Staff/details";
import CreateAssessment from "./Staff/createAssessment";

const AdminRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route
          path="/admin"
          element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>
          }
        >
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/projects" element={<Projects />} />
          <Route path="/admin/projects/:id" element={<ProjectDetails />} />
          <Route path="/admin/projects/new" element={<CreateProject />} />
          <Route path="/admin/clients" element={<Clients />} />
          <Route path="/admin/clients/new" element={<CreateClient />} />
          <Route path="/admin/community" element={<Community />} />
          <Route
            path="/admin/community/blogpost/new"
            element={<CreateBlogPost />}
          />
          <Route
            path="/admin/community/blogpost/:postTitle"
            element={<PostDetails />}
          />
          <Route path="/admin/analytics" element={<Analytics />} />
          <Route path="/admin/settings" element={<Settings />} />
          <Route path="/admin/tour" element={<SiteTour />} />
          <Route path="/admin/staffs" element={<Staff />} />
          <Route
            path="/admin/staffs/assessment"
            element={<CreateAssessment />}
          />
          <Route
            path="/admin/staffs/assessment/:id"
            element={<CreateAssessment />}
          />
          <Route path="/admin/staffs/:id" element={<StaffDetails />} />
          <Route path="/admin/staffs/new" element={<CreateStaff />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AdminRoutes;

import styled from "styled-components";
import { useNavigate } from "react-router";
import { markAsReadPayload, notificationRes } from "app/api/utilities/types";
import { notificationType } from "app/types/common";
import useAdminMarkAsRead from "app/hooks/utilities/useAdminMarkAsRead";

interface props {
  data: notificationRes;
  onClose: () => void;
}

const Entry: React.FC<props> = ({ data, onClose }) => {
  const { id, message, type } = data;
  const navigate = useNavigate();
  const { mutate } = useAdminMarkAsRead();

  const handleNavigate = (type: string) => {
    switch (type) {
      case notificationType.ASSESSMENT:
        navigate("/admin/staffs?activeTab=e-team");
        break;
      case notificationType.GIR:
        navigate("/admin/tour?activeTab=General+Site+Tour");
        break;
      case notificationType.CIR:
        navigate("/admin/tour?activeTab=Custom+Site+Tour");
        break;
      case notificationType.ORDER:
        navigate("/admin/analytics?activeTab=Order+Analysis");
        break;
      case notificationType.PAYMENT:
        navigate(
          "/admin/analytics?activeTab=Payment+Analysis&activeCategory=lands&tableNav=online"
        );
        break;
      case notificationType.OFFERPAYMENT:
        navigate(
          "/admin/analytics?activeTab=Sales+Analysis&activeCategory=lands&tableNav=online"
        );
        break;
      case notificationType.OFFER:
        navigate("/admin/community?activeTab=Offers");
        break;
      case notificationType.BID:
        navigate(
          "/admin/staffs?activeTab=Sales+Analysis&activeCategory=lands&tableNav=online"
        ); //
        break;

      default:
        break;
    }
  };

  const handleMarkAsRead = (id: number, type: string) => {
    const payload: markAsReadPayload = {
      notification_id: id,
    };
    mutate(payload);
    handleNavigate(type);
    onClose();
  };

  return (
    <Container onClick={() => handleMarkAsRead(id, type)}>
      <ContentWrapper>
        <Content>{message}</Content>
        <span>10/12/23</span>
      </ContentWrapper>
    </Container>
  );
};

export default Entry;

const Container = styled.div`
  display: flex;
  gap: 20px;
  height: 70px;
  background: #faa84f1a;
  padding: 12px 20px;
  cursor: pointer;
  transition: all 0.2ms ease-in;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > span {
    letter-spacing: -0.08px;
    color: #727272;
    font-size: 12px;
  }
`;

const Content = styled.div`
  font-size: 15px;
  letter-spacing: -0.1px;
  color: #000000;

  & > span {
    font-weight: 600;
  }
`;

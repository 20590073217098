import Question from "./question";
import { useState } from "react";
import { Btn } from "../createAssessment";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  resetAssessment,
} from "app/redux/assessment/assessmentSlice";
import SetQuestion from "./setQuestion";
import styled from "styled-components";
import EditQuestionModal from "./modals/editQuestion";
import { assessmentObj, questionPayload } from "app/api/staff/types";
import useAdminCreateAssessment from "app/hooks/staffs/useAdminCreateAssessment";
import { toast } from "react-toastify";
import DualRing from "app/views/Web/components/loader";
import { useNavigate, useParams } from "react-router";
import useAdminEditAssessment from "app/hooks/staffs/useAdminEditAssessment";

const CreateQuestions = () => {
  const [questionToEdit, setQuestionToEdit] = useState<{
    data: questionPayload;
    index: number;
  }>();
  const { assessment } = useAppSelector(assessmentSelector);
  const { mutate, isLoading } = useAdminCreateAssessment();
  const { mutate: updateMutate, isLoading: updateIsLoading } =
    useAdminEditAssessment();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/admin/staffs?activeTab=assessment");
  };

  const handleSubmit = () => {
    if (assessment) {
      const payload: assessmentObj = assessment;
      !!id
        ? updateMutate(
            { ...payload, assessment_id: Number(id) },
            {
              onSuccess: () => {
                toast.success("Assessment updated successfully");
                dispatch(resetAssessment());
                handleGoBack();
              },
            }
          )
        : mutate(payload, {
            onSuccess: () => {
              toast.success("Assessment created successfully");
              dispatch(resetAssessment());
              handleGoBack();
            },
          });
    }
  };

  return (
    <Wrapper>
      {assessment?.title && (
        <AssessmentBox>
          <AssessmentTxt>{`Assessment Title: ${assessment?.title}`}</AssessmentTxt>
          <AssessmentTxt>{`Assessment Description: ${assessment?.description}`}</AssessmentTxt>
        </AssessmentBox>
      )}
      <Box display={"flex"} flexDirection={"column"} gap={"25px"}>
        {assessment?.questions?.map((x, idx) => (
          <SetQuestion
            key={idx}
            index={idx + 1}
            data={x}
            editHandler={setQuestionToEdit}
          />
        ))}
      </Box>
      <Question index={(assessment?.questions?.length || 0) + 1} />
      <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Btn onClick={handleSubmit}>
          {isLoading || updateIsLoading ? (
            <DualRing color="#fff" width={"16px"} height={"16px"} />
          ) : (
            `Save`
          )}
        </Btn>
      </Box>
      <EditQuestionModal
        closeHandler={() => setQuestionToEdit(undefined)}
        isOpen={!!questionToEdit}
        data={questionToEdit?.data}
        index={questionToEdit?.index || 1}
        width={"58vw"}
      />
    </Wrapper>
  );
};

export default CreateQuestions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssessmentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0px 16px 24px;
  gap: 16px;
  width: 581px;
  background: #ddeef8;
  border-radius: 4px;
  margin-bottom: 30px;
`;

const AssessmentTxt = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #1b1b1b;
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import ClientGrid from "./components/grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import Paginating from "app/views/Admin/components/Pagination";
import useGetAllStaffClients from "app/hooks/users/useGetAllStaffClients";

const MyClients = () => {
  // const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading, error } = useGetAllStaffClients();
  // const size = 9;
  // const pageCount = data && Math.floor(data.total / size) + 1;
  // const startCount = size * (currentPage - 1) + 1;
  // const endCount = size * (currentPage - 1) + size;

  useEffect(() => {
    error && toast.error(error as string);
  }, [error]);

  // const onPageChange = (page: number) => {
  //   setCurrentPage(page);
  // };

  return (
    <Container>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Header>Clients</Header>
      </Box>
      <ClientGrid data={data || []} isLoading={isLoading} />
      {/* {data && (
        <Footer>
          <Show>
            Showing {startCount} to{" "}
            {data?.total && endCount < data?.total ? endCount : data?.total} of{" "}
            {data?.total}
          </Show>

          <Paginating
            totalElements={data?.total || 0}
            size={size}
            pageCount={pageCount}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </Footer>
      )} */}
    </Container>
  );
};

export default MyClients;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  height: 80vh;
  width: 95%;
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

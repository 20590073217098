import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import DualRing from "app/views/Web/components/loader";
import React from "react";
import { NoProjectText, SpinnerWrapper } from "../../..";
import ProjectGrid from "./grid";

interface Props {
  selectedCategory: number;
}

const Details: React.FC<Props> = ({ selectedCategory }) => {
  const {
    data: fetchedProjectsPerCategory,
    isLoading: projectsPerCategoryIsLoading,
  } = useGetAllProjectPerCategory(selectedCategory);

  if (projectsPerCategoryIsLoading)
    return (
      <SpinnerWrapper>
        <DualRing width="20px" height="20px" color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedProjectsPerCategory && fetchedProjectsPerCategory.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no available project yet</NoProjectText>
      </SpinnerWrapper>
    );
  return (
    <>
      {fetchedProjectsPerCategory && (
        <ProjectGrid data={fetchedProjectsPerCategory} />
      )}
    </>
  );
};

export default Details;

import { useEffect, useState } from "react";
import {
  BgShadow1,
  BgShadow2,
  CirclesWrapper,
  Dot,
  GridContainer,
  HeroImageContainer,
  HeroImg1,
  HeroImg2,
  ImpactContainer,
  LabelTag1,
  LabelTag2,
  StarWrapper,
  VectorWrapper,
} from "./style";
import HeroImage1 from "../../../images/web/homes-image3.jpg";
// import HeroImage2 from "../../../images/web/hero-image2.png";
import heroFrame2 from "../../../images/web/pinapple-image.jpg";
import { ReactComponent as TwoCirclesImg } from "../../../images/web/two-circles.svg";
import { ReactComponent as StarImg } from "../../../images/web/star.svg";
import { ReactComponent as VectorLineImg } from "../../../images/web/Vector-line.svg";
import SearchSelectBox from "../components/search/SearchSelectBox";
import { homepageNumbersRes } from "../../../api/utilities/types";
import api from "../../../api";
import { toast } from "react-toastify";
import EastIcon from "@mui/icons-material/East";
import styled from "styled-components";
import { fadeIn, popUp, slideIn } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";

const initialState: homepageNumbersRes = {
  cities: 0,
  projects: 0,
  years: 0,
};

interface Props {
  goToSearchElement: () => void;
}

const Hero: React.FC<Props> = ({ goToSearchElement }) => {
  const [homepageNumbers, setHomepageNumbers] =
    useState<homepageNumbersRes>(initialState);
  const navigate = useNavigate();

  useEffect(() => {
    async function getCategories() {
      try {
        const res = await api.utilityService.homepageNumbers();
        setHomepageNumbers(res.data.data);
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }

    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = {
    animate: {
      transition: {
        staggerChildren: 0.007,
      },
    },
  };

  return (
    <GridContainer animate={"animate"} initial={"initial"}>
      <motion.div
        className="title"
        initial="initial"
        animate={"animate"}
        variants={container}
      >
        {/* {placeholderText.map((item, index) => {
          return <WavyText {...item} key={index} />;
        })} */}
        <motion.h1 variants={slideIn("left")}>
          Welcome to Adbond Harvest and Homes
        </motion.h1>
        <motion.h2 variants={slideIn("right")} custom={1}>
          We are an “agro-to-home” development company. We help you secure a
          future space you can call home.
        </motion.h2>
        <SeeProjectsBtn
          variants={popUp}
          custom={3}
          onClick={() => navigate(`/project`)}
        >
          See Projects
          <EastIcon />
        </SeeProjectsBtn>
      </motion.div>

      <div className="left">
        <HeroImageContainer variants={popUp} custom={1}>
          <HeroImg1>
            <img src={HeroImage1} alt="landing" />
            <BgShadow1 />
            <LabelTag1>
              <Dot bg="#49B7E4" />
              Home
            </LabelTag1>
          </HeroImg1>
          <HeroImg2>
            <img src={heroFrame2} alt="landing" className="small-image" />
            <BgShadow2 />
            <LabelTag2>
              <Dot bg="#AFD34F" />
              Agro
            </LabelTag2>
            <CirclesWrapper>
              <TwoCirclesImg />
            </CirclesWrapper>

            <StarWrapper>
              <StarImg />
            </StarWrapper>

            <VectorWrapper>
              <VectorLineImg />
            </VectorWrapper>
          </HeroImg2>
        </HeroImageContainer>
      </div>
      <SearchSelectBox goToSearchElement={goToSearchElement} />
      <ImpactContainer variants={fadeIn()} custom={6}>
        <div className="content">
          <span>{homepageNumbers.projects}</span>
          <span>{homepageNumbers.projects > 1 ? "Projects" : "Project"}</span>
        </div>
        <div className="content">
          <span>{homepageNumbers.cities}</span>
          <span>{homepageNumbers.cities > 1 ? "Cities" : "City"}</span>
        </div>
        <div className="content">
          <span>{homepageNumbers.years}</span>
          <span>{homepageNumbers.years > 1 ? "Years" : "Year"}</span>
        </div>
        <div className="content">
          <span>90%</span>
          <span>Satisfied Clients</span>
        </div>
      </ImpactContainer>
    </GridContainer>
  );
};

export default Hero;

const SeeProjectsBtn = styled(motion.button)`
  width: max-content;
  height: 56px;
  padding: 16px 30px;
  border: none;
  border-radius: 8px;
  background: #afd34f;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 13px;
  margin-top: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  transition: all 0.3s ease-out;

  & > svg {
    transition: all 0.3s ease;
  }

  &:hover {
    background-color: #697f2f;

    & > svg {
      transform: translateX(6px);
    }
  }
`;

import { useEffect, useRef, useState } from "react";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import Spinner from "app/views/Web/components/Spinner";
import { capitalize } from "lodash";
import styled from "styled-components";
import { PaymentTabs, showData } from "../data";
import { ReactComponent as SearchIcon } from "../../../../images/admin/search-icon.svg";
import DropDown from "../../components/dropDown";
import OnlineTable from "./grid/OnlineTable";
import OfflineTable from "./grid/OfflineTable";
import Paginating from "../../components/Pagination";
import AddIcon from "@mui/icons-material/Add";
import CreateBankAccount from "./modals/CreateBankAccount";
import DisplayAccountTable from "./DisplayAccountTable";
import { useSearchParams } from "react-router-dom";
import useAdminGetPaymentsByCategory from "app/hooks/payment/useAdminGetPaymentsByCategory";
import useAdminPaymentSearchByCategory from "app/hooks/payment/useAdminPaymentSearchByCategory";
import { adminGetPaymentsByCategoryResponse } from "app/api/payment/types";
import { PaginatedResponse } from "app/redux/types";
import { toast } from "react-toastify";
import useAdminGetPaymentExport from "app/hooks/payment/useAdminGetPaymentExport";
import { useReactToPrint } from "react-to-print";
import { categoryResponse } from "app/api/project/types";

export enum TableNav {
  online = "online",
  offline = "offline",
  bank = "bank",
}

export enum DisplayPaymentQuery {
  activeCategory = "activeCategory",
  tableNav = "tableNav",
  edit_bank = "edit_bank",
}

const DisplayPayment = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const editBank = searchParams.get(DisplayPaymentQuery.edit_bank);
  const getCategoryParams = searchParams.get(
    DisplayPaymentQuery.activeCategory
  );
  const getTableParams = searchParams.get(DisplayPaymentQuery.tableNav);
  const [searchResult, setSearchResult] =
    useState<PaginatedResponse<adminGetPaymentsByCategoryResponse[]>>();
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [miniTab, setMinTab] = useState(TableNav.online);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const componentRef = useRef<HTMLDivElement>(null);

  const { data: fetchedAllCategory, isLoading: allCategoryIsLoading } =
    useGetAllCategories();

  const {
    data: paymentsData,
    isLoading: paymentLoading,
    isError: paymentError,
  } = useAdminGetPaymentsByCategory(
    selectedCategory as number,
    page,
    perPage,
    miniTab
  );

  const { isFetching: isExporting, refetch } = useAdminGetPaymentExport(
    selectedCategory as number,
    miniTab,
    false
  );

  const { mutate: searchMutate, isLoading: isSearching } =
    useAdminPaymentSearchByCategory();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePerPage = (value: any) => {
    setPerPage(value);
    setPage(1);
  };

  const onSearch = (page: number) => {
    const payload = {
      result: {
        search_string: searchText,
      },
      category_id: selectedCategory as number,
      page: page,
      per_page: perPage,
      type: miniTab,
    };
    searchMutate(payload, {
      onSuccess: (data) => {
        if (data.data.total === 0) {
          toast.error("Search not found!");
        } else {
          setSearchResult(data.data);
        }
      },
    });
  };

  useEffect(() => {
    if (fetchedAllCategory && fetchedAllCategory.length > 0) {
      setSelectedCategory(fetchedAllCategory[0].id);
    }
  }, [fetchedAllCategory]);

  useEffect(() => {
    if (editBank && miniTab === TableNav.bank) setOpen(true);
  }, [editBank, miniTab]);

  const onPageChange = (page: number) => {
    setPage(page);
    searchText && onSearch(page);
  };

  const handleClose = () => {
    setOpen(false);
    searchParams.delete(DisplayPaymentQuery.edit_bank);
    setSearchParams(searchParams);
  };
  const handleOpen = () => setOpen(true);

  useEffect(() => {
    if (
      fetchedAllCategory &&
      fetchedAllCategory.length > 0 &&
      !getCategoryParams
    ) {
      setSelectedCategory(fetchedAllCategory[0].id);
      searchParams.set(
        DisplayPaymentQuery.activeCategory,
        fetchedAllCategory[0].name
      );
      setSearchParams(searchParams);
    }
  }, [fetchedAllCategory, setSearchParams, getCategoryParams, searchParams]);

  useEffect(() => {
    if (getCategoryParams && fetchedAllCategory) {
      const result = fetchedAllCategory.filter(
        (ev) => ev.name === getCategoryParams
      );
      setSelectedCategory(result[0].id);
    }
  }, [fetchedAllCategory, getCategoryParams]);

  const onTabChange = (x: categoryResponse) => {
    setSelectedCategory(x.id);
    searchParams.set(DisplayPaymentQuery.activeCategory, x.name);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!getTableParams) {
      searchParams.set(DisplayPaymentQuery.tableNav, TableNav.online);
      setSearchParams(searchParams);
    }
  }, [setSearchParams, getTableParams, searchParams]);

  useEffect(() => {
    if (getTableParams) {
      const result = PaymentTabs.filter((ev) => ev.id === getTableParams);
      setMinTab(result[0].id);
    }
  }, [getTableParams]);

  if (allCategoryIsLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedAllCategory && fetchedAllCategory.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no available category yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      <TabsWrapper>
        {fetchedAllCategory?.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedCategory}
            onClick={() => onTabChange(x)}
          >
            {capitalize(x.name)}
          </Tab>
        ))}
      </TabsWrapper>
      <Wrapper>
        <BtnWrapper>
          <MiniTabWrapper>
            {PaymentTabs?.map((x, i) => (
              <MiniTab
                key={i}
                isActive={x.id === miniTab}
                onClick={() => {
                  setSearchText("");
                  setSearchResult(undefined);
                  setPage(1);
                  setMinTab(x.id);
                  searchParams.set(DisplayPaymentQuery.tableNav, x.id);
                  setSearchParams(searchParams);
                }}
              >
                {capitalize(x.name)}
              </MiniTab>
            ))}
          </MiniTabWrapper>

          {miniTab === TableNav.bank && (
            <CreateBtn onClick={handleOpen}>
              <AddIcon />
              Create Bank Account
            </CreateBtn>
          )}
        </BtnWrapper>
        {miniTab !== TableNav.bank && (
          <Middle>
            <DropWrapper>
              <Print onClick={() => refetch()}>
                {isExporting ? (
                  <Spinner color="white" />
                ) : (
                  "Export as excel sheet"
                )}
              </Print>
              <Print onClick={() => handlePrint()}>Print</Print>
              <DropDown
                dropDownOptions={showData}
                bgColor={"#3D9AC0"}
                height="100%"
                handleChange={handlePerPage}
              />
            </DropWrapper>
            <SearchBox
              onSubmit={(event) => {
                event.preventDefault();
                onSearch(page);
              }}
            >
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  setPage(1);
                  setSearchText(e.target.value);
                }}
              />
              {isSearching ? (
                <Spinner color="black" />
              ) : (
                <SearchIcon
                  onClick={() => {
                    onSearch(page);
                  }}
                />
              )}
            </SearchBox>
          </Middle>
        )}

        {paymentLoading && (
          <SpinnerWrapper>
            <Spinner color="#97B644" />
          </SpinnerWrapper>
        )}

        {paymentError && !paymentLoading && (
          <SpinnerWrapper>
            <NoProjectText>An error occurred</NoProjectText>
          </SpinnerWrapper>
        )}

        {paymentsData && !paymentError && !paymentLoading && (
          <>
            {miniTab === TableNav.online && (
              <OnlineTable
                data={searchResult?.data || paymentsData.data}
                ref={componentRef}
              />
            )}
            {miniTab === TableNav.offline && (
              <OfflineTable
                data={searchResult?.data || paymentsData.data}
                ref={componentRef}
              />
            )}
            {miniTab === TableNav.bank && <DisplayAccountTable />}
            {miniTab !== TableNav.bank && paymentsData.data.length > 0 && (
              <Footer>
                <Show>
                  Showing 1 to{" "}
                  {searchResult?.data.length || paymentsData.data.length} of{" "}
                  {searchResult?.total || paymentsData.total}
                </Show>

                <Paginating
                  totalElements={
                    searchResult ? searchResult.total : paymentsData.total
                  }
                  size={
                    searchResult ? searchResult.per_page : paymentsData.per_page
                  }
                  pageCount={3}
                  currentPage={page}
                  onChange={onPageChange}
                />
              </Footer>
            )}
          </>
        )}
      </Wrapper>
      {open && <CreateBankAccount open={open} handleClose={handleClose} />}
    </>
  );
};

export default DisplayPayment;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  height: 30px;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  border-bottom: ${({ isActive }) => (isActive ? "3px solid #AFD34F" : "none")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  padding: 34px 37px;
  background: #eef5e0;
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const MiniTabWrapper = styled.div`
  width: max-content;
  display: flex;
  background: #97b644;
  border-radius: 4px;
  padding: 8px;
`;

interface MiniTabProps {
  isActive?: boolean;
}

const MiniTab = styled.div<MiniTabProps>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 14px;
  letter-spacing: 0.18px;
  padding: 8px 40px;
  border-radius: 4px;
  color: ${({ isActive }) => (isActive ? "#181D0B" : "#FBFBFB")};
  background: ${({ isActive }) => (isActive ? "#FFFFFF" : "transparent")};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`;

const Middle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
`;

const SearchBox = styled.form`
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  border-radius: 12px;
  width: 475px;
  height: 52px;

  input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }
  & > svg {
    color: #303030;
    cursor: pointer;
  }
`;

const DropWrapper = styled.div`
  display: flex;
  gap: 24px;
  min-height: 32px;

  .css-ewg7x9 {
    width: 103px;
    height: 28px;
  }

  .MuiSelectUnstyled-popper {
    z-index: 99 !important;
  }
`;

const Print = styled.div`
  padding: 7px 12px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 18px;
  color: #ffffff;
  background: #3d9ac0;
  border-radius: 4px;
  height: 100%;
  opacity: 0.9;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

const CreateBtn = styled.button`
  width: 240px;
  height: 44px;
  background: #49b7e4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #327f9e;
    transition: all 0.2s ease-out;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 27px;
`;

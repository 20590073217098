import { useQuery } from "@tanstack/react-query";
import { clientsPerPackage } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useClientsPerPackage = (packageId: string) => {
  return useQuery(
    [ProjectsKey.clientsPerPackage],
    () => clientsPerPackage(packageId),
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useClientsPerPackage;

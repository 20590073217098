import React from "react";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Box } from "@mui/material";
import { truncateTxt } from "../../../../utils";

interface Props {
  type: "video" | "image";
  label: string;
  size?: string;
  content: string;
  format?: string;
  isExtra?: boolean;
  onDelete: () => void;
}

const PreviewBox: React.FC<Props> = ({
  type,
  label,
  size,
  content,
  isExtra,
  format,
  onDelete,
}) => {
  return (
    <Container isExtra={isExtra}>
      <div className="cancel" onClick={onDelete}>
        <HighlightOffIcon />
      </div>
      <div className="content">
        {type === "video" ? (
          <video src={content}></video>
        ) : (
          <img src={content} alt="" />
        )}
      </div>

      <Box flexDirection={"column"} display="flex" className="text">
        <div>
          {truncateTxt(label || "", 15)}
          {format}
        </div>
        <span>{size}</span>
      </Box>
    </Container>
  );
};

export default PreviewBox;

interface ContainerProps {
  isExtra?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 11px;
  position: relative;
  background: #ffffff;
  padding: ${({ isExtra }) => (isExtra ? "9px 12px" : "12px 15px")};
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;

  .content {
    width: 64.16px;
    height: 46.92px;
    border-radius: 3px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }

    video {
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }

  .text {
    & > div {
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      color: #2a3313;
    }

    & > span {
      font-weight: 500;
      font-size: 10px;
      line-height: 140%;
      color: #777777;
    }
  }

  .cancel {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    right: -15px;
    top: -15px;
    background: #fbfbfb;
    cursor: pointer;
    svg {
      width: ${({ isExtra }) => (isExtra ? "20px" : "22px")};
      height: ${({ isExtra }) => (isExtra ? "20px" : "22px")};
      fill: #b3261e;
    }
  }
`;

import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import { getTotalPromo } from "app/utils";
import DualRing from "app/views/Web/components/loader";
import React from "react";
import styled from "styled-components";
import { NoProjectText, SpinnerWrapper } from "..";
import ProjectCard from "../../components/Cards/projectCard";

interface Props {
  selectedLocation: number;
}

const AllPackage: React.FC<Props> = ({ selectedLocation }) => {
  const {
    data: fetchedPackagePerLocation,
    isLoading: packagePerLocationIsLoading,
  } = useGetAllPackagePerLocation(selectedLocation);

  if (packagePerLocationIsLoading)
    return (
      <SpinnerWrapper>
        <DualRing width="20px" height="20px" color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedPackagePerLocation && fetchedPackagePerLocation.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no package for this project</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      <CardWrapper>
        {fetchedPackagePerLocation &&
          fetchedPackagePerLocation.map((x, i) => (
            <ProjectCard
              {...x}
              id={x.id}
              key={i}
              packageName={x.name}
              isMain
              addClientField
              clientsNo={x.clients}
              plots={x.items[0]?.available_units}
              mainFile={x.main_file}
              location={x.project_location.location}
              address={x.project_location.address}
              isSoldOut={x.sold_out}
              isOnPromo={x.onPromo}
              promoDiscount={getTotalPromo(x.promos)}
            />
          ))}
      </CardWrapper>
    </>
  );
};

export default AllPackage;

const CardWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 100%;
`;

import React, { useEffect, useState } from "react";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import { SingleOption } from "app/types/common";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { Form, Formik } from "formik";
import { CreateBtn, FlexWrapper, FormWrapper } from "../sections/styles";
import { tourDatesValidation, tourDetailsValidation } from "../validation";
import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import ModalWrapper from "./ModalWrapper";
import NotifyDialog from "./NotifyDialog";
import { toast } from "react-toastify";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import ScheduleBox from "../ScheduleBox";
import usePostSiteInspection from "app/hooks/site tour/usePostSiteInspection";
import useGetInspectionDaysPerLocation from "app/hooks/site tour/useGetInspectionDaysPerLocation";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const ScheduleTour: React.FC<Props> = ({ open, handleClose }) => {
  const [step, setStep] = useState(1);
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const [location, setLocation] = useState<SingleOption>();
  const [packages, setPackages] = useState<SingleOption>();
  const [openDialog, setOpenDialog] = useState(false);
  const handleDialogClose = () => setOpenDialog(false);
  const handleDialogOpen = () => setOpenDialog(true);
  const { user } = useAppSelector(getAuth);
  const [selectedTourDate, setSelectedTourDate] = useState<SingleOption>();

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType?.value as number);

  const { data: packagePerLocationData, isLoading: packagePerLocationLoading } =
    useGetAllPackagePerLocation(location?.value as number);

  const { data: inspectionDaysData, isLoading: isInspecting } =
    useGetInspectionDaysPerLocation(location?.value as number);

  const { mutate: createMutate, isLoading: isCreating } =
    usePostSiteInspection();

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  useEffect(() => {
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType]);

  useEffect(() => {
    setLocation(undefined);
    setPackages(undefined);
  }, [projectType]);

  useEffect(() => {
    setPackages(undefined);
  }, [location]);

  const onClose = () => {
    handleClose();
    handleDialogClose();
  };

  const formatDate = (date: string) => {
    const result = new Date(date);
    return result.toISOString().substring(0, 10);
  };

  const onSubmit = () => {
    if (!selectedTourDate) return;
    if (!packages || !user) return;
    const payload = {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      package_id: packages.value,
      proposed_date: formatDate(selectedTourDate.label),
      inspection_day_id: selectedTourDate.value,
      phone_number: user.phone_number,
    };
    createMutate(payload, {
      onSuccess: () => {
        handleDialogOpen();
        toast.success("Site Tour Book Successfully!");
      },
    });
  };

  const isAvailableDate = inspectionDaysData && inspectionDaysData.length > 0;

  return (
    <>
      {openDialog ? (
        <NotifyDialog open={openDialog} handleClose={onClose} />
      ) : (
        <ModalWrapper
          open={open}
          title={"Schedule a site tour"}
          handleClose={handleClose}
          applyBackBtn={step === 2}
          goBack={() => setStep(1)}
        >
          <Formik
            initialValues={
              step === 1
                ? {
                    categoryType,
                    projectType,
                    location,
                    packages,
                  }
                : {
                    selectedTourDate,
                  }
            }
            validationSchema={
              step === 1 ? tourDetailsValidation : tourDatesValidation
            }
            enableReinitialize
            onSubmit={() => {
              if (step === 2) {
                onSubmit();
              } else {
                setStep(2);
              }
            }}
          >
            <FormWrapper>
              <Form>
                {step === 1 ? (
                  <>
                    <FlexWrapper>
                      <SelectField
                        name="categoryType"
                        label="Category"
                        value={categoryType as SingleOption}
                        onChange={(e) => setCategoryType(e as SingleOption)}
                        options={formatData(allCategoryData)}
                        selectType="normal"
                        isLoading={allCategoryLoading}
                      />

                      <SelectField
                        name="projectType"
                        label="Project"
                        value={projectType as SingleOption}
                        onChange={(e) => setProjectType(e as SingleOption)}
                        disabled={!categoryType}
                        options={formatData(projectPerCategoryData)}
                        selectType="normal"
                        isLoading={projectPerCategoryLoading}
                      />
                    </FlexWrapper>
                    <FlexWrapper>
                      <SelectField
                        name="location"
                        label="Location"
                        value={location as SingleOption}
                        onChange={(e) => setLocation(e as SingleOption)}
                        disabled={!projectType}
                        options={formatData(locationPerProjectData)}
                        selectType="normal"
                        isLoading={locationPerProjectLoading}
                      />
                      <SelectField
                        name="packages"
                        label="Package"
                        value={packages as SingleOption}
                        onChange={(e) => setPackages(e as SingleOption)}
                        disabled={!location}
                        options={formatData(packagePerLocationData)}
                        selectType="normal"
                        isLoading={packagePerLocationLoading}
                      />
                    </FlexWrapper>
                  </>
                ) : (
                  <>
                    <Content>
                      {isAvailableDate ? (
                        <>
                          <h6>Select an available date for tour</h6>
                          <ScheduleWrapper>
                            {inspectionDaysData?.map((item) => (
                              <ScheduleBox
                                key={item.id}
                                value={item.id}
                                label={new Date(
                                  item.day.dates[0]
                                ).toLocaleString("en-us", {
                                  day: "2-digit",
                                  month: "long",
                                  year: "numeric",
                                })}
                                selectedValue={selectedTourDate?.value}
                                setSelectedTourDate={setSelectedTourDate}
                                name="selectedTourDate"
                              />
                            ))}
                          </ScheduleWrapper>
                        </>
                      ) : (
                        <NoContent>
                          Oops, No available site tour date for now.
                        </NoContent>
                      )}
                    </Content>
                    <NoteWrapper>
                      For more information or guidance on scheduling a site
                      tour, reach out to us via
                      <InfoLink href="mailTo: sitetour@adbondharvestandhomes.com">
                        {" "}
                        sitetour@adbondharvestandhomes.com
                      </InfoLink>{" "}
                    </NoteWrapper>
                  </>
                )}
                <CreateBtn
                  type="submit"
                  disabled={!isAvailableDate && step === 2}
                >
                  {((step === 2 && isInspecting) || isCreating) && (
                    <Spinner color="black" />
                  )}
                  {step === 1 ? "Next" : "Confirm"}
                </CreateBtn>
              </Form>
            </FormWrapper>
          </Formik>
        </ModalWrapper>
      )}
    </>
  );
};

export default ScheduleTour;

const Content = styled.div`
  background: #cde6f5;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 25px;

  h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    margin-bottom: 24px;
  }

  ${mediaObj.mobile} {
    padding: 16px;
  }
`;

const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const NoteWrapper = styled.div`
  font-weight: 450;
  font-size: 14px;
  line-height: 140%;
  color: #2a3313;
  text-align: center;
`;

const InfoLink = styled.a`
  color: #afd34f;
`;

const NoContent = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { settingsRoute } from "./data";
import { useEffect } from "react";
import mediaQueries from "styles/utils/mediaQueries";
import { SettingsTabEnum } from "app/types/common";
import { AnimatePresence } from "framer-motion";
import UserProfile from "./UserProfile";
import Security from "./Security";
import Details from "./details";

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const searchParam = searchParams.get("activeTab");

  useEffect(() => {
    !searchParam && setSearchParams({ activeTab: SettingsTabEnum.Details });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (id: SettingsTabEnum) => {
    setSearchParams({ activeTab: id.toString() });
  };

  const getActive = (link: string) => {
    return searchParam === link;
  };

  return (
    <Container>
      <SideNav>
        <h6>Settings</h6>
        <NavWrapper>
          {settingsRoute.map((item) => (
            <LinkWrapper
              isActive={getActive(item.link)}
              onClick={() => handleTabChange(item.link)}
            >
              {item.title}
            </LinkWrapper>
          ))}
        </NavWrapper>
      </SideNav>
      <AnimatePresence exitBeforeEnter>
        <Content>
          {searchParam === SettingsTabEnum.UserProfile && <UserProfile />}
          {searchParam === SettingsTabEnum.Security && <Security />}
          {searchParam === SettingsTabEnum.Details && <Details />}
        </Content>
      </AnimatePresence>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  display: flex;
  gap: 15px;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

const SideNav = styled.div`
  padding: 48px 16px;
  background-color: white;
  min-width: 190px;
  & > h6 {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #2a3313;
    margin-bottom: 48px;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    background: transparent;

    & > h6 {
      margin: 10px 10px 15px;
    }
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;

  ${mediaQueries.mobile} {
    flex-direction: row;
    justify-content: space-between;
    gap: 0;
    width: 90%;
    margin: 0 auto;
  }
`;

interface LinkWrapperProps {
  isActive: boolean;
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: ${({ isActive }) => (isActive ? "#97B644" : "#3e4b1c")};
  border-left: ${({ isActive }) => (isActive ? "5px solid #97B644" : "none")};
  padding: 5px 20px;
  transition: all 0.3s ease-out;
  cursor: pointer;

  &:hover {
    color: #97b644;
  }

  ${mediaQueries.mobile} {
    font-size: 14px;
    padding: 5px 9px;
    border-left: none;
    border-bottom: ${({ isActive }) =>
      isActive ? "5px solid #97B644" : "none"};
  }
`;

const Content = styled.div`
  background-color: white;
  padding: 40px 24px;
  flex: 1;

  ${mediaQueries.mobile} {
    padding: 0 15px 20px;
  }
`;

import { useCommunityGetOffers } from "app/hooks/community/useGetOffers";
import { ErrorText, NoProjectText, SpinnerWrapper } from "./styles";
import Spinner from "app/views/Web/components/Spinner";
import OfferCard from "./cards/OfferCard";
import { motion } from "framer-motion";
import styled from "styled-components";
import { delayStagger } from "app/utils/animationVariants";

const DisplayOffers = () => {
  const { data, isLoading, isError } = useCommunityGetOffers();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <ErrorText>An error occurred. Please try again later</ErrorText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no content for this yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <Container
      variants={delayStagger(1)}
      custom={0.7}
      viewport={{ once: true, amount: 0.2 }}
      whileInView={"animate"}
      initial={"initial"}
    >
      {data && data.map((ev) => <OfferCard key={ev.id} data={ev} />)}
    </Container>
  );
};

export default DisplayOffers;

const Container = styled(motion.div)`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

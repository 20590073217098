import { Box } from "@mui/material";
import { questionToDisplay } from "app/api/assessment/types";
import { useAppSelector } from "app/redux";
import { assessmentSelector } from "app/redux/assessment/assessmentSlice";
import { Dispatch, FC, SetStateAction } from "react";
import styled from "styled-components";

interface props {
  data: questionToDisplay;
  index: number;
  setSelectedOptionId: Dispatch<SetStateAction<number | undefined>>;
  selectedOptionId?: number;
}

const Question: FC<props> = ({
  index,
  data,
  setSelectedOptionId,
  selectedOptionId,
}) => {
  const { questionObj: question } = data;
  const { isTimeUp } = useAppSelector(assessmentSelector);
  isTimeUp && setSelectedOptionId(undefined);

  return (
    <Wrapper>
      <Box display={"flex"} flexDirection={"column"} gap={"12px"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Title>Question {index}</Title>
        </Box>
        {question.question && (
          <QuestionTxt
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          />
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={"9px"}>
        {question.options &&
          question.options.map(({ id, value, answer: isAnswer }, idx) => (
            <Box display={"flex"} alignItems={"center"} gap={"90px"} key={idx}>
              <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                {value && (
                  <OptionLabel onClick={() => setSelectedOptionId(id)}>
                    <input
                      type={"radio"}
                      name={"options"}
                      checked={selectedOptionId === id}
                      disabled={isTimeUp}
                    />
                    <Option
                      dangerouslySetInnerHTML={{
                        __html: value,
                      }}
                    />
                  </OptionLabel>
                )}
              </Box>
              {isAnswer && <Answer>Answer</Answer>}
            </Box>
          ))}
      </Box>
    </Wrapper>
  );
};

export default Question;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 12px;
  min-height: 200px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 144%;
  color: #181d0b;
`;

const QuestionTxt = styled.p`
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: #2a3313;
`;

const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 14px;
  cursor: pointer;

  & > input {
    accent-color: #afd34f;
  }
`;

const Option = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #464646;
`;

const Answer = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #48b6e3;
`;

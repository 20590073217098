import useGetClientPackages from "app/hooks/clients/useGetClientPackages";
import { useTabletCheck } from "app/hooks/useTabletCheck";
import { truncateTxt } from "app/utils";
import { PropertiesRoutes, UserRoutes } from "app/utils/data";
import Spinner from "app/views/Web/components/Spinner";
import { useNavigate } from "react-router";
import PropertyCard from "../../components/Cards/property";
import { CardWrapper, NoProjectText, SpinnerWrapper } from "./styles";

const MyProperties = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useGetClientPackages();
  const isTab = useTabletCheck();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>You have no property yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <CardWrapper>
      {data &&
        data.map((ev) => (
          <PropertyCard
            key={ev.id}
            categoryName={ev.category.name}
            packageName={ev.package.name}
            propertyName={ev.project.project}
            locationAddress={truncateTxt(
              ev.project?.location + " " + (ev?.project?.address || ""),
              50
            )}
            packagePrice={+ev.price}
            mainFile={ev.package.main_file}
            isMain
            width="unset"
            isInstallmental={!ev.fully_paid}
            onCardClick={() =>
              ev.fully_paid
                ? navigate(
                    `${UserRoutes.Properties}/${PropertiesRoutes.Complete}/${ev.id}`
                  )
                : navigate(
                    `${UserRoutes.Properties}/${PropertiesRoutes.Installment}/${ev.id}`
                  )
            }
            isTab={isTab}
          />
        ))}
    </CardWrapper>
  );
};

export default MyProperties;

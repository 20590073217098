import { useQuery } from "@tanstack/react-query";
import { clientGetProfile } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useClientGetProfile = () => {
  return useQuery([UserKeys.clientGetProfile], clientGetProfile, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useClientGetProfile;

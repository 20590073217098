import React, { useEffect, useState } from "react";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import useGetAllLocationPerProject from "app/hooks/projects/useGetAllLocationPerProject";
import useGetAllProjectPerCategory from "app/hooks/projects/useGetAllProjectPerCategory";
import { SingleOption } from "app/types/common";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import TimePickerField from "app/views/Admin/components/formik inputs/TimePickerField";
import dayjs, { Dayjs } from "dayjs";
import { Form, Formik } from "formik";
import { CreateBtn, FlexWrapper, FormWrapper } from "./styles";
import { customSiteTourValidation } from "../validation";
import useGetAllPackagePerLocation from "app/hooks/projects/useGetAllPackagePerLocation";
import useCustomUpdateSchedule from "app/hooks/site tour/useCustomUpdateSchedule";
import { CustomTourResponse } from "app/api/site tour/types";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import { useSearchParams } from "react-router-dom";

interface FormProps {
  package_id: number;
  proposed_date: string;
  proposed_time: string;
}

interface Props {
  setNextStep: () => void;
  pushToFormData: (data: FormProps) => void;
  editData?: CustomTourResponse;
}

const CreateCustom: React.FC<Props> = ({
  setNextStep,
  pushToFormData,
  editData,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [projectType, setProjectType] = useState<SingleOption>();
  const [location, setLocation] = useState<SingleOption>();
  const [packages, setPackages] = useState<SingleOption>();
  const [date, setDate] = useState<Dayjs | null>(null);
  const [time, setTime] = useState<Dayjs | null>(null);

  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();

  const { data: projectPerCategoryData, isLoading: projectPerCategoryLoading } =
    useGetAllProjectPerCategory(categoryType?.value as number);

  const { data: locationPerProjectData, isLoading: locationPerProjectLoading } =
    useGetAllLocationPerProject(projectType?.value as number);

  const { data: packagePerLocationData, isLoading: packagePerLocationLoading } =
    useGetAllPackagePerLocation(location?.value as number);

  const { mutate: updateMutate, isLoading: isUpdating } =
    useCustomUpdateSchedule();

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name || ev.location,
      };
      return temp;
    });
  };

  useEffect(() => {
    if (editData) return;
    setProjectType(undefined);
    setLocation(undefined);
  }, [categoryType, editData]);

  useEffect(() => {
    if (editData) return;
    setLocation(undefined);
    setPackages(undefined);
  }, [projectType, editData]);

  useEffect(() => {
    if (editData) return;
    setPackages(undefined);
  }, [location, editData]);

  useEffect(() => {
    if (editData) {
      setCategoryType({
        value: 0,
        label: editData.category,
      });

      setProjectType({
        value: 0,
        label: editData.project,
      });

      setLocation({
        value: 0,
        label: editData.location,
      });

      setPackages({
        value: 0,
        label: editData.clients[0]?.package,
      });

      setDate(dayjs(editData.date));
      setTime(dayjs(editData.time, "hh:mm A"));
    }
  }, [editData]);

  return (
    <Formik
      initialValues={{
        categoryType,
        projectType,
        location,
        packages,
        date,
        time,
      }}
      validationSchema={customSiteTourValidation}
      enableReinitialize
      onSubmit={() => {
        if (editData) {
          const payload = {
            id: editData.id,
            proposed_date: dayjs(date).format("YYYY-MM-DD"),
            proposed_time: dayjs(time).format("hh:mm A"),
          };
          const onSuccess = () => {
            toast.success(`Custom Tour Edited Successfully!`);
            searchParams.delete("create");
            searchParams.delete("edit");
            searchParams.delete("page");
            setSearchParams(searchParams);
          };
          updateMutate(payload, {
            onSuccess,
          });
        } else {
          if (!packages) return;
          const payload: FormProps = {
            package_id: packages.value,
            proposed_date: dayjs(date).format("YYYY-MM-DD"),
            proposed_time: dayjs(time).format("hh:mm A"),
          };
          pushToFormData(payload);
          setNextStep();
        }
      }}
    >
      <FormWrapper>
        <Form>
          <FlexWrapper>
            <SelectField
              name="categoryType"
              label="Category"
              value={categoryType as SingleOption}
              onChange={(e) => setCategoryType(e as SingleOption)}
              options={formatData(allCategoryData)}
              selectType="normal"
              isLoading={allCategoryLoading}
              disabled={!!editData}
            />

            <SelectField
              name="projectType"
              label="Property"
              value={projectType as SingleOption}
              onChange={(e) => setProjectType(e as SingleOption)}
              disabled={!categoryType || !!editData}
              options={formatData(projectPerCategoryData)}
              selectType="normal"
              isLoading={projectPerCategoryLoading}
            />
          </FlexWrapper>
          <FlexWrapper>
            <SelectField
              name="location"
              label="Location"
              value={location as SingleOption}
              onChange={(e) => setLocation(e as SingleOption)}
              disabled={!projectType || !!editData}
              options={formatData(locationPerProjectData)}
              selectType="normal"
              isLoading={locationPerProjectLoading}
            />
            <SelectField
              name="packages"
              label="Package type"
              value={packages as SingleOption}
              onChange={(e) => setPackages(e as SingleOption)}
              disabled={!location || !!editData}
              options={formatData(packagePerLocationData)}
              selectType="normal"
              isLoading={packagePerLocationLoading}
            />
          </FlexWrapper>

          <FlexWrapper>
            <DatePickerField
              name="date"
              label="Date"
              placeholder="Select Date"
              value={date}
              onChange={(e) => setDate(e)}
            />

            <TimePickerField
              name="time"
              label="Time"
              placeholder="Select Time"
              value={time}
              onChange={(e) => setTime(e)}
            />
          </FlexWrapper>

          <CreateBtn type="submit">
            {isUpdating && <Spinner color="black" />}
            {editData ? "Update" : "Next"}
          </CreateBtn>
        </Form>
      </FormWrapper>
    </Formik>
  );
};

export default CreateCustom;

import { useAppSelector } from "app/redux";
import { getAuth } from "app/redux/auth/authSlice";
import React from "react";

export default function useAuth() {
  const tokens = useAppSelector(getAuth).accessToken;

  const [isLoggedIn, setIsLoggedIn] = React.useState(!!tokens);

  React.useEffect(() => {
    setIsLoggedIn(!!tokens);
  }, [tokens]);

  return isLoggedIn;
}

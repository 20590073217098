import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePost } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useUpdatePost = () => {
  const queryClient = useQueryClient();

  return useMutation(updatePost, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetBlogpost]);
    },
  });
};

export default useUpdatePost;

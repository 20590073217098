import { createGlobalStyle } from "styled-components";
import { AgGridStyles } from "./ag-grid-styles";

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    font-family: 'Circular Std', sans-serif;
    box-sizing: border-box;
  }


  html,
  body {
    height: 100%;
    width: 100%;
    background-color: #FBFBFB;
    font-family: 'Circular Std', sans-serif;
    scroll-behavior: smooth;
  }

  a {
      text-decoration: none;
  }

  a {
      text-decoration: none;
  }

  ul {
      list-style: none;
  }

  ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
  }

  /*vertical Handle */

  ::-webkit-scrollbar-thumb:vertical {
      background-color: #97b644;
      border-radius: 10px;
  }

  /*horizontal Handle */
  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #97b644;
    border-radius: 10px;
  }

  ${AgGridStyles}
`;

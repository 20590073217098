import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  link?: string;
  title: string;
  icon: any;
  action?: () => void;
}

const CustomLink: React.FC<Props> = ({ icon: Icon, link, title, action }) => {
  const { pathname } = useLocation();
  const path = `/${pathname.split("/").slice(1).join("/")}`;
  const isActive = path.includes(link as string);

  return (
    <Container isactive={isActive}>
      {!!link ? (
        <ContentWrapper to={link} isactive={isActive}>
          <Icon />
          <p>{title}</p>
        </ContentWrapper>
      ) : (
        <NonLinkWrapper isactive={isActive} onClick={action}>
          <Icon />
          <p>{title}</p>
        </NonLinkWrapper>
      )}
    </Container>
  );
};

export default CustomLink;

interface ContainerProps {
  isactive: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  border-left: ${({ isactive }) => (isactive ? "5px solid #97B644" : "none")};
  transition: all 0.2s ease-in-out;
`;

const ContentWrapper = styled(Link)<ContainerProps>`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  background: ${({ isactive }) => (isactive ? "#f2f8e2" : "transparent")};
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  & > p {
    margin-top: 3px;
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #97b644;
  }

  &:hover {
    background: #f2f8e2;
  }
`;

const NonLinkWrapper = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-left: 17px;
  width: 180px;
  height: 40px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;

  & > p {
    margin: 0;
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #b3261e;
  }
`;

import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

interface Props {
  open: boolean;
  handleClose: () => void;
}

const NotifyDialog: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content>
          Thank you for scheduling a site tour with us. A mail has been sent to
          your <DarkGreenText>email address.</DarkGreenText> <br /> We can’t
          wait to take you on a wonderful tour of this project.
        </Content>

        <ConfirmBtn type="submit" onClick={handleClose}>
          Return to site tour
        </ConfirmBtn>
      </Container>
    </Backdrop>
  );
};

export default NotifyDialog;

const Container = styled.div`
  width: 550px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaObj.tablet} {
    width: 70vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 1rem;
`;

const DarkGreenText = styled.span`
  color: #afd34f;
`;

const Btn = styled.button`
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
`;
const ConfirmBtn = styled(Btn)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fbfbfb;
  background: #97b644;
  border: none;
  margin: auto;
  margin-top: 24px;
  padding: 10px 24px;
  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

import { Box } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { FC } from "react";
import styled from "styled-components";
import { nextOfKin } from "app/api/client/types";

interface props {
  handleClose: () => void;
  isOpen: boolean;
  data: nextOfKin;
}

const NextOfKinModal: FC<props> = ({ handleClose, isOpen, data }) => {
  const {
    address,
    country,
    email,
    fullname,
    gender,
    phone_number,
    relationship,
  } = data;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <ContentWrapper>
          <Title>Next of Kin Details</Title>
          <Box display={"flex"} flexDirection={"column"} gap={0.8} mt={2}>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Full Name:</Label>
              <Value color={"#809a39"}>{fullname}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Gender:</Label>
              <Value>{gender}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Email Address:</Label>
              <Value color={"#327F9E"}>{email}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Phone Number:</Label>
              <Value>{phone_number}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Nationality:</Label>
              <Value>{country}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Address:</Label>
              <Value>{address}</Value>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={0.6}>
              <Label>Relationship:</Label>
              <Value>{relationship}</Value>
            </Box>
          </Box>
        </ContentWrapper>
      </Fade>
    </Modal>
  );
};

export default NextOfKinModal;

const ContentWrapper = styled.div`
  width: fit-content;
  max-height: 50vh;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 24px;
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #2a3313;
`;

interface valueProps {
  color?: string;
}

const Value = styled.p<valueProps>`
  font-weight: 500;
  color: #2a3313;
  color: ${({ color }) => (color ? color : "#777777")};
  font-size: 14px;
`;

import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { adminGetClientPackagesRes } from "app/api/analytics/types";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import DoaModal from "../modals/DoaModal";
import DynamicRenderer from "./DynamicRenderer";

type TableField = keyof adminGetClientPackagesRes | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: adminGetClientPackagesRes[];
}

interface Props {
  data: adminGetClientPackagesRes[];
}

const OrderTable: React.FC<Props> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: adminGetClientPackagesRes[] = data;
  const [open, setOpen] = useState<adminGetClientPackagesRes>();
  const handleClose = () => setOpen(undefined);
  const handleOpen = (data: adminGetClientPackagesRes) => setOpen(data);

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Client's Name",
      field: "client",
      sortable: false,
      flex: 1,
      cellStyle: {
        textTransform: "capitalize",
        color: "#97B644",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return data.client.firstname + " " + data.client.lastname;
      },
      wrapText: true,
    },
    {
      headerName: "Category",
      field: "category",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        textTransform: "capitalize",
        color: "#97B644",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return data.category.name;
      },
      wrapText: true,
    },

    {
      headerName: "Property",
      field: "project",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#143340",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return data.project.project;
      },
      wrapText: true,
    },
    {
      headerName: "Package Type",
      field: "package",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return data.package.name;
      },
      wrapText: true,
    },
    {
      headerName: "Amount Paid",
      field: "price",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return `NGN ${Number(data.price).toLocaleString("en-US")}`;
      },
      wrapText: true,
    },
    {
      headerName: "Status",
      field: "doa_uploaded",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      //   cellRendererFramework: StatusRenderer,
      valueFormatter: (params) => {
        const { data }: { data: adminGetClientPackagesRes } = params;
        return data.doa_uploaded ? "Yes" : "No";
      },
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: DynamicRenderer,
      cellRendererParams: {
        dataArray: [
          {
            name: "Upload DOA",
            onClick: handleOpen,
          },
        ],
      },
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowClass={rowClass}
          getRowClass={getRowClass}
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          rowHeight={60}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          immutableData
          getRowNodeId={(data: adminGetClientPackagesRes) => {
            return data.id.toString();
          }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
      {open && <DoaModal open={!!open} data={open} handleClose={handleClose} />}
    </>
  );
};

export default OrderTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 350px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  & * {
    overflow: visible !important;
  }
`;

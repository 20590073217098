import { useClientResetPassword } from "app/hooks/auth/useResetPassword";
import { useClientVerifyResetHash } from "app/hooks/auth/useVerifyResetHash";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  AdminPasswordResetValidation,
  PasswordResetValidation,
} from "./vaildation";

interface Props {
  isAdminStaff?: boolean;
}

const PasswordChange: FC<Props> = ({ isAdminStaff }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetEmail = searchParams.get("email");
  const resetHash = searchParams.get("hash");
  const isResetting = resetEmail && resetHash;
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetToken, setResetToken] = useState<string>();

  const { mutate: verifyHashMutate, isLoading: isVerifying } =
    useClientVerifyResetHash();

  const { mutate: resetMutate, isLoading: resetLoading } =
    useClientResetPassword();

  const clearAllField = () => {
    setNewPassword("");
    setConfirmNewPassword("");
  };

  useEffect(() => {
    if (isResetting) {
      const payload = {
        email: resetEmail,
        hash: resetHash,
      };
      verifyHashMutate(payload, {
        onSuccess: (data) => setResetToken(data.data.reset_token),
      });
    }
  }, [resetEmail, resetHash, verifyHashMutate, isResetting]);

  return (
    <>
      <Formik
        initialValues={{
          newPassword,
          confirmNewPassword,
        }}
        validationSchema={
          isAdminStaff ? AdminPasswordResetValidation : PasswordResetValidation
        }
        enableReinitialize
        onSubmit={() => {
          const onSuccess = () => {
            toast.success(`Password Reset Successfully!`);
            clearAllField();
            isAdminStaff ? navigate("/admin-login") : navigate("/login");
          };
          const payload = {
            reset_token: String(resetToken),
            password: newPassword,
            password_confirmation: confirmNewPassword,
          };

          resetMutate(payload, {
            onSuccess,
          });
        }}
      >
        <Form>
          <H6>Reset Password</H6>
          <P>Please reset your password</P>
          <FormWrapper>
            <InputField
              name="newPassword"
              label="New Password"
              placeholder="************"
              inputType="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputField
              name="confirmNewPassword"
              label="Confirm New Password"
              placeholder="************"
              inputType="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </FormWrapper>
          <SaveBtn type="submit" disabled={isVerifying}>
            {(isVerifying || resetLoading) && <Spinner color="black" />}
            Save Changes
          </SaveBtn>
        </Form>
      </Formik>
    </>
  );
};

export default PasswordChange;

const H6 = styled.div`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 24px;
`;

const P = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin-bottom: 27px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px 62px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  &:disabled {
    pointer-events: none;
    background-color: #d8e8b8;
  }
`;

import { useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import UserGrid from "./components/grid/UserGrid";
import CreateUserModal from "./components/modals/CreateUserModal";
import Spinner from "app/views/Web/components/Spinner";
import { motion } from "framer-motion";
import Paginating from "../components/Pagination";
import useAdminGetAllAdmins from "app/hooks/users/useAdminGetAllAdmins";

const Admins = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: userData,
    isLoading,
    isError,
  } = useAdminGetAllAdmins(currentPage);

  const size = 9;
  const pageCount = userData && Math.floor(userData.total / size) + 1;
  const startCount = size * (currentPage - 1) + 1;
  const endCount = size * (currentPage - 1) + size;

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <Header>
        <h5>All Admins</h5>
        <CreateBtn onClick={handleOpen}>
          <AddIcon />
          Add a new admin
        </CreateBtn>
      </Header>
      {isLoading && !isError && (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      )}
      {!isLoading && isError && (
        <SpinnerWrapper>
          <NoProjectText>An Error Occured</NoProjectText>
        </SpinnerWrapper>
      )}
      {!isLoading && !isError && (!userData || userData.data.length === 0) && (
        <SpinnerWrapper>
          <NoProjectText>No user yet</NoProjectText>
        </SpinnerWrapper>
      )}
      {!isLoading && !isError && userData && <UserGrid data={userData.data} />}
      {userData?.data && (
        <Footer>
          <Show>
            Showing {startCount} to{" "}
            {userData?.total && endCount < userData?.total
              ? endCount
              : userData?.total}{" "}
            of {userData?.total}
          </Show>

          <Paginating
            totalElements={userData?.total || 0}
            size={size}
            pageCount={pageCount}
            currentPage={currentPage}
            onChange={onPageChange}
          />
        </Footer>
      )}
      {open && <CreateUserModal open={open} handleClose={handleClose} />}
    </motion.div>
  );
};

export default Admins;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

const CreateBtn = styled.button`
  width: 180px;
  height: 44px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

const NoProjectText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
`;

const Show = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 19px;
`;

import { Box } from "@mui/material";
import { useRef, useState } from "react";
import styled, { css } from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import NextOfKin from "./components/nextOfKin";
import PersonalInfo from "./components/personalInfo";
import { MyDetailsTabData } from "./data";
import { ReactComponent as EditIcon } from "../../../images/admin/edit-icon.svg";
import Spinner from "app/views/Web/components/Spinner";
import { mediaObj } from "styles/utils/media";
import { motion } from "framer-motion";

const Details = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [selectedTab, setSelectedTab] = useState(1);
  const personalBtn = useRef<HTMLButtonElement>(null);
  const nokBtn = useRef<HTMLButtonElement>(null);
  const resetBtn = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (id: number) => {
    setSelectedTab(id);
  };
  const onSave = () => {
    if (selectedTab === 1) personalBtn?.current?.click();
    if (selectedTab === 2) nokBtn?.current?.click();
  };

  const onCancel = () => {
    resetBtn?.current?.click();
    setIsEditable(false);
  };

  const onSetToNotEditable = () => {
    setIsEditable(false);
  };

  const isLoading = (value: boolean) => {
    setLoading(value);
  };
  return (
    <Container
      initial={{
        opacity: 0,
        x: 100,
      }}
      exit={{
        opacity: 0,
        y: 200,
      }}
      animate={{
        opacity: 1,
        x: 0,
        y: 0,
      }}
      transition={{ duration: 0.55 }}
    >
      <Header>
        <Title>Kyc information</Title>

        <BtnWrapper>
          {isEditable && <EditBox onClick={onCancel}>Cancel</EditBox>}
          {isEditable ? (
            <SaveBtn onClick={onSave} type="submit">
              {loading && <Spinner color="black" />}
              Save Changes
            </SaveBtn>
          ) : (
            <EditBox onClick={() => setIsEditable(true)}>
              <EditIcon />
              Edit
            </EditBox>
          )}
        </BtnWrapper>
      </Header>
      <Box display={"flex"} alignItems={"center"} gap={"24px"} mt={"28px"}>
        {MyDetailsTabData.map((x, idx) => (
          <Tab
            key={idx}
            onClick={() => {
              handleTabChange(x.id);
              setIsEditable(false);
            }}
            isActive={x.id === selectedTab}
          >
            {x.name}
          </Tab>
        ))}
      </Box>

      {selectedTab === 1 && (
        <PersonalInfo
          isEditable={isEditable}
          saveBtn={personalBtn}
          resetBtn={resetBtn}
          isLoading={isLoading}
          onSetToNotEditable={onSetToNotEditable}
        />
      )}
      {selectedTab === 2 && (
        <NextOfKin
          isEditable={isEditable}
          saveBtn={nokBtn}
          isLoading={isLoading}
          resetBtn={resetBtn}
          onSetToNotEditable={onSetToNotEditable}
        />
      )}
      <MobileBtnWrapper>
        {isEditable && <EditBox onClick={onCancel}>Cancel</EditBox>}
        {isEditable ? (
          <SaveBtn onClick={onSave} type="submit">
            {loading && <Spinner color="black" />}
            Save Changes
          </SaveBtn>
        ) : (
          <EditBox onClick={() => setIsEditable(true)}>
            <EditIcon />
            Edit
          </EditBox>
        )}
      </MobileBtnWrapper>
    </Container>
  );
};

export default Details;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;

  ${mediaQueries.mobile} {
    display: none;
  }
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#181d0b")};
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 3px solid #afd34f;
    `}

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${mediaObj.mobile} {
    display: none;
  }
`;

const MobileBtnWrapper = styled.div`
  display: none;
  align-items: center;
  gap: 2rem;

  ${mediaObj.mobile} {
    display: flex;
    margin: 30px 0 0 auto;
  }
`;

const EditBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
  svg {
    fill: #afd34f;
  }
  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
    & > svg {
      fill: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  ${mediaObj.mobile} {
    height: 40px;
    padding: 10px 16px;
    font-size: 0.9rem;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

import { useMobileCheck } from "app/hooks/useMobileCheck";
import { staggerContainer } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import AboutCard from "../components/cards/aboutCard";
import { cardData } from "./data";

const PageCards = () => {
  const isMobile = useMobileCheck();

  return (
    <Container
      variants={staggerContainer}
      custom={0.55}
      viewport={{ once: true, amount: isMobile ? 0.2 : 0.7 }}
      whileInView={"animate"}
      initial={"initial"}
    >
      {cardData.map((data, i) => (
        <AboutCard width="345px" key={i} {...data} />
      ))}
    </Container>
  );
};

export default PageCards;

const Container = styled(motion.div)`
  display: flex;
  flex-flow: row wrap;
  gap: 64px;
  justify-content: center;
  margin-top: 98px;
  margin-bottom: 104px;

  ${mediaObj.mediumDesktop} {
    gap: 40px;
  }

  ${mediaQueries.mobile} {
    gap: 30px;
    margin: 60px 0;
  }
`;

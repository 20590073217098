import { Box } from "@mui/material";
import { AdminRateUserPayload } from "app/api/users/types";
import useAdminRateUser from "app/hooks/users/useAdminRateUser";
import DualRing from "app/views/Web/components/loader";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import CustomModal from "../../../components/modal";

interface props {
  handleClose: () => void;
  isOpen: boolean;
  width?: string;
  id: number;
}

const RateStaffModal: FC<props> = ({ handleClose, isOpen, width, id }) => {
  const [value, setValue] = useState<any>();

  const { isLoading, mutate } = useAdminRateUser();

  const handleSubmit = () => {
    const payload: AdminRateUserPayload = {
      rating: value,
      user_id: id,
    };
    mutate(payload, {
      onSuccess: () => {
        toast.success("Staff rated Successfully");
        handleClose();
      },
    });
  };

  const isValid = value && value > 0 && value <= 10;
  return (
    <CustomModal isOpen={isOpen} handleClose={handleClose} width={width}>
      <Wrapper>
        <Title>Rate Staff</Title>
        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          <Label>Rating</Label>
          <Input
            placeholder="Rate from 1 - 10"
            type={"number"}
            min={1}
            max={10}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
        <RatingBtn disabled={!isValid} onClick={handleSubmit}>
          {isLoading ? (
            <DualRing color="#fff" width={"9px"} height={"9px"} />
          ) : (
            `Confirm Rating`
          )}
        </RatingBtn>
      </Wrapper>
    </CustomModal>
  );
};

export default RateStaffModal;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Title = styled.p`
  font-weight: 450;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

const Input = styled.input`
  height: 56px;
  width: 100%;
  padding: 16px 24px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  outline: none;

  &:out-of-range {
    border: 1px solid #b3261e;
  }

  &::placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #949292;
  }
`;

const RatingBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  width: 100%;
  height: 46px;
  background: #afd34f;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;

  & > div {
    margin: 0 10px 12px 0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
`;

import styled from "styled-components";
import P from "../../../../styles/elements/P";
import { ReactComponent as ImgLogo } from "../../../images/web/Adbond Logo 1.svg";
import { ReactComponent as FacebookIcon } from "../../../images/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../../images/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/youtube.svg";
import { ReactComponent as InstagramIcon } from "../../../images/instagram.svg";
import media, { mediaObj } from "../../../../styles/utils/media";
import { footerLinks } from "app/utils/data";
import { Link } from "react-router-dom";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import mediaQueries from "styles/utils/mediaQueries";
//

const Footer = () => {
  const isMobile = useMobileCheck();

  return (
    <Container>
      <ContentWrapper>
        <LeftContent>
          <ImgLogo />
          {!isMobile && (
            <SocialAndAddress>
              <SocialsWrapper>
                <a
                  href="https://www.facebook.com/adbondharvest?mibextid=ZbWKwL"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>

                <a
                  href="https://twitter.com/adbondharvest?t=n3JxEmUlOkwXgT6TwaV1Cg&s=08"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://instagram.com/adbondharvestandhomes?igshid=ZDdkNTZiNTM="
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>

                <a
                  href="https://youtube.com/@adbondharvest1176"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <YoutubeIcon />
                </a>
              </SocialsWrapper>
              <p>
                Adbond Harvest & Homes Limited. 3rd & 4th Floor, Centage Plaza,
                14 Allen avenue. Ikeja, Lagos.
              </p>
            </SocialAndAddress>
          )}
        </LeftContent>
        <RightContent>
          <LinksWrapper>
            {footerLinks.slice(0, 4).map((x, idx) => (
              <Link to={x.link} key={idx}>
                <LinkLabel>{x.label}</LinkLabel>
              </Link>
            ))}
          </LinksWrapper>
          <LinksWrapper>
            {footerLinks.slice(4).map((x, idx) => (
              <Link to={x.link} key={idx}>
                <LinkLabel>{x.label}</LinkLabel>
              </Link>
            ))}
          </LinksWrapper>
        </RightContent>
        {isMobile && (
          <SocialAndAddress>
            <SocialsWrapper>
              <FacebookIcon />
              <TwitterIcon />
              <InstagramIcon />
              <YoutubeIcon />
            </SocialsWrapper>
            <p>
              Adbond Harvest & Homes Limited. 3rd & 4th Floor, Centage Plaza, 14
              Allen avenue. Ikeja, Lagos.
            </p>
          </SocialAndAddress>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  position: relative;
  width: 100%;
  background: #f4f9ec;
  z-index: 3;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;
  max-width: 1536px;
  margin: 0 auto;

  ${media.bigMobile`
    flex-direction: column;
    gap: 20px;
  `}
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 40%;

  & > svg {
    width: 153.52px;
    height: 56px;
    object-fit: cover;
    margin-left: -15px;
  }

  ${media.bigMobile`
    width: 100%;
    gap: 25px;

    & > p {
      font-size: 14px;
    }
  `}
`;

const SocialAndAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > p {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    width: 45%;
    color: #5e5e5e;

    ${mediaObj.smallDesktop} {
      width: 90%;
    }
  }

  ${mediaQueries.mobile} {
    margin-top: 20px;
  }
`;

const RightContent = styled.div`
  display: flex;
  gap: 160px;

  ${mediaObj.smallDesktop} {
    gap: 140px;
  }

  ${media.bigMobile`
    align-self: flex-start;
    gap: 25px;
  `}
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;

const LinkLabel = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #1b1b1b;
  transition: all 0.25s ease;

  &:hover {
    color: #000000;
    transform: translateY(-2px);
  }
`;

const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  & > svg {
    max-width: 22px;
    max-height: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in;

    path {
      fill: #49b7e4;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }
`;

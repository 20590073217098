import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { assessmentObj, questionPayload } from "app/api/staff/types";
import { RootState } from "..";

interface props {
  questions?: questionPayload[];
  assessment?: assessmentObj;
  assessments?: assessmentObj[];
  isTimeUp?: boolean;
  memberEmail?: string;
}

const AssessmentState: props = {
  questions: undefined,
  assessment: {},
  assessments: [],
  isTimeUp: false,
  memberEmail: undefined,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: AssessmentState,
  reducers: {
    setAssessments: (state, action: PayloadAction<assessmentObj[]>) => {
      state.assessments = action.payload;
    },
    setAssessment: (state, action: PayloadAction<assessmentObj>) => {
      state.assessment = action.payload;
    },
    setQuestions: (state, action: PayloadAction<questionPayload>) => {
      const assessment = JSON.parse(
        JSON.stringify(state.assessment)
      ) as assessmentObj;
      state.assessment = !!assessment?.questions?.length
        ? {
            ...assessment,
            questions: [
              ...assessment.questions,
              {
                ...action.payload,
                idx: Math.floor(Math.random() * 100),
              },
            ],
          }
        : {
            ...assessment,
            questions: [
              {
                ...action.payload,
                idx: Math.floor(Math.random() * 100),
              },
            ],
          };
    },
    editQuestion: (state, action: PayloadAction<questionPayload>) => {
      const assessment = JSON.parse(
        JSON.stringify(state.assessment)
      ) as assessmentObj;
      const idx = assessment?.questions?.findIndex((x) =>
        x.id ? x.id === action.payload.id : x.idx === action.payload.idx
      );
      (idx || idx === 0) &&
        idx > -1 &&
        assessment?.questions?.splice(idx, 1, action.payload);
      state.assessment = assessment;
    },
    deleteQuestion: (state, action: PayloadAction<number>) => {
      const assessment = JSON.parse(
        JSON.stringify(state.assessment)
      ) as assessmentObj;
      const idx = assessment?.questions?.findIndex((x) =>
        x.id ? x.id === action.payload : x.idx === action.payload
      );
      (idx || idx === 0) && idx > -1 && assessment?.questions?.splice(idx, 1);
      state.assessment = assessment;
    },
    resetAssessment: (state, action: PayloadAction) => {
      state.assessment = {};
    },
    setTimerState: (state, action: PayloadAction<boolean>) => {
      state.isTimeUp = action.payload;
    },
    setMemberEmail: (state, action: PayloadAction<string | undefined>) => {
      state.memberEmail = action.payload;
    },
  },
});

export const {
  setAssessments,
  setAssessment,
  setQuestions,
  editQuestion,
  deleteQuestion,
  resetAssessment,
  setTimerState,
  setMemberEmail,
} = assessmentSlice.actions;

export const assessmentSelector = (state: RootState) => state.assessment;
export default assessmentSlice.reducer;

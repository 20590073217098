import { GetPostResponse } from "app/api/community/types";
import useAuth from "app/hooks/auth/useAuth";
import React, { useState } from "react";
import styled from "styled-components";
import CommentBox from "./CommentBox";
import CommentField from "./CommentField";
import { ErrorText, SpinnerWrapper } from "./styles";
import LoginModal from "./modals/LoginModal";

interface Props {
  postData: GetPostResponse;
}

const PostComments: React.FC<Props> = ({ postData }) => {
  const isLoggedIn = useAuth();
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <Header>Comments</Header>
      {isLoggedIn ? (
        <CommentField open={true} postId={postData.id} handleClose={() => {}} />
      ) : (
        <TextArea
          placeholder={"Type a comment..."}
          onClick={() => setOpen(true)}
        />
      )}
      {postData.comments.length > 0 ? (
        postData.comments.map((comment) => (
          <CommentBox data={comment} key={comment.id} />
        ))
      ) : (
        <SpinnerWrapper>
          <Text>
            There are currently no comment yet. Be the first to comment.
          </Text>
        </SpinnerWrapper>
      )}
      {open && (
        <LoginModal
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        />
      )}
    </Container>
  );
};

export default PostComments;

const Container = styled.div`
  padding: 24px;
  overflow: scroll;
  max-height: 100vh;
`;

const Header = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 2rem;
`;

const Text = styled(ErrorText)`
  text-align: center;
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: none;
  background-color: #fbfbfb;
  border: 1px solid #bdbdbd9f;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  &:focus-visible {
    outline: none;
  }
`;

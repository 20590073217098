import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import ScheduleTour from "./components/modals/ScheduleTour";
import DisplaySiteTours from "./components/sections/DisplaySiteTours";
import mediaQueries from "styles/utils/mediaQueries";
import KycModal from "../Projects/components/modals/KycModal";
import { ClientUserResponse } from "app/api/users/types";
import { getAuth } from "app/redux/auth/authSlice";
import { useAppSelector } from "app/redux";

const SiteTour = () => {
  const profile = useAppSelector(getAuth).user as ClientUserResponse;
  const [open, setOpen] = useState(false);
  const [openKyc, setOpenKyc] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  return (
    <Container>
      <ContentWrapper>
        <Header>Site tour</Header>
        <CreateBtn
          onClick={() => {
            if (!profile.kyc_completed) {
              setOpenKyc(true);
              return;
            }
            handleOpen();
          }}
        >
          <AddIcon />
          Schedule a site tour
        </CreateBtn>

        <Box display={"flex"} alignItems={"center"} gap={"24px"} mt={"20px"}>
          <Tab isActive>My scheduled tours</Tab>
        </Box>
        <TourWrapper>
          <DisplaySiteTours />
        </TourWrapper>
      </ContentWrapper>

      {open && <ScheduleTour open={open} handleClose={handleClose} />}

      {openKyc && (
        <KycModal open={openKyc} handleClose={() => setOpenKyc(false)} />
      )}
    </Container>
  );
};

export default SiteTour;

const Container = styled.div`
  display: flex;
  background: #ffffff;
  padding: 24px;
  min-height: 700px;
  height: 80vh;
  width: 100%;

  ${mediaQueries.mobile} {
    padding: 0;
    background: transparent;
  }
`;

const ContentWrapper = styled.div`
  margin-right: 20px;
  flex-grow: 1;

  ${mediaQueries.mobile} {
    margin-right: 0;
  }
`;

const Header = styled.h5`
  font-weight: 500;
  font-size: 25px;
  color: #181d0b;

  ${mediaQueries.mobile} {
    font-size: 20px;
  }
`;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 0.5rem 1rem;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-top: 32px;
  margin-bottom: 36px;

  &:hover {
    background-color: #697f2f;
  }

  ${mediaQueries.mobile} {
    margin-top: 24px;
  }
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 450;
  font-size: 1rem;
  color: #181d0b;
  cursor: pointer;
  transition: all 0.2s ease-in;
  padding-bottom: 2px;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 4px solid #97b644; ;
    `}
`;

const TourWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 12px 2px;
  margin-top: 20px;
  max-height: 70%;
  overflow-y: scroll;

  ${mediaQueries.mobile} {
    max-height: unset;
  }
`;

import { cardVariant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import { capitalize } from "lodash";
import { useNavigate } from "react-router";
import styled from "styled-components";
import media, { mediaObj } from "../../../../../styles/utils/media";
import mediaQueries from "../../../../../styles/utils/mediaQueries";
import { categoryResponse } from "../../../../api/project/types";

interface props {
  data: categoryResponse;
}

const ProjectCard: React.FC<props> = ({ data }) => {
  const { description, id, name, photo } = data;
  const navigate = useNavigate();
  return (
    <Container
      variants={cardVariant}
      custom={0.4}
      onClick={() => navigate(`${id}`)}
    >
      <CardImage src={photo} />
      <CardContent>
        <Title>{capitalize(name)}</Title>
        <Description>{description}</Description>
      </CardContent>
      <Label>{capitalize(name)}</Label>
    </Container>
  );
};

export default ProjectCard;

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 240px;
  height: 450px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.2s ease-in;

  ${media.bigMobile`
    flex-direction: column;
    `}

  ${mediaQueries.mobile} {
    min-height: 300px;
    height: auto;
    width: 100%;
  }
`;

const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transform: scale(1);
  transition: all 2s ease;

  ${Container}:hover & {
    transform: scale(1.1);
  }
`;

const CardContent = styled(motion.div)`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 31px;
  background: #06060669;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transform: translateY(100%);
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  transition-delay: 0.3;

  ${Container}:hover & {
    transform: translateY(0);
  }

  ${media.bigMobile`
    padding: 0;
    `}

  ${mediaObj.smallDesktop} {
    transform: translateY(0);
  }

  ${mediaQueries.mobile} {
    padding-top: 30px;
    gap: 15px;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 30px;
  line-height: 140%;
  color: #fbfbfb;

  ${media.bigMobile`
    font-size: 24px;
    `}
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  padding: 0 7px;
  line-height: 140%;
  text-align: center;
  color: #fbfbfb;

  ${mediaQueries.mobile} {
    font-size: 15px;
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 30px;
  color: #fbfbfb;
  width: 100%;
  height: 55px;
  transform: translateY(0);
  background: linear-gradient(
    163.55deg,
    rgba(6, 6, 6, 0.24) 11.4%,
    rgba(0, 0, 0, 0.8) 110.73%
  );
  transition: all 0.3s;

  ${Container}:hover & {
    transform: translateY(100%);
  }

  ${mediaObj.smallDesktop} {
    display: none;
  }
`;

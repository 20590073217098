import { useClientChangePassword } from "app/hooks/auth/useChangePassword";
import { useClientResetPassword } from "app/hooks/auth/useResetPassword";
import { useClientVerifyResetHash } from "app/hooks/auth/useVerifyResetHash";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import {
  PasswordChangeValidation,
  PasswordResetValidation,
} from "./validation";

interface Props {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const PasswordChange: React.FC<Props> = ({ setStep }) => {
  const [searchParams] = useSearchParams();
  const resetEmail = searchParams.get("email");
  const resetHash = searchParams.get("hash");
  const isResetting = resetEmail && resetHash;
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [resetToken, setResetToken] = useState<string>();

  const { mutate: createMutate, isLoading: isCreating } =
    useClientChangePassword();

  const { mutate: verifyHashMutate, isLoading: isVerifying } =
    useClientVerifyResetHash();

  const { mutate: resetMutate, isLoading: resetLoading } =
    useClientResetPassword();

  const clearAllField = () => {
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
  };

  useEffect(() => {
    if (isResetting) {
      const payload = {
        email: resetEmail,
        hash: resetHash,
      };
      verifyHashMutate(payload, {
        onSuccess: (data) => setResetToken(data.data.reset_token),
      });
    }
  }, [resetEmail, resetHash, verifyHashMutate, isResetting]);

  return (
    <Container>
      <Formik
        initialValues={{
          currentPassword,
          newPassword,
          confirmNewPassword,
        }}
        validationSchema={
          isResetting ? PasswordResetValidation : PasswordChangeValidation
        }
        enableReinitialize
        onSubmit={() => {
          const onSuccess = () => {
            toast.success(
              `Password ${isResetting ? "Reset" : "Changed"} Successfully!`
            );
            clearAllField();
          };
          if (isResetting) {
            const payload = {
              reset_token: String(resetToken),
              password: newPassword,
              password_confirmation: confirmNewPassword,
            };

            resetMutate(payload, {
              onSuccess,
            });
          } else {
            const payload = {
              current_password: currentPassword,
              password: newPassword,
              password_confirmation: confirmNewPassword,
            };

            createMutate(payload, {
              onSuccess,
            });
          }
        }}
      >
        <Form>
          <H6>{isResetting ? "Reset" : "Change"} Password</H6>
          <P>
            Please{" "}
            {isResetting
              ? "reset your password "
              : "Input your current and new password to change your account password."}{" "}
          </P>
          <FormWrapper>
            {!isResetting && (
              <InputField
                name="currentPassword"
                label="Current password"
                placeholder="************"
                inputType="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            )}
            <InputField
              name="newPassword"
              label="New password"
              placeholder="************"
              inputType="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <InputField
              name="confirmNewPassword"
              label="Confirm new password"
              placeholder="************"
              inputType="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            {!isResetting && (
              <Info onClick={() => setStep(2)}>
                Forgot password? Click here.
              </Info>
            )}
          </FormWrapper>
          <SaveBtn type="submit" disabled={isVerifying}>
            {(isCreating || isVerifying || resetLoading) && (
              <Spinner color="black" />
            )}
            Save changes
          </SaveBtn>
        </Form>
      </Formik>
    </Container>
  );
};

export default PasswordChange;

const Container = styled.div`
  & > form {
    width: 60%;
  }

  ${mediaObj.smallDesktop} {
    & > form {
      width: 100%;
    }
  }
`;

const H6 = styled.div`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 24px;

  ${mediaQueries.mobile} {
    font-size: 1rem;
    margin: 28px 0 10px;
  }
`;

const P = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #5e5e5e;
  margin-bottom: 27px;

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaQueries.mobile} {
    gap: 15px;
  }
`;

const Info = styled.div`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  margin-top: -8px;
  color: #809a39;
  cursor: pointer;

  &:hover {
    color: #697f2f;
    transition: all 0.1s ease-out;
  }

  ${mediaQueries.mobile} {
    font-size: 14px;
    margin: -8px auto 0;
  }
`;

const SaveBtn = styled.button`
  height: 44px;
  padding: 24px 62px;
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 50px;
  margin-left: auto;

  &:hover {
    background-color: #697f2f;
    transition: all 0.1s ease-out;
  }

  &:disabled {
    pointer-events: none;
    background-color: #d8e8b8;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { adminGetAUser } from "app/api/users";
import { parseError } from "app/utils";
import { UserKeys } from "./types";

const useAdminGetAUser = (id: number) => {
  return useQuery([UserKeys.adminGetAUser, id], () => adminGetAUser(id), {
    enabled: !!id,
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
    refetchOnWindowFocus: true,
  });
};

export default useAdminGetAUser;

import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Separator } from "app/images/separator.svg";
import { useCountdown } from "app/hooks/useCountdown";
import { useAppDispatch, useAppSelector } from "app/redux";
import {
  assessmentSelector,
  setTimerState,
} from "app/redux/assessment/assessmentSlice";
import useGetCompanyInfo from "app/hooks/utilities/useGetCompanyInfo";

const CountDown = () => {
  const [separatorBlink, setSeparatorBlink] = useState(false);
  const { isTimeUp } = useAppSelector(assessmentSelector);
  const dispatch = useAppDispatch();
  const { data } = useGetCompanyInfo();
  const duration = Number(data?.virtual_staff_assessment_time_limit) * 60 || 0;
  const countDown = useCountdown(duration);
  const timesLabel = ["HRS", "MINS", "SECS"];
  countDown.every((item) => item === "00") && dispatch(setTimerState(true));

  useEffect(() => {
    const interval = setInterval(() => {
      setSeparatorBlink(true);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      separatorBlink && setSeparatorBlink(false);
    }, 500);
  }, [separatorBlink]);

  return (
    <Wrapper>
      {!isTimeUp ? (
        countDown.map((item, index) => (
          <Box display={"flex"} flexDirection={"column"} gridGap={"8px"}>
            <CountWrapper key={index} separatorBlink={separatorBlink}>
              <Count>{item}</Count>
              {index !== countDown.length - 1 && <Separator />}
            </CountWrapper>
            <CountLabel>{timesLabel[index]}</CountLabel>
          </Box>
        ))
      ) : (
        <span>TIME UP</span>
      )}
    </Wrapper>
  );
};

export default CountDown;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 14px 8px 8px;
  width: 227px;
  height: 46px;
  font-weight: 700;
  font-size: 16px;
  line-height: 44px;
  letter-spacing: -0.02em;

  & span {
    color: #ad1010;
  }
`;

const CountWrapper = styled.div<{ separatorBlink?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;

  & > svg {
    width: 3px;
    height: 10px;
    ${({ separatorBlink }) =>
      separatorBlink &&
      css`
        g {
          opacity: 1;
        }
      `}

    g {
      circle {
        fill: #afd34f;
      }
    }
  }
`;

const Count = styled.div`
  font-family: "Inter";
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.08em;
  color: #afd34f;
`;

const CountLabel = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 100%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #798694;
`;

import React from "react";
import { Navigate, useLocation } from "react-router";
import storage from "../utils/storage";

interface Props {
  children: JSX.Element;
  redirectUrl?: string;
}

const RequireAuth: React.FC<Props> = ({
  children,
  redirectUrl = "/admin-login",
}) => {
  const location = useLocation();
  const isLoggedIn = !!storage.get("adbondAccessToken");

  if (!isLoggedIn) {
    return <Navigate to={redirectUrl} state={{ from: location }} replace />;
  } else {
    return children;
  }
};

export default RequireAuth;

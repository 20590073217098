import { useQuery } from "@tanstack/react-query";
import { getClientPackages } from "app/api/client";
import { parseError } from "app/utils";
import { ClientQueryKeys } from "./types";

const useGetClientPackages = () => {
  return useQuery([ClientQueryKeys.getClientPackages], getClientPackages, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetClientPackages;

import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

const AcceptCookies = () => {
  const [isOpen, setIsOpen] = useState(true);

  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith("cookie_consent="))
    ?.split("=")[1];

  const onDecline = () => {
    document.cookie = "cookie_consent=deny;";
    setIsOpen(false);
  };

  const onAccept = () => {
    document.cookie = "cookie_consent=allow;";
    setIsOpen(false);
  };

  return (
    <>
      {!cookieValue && isOpen && (
        <AnimatePresence>
          <Container
            initial={{
              opacity: 0,
              bottom: "-30%",
            }}
            animate={{
              opacity: 1,
              bottom: "1%",
            }}
            transition={{ duration: 0.8, easings: ["easeOut"] }}
          >
            <Title>Cookies Settings</Title>
            <Para>
              We use cookies to make your experience on our site even better.
              <br /> <br />
              By clicking <Colored>Accept</Colored> you're agreeing to our use
              of cookies. if you want to know more, you can read our{" "}
              <LearnLink to="/legal/cookies">cookie policy.</LearnLink>
            </Para>

            <BtnWrapper>
              <AcceptBtn onClick={onAccept}>Accept</AcceptBtn>
              <DeclineBtn onClick={onDecline}>Decline</DeclineBtn>
            </BtnWrapper>
          </Container>
        </AnimatePresence>
      )}
    </>
  );
};

export default AcceptCookies;

export const Container = styled(motion.div)`
  width: 369px;
  border-radius: 8px;
  padding: 24px;
  position: fixed;
  right: 5%;
  z-index: 50;
  background-color: white;
  box-shadow: 0px 10px 48px #33404d26;

  ${mediaObj.bigMobile} {
    width: 90vw;
    padding: 5%;
  }
`;

export const Title = styled.h2`
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #2a3313;
  margin-bottom: 24px;

  ${mediaObj.bigMobile} {
    display: none;
  }
`;

export const Para = styled.p`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #464646;
`;

export const Colored = styled.span`
  color: #afd34f;
`;

export const LearnLink = styled(Link)`
  display: inline-block;
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #afd34f;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-top: 40px;
`;

export const Btn = styled.button`
  border-radius: 8px;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  height: 42px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const DeclineBtn = styled(Btn)`
  color: #1b1b1b;
  border: 1px solid #181d0b;
`;

export const AcceptBtn = styled(Btn)`
  background-color: #afd34f;
  color: #ffffff;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

import { ClickAwayListener } from "@mui/base";
import useAdminGetNotifications from "app/hooks/utilities/useAdminGetNotifications";
import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as NotificationIcon } from "../../../../images/admin/bell.svg";
import Fade from "../fade";
import { IconsBg } from "../Header";
import DropDown from "./dropDown";

const Notification = () => {
  const { data, error } = useAdminGetNotifications();
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const handleClickAway = () => {
    setIsOpenDropDown(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <IconWrapper>
        <IconsBg onClick={() => handleToggleDropdown()}>
          <NotificationIcon />
        </IconsBg>
        <Fade isOpen={isOpenDropDown}>
          <DropDown onClose={handleClickAway} data={data || []} />
        </Fade>
      </IconWrapper>
    </ClickAwayListener>
  );
};

export default Notification;

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { adminGetPaymentsByCategoryResponse } from "app/api/payment/types";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import OnlineModal from "../modals/OnlineModal";
import DynamicRenderer from "./DynamicRenderer";
import StatusRenderer from "./StatusRenderer";

type TableField = keyof adminGetPaymentsByCategoryResponse | "action";

interface GridDef extends ColDef {
  field: TableField;
}

interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: adminGetPaymentsByCategoryResponse[];
}

interface Props {
  data: adminGetPaymentsByCategoryResponse[];
}

const OnlineTable = React.forwardRef<HTMLDivElement, Props>(({ data }, ref) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: adminGetPaymentsByCategoryResponse[] = data;

  const [open, setOpen] = useState<adminGetPaymentsByCategoryResponse>();
  const handleClose = () => setOpen(undefined);
  const handleOpen = (data: adminGetPaymentsByCategoryResponse) =>
    setOpen(data);

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "S/N",
      field: "id",
      sortable: false,
      cellStyle: {
        justifyContent: "center",
        ...gridCellStyle,
      },
      valueGetter: "node.rowIndex + 1",
      flex: 0.4,
    },
    {
      headerName: "Client’s Name",
      field: "client",
      sortable: false,
      flex: 1,
      cellStyle: {
        color: "#97B644",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetPaymentsByCategoryResponse } = params;
        return data.client.firstname + " " + data.client.lastname;
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Package Type",
      field: "package",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Reference no",
      field: "reference",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#143340",
        ...gridCellStyle,
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Amount Paid",
      field: "amount",
      sortable: false,
      flex: 0.8,
      cellStyle: {
        color: "#281103",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetPaymentsByCategoryResponse } = params;
        return `NGN ${Number(data.amount).toLocaleString("en-US")}`;
      },
    },
    {
      headerName: "Channel",
      field: "payment_channel",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        textTransform: "capitalize",
        color: "#49B7E4",
        ...gridCellStyle,
      },
    },
    {
      headerName: "Status",
      field: "confirmed",
      sortable: false,
      flex: 0.7,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Date",
      field: "order",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: adminGetPaymentsByCategoryResponse } = params;
        return new Intl.DateTimeFormat("en-US").format(
          new Date(data.order.order_date)
        );
      },
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: DynamicRenderer,
      cellRendererParams: {
        dataArray: [
          {
            name: "View",
            onClick: handleOpen,
          },
        ],
      },
      wrapText: true,
      autoHeight: true,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const rowClass = "my-white-class";

  const getRowClass = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      return "my-shaded-effect";
    }
  };

  return (
    <div ref={ref}>
      <GridWrapper className="ag-theme-alpine">
        <AgGridReact
          rowClass={rowClass}
          getRowClass={getRowClass}
          onGridReady={gridRendered}
          animateRows
          headerHeight={45}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          // immutableData
          // getRowNodeId={(data: adminGetPaymentsByCategoryResponse) => {
          //   return data.id.toString();
          // }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
      {open && (
        <OnlineModal open={!!open} data={open} handleClose={handleClose} />
      )}
    </div>
  );
});

export default OnlineTable;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "0.875rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  min-height: 350px;
  width: 100%;
  margin-bottom: 10px;

  .my-white-class {
    background-color: #ffffff;
  }

  .my-shaded-effect {
    background-color: transparent;
  }

  .ag-header {
    background-color: #281103 !important;
    [col-id="sn"] {
      .ag-header-cell-label {
        justify-content: center;
      }
    }
    .ag-header-cell-text {
      color: white;
      font-weight: 450;
      font-size: 16px;
      line-height: 140%;
      padding: 0 0.5rem;
    }
  }

  .ag-root-wrapper-body.ag-layout-normal {
    height: max-content;
  }

  & * {
    overflow: visible !important;
  }
`;

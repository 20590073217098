import React, { useState } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as EditIcon } from "../../../images/admin/edit-icon.svg";
import ImageDisplay from "./components/ImageDisplay";
import { useNavigate, useParams } from "react-router";
import ProjectImages from "../../Web/components/modal/ProjectImages";
import ClientModal from "./components/Modals/ClientModal";
import { packagesObj } from "app/api/project/types";
import Spinner from "app/views/Web/components/Spinner";
import { Box } from "@mui/system";
import DialogModal from "./components/Modals/DialogModal";
import useGetASinglePackage from "app/hooks/projects/useGetASinglePackage";
import PackageItems from "./components/PackageItems";

const PackageDetails = () => {
  const navigate = useNavigate();
  const { id: packageId } = useParams();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);

  const { data: packageData, isFetching } = useGetASinglePackage(
    Number(packageId)
  );

  const goBack = () => {
    navigate(-1);
  };

  const formatImages = () => {
    const temp = [];
    temp.push((packageData as packagesObj).main_file);
    const result = packageData?.photos
      ? [...temp, ...packageData.photos]
      : temp;

    return result;
  };

  const formatModalImages = () => {
    const temp = formatImages().filter((ev) => ev.file_type === "image");
    return temp;
  };

  const getTypeText = () => {
    if (!packageData) return;
    if (packageData.active) {
      return "Deactivate";
    } else {
      return packageData.canDelete ? "Delete" : "Activate";
    }
  };

  return (
    <Container>
      <Nav>
        <ArrowBackIcon onClick={goBack} />
        <h5>Package Details</h5>
      </Nav>

      {isFetching && (
        <SpinnerWrapper>
          <Spinner width="40px" height="40px" color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {!packageData && !isFetching && (
        <SpinnerWrapper>
          <NoPackageText>Package Not Found</NoPackageText>
        </SpinnerWrapper>
      )}

      {packageData && !isFetching && (
        <>
          <Wrapper>
            <h6>{packageData?.name}</h6>
            <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
              <EditBox
                onClick={() =>
                  navigate(`/admin/projects/new?edit=${packageId}`)
                }
              >
                <EditIcon />
                Edit Package
              </EditBox>

              <DeactivateBox
                onClick={() => setOpenDialog(true)}
                isBgRed={packageData.active || packageData.canDelete}
              >
                {getTypeText()}
              </DeactivateBox>
            </Box>
          </Wrapper>
          <ContentWrapper>
            <div>
              {packageData?.main_file && (
                <ImageDisplay setOpen={setOpen} imageData={formatImages()} />
              )}
              <p>{packageData?.description}</p>
              <ItemBody>
                <PackageBox>Location</PackageBox>{" "}
                <span className="location">
                  {packageData.project}
                  {", "}
                  {packageData.state}
                </span>
              </ItemBody>
              {packageData.items.map((ev) => (
                <ItemBody>
                  <PackageBox>Plots Available ({ev.size} sqm)</PackageBox>{" "}
                  <span className="plots">{ev.available_units} Plots</span>
                </ItemBody>
              ))}

              <ClientBox onClick={() => setOpenClientModal(true)}>
                Clients : {packageData.clients}
              </ClientBox>
            </div>

            <div>
              {packageData?.benefits && (
                <BenefitContainer>
                  <h2>Benefits</h2>
                  <BenefitContent
                    benefitNo={Math.ceil(
                      packageData.benefits.split(",").length / 3
                    )}
                  >
                    {packageData?.benefits.split(",").map((ev) => (
                      <BenefitItem>{ev}</BenefitItem>
                    ))}
                  </BenefitContent>
                </BenefitContainer>
              )}

              <Pricing>
                {packageData.items &&
                  packageData.items.length > 0 &&
                  packageData.items.map((item, i) => (
                    <PackageItems item={item} index={i} />
                  ))}
              </Pricing>
            </div>
          </ContentWrapper>
          {packageData?.main_file && open && (
            <ProjectImages
              open={open}
              handleClose={() => setOpen(false)}
              imageArray={formatModalImages()}
              packageName={packageData.name}
            />
          )}
          {openClientModal && (
            <ClientModal
              open={openClientModal}
              handleClose={() => setOpenClientModal(false)}
              packageData={packageData}
            />
          )}
        </>
      )}
      {openDialog && packageData && (
        <DialogModal
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
          packageData={packageData}
        />
      )}
    </Container>
  );
};

export default PackageDetails;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  min-height: 80vh;
`;

const Nav = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  color: #181d0b;

  svg {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #afd34f;
      transition: all 0.3s ease-in-out;
    }
  }

  h5 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 24px;

  h6 {
    font-weight: 450;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #181d0b;
  }
`;

const EditBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
  svg {
    fill: #afd34f;
  }
  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
    & > svg {
      fill: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

interface DeactivateBoxProps {
  isBgRed: boolean;
}

const DeactivateBox = styled.div<DeactivateBoxProps>`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  background-color: ${({ isBgRed }) => (isBgRed ? "#b3261e" : "#afd34f")};
  color: #fbfbfb;
  padding: 11px 24px;
  cursor: pointer;
  border-radius: 8px;
  svg {
    fill: #fbfbfb;
  }
  &:hover {
    background-color: ${({ isBgRed }) => (isBgRed ? "#b3261ee4" : "#697f2f")};
    transition: all 0.3s ease-in-out;
    & > svg {
      transition: all 0.3s ease-in-out;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;

  & > div {
    display: flex;
    flex-direction: column;
  }

  & > div:nth-child(1) {
    width: 60%;
    p {
      margin-top: 10px;
      width: 70%;
      font-weight: 450;
      font-size: 1rem;
      line-height: 140%;
      color: #464646;
      white-space: pre-wrap;
      overflow-wrap: break-word;
    }
  }

  & > div:nth-child(2) {
    min-width: 370px;
    width: 40%;
  }
`;

const ItemBody = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  margin-top: 1.5rem;

  .location {
    color: #143340;
  }

  .plots {
    color: #3e4b1c;
  }
`;

const PackageBox = styled.div`
  background: #143340;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fbfbfb;
`;

const ClientBox = styled.div`
  width: max-content;
  background: #afd352;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fbfbfb;
  margin-top: 24px;
  cursor: pointer;
`;

const BenefitContainer = styled.div`
  background-color: #d8e8b8;
  padding: 12px 16px;
  border-radius: 4px;

  h2 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: #303030;
    text-align: center;
  }
`;

interface BenefitContentProps {
  benefitNo: number;
}

const BenefitContent = styled.div<BenefitContentProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: ${({ benefitNo }) => `repeat(${benefitNo}, 5rem)`};
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
`;

const BenefitItem = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28647d;
  border-radius: 2px;
  color: #fbfbfb;
  text-align: center;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  word-break: normal;
  overflow-y: scroll;
  text-transform: capitalize;
`;

const Pricing = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 14px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

const NoPackageText = styled.p`
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

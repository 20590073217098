import { css, CSSObject } from "styled-components";

type RuleOrQueryType = CSSObject | TemplateStringsArray;

const mediaQuery =
  (query: RuleOrQueryType) =>
  (rules: RuleOrQueryType, ...placeholders: any[]) =>
    css`
      @media screen and (${css(query)}) {
        ${css(rules)}
      }
    `;

const media = {
  smallMobile: mediaQuery`max-width: 320px`, // iphone 5/SE 😂
  mobile: mediaQuery`max-width: 480px`, // mobile
  bigMobile: mediaQuery`max-width: 540px`, // surface duo
  tablet: mediaQuery`max-width: 768px`, // tablets
  smallDesktop: mediaQuery`max-width: 1024px`, // tablets landscape, small desktops
  mediumDesktop: mediaQuery`max-width: 1366px`, // tablets landscape, small desktops
  print: mediaQuery`print`,
};

export const mediaObj = {
  smallMobile: "@media screen and (max-width: 320px)",
  mobile: "@media screen and (max-width: 480px)",
  bigMobile: "@media screen and (max-width: 540px)",
  tablet: "@media screen and (max-width: 768px)",
  smallDesktop: "@media screen and (max-width: 1024px)",
  mediumDesktop: "@media screen and (max-width: 1366px)",
  desktop: "@media screen and (max-width: 1520px)",
};

export default media;

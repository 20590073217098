import React from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { ReactComponent as ImgLogo } from "../../../../images/web/Adbond Logo 1.svg";

interface Props {
  name: string;
  time: string;
  value?: string;
}

const AvatarContainer: React.FC<Props> = ({ name, time, value }) => {
  return (
    <AvatarBox>
      <AvatarImg hasValue={!!value}>
        {value ? <img src={value} alt="avatar" /> : <ImgLogo />}
      </AvatarImg>
      <div>
        <h6>{name}</h6>
        <span>{time}</span>
      </div>
    </AvatarBox>
  );
};

export default AvatarContainer;

const AvatarBox = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: auto;

  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #181d0b;
    text-transform: capitalize;
  }

  span {
    font-weight: 450;
    font-size: 12px;
    line-height: 140%;
    color: #777777;
  }

  ${mediaQueries.mobile} {
    gap: 8px;
    margin-top: 0;

    h6 {
      font-size: 12px;
      line-height: 12px;
      margin-top: 5px;
    }

    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
`;

interface AvatarImgProps {
  hasValue: boolean;
}

const AvatarImg = styled.div<AvatarImgProps>`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${({ hasValue }) => !hasValue && "#DDEEF8"};
  img,
  svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }

  ${mediaQueries.mobile} {
    width: 30px;
    height: 30px;
  }
`;

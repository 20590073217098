import React, { useEffect, useState } from "react";
import useGetPostByTitle from "app/hooks/community/useGetPostByTitle";
import { useParams } from "react-router";
import styled from "styled-components";
import PostComments from "./components/PostComments";
import PostContent from "./components/PostContent";
import useIncreasePostViews from "app/hooks/community/useIncreasePostViews";

const PostDetails = () => {
  const [isHidden, setIsHidden] = useState(true);
  const [animating, setAnimating] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const { postTitle } = useParams();
  const { data, isFetching, isError } = useGetPostByTitle(postTitle as string);

  const { mutate: increaseViewsMutate } = useIncreasePostViews();

  useEffect(() => {
    if (openComment) {
      setIsHidden(false);
      setTimeout(() => {
        setAnimating(true);
      }, 200);
    } else {
      setAnimating(false);
      setTimeout(() => {
        setIsHidden(true);
      }, 200);
    }
  }, [openComment]);

  useEffect(() => {
    if (data) {
      const payload = {
        post_id: data.id,
      };
      increaseViewsMutate(payload);
    }
  }, [data, increaseViewsMutate]);

  return (
    <Container>
      <PostContent
        setOpenComment={setOpenComment}
        postData={data}
        isFetching={isFetching}
        isError={isError}
      />
      <CommentsContainer isHidden={isHidden} animating={animating}>
        {data && <PostComments postData={data} />}
      </CommentsContainer>
    </Container>
  );
};

export default PostDetails;

const Container = styled.div`
  display: flex;
  background-color: white;
  min-height: 80vh;
`;

interface CommentsContainerProps {
  isHidden: boolean;
  animating: boolean;
}

const CommentsContainer = styled.div<CommentsContainerProps>`
  flex: 1;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32);
  display: ${({ isHidden }) => (isHidden ? "none" : "block")};
  transform: ${({ animating }) =>
    animating ? "translateX(0)" : "translateX(30%)"};
  opacity: ${({ animating }) => (animating ? "1" : "0")};
  transition: "all 0.3s ease-in-out";
`;

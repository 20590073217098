import { PromoProjectObj } from "app/api/offers/types";
import React from "react";
import styled from "styled-components";

interface Props {
  data: PromoProjectObj[];
}

const PromoProject: React.FC<Props> = ({ data }) => {
  return (
    <>
      <GridItem type="project">
        <span>Category: </span>
        <span>{data[0].product.category}</span>
      </GridItem>

      <GridItem>
        <Wrapper>
          <span>Project Type:</span>
          <CategoryWrapper>
            {data.map((ev) => (
              <Pills>{ev.product.name}</Pills>
            ))}
          </CategoryWrapper>
        </Wrapper>
      </GridItem>
    </>
  );
};

export default PromoProject;

const Wrapper = styled.div`
  display: flex;
  gap: 8px;

  & > span:nth-child(1) {
    white-space: nowrap;
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface GridItemProps {
  type?: "project";
}

const GridItem = styled.div<GridItemProps>`
  width: ${({ type }) => type === "project" && "max-content"};
  padding: ${({ type }) => type === "project" && "4px 8px"};
  background-color: ${({ type }) => type === "project" && "#CDE6F5"};
  border-radius: ${({ type }) => type === "project" && "4px"};
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: #777777;
    color: ${({ type }) => type === "project" && "#2A3313"};
    text-transform: capitalize;
  }
`;

const Pills = styled.div`
  padding: 4px 12px;
  background: #b6b6b6;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 140%;
  color: #fbfbfb;
  text-transform: capitalize;
`;

import { motion } from "framer-motion";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import media, { mediaObj } from "../../../../styles/utils/media";

export const GridContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, max-content);
  padding: 20px 60px;
  align-items: center;
  padding-top: 56px;

  ${mediaObj.smallDesktop} {
    padding: 20px 0 0;
  }

  ${media.tablet`
  row-gap: 45px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(4, max-content);
  `}

  ${media.bigMobile`
  padding-right:0;
  padding-left:0;
  `}
  
  ${mediaQueries.mobile} {
    padding-top: 10px;
  }

  .left {
    grid-row: 1/-1;
    grid-column: 2/-1;
    align-self: flex-start;

    ${media.smallDesktop`
    grid-row: 2/3;
    grid-column: 2/-1;
    align-self: unset;
    `}

    ${media.tablet`
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    `}
  }

  .title {
    margin-top: 50px;
    width: 90%;

    & > h1 {
      font-weight: 500;
      font-size: 31px;
      line-height: 140%;
      color: #143340;
    }

    & > h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 160%;
      color: #143340cc;
      margin-top: 10px;
    }

    ${mediaObj.mediumDesktop} {
      width: 100%;

      & > h1 {
        font-size: 28px;
      }

      & > h2 {
        width: 90%;
        font-size: 23px;
      }
    }

    ${media.smallDesktop`
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    margin-top: 0px;
    
    & > h1 {
      font-size: 30px;
    }
    `}

    ${media.tablet`
    `}

    ${media.bigMobile`
    & > p{
      font-size: 1.25rem;
    }
    `}

    ${mediaQueries.mobile} {
      width: 90%;
      & > h1 {
        font-size: 30px;
        line-height: 40px;
      }

      & > h2 {
        font-size: 18px;
        margin-top: 10px;
        width: 100%;
      }
    }
  }
`;

export const ImpactContainer = styled(motion.div)`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 8px 32px;
  background: #143340;
  border-radius: 8px;
  color: white;
  grid-column: 1 / -1;
  z-index: 30;

  ${media.tablet`
    gap: 24px;
    padding: 8px 24px;
    width: 100%;
    justify-content: space-between;
    `}

  @media only screen and (max-width: 374px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    & > span:nth-child(1) {
      font-weight: 500;
      font-size: 2.4375rem;
      line-height: 140%;

      ${media.bigMobile`
    font-size: 1.9375rem;
    `}
    }

    & > span:nth-child(2) {
      font-weight: 500;
      font-size: 1rem;
      line-height: 140%;
      white-space: nowrap;

      ${media.bigMobile`
    font-size: 0.8125rem;
    `}
    }
  }
`;

export const HeroImageContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 575px;
  margin-left: auto;
  border-radius: 20px;
  position: relative;

  ${media.tablet`
    width: 70%;
  `}

  ${media.bigMobile`
  height: 250px;

  `}
`;

export const HeroImg1 = styled(motion.div)`
  position: relative;
  margin-left: auto;
  width: 478.75px;
  height: 320.97px;
  transform: translateY(20px);
  isolation: isolate;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    transform: translate(100px, 115px) scale(0.8);
  }

  & > img {
    clip-path: polygon(100% 0, 100% 100%, 39% 100%, 0 54%, 0 0);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    transition: all 0.45s ease;

    ${HeroImageContainer}:hover & {
      transform: scale(1.015);
    }

    ${mediaQueries.mobile} {
      ${HeroImageContainer}:hover & {
        transform: scale(1);
      }
    }
  }

  ${mediaObj.desktop} {
    ${HeroImageContainer}:hover & {
      width: 35vw;
      transform: translate(80px, 120px) scale(0.75);
    }

    img {
      clip-path: polygon(100% 0, 100% 100%, 35% 100%, 0 55%, 0 0);
    }
  }

  ${mediaObj.mediumDesktop} {
    transform: scale(0.9);
    ${HeroImageContainer}:hover & {
      width: 35vw;
      transform: translate(90px, 70px) scale(0.75);
    }

    img {
      clip-path: polygon(100% 0, 100% 100%, 38% 100%, 0 56%, 0 0);
    }
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
    margin-top: 75px;
    margin-left: unset;

    ${HeroImageContainer}:hover & {
      transform: scale(0.9);
      width: 100%;
    }
  }

  ${media.bigMobile`
  height: 100%;
  `}

  ${mediaQueries.mobile} {
    transform: unset;
    width: 70vw;
    height: 220px;
    transform: scale(0.95);

    ${HeroImageContainer}:hover & {
      transform: unset;
      width: 70vw;
      height: 220px;
      transform: scale(0.95);
    }
  }
`;

export const HeroImg2 = styled(motion.div)`
  position: absolute;
  bottom: 2%;
  transform: translateX(-50%);
  left: 30%;
  margin-right: auto;
  width: 384.24px;
  height: 241.59px;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    transform: translate(-250px, -60px);
    transform: translate(-140px, -165px) scale(0.98);
  }

  & > img {
    clip-path: polygon(70% 0, 70% 60%, 100% 100%, 0 100%, 0 0);
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  ${mediaObj.desktop} {
    ${HeroImageContainer}:hover & {
      transform: translate(-180px, -162px) scale(0.78);
    }
  }

  ${mediaObj.mediumDesktop} {
    transform: translateX(-50%) translateY(-12%) scale(0.9);
    ${HeroImageContainer}:hover & {
      transform: translate(-180px, -211px) scale(0.8);
    }
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
    bottom: 7%;
    left: -18%;
    margin-left: unset;

    ${HeroImageContainer}:hover & {
      transform: translateX(-50%) translateY(-12%) scale(0.9);
    }
  }

  ${media.tablet`
  width: 80%;
  left:0;
  `}

  ${media.bigMobile`
  height: 147px;
  `}
  
  ${mediaQueries.mobile} {
    bottom: -25%;
    transform: translate(-50%, 8%);
    ${HeroImageContainer}:hover & {
      transform: translate(-50%, 8%);
    }
  }
`;

const BgShadow = styled.div`
  z-index: -1;
  position: absolute;
  right: 10px;
  top: 10px;
  border-radius: 8px;
`;

export const BgShadow1 = styled(BgShadow)`
  clip-path: polygon(100% 0, 100% 100%, 39% 100%, 0 54%, 0 0);
  width: 100%;
  height: 100%;
  background-color: #49b7e4;

  ${mediaObj.desktop} {
    ${HeroImageContainer}:hover & {
      clip-path: polygon(100% 0, 100% 100%, 36% 100%, 0 53%, 0 0);
    }
  }

  ${mediaObj.mediumDesktop} {
    ${HeroImageContainer}:hover & {
      clip-path: polygon(100% 0, 100% 100%, 40% 100%, 0 54%, 0 0);
    }
  }
`;

export const BgShadow2 = styled(BgShadow)`
  clip-path: polygon(70% 0, 70% 60%, 100% 100%, 0 100%, 0 0);
  width: 100%;
  height: 100%;
  background-color: #afd34f;
`;

const LabelTag = styled.div`
  position: absolute;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  gap: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
`;

export const LabelTag1 = styled(LabelTag)`
  top: 30%;
  left: -13%;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    top: -30%;
    left: 70%;
  }

  ${mediaQueries.mobile} {
    ${HeroImageContainer}:hover & {
      top: 30%;
      left: -13%;
    }
  }
`;

export const LabelTag2 = styled(LabelTag)`
  top: 50%;
  left: 65%;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    top: 120%;
    left: 30%;
  }

  ${mediaObj.mediumDesktop} {
    ${HeroImageContainer}:hover & {
      top: 115%;
    }
  }

  ${mediaQueries.mobile} {
    top: 55%;
    left: 63%;

    ${HeroImageContainer}:hover & {
      top: 55%;
      left: 63%;
    }
  }
`;

interface DotProps {
  bg: string;
}

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  background: ${({ bg }) => bg};
  border-radius: 100%;
`;

export const VectorWrapper = styled.div`
  position: absolute;
  left: 90%;
  top: 10%;
  z-index: -1;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    transform: translate(50px, 80px) rotateZ(45deg);
    transform: translate(-18px, 170px) rotateZ(72deg) scale(0.8);
  }

  ${mediaObj.smallDesktop} {
    transform: translate(60px, 100px) rotateZ(25deg) scale(0.9);
    ${HeroImageContainer}:hover & {
      transform: translate(60px, 100px) rotateZ(25deg) scale(0.9);
    }
  }

  ${mediaQueries.mobile} {
    left: 100%;
    top: 46%;
    transform: translate(-19px, -32px) rotateZ(365deg) scale(0.75);

    & > svg {
      display: inline-block;
      width: 120px;
      height: 120px;
    }

    ${HeroImageContainer}:hover & {
      transform: translate(-19px, -32px) rotateZ(365deg) scale(0.75);
    }
  }
`;

export const StarWrapper = styled.div`
  position: absolute;
  left: 30%;
  top: -20%;
  transition: all 0.45s ease;

  ${HeroImageContainer}:hover & {
    left: -5%;
    top: -60px;
  }

  ${media.bigMobile`
  display:none
  `}

  ${mediaQueries.mobile} {
    display: inline-block;
    left: 4%;
    top: -30%;

    ${HeroImageContainer}:hover & {
      left: 4%;
      top: -30%;
    }
  }
`;

export const CirclesWrapper = styled.div`
  position: absolute;
  left: 150%;
  top: 20%;
  transition: all 0.45s ease;

  ${mediaQueries.mobile} {
    left: 145%;
    top: 45%;
  }
`;

export const SearchContainer = styled(motion.div)`
  padding: 22px 16px 22px 22px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 46px;
  margin-top: 48px;
  margin-bottom: 40px;
  z-index: 2;
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  width: max-content;

  & > div.error {
    border-bottom: 1px solid red;
  }

  ${mediaObj.mediumDesktop} {
    gap: 30px;
    padding: 18px 16px 18px 20px;
  }

  ${media.smallDesktop`
      flex-direction: column;
      width: 45%;
      margin-bottom: 100px;
      margin-top: -70px;
      align-items:unset;
      background:#FBFBFB;
      `}

  ${media.tablet`
    margin-bottom: 0;
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    width: 100%;
    margin-top:40px;
    box-shadow: none;

    & > div{
      box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #EEEEEE;
      border-radius: 8px;
      padding: 16px 24px;
 
}

& > div.error {
  border: 1px solid red;
  }
`}

  ${media.bigMobile`
    gap:24px;
    margin-top:80px;
    font-size: 1.25rem;
    padding: 20px 0;
    `}
`;

export const SearchButton = styled.button`
  padding: 16px 50px;
  color: white;
  border: none;
  border-radius: 8px;
  background: #afd34f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 13px;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  &:disabled {
    pointer-events: none;
    background-color: #d8e8b8;
  }

  ${mediaObj.mediumDesktop} {
    padding: 15px 30px;
  }
`;

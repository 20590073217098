import React from "react";
import { useGetClientAcceptedOffer } from "app/hooks/offers/useOffer";
import Spinner from "app/views/Web/components/Spinner";
import SalesBidCard from "./cards/SalesBidCard";
import { CardWrapper, NoProjectText, SpinnerWrapper } from "./styles";

const ApprovedSalesOffer = () => {
  const { data, isLoading, isError } = useGetClientAcceptedOffer();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>You currently don’t have approved offers</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <CardWrapper>
      {data &&
        data.map((ev, i) => (
          <SalesBidCard isBid={false} data={ev} key={ev.id} />
        ))}
    </CardWrapper>
  );
};

export default ApprovedSalesOffer;

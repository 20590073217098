import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { KeyboardDoubleArrowRightSharp } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import NextOfKin from "./components/nextOfKin";
import PersonalInfo from "./components/personalInfo";
import { useNavigate } from "react-router";
import api from "app/api";
import { toast } from "react-toastify";
import { createClientPayload } from "app/api/client/types";
import { labelValueType } from "app/types/common";
import DualRing from "app/views/Web/components/loader";
import { isEmpty } from "app/utils/common";
import { parseError } from "app/utils";

const CreateClient = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<createClientPayload>();
  const [formIndex, setFormIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [countries, setCountries] = useState<labelValueType<string, string>[]>(
    []
  );

  const handleGoBack = () => {
    navigate("/admin/clients");
  };

  useEffect(() => {
    async function getCountries() {
      try {
        const res = await api.utilityService.getAllCountries();
        setCountries(
          res.data.data.map((x) => ({ value: x.id.toString(), label: x.name }))
        );
      } catch (error) {
        let errorMessage = error as any;
        toast.error(errorMessage);
      }
    }

    getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    formData && setIsValid(!isEmpty(formData));
  }, [formData]);

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const getBtnLabel = () => {
    return formIndex === 1 ? (
      <>
        <p>Next</p> <KeyboardDoubleArrowRightSharp />
      </>
    ) : (
      "Create Client"
    );
  };

  const handleSubmit = async () => {
    if (formIndex === 1) {
      setFormIndex(2);
      return;
    }

    if (formData) {
      setIsLoading(true);
      try {
        const res = await api.clientService.createClient(formData);
        res.status === 200 && toast.success("Client created successfully");
        setTimeout(() => {
          handleGoBack();
        }, 2000);
      } catch (error) {
        parseError(error);
      }
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <ArrowBackIcon onClick={() => handleGoBack()} />
        <HeaderText>Add a new client</HeaderText>
      </Header>
      <Box display={"flex"} gap={10}>
        <Title>
          {formIndex === 1
            ? "Input Personal Details"
            : "Add information about next of kin"}
        </Title>
        <PageCount>{formIndex} of 2</PageCount>
      </Box>
      <ContentWrapper>
        {formIndex === 1 ? (
          <PersonalInfo
            handleChange={handleChange}
            countries={countries}
            formData={formData}
          />
        ) : (
          <NextOfKin
            handleChange={handleChange}
            countries={countries}
            formData={formData}
          />
        )}
      </ContentWrapper>
      <ButtonWrapper>
        {formIndex === 2 && (
          <BackBtn onClick={() => setFormIndex(1)}>Back</BackBtn>
        )}
        <SubmitButton onClick={handleSubmit} disabled={!isValid}>
          {isLoading ? (
            <DualRing color="#fff" width={"16px"} height={"16px"} />
          ) : (
            getBtnLabel()
          )}
        </SubmitButton>
      </ButtonWrapper>
    </Container>
  );
};

export default CreateClient;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 24px 40px;
  min-height: 80vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 40px;

  & > svg {
    width: 26px;
    height: 25px;
    cursor: pointer;
  }
`;

const HeaderText = styled.h5`
  font-weight: 500;
  font-size: 25px;
  color: #181d0b;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #181d0b;
`;

const PageCount = styled.p`
  font-weight: 500;
  font-size: 16px;
  color: #464646;
  margin-top: 2px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  margin-top: auto;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 10px;
  gap: 10px;
  width: 150px;
  height: 48px;
  font-weight: 500;
  font-size: 16px;
  border: none;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in;

  &:hover {
    background: #697f2f;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  & > div {
    margin: 0 11px 12px 0;
  }
`;

const BackBtn = styled(SubmitButton)`
  background: whitesmoke;
  color: #565555;
  transition: all 0.2s ease-in;

  &:hover {
    background: #f2f0f0;
  }
`;

import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";

export const Title = styled.h5`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  color: #2a3313;
`;

export const BlueHr = styled.div`
  height: 1px;
  background-color: #49b7e4;
  flex-grow: 1;

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 24px;
  margin-top: 40px;

  ${mediaObj.bigMobile} {
    flex-direction: column;
    align-items: flex-start;
  }

  ${mediaQueries.mobile} {
    gap: 6px;
    margin: 30px 0 15px;
  }
`;

export const Content = styled.p`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #464646;
`;

export const Anchor = styled.a`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #afd34f;
  word-break: break-all;

  &:hover {
    text-decoration: underline;
    transition: all 0.3s ease-in-out;
  }
`;

export const Bold = styled.span`
  font-weight: bold;
`;

export const Ul = styled.ul`
  list-style: unset;
  list-style-position: inside;
`;

export const Li = styled.li`
  margin: 0.5rem 0;
`;

import { Box } from "@mui/material";
import { createClientPayload } from "app/api/client/types";
import { labelValueType } from "app/types/common";
import { FC } from "react";
import CustomInput from "../../components/customInput";
import CustomSelect from "../../components/customSelect";
import {
  employmentStatusOptions,
  genderOptions,
  martialStatusOptions,
  titleOptions,
} from "../data";

interface props {
  handleChange: (value: string, name: string) => void;
  countries: labelValueType<string, string>[];
  formData: createClientPayload | undefined;
}

const PersonalInfo: FC<props> = ({ handleChange, countries, formData }) => {
  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={3} width={"47%"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomInput
            label={"First Name"}
            handleChange={handleChange}
            name={"firstname"}
            width={"47%"}
            value={formData?.firstname || ""}
          />
          <CustomInput
            label={"Last Name"}
            handleChange={handleChange}
            name={"lastname"}
            width={"47%"}
            value={formData?.lastname || ""}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomInput
            label={"Email Address"}
            handleChange={handleChange}
            name={"email"}
            width={"47%"}
            value={formData?.email || ""}
          />
          <CustomInput
            label={"Phone Number"}
            handleChange={handleChange}
            name={"phone_number"}
            width={"47%"}
            value={formData?.phone_number || ""}
          />
        </Box>
        <Box display={"flex"}>
          <CustomInput
            label={"Address"}
            handleChange={handleChange}
            name={"address"}
            width={"100%"}
            value={formData?.address || ""}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomSelect
            label={"Employment Status"}
            handleChange={handleChange}
            name={"employment_status"}
            options={employmentStatusOptions}
            width={"47%"}
            value={employmentStatusOptions.filter(
              (x) => x.value === formData?.employment_status
            )}
          />
          <CustomInput
            label={"Occupation"}
            handleChange={handleChange}
            name={"occupation"}
            width={"47%"}
            value={formData?.occupation || ""}
          />
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={3} width={"47%"}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomSelect
            label={"Title"}
            handleChange={handleChange}
            name={"title"}
            options={titleOptions}
            width={"47%"}
            value={titleOptions.filter((x) => x.value === formData?.title)}
          />
          <CustomSelect
            label={"Gender"}
            handleChange={handleChange}
            name={"gender"}
            options={genderOptions}
            width={"47%"}
            value={genderOptions.filter((x) => x.value === formData?.gender)}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomSelect
            label={"Nationality"}
            handleChange={handleChange}
            name={"country_id"}
            options={countries}
            width={"100%"}
            value={countries.filter(
              (x) => x.value === formData?.country_id?.toString()
            )}
          />
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <CustomInput
            label={"Postal Code"}
            handleChange={handleChange}
            name={"postal_code"}
            width={"47%"}
            value={formData?.postal_code || ""}
          />
          <CustomSelect
            label={"Marital Status"}
            handleChange={handleChange}
            name={"marital_status"}
            options={martialStatusOptions}
            width={"47%"}
            value={martialStatusOptions.filter(
              (x) => x.value === formData?.marital_status
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default PersonalInfo;

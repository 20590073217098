import { useEffect, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as gradient } from "app/images/web/mix-gradient.svg";

const CursorGradient = () => {
  const cursorRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener("mousemove", mMove);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", mMove);
    };

    const mMove = (el: any) => {
      if (cursorRef.current) {
        const mouseX = el.clientX - 900;
        const mouseY = el.clientY - 850;
        cursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0) scale(1)`;
      }
    };

    addEventListeners();

    return () => removeEventListeners();
  }, []);
  return <Gradient ref={cursorRef} />;
};

export default CursorGradient;

const Gradient = styled(gradient)`
  width: 1500px;
  height: 1500px;
  z-index: 1;
  position: absolute;
  top: 20px;
  left: 30px;
  pointer-events: none;
`;

import React from "react";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Backdrop, Fade } from "@mui/material";

interface Props {
  open: boolean;
  handleClose: () => void;
  children: JSX.Element | JSX.Element[];
}

const ModalWrapper: React.FC<Props> = ({ open, handleClose, children }) => {
  return (
    <Wrapper>
      <Backdrop
        sx={{
          color: "#000000",
          opacity: "0.4",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      />
      <Fade in={open}>
        <Container>
          <Cancel onClick={handleClose}>
            <HighlightOffIcon />
          </Cancel>
          {children}
        </Container>
      </Fade>
    </Wrapper>
  );
};

export default ModalWrapper;

const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  padding: 24px 24px 40px 24px;
  position: relative;
  background-color: white;
  border-radius: 16px;
  z-index: 1300;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 24px;
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

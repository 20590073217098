import { useMutation } from "@tanstack/react-query";
import { gradeAssessments } from "app/api/assessment";
import { parseError } from "app/utils";

const useGradeAssessment = () => {
  return useMutation(gradeAssessments, {
    onError: (error) => parseError(error),
  });
};

export default useGradeAssessment;

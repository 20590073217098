import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSearchParams } from "react-router-dom";

interface Props {
  open: boolean;
  children: JSX.Element;
  title: string;
}

const CreateWrapper: React.FC<Props> = ({ children, title, open }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    const y = searchParams.get("activeTab");
    y && setSearchParams({ activeTab: y });
  };
  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Cancel onClick={handleClose}>
          <HighlightOffIcon />
        </Cancel>
        <h6>{title}</h6>
        {children}
      </Container>
    </Backdrop>
  );
};

export default CreateWrapper;

const Container = styled.div`
  width: 520px;
  padding: 25px 23px 40px 23px;
  position: relative;
  background-color: white;
  border-radius: 16px;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 24px;
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import media from "styles/utils/media";
import { Link } from "react-router-dom";
import P from "styles/elements/P";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "10px",
};
// testing
//testing
interface Props {
  open: boolean;
  handleClose: () => void;
}

const LoginModal: React.FC<Props> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Container>
          <div className="cancel" onClick={() => handleClose()}>
            <CancelRoundedIcon />
          </div>
          <p>
            To <GreenText>Like or Comment </GreenText>on a post, you have to
            Login. If you don’t have an account, you will need to Sign up.
          </p>
          <BtnWrapper>
            <Link to={"/login"}>
              <LoginButton>
                <P bodybutton className="btn">
                  Login
                </P>
              </LoginButton>
            </Link>
            <Link to={"/signup"}>
              <SignButton>
                <P bodybutton>Sign up</P>
              </SignButton>
            </Link>
          </BtnWrapper>
        </Container>
      </Box>
    </Modal>
  );
};

export default LoginModal;

const Container = styled.div`
  min-width: 31.25rem;
  max-width: 33.875rem;
  padding: 3.125rem 4.0625rem;
  text-align: center;
  position: relative;

  .cancel {
    color: #dc362e;
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    cursor: pointer;
    width: 1.25rem;
    height: 1.25rem;
  }

  p {
    text-align: center;
    font-weight: 450;
    font-size: 1rem;
    color: #464646;

    ${media.bigMobile`
    font-size: 0.875rem;
        `}
  }

  ${media.bigMobile`
  width: 88vw;
  padding: 3.125rem 1.0625rem;
  min-width: unset;
  max-width: unset;
        `}
`;

const LoginButton = styled.button`
  padding: 10px 24px;
  color: white;
  border: none;
  border-radius: 8px;
  background: #afd34f;
  cursor: pointer;

  p {
    color: white;
  }

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const SignButton = styled.button`
  padding: 10px 24px;
  border: none;
  border-radius: 8px;
  background: white;
  cursor: pointer;

  p {
    color: #afd34f;
    text-decoration: underline;
    white-space: nowrap;
  }

  &:hover p {
    color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 200px;
  margin: auto;
  margin-top: 39px;
`;

const GreenText = styled.span`
  color: #afd34f;
`;

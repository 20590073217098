import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  acceptBid,
  deleteBid,
  getAllBid,
  getASingleBid,
  placeBid,
  updateBid,
} from "app/api/offers";
import { parseError } from "app/utils";
import { OffersQueryKeys } from "./types";

export const useGetAllBid = () => {
  return useQuery([OffersQueryKeys.getAllBid], getAllBid, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export const useGetASingleBid = (bid_id: number, isEnabled?: boolean) => {
  return useQuery(
    [OffersQueryKeys.getASingleBid, bid_id],
    () => getASingleBid(bid_id),
    {
      enabled: !!bid_id && isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useAcceptBid = () => {
  const queryClient = useQueryClient();

  return useMutation(acceptBid, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([OffersQueryKeys.getASingleOffer]);
    },
  });
};

export const usePlaceBid = () => {
  return useMutation(placeBid, {
    onError: (error) => parseError(error),
  });
};

export const useUpdateBid = () => {
  return useMutation(updateBid, {
    onError: (error) => parseError(error),
  });
};

export const useDeleteBid = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteBid, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([OffersQueryKeys.getAllBid]);
    },
  });
};

import styled from "styled-components";
import AboutCard, {
  CardWrapper,
  ContentWrapper,
} from "../components/cards/aboutCard";
// import heroFrame1 from "../../../images/hero-frame-1.png";
import heroFrame1 from "../../../images/web/home-image4.jpg";
// import heroFrame2 from "../../../images/hero-frame-2.png";
import heroFrame2 from "../../../images/web/MCH.jpg";
// import heroFrame3 from "../../../images/hero-frame-3.png";
import heroFrame3 from "../../../images/web/cow-image.jpg";
import { cardData, teamCard } from "./data";
import TeamCard from "../components/cards/teamCard";
import mediaQueries from "../../../../styles/utils/mediaQueries";
import OurStory from "./OurStory";
import { mediaObj } from "styles/utils/media";
import { motion } from "framer-motion";
import {
  cardTextAnimate,
  cardVariant,
  fadeIn,
  popUp,
  slideIn,
  staggerContainer,
} from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";

const About = () => {
  const isMobile = useMobileCheck();

  return (
    <Container>
      <HeroSection>
        <LeftHero initial={"initial"} animate={"animate"}>
          <div>
            <H1 variants={slideIn()}>About Adbond Harvest & Homes </H1>
            <motion.p variants={slideIn("right")}>
              We are the first and leading “agro-to-home” development company
              for agricultural and residential purposes in Nigeria.
            </motion.p>
          </div>
          <div>
            <motion.h2 variants={fadeIn("up")} custom={2}>
              Our Unique Story
            </motion.h2>
            <motion.p variants={fadeIn("up")} custom={4}>
              Our Philosophy is to promote wealth, and unity through
              “agro-to-home” development projects in Africa
              <br /> <br />
              We are creating an environment for individuals, businesses, and
              co-operative societies to secure their future spaces. We embarked
              on this journey in 2016 with diversified real estate options and
              locations for our clients. <br /> <br />
              Our services then transformed into encouraging clients to create
              wealth through “agro-to-home” development projects. So we made
              products such as Land, Agro, and Home for people to purchase
              lands, cultivate lands with agricultural options, and build the
              home structures they desire. <br /> <br />
              Our products are still in place, and now, we are infusing
              technology to make asset purchase, management, and wealth creation
              easier for all through a user-focused intuitive website
              application. Introducing technological solutions will enable our
              clients to manage their properties more efficiently and
              effectively.
              <br /> <br />
              Keeping our mission in mind, we are in the right place to secure
              property spaces in Nigeria and Africa for future property owners.
            </motion.p>
          </div>
        </LeftHero>
        <RightHero initial={"initial"} animate={"animate"}>
          <HeroImage1 variants={popUp} custom={2}>
            <img src={heroFrame1} alt="" />
          </HeroImage1>
          <HeroImage2 variants={popUp} custom={2.5}>
            <img src={heroFrame2} alt="" />
          </HeroImage2>
          <HeroImage3 variants={popUp} custom={3}>
            <img src={heroFrame3} alt="" />
          </HeroImage3>
        </RightHero>
      </HeroSection>
      <CardSection
        variants={staggerContainer}
        initial="initial"
        whileInView="animate"
        viewport={{ amount: isMobile ? 0.2 : 0.8, once: true }}
      >
        {cardData.map((data, i) => (
          <AboutCard key={i} {...data} />
        ))}
        <CardWrapper variants={cardVariant}>
          <ContentWrapper variants={staggerContainer} custom={0.5}>
            <motion.h1 variants={cardTextAnimate}>Our core values</motion.h1>
            <motion.p variants={cardTextAnimate}>I – Innovation </motion.p>
            <motion.p variants={cardTextAnimate}>T – Trustworthiness</motion.p>
            <motion.p variants={cardTextAnimate}>I – Integrity</motion.p>
            <motion.p variants={cardTextAnimate}>
              E – Excellent Execution
            </motion.p>
            <motion.p variants={cardTextAnimate}>D – Delivery</motion.p>
          </ContentWrapper>
        </CardWrapper>
      </CardSection>
      <TeamSection>
        <h1>Meet our team</h1>
        <TeamCardWrapper>
          {teamCard.map((x, i) => (
            <TeamCard key={i} {...x} />
          ))}
        </TeamCardWrapper>
      </TeamSection>
      <OurStory />
    </Container>
  );
};

export default About;

const Container = styled.div`
  width: 90%;
  margin: 150px auto 40px;

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    margin: 20px auto 40px;
    width: 100%;
  }
`;

const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1370px;
  width: 100%;
  margin-bottom: 42px;

  ${mediaObj.smallDesktop} {
    flex-direction: column;
    margin-bottom: 600px;
  }

  ${mediaQueries.mobile} {
    margin-bottom: 585px;
  }
`;

const LeftHero = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;

  & > div {
    & > h1 {
      font-weight: 600;
      font-size: 31px;
      line-height: 140%;
      color: #143340;
    }

    & > p {
      margin: 10px 0 0;
      font-weight: 450;
      font-size: 1.2rem;
      line-height: 140%;
      color: #5e5e5e;
    }

    & > h2 {
      font-weight: 600;
      font-size: 25px;
      line-height: 140%;
      color: #143340;
      margin-top: 12px;
    }
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    & > div {
      & > h1 {
        font-size: 25px;
      }

      & > p {
        font-size: 16px;
      }

      & > h2 {
        font-size: 20px;
      }
    }
  }
`;

const H1 = styled(motion.h1)``;

const RightHero = styled(motion.div)`
  position: relative;
  width: 30%;

  ${mediaObj.smallDesktop} {
    width: 100%;
    margin-top: 60px;
  }
`;

const HeroImage1 = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -20px;
  right: 0;
  width: 348px;
  height: 368px;
  background: #ffffff;
  border-radius: 12px;

  & > img {
    margin: 0 auto;
    width: 95%;
    height: 95%;
    object-fit: cover;
    border-radius: 12px;
  }

  ${mediaObj.smallDesktop} {
    width: 55%;
    right: 50px;
    height: 285px;
  }

  ${mediaQueries.mobile} {
    width: 75%;
    right: 0;
  }
`;

const HeroImage2 = styled(HeroImage1)`
  top: 260px;
  left: -100px;
  width: 253px;
  height: 266px;

  ${mediaObj.smallDesktop} {
    width: 40%;
    height: 190px;
    left: 10%;
    top: 170px;
  }

  ${mediaQueries.mobile} {
    width: 190px;
    left: 0;
  }
`;

const HeroImage3 = styled(HeroImage1)`
  width: 307px;
  height: 323px;
  top: 400px;
  right: -24px;

  ${mediaObj.smallDesktop} {
    width: 50%;
    right: 100px;
    top: 320px;
    height: 225px;
  }

  ${mediaQueries.mobile} {
    width: 225px;
    right: 0;
    top: 290px;
  }
`;

const CardSection = styled(motion.div)`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 120px;
`;

const TeamSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
  width: 100%;
  margin: 120px 0 80px;

  & > h1 {
    font-weight: 600;
    font-size: 1.9375rem;
    line-height: 140%;
    color: #000000;
    text-align: center;
  }

  ${mediaQueries.mobile} {
    margin: 80px 0 0;

    & > h1 {
      font-size: 20px;
    }
  }
`;

const TeamCardWrapper = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  gap: 72px;
  width: 75%;
  margin: 0 auto 70px;

  ${mediaObj.tablet} {
    gap: 45px;
    width: 100%;
    justify-content: center;
  }
`;

import request from "../request";
import {
  adminGetOfferPaymentsByCategoryResponse,
  adminGetPaymentsByCategoryResponse,
  AdminPaymentConfirmPayload,
  AdminPaymentRejectPayload,
  AdminPaymentSearchByCategoryPayload,
  BankOfferPaymentPayload,
  BankOrderPaymentPayload,
  BankPaymentPayload,
  BankReferencePayload,
  GetPackageAmountResponse,
  verifyOfferTransactionPayload,
  verifyOrderTransactionPayload,
  verifyTransactionPayload,
} from "./types";
import { ApiResponse, PaginatedResponse } from "app/redux/types";
import { photosObj } from "../project/types";

export const getPackageAmount = async (
  packageItemId: number,
  plotUnit?: number
) => {
  return await request.get<ApiResponse<GetPackageAmountResponse>>(
    "/payment/get_amount",
    {
      params: {
        package_item_id: packageItemId,
        units: plotUnit,
      },
    }
  );
};

export const verifyTransaction = async (payload: verifyTransactionPayload) => {
  return await request.post<ApiResponse<string>>(
    "/payment/verify_transaction",
    payload
  );
};

export const verifyOfferTransaction = async (
  payload: verifyOfferTransactionPayload
) => {
  return await request.post<ApiResponse<string>>(
    "/offer/payment/verify",
    payload
  );
};

export const verifyOrderTransaction = async (
  payload: verifyOrderTransactionPayload
) => {
  return await request.post<ApiResponse<string>>(
    "/payment/order/verify_transaction",
    payload
  );
};

export const bankPayment = async (payload: BankPaymentPayload) => {
  return await request.post<ApiResponse<string>>("/payment/bank", payload);
};

export const bankOfferPayment = async (payload: BankOfferPaymentPayload) => {
  return await request.post<ApiResponse<string>>(
    "/offer/payment/bank",
    payload
  );
};

export const bankReferencePayment = async (payload: BankReferencePayload) => {
  return await request.post<ApiResponse<string>>(
    "/payment/get_bank_payment_reference",
    payload
  );
};

export const bankOrderPayment = async (payload: BankOrderPaymentPayload) => {
  return await request.post<ApiResponse<string>>(
    "/payment/order/bank",
    payload
  );
};

export const uploadPaymentEvidence = async (payload: FormData) => {
  return await request.post<ApiResponse<photosObj>>(
    "/payment/upload_payment_evidence",
    payload
  );
};

export const adminGetPaymentsByCategory = async (
  category_id: number,
  page: number,
  per_page: number,
  type: string
) => {
  return await request.get<
    PaginatedResponse<adminGetPaymentsByCategoryResponse[]>
  >(`/admin/payment/category/${category_id}/${page}`, {
    params: {
      type,
      per_page,
    },
  });
};

export const adminPaymentSearchByCategory = async (
  payload: AdminPaymentSearchByCategoryPayload,
  category_id: number,
  page: number,
  per_page: number,
  type: string
) => {
  return await request.post<
    PaginatedResponse<adminGetPaymentsByCategoryResponse[]>
  >(`/admin/payment/category/search/${category_id}/${page}`, payload, {
    params: {
      type,
      per_page,
    },
  });
};

export const adminPaymentConfirm = async (
  payload: AdminPaymentConfirmPayload
) => {
  return await request.post<ApiResponse<adminGetPaymentsByCategoryResponse>>(
    `/admin/payment/confirm`,
    payload
  );
};

export const adminPaymentReject = async (
  payload: AdminPaymentRejectPayload
) => {
  return await request.post<ApiResponse<adminGetPaymentsByCategoryResponse>>(
    `/admin/payment/reject`,
    payload
  );
};

export const adminGetPaymentExport = async (
  category_id: number,
  type: string
) => {
  return await request.get<string>(`/admin/payment/export/${category_id}`, {
    responseType: "arraybuffer",
    params: {
      type,
    },
  });
};

export const adminGetOfferPaymentsByCategory = async (
  category_id: number,
  page: number,
  per_page: number,
  type: string
) => {
  return await request.get<
    PaginatedResponse<adminGetOfferPaymentsByCategoryResponse[]>
  >(`/admin/offer/payment/category/${category_id}/${page}`, {
    params: {
      type,
      per_page,
    },
  });
};

export const adminOfferPaymentSearchByCategory = async (
  payload: AdminPaymentSearchByCategoryPayload,
  category_id: number,
  page: number,
  per_page: number,
  type: string
) => {
  return await request.post<
    PaginatedResponse<adminGetOfferPaymentsByCategoryResponse[]>
  >(`/admin/offer/payment/category/search/${category_id}/${page}`, payload, {
    params: {
      type,
      per_page,
    },
  });
};

export const adminGetOfferPaymentExport = async (
  category_id: number,
  type: string
) => {
  return await request.get<string>(
    `/admin/offer/payment/export/${category_id}`,
    {
      responseType: "arraybuffer",
      params: {
        type,
      },
    }
  );
};

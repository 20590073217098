import React, { useState } from "react";
import { useField } from "formik";
import styled from "styled-components";
import { ReactComponent as MailIcon } from "../../../../images/mail-icon.svg";
import { ReactComponent as EyeIcon } from "../../../../images/eye-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../../images/phone-icon.svg";
import mediaQueries from "styles/utils/mediaQueries";
import { motion } from "framer-motion";
import { cardVariant } from "app/utils/animationVariants";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: (
    ev:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  inputType?: "text" | "textarea" | "email" | "password" | "phone";
  textareaBg?: string;
  numberInput?: "number";
  min?: number;
}

const InputField: React.FC<Props> = ({
  label,
  placeholder,
  isDisabled,
  value,
  isRequired,
  inputType = "text",
  textareaBg,
  numberInput,
  min,
  ...props
}) => {
  const [isPassword, setIsPassword] = useState(true);
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"} ${
    isRequired ? "required" : ""
  }`;

  return (
    <InputContainer textareaBg={textareaBg} variants={cardVariant}>
      <label htmlFor={props.name} className={errorClass}>
        {label}
      </label>
      {inputType === "text" && (
        <input
          {...field}
          {...props}
          type={numberInput || "text"}
          id={props.name}
          value={value}
          className={errorClass}
          placeholder={placeholder}
          disabled={isDisabled}
          min={min}
          onChange={(ev) => {
            props.onChange(ev);
            field.onChange(ev);
          }}
        />
      )}

      {inputType === "textarea" && (
        <textarea
          {...field}
          {...props}
          id={props.name}
          className={errorClass}
          placeholder={placeholder}
          onChange={(ev) => {
            props.onChange(ev);
            field.onChange(ev);
          }}
        />
      )}

      {inputType === "email" && (
        <IconInput className={errorClass} htmlFor={props.name}>
          <input
            type="text"
            id={props.name}
            {...field}
            {...props}
            placeholder={placeholder}
            onChange={(ev) => {
              props.onChange(ev);
              field.onChange(ev);
            }}
          />
          <MailIcon />
        </IconInput>
      )}

      {inputType === "phone" && (
        <IconInput className={errorClass} htmlFor={props.name}>
          <input
            type="tel"
            id={props.name}
            {...field}
            {...props}
            placeholder={placeholder}
            onChange={(ev) => {
              props.onChange(ev);
              field.onChange(ev);
            }}
          />
          <PhoneIcon />
        </IconInput>
      )}

      {inputType === "password" && (
        <IconInput className={errorClass}>
          <input
            type={isPassword ? "password" : "text"}
            id={props.name}
            {...field}
            {...props}
            onChange={(ev) => {
              props.onChange(ev);
              field.onChange(ev);
            }}
          />
          <EyeIcon
            onClick={() => setIsPassword((prev) => !prev)}
            className="clickable"
          />
        </IconInput>
      )}

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </InputContainer>
  );
};

export default InputField;

interface InputContainerProps {
  textareaBg?: string;
}

const InputContainer = styled(motion.div)<InputContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    font-weight: 450;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    width: max-content;

    &.required:after {
      content: "*";
      font-size: 24px;
      line-height: 0;
      vertical-align: middle;
      color: #d90b2c;
    }

    ${mediaQueries.mobile} {
      font-size: 14px;
    }
  }

  & > input {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    height: 3.5rem;
    padding: 0 1.5rem;

    :focus-visible {
      outline: none;
    }

    &.error {
      border: 1px solid red;
    }
  }

  & > textarea {
    border: 1px solid #bdbdbd;
    border-radius: 0.5rem;
    height: 10rem;
    padding: 1.125rem 1.5rem;
    resize: none;
    background-color: ${({ textareaBg }) => textareaBg};

    :focus-visible {
      outline: none;
    }

    &.error {
      border: 1px solid red;
    }
  }
`;

const IconInput = styled.label`
  width: 100% !important;
  height: 3.5rem;
  padding: 18px 24px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  &.error {
    border: 1px solid red;
  }

  svg {
    width: 21px;
    height: 21px;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
    box-shadow: 0 0 0px 1000px #f3f3f3 inset;
  }

  .clickable {
    cursor: pointer;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import Spinner from "../components/Spinner";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));

export interface SelectBoxOption {
  value: number | string;
  name: string;
}
interface Props {
  label: string;
  selectedValue?: number | string;
  data?: SelectBoxOption[];
  stateHandler: (data: number | string) => void;
  isLoading?: boolean;
}

const SelectBox: React.FC<Props> = ({
  label,
  selectedValue,
  stateHandler,
  data,
  isLoading,
}) => {
  const classes = useStyles();
  const handleChange = (event: any, news: any) => {
    stateHandler(event.target.value);
  };

  const getSelectedValue = () => {
    if (!selectedValue || !data) return "";
    if (label === "Location")
      return (
        data.filter((ev) => ev.value === selectedValue)[0].name + ", Nigeria"
      );
    else return data.filter((ev) => ev.value === selectedValue)[0].name;
  };

  return (
    <Container>
      <FormControl sx={{ width: "100%", height: "20px" }}>
        <Select
          value={selectedValue || ""}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className="select"
          sx={{
            "& .MuiSelect-select": {
              padding: 0,
              fontWeight: 450,
              fontSize: "16px",
              color: "#303030",
              marginRight: "15px",
            },
            "& .MuiSvgIcon-root": {
              color: "#323232",
              height: "20px",
              width: "20px",
            },
          }}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            PaperProps: { sx: { maxHeight: 200 } },
          }}
          IconComponent={() => (
            <IconWrapper>
              {isLoading ? (
                <Spinner color="black" width={"20px"} height={"10px"} />
              ) : label === "Location" ? (
                <LocationOnRoundedIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </IconWrapper>
          )}
          renderValue={(selected) => {
            return <span>{label}</span>;
          }}
        >
          {data &&
            data.map((ev) => (
              <MenuItem key={ev.value} value={ev.value}>
                {ev.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <span className="selected-value">{getSelectedValue()}</span>
    </Container>
  );
};

export default SelectBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;

  & > * {
    font-family: "Circular Std", sans-serif;
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
  }
  .select {
    border: none;
    border-radius: 0.5rem;
    height: 3.5rem;

    & > fieldset {
      border: none;
    }
  }
  .selected-value {
    font-weight: 450;
    font-size: 13px;
    color: #ababab;
  }
`;

const IconWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  right: 10px;
`;

import { FC } from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface props {
  label: string;
  handleChange: (value: string, name: string) => void;
  width?: string;
  name: string;
  type?: string;
  value?: string;
}

const CustomInput: FC<props> = ({
  handleChange,
  label,
  width,
  name,
  type,
  value,
}) => {
  return (
    <Container width={width}>
      <Label>{label}</Label>
      <Input
        type={type || "text"}
        name={name}
        value={value}
        onChange={(e) => handleChange(e.target.value, name)}
      />
    </Container>
  );
};

export default CustomInput;

interface containerProps {
  width?: string;
}

const Container = styled.div<containerProps>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: ${({ width }) => (width ? width : "100%")};
`;

const Label = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #181d0b;
`;

const Input = styled.input`
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  color: #949292;
  height: 56px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  outline: none;

  ${mediaQueries.mobile} {
    height: 48px;
  }
`;

import { Box, Grid } from "@mui/material";
import { personalInfoPayload } from "app/api/users/types";
import { labelValueType } from "app/types/common";
import { RefObject, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  employmentStatusOptions,
  genderData,
  martialStatusOptions,
  titleOptions,
} from "../data";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { Form, Formik } from "formik";
import SelectField from "app/views/Admin/components/formik inputs/SelectField";
import { getAuth, setUser } from "app/redux/auth/authSlice";
import { useAppDispatch, useAppSelector } from "app/redux";
import { PersonalInfoValidation } from "./validation";
import useClientUpdate from "app/hooks/users/useClientUpdate";
import { ClientUserResponse } from "app/api/users/types";
import useGetAllCountries from "app/hooks/utilities/useGetAllCountries";
import mediaQueries from "styles/utils/mediaQueries";
import DatePickerField from "app/views/Admin/components/formik inputs/DatePickerField";
import dayjs from "dayjs";
import PhoneFormatter from "app/views/Admin/components/formik inputs/PhoneFormatter";
import useGetAgeGroups from "app/hooks/utilities/useGetAgeGroups";

interface Props {
  isEditable: boolean;
  saveBtn: RefObject<HTMLButtonElement>;
  resetBtn: RefObject<HTMLButtonElement>;
  isLoading: (value: boolean) => void;
  onSetToNotEditable: () => void;
}

const PersonalInfo: React.FC<Props> = ({
  isEditable,
  saveBtn,
  isLoading,
  resetBtn,
  onSetToNotEditable,
}) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<personalInfoPayload>();
  const [countries, setCountries] = useState<labelValueType<string, string>[]>(
    []
  );
  const [groups, setGroups] = useState<labelValueType<string, string>[]>([]);
  const { user } = useAppSelector(getAuth);
  const { data: countriesData, isLoading: countriesLoading } =
    useGetAllCountries();
  const { data: groupsData, isLoading: groupsLoading } = useGetAgeGroups();
  const { mutate: updateMutate, isLoading: isUpdating } = useClientUpdate();

  useEffect(() => {
    if (countriesData) {
      setCountries(
        countriesData.map((x) => ({ value: x.id.toString(), label: x.name }))
      );
    }
  }, [countriesData]);

  useEffect(() => {
    if (groupsData) {
      setGroups(
        groupsData.map((x) => ({
          value: x.id.toString(),
          label: x.group,
        }))
      );
    }
  }, [groupsData]);

  const settingInitial = useCallback(() => {
    if (user && countries.length > 0) {
      const {
        country,
        firstname,
        lastname,
        email,
        gender,
        phone_number,
        address,
        postal_code,
        marital_status,
        employment_status,
        occupation,
        title,
        dob,
        age_group,
      } = user as ClientUserResponse;
      const temp = countries.filter((x) => x.label === country)[0];
      setFormData({
        firstname,
        lastname,
        email,
        title,
        gender,
        phone_number,
        address,
        postal_code,
        marital_status,
        employment_status,
        occupation,
        country_id: +temp?.value,
        dob,
        age_group_id: age_group?.id,
      });
    }
  }, [user, countries]);

  useEffect(() => {
    settingInitial();
  }, [settingInitial]);

  const handleChange = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSuccess = (data: any) => {
    toast.success(`Details Updated Successfully!`);
    dispatch(setUser(data.data.data));
    onSetToNotEditable();
  };

  const onSubmit = () => {
    if (!formData) return;
    let payload = {
      ...formData,
    };

    if (!formData.dob) {
      delete payload.dob;
    }

    updateMutate(payload, {
      onSuccess,
    });
  };

  useEffect(() => {
    isLoading(isUpdating);
  }, [isUpdating, isLoading]);

  return (
    <Formik
      initialValues={{
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        email: formData?.email,
        title: formData?.title,
        gender: formData?.gender,
        phone_number: formData?.phone_number,
        address: formData?.address,
        postal_code: formData?.postal_code,
        marital_status: formData?.marital_status,
        employment_status: formData?.employment_status,
        occupation: formData?.occupation,
        age_group_id: formData?.age_group_id,
      }}
      enableReinitialize
      validationSchema={PersonalInfoValidation}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form>
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={3}
            width={"100%"}
            mt={"50px"}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputField
                  name="firstname"
                  label={"First name"}
                  placeholder={"First Name"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.firstname || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputField
                  name="lastname"
                  label={"Last name"}
                  placeholder={"Last Name"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.lastname || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectField
                  name="title"
                  label={"Title"}
                  onChange={(e: any) => handleChange(e.value, "title")}
                  options={titleOptions}
                  value={titleOptions.filter(
                    (x) => x.value === formData?.title
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isRequired
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <SelectField
                  name="gender"
                  label={"Gender"}
                  onChange={(e: any) => handleChange(e.value, "gender")}
                  options={genderData}
                  value={genderData.filter((x) => x.value === formData?.gender)}
                  selectType="normal"
                  disabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name="email"
                  label={"Email address"}
                  placeholder={"Email Address"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.email || ""}
                  isDisabled={true}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PhoneFormatter
                  name={"phone_number"}
                  label={"Phone number"}
                  formik={formik}
                  value={formData?.phone_number}
                  onChange={(value: string) =>
                    handleChange(value, "phone_number")
                  }
                  isDisabled={!isEditable}
                  isRequired
                  borderStyle={"1px solid #bdbdbd"}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <SelectField
                  name={"country_id"}
                  label={"Nationality"}
                  onChange={(e: any) => handleChange(e.value, "country_id")}
                  options={countries}
                  value={countries.filter(
                    (x) => x.value === formData?.country_id?.toString()
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isLoading={countriesLoading}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <DatePickerField
                  name="dob"
                  label="Date of birth"
                  placeholder="yyyy-mm-dd"
                  isDisabled={!isEditable}
                  value={dayjs(formData?.dob || null)}
                  onChange={(e) =>
                    handleChange(dayjs(e).format("YYYY-MM-DD"), "dob")
                  }
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectField
                  name="age_group_id"
                  label="Age group"
                  onChange={(e: any) => handleChange(e.value, "age_group_id")}
                  options={groups}
                  value={groups.filter(
                    (x) => x.value === formData?.age_group_id?.toString()
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isLoading={groupsLoading}
                  isRequired
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <SelectField
                  name={"marital_status"}
                  label={"Marital status"}
                  onChange={(e: any) => handleChange(e.value, "marital_status")}
                  options={martialStatusOptions}
                  value={martialStatusOptions.filter(
                    (x) => x.value === formData?.marital_status
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <SelectField
                  name={"employment_status"}
                  label={"Employment status"}
                  onChange={(e: any) =>
                    handleChange(e.value, "employment_status")
                  }
                  options={employmentStatusOptions}
                  value={employmentStatusOptions.filter(
                    (x) => x.value === formData?.employment_status
                  )}
                  selectType="normal"
                  disabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name={"occupation"}
                  label={"Occupation"}
                  placeholder={"Occupation"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.occupation || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <InputField
                  name={"address"}
                  label={"Home/office address"}
                  placeholder={"Address"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.address || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <InputField
                  name={"postal_code"}
                  label={"Postal code"}
                  placeholder={"Postal Code"}
                  onChange={(e) => handleChange(e.target.value, e.target.name)}
                  value={formData?.postal_code || ""}
                  isDisabled={!isEditable}
                  isRequired
                />
              </Grid>
            </Grid>

            <Box width={"100%"} mt={"20px"} display="none">
              <SubmitBtn type="submit" ref={saveBtn}>
                Save
              </SubmitBtn>
              <SubmitBtn type="button" ref={resetBtn} onClick={settingInitial}>
                Reset
              </SubmitBtn>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInfo;

export const SubmitBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-left: auto;
  color: #fbfbfb;
  width: 115px;
  height: 46px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  border: none;

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

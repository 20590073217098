import AddIcon from "@mui/icons-material/Add";
import { EditorState } from "draft-js";
import { FC } from "react";
import styled from "styled-components";
import BodyField from "../../Community/components/BodyField";

interface props {
  value: EditorState;
  setBodyState: React.Dispatch<React.SetStateAction<EditorState>>;
  handleAddOptions: () => void;
  fullWidth?: boolean;
}

const Option: FC<props> = ({
  setBodyState,
  value,
  handleAddOptions,
  fullWidth,
}) => {
  return (
    <>
      <BodyWrapper fullWidth={fullWidth}>
        <BodyField
          name={"option"}
          label={`Option`}
          value={value}
          onChange={setBodyState}
          placeholder={"Enter option"}
          isRequired
        />
      </BodyWrapper>
      <AddOptionsBtn onClick={handleAddOptions} disabled={!!!value}>
        <AddIcon /> Add an option for answers
      </AddOptionsBtn>
    </>
  );
};

export default Option;

const BodyWrapper = styled.div<{ fullWidth?: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? `100%` : `80%`)};
  margin-top: 25px;

  .rdw-editor-main {
    min-height: 70px;
  }
`;

const AddOptionsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 30px;
  color: #2a3313;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.25s ease-in;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  & > svg {
    fill: #2a3313;
    height: 18px;
    margin-top: -2px;
    width: 18px;
  }
`;

import { Box } from "@mui/material";
import styled from "styled-components";
import heroImage from "app/images/user/hero-image.png";
import mediaQueries from "styles/utils/mediaQueries";
import { useNavigate } from "react-router";
import { UserRoutes } from "app/utils/data";
import { mediaObj } from "styles/utils/media";

export const Hero = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"18px"}
      >
        <Title>Buy | Engage | Earn</Title>
        <SubTitle>
          We provide land for agricultural and homes purposes for individuals
          from all walks of life.
        </SubTitle>
      </Box>
      <ViewProjectsBtn onClick={() => navigate(UserRoutes.Projects)}>
        View available projects
      </ViewProjectsBtn>
      <HeroImg src={heroImage} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  isolation: isolate;
  height: 255px;
  padding: 40px 20px 22px;
  border-radius: 8px;

  ${mediaQueries.mobile} {
    height: auto;
  }
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 25px;
  line-height: 140%;
  color: #fbfbfb;

  ${mediaQueries.mobile} {
    font-size: 20px;
    text-align: center;
  }
`;

const SubTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #fbfbfb;
  width: 70%;

  ${mediaQueries.mobile} {
    width: 100%;
    font-size: 18px;
  }
`;

const ViewProjectsBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-height: 54px;
  background: #afd34f;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  border-radius: 8px;
  cursor: pointer;
  padding: 3px 20px;
  text-align: center;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-out;
  }

  ${mediaObj.mediumDesktop} {
    width: 160px;
    height: 45px;
  }

  ${mediaObj.smallDesktop} {
    width: 215px;
    height: 48px;
  }

  ${mediaQueries.mobile} {
    margin-top: 25px;
    width: 180px;
    height: 50px;
    font-size: 15px;
  }
`;

const HeroImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.5);
  z-index: -1;
`;

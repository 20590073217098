import styled from "styled-components";
import virtualImage from "app/images/web/virtual-image.jpg";
// import virtualImage from "app/images/virtual-staff.png";
import { mediaObj } from "styles/utils/media";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { heroAnimate, scaleText } from "../components/cards/framer-variants";

const VirtualSection = () => {
  const navigate = useNavigate();

  return (
    <Wrapper whileInView={heroAnimate} viewport={{ once: true }}>
      <ContentWrapper>
        <Title
          variants={scaleText}
          initial="hidden"
          whileInView="animate"
          viewport={{ once: true }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
        >
          Join the <GreenTxt>Adbond</GreenTxt> virtual team
        </Title>
        <Description>
          Pitch your skills and show up for clients at every stage. If this
          describes you, we'll love to work with you.
        </Description>
        <JoinBtn onClick={() => navigate("assessment")}>Join the team</JoinBtn>
      </ContentWrapper>
      <VirtualImage src={virtualImage} />
    </Wrapper>
  );
};

export default VirtualSection;

const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10%;
  width: 70%;
  padding: 80px 0 70px;
  margin: 0 auto 130px;
  //
  margin-bottom: 0;
  background-color: #ffffff;
  box-shadow: 0 0 0 100vmax #ffffff;
  clip-path: inset(0 -100vmax);

  ${mediaObj.mediumDesktop} {
    width: 90%;
  }

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaObj.bigMobile} {
    flex-direction: column-reverse;
    width: 100%;
    gap: 20px;
    margin-bottom: 50px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 40%;

  ${mediaObj.mediumDesktop} {
    width: 50%;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
  }
`;

const Title = styled(motion.h2)`
  font-weight: 700;
  font-size: 31px;
  line-height: 140%;
  color: #143340;
  width: 100%;

  ${mediaObj.bigMobile} {
    width: 90%;
    font-size: 25px;
  }
`;

const GreenTxt = styled.span`
  font-weight: 700;
  font-size: 31px;
  line-height: 140%;
  color: #afd34f;

  ${mediaObj.bigMobile} {
    font-size: 25px;
  }
`;

const Description = styled.p`
  font-weight: 450;
  font-size: 1.2rem;
  line-height: 140%;
  color: #5e5e5e;
`;

const JoinBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 185px;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  background: #afd34f;
  border-radius: 8px;
  margin-top: 15px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
  }
`;

const VirtualImage = styled.img`
  width: 350px;
  height: 280px;
  object-fit: cover;

  ${mediaObj.tablet} {
    width: 300px;
    height: 240px;
  }

  ${mediaObj.bigMobile} {
    width: 100%;
    max-width: 100%;
    height: 280px;
  }
`;

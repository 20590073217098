import React from "react";
import styled from "styled-components";

interface Props {
  label: string;
}

const PostTag: React.FC<Props> = ({ label }) => {
  return <Container>{label}</Container>;
};

export default PostTag;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ecf4dc;
  border-radius: 12px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 140%;
  color: #536425;
  text-transform: capitalize;
  height: 25px;
  padding: 4px 16px;
`;

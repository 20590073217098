import React from "react";
import { projectObj } from "app/api/project/types";
import Actions from "app/views/Admin/components/Actions";
import { CreateProjectUrl } from "..";
import { Wrapper } from "./MultipleRenderer";
import useDeactivateProjectLocation from "app/hooks/projects/useDeactivate";
import useActivateProjectLocation from "app/hooks/projects/useActivate";
import { toast } from "react-toastify";
import useDeleteProjectLocation from "app/hooks/projects/useDelete";
import { useSearchParams } from "react-router-dom";

const ActionRenderer = ({ data }: { data: projectObj }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: deactivateMutate, isLoading: isDeactivating } =
    useDeactivateProjectLocation();

  const { mutate: activateMutate, isLoading: isActivating } =
    useActivateProjectLocation();

  const { mutate: deleteMutate, isLoading: isDeleting } =
    useDeleteProjectLocation();

  const onEdit = () => {
    searchParams.set("create", CreateProjectUrl.project);
    searchParams.set("edit", data.id.toString());
    setSearchParams(searchParams);
  };

  const onDeactivate = (project_location_id: number) => {
    const payload = {
      project_location_id,
    };
    deactivateMutate(payload, {
      onSuccess: () => {
        toast.success("Project Deactivated Successfully!");
      },
    });
  };

  const onActivate = (project_location_id: number) => {
    const payload = {
      project_location_id,
    };
    activateMutate(payload, {
      onSuccess: () => toast.success("Project Activated Successfully!"),
    });
  };

  const onDelete = (project_location_id: number) => {
    const payload = {
      project_location_id,
    };
    deleteMutate(payload, {
      onSuccess: () => toast.success("Project Deleted Successfully!"),
    });
  };

  const onDeleteAction = (
    project_location_id: number,
    isActive: boolean,
    canDelete: boolean
  ) => {
    if (isActive) {
      onDeactivate(project_location_id);
    } else {
      canDelete
        ? onDelete(project_location_id)
        : onActivate(project_location_id);
    }
  };

  const getTypeText = (isActive: boolean, canDelete: boolean) => {
    if (isActive) {
      return "Deactivate";
    } else {
      return canDelete ? "Delete" : "Activate";
    }
  };

  return (
    <Wrapper length={data.locations.length}>
      {data.locations.map((ev) => (
        <Actions
          key={ev.id}
          position="horizontal"
          editHandler={onEdit}
          marginRight="0"
          width="max-content"
          deleteText={getTypeText(ev.active, ev.canDelete)}
          deleteHandler={() => onDeleteAction(ev.id, ev.active, ev.canDelete)}
          isDeleting={isDeactivating || isActivating || isDeleting}
        />
      ))}
    </Wrapper>
  );
};

export default ActionRenderer;

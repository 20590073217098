import { ApiResponse, PaginatedResponse } from "app/redux/types";
import { OfferResponse } from "../offers/types";
import request from "../request";
import {
  CommentsObj,
  CreateBlogpostPayload,
  CreateBlogpostResponse,
  CreateCommentPayload,
  CreateEventPayload,
  CreateForumPayload,
  CreateNewsPayload,
  DeleteCommentPayload,
  DeleteEventPayload,
  DeleteForumPayload,
  DeleteNewsPayload,
  DeletePostPayload,
  DeleteReplyCommentPayload,
  DeleteReplyResponse,
  EventResponse,
  ForumResponse,
  GetPostResponse,
  IncreasePostViewsPayload,
  LikeCommentPayload,
  LikePostPayload,
  NewsResponse,
  RemoveReactionPayload,
  ReplyCommentPayload,
  ReplyResponse,
  SearchTagsPayload,
  SearchTagsResponse,
  UpdateEventPayload,
  UpdateForumPayload,
  UpdateNewsPayload,
  UpdatePostPayload,
} from "./types";

export const searchTags = async (payload: SearchTagsPayload) => {
  return await request.post<ApiResponse<SearchTagsResponse[]>>(
    `/search_tags`,
    payload
  );
};

export const createBlogpost = async (payload: CreateBlogpostPayload) => {
  return await request.post<ApiResponse<CreateBlogpostResponse[]>>(
    `/admin/post/create`,
    payload
  );
};

export const getPost = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<GetPostResponse[]>>(
    `/admin/post/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const getPostByTitle = async (title: string) => {
  return await request.get<ApiResponse<GetPostResponse>>(
    `/admin/post/get_post/${title}`
  );
};

export const updatePost = async (payload: UpdatePostPayload) => {
  return await request.post<ApiResponse<CreateBlogpostResponse>>(
    `/admin/post/update`,
    payload
  );
};

export const deletePost = async (payload: DeletePostPayload) => {
  return await request.post<ApiResponse<string[]>>(
    `/admin/post/delete`,
    payload
  );
};

export const createNews = async (payload: CreateNewsPayload) => {
  return await request.post<ApiResponse<NewsResponse>>(
    `/admin/news/create`,
    payload
  );
};

export const getNews = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<NewsResponse[]>>(
    `/admin/news/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const getNewsByTitle = async (title: string) => {
  return await request.get<ApiResponse<NewsResponse>>(
    `/admin/news/get_news/${title}`
  );
};

export const updateNews = async (payload: UpdateNewsPayload) => {
  return await request.post<ApiResponse<NewsResponse>>(
    `/admin/news/update`,
    payload
  );
};

export const deleteNews = async (payload: DeleteNewsPayload) => {
  return await request.post<ApiResponse<string[]>>(
    `/admin/news/delete`,
    payload
  );
};

export const createEvent = async (payload: CreateEventPayload) => {
  return await request.post<ApiResponse<EventResponse>>(
    `/admin/event/create`,
    payload
  );
};

export const getEvent = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<EventResponse[]>>(
    `/admin/event/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const getEventByTitle = async (title: string) => {
  return await request.get<ApiResponse<EventResponse>>(
    `/admin/event/get_event/${title}`
  );
};

export const updateEvent = async (payload: UpdateEventPayload) => {
  return await request.post<ApiResponse<EventResponse>>(
    `/admin/event/update`,
    payload
  );
};

export const deleteEvent = async (payload: DeleteEventPayload) => {
  return await request.post<ApiResponse<string[]>>(
    `/admin/event/delete`,
    payload
  );
};

export const createForum = async (payload: CreateForumPayload) => {
  return await request.post<ApiResponse<ForumResponse>>(
    `/admin/forum/create`,
    payload
  );
};

export const getForum = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<ForumResponse[]>>(
    `/admin/forum/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const getForumByTitle = async (title: string) => {
  return await request.get<ApiResponse<ForumResponse>>(
    `/admin/forum/get_forum/${title}`
  );
};

export const updateForum = async (payload: UpdateForumPayload) => {
  return await request.post<ApiResponse<ForumResponse>>(
    `/admin/forum/update`,
    payload
  );
};

export const deleteForum = async (payload: DeleteForumPayload) => {
  return await request.post<ApiResponse<string[]>>(
    `/admin/forum/delete`,
    payload
  );
};

export const createComment = async (payload: CreateCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/admin/comment/create`,
    payload
  );
};

export const replyComment = async (payload: ReplyCommentPayload) => {
  return await request.post<ApiResponse<ReplyResponse>>(
    `/admin/comment/reply`,
    payload
  );
};

export const getPostComments = async (commentId: number) => {
  return await request.get<ApiResponse<CommentsObj[]>>(
    `/admin/comment/get_comments/${commentId}`
  );
};

export const deleteComment = async (payload: DeleteCommentPayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/comment/delete`,
    payload
  );
};

export const deleteReplyComment = async (
  payload: DeleteReplyCommentPayload
) => {
  return await request.post<ApiResponse<DeleteReplyResponse>>(
    `/admin/comment/delete_reply`,
    payload
  );
};

export const likeComment = async (payload: LikeCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/admin/comment/like`,
    payload
  );
};

export const dislikeComment = async (payload: LikeCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/admin/comment/dislike`,
    payload
  );
};

export const removeReaction = async (payload: RemoveReactionPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/admin/comment/remove_reaction`,
    payload
  );
};

export const increasePostViews = async (payload: IncreasePostViewsPayload) => {
  return await request.post<ApiResponse<string>>(
    `/admin/post/increase_views`,
    payload
  );
};

export const clientGetNews = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<NewsResponse[]>>(
    `/news/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const getCommunityOffers = async () => {
  return await request.get<PaginatedResponse<OfferResponse[]>>(`/offer/all`);
};

export const clientGetPost = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<GetPostResponse[]>>(
    `/post/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const clientGetPostByTitle = async (title: string) => {
  return await request.get<ApiResponse<GetPostResponse>>(
    `/post/get_post/${title}`
  );
};

export const clientGetForum = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<ForumResponse[]>>(
    `/forum/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const clientGetEvent = async (page = 1, perPage?: number) => {
  return await request.get<PaginatedResponse<EventResponse[]>>(
    `/event/get/${page}`,
    {
      params: {
        per_page: perPage,
      },
    }
  );
};

export const clientIncreasePostViews = async (
  payload: IncreasePostViewsPayload
) => {
  return await request.post<ApiResponse<string>>(
    `/post/increase_views`,
    payload
  );
};

export const clientLikeComment = async (payload: LikeCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(`/comment/like`, payload);
};

export const clientDislikeComment = async (payload: LikeCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/comment/dislike`,
    payload
  );
};

export const clientRemoveReaction = async (payload: RemoveReactionPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/comment/remove_reaction`,
    payload
  );
};

export const clientCreateComment = async (payload: CreateCommentPayload) => {
  return await request.post<ApiResponse<CommentsObj>>(
    `/comment/create`,
    payload
  );
};

export const clientReplyComment = async (payload: ReplyCommentPayload) => {
  return await request.post<ApiResponse<ReplyResponse>>(
    `/comment/reply`,
    payload
  );
};

export const clientLikePost = async (payload: LikePostPayload) => {
  return await request.post<ApiResponse<GetPostResponse>>(
    `/post/like`,
    payload
  );
};

export const clientPostRemoveReaction = async (payload: LikePostPayload) => {
  return await request.post<ApiResponse<GetPostResponse>>(
    `/post/remove_reaction`,
    payload
  );
};

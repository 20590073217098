import { ApiResponse } from "../../redux/types";
import request from "../request";
import {
  AllPackageLocationResponse,
  categoryResponse,
  ClientsPerPackageRes,
  CreatePackagePayload,
  CreateProjectPayload,
  DeactivatePackagePayload,
  DeactivateProjectLocationPayload,
  EditPackagePayload,
  InspectionDateResponse,
  LatestPackageResponse,
  locationObj,
  packagesObj,
  ProjectItemsObj,
  projectObj,
  projectResponse,
  SearchBenefitsPayload,
  SearchBenefitsResponse,
  SiteInspectionPayload,
  SiteInspectionResponse,
  UpdateProjectPayload,
} from "./types";

export const getAllCategories = async () => {
  return await request.get<ApiResponse<categoryResponse[]>>(`/category/all`);
};

export const getProjectsByCategoryId = async (id: number) => {
  return await request.get<ApiResponse<projectResponse>>(
    `/category/get_category/${id}`
  );
};

export const getSinglePackage = async (id: number) => {
  return await request.get<ApiResponse<packagesObj>>(
    `/package/get_package/${id}`
  );
};

export const getASingleProject = async (projectId: number) => {
  return await request.get<ApiResponse<projectObj>>(
    `/project/get_project/${projectId}`
  );
};

export const getALocationById = async (projectLocationId: number) => {
  return await request.get<ApiResponse<locationObj>>(
    `/project/get_projects_location/${projectLocationId}`
  );
};

export const getAllProjectPerCategory = async (id: number) => {
  return await request.get<ApiResponse<projectObj[]>>(
    `/project/get_projects/${id}`
  );
};

export const getAllLocationPerProject = async (id: number) => {
  return await request.get<ApiResponse<locationObj[]>>(
    `/project/get_locations/${id}`
  );
};

export const getAllPackagePerLocation = async (id: number) => {
  return await request.get<ApiResponse<packagesObj[]>>(
    `/package/get_packages/${id}`
  );
};

export const getAllItemPerPackage = async (id: number) => {
  return await request.get<ApiResponse<ProjectItemsObj>>(
    `/package/get_package/${id}`
  );
};

export const getInspectionDaysPerLocation = async (id: number) => {
  return await request.get<ApiResponse<InspectionDateResponse[]>>(
    `/project/get_inspection_days/${id}`
  );
};

export const postSiteInspection = async (payload: SiteInspectionPayload) => {
  return await request.post<ApiResponse<SiteInspectionResponse>>(
    `/inspection/request`,
    payload
  );
};

export const getAllPackageLocation = async () => {
  return await request.get<ApiResponse<AllPackageLocationResponse[]>>(
    `/get_locations`
  );
};

export const searchBenefits = async (payload: SearchBenefitsPayload) => {
  return await request.post<ApiResponse<SearchBenefitsResponse[]>>(
    `/search_benefits`,
    payload
  );
};

export const createPackage = async (payload: CreatePackagePayload) => {
  return await request.post<ApiResponse<packagesObj>>(
    `/admin/package/create`,
    payload
  );
};

export const editPackage = async (payload: EditPackagePayload) => {
  return await request.post<ApiResponse<packagesObj>>(
    `/admin/package/update`,
    payload
  );
};

export const createProject = async (payload: CreateProjectPayload) => {
  return await request.post<ApiResponse<projectObj>>(
    `/admin/project/create`,
    payload
  );
};

export const updateProject = async (payload: UpdateProjectPayload) => {
  return await request.post<ApiResponse<projectObj>>(
    `/admin/project/update`,
    payload
  );
};

export const deactivateProjectLocation = async (
  payload: DeactivateProjectLocationPayload
) => {
  return await request.post<ApiResponse<projectObj>>(
    `/admin/project/deactivate_project_location`,
    payload
  );
};

export const activateProjectLocation = async (
  payload: DeactivateProjectLocationPayload
) => {
  return await request.post<ApiResponse<projectObj>>(
    `/admin/project/activate_project_location`,
    payload
  );
};

export const deleteProjectLocation = async (
  payload: DeactivateProjectLocationPayload
) => {
  return await request.post<ApiResponse<projectObj>>(
    `/admin/project/delete_project_location`,
    payload
  );
};

export const deactivatePackage = async (payload: DeactivatePackagePayload) => {
  return await request.post<ApiResponse<packagesObj>>(
    `/admin/package/deactivate`,
    payload
  );
};

export const activatePackage = async (payload: DeactivatePackagePayload) => {
  return await request.post<ApiResponse<packagesObj>>(
    `/admin/package/activate`,
    payload
  );
};

export const deletePackage = async (payload: DeactivatePackagePayload) => {
  return await request.post<ApiResponse<packagesObj>>(
    `/admin/package/delete`,
    payload
  );
};

export const getLatestPackage = async () => {
  return await request.get<ApiResponse<LatestPackageResponse[]>>(
    `/package/latest`
  );
};

export const getProjectCount = async () => {
  return await request.get<ApiResponse<number>>(`/project/count`);
};

export const downloadPackageBrochure = async (packageId: string) => {
  return await request.get<string>(`/package/download_brochure/${packageId}`, {
    responseType: "arraybuffer",
  });
};

export const clientsPerPackage = async (packageId: string) => {
  return await request.get<ApiResponse<ClientsPerPackageRes[]>>(
    `/package/clients/${packageId}`
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getProjectsByCategoryId,
  getAllCategories,
  getSinglePackage,
  getAllProjectPerCategory,
  getAllLocationPerProject,
  getAllPackagePerLocation,
  getInspectionDaysPerLocation,
  postSiteInspection,
};

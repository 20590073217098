import styled from "styled-components";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import { FC } from "react";
import { useNavigate } from "react-router";
import { truncateTxt } from "../../../../utils";
import { photosObj } from "app/api/project/types";
import { mediaObj } from "styles/utils/media";

interface props {
  address?: string;
  location?: string;
  packageName: string;
  plots?: string;
  addClientField?: boolean;
  clientsNo?: number;
  isMain?: boolean;
  id?: number;
  mainFile?: photosObj;
  isSoldOut: boolean;
  isOnPromo: boolean;
  promoDiscount: number;
}

const ProjectCard: FC<props> = ({
  address,
  location,
  packageName,
  addClientField,
  isMain,
  id,
  mainFile,
  plots,
  isSoldOut,
  isOnPromo,
  promoDiscount,
  clientsNo,
}) => {
  const navigate = useNavigate();

  return (
    <Container isMain={isMain} isSoldOut={isSoldOut}>
      {isSoldOut && <SoldOut>Sold Out</SoldOut>}
      {!isSoldOut && isOnPromo && <OnPromo>{promoDiscount}% off</OnPromo>}

      {mainFile?.file_type === "image" && (
        <Image src={mainFile.url} isMain={isMain} />
      )}
      {mainFile?.file_type === "video" && (
        <VideoWrapper isMain={isMain}>
          <video autoPlay muted loop>
            <source src={mainFile.url} type="video/mp4" />
          </video>
        </VideoWrapper>
      )}

      <ContentWrapper isMain={isMain}>
        <Entry isMain={isMain}>
          <LocationOnRoundedIcon />
          <EntryValue isMain={isMain}>
            {truncateTxt(`${location}, ${address}` || "", 50)}
          </EntryValue>
        </Entry>
        <Entry>
          <EntryLabel isMain={isMain}>Package type</EntryLabel>
          <EntryValue isMain={isMain}>{packageName}</EntryValue>
        </Entry>
        <Entry>
          <EntryLabel isMain={isMain}>Plots</EntryLabel>
          <EntryValue isMain={isMain}>{plots}</EntryValue>
        </Entry>
        {addClientField && isMain && (
          <Entry>
            <EntryLabel isMain={isMain}>Clients</EntryLabel>
            <EntryValue isMain={isMain}>{clientsNo}</EntryValue>
          </Entry>
        )}
        {isMain && !isSoldOut && (
          <DetailsBtn onClick={() => navigate(`${id}`)}>See details</DetailsBtn>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default ProjectCard;

interface cardType {
  isMain?: boolean;
  isSoldOut?: boolean;
}

const Container = styled.div<cardType>`
  position: relative;
  width: ${({ isMain }) => (isMain ? "30%" : "231px")};
  height: ${({ isMain }) => (isMain ? "auto" : "210px")};
  overflow: auto;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: ${({ isMain }) => (isMain ? "12px" : "5.99348px")};
  background: ${({ isSoldOut }) => isSoldOut && "#f1f1f1"};
  cursor: ${({ isSoldOut }) => isSoldOut && "not-allowed"};

  ${mediaObj.mediumDesktop} {
    width: 32%;
  }
`;

const SoldOut = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #181d0b;
  border-radius: 0px 8px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const OnPromo = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #afd34f;
  border-radius: 0px 8px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const Image = styled.img<cardType>`
  width: 100%;
  object-fit: cover;
  height: ${({ isMain }) => (isMain ? "168px" : "95px")};
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;
`;

const VideoWrapper = styled.div<cardType>`
  width: 100%;
  height: ${({ isMain }) => (isMain ? "168px" : "95px")};
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5.99348px;
    border-top-right-radius: 5.99348px;
  }
`;

const ContentWrapper = styled.div<cardType>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMain }) => (isMain ? "15px" : "12px")};
  padding: ${({ isMain }) =>
    isMain ? "12px 12px 20px 14px" : "2px 12px 8px 14px"};
`;

const Entry = styled.div<cardType>`
  display: flex;
  align-items: center;
  gap: ${({ isMain }) => (isMain ? "16px" : "9px")};

  & > svg {
    width: ${({ isMain }) => (isMain ? "16px" : "14px")};
    height: ${({ isMain }) => (isMain ? "19px" : "16px")};
  }
`;

const EntryLabel = styled.div<cardType>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  font-weight: 500;
  font-size: ${({ isMain }) => (isMain ? "13px" : "10px")};
  line-height: 140%;
  color: #fbfbfb;
  min-height: 26px;
  background: #143340;
  border-radius: 4px;
`;

const EntryValue = styled.p<cardType>`
  font-weight: 500;
  font-size: ${({ isMain }) => (isMain ? "16px" : "12px")};
  line-height: 140%;
  color: #5e5e5e;
`;

const DetailsBtn = styled.div`
  width: max-content;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 2px;
    background-color: #afd34f;
    width: 0;
  }

  &:hover::after {
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
`;

import React from "react";
import { OfferResponse } from "app/api/offers/types";
import { useOutletContext } from "react-router";
import styled from "styled-components";
import SalesOfferTable from "./components/gridComponents/SalesOfferTable";
import Valuation from "./components/valuation";

const OffersPage = () => {
  const { offerData }: { offerData: OfferResponse } = useOutletContext();

  const formatData = () => {
    const dataArray = offerData.bids.map((ev) => {
      const temp = {
        id: ev.id,
        clientName: ev.client.firstname + " " + ev.client.lastname,
        phone: ev.client.phone_number || "-",
        amountOffered: ev.bid__price,
        date: ev.created_at,
      };

      return temp;
    });

    return dataArray;
  };

  return (
    <>
      <TableWrapper>
        <h6>Sales Offer</h6>
        {offerData.bid_accepted ? (
          <Content>You have accepted a bid. Please await payment.</Content>
        ) : offerData.bids.length === 0 ? (
          <NoOffer>We have no sale offer for this package yet</NoOffer>
        ) : (
          <SalesOfferTable data={formatData()} />
        )}
      </TableWrapper>
      <Valuation price={+offerData.current_valuation} />
    </>
  );
};

export default OffersPage;

const TableWrapper = styled.div`
  padding: 19px 17px;
  background: #ffffff;
  box-shadow: 0px 8px 48px #eeeeee;
  border-radius: 8px;
  overflow: visible !important;

  h6 {
    font-weight: 450;
    font-size: 16px;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 24px;
  }
`;

export const NoOffer = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
  margin: 40px 0;
  text-align: center;
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #464646;
  padding: 11px 13px;
  background: #e6f2fa;
  border-radius: 4px;
`;

import { useQuery } from "@tanstack/react-query";
import { clientGetPostByTitle, getPostByTitle } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useGetPostByTitle = (title: string) => {
  return useQuery(
    [CommunityQueryKeys.getPostByTitle],
    () => getPostByTitle(title),
    {
      enabled: !!title,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetPostByTitle;

export const useClientGetPostByTitle = (title: string) => {
  return useQuery(
    [CommunityQueryKeys.clientGetPostByTitle],
    () => clientGetPostByTitle(title),
    {
      enabled: !!title,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

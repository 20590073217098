import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import DualRing from "app/views/Web/components/loader";
import { capitalize } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { NoProjectText, SpinnerWrapper, tabProps } from "../../..";
import Details from "./Details";

const Allcontent = () => {
  const { data: fetchedAllCategory, isLoading: allCategoryIsLoading } =
    useGetAllCategories();
  const [selectedCategory, setSelectedCategory] = useState<number>();

  useEffect(() => {
    if (fetchedAllCategory && fetchedAllCategory.length > 0) {
      setSelectedCategory(fetchedAllCategory[0].id);
    }
  }, [fetchedAllCategory]);

  if (allCategoryIsLoading)
    return (
      <SpinnerWrapper>
        <DualRing width="20px" height="20px" color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (fetchedAllCategory && fetchedAllCategory.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no available category yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      <TabsWrapper>
        {fetchedAllCategory?.map((x, i) => (
          <Tab
            key={i}
            isActive={x.id === selectedCategory}
            onClick={() => setSelectedCategory(x.id)}
          >
            {capitalize(x.name)}
          </Tab>
        ))}
      </TabsWrapper>
      {selectedCategory && <Details selectedCategory={selectedCategory} />}
    </>
  );
};

export default Allcontent;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 9px;
`;

const Tab = styled.span<tabProps>`
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  border-bottom: ${({ isActive }) => (isActive ? "3px solid #AFD34F" : "none")};
  cursor: pointer;
  transition: all 0.2s ease-in;
  white-space: nowrap;
`;

import { dashboardProjSalesRes } from "app/api/sales/types";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import DualRing from "../../Web/components/loader";
import { MainLoaderWrapper } from "../../Web/Project/projectCategory";
import { MonthRange } from "../Dashboard/data";

export interface chartType {
  options: {
    dataLabels: {
      enabled: boolean;
    };
    stroke: {
      curve: string;
    };
    noData: {
      text: string;
    };
    xaxis: {
      categories: string[];
    };
    plotOptions: any;
    legend: {
      position: string;
    };
    title: any;
    colors: string[];
  };
  series: dashboardProjSalesRes[];
}

interface props {
  monthRange: MonthRange;
  data: dashboardProjSalesRes[];
  isLoading: boolean;
  legendPosition?: "top" | "right" | "bottom" | "left";
  colors?: string[];
  noTitle?: boolean;
}

const ApexChart: React.FC<props> = ({
  monthRange,
  data,
  isLoading,
  legendPosition = "bottom",
  colors,
  noTitle,
}) => {
  const initialChartData: chartType = {
    options: {
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      noData: {
        text: "Loading...",
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "April", "May", "June"],
      },
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      },
      legend: {
        position: legendPosition,
      },
      title: {
        text: noTitle ? "" : "SQM in 100",
        floating: false,
        offsetY: 0,
        align: "left",
        style: {
          color: "#181D0B",
          fontWeight: "500",
          fontSize: "13px",
          lineHeight: "140%",
        },
      },
      colors: colors || ["#49B7E4", "#95CEEC", "#97B644"],
    },
    series: [
      {
        name: "land",
        data: [30, 40, 45, 50, 49, 60],
      },
      {
        name: "agro",
        data: [60, 40, 33, 76, 34, 45],
      },
      {
        name: "homes",
        data: [10, 25, 5, 30, 87, 30],
      },
    ],
  };
  const [chartConfig, setChartConfig] = useState(initialChartData);

  useEffect(() => {
    if (monthRange === MonthRange.FirstQuarter) {
      setChartConfig({
        ...chartConfig,
        options: {
          ...chartConfig.options,
          xaxis: {
            ...chartConfig.options.xaxis,
            categories: ["Jan", "Feb", "Mar", "April", "May", "June"],
          },
        },
        series: data,
      });
    } else if (monthRange === MonthRange.LastQuarter) {
      setChartConfig({
        ...chartConfig,
        options: {
          ...chartConfig.options,
          xaxis: {
            ...chartConfig.options.xaxis,
            categories: ["July", "Aug", "Sep", "Oct", "Nov", "Dec"],
          },
        },
        series: data,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthRange, data]);

  return (
    <Container>
      {isLoading ? (
        <MainLoaderWrapper>
          <DualRing width="30px" height="30px" color="#727272" />
        </MainLoaderWrapper>
      ) : (
        <Chart
          series={chartConfig.series}
          height={300}
          options={chartConfig.options}
          type={"bar"}
        />
      )}
    </Container>
  );
};

export default ApexChart;

const Container = styled.div`
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { getInspectionDaysPerLocation } from "app/api/project";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const useGetInspectionDaysPerLocation = (projectLocationId: number) => {
  return useQuery(
    [siteTourKeys.getInspectionDaysPerLocation, projectLocationId],
    () => getInspectionDaysPerLocation(projectLocationId),
    {
      enabled: !!projectLocationId,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetInspectionDaysPerLocation;

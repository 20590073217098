import { EventResponse } from "app/api/community/types";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
import { truncateTxt } from "app/utils";

export interface UpcomingEventCardProps {
  data: EventResponse;
}

const UpcomingEventCard: React.FC<UpcomingEventCardProps> = ({ data }) => {
  return (
    <Container>
      <Img src={data.cover_photo.url} />
      <H3>{data.title}</H3>
      <Para>{truncateTxt(data.description, 80)}</Para>
      <DateTime>
        {dayjs(data.start_date).format("DD MMMM,") + " " + data.start_time}
      </DateTime>
    </Container>
  );
};

export default UpcomingEventCard;

const Container = styled.div`
  flex-shrink: 0;
  width: 243px;
  height: 210px;
  padding: 8px;
  padding-bottom: 13px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
`;

const Img = styled.img`
  width: 100%;
  height: 96px;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
`;

const H3 = styled.h3`
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #181d0b;
`;

const Para = styled.p`
  font-weight: 450;
  font-size: 0.75rem;
  line-height: 140%;
  color: #767676;
  margin: 8px 0px;
`;

const DateTime = styled.div`
  font-weight: 450;
  font-size: 0.75rem;
  line-height: 140%;
  color: #49b7e4;
`;

import { PromoPackageObj } from "app/api/offers/types";
import React from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  data: PromoPackageObj[];
}

const PromoPackage: React.FC<Props> = ({ data }) => {
  return (
    <>
      <GridItem type="project">
        <span>Category: </span>
        <span>{data[0].product.category}</span>
      </GridItem>

      <GridItem>
        <span>Project Type:</span>
        <CategoryWrapper>
          <Pills>{data[0].product.project}</Pills>
        </CategoryWrapper>
      </GridItem>

      <GridItem>
        <span>Location: </span>
        <span>{data[0].product.location} State</span>
      </GridItem>

      <GridItem type="package">
        <span>Package: </span>
        {data.length === 1 ? (
          <span>{data[0].product.name} State</span>
        ) : (
          <span>{data.map((ev) => ev.product.name).join(", ")} State</span>
        )}
      </GridItem>
    </>
  );
};

export default PromoPackage;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface GridItemProps {
  type?: "package" | "project";
}

const GridItem = styled.div<GridItemProps>`
  width: ${({ type }) => type === "project" && "max-content"};
  padding: ${({ type }) => type === "project" && "4px 8px"};
  background-color: ${({ type }) => type === "project" && "#CDE6F5"};
  border-radius: ${({ type }) => type === "project" && "4px"};
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;

  & > span:nth-child(1) {
    color: #2a3313;
  }

  & > span:nth-child(2) {
    color: ${({ type }) => type === "project" && "#2A3313"};
    color: ${({ type }) => type === "package" && "#AFD34F"};
    text-transform: capitalize;
  }

  ${mediaQueries.mobile} {
    font-size: 0.75rem;
  }
`;

const Pills = styled.div`
  padding: 4px 12px;
  background: #b6b6b6;
  border-radius: 20px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 140%;
  color: #fbfbfb;
  text-transform: capitalize;
`;

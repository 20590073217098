import { delayStagger, variant } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";
import Cookies from "./components/Cookies";
import Privacy from "./components/Privacy";
import { Content } from "./components/styles";
import TC from "./components/TC";

enum LegalPath {
  privacy = "privacy",
  cookies = "cookies",
  tc = "tc",
}

const Legal = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/").at(-1);

  const getHeaderName = () => {
    if (path === LegalPath.privacy) return "Privacy Policy";
    if (path === LegalPath.cookies) return "Cookies Policy";
    if (path === LegalPath.tc) return "Terms and Conditions";
    return "";
  };

  const getHeaderSubText = () => {
    if (path === LegalPath.privacy)
      return "At Adbond Harvest and Homes, we are committed to protecting the privacy of our website visitors and customers. This privacy policy outlines the types of information we collect, how we use and protect it, and your rights concerning your personal information.";
    if (path === LegalPath.cookies) return "Sub copy: Last updated: 15-02-2023";
    if (path === LegalPath.tc)
      return `Welcome to the Adbond Harvest and Homes website, www.adbondharvestandhomes.com, which is owned and operated by Adbond Harvest and Homes, an agro-to-home development company. By accessing or using this Site, you agree to be bound by the following terms and conditions (the "Terms and Conditions"). Please read them carefully before using the Site.`;
    return "";
  };

  return (
    <Container>
      <LeftSide
        variants={delayStagger(0.1)}
        custom={0.7}
        viewport={{ once: true, amount: 0.2 }}
        whileInView={"animate"}
        initial={"closed"}
      >
        <LeftItem
          isActive={path === LegalPath.privacy}
          onClick={() => navigate(`/legal/${LegalPath.privacy}`)}
          variants={variant}
        >
          <No>1</No>
          <Value>Privacy Policy</Value>
        </LeftItem>
        <LeftItem
          isActive={path === LegalPath.cookies}
          onClick={() => navigate(`/legal/${LegalPath.cookies}`)}
          variants={variant}
        >
          <No>2</No>
          <Value>Cookies Policy</Value>
        </LeftItem>
        <LeftItem
          isActive={path === LegalPath.tc}
          onClick={() => navigate(`/legal/${LegalPath.tc}`)}
          variants={variant}
        >
          <No>3</No>
          <Value>Terms and Conditions</Value>
        </LeftItem>
      </LeftSide>
      <RightSide>
        <Header>{getHeaderName()}</Header>
        <Content>{getHeaderSubText()}</Content>

        {path === LegalPath.privacy && <Privacy />}
        {path === LegalPath.cookies && <Cookies />}
        {path === LegalPath.tc && <TC />}
      </RightSide>
    </Container>
  );
};

export default Legal;

const Container = styled.div`
  display: flex;
  min-height: 685px;
  margin-bottom: 140px;

  ${mediaObj.mediumDesktop} {
    flex-direction: column;
  }

  ${mediaQueries.mobile} {
    margin-bottom: 40px;
  }
`;

const LeftSide = styled(motion.div)`
  width: 292px;
  flex-shrink: 0;
  padding: 30px 44px;
  /* background: #e6f2fa; */
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${mediaObj.mediumDesktop} {
    gap: 20px;
    padding: 30px;
  }

  ${mediaObj.smallDesktop} {
    padding: 20px 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
  }
`;

const RightSide = styled.div`
  padding: 30px;
  padding-right: 150px;

  ${mediaObj.mediumDesktop} {
    padding: 30px;
  }

  ${mediaObj.smallDesktop} {
    padding: 30px 0;
  }

  ${mediaQueries.mobile} {
    padding: 0;
    margin-top: 40px;
  }
`;

const Header = styled.h2`
  font-weight: 500;
  font-size: 1.5625rem;
  line-height: 140%;
  color: #2a3313;
  margin-bottom: 0.5rem;
`;

interface LeftItemProps {
  isActive: boolean;
}

const LeftItem = styled(motion.div)<LeftItemProps>`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  opacity: ${({ isActive }) => !isActive && 0.4};

  &:hover {
    opacity: 0.8;
    transition: all 0.3s ease-in-out;
  }
`;

const No = styled.span`
  width: 24px;
  height: 24px;
  background: #809a39;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
`;

const Value = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #809a39;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import media from "../../../../styles/utils/media";
import landImage from "../../../images/hero-frame-1.png";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import ProjectImages from "../components/modal/ProjectImages";
import api from "../../../api";
import { useNavigate, useParams } from "react-router";
import { packagesObj } from "../../../api/project/types";
import { toast } from "react-toastify";
import DualRing from "../components/loader";
import { AxiosError } from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import useAuth from "app/hooks/auth/useAuth";
import { UserRoutes } from "app/utils/data";
import PackageItems from "./components/PackageItems";
import useDownloadPackageBrochure from "app/hooks/projects/useDownloadPackageBrochure";
import Spinner from "../components/Spinner";
import { motion } from "framer-motion";
import {
  AuthConeAnim,
  cardVariant,
  delayStagger,
  fadeIn,
} from "app/utils/animationVariants";
import { getDashboardUrl } from "app/redux/dashboard/dashboardSlice";
import { useAppSelector } from "app/redux";

const PackageDetails = () => {
  const isLoggedIn = useAuth();
  const navigate = useNavigate();
  const dashboardUrl = useAppSelector(getDashboardUrl).dashboardUrl;
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [detailsData, setDetailsData] = useState<packagesObj>();
  const { isFetching: isExporting, refetch } = useDownloadPackageBrochure(
    id as string
  );
  const discount = Number(detailsData?.items[0]?.discount)?.toFixed(0) || 0;

  useEffect(() => {
    if (detailsData?.sold_out) navigate(-1);
  }, [navigate, detailsData?.sold_out]);

  const fetchPackageDetails = async (id: number) => {
    try {
      setLoading(true);
      const res = await api.projectService.getSinglePackage(id);
      setDetailsData(res.data.data);
      setLoading(false);
    } catch (error) {
      let errorMessage = error as any as AxiosError;
      toast.error(errorMessage.response?.data.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isNaN(Number(id))) {
      toast.error("Invalid category id");
    } else {
      fetchPackageDetails(Number(id));
    }
  }, [id]);

  const formatImages = () => {
    const temp = [];
    temp.push((detailsData as packagesObj).main_file);
    const result = detailsData?.photos
      ? [...temp, ...detailsData.photos]
      : temp;

    return result;
  };

  const formatModalImages = () => {
    const temp = formatImages().filter((ev) => ev.file_type === "image");
    return temp;
  };

  return (
    <>
      {loading ? (
        <LoaderWrapper>
          <DualRing width="35px" height="35px" color="#727272" />
        </LoaderWrapper>
      ) : (
        <>
          <BackWrapper
            onClick={() => navigate(-1)}
            variants={AuthConeAnim}
            animate={"animate"}
            initial={"initial"}
            custom={2}
          >
            <ArrowBackIosIcon /> <div>Back</div>
          </BackWrapper>
          <GridContainer
            variants={delayStagger(1.5)}
            custom={0.5}
            viewport={{ once: true, amount: 0.2 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            <ContentContainer>
              <motion.h2 variants={cardVariant}>{detailsData?.name}</motion.h2>
              <motion.p variants={cardVariant}>
                {detailsData?.description}
              </motion.p>
              <ItemBody variants={cardVariant}>
                <PackageBox>Location</PackageBox>{" "}
                <span className="location">
                  {" "}
                  {detailsData?.project}
                  {", "}
                  {detailsData?.state}
                </span>
              </ItemBody>
              {detailsData?.items.map((ev) => (
                <ItemBody variants={cardVariant}>
                  <PackageBox>Plots Available ({ev.size} sqm)</PackageBox>{" "}
                  <span className="plots">{ev.available_units} Plots</span>
                </ItemBody>
              ))}
            </ContentContainer>
            <>
              {detailsData?.benefits && (
                <BenefitContainer variants={cardVariant}>
                  <h2>Benefits</h2>
                  <BenefitContent
                    benefitNo={Math.ceil(
                      detailsData.benefits.split(",").length / 3
                    )}
                  >
                    {detailsData?.benefits.split(",").map((ev) => (
                      <BenefitItem>{ev}</BenefitItem>
                    ))}
                  </BenefitContent>
                </BenefitContainer>
              )}
            </>

            <ImageContainer initial={"initial"} animate={"animate"}>
              {detailsData?.main_file && (
                <motion.div variants={AuthConeAnim} custom={1}>
                  {detailsData.main_file.file_type === "image" && (
                    <img
                      src={detailsData?.main_file.url}
                      alt=""
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = landImage;
                      }}
                    />
                  )}
                  {detailsData.main_file.file_type === "video" && (
                    <video autoPlay muted loop controls>
                      <source
                        src={detailsData.main_file.url}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </motion.div>
              )}
              <div className="footer">
                <BtnContainer
                  onClick={() => refetch()}
                  disabled={!detailsData?.brochure}
                  variants={AuthConeAnim}
                  custom={2}
                >
                  {isExporting ? (
                    <Spinner color="white" />
                  ) : (
                    <DownloadOutlinedIcon />
                  )}
                  <span>Download Brochure</span>
                </BtnContainer>

                <motion.button
                  onClick={() => setOpen(true)}
                  variants={AuthConeAnim}
                  custom={2.4}
                >
                  See more images
                </motion.button>
              </div>
            </ImageContainer>
          </GridContainer>
          <Pricing
            variants={delayStagger(1.5)}
            custom={0.5}
            viewport={{ once: true, amount: 0.3 }}
            whileInView={"animate"}
            initial={"initial"}
          >
            {detailsData?.items &&
              detailsData?.items.length > 0 &&
              detailsData.items.map((item, i) => (
                <PackageItems item={item} index={i} />
              ))}
          </Pricing>

          <PricingFooter
            viewport={{ once: true, amount: 0.2 }}
            whileInView={"animate"}
            initial={"initial"}
            variants={fadeIn("up")}
          >
            <BtnContainer
              onClick={() => {
                if (isLoggedIn && dashboardUrl === UserRoutes.Root) {
                  return navigate(`${UserRoutes.Projects}/${detailsData?.id}`);
                } else {
                  return navigate("/login");
                }
              }}
            >
              Buy Now ({`${discount}%`} off)
            </BtnContainer>
          </PricingFooter>
          {detailsData?.main_file && open && (
            <ProjectImages
              open={open}
              handleClose={() => setOpen(false)}
              imageArray={formatModalImages()}
              packageName={detailsData.name}
            />
          )}
        </>
      )}
    </>
  );
};

export default PackageDetails;

const BackWrapper = styled(motion.div)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-right: auto;
  color: #afd34f;
  margin-bottom: 1rem;
  cursor: pointer;
  div {
    line-height: 140%;
  }
  svg {
    fill: #afd34f;
    height: 25px;
    width: 25px;
  }
  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
    svg {
      fill: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

const GridContainer = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;
  row-gap: 1.5rem;
  column-gap: 4rem;
  margin-top: 1rem;
  ${media.bigMobile`
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
    `}
`;

const ContentContainer = styled.div`
  h2 {
    font-weight: bold;
    font-size: 1.5625rem;
    line-height: 140%;
    color: #2a3313;
  }

  p {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    margin-top: 1.5rem;
    color: #464646;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  ${media.mediumDesktop`
  display:flex;
  flex-direction: column;
    justify-content: center;
    `}

  ${media.bigMobile`
  h2{
    font-size: 1.25rem;
  }
  p{
    font-size: 1rem;
  }
    `}
`;

const ItemBody = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  margin-top: 1.5rem;

  .location {
    color: #143340;
  }

  .plots {
    color: #3e4b1c;
  }

  ${media.smallDesktop`
    flex-wrap: wrap;
    `}
  ${media.bigMobile`
    font-size: 0.8125rem;
    `}
`;

const PackageBox = styled.div`
  background: #143340;
  border-radius: 4px;
  padding: 4px 8px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 0.8125rem;
`;

const BenefitContainer = styled(motion.div)`
  background-color: #d8e8b8;
  padding: 1.5rem;
  border-radius: 8px;
  height: max-content;

  h2 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    color: #303030;
    text-align: center;
  }

  ${media.mediumDesktop`
  grid-column:1/-1;
  max-width:563px;
  min-width:520px;
  justify-self: center;
    `}

  ${media.bigMobile`
    h2{
    font-size:1rem;
  }
  
  width:100%;
  min-width: unset;
  max-width: unset;
  padding: 1rem;

    `}
`;

interface BenefitContentProps {
  benefitNo: number;
}

const BenefitContent = styled.div<BenefitContentProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: ${({ benefitNo }) => `repeat(${benefitNo}, 5rem)`};
  justify-content: center;
  gap: 12px;
  margin-top: 12px;

  ${media.bigMobile`

    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    `}
`;

const BenefitItem = styled.div`
  height: 5rem;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28647d;
  border-radius: 4px;
  color: #fbfbfb;
  text-align: center;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 140%;
  word-break: normal;
  overflow-y: scroll;
  text-transform: capitalize;

  ${media.bigMobile`
       font-size:0.6875rem;
    `}
`;

const ImageContainer = styled(motion.div)`
  grid-column: 2/-1;
  grid-row: 1/-1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  & > div:nth-child(1) {
    max-height: 500px;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.75rem;
      margin-bottom: 0.75rem;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    & > button:nth-child(2) {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: #afd34f;
      border: none;
      background-color: white;
      cursor: pointer;

      &:hover {
        color: #697f2f;
        transition: all 0.2s ease-out;
      }
    }
  }

  ${media.mediumDesktop`
  grid-row: 1/2;
    `}
  ${media.smallDesktop`
  .footer{
    flex-wrap: wrap;
    justify-content: center;
  }
    `}

    ${media.bigMobile`
    grid-column:1/-1;
    grid-row: 2/3;

    img{
        height:331px;
    }
    .footer{
        flex-direction: column;
        gap: 32px;
    }
    `}
`;

interface BtnContainerProps {
  bg?: string;
}

const BtnContainer = styled(motion.button)<BtnContainerProps>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: ${({ bg }) => (bg ? bg : "#AFD34F")};
  border: none;
  color: white;
  padding: 17px 40px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6 !important;
  }

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const PricingFooter = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  gap: 0.75rem;
  ${media.mobile`
  flex-wrap: wrap;
  gap: 1rem;

  button {
    width: max-content;
    justify-content: center;
    margin:auto;
  }

    `}
`;

const Pricing = styled(motion.div)`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 40px;

  ${media.mobile`
  margin: unset;
  margin-top: 40px;

    `}
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

import { createAsyncThunk } from "@reduxjs/toolkit";
import storage from "app/utils/storage";
import api from "../../api";
import { LoginPayload } from "../../api/auth/types";
import { setUser } from "./authSlice";
import { TokenState } from "./types";

export const setToken = createAsyncThunk(
  "auth/setToken",
  async (payload: TokenState) => {
    storage.set("adbondAccessToken", payload.accessToken);
    return payload;
  }
);

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (payload: LoginPayload, thunkAPI) => {
    try {
      const res = await api.authService.login(payload);
      thunkAPI.dispatch(setUser(res.data.data.user));
      thunkAPI.dispatch(setToken({ accessToken: res.data.data.token }));
      return res.data.data;
    } catch (error) {
      let errorMessage = error as any;
      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

import { ApiResponse } from "app/redux/types";
import request from "../request";
import {
  dashboardProjSalesPayload,
  dashboardProjSalesRes,
  PieChartPayload,
  PieChartRes,
  SalesPayload,
  SalesRes,
} from "./types";

export const adminGetProjectSales = async (
  payload: dashboardProjSalesPayload
) => {
  return await request.post<ApiResponse<dashboardProjSalesRes[]>>(
    "/admin/sales/bar_chart",
    payload
  );
};

export const adminSalesPieChart = async (payload: PieChartPayload) => {
  return await request.post<ApiResponse<PieChartRes>>(
    "/admin/sales/pie_chart",
    payload
  );
};

export const adminSalesLands = async (payload: SalesPayload) => {
  return await request.post<ApiResponse<SalesRes>>(
    "/admin/sales/lands",
    payload
  );
};

export const adminSalesHomes = async (payload: SalesPayload) => {
  return await request.post<ApiResponse<SalesRes>>(
    "/admin/sales/homes",
    payload
  );
};

export const adminSalesAgro = async (payload: SalesPayload) => {
  return await request.post<ApiResponse<SalesRes>>(
    "/admin/sales/agro",
    payload
  );
};

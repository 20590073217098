import { useField } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as MailIcon } from "../../../../images/mail-icon.svg";
import { ReactComponent as EyeIcon } from "../../../../images/eye-icon.svg";
import { motion } from "framer-motion";
import { cardTextAnimate } from "app/utils/animationVariants";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  type: "email" | "password" | "text";
  value?: string;
  disabled?: boolean;
}

export const TextField: React.FC<Props> = ({ label, type, ...props }) => {
  const [isPassword, setIsPassword] = useState(true);
  const [field, meta] = useField(props);
  const errorClass = `${meta.touched && meta.error && "error"}`;

  return (
    <InputContainer variants={cardTextAnimate}>
      <label htmlFor={props.name} className="label">
        {label}
      </label>

      {type === "text" && (
        <TextWrapper
          type="text"
          id={props.name}
          className={errorClass}
          {...field}
          {...props}
          onChange={(ev) => {
            props.onChange(ev);
            field.onChange(ev);
          }}
        />
      )}
      {type === "email" && (
        <IconInput className={errorClass} htmlFor={props.name}>
          <input
            type="text"
            id={props.name}
            {...field}
            {...props}
            onChange={(ev) => {
              props.onChange(ev);
              field.onChange(ev);
            }}
          />
          <MailIcon />
        </IconInput>
      )}

      {type === "password" && (
        <IconInput className={errorClass}>
          <input
            type={isPassword ? "password" : "text"}
            id={props.name}
            {...field}
            {...props}
            onChange={(ev) => {
              props.onChange(ev);
              field.onChange(ev);
            }}
          />
          <EyeIcon
            onClick={() => setIsPassword((prev) => !prev)}
            className="clickable"
          />
        </IconInput>
      )}

      {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
    </InputContainer>
  );
};

const InputContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > label {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    margin-bottom: 0.5rem;
    color: #181d0b;
  }
  .label {
    width: max-content;
  }
`;

const TextWrapper = styled.input`
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  height: 3.5rem;
  padding: 18px 24px;
  width: 100%;

  :focus-visible {
    outline: none;
  }

  &.error {
    border: 1px solid red;
  }

  input:autofill {
    background-color: white;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

const IconInput = styled.label`
  height: 56px;
  padding: 18px 24px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  &.error {
    border: 1px solid red;
  }

  svg {
    width: 28px;
    height: 28px;
  }

  input {
    width: 100%;
    border: none;
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #f3f3f3 inset;
    box-shadow: 0 0 0px 1000px #f3f3f3 inset;
  }

  .clickable {
    cursor: pointer;
  }
`;

import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as SendIcon } from "../../../../images/admin/send-icon.svg";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import useCreateComment from "app/hooks/community/useCreateComment";
import Spinner from "app/views/Web/components/Spinner";
import useCreateReplyComment from "app/hooks/community/useCreateReplyComment";

interface Props {
  type?: "reply";
  postId?: number;
  commentId?: number;
  handleClose: () => void;
  open: boolean;
}

const CommentField: React.FC<Props> = ({
  type,
  postId,
  commentId,
  handleClose,
  open,
}) => {
  const [comment, setComment] = useState("");

  const { mutate: createMutate, isLoading: isCreating } = useCreateComment();
  const { mutate: replyMutate, isLoading: isReplying } =
    useCreateReplyComment();

  const onCreateSucess = () => {
    setComment("");
    handleClose();
  };

  const submitHandler = () => {
    if (!comment) return;
    if (type === "reply") {
      const payload = {
        comment_id: commentId as number,
        body: comment,
      };
      replyMutate(payload, {
        onSuccess: onCreateSucess,
      });
    } else {
      const payload = {
        post_id: postId as number,
        body: comment,
      };
      createMutate(payload, {
        onSuccess: onCreateSucess,
      });
    }
  };
  return (
    <Container isOpen={open}>
      <Cancel onClick={handleClose}>
        <HighlightOffIcon />
      </Cancel>
      <textarea
        rows={5}
        placeholder={type === "reply" ? "Type a reply..." : "Type a comment..."}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      ></textarea>
      <Footer>
        {isCreating || isReplying ? (
          <Spinner color="#afd34f" />
        ) : (
          <SendIcon onClick={submitHandler} />
        )}
      </Footer>
    </Container>
  );
};

export default CommentField;

interface ContainerProps {
  isOpen: boolean;
}

const Container = styled.div<ContainerProps>`
  background-color: #fbfbfb;
  padding: 14px 10px;
  display: ${({ isOpen }) => !isOpen && "none"};
  position: relative;

  textarea {
    width: 100%;
    resize: none;
    border: none;
    background-color: #fbfbfb;
    &:focus-visible {
      outline: none;
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
    fill: #afd34f;
    cursor: pointer;
  }
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
    fill: #b3261e;
  }
`;

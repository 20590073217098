export enum UtilitiesKeys {
  getAllStates = "get-all-states",
  getRoles = "get-roles",
  getAllCountries = "get-all-countries",
  getBankAccounts = "get-bank-accounts",
  getTotalActiveProject = "get-total-active-project",
  getTotalClients = "get-total-clients",
  getAdminNotifications = "get-admin-notifications",
  getCompanyInfo = "get-company-info",
  getAgeGroups = "get-age-groups",
  getAdminBankList = "get-admin-bank-list",
}

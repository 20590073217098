import { useQuery } from "@tanstack/react-query";
import { getASingleClientPackage } from "app/api/client";
import { parseError } from "app/utils";
import { ClientQueryKeys } from "./types";

const useGetASingleClientPackage = (id: number, isEnabled?: boolean) => {
  return useQuery(
    [ClientQueryKeys.getASingleClientPackages, id],
    () => getASingleClientPackage(id),
    {
      enabled: !!id && isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export default useGetASingleClientPackage;

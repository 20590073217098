import { useMobileCheck } from "app/hooks/useMobileCheck";
import { popUp, slideIn, staggerContainer } from "app/utils/animationVariants";
import { motion } from "framer-motion";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";
import { mediaObj } from "../../../../styles/utils/media";

const OurStory = () => {
  const isMobile = useMobileCheck();

  return (
    <Container
      variants={staggerContainer}
      initial="initial"
      whileInView="animate"
      viewport={{ amount: isMobile ? 0.2 : 0.6, once: true }}
    >
      <div className="left">
        <motion.h2 variants={slideIn("left")}>Our Processes</motion.h2>
        <motion.p variants={slideIn("right")}>
          <h5>Land purchase</h5>
          We encourage our target market to make land investments. Our land
          subscription packages at Adbond have been expanded to allow
          individuals and corporations to make outright or instalment purchases.
          <h5>Agricultural engagement</h5>
          Once lands are bought from our sales team, we offer agricultural
          engagement plans for clients to select preferred options and farming
          systems. We then hire farming experts based on clients’ approval and
          start cultivating in-bond lands. Annually, our clients earn income
          from farms engaged in agriculture.
          <h5>Home development</h5>
          While clients gain from farm proceedings over a set length of time, we
          are there for them every step of the way, advising them on structures
          that can be constructed on cultivated land once it has generated
          returns.
        </motion.p>
      </div>

      <motion.div className="right" variants={popUp}>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/NNMXcdhhHzg"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </motion.div>
    </Container>
  );
};

export default OurStory;

const Container = styled(motion.div)`
  background: white;
  padding: 70px 0px 60px;
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 120px;
  box-shadow: 0 0 0 100vmax #fff;
  clip-path: inset(0 -100vmax);

  .left {
    width: 60%;

    h2 {
      font-weight: 500;
      font-size: 1.9375rem;
      line-height: 140%;
      color: #2a3313;
      margin-bottom: 24px;

      ${mediaQueries.mobile} {
        font-size: 1.5rem;
        margin-bottom: 14px;
      }
    }

    h5 {
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 180%;
      color: #2a3313;
      margin-top: 1rem;

      ${mediaQueries.mobile} {
        font-size: 1.125rem;
      }
    }

    p {
      font-weight: 450;
      font-size: 1.2rem;
      line-height: 140%;
      color: #5e5e5e;

      ${mediaQueries.mobile} {
        font-size: 1rem;
      }
    }
  }

  .right {
    width: 573.2px;
    height: 307.29px;

    iframe {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 8px;
    }

    ${mediaObj.smallDesktop} {
      height: 420px;
    }
  }

  ${mediaObj.smallDesktop} {
    flex-wrap: wrap;

    .left {
      width: 90%;
    }
  }

  ${mediaObj.bigMobile} {
    padding: 36px 16px;
    gap: 50px;
    padding: 40px 0 30px;

    .left {
      width: 100%;

      h5 {
        font-size: 1.2rem;
      }
    }
  }
`;

import { ClientsPerPackageRes } from "app/api/project/types";
import styled from "styled-components";

const InstallmentRenderer = ({ data }: { data: ClientsPerPackageRes }) => {
  return (
    <Wrapper isInstallment={data.installment}>
      {data.installment ? "Installment" : "Full Payment"}
    </Wrapper>
  );
};

export default InstallmentRenderer;

interface WrapperProps {
  isInstallment: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  color: ${({ isInstallment }) => (isInstallment ? "#28647D" : "#6750A4")};
`;

import Container from "@mui/material/Container";
import { Outlet } from "react-router";
import Header from "../components/header";
import Footer from "../components/footer";
import styled from "styled-components";
import SimpleAccordion from "./ContactBox";
import { useSearchParams } from "react-router-dom";
import storage from "app/utils/storage";
import AcceptCookies from "../LandingPage/components/AcceptCookies";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import useScrollHandler from "app/hooks/useScrollHandler";
import { AnimatePresence, motion } from "framer-motion";
import { slideIn } from "app/utils/animationVariants";
import { mediaObj } from "styles/utils/media";

const Layout = () => {
  const [searchParams] = useSearchParams();
  const ref = searchParams.get("ref");
  ref && storage.set("userRef", ref);
  const { scrolledDown } = useScrollHandler();

  return (
    <ContentWrapper>
      <StyledContainer maxWidth="xl">
        <Header />
        <OutletWrapper>
          <Outlet />
        </OutletWrapper>
        <SimpleAccordion />
      </StyledContainer>
      <Footer />
      <AnimatePresence exitBeforeEnter>
        <ChatWrapper
          href="https://chat.whatsapp.com/GvCMEGUmvWk649A7GOK4P6"
          target={"_blank"}
          variants={slideIn()}
          custom={0.85}
          animate={scrolledDown ? "animate" : "initial"}
          whileHover={{ opacity: 1, transition: { duration: 1 } }}
        >
          <IconBox>
            <WhatsAppIcon />
          </IconBox>
          <ChatText>Chat with us</ChatText>
        </ChatWrapper>
      </AnimatePresence>
      <AcceptCookies />
    </ContentWrapper>
  );
};

export default Layout;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const StyledContainer = styled(Container)`
  flex-grow: 1;
`;

const OutletWrapper = styled.div`
  margin-top: 100px;
`;

const ChatWrapper = styled(motion.a)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  gap: 8px;

  ${mediaObj.bigMobile} {
    left: 10px;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #25d366;
  width: 50px;
  height: 50px;

  & > svg {
    color: #fff;
    width: 28px;
    height: 28px;
  }
`;

const ChatText = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-shadow: 2px 2px 2px #fff;

  ${mediaObj.bigMobile} {
    display: none;
  }
`;

import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import ActionRenderer from "./actionRenderer";
import { UserResponse } from "app/types/common";

interface GridProps {
  data: UserResponse[];
}

type UserField = keyof UserResponse | "action";

export interface GridDef extends ColDef {
  field: UserField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: UserResponse[];
}

const UserGrid: React.FC<GridProps> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: UserResponse[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Role",
      sortable: false,
      field: "role",
      flex: 1.2,
      cellStyle: {
        textTransform: "capitalize",
        ...gridCellStyle,
      },
      valueFormatter: (params) => {
        const { data }: { data: UserResponse } = params;
        return data.role?.name || "--";
      },
    },
    {
      headerName: "Email Address",
      field: "email",
      sortable: false,
      flex: 1.5,
      cellStyle: {
        ...gridCellStyle,
        color: "#28647D",
      },
      wrapText: true,
    },
    {
      sortable: false,
      field: "action",
      flex: 0.5,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  return (
    <GridWrapper id="OpenOrdersGridWrapper" className={`ag-theme-balham`}>
      <AgGridReact
        onGridReady={gridRendered}
        animateRows
        headerHeight={50}
        rowHeight={50}
        columnDefs={gridConfig.columnDefs}
        rowData={gridConfig.rowData}
        suppressRowTransform
        defaultColDef={{
          sortable: true,
          filter: false,
          cellStyle: {
            ...gridCellStyle,
          },
        }}
        immutableData
        getRowNodeId={(data: UserResponse) => {
          return data.id.toString();
        }}
        gridOptions={{
          onSortChanged(e: AgGridEvent) {
            e.api.refreshCells();
          },
          onFilterChanged(e: AgGridEvent) {
            e.api.refreshCells();
          },
        }}
      />
    </GridWrapper>
  );
};

export default UserGrid;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "font-size": "1rem",
  "line-height": "140%",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 500px;
  width: 100%;
  margin: 40px 0 10px;
  overflow-y: scroll;
  overflow-x: scroll;

  & .ag-header-cell-text {
    font-size: 17px !important;
  }

  & .ag-header-cell {
    padding-left: 8px !important;
  }

  & *:not(img) {
    overflow: visible !important;
  }
`;

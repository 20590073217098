import React, { useState } from "react";
import styled from "styled-components";
import AddIcon from "@mui/icons-material/Add";
import { Form, Formik } from "formik";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import { clientObj } from "app/api/client/types";
import { NewClientValidation } from "../validation";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  setNextStep: () => void;
  onCreate: (data: newClientProps[]) => void;
  selectedClient: clientObj[];
  isCreating: boolean;
  editId: string | null;
}

const newClient = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export type newClientProps = typeof newClient;

const AddClient: React.FC<Props> = ({
  setNextStep,
  selectedClient,
  onCreate,
  isCreating,
  editId,
}) => {
  const [newClientArray, setNewClientArray] = useState<newClientProps[]>([]);

  const addMoreArray = () => {
    setNewClientArray((oldArray) => [
      ...oldArray,
      {
        ...newClient,
      },
    ]);
  };

  const cancelArray = (index: number) => {
    setNewClientArray([
      ...newClientArray.slice(0, index),
      ...newClientArray.slice(index + 1),
    ]);
  };

  const getSelectedDetails = (data: newClientProps[]): any => {
    if (!data || data.length === 0) return [];
    const temp = data.map((ev) => ({
      firstname: ev.firstName,
      lastname: ev.lastName,
      email: ev.email,
      phone_number: ev.phone,
    }));
    return temp;
  };
  return (
    <>
      <DatabaseWrapper>
        <AddClientBtn onClick={setNextStep}>
          <AddIcon />
          import from database
        </AddClientBtn>
        {selectedClient.length > 0 && (
          <SelectedWrapper>
            {selectedClient.map((client, i) => (
              <SelectedBox key={i}>{client.fullname}</SelectedBox>
            ))}
          </SelectedWrapper>
        )}
      </DatabaseWrapper>
      <HR />
      <Formik
        initialValues={{
          newClientArray,
          selectedClient,
        }}
        validationSchema={NewClientValidation}
        enableReinitialize
        onSubmit={() => {
          const temp = getSelectedDetails(newClientArray);
          onCreate(temp);
        }}
      >
        <Form>
          <Wrapper>
            {newClientArray.map((item, i) => (
              <div key={i}>
                <Cancel onClick={() => cancelArray(i)}>
                  <HighlightOffIcon />
                </Cancel>
                <GridWrapper>
                  <InputField
                    name={`newClientArray[${i}].firstName`}
                    label="First Name"
                    placeholder="First Name"
                    value={item.firstName}
                    onChange={(e) => {
                      item.firstName = e.target.value;
                      setNewClientArray([...newClientArray]);
                    }}
                  />
                  <InputField
                    name={`newClientArray[${i}].lastName`}
                    label="Last Name"
                    placeholder="Last Name"
                    value={item.lastName}
                    onChange={(e) => {
                      item.lastName = e.target.value;
                      setNewClientArray([...newClientArray]);
                    }}
                  />
                  <InputField
                    name={`newClientArray[${i}].email`}
                    label="Email Address"
                    placeholder="Email Address"
                    value={item.email}
                    onChange={(e) => {
                      item.email = e.target.value;
                      setNewClientArray([...newClientArray]);
                    }}
                  />
                  <InputField
                    name={`newClientArray[${i}].phone`}
                    label="Phone number"
                    placeholder="Phone number"
                    value={item.phone}
                    onChange={(e) => {
                      item.phone = e.target.value;
                      setNewClientArray([...newClientArray]);
                    }}
                  />
                </GridWrapper>
              </div>
            ))}

            <AddMore onClick={addMoreArray} type="button">
              Add a new contact
            </AddMore>
          </Wrapper>
          <SubmitBtn type="submit">
            <>
              {isCreating && <Spinner color="black" />}
              {editId ? "Update" : "Create"}
            </>
          </SubmitBtn>
        </Form>
      </Formik>
    </>
  );
};

export default AddClient;

const DatabaseWrapper = styled.div`
  background: #cde6f5;
  border-radius: 8px;
  padding: 39px 131px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`;

const AddClientBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 44px;
  gap: 10px;
  background: #afd34f;
  border-radius: 4px;
  font-weight: 450;
  font-size: 0.8125rem;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #697f2f;
  }
`;

const HR = styled.div`
  height: 1px;
  background-color: #777777;
  margin-top: 16px;
  margin-bottom: 19px;
`;

const AddMore = styled.button`
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #afd34f;
  width: max-content;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const SelectedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 7px;
  row-gap: 12px;
  align-items: center;
`;

const SelectedBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  color: #809a39;
  padding: 4px 8px;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 50vh;
  overflow-y: scroll;
  padding: 15px;

  & > div {
    position: relative;
    background: #cde6f5;
    padding: 15px;
    border-radius: 8px;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -7px;
  top: -7px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const SubmitBtn = styled.button`
  width: 90%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #afd34f;
  border-radius: 8px;
  padding: 12px 50px;
  border: none;
  margin-top: 34px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  justify-content: center;
  gap: 10px;
  align-items: center;

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

import React, { RefObject, useEffect } from "react";
import styled from "styled-components";
import media from "../../../../styles/utils/media";
import SearchResultsCard from "../components/cards/SearchResultsCard";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useAppDispatch, useAppSelector } from "app/redux";
import { homeSelector, resetHome } from "app/redux/home/HomeSlice";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { staggerContainer } from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import { getTotalPromo } from "app/utils";

interface Props {
  searchElementRef: RefObject<HTMLDivElement>;
  goToSearchElement: () => void;
}

const Search: React.FC<Props> = ({ searchElementRef, goToSearchElement }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useMobileCheck();
  const { searchData } = useAppSelector(homeSelector);

  useEffect(() => {
    goToSearchElement();
  }, [goToSearchElement]);

  const onClear = () => {
    dispatch(resetHome());
    navigate("/");
  };

  return (
    <Container ref={searchElementRef}>
      <Header>
        <span>Search result(s)</span>
        <span className="clear" onClick={onClear}>
          <HighlightOffIcon />
          Clear result
        </span>
      </Header>
      <motion.div
        className="result"
        variants={staggerContainer}
        custom={0.5}
        viewport={{ once: true, amount: isMobile ? 0.1 : 0.4 }}
        whileInView={"animate"}
        initial={"initial"}
      >
        {(!searchData || searchData.length === 0) && (
          <NoSearchText>Search not found.</NoSearchText>
        )}
        {searchData &&
          searchData.map((ev, i) => (
            <SearchResultsCard
              key={i}
              image={ev.photo}
              propertyType={ev.category}
              location={ev.location + ", " + ev.address}
              packageType={ev.package}
              amount={+ev.price}
              isInstallmentAvailable={ev.installment_available}
              packageId={ev.package_id}
              categoryId={ev.category_id}
              mainFile={ev.main_file}
              isSoldOut={ev.sold_out}
              isOnPromo={ev.onPromo}
              promoDiscount={getTotalPromo(ev.promos)}
            />
          ))}
      </motion.div>
    </Container>
  );
};

export default Search;

const Container = styled.div`
  padding: 0 60px;
  padding-top: 50px;

  .result {
    margin-top: 12px;
    display: grid;
    column-gap: 2rem;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    ${media.bigMobile`
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    `}
  }

  ${media.bigMobile`
    padding: 40px 0;
    `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .clear {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  & > span:nth-child(1) {
    font-weight: 500;
    font-size: 1.5625rem;
    ${media.bigMobile`
    font-size: 1rem;
    `}
  }

  & > span:nth-child(2) {
    font-weight: 500;
    font-size: 1rem;
    color: #b3261e;
    ${media.bigMobile`
    font-size: 0.8125rem;
    `}
  }
`;

const NoSearchText = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1/-1;
  font-size: 24px;
  font-weight: 600;
  color: #5e5e5e;
`;

import * as Yup from "yup";

export const clientsToPackageValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  packages: Yup.object().required("This field is required"),
  squaremeter: Yup.object().required("This field is required"),
  payment: Yup.object().required("This field is required"),
});

export const inStallmentClientsToPackageValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  packages: Yup.object().required("This field is required"),
  squaremeter: Yup.object().required("This field is required"),
  payment: Yup.object().required("This field is required"),
  amountPaid: Yup.number().required("This field is required"),
});

import styled, { css } from "styled-components";
import { Box } from "@mui/material";
import { StaffNavData } from "./data";
import StaffList from "./sections/staffList";
import Assessment from "./sections/assessment";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { StaffTabEnum } from "app/types/common";
import ETeamResult from "./sections/eTeam";

const Staff = () => {
  const [searchParams, setSearchParams] = useSearchParams({});

  useEffect(() => {
    !searchParam && setSearchParams({ activeTab: StaffTabEnum.StaffList });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (id: StaffTabEnum) => {
    setSearchParams({ activeTab: id.toString() });
  };

  const searchParam = searchParams.get("activeTab");

  return (
    <Container>
      <Header>Staff</Header>
      <Box display={"flex"} alignItems={"center"} gap={"24px"} mt={"20px"}>
        {StaffNavData.map((x) => (
          <Tab
            key={x.id}
            onClick={() => {
              handleTabChange(x.id);
            }}
            isActive={x.id === searchParam}
          >
            {x.name}
          </Tab>
        ))}
      </Box>
      {searchParam === StaffTabEnum.StaffList && <StaffList />}
      {searchParam === StaffTabEnum.Assessment && <Assessment />}
      {searchParam === StaffTabEnum.ETeamResult && <ETeamResult />}
    </Container>
  );
};

export default Staff;

const Container = styled.div`
  background: #ffffff;
  padding: 24px;
  height: 80vh;
  width: 95%;
`;

const Header = styled.h5`
  font-weight: 450;
  font-size: 25px;
  line-height: 140%;
  color: #181d0b;
`;

interface tabProps {
  isActive?: boolean;
}

const Tab = styled.span<tabProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.2s ease-in;

  ${({ isActive }) =>
    isActive &&
    css`
      border-bottom: 3px solid #afd34f;
    `}
`;

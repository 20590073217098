import { useMutation } from "@tanstack/react-query";
import { clientUpdateNok } from "app/api/users";
import { parseError } from "app/utils";

const useClientUpdateNok = () => {
  //   const queryClient = useQueryClient();

  return useMutation(clientUpdateNok, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      //   queryClient.invalidateQueries([UserKeys.adminGetAllUsers]);
    },
  });
};

export default useClientUpdateNok;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  adminApproveOffer,
  adminGetAllOffer,
  adminRejectOffer,
  createOffer,
  deleteOffer,
  getAllClientOffer,
  getASingleOffer,
  getClientAcceptedOffer,
  getClientPendingOffer,
  getClientRejectedOffer,
  updateOffer,
} from "app/api/offers";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "../community/types";
import { OffersQueryKeys } from "./types";

export const useGetAllClientOffer = () => {
  return useQuery([OffersQueryKeys.getAllClientOffers], getAllClientOffer, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export const useGetClientAcceptedOffer = () => {
  return useQuery(
    [OffersQueryKeys.getClientAcceptedOffers],
    getClientAcceptedOffer,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export const useGetClientPendingOffer = () => {
  return useQuery(
    [OffersQueryKeys.getClientPendingOffers],
    getClientPendingOffer,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export const useGetClientRejectedOffer = () => {
  return useQuery(
    [OffersQueryKeys.getClientRejectedOffers],
    getClientRejectedOffer,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export const useGetASingleOffer = (offer_id: number, isEnabled?: boolean) => {
  return useQuery(
    [OffersQueryKeys.getASingleOffer, offer_id],
    () => getASingleOffer(offer_id),
    {
      enabled: !!offer_id && isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );
};

export const useCreateOffer = () => {
  return useMutation(createOffer, {
    onError: (error) => parseError(error),
  });
};

export const useUpdateOffer = () => {
  return useMutation(updateOffer, {
    onError: (error) => parseError(error),
  });
};

export const useDeleteOffer = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteOffer, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([OffersQueryKeys.getClientAcceptedOffers]);
      queryClient.invalidateQueries([OffersQueryKeys.getClientPendingOffers]);
      queryClient.invalidateQueries([OffersQueryKeys.getClientRejectedOffers]);
    },
  });
};

export const useAdminGetAllOffer = () => {
  return useQuery([OffersQueryKeys.adminGetAllOffer], adminGetAllOffer, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export const useAdminApproveOffer = () => {
  const queryClient = useQueryClient();

  return useMutation(adminApproveOffer, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetOffers]);
    },
  });
};

export const useAdminRejectOffer = () => {
  const queryClient = useQueryClient();

  return useMutation(adminRejectOffer, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetOffers]);
    },
  });
};

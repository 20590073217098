import React from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import { useDeleteOffer } from "app/hooks/offers/useOffer";
import { toast } from "react-toastify";
import Spinner from "app/views/Web/components/Spinner";
import { useNavigate } from "react-router";
import { UserRoutes } from "app/utils/data";

interface Props {
  open: boolean;
  handleClose: () => void;
  offerId: number;
}

const NotifyDialog: React.FC<Props> = ({ open, handleClose, offerId }) => {
  const navigate = useNavigate();
  const { mutate: deleteOfferMutate, isLoading: isOfferDeleting } =
    useDeleteOffer();

  const onNavigateProperties = () => navigate(`${UserRoutes.Properties}`);

  const onOfferDelete = () => {
    const payload = {
      offer_id: offerId,
    };
    deleteOfferMutate(payload, {
      onSuccess: () => {
        toast.success("Offer deleted sucessfully!");
        onNavigateProperties();
      },
    });
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content>
          Are you sure you want to close this{" "}
          <DarkGreenText>Adbond</DarkGreenText> package.
        </Content>

        <BtnWrapper isDisabled={false}>
          <ConfirmBtn onClick={onOfferDelete}>
            {isOfferDeleting && <Spinner color="#fbfbfb" />}
            Yes, Close
          </ConfirmBtn>
          <CancelBtn onClick={handleClose} type="button">
            No, Cancel
          </CancelBtn>
        </BtnWrapper>
      </Container>
    </Backdrop>
  );
};

export default NotifyDialog;

const Container = styled.div`
  width: 550px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaObj.tablet} {
    width: 70vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 1rem;
`;

const DarkGreenText = styled.span`
  color: #afd34f;
`;

interface BtnWrapperProps {
  isDisabled: boolean;
}

const BtnWrapper = styled.div<BtnWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 24px;

  & > button {
    cursor: ${({ isDisabled }) => isDisabled && "not-allowed"};
  }
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  text-transform: capitalize;
`;

const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #afd34f;
  border: none;
`;

const CancelBtn = styled(Btn)`
  color: #2a3313;
  border: 1px solid #2a3313;
  background-color: transparent;
`;

import React from "react";
import useGetCustomTours from "app/hooks/site tour/useGetCustomTours";
import { NoProjectText, SpinnerWrapper } from "./styles";
import Spinner from "app/views/Web/components/Spinner";
import TourCard from "app/views/Admin/components/Cards/tour";

interface Props {
  handleTourSelect: (data: any, isSelected: boolean) => void;
  selectedTourId?: number;
  selectedMonth: number;
  onCloseDetails: () => void;
}

const DisplayCustomTours: React.FC<Props> = ({
  selectedTourId,
  handleTourSelect,
  selectedMonth,
  onCloseDetails,
}) => {
  const currentYear = new Date().getFullYear();

  const {
    data: customData,
    isLoading: customLoading,
    isError: customIsError,
  } = useGetCustomTours(selectedMonth, currentYear);

  if (customLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!customLoading && customIsError)
    return (
      <SpinnerWrapper>
        <NoProjectText>An error occurred. Please try again later</NoProjectText>
      </SpinnerWrapper>
    );

  if (customData && customData.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>No custom tour available yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {customData &&
        customData.map((x) => (
          <TourCard
            key={x.id}
            data={x}
            handleTourSelect={handleTourSelect}
            isSelected={selectedTourId === x.id}
            isCustom
            onCloseDetails={onCloseDetails}
          />
        ))}
    </>
  );
};

export default DisplayCustomTours;

import { StaffTabEnum } from "app/types/common";

export const StaffNavData = [
  {
    name: "Staff List",
    id: StaffTabEnum.StaffList,
  },
  {
    name: "Virtual staff grade",
    id: StaffTabEnum.ETeamResult,
  },
  {
    name: "Assessment Overview",
    id: StaffTabEnum.Assessment,
  },
];

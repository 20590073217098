import { useMutation } from "@tanstack/react-query";
import { adminVerifyResetHash, clientVerifyResetHash } from "app/api/auth";
import { parseError } from "app/utils";

const useAdminVerifyResetHash = () => {
  return useMutation(adminVerifyResetHash, {
    onError: (error) => parseError(error),
  });
};

export default useAdminVerifyResetHash;

export const useClientVerifyResetHash = () => {
  return useMutation(clientVerifyResetHash, {
    onError: (error) => parseError(error),
  });
};

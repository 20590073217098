import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";

interface DashboardState {
  dashboardUrl?: string;
}

const initialState: DashboardState = {
  dashboardUrl: undefined,
};

const dashboardSlice = createSlice({
  name: "dashboardUrl",
  initialState,
  reducers: {
    resetDashboardUrl: () => initialState,
    setDashboardUrl: (state, action: PayloadAction<string>) => {
      state.dashboardUrl = action.payload;
    },
  },
});

export const { resetDashboardUrl, setDashboardUrl } = dashboardSlice.actions;

export const getDashboardUrl = (state: RootState): DashboardState =>
  state.dashboard;

export default dashboardSlice.reducer;

import { useEffect, useState } from "react";
import { useMobileCheck } from "./useMobileCheck";

const useScrollHandler = () => {
  const [scrolledDown, setIsScrolledDown] = useState(false);
  const [scrollingUp, setScrollingUp] = useState(false);
  const isMobile = useMobileCheck();
  let prevScroll: number;

  const handleScroll = () => {
    const currScroll = window.scrollY;
    var scrollCheck = isMobile ? currScroll > 15 : currScroll > 50;
    setIsScrolledDown(scrollCheck);
    const isScrolled = prevScroll > currScroll && scrollCheck;
    setScrollingUp(isScrolled);
    prevScroll = currScroll;
  };

  function on(obj: any, ...args: any) {
    obj.addEventListener(...args);
  }

  function off(obj: any, ...args: any) {
    obj.removeEventListener(...args);
  }

  useEffect(() => {
    on(window, "scroll", handleScroll, { passive: true });
    return () => {
      off(window, "scroll", handleScroll, { passive: true });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { scrolledDown, scrollingUp };
};

export default useScrollHandler;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createProject } from "app/api/project";
import { parseError } from "app/utils";
import { ProjectsKey } from "./types";

const useCreateProject = () => {
  const queryClient = useQueryClient();

  return useMutation(createProject, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([ProjectsKey.allProjectPerCatgory]);
    },
  });
};

export default useCreateProject;

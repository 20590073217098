import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../../images/admin/search-icon.svg";
// import { ReactComponent as NotificationIcon } from "../../../../images/admin/bell.svg";
import { ReactComponent as SupportIcon } from "../../../../images/admin/support.svg";
import useScrollHandler from "../../../../hooks/useScrollHandler";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { getAuth, setUser } from "../../../../redux/auth/authSlice";
import { parseError } from "../../../../utils";
import { useCallback, useEffect } from "react";
import { adminGetProfile } from "app/api/users";
import NameAvatar from "../nameAvatar";
import { Link } from "react-router-dom";
import Notification from "../Notification";
import { UserResponse, whatsAppLink } from "app/types/common";

const Header = () => {
  const dispatch = useAppDispatch();
  const { scrolledDown } = useScrollHandler();
  const { user } = useAppSelector(getAuth) as {
    user: UserResponse | undefined;
  };

  const formatName = () => {
    if (!user) return;
    return user?.role?.name;
  };

  const fetchProfile = useCallback(async () => {
    try {
      const res = await adminGetProfile();
      dispatch(setUser(res.data.data));
    } catch (error) {
      parseError(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user) fetchProfile();
  }, [user, fetchProfile]);

  return (
    <Container isInFocus={scrolledDown}>
      <ContentWrapper>
        <SearchBar isInFocus={scrolledDown}>
          <Input
            type="text"
            placeholder="Search for projects, clients, locations…………"
            isInFocus={scrolledDown}
          />
          <SearchIcon />
        </SearchBar>
        <RightContentWrapper>
          <IconsWrapper>
            <Notification />
            <IconsBg href={whatsAppLink} target={"_blank"}>
              <SupportIcon />
            </IconsBg>
          </IconsWrapper>
          <ProfileWrapper to={"settings/profile"}>
            {user?.profile_photo ? (
              <ProfileImg src={user.profile_photo.url} />
            ) : (
              <NameAvatar
                name={user?.role?.name || ""}
                width="40px"
                height="40px"
              />
            )}
            <ProfileName>{formatName()}</ProfileName>
          </ProfileWrapper>
        </RightContentWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default Header;

interface headerProps {
  isInFocus?: boolean;
}

const Container = styled.div<headerProps>`
  position: fixed;
  top: 0;
  left: 211px;
  display: flex;
  width: calc(100vw - 211px);
  height: 70px;
  padding: 15px 90px;
  background: ${({ isInFocus }) => (isInFocus ? "#ffffff" : "transparent")};
  z-index: 999;
  transition: all 0.2s ease-in;
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SearchBar = styled.div<headerProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: 40%;
  max-width: 475px;
  height: 50px;
  background: ${({ isInFocus }) => (isInFocus ? "#f5f5f5" : "#ffffff")};
  border-radius: 20px;
  transition: all 0.2s ease-in;

  & > svg {
    position: absolute;
    top: 13px;
    right: 25px;
    width: 22.17px;
    height: 22.17px;
    cursor: pointer;
  }
`;

const Input = styled.input<headerProps>`
  width: 100%;
  height: 80%;
  padding: 3px 60px 0 20px;
  font-size: 16px;
  background: ${({ isInFocus }) => (isInFocus ? "#f5f5f5" : "#ffffff")};
  outline: none;
  border: none;
  border-radius: 20px;
  transition: all 0.2s ease-in;
`;

const RightContentWrapper = styled.div`
  display: flex;
  gap: 65px;
  align-items: center;
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
`;

export const IconsBg = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: #eef5e0;
  border-radius: 50%;
  cursor: pointer;
`;

const ProfileWrapper = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  cursor: pointer;
`;

const ProfileImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;

const ProfileName = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  text-transform: capitalize;
  color: #143340;
`;

import styled from "styled-components";
import IncomeCard from "../../components/Cards/incomeCard";
import { ReactComponent as DarkBlueBar } from "../../../../images/admin/dark-blue-bar.svg";
import { ReactComponent as LightBlueBar } from "../../../../images/admin/light-blue-bar.svg";
import { ReactComponent as GreenBar } from "../../../../images/admin/green-bar.svg";
import { useEffect, useState } from "react";
import useAdminSalesLands, {
  useAdminSalesAgro,
  useAdminSalesHomes,
} from "app/hooks/sales/useSales";
import { salesFullMonthOptions, yearDropDownOptions } from "../data";
import { SalesRes } from "app/api/sales/types";
import { abbreviateNumber } from "app/utils";
import { mediaObj } from "styles/utils/media";

const IncomeSection = () => {
  const [landYear, setLandYear] = useState<number>(
    yearDropDownOptions[0].value
  );

  const [homeYear, setHomeYear] = useState<number>(
    yearDropDownOptions[0].value
  );

  const [agroYear, setAgroYear] = useState<number>(
    yearDropDownOptions[0].value
  );

  const [landData, setLandData] = useState<SalesRes>();
  const [homeData, setHomeData] = useState<SalesRes>();
  const [agroData, setAgroData] = useState<SalesRes>();

  const { mutate: landMutate, isIdle: landLoading } = useAdminSalesLands();
  const { mutate: homeMutate, isIdle: homeLoading } = useAdminSalesHomes();
  const { mutate: agroMutate, isIdle: agroLoading } = useAdminSalesAgro();

  const handleLandYear = (value: number) => {
    setLandYear(value);
  };

  const handleHomeYear = (value: number) => {
    setHomeYear(value);
  };

  const handleAgroYear = (value: number) => {
    setAgroYear(value);
  };

  useEffect(() => {
    const landMonth = salesFullMonthOptions[0].value;
    if (landYear && landMonth) {
      const [start, end] = landMonth.split(" ");
      const payload = {
        end: Number(end),
        start: Number(start),
        year: Number(landYear),
      };
      landMutate(payload, {
        onSuccess: (data) => setLandData(data.data.data),
      });
    }
  }, [landYear, landMutate]);

  useEffect(() => {
    const homeMonth = salesFullMonthOptions[0].value;
    if (homeYear && homeMonth) {
      const [start, end] = homeMonth.split(" ");
      const payload = {
        end: Number(end),
        start: Number(start),
        year: Number(homeYear),
      };
      homeMutate(payload, {
        onSuccess: (data) => setHomeData(data.data.data),
      });
    }
  }, [homeYear, homeMutate]);

  useEffect(() => {
    const agroMonth = salesFullMonthOptions[0].value;
    if (agroYear && agroMonth) {
      const [start, end] = agroMonth.split(" ");
      const payload = {
        end: Number(end),
        start: Number(start),
        year: Number(agroYear),
      };
      agroMutate(payload, {
        onSuccess: (data) => setAgroData(data.data.data),
      });
    }
  }, [agroYear, agroMutate]);

  return (
    <Container>
      <IncomeCard
        label={"Land - Total sales income"}
        value={
          landData?.amount ? abbreviateNumber(landData.amount * 1000000) : "0"
        }
        count={landData?.total || 0}
        icon={DarkBlueBar}
        handleYearChange={handleLandYear}
        monthOptions={salesFullMonthOptions}
        yearOptions={yearDropDownOptions}
        isLoading={landLoading}
        subLabel="sold lands"
      />
      <IncomeCard
        label={"Agro - Total sales income"}
        value={
          agroData?.amount ? abbreviateNumber(agroData.amount * 1000000) : "0"
        }
        count={agroData?.total || 0}
        icon={GreenBar}
        handleYearChange={handleAgroYear}
        monthOptions={salesFullMonthOptions}
        yearOptions={yearDropDownOptions}
        isLoading={agroLoading}
        subLabel="cultivated lands"
      />
      <IncomeCard
        label={"Home Sales Income"}
        value={
          homeData?.amount ? abbreviateNumber(homeData.amount * 1000000) : "0"
        }
        count={homeData?.total || 0}
        icon={LightBlueBar}
        handleYearChange={handleHomeYear}
        monthOptions={salesFullMonthOptions}
        yearOptions={yearDropDownOptions}
        isLoading={homeLoading}
        subLabel="sold homes"
      />
    </Container>
  );
};

export default IncomeSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 35%;

  ${mediaObj.mediumDesktop} {
    width: 38%;
  }
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeInspectionSchedule } from "app/api/site tour";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const useRemoveInspectionSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation(removeInspectionSchedule, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([siteTourKeys.getSiteTours]);
      queryClient.invalidateQueries([siteTourKeys.getASiteTour]);
    },
  });
};

export default useRemoveInspectionSchedule;

import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { ReactComponent as assessmenticon } from "app/images/empty-assessment.svg";
import useAdminGetAllAssessments from "app/hooks/staffs/useAdminGetAssessments";
import AssessmentCard from "../components/assessmentCard";
import { MainLoaderWrapper } from "../components/grid";
import DualRing from "app/views/Web/components/loader";
import { useEffect } from "react";
import { useAppDispatch } from "app/redux";
import { resetAssessment } from "app/redux/assessment/assessmentSlice";

const Assessment = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data, isLoading } = useAdminGetAllAssessments();

  useEffect(() => {
    dispatch(resetAssessment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <CreateBtn
          onClick={() => {
            navigate("assessment");
          }}
        >
          <AddIcon />
          Create Assessment
        </CreateBtn>
      </Box>
      <ContentWrapper>
        {isLoading ? (
          <MainLoaderWrapper height="545px">
            <DualRing width="30px" height="30px" color="#727272" />
          </MainLoaderWrapper>
        ) : data?.length && data?.length > 0 ? (
          data?.map((x) => <AssessmentCard data={x} key={x.id} />)
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"100%"}
            gap={"24px"}
            mt={"30px"}
          >
            <NoContentIcon />
            <NoContentTxt>No assessment has been created yet</NoContentTxt>
          </Box>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Assessment;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 47px;
`;

const CreateBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 8px 12px;
  gap: 10px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #697f2f;
    transition: all 0.3s ease-in-out;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const NoContentIcon = styled(assessmenticon)`
  width: 219.33px;
  height: 219.33px;
`;

const NoContentTxt = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #2a3313;
`;

import React from "react";
import styled from "styled-components";
import media from "../../../../../styles/utils/media";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import {
  cardTextAnimate,
  cardVariant,
  staggerContainer,
} from "app/utils/animationVariants";
import { photosObj } from "app/api/project/types";

interface Props {
  image: string;
  location: string;
  propertyType: string;
  packageType: string;
  amount: number;
  isInstallmentAvailable: boolean;
  packageId: number;
  categoryId: string;
  mainFile?: photosObj;
  isSoldOut: boolean;
  isOnPromo: boolean;
  promoDiscount: number;
}

const SearchResultsCard: React.FC<Props> = ({
  image,
  location,
  propertyType,
  packageType,
  amount,
  isInstallmentAvailable,
  packageId,
  categoryId,
  mainFile,
  isSoldOut,
  isOnPromo,
  promoDiscount,
}) => {
  const navigate = useNavigate();

  return (
    <AnimatePresence exitBeforeEnter>
      <ResultCard variants={cardVariant} custom={0.8} isSoldOut={isSoldOut}>
        {isSoldOut && <SoldOut>Sold Out</SoldOut>}
        {!isSoldOut && isOnPromo && <OnPromo>{promoDiscount}% off</OnPromo>}

        {mainFile?.file_type === "image" && <Image src={mainFile.url} />}
        {mainFile?.file_type === "video" && (
          <VideoWrapper>
            <video autoPlay muted loop>
              <source src={mainFile.url} type="video/mp4" />
            </video>
          </VideoWrapper>
        )}
        <CardBody
          variants={staggerContainer}
          custom={0.5}
          viewport={{ once: true, amount: 0.6 }}
          whileInView={"animate"}
          initial={"initial"}
        >
          <ItemBody variants={cardTextAnimate}>
            <LocationOnIcon />
            <span className="location">{location}</span>
          </ItemBody>
          <ItemBody variants={cardTextAnimate}>
            <PackageBox>Property</PackageBox>
            <span className="package-title">{propertyType}</span>
          </ItemBody>
          <ItemBody variants={cardTextAnimate}>
            <PackageBox>Package type</PackageBox>
            <span className="package-title">{packageType}</span>
          </ItemBody>
          <ButtonGroup variants={cardTextAnimate}>
            {!isSoldOut && (
              <Button
                onClick={() =>
                  navigate(`/project/${categoryId}/package/${packageId}`)
                }
              >
                See details
              </Button>
            )}

            <DetailsGroup>
              <span className="amount">#{amount.toLocaleString("en-US")}</span>
              {isInstallmentAvailable && (
                <span className="details">
                  Installment payment options available
                </span>
              )}
            </DetailsGroup>
          </ButtonGroup>
        </CardBody>
      </ResultCard>
    </AnimatePresence>
  );
};

export default SearchResultsCard;

interface ResultCardProps {
  isSoldOut?: boolean;
}

const ResultCard = styled(motion.div)<ResultCardProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  overflow: hidden;
  position: relative;
  background: ${({ isSoldOut }) => isSoldOut && "#f1f1f1"};

  &:hover {
    box-shadow: rgb(0 0 0 / 16%) 0px 3px 20px;
    transition: all ease-in-out 0.3s;
  }
`;

const CardBody = styled(motion.div)`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ItemBody = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 8px;

  .location {
    font-weight: 500;
    font-size: 1rem;
    color: #5e5e5e;
    ${media.bigMobile`
    font-weight: 700;
    font-size: 0.875rem;
    `}
  }

  .package-title {
    font-weight: 500;
    font-size: 1rem;
    color: #5e5e5e;
    text-transform: capitalize;
    ${media.bigMobile`
    font-size: 0.875rem;
    `};
  }
`;

const PackageBox = styled.div`
  background: #143340;
  border-radius: 4px;
  padding: 8px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 0.8125rem;
  ${media.bigMobile`
    font-size: 0.75rem;
    `}
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  text-align: center;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  cursor: pointer;
  border: none;
  background: #afd34f;
  border-radius: 8px;
  width: fit-content;
  white-space: nowrap;
  ${media.bigMobile`
    width:128px;
    `}

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const ButtonGroup = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const DetailsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 8px;
  .amount {
    font-weight: 700;
    font-size: 1.5625rem;
    color: #1e4b5e;
    ${media.bigMobile`
    font-size: 1.25rem;
    `}
  }
  .details {
    font-weight: 500;
    font-size: 0.8125rem;
    text-align: center;
    color: #1e4b5e;
    ${media.bigMobile`
    font-size: 0.75rem;
    `}
  }
`;

const SoldOut = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #181d0b;
  border-radius: 0px 8px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const OnPromo = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  background: #afd34f;
  border-radius: 0px 8px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 168px;
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: 168px;
  border-top-left-radius: 5.99348px;
  border-top-right-radius: 5.99348px;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5.99348px;
    border-top-right-radius: 5.99348px;
  }
`;

import React from "react";
import styled from "styled-components";
import Actions from "app/views/Admin/components/Actions";
import AvatarContainer from "../AvatarContainer";
import { NewsResponse } from "app/api/community/types";
import useDeleteNews from "app/hooks/community/useDeleteNews";
import { toast } from "react-toastify";
import { ContentTypes } from "../..";
import { useSearchParams } from "react-router-dom";

interface Props {
  data: NewsResponse;
}

const NewsCard: React.FC<Props> = ({ data }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { title, description, cover_photo, user, created_at, slug } = data;

  const { mutate: deleteMutate, isLoading: deleteLoading } = useDeleteNews();

  const onDelete = () => {
    const payload = {
      news_id: data.id,
    };
    deleteMutate(payload, {
      onSuccess: onDeleteSuccess,
    });
  };

  const onDeleteSuccess = () => {
    toast.success(`News Deleted Successfully!`);
  };

  const onEdit = () => {
    searchParams.set("create", ContentTypes.newsAndPromos);
    searchParams.set("edit", slug);
    setSearchParams(searchParams);
  };

  return (
    <Container>
      <AbsoluteBox>
        <Tag>News</Tag>
        <Actions
          editHandler={onEdit}
          deleteHandler={onDelete}
          isDeleting={deleteLoading}
        />
      </AbsoluteBox>
      <ImgWrapper>
        <img src={cover_photo.url} alt="news" />
      </ImgWrapper>
      <ContentWrapper>
        <h6>{title} </h6>
        <p>{description}</p>
        <AvatarContainer
          name={
            (user?.firstname || "admin") + " " + (user?.lastname || "admin")
          }
          time={created_at}
          value={user?.profile_photo?.url}
        />
      </ContentWrapper>
    </Container>
  );
};

export default NewsCard;

const Container = styled.div`
  width: 784px;
  position: relative;
  display: flex;
  gap: 32px;
  padding: 24px 66px 24px 24px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32), 0px 8px 48px #eeeeee;
  border-radius: 12px;
`;

const ImgWrapper = styled.div`
  width: 180px;
  height: 180px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  & > h6 {
    font-weight: 500;
    font-size: 1.18rem;
    line-height: 140%;
    color: #2a3313;
    text-transform: capitalize;
  }

  & > p {
    width: 90%;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #5e5e5e;
    margin: 6px 0 15px;
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
`;

const Tag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 26px;
  background: #48b6e3;
  color: #fbfbfb;
  border-radius: 0px 8px;
  padding: 4px 16px;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
  color: #fbfbfb;
`;

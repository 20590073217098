import { labelValueType, MonthsValue } from "app/types/common";
import { TableNav } from "./components/DisplayPayment";
import { SalesTableNav } from "./components/DisplaySales";

export enum TabsAnalytics {
  project = 1,
  payment,
  order,
  staff,
  sales,
}

export const AnalyticsTabs = [
  {
    name: "Project Analysis",
    id: TabsAnalytics.project,
  },
  {
    name: "Payment Analysis",
    id: TabsAnalytics.payment,
  },
  {
    name: "Order Analysis",
    id: TabsAnalytics.order,
  },
  {
    name: "Staff Analysis",
    id: TabsAnalytics.staff,
  },
  {
    name: "Sales Analysis",
    id: TabsAnalytics.sales,
  },
];

export const yearDropDownOptions: labelValueType<string, number>[] = [
  {
    label: String(new Date().getFullYear()),
    value: new Date().getFullYear(),
  },
  {
    label: String(new Date().getFullYear() - 1),
    value: new Date().getFullYear() - 1,
  },
];

export const monthDropDownOptions: labelValueType<string, number>[] = [
  {
    label: "Jan",
    value: 1,
  },
  {
    label: "Feb",
    value: 2,
  },
  {
    label: "Mar",
    value: 3,
  },
];

export const PaymentTabs = [
  {
    name: "Online",
    id: TableNav.online,
  },
  {
    name: "Offline",
    id: TableNav.offline,
  },
  {
    name: "Bank",
    id: TableNav.bank,
  },
];

export const SalesTabs = [
  {
    name: "Online",
    id: SalesTableNav.online,
  },
  {
    name: "Offline",
    id: SalesTableNav.offline,
  },
];

export const exportData = [
  {
    label: "Export",
    value: 1,
  },
];

export const showData = [
  {
    label: "Show 5 per rows",
    value: 5,
  },
  {
    label: "Show 10 per rows",
    value: 10,
  },
  {
    label: "Show 25 per rows",
    value: 25,
  },
  {
    label: "Show 50 per rows",
    value: 50,
  },
];

export const statusData = [
  {
    label: "Pending",
    value: 1,
  },
  {
    label: "Approved",
    value: 2,
  },
  {
    label: "Declined",
    value: 3,
  },
];

export const salesMonthOptions: labelValueType<string, number>[] = [
  {
    label: "Jan",
    value: MonthsValue.jan,
  },
  {
    label: "Feb",
    value: MonthsValue.feb,
  },
  {
    label: "Mar",
    value: MonthsValue.mar,
  },
  {
    label: "Apr",
    value: MonthsValue.apr,
  },
  {
    label: "May",
    value: MonthsValue.may,
  },
  {
    label: "Jun",
    value: MonthsValue.jun,
  },
  {
    label: "July",
    value: MonthsValue.july,
  },
  {
    label: "Aug",
    value: MonthsValue.aug,
  },
  {
    label: "Sept",
    value: MonthsValue.sept,
  },
  {
    label: "Oct",
    value: MonthsValue.oct,
  },
  {
    label: "Nov",
    value: MonthsValue.nov,
  },
  {
    label: "Dec",
    value: MonthsValue.dec,
  },
];

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postSiteInspection } from "app/api/project";
import { parseError } from "app/utils";
import { siteTourKeys } from "./types";

const usePostSiteInspection = () => {
  const queryClient = useQueryClient();

  return useMutation(postSiteInspection, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([siteTourKeys.approvedInspections]);
    },
  });
};

export default usePostSiteInspection;

import React from "react";
import { Route } from "react-router-dom";
import { Routes, useLocation } from "react-router";
import AdminLayout from "./Layout";
import RequireAuth from "../../hooks/RequireAuth";
import NotFound from "./notFound";
import Settings from "./Settings";
import { staffRoutes } from "app/utils/data";
import Dashboard from "./Dashboard";
import ETeamDetails from "./ETeam/details";
import VirtualStaff from "./ETeam";
import MyClients from "./Clients";
import DetailsLayout from "../Client/Properties/DetailsLayout";
import PostDetails from "../Admin/Community/PostDetails";
import Community from "./Community";
import CommunityOffers from "./Community/CommunityOffers";

const StaffRoutes: React.FC = () => {
  const location = useLocation();

  return (
    <>
      <Routes location={location}>
        <Route
          path="/staff"
          element={
            <RequireAuth>
              <AdminLayout />
            </RequireAuth>
          }
        >
          <Route path={staffRoutes.Settings} element={<Settings />} />
          <Route path={staffRoutes.Dashboard} element={<Dashboard />} />
          <Route path={staffRoutes.Clients} element={<MyClients />} />
          <Route path={staffRoutes.VirtualStaff} element={<VirtualStaff />} />
          <Route
            path={`${staffRoutes.VirtualStaff}/:id`}
            element={<ETeamDetails />}
          />
          <Route path={staffRoutes.Community.Index} element={<Community />} />
          <Route
            path={`${staffRoutes.Community.BlogPost}/:postTitle`}
            element={<PostDetails />}
          />
          <Route
            path={`${staffRoutes.Community.Offers}`}
            element={<DetailsLayout />}
          >
            <Route
              path={`${staffRoutes.Community.Offers}/:id`}
              element={<CommunityOffers />}
            />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default StaffRoutes;

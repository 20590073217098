import { useMutation } from "@tanstack/react-query";
import { adminSalesPieChart } from "app/api/sales";
import { parseError } from "app/utils";

const useAdminSalesPieChart = () => {
  return useMutation(adminSalesPieChart, {
    onError: (error) => parseError(error),
  });
};

export default useAdminSalesPieChart;

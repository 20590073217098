import React, { useEffect } from "react";
import { Backdrop } from "@mui/material";
import styled from "styled-components";
import { toast } from "react-toastify";
import DualRing from "app/views/Web/components/loader";
import useAdminUpgradeStaff from "app/hooks/staffs/useAdminUpgradeStaff";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  id: number;
}

const UpgradeStaffModal: React.FC<Props> = ({ isOpen, handleClose, id }) => {
  const { mutate, error, isLoading } = useAdminUpgradeStaff();

  useEffect(() => {
    let errorMessage = error as string;
    toast.error(errorMessage);
  }, [error]);

  const onSubmit = () => {
    mutate(id, {
      onSuccess: () => toast.success("User upgraded successfully!"),
    });

    handleClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isOpen}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Content>
          Are you sure you want to <Upgrade>upgrade</Upgrade> this staff?
        </Content>
        <BtnWrapper>
          <CancelBtn onClick={handleClose} type="button">
            No, Cancel
          </CancelBtn>
          <ConfirmBtn onClick={onSubmit}>
            {isLoading ? (
              <DualRing color="#fff" width={"9px"} height={"9px"} />
            ) : (
              `Yes, Upgrade`
            )}
          </ConfirmBtn>
        </BtnWrapper>
      </Container>
    </Backdrop>
  );
};

export default UpgradeStaffModal;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 25vw;
  padding: 40px 20px 35px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #00000050;
  z-index: 20;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
`;

const Content = styled.div`
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  color: #464646;
`;

const Upgrade = styled.span`
  color: #28647d;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 8px;
  min-width: 90px;
  height: 36px;
  cursor: pointer;
`;

const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #97b644;
  border: none;

  & > div {
    margin: 0 10px 12px 0;
  }
  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-in-out;
  }
`;

const CancelBtn = styled(Btn)`
  color: #dd1717;
  border: 1px solid #dd1717;
  background-color: transparent;
`;

import useAdminRecentSales from "app/hooks/analytics/useAdminRecentSales";
import Spinner from "app/views/Web/components/Spinner";
import styled from "styled-components";
import SalesGrid from "./grid";

const RecentSales = () => {
  const { data, isLoading } = useAdminRecentSales();

  return (
    <Container>
      <Title>Recent Sales</Title>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner color="#AFD34F" />
        </SpinnerWrapper>
      )}
      {!isLoading && data && <SalesGrid data={data} />}
    </Container>
  );
};

export default RecentSales;

const Container = styled.div`
  width: 100%;
  background: #fff;
  padding: 20px 15px 15px;
  margin-top: 40px;
  border-radius: 8px;
`;

const Title = styled.h1`
  font-weight: 450;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
  margin-bottom: 15px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { ContentTypes } from "../..";
import CreateWrapper from "../CreateWrapper";
import NewsSection from "../sections/NewsSection";
import OffersSection from "../sections/OffersSection";

const CreateNewsAndOffers = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const createType = searchParams.get("create");
  const [activeNav, setActiveNav] = useState(1);
  const editTitle = searchParams.get("edit");
  const editPromo = searchParams.get("edit_promo");
  const entry = searchParams.get("create");

  useEffect(() => {
    if (editPromo) {
      setActiveNav(2);
    }
  }, [editPromo]);

  return (
    <CreateWrapper
      open={createType === ContentTypes.newsAndPromos}
      title={`${editTitle || editPromo ? "Update" : "Create"}  News and Promos`}
    >
      <>
        <NavWrapper>
          <Nav
            isActive={activeNav === 1}
            isDisabled={!!editPromo}
            onClick={() => {
              if (!!editPromo) return;
              setActiveNav(1);
              entry &&
                setSearchParams({
                  activeTab: "News",
                  create: entry,
                });
            }}
          >
            News
          </Nav>
          <Nav
            isActive={activeNav === 2}
            isDisabled={!!editTitle}
            onClick={() => {
              if (!!editTitle) return;
              setActiveNav(2);
              entry &&
                setSearchParams({
                  activeTab: "Promo",
                  create: entry,
                });
            }}
          >
            Promo
          </Nav>
        </NavWrapper>
        {activeNav === 1 ? <NewsSection /> : <OffersSection />}
      </>
    </CreateWrapper>
  );
};

export default CreateNewsAndOffers;

const NavWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 24px;
`;

interface NavProps {
  isActive?: boolean;
  isDisabled: boolean;
}

const Nav = styled.div<NavProps>`
  font-weight: 450;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#AFD34F" : "#777777")};
  border-bottom: ${({ isActive }) => isActive && "3px solid #AFD34F"};
  transition: all 0.2s ease-in;
  white-space: nowrap;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
`;

import React from "react";
import useAdminGetOffers from "app/hooks/community/useGetOffers";
import { ErrorText, NoProjectText, SpinnerWrapper } from "./styles";
import Spinner from "app/views/Web/components/Spinner";
import OfferCard from "./cards/OfferCard";

const DisplayOffers = () => {
  const { data, isLoading, isError } = useAdminGetOffers();

  if (isLoading)
    return (
      <SpinnerWrapper>
        <Spinner color="#AFD34F" />
      </SpinnerWrapper>
    );

  if (!isLoading && isError)
    return (
      <SpinnerWrapper>
        <ErrorText>An error occurred. Please try again later</ErrorText>
      </SpinnerWrapper>
    );

  if (data && data.length === 0)
    return (
      <SpinnerWrapper>
        <NoProjectText>We have no content for this yet</NoProjectText>
      </SpinnerWrapper>
    );

  return (
    <>
      {data &&
        data.map((ev) => (
          <OfferCard
            key={ev.id}
            data={ev}
            packageData={ev.customerPackageItem.packageItem.package}
          />
        ))}
    </>
  );
};

export default DisplayOffers;

import React, { useState } from "react";
import { Backdrop } from "@mui/material";
import {
  useAdminApproveOffer,
  useAdminRejectOffer,
} from "app/hooks/offers/useOffer";
import InputField from "app/views/Admin/components/formik inputs/InputField";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";
import { DialogModalValidation } from "../validation";
import { mediaObj } from "styles/utils/media";

interface Props {
  userName: string;
  open: boolean;
  handleClose: () => void;
  type: "accept" | "reject";
  offerId: number;
}

const DialogModal: React.FC<Props> = ({
  userName,
  open,
  handleClose,
  type,
  offerId,
}) => {
  const [reasonText, setReasonText] = useState("");

  const { mutate: approveMutate, isLoading: isApproving } =
    useAdminApproveOffer();

  const { mutate: rejectMutate, isLoading: isRejecting } =
    useAdminRejectOffer();

  const onSuccess = () => {
    toast.success(
      `Offer ${type === "accept" ? "approved" : "rejected"} successfully!`
    );
    handleClose();
  };

  const onApprove = () => {
    const payload = {
      offer_id: offerId,
    };
    approveMutate(payload, {
      onSuccess,
    });
  };

  const onReject = () => {
    const payload = {
      offer_id: offerId,
      reason: reasonText,
    };
    rejectMutate(payload, {
      onSuccess,
    });
  };

  const onConfirm = () => {
    if (type === "accept") {
      onApprove();
    }

    if (type === "reject") {
      onReject();
    }
  };

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Formik
          initialValues={{
            reasonText,
          }}
          validationSchema={type === "reject" && DialogModalValidation}
          enableReinitialize
          onSubmit={onConfirm}
        >
          <Form>
            <Content>
              Are you sure you want to{" "}
              {type === "accept" ? (
                <Accept>accept</Accept>
              ) : (
                <Reject>reject</Reject>
              )}{" "}
              this sales offer created by <User>{userName}</User>
            </Content>
            {type === "reject" && (
              <InputField
                name="reasonText"
                label=""
                value={reasonText}
                placeholder="State reason for rejecting sale offer for client"
                onChange={(e) => setReasonText(e.target.value)}
                inputType="textarea"
                textareaBg="#F7F6F6"
              />
            )}
            <BtnWrapper type={type} isDisabled={isApproving || isRejecting}>
              <ConfirmBtn type="submit">
                {(isApproving || isRejecting) && <Spinner color="black" />}
                Confirm
              </ConfirmBtn>
              <CancelBtn onClick={handleClose} type="button">
                Cancel
              </CancelBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Container>
    </Backdrop>
  );
};

export default DialogModal;

const Container = styled.div`
  width: 550px;
  padding: 24px 42px 33px 42px;
  background-color: white;
  border-radius: 8px;

  & > form {
    width: 100%;

    button {
      font-weight: 500;
      font-size: 0.8125rem;
      line-height: 140%;
      padding: 10px 24px;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  ${mediaObj.tablet} {
    width: 70vw;
  }

  ${mediaObj.bigMobile} {
    width: 90vw;
  }
`;

const Content = styled.div`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  text-align: center;
  color: #464646;
  margin-bottom: 24px;
`;

const User = styled.span`
  color: #49b7e4;
`;

const Accept = styled.span`
  color: #afd34f;
`;

const Reject = styled.span`
  color: #b40909;
`;

interface BtnWrapperProps {
  type: "accept" | "reject";
  isDisabled: boolean;
}

const BtnWrapper = styled.div<BtnWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: ${({ type }) => (type === "accept" ? "40px" : "24px")};

  & > button {
    cursor: ${({ isDisabled }) => isDisabled && "not-allowed"};
  }
`;
const ConfirmBtn = styled.button`
  color: #fbfbfb;
  background: #afd34f;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CancelBtn = styled.button`
  color: #2a3313;
  border: 1px solid #2a3313;
  background-color: transparent;
`;

import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import { PropertiesRoutes, UserRoutes } from "app/utils/data";
import { Box } from "@mui/material";
import mediaQueries from "styles/utils/mediaQueries";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useEffect, useState } from "react";
import NotifyDialog from "./components/modals/NotifyDialog";
import { NoProjectText, SpinnerWrapper } from "./components/styles";
import Spinner from "app/views/Web/components/Spinner";
import useGetASingleClientPackage from "app/hooks/clients/useGetASingleClientPackage";
import { useGetASingleOffer } from "app/hooks/offers/useOffer";
import ImageDisplay from "../Projects/components/ImageDisplay";
import ProjectImages from "app/views/Web/components/modal/ProjectImages";
import { OfferPackageObj } from "app/api/offers/types";
import { useGetASingleBid } from "app/hooks/offers/useBid";
import { mediaObj } from "styles/utils/media";
import useDownloadPackageBrochure from "app/hooks/projects/useDownloadPackageBrochure";
import useDownloadClientPackageDoa from "app/hooks/clients/useDownloadClientPackageDoa";

const DetailsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location.pathname.split("/").at(-2);
  const [packageData, setPackageData] = useState<OfferPackageObj>();
  const [sqmValue, setSqmValue] = useState("");
  const [plotUnit, setPlotUnit] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openImageDisplay, setOpenImageDisplay] = useState(false);
  const handleClose = () => setOpen(false);

  const { id: packageId } = useParams();
  const { isFetching: isExporting, refetch } = useDownloadPackageBrochure(
    packageId as string
  );

  const { data: clientPackageData, isLoading: clientPackageFetching } =
    useGetASingleClientPackage(
      Number(packageId),
      type === PropertiesRoutes.Complete ||
        type === PropertiesRoutes.Installment
    );

  const { data: offerData, isFetching: offerFetching } = useGetASingleOffer(
    Number(packageId),
    type === PropertiesRoutes.Offers
  );

  const { data: bidData, isFetching: bidFetching } = useGetASingleBid(
    Number(packageId),
    type === PropertiesRoutes.Bids
  );

  const { isFetching: isDoaExporting, refetch: doaRefetch } =
    useDownloadClientPackageDoa(packageId as string);

  useEffect(() => {
    if (clientPackageData) {
      setPackageData(clientPackageData.packageItem.package);
      setSqmValue(clientPackageData.packageItem.size);
      setPlotUnit(clientPackageData.units);
    }
  }, [clientPackageData]);

  useEffect(() => {
    if (offerData) {
      setPackageData(offerData.customerPackageItem.packageItem.package);
      setSqmValue(offerData.customerPackageItem.packageItem.size);
      // setPlotUnit(offerData)
    }
  }, [offerData]);

  useEffect(() => {
    if (bidData) {
      setPackageData(bidData.customerPackageItem?.packageItem.package);
      setSqmValue(bidData.customerPackageItem.packageItem.size);
      // setPlotUnit(bidData.units)
    }
  }, [bidData]);

  useEffect(() => {
    if (
      type === PropertiesRoutes.Complete ||
      type === PropertiesRoutes.Installment
    ) {
      setPageLoading(clientPackageFetching);
    }
  }, [clientPackageFetching, type]);

  useEffect(() => {
    if (type === PropertiesRoutes.Offers) {
      setPageLoading(offerFetching);
    }
  }, [type, offerFetching]);

  useEffect(() => {
    if (type === PropertiesRoutes.Bids) {
      setPageLoading(bidFetching);
    }
  }, [type, bidFetching]);

  const formatImages = () => {
    const temp = [];
    temp.push((packageData as OfferPackageObj).main_file);
    const result = packageData?.photos
      ? [...temp, ...packageData.photos]
      : temp;

    return result;
  };

  const formatModalImages = () => {
    const temp = formatImages().filter((ev) => ev.file_type === "image");
    return temp;
  };

  const isCommunityOffers =
    location.pathname.split("/").slice(0, -1).join("/") ===
    UserRoutes.Community.Offers;

  return (
    <Wrapper>
      <BackBtn>
        <ArrowBackIcon onClick={() => navigate(-1)} />
        <Back>{isCommunityOffers ? "Back" : "Package Details"}</Back>
      </BackBtn>

      {pageLoading && (
        <SpinnerWrapper>
          <Spinner width="40px" height="40px" color="#AFD34F" />
        </SpinnerWrapper>
      )}

      {!packageData && !pageLoading && (
        <SpinnerWrapper>
          <NoProjectText>Package Not Found</NoProjectText>
        </SpinnerWrapper>
      )}

      {packageData && !pageLoading && (
        <>
          <TopWrapper>
            <Title>
              {" "}
              {isCommunityOffers ? "Sales Offer" : packageData.name}{" "}
            </Title>
            {type === PropertiesRoutes.Complete && (
              <DownloadBtn
                onClick={() => doaRefetch()}
                disabled={!clientPackageData?.doa_uploaded}
              >
                {isDoaExporting ? (
                  <Spinner color="#AFD34F" />
                ) : (
                  <DownloadOutlinedIcon />
                )}
                Download Deed of Agreement
              </DownloadBtn>
            )}
            {type === PropertiesRoutes.Offers && !isCommunityOffers && (
              <CloseBtn onClick={() => setOpen(true)}>Close Sales</CloseBtn>
            )}
            {type === PropertiesRoutes.Bids && (
              <BtnBox
                onClick={() => refetch()}
                disabled={!packageData.brochure}
              >
                {isExporting ? (
                  <Spinner color="white" />
                ) : (
                  <DownloadOutlinedIcon />
                )}
                <span>Download Brochure</span>
              </BtnBox>
            )}
          </TopWrapper>
          <GridWrapper>
            <FlexBox>
              {packageData?.main_file && (
                <ImageDisplay
                  setOpen={setOpenImageDisplay}
                  imageData={formatImages()}
                />
              )}
              <DetailsWrapper>
                <Description>{packageData?.description}</Description>
                {isCommunityOffers && (
                  <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                    <DeetLabel>Property</DeetLabel>
                    <Deet>{packageData.name}</Deet>
                  </Box>
                )}
                <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                  <DeetLabel>Location</DeetLabel>
                  <Deet>
                    {packageData.project_location?.location}
                    {", "}
                    {packageData.project_location?.address}
                  </Deet>
                </Box>
                {!isCommunityOffers && (
                  <>
                    <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                      <DeetLabel>Squaremeter</DeetLabel>
                      <Deet>{sqmValue}</Deet>
                    </Box>
                    {plotUnit && (
                      <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                        <DeetLabel>Units</DeetLabel>
                        <Deet>{plotUnit}</Deet>
                      </Box>
                    )}
                  </>
                )}
                {type !== PropertiesRoutes.Installment &&
                  type !== PropertiesRoutes.Bids &&
                  !isCommunityOffers && (
                    <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                      <DeetLabel bg={"#97b644"}>Purchase Price</DeetLabel>
                      <Price>
                        {"\u20A6 "}
                        {Number(
                          (clientPackageData || offerData)?.purchase_price
                        ).toLocaleString("en-US")}
                      </Price>
                    </Box>
                  )}

                {type === PropertiesRoutes.Bids && (
                  <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                    <DeetLabel bg={"#97b644"}>Bid Price</DeetLabel>
                    <Price>
                      {"\u20A6 "}
                      {Number(bidData?.bid__price).toLocaleString("en-US")}
                    </Price>
                  </Box>
                )}

                {type === PropertiesRoutes.Installment && (
                  <>
                    <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                      <DeetLabel bg={"#97b644"}>Amount Paid</DeetLabel>
                      <Price>
                        #
                        {Number(
                          clientPackageData?.payments[0].order.amount_paid
                        ).toLocaleString("en-US")}
                      </Price>
                    </Box>
                    <Box display={"flex"} alignItems={"center"} gap={"16px"}>
                      <DeetLabel bg={"#DC362E"}>Amount Remaining</DeetLabel>
                      <Price>
                        #
                        {clientPackageData
                          ? Number(
                              +clientPackageData.payments[0].order
                                .amount_payable -
                                +clientPackageData.payments[0].order.amount_paid
                            ).toLocaleString("en-US")
                          : ""}
                      </Price>
                    </Box>
                  </>
                )}
              </DetailsWrapper>
            </FlexBox>
            <FlexBox>
              {packageData?.benefits && (
                <BenefitContainer>
                  <h2>Package Benefits</h2>
                  <BenefitContent
                    benefitNo={Math.ceil(
                      packageData.benefits.split(",").length / 3
                    )}
                  >
                    {packageData.benefits.split(",").map((ev, i) => (
                      <BenefitItem key={i}>{ev}</BenefitItem>
                    ))}
                  </BenefitContent>
                </BenefitContainer>
              )}

              <OutletContainer>
                <Outlet context={{ offerData, clientPackageData, bidData }} />
              </OutletContainer>
            </FlexBox>
          </GridWrapper>
        </>
      )}
      {open && offerData && (
        <NotifyDialog
          open={open}
          offerId={offerData.id}
          handleClose={handleClose}
        />
      )}
      {packageData?.main_file && openImageDisplay && (
        <ProjectImages
          open={openImageDisplay}
          handleClose={() => setOpenImageDisplay(false)}
          imageArray={formatModalImages()}
          packageName={packageData.name}
        />
      )}
    </Wrapper>
  );
};

export default DetailsLayout;

const Wrapper = styled.div`
  padding: 36px 28px;
  background: #ffffff;
  min-height: 80vh;

  ${mediaQueries.mobile} {
    padding: 1rem 0.5rem;
  }
`;

const BackBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;

  & > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

const Back = styled.p`
  font-weight: 450;
  font-size: 25px;
  color: #181d0b;
  margin-top: 2px;

  ${mediaQueries.mobile} {
    font-size: 16px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;

  ${mediaQueries.mobile} {
    align-items: center;
    gap: 10px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  color: #2a3313;
  flex-shrink: 0;

  ${mediaQueries.mobile} {
    font-size: 18px;
  }
`;

const DownloadBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  color: #afd34f;
  width: 336px;
  height: 56px;
  border: 1px solid #afd34f;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  background-color: white;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background: #afd34f;
    color: #fbfbfb;
    transition: all 0.2s ease-out;

    svg {
      fill: white;
    }
  }

  ${mediaObj.mobile} {
    padding: 5%;
    gap: 5%;
    font-size: 12px;
    width: 180px;

    svg {
      display: none;
    }
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;

  ${mediaObj.tablet} {
    grid-template-columns: 1fr;
    gap: 16px;

    & > div:nth-child(2) {
      background-color: red;
    }
  }
`;

const OutletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mediaObj.tablet} {
    grid-row: 2/3;
  }
`;

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    gap: 20px;
  }

  ${mediaObj.tablet} {
    display: contents;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  flex-shrink: 0;
  width: clamp(350px, 44%, 515px);
`;

const Description = styled.p`
  font-weight: 450;
  font-size: 16px;
  line-height: 140%;
  color: #464646;
  margin-bottom: 10px;
`;

const DeetLabel = styled.span<{ bg?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  font-weight: 500;
  font-size: 16px;
  color: #fbfbfb;
  height: 30px;
  background: ${({ bg }) => (bg ? bg : "#143340")};
  border-radius: 4px;
`;

const Deet = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #143340;
`;

const Price = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #1e4b5e;

  ${mediaQueries.mobile} {
    font-size: 16px;
  }
`;

const BenefitContainer = styled.div`
  width: 100%;
  background-color: #d8e8b8;
  padding: 12px 16px;
  border-radius: 4px;

  h2 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: #303030;
    text-align: center;
  }
`;

interface BenefitContentProps {
  benefitNo: number;
}

const BenefitContent = styled.div<BenefitContentProps>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-template-rows: ${({ benefitNo }) => `repeat(${benefitNo}, 3rem)`};
  justify-content: center;
  gap: 12px;
  margin-top: 12px;
`;

const BenefitItem = styled.div`
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #28647d;
  border-radius: 2px;
  color: #fbfbfb;
  text-align: center;
  font-weight: 450;
  font-size: 0.8125rem;
  line-height: 140%;
  word-break: normal;
  overflow-y: scroll;
  text-transform: capitalize;
`;

const CloseBtn = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  background-color: #b3261e;
  color: #fbfbfb;
  padding: 16px 50px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #b3261ee4;
    transition: all 0.3s ease-in-out;
  }

  ${mediaObj.mobile} {
    padding: 5%;
  }
`;

const BtnBox = styled.button`
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  background: #afd34f;
  border: none;
  color: white;
  padding: 17px 40px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaObj.mobile} {
    padding: 5%;
    gap: 5%;
    font-size: 12px;
    width: max-content;

    svg {
      display: none;
    }
  }
`;

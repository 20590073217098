import { photosObj } from "app/api/project/types";
import React from "react";
import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  imageData: photosObj[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const displayLength = 4;

const ImageDisplay: React.FC<Props> = ({ imageData, setOpen }) => {
  const withoutVideo = imageData.filter(
    (ev) => ev.file_type === "video"
  ).length;

  const imageSurplus =
    imageData.length - (displayLength - withoutVideo) - withoutVideo;

  const getGridColumn = () => {
    if (imageData.length === 1) return "1fr";
    if (imageData.length === 3) return "2fr 1fr";
    return "2fr 160px";
  };

  const getGridRow = () => {
    if (imageData.length === 1) return "1";
    if (imageData.length === 2) return "3";
    if (imageData.length === 3) return "2";
    return "3";
  };

  const getFileElement = (file: photosObj) => {
    if (file.file_type === "image")
      return (
        <ImageWrapper onClick={() => setOpen(true)}>
          <img src={file.url} alt="project images" />
        </ImageWrapper>
      );
    if (file.file_type === "video")
      return (
        <video autoPlay muted loop controls>
          <source src={file.url} type="video/mp4" />
        </video>
      );
  };

  return (
    <div>
      <Container gridColumn={getGridColumn()} gridRow={getGridRow()}>
        {imageData.map((img, i) => {
          if (i + 1 > displayLength) return undefined;
          if (i + 1 === displayLength)
            return (
              <LastImageWrapper key={i} onClick={() => setOpen(true)}>
                {getFileElement(img)}
                {imageSurplus > 0 && (
                  <div>
                    <span>{imageSurplus} Pictures</span>
                  </div>
                )}
              </LastImageWrapper>
            );
          return getFileElement(img);
        })}
      </Container>
    </div>
  );
};

export default ImageDisplay;

interface ContainerProps {
  gridColumn: string;
  gridRow: string;
}

const Container = styled.div<ContainerProps>`
  display: grid;
  height: 383px;
  grid-template-rows: ${(props) => `repeat(${props.gridRow}, minmax(0,1fr))`};
  grid-template-columns: ${(props) => props.gridColumn};
  gap: 1rem;

  img {
    border-radius: 12px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  video {
    border-radius: 12px;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  & > :first-child {
    grid-row: 1/-1;
  }

  img:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  ${mediaQueries.mobile} {
    height: 250px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  img:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }
`;

const LastImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:hover img {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000066;
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    text-decoration-line: underline;
    color: #afd352;
    border-radius: 12px;
    cursor: pointer;
    &:hover span {
      transform: scale(1.1);
      transition: all 0.3s ease-in-out;
    }
  }
`;

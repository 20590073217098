import React, { useState } from "react";
import { adminGetClientPackagesRes } from "app/api/analytics/types";
import { uploadDoa } from "app/api/utilities";
import useAdminClientDoa from "app/hooks/analytics/useAdminClientDoa";
import { parseError } from "app/utils";
import Spinner from "app/views/Web/components/Spinner";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import styled from "styled-components";
import { UplaodDoaValidation } from "../validation";
import DoaUploadBox from "./DoaUploadBox";
import ModalWrapper from "./ModalWrapper";
import { Box } from "@mui/system";

interface Props {
  open: boolean;
  handleClose: () => void;
  data: adminGetClientPackagesRes;
}

const DoaModal: React.FC<Props> = ({ open, handleClose, data }) => {
  const [mainFileId, setMainFileId] = useState<number>();
  const { mutate: saveMutate, isLoading: isSaving } = useAdminClientDoa();

  const onSubmitHandler = () => {
    if (!mainFileId) return;
    const payload = {
      client_package_id: data.id,
      doa_file_id: mainFileId,
    };
    saveMutate(payload, {
      onSuccess: () => {
        handleClose();
        toast.success("Upload sucessfully!");
      },
    });
  };

  const onMainFileDelete = () => {
    setMainFileId(undefined);
  };

  const MainPhotoUploader = async (file: File) => {
    try {
      const data = new FormData();
      data.append("document", file);
      const res = await uploadDoa(data);
      setMainFileId(res.data.data.id);
    } catch (error) {
      parseError(error);
    }
  };

  return (
    <ModalWrapper open={open} handleClose={handleClose}>
      <Container>
        <Formik
          initialValues={{
            mainFileId,
          }}
          validationSchema={UplaodDoaValidation}
          enableReinitialize
          onSubmit={onSubmitHandler}
        >
          <Form>
            <Box mt="1rem">
              <DoaUploadBox
                name="mainFileId"
                imageBoxText="Upload DOA"
                photoPostRequest={MainPhotoUploader}
                onSingleFileDelete={onMainFileDelete}
              />
            </Box>

            <BtnWrapper>
              <CancelBtn onClick={handleClose} type="button">
                Cancel
              </CancelBtn>
              <ConfirmBtn type="submit">
                {isSaving && <Spinner color="black" />}
                Yes, Upload
              </ConfirmBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      </Container>
    </ModalWrapper>
  );
};

export default DoaModal;

const Container = styled.div`
  width: 100%;
`;

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  width: 100%;
  margin-top: 24px;
`;

const Btn = styled.button`
  padding: 10px 28px;
  border-radius: 6px;
  cursor: pointer;
`;

const ConfirmBtn = styled(Btn)`
  color: #fbfbfb;
  background: #97b644;
  border: none;
  display: flex;
  gap: 8px;
  padding: 11px 28px;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #697f2f;
  }
`;

const CancelBtn = styled(Btn)`
  color: #dd1717;
  border: 1px solid #dd1717;
  background-color: transparent;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

export enum ProjectsKey {
  allCategory = "get-all-categories",
  locationPerProject = "get-all-location-per-project",
  packagePerLocation = "get-all-package-per-location",
  allProjectPerCatgory = "get-all-projects-per-category",
  singleProject = "get-a-single-project",
  projectPerCategoryId = "get-projects-by-category-id",
  allItemPerPackage = "get-all-item-per-package",
  singlePackage = "get-a-single-package",
  latestPackage = "get-latest-package",
  getProjectCount = "get-project-count",
  downloadPackageBrochure = "download-package-brochure",
  clientsPerPackage = "clients-per-package",
}

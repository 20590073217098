import { StaffTypeEnum, UserResponse } from "app/types/common";
import styled from "styled-components";

const StaffTypeRenderer = ({ data }: { data: UserResponse }) => {
  const {
    staff_type: { name },
  } = data;
  return <Container type={name}>{name}</Container>;
};

export default StaffTypeRenderer;

const Container = styled.div<{ type: StaffTypeEnum }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 12px;
  gap: 4px;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  color: #fbfbfb;
  background: #97b644;
  border-radius: 10px;
`;

import { useNavigate } from "react-router";
import styled from "styled-components";
import { truncateTxt } from "../../../../utils";
import mediaQueries from "../../../../../styles/utils/mediaQueries";
import { packagesObj } from "../../../../api/project/types";
import { capitalize } from "lodash";
import { cardVariant } from "app/utils/animationVariants";
import { AnimatePresence, motion } from "framer-motion";
import { mediaObj } from "styles/utils/media";

interface props {
  packageData: packagesObj;
  isSoldOut: boolean;
  isOnPromo: boolean;
  promoDiscount: number;
}

const PackageCard: React.FC<props> = ({
  packageData,
  isSoldOut,
  isOnPromo,
  promoDiscount,
}) => {
  const navigate = useNavigate();
  const { items, name, project_location, benefits, id, main_file } =
    packageData;

  return (
    <AnimatePresence exitBeforeEnter key={packageData.id}>
      <Container isSoldOut={isSoldOut} variants={cardVariant}>
        {isSoldOut && <SoldOut>Sold Out</SoldOut>}
        {!isSoldOut && isOnPromo && <OnPromo>{promoDiscount}% off</OnPromo>}
        {main_file?.file_type === "image" && <CardImage src={main_file?.url} />}
        {main_file?.file_type === "video" && (
          <VideoWrapper>
            <video autoPlay muted loop>
              <source src={main_file.url} type="video/mp4" />
            </video>
          </VideoWrapper>
        )}
        <CardBody>
          <ContentWrapper>
            <Entry>
              <span>Package</span>
              <p>{name}</p>
            </Entry>
            <Entry>
              <span>Available Unit/Plot</span>
              <p>{items && items[0]?.available_units}</p>
            </Entry>
            <Entry>
              <span>Location</span>
              <p>
                {project_location.location + ", " + project_location.address}
              </p>
            </Entry>
          </ContentWrapper>
          {benefits && (
            <CardMobileBenefits>
              <h2>Benefits</h2>
              <p>{truncateTxt(benefits.split(",").join(", "), 45)}</p>
            </CardMobileBenefits>
          )}
          {!isSoldOut && (
            <SeeMoreButton onClick={() => navigate(`package/${id}`)}>
              See more
            </SeeMoreButton>
          )}
        </CardBody>
        <CardBenefits>
          <h2>Benefits</h2>
          {benefits && (
            <BenefitsWrapper>
              {benefits.split(",").map((x, i) => (
                <p key={i}>{capitalize(x)}</p>
              ))}
            </BenefitsWrapper>
          )}
        </CardBenefits>
      </Container>
    </AnimatePresence>
  );
};

export default PackageCard;

interface ContainerProps {
  isSoldOut: boolean;
}

const Container = styled(motion.div)<ContainerProps>`
  position: relative;
  display: flex;
  gap: 16px;
  width: 870px;
  height: 243px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  background: ${({ isSoldOut }) => isSoldOut && "#f1f1f1"};
  cursor: ${({ isSoldOut }) => isSoldOut && "not-allowed"};

  ${mediaObj.smallDesktop} {
    width: 100%;
  }

  ${mediaQueries.mobile} {
    flex-direction: column;
    height: auto;
  }
`;

const CardImage = styled.img`
  width: 260px;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  object-fit: cover;

  ${mediaQueries.mobile} {
    width: 100%;
    height: 235px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

const VideoWrapper = styled.div`
  width: 260px;
  height: 100%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  ${mediaQueries.mobile} {
    width: 100%;
    height: 235px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px 15px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Entry = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    background: #143340;
    border-radius: 4px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: #fbfbfb;
  }

  & > p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 140%;
    color: #3e4b1c;
  }

  ${mediaQueries.mobile} {
    & > span {
      font-size: 13px;
    }

    & > p {
      font-size: 14px;
    }
  }
`;

const SeeMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 117px;
  height: 38px;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  color: #ffffff;
  border: none;
  cursor: pointer;

  ${mediaQueries.mobile} {
    margin-top: 30px;
  }

  &:hover {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }
`;

const CardBenefits = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  width: 167px;
  flex-shrink: 0;
  gap: 15px;
  background: #536425;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  & > h2 {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    color: #fbfbfb;
  }

  ${mediaQueries.mobile} {
    display: none;
  }
`;

const CardMobileBenefits = styled.div`
  display: none;

  & > h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: #fbfbfb;
  }

  & > p {
    font-weight: 500;
    font-size: 12px;
    line-height: 140%;
    color: #fbfbfb;
  }

  ${mediaQueries.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    gap: 8px;
    padding: 10px;
    background: #536425;
  }
`;

const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-height: 160px;
  overflow-y: scroll;

  & > p {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 140%;
    color: #fbfbfb;
  }
`;

const SoldOut = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: #181d0b;
  border-radius: 8px 0px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

const OnPromo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: #afd34f;
  border-radius: 8px 0px 0px 0px;
  padding: 4px 16px;
  color: #fbfbfb;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
`;

import styled from "styled-components";
import mediaQueries from "styles/utils/mediaQueries";

export const FlexWrapper = styled.div`
  display: flex;
  gap: 24px;

  ${mediaQueries.mobile} {
    flex-direction: column;
  }
`;

export const FormWrapper = styled.div`
  & > form {
    width: 100%;
    overflow-y: scroll;
    max-height: 60vh;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const CreateBtn = styled.button`
  width: 90%;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  margin: auto;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background: #afd34f;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #fbfbfb;
  outline: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover:not(:disabled) {
    background-color: #697f2f;
    transition: all 0.2s ease-out;
  }

  ${mediaQueries.mobile} {
    width: 100%;
  }
`;

export const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../../../images/admin/search-icon.svg";
import AllClientGrid from "../grid";
import { clientObj } from "app/api/client/types";
import Paginating from "app/views/Admin/components/Pagination";
import useGetPaginatedAllClients from "app/hooks/site tour/useGetAllClients";
import { CustomTourResponse } from "app/api/site tour/types";
import useSearchClient from "app/hooks/clients/useSearchClient";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  selectClient: (data: clientObj, type: boolean) => void;
  setNextStep: () => void;
  isActivePage: boolean;
  editData?: CustomTourResponse;
  selectedClient: clientObj[];
}

const PER_PAGE = 5;

const AllClient: React.FC<Props> = ({
  selectClient,
  setNextStep,
  isActivePage,
  editData,
  selectedClient,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchResult, setSearchResult] = useState<clientObj[]>();
  const [page, setPage] = useState(1);
  const { data: allClientData, isLoading: allClientLoading } =
    useGetPaginatedAllClients(page, PER_PAGE, isActivePage);

  const { mutate: searchMutate, isLoading: isSearching } = useSearchClient();

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const selectClientData = () => {
    if (!allClientData) return;
    if (editData) {
      return filterClients();
    } else {
      return allClientData.clients;
    }
  };
  const filterClients = () => {
    if (!allClientData || !editData) return;
    const temp = editData.clients.map((ev) => ev.email);
    return allClientData.clients.filter((ev) => !temp.includes(ev.email));
  };

  const getTotalElement = () => {
    if (editData && allClientData) {
      return allClientData.total - editData.clients.length;
    } else {
      return allClientData?.total || 0;
    }
  };

  const onSearchClients = (e: any) => {
    e.preventDefault();
    if (searchText.trim() === "") return setSearchResult(undefined);
    const payload = {
      name: searchText,
    };
    searchMutate(payload, {
      onSuccess: (data) => setSearchResult(data.data.data),
    });
  };

  const searchClient = () => {
    if (!searchResult) return;
    if (editData) {
      const temp = editData.clients.map((ev) => ev.email);
      return searchResult.filter((ev) => !temp.includes(ev.email));
    } else {
      return searchResult;
    }
  };

  useEffect(() => {
    if (isActivePage) {
      setSearchText("");
      setSearchResult(undefined);
    }
  }, [isActivePage]);

  return (
    <Container>
      <>
        <SearchBox onSubmit={onSearchClients}>
          <input
            type="text"
            placeholder="Search client"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
          {isSearching ? (
            <Spinner color="#303030" />
          ) : (
            <SearchIcon onClick={onSearchClients} />
          )}
        </SearchBox>
        <Wrapper>
          <AllClientGrid
            data={searchResult ? searchClient() : selectClientData()}
            isLoading={allClientLoading}
            selectClient={selectClient}
            selectedClient={selectedClient}
          />
        </Wrapper>
        {allClientData && !searchResult && (
          <Paginating
            totalElements={getTotalElement()}
            size={allClientData.per_page}
            pageCount={5}
            currentPage={page}
            onChange={onPageChange}
          />
        )}
        <SubmitBtn onClick={setNextStep}>
          Select and add a client to a site tour
        </SubmitBtn>
      </>
    </Container>
  );
};

export default AllClient;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Wrapper = styled.div`
  overflow: scroll;
  width: 80vh;
`;

const SearchBox = styled.form`
  background-color: #fbfbfb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  border-radius: 20px;
  width: 475px;
  height: 56px;
  margin: auto;

  input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
  }
  & > svg {
    color: #303030;
  }
`;

const SubmitBtn = styled.button`
  width: max-content;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #afd34f;
  border-radius: 8px;
  padding: 12px 50px;
  border: none;
  margin-top: 24px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  line-height: 140%;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #697f2f;
  }
`;

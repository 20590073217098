import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clientDislikeComment, dislikeComment } from "app/api/community";
import { CommentsObj } from "app/api/community/types";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useDislikeComment = () => {
  const queryClient = useQueryClient();

  return useMutation(dislikeComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.getPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                comments: [
                  ...oldQueryData.data.data.comments.map(
                    (comment: CommentsObj) =>
                      comment.id === successData.data.data.id
                        ? successData.data.data
                        : comment
                  ),
                ],
              },
            },
          };
        }
      );
    },
  });
};

export default useDislikeComment;

export const useClientDislikeComment = () => {
  const queryClient = useQueryClient();

  return useMutation(clientDislikeComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.clientGetPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                comments: [
                  ...oldQueryData.data.data.comments.map(
                    (comment: CommentsObj) =>
                      comment.id === successData.data.data.id
                        ? successData.data.data
                        : comment
                  ),
                ],
              },
            },
          };
        }
      );
    },
  });
};

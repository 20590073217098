import React, { useRef } from "react";
import {
  AgGridEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { projectObj } from "app/api/project/types";
import ActionRenderer from "./ActionRenderer";
import MultipleRenderer from "./MultipleRenderer";

interface GridProps {
  data: projectObj[];
}

type ProjectField = keyof projectObj | "action";

export interface GridDef extends ColDef {
  field: ProjectField;
}

export interface GridConfigTypes {
  columnDefs: GridDef[];
  rowData: projectObj[];
}

const ProjectGrid: React.FC<GridProps> = ({ data }) => {
  const gridApi = useRef<GridApi | null>(null);
  const GridData: projectObj[] = data;

  const rowsConfig: React.MutableRefObject<GridDef[]> = useRef<GridDef[]>([
    {
      headerName: "Project Name",
      field: "name",
      sortable: false,
      cellStyle: {
        ...gridCellStyle,
        color: "#809A39",
        textTransform: "capitalize",
      },
      flex: 1,
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "State",
      field: "locations",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        textTransform: "capitalize",
        color: "#28647D",
      },
      cellRendererFramework: MultipleRenderer,
      cellRendererParams: {
        type: "location",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Address",
      sortable: false,
      field: "name",
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        textTransform: "capitalize",
      },
      cellRendererFramework: MultipleRenderer,
      cellRendererParams: {
        type: "address",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      headerName: "Reverted Interest Rate",
      field: "reverted_interest_rate",
      sortable: false,
      flex: 1,
      cellStyle: {
        ...gridCellStyle,
        textTransform: "capitalize",
        color: "#28647D",
      },
      wrapText: true,
      autoHeight: true,
    },
    {
      sortable: false,
      field: "action",
      width: 100,
      cellStyle: {
        ...gridCellStyle,
      },
      cellRendererFramework: ActionRenderer,
      wrapText: true,
      autoHeight: true,
    },
  ]);

  const gridConfig: GridConfigTypes = {
    columnDefs: [...rowsConfig.current],
    rowData: GridData,
  };

  const gridRendered = (e: GridReadyEvent) => {
    gridApi.current = e.api;
  };

  const getRowStyle = (params: any): any => {
    if (params.node.rowIndex % 2 !== 0) {
      return { background: "#E9F6FC" };
    }
  };

  return (
    <>
      <GridWrapper id="OpenOrdersGridWrapper" className="ag-theme-alpine">
        <AgGridReact
          onGridReady={gridRendered}
          animateRows
          headerHeight={50}
          columnDefs={gridConfig.columnDefs}
          rowData={gridConfig.rowData}
          suppressRowTransform
          getRowStyle={getRowStyle}
          defaultColDef={{
            sortable: true,
            filter: false,
            cellStyle: {
              ...gridCellStyle,
            },
          }}
          getRowNodeId={(data: projectObj) => {
            return data.id.toString();
          }}
          gridOptions={{
            onSortChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
            onFilterChanged(e: AgGridEvent) {
              e.api.refreshCells();
            },
          }}
        />
      </GridWrapper>
    </>
  );
};

export default ProjectGrid;

const gridCellStyle = {
  display: "flex",
  "align-items": "center",
  "font-weight": "450",
  "line-height": "140%",
  "font-size": "13px",
  padding: "0 0.5rem",
};

const GridWrapper = styled.div`
  height: 500px;
  width: 100%;
  margin-top: 24px;
  /* overflow-y: scroll;
  overflow-x: scroll; */

  & .ag-center-cols-viewport {
    overflow: visible !important;
    & * {
      overflow: visible !important;
    }
  }
`;

import React, { useRef, useState } from "react";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import { homeFAQData, mainFAQData } from "./data";
import { ReactComponent as MinusSvg } from "../../../images/web/minus.svg";
import { ReactComponent as PlusSvg } from "../../../images/web/plus.svg";
import { ReactComponent as ThreeLineSvg } from "../../../images/web/three-lines.svg";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import {
  cardVariant,
  delayStagger,
  popUp,
  slideIn,
} from "app/utils/animationVariants";
import { useMobileCheck } from "app/hooks/useMobileCheck";
import mediaQueries from "styles/utils/mediaQueries";

interface Props {
  mini?: boolean;
}

const FAQ: React.FC<Props> = ({ mini }) => {
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [accordionId, setAccordionId] = useState<number>();
  const data = mini ? homeFAQData : mainFAQData;
  const isMobile = useMobileCheck();

  return (
    <Wrapper
      viewport={{ once: true, amount: isMobile ? 0.1 : 0.3 }}
      whileInView={"animate"}
      initial={"initial"}
    >
      {/* <LittleTitle variants={slideIn()} custom={2}>
        FAQs
      </LittleTitle> */}
      <TitleWrapper>
        <Title variants={slideIn("right")} custom={2}>
          Frequently asked Questions
        </Title>
        <ThreeLineSvgWrapper variants={popUp} custom={7}>
          <ThreeLineSvg />
        </ThreeLineSvgWrapper>
      </TitleWrapper>

      <SubTitle variants={popUp} custom={4}>
        Got questions? Get answers from the team
      </SubTitle>
      <MiniWrapper
        variants={delayStagger(3)}
        custom={isMobile ? 0.8 : 0.4}
        viewport={{ once: true, amount: isMobile ? 0.1 : 0.3 }}
        whileInView={"animate"}
        initial={"initial"}
      >
        {data.map((item, i) => (
          <AccordionItem
            key={i}
            onClick={() =>
              setAccordionId((prev) => (prev === i ? undefined : i))
            }
            variants={cardVariant}
            custom={0.8}
          >
            <AccordionHeader mini={mini}>
              <h2>{item.title}</h2>
              {i === accordionId ? <MinusSvg /> : <PlusSvg />}
            </AccordionHeader>

            <AccordionBody
              idNo={i}
              accordionId={accordionId}
              ref={ref}
              height={ref.current?.scrollHeight}
            >
              {item.renderContent()}
            </AccordionBody>
          </AccordionItem>
        ))}
      </MiniWrapper>
      {mini && (
        <SeeMoreBtn
          onClick={() => navigate("/FAQs")}
          variants={popUp}
          custom={10}
        >
          See more questions
          <EastIcon />
        </SeeMoreBtn>
      )}
    </Wrapper>
  );
};

export default FAQ;

const Wrapper = styled(motion.div)`
  padding: 40px 256px 20px;

  ${mediaObj.mediumDesktop} {
    padding: 20px 70px;
  }

  ${mediaObj.smallDesktop} {
    padding: 20px 0;
  }

  ${mediaObj.bigMobile} {
    padding: 0 0 20px;
  }
`;
const LittleTitle = styled(motion.div)`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 140%;
  color: #909090;
  text-align: center;

  ${mediaQueries.mobile} {
    font-size: 1.2rem;
  }
`;
const Title = styled(motion.div)`
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 140%;
  color: #071e11;
  margin-top: 4px;
  margin-bottom: 16px;
  text-align: center;

  ${mediaObj.tablet} {
    font-size: 1.9375rem;
  }

  ${mediaQueries.mobile} {
    font-size: 1.8375rem;
  }
`;

const SubTitle = styled(motion.div)`
  font-weight: 450;
  font-size: 1.25rem;
  line-height: 140%;
  color: #535353;
  text-align: center;

  ${mediaQueries.mobile} {
    font-size: 0.9rem;
  }
`;
const TitleWrapper = styled(motion.div)`
  position: relative;
  width: max-content;
  margin: auto;

  ${mediaObj.tablet} {
    width: unset;
    & > svg {
      display: none;
    }
  }
`;

const ThreeLineSvgWrapper = styled(motion.div)`
  position: absolute;
  top: -5px;
  right: -35px;

  ${mediaObj.tablet} {
    display: none;
  }
`;

const MiniWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 40px;
  margin-bottom: 57px;
`;

interface AccordionHeaderProps {
  mini?: boolean;
}

const AccordionHeader = styled.div<AccordionHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  background: #f9fbf3;
  border-radius: 4px;
  cursor: pointer;
  height: ${({ mini }) => (mini ? "60px" : "64px")};
  transition: 0.4s;

  & > h2 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;

    ${mediaQueries.mobile} {
      font-size: 1rem;
    }
  }
`;

interface AccordionBodyProps {
  idNo: number;
  accordionId?: number;
  height?: number;
}

const AccordionBody = styled.div<AccordionBodyProps>`
  font-weight: 450;
  font-size: 1rem;
  line-height: 140%;
  color: #535353;
  overflow: hidden;
  padding: ${({ idNo, accordionId }) =>
    idNo !== accordionId ? "0" : `16px 20px`};
  opacity: ${({ idNo, accordionId }) => (idNo !== accordionId ? "0" : `1`)};
  max-height: ${({ idNo, accordionId, height }) =>
    idNo !== accordionId ? "0" : `${height ? height * 3 : 0}px`};
  transition: max-height 0.2s ease-out;
`;

const AccordionItem = styled(motion.div)``;
const SeeMoreBtn = styled(motion.button)`
  background-color: transparent;
  border: none;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 14px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #afd34f;
  cursor: pointer;
  margin-bottom: 46px;

  & > svg {
    transition: all 0.3s ease;
  }

  &:hover {
    color: #697f2f;

    & > svg {
      transform: translateX(6px);
    }
  }
`;

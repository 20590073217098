import { useQuery } from "@tanstack/react-query";
import { getAgeGroups } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetAgeGroups = () => {
  return useQuery([UtilitiesKeys.getAgeGroups], getAgeGroups, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetAgeGroups;

import styled from "styled-components";
import { mediaObj } from "styles/utils/media";
import mediaQueries from "styles/utils/mediaQueries";

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
`;

export const NoProjectText = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #5e5e5e;
  margin-top: 40px;

  ${mediaObj.mobile} {
    font-size: 1.2rem;
  }
`;

export const CardWrapper = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 330px));
  gap: 24px;

  ${mediaQueries.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
`;

import { Box } from "@mui/material";
import DashboardTotalCard from "app/views/Admin/components/Cards/dashboardTotal";
import { ReactComponent as CommissionIcon } from "app/images/staff-commission.svg";
import { ReactComponent as totalSalesIcon } from "app/images/total-sales.svg";
import { ReactComponent as TotalClientIcon } from "app/images/admin/total-clients.svg";
import RecentClients from "./components/recentClients";
import styled from "styled-components";
import { useAppSelector } from "app/redux";
import { getAuth } from "app/redux/auth/authSlice";
import { UserResponse } from "app/types/common";

const Dashboard = () => {
  const userData = useAppSelector(getAuth).user as UserResponse;

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"24px"}>
      <RatingCard>
        <RatingTxt>Your Rating </RatingTxt>
        <Rating>{`${userData?.rating?.rating || 0}/10`}</Rating>
      </RatingCard>
      <Box display={"flex"} justifyContent={"space-between"}>
        <DashboardTotalCard
          label={"Total Sales"}
          icon={totalSalesIcon}
          labelHeight
          value={userData?.sales?.length || 0}
          height={"145px"}
          width="24%"
          isLoading={false}
        />
        <DashboardTotalCard
          label={"Total Commision Earned Before Tax"}
          icon={CommissionIcon}
          labelHeight
          value={Number(userData?.commission_before_tax) || 0}
          height={"145px"}
          width="24%"
          isLoading={false}
        />
        <DashboardTotalCard
          label={"Total Commision Earned After Tax"}
          icon={CommissionIcon}
          labelHeight
          value={Number(userData?.commission) || 0}
          height={"145px"}
          width="24%"
          isLoading={false}
        />

        <DashboardTotalCard
          label={"Total Existing Clients"}
          icon={TotalClientIcon}
          labelHeight
          value={userData?.clients?.length || 0}
          height={"145px"}
          width="24%"
          isLoading={false}
        />
      </Box>
      <RecentClients />
    </Box>
  );
};

export default Dashboard;

const RatingCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  gap: 40px;
  height: 36px;
  background: #afd34f;
  border-radius: 4px;
  margin-left: auto;
`;

const RatingTxt = styled.p`
  font-weight: 450;
  font-size: 14px;
  color: #fbfbfb;
`;

const Rating = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: #327f9e;
`;

import styled from "styled-components";
import { ReactComponent as ThreeDots } from "app/images/admin/three-dots.svg";
import DetailsModal from "../modals/details";
import { ClickAwayListener } from "@mui/base";
import { useState } from "react";
import Fade from "app/views/Admin/components/fade";
import { Visibility } from "@mui/icons-material";
import { myClientRes } from "app/api/client/types";

const ActionRenderer = ({ data }: { data: myClientRes }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsDropdownOpen(false)}>
      <Container>
        <ThreeDots onClick={() => setIsDropdownOpen(!isDropdownOpen)} />
        <Fade isOpen={isDropdownOpen}>
          <ClientDropdown>
            <DropdownLabel
              onClick={() => {
                setIsDropdownOpen(false);
                setIsDetailsModalOpen(true);
              }}
            >
              <Visibility width={"10px"} /> <span>View Details</span>
            </DropdownLabel>
          </ClientDropdown>
        </Fade>
        <DetailsModal
          data={data}
          isOpen={isDetailsModalOpen}
          handleClose={() => setIsDetailsModalOpen(false)}
        />
      </Container>
    </ClickAwayListener>
  );
};

export default ActionRenderer;

const Container = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 45;
`;

const ClientDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: calc(100% + -10px);
  right: -140px;
  background: #fff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  z-index: 45;
`;

const DropdownLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 9px 12px;
  font-weight: 450;
  font-size: 12px;
  color: #2a3313;
  width: 100%;

  &:hover {
    background: #d5d8d820;
  }

  & > svg {
    width: 16px;
    height: 16px;
  }
`;

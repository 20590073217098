import { useMutation, useQueryClient } from "@tanstack/react-query";
import { adminEditAssessment } from "app/api/staff";
import { parseError } from "app/utils";
import { AssessmentKeys } from "./types";

const useAdminEditAssessment = () => {
  const queryClient = useQueryClient();

  return useMutation(adminEditAssessment, {
    onError: (error) => parseError(error),
    onSuccess: () =>
      queryClient.invalidateQueries([AssessmentKeys.adminGetAllAssessment]),
  });
};

export default useAdminEditAssessment;

import { UserResponse } from "app/types/common";
import NameAvatar from "app/views/Admin/components/nameAvatar";
import styled from "styled-components";

const NameRenderer = ({ data }: { data: UserResponse }) => {
  const { firstname, lastname, profile_photo } = data;
  const fullname = `${firstname} ${lastname}`;

  return (
    <Container>
      {profile_photo ? (
        <Img src={profile_photo.url} />
      ) : (
        <NameAvatar name={fullname} width="32px" height="32px" />
      )}
      <Name>{fullname}</Name>
    </Container>
  );
};

export default NameRenderer;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Img = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  flex-shrink: 0;
`;

const Name = styled.div`
  color: #809a39;
`;

import { Box } from "@mui/material";
import { EditorState, convertToRaw } from "draft-js";
import { FC, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import BodyField from "../../Community/components/BodyField";
import Option from "./option";
import AddIcon from "@mui/icons-material/Add";
import { Form, Formik } from "formik";
import { QuestionValidation } from "../validation";
import { useAppDispatch } from "app/redux";
import { setQuestions } from "app/redux/assessment/assessmentSlice";
import draftToHtml from "draftjs-to-html";
import { ReactComponent as EditIcon } from "app/images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOption from "./modals/editOption";
import DeleteAssessObj from "./modals/deleteAssessObj";
import { toast } from "react-toastify";
import { options, questionObj } from "app/api/staff/types";

interface props {
  index: number;
}

const Question: FC<props> = ({ index }) => {
  const dispatch = useAppDispatch();
  const [formData, setFormData] = useState<questionObj>();
  const [formOptions, setFormOptions] = useState<options[]>();
  const [option, setOption] = useState(EditorState.createEmpty());
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>();
  const [editOption, setEditOption] = useState<options>();
  const [deleteOptionId, setDeleteOptionId] = useState<number>();

  const convertedOptionValue = draftToHtml(
    convertToRaw(option?.getCurrentContent())
  );

  const isEditorFieldValid = (prop?: EditorState) => {
    return (
      prop?.getCurrentContent().hasText() &&
      prop?.getCurrentContent().getPlainText() !== ""
    );
  };

  useEffect(() => {
    const newOptions =
      formOptions && (JSON.parse(JSON.stringify(formOptions)) as options[]);

    if ((selectedOptionIndex || selectedOptionIndex === 0) && newOptions) {
      newOptions.forEach((item) => (item.answer = false));
      const selectedOption = newOptions[selectedOptionIndex];
      selectedOption.answer = true;
      newOptions.splice(selectedOptionIndex, 1, selectedOption);
      setFormOptions(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptionIndex]);

  const resetState = () => {
    setFormData({});
    setFormOptions([]);
    setSelectedOptionIndex(undefined);
  };

  const handleQuestionChange = (value: SetStateAction<EditorState>) => {
    setFormData({ ...formData, question: value as EditorState });
  };

  const handleAddQuestion = () => {
    if (!isEditorFieldValid(formData?.question)) {
      toast.info("Please enter question");
      return;
    }
    if (!formOptions || formOptions?.length === 0) {
      toast.info("Please enter option");
      return;
    }

    if (!formOptions?.some((x) => x.answer === true)) {
      toast.info("Please select a correct answer");
      return;
    }

    const questionInHtml =
      formData?.question &&
      draftToHtml(convertToRaw(formData?.question?.getCurrentContent()));

    dispatch(
      setQuestions({
        ...formData,
        question: questionInHtml,
        options: formOptions,
      })
    );
    resetState();
  };

  const handleAddOptions = () => {
    if (!isEditorFieldValid(formData?.question)) {
      toast.info("Please enter a question before setting options");
      return;
    }

    if (isEditorFieldValid(option)) {
      if (formOptions && formOptions?.length > 0) {
        setFormOptions([
          ...formOptions,
          { value: convertedOptionValue, idx: formOptions?.length },
        ]);
      } else {
        setFormOptions([{ value: convertedOptionValue, idx: 0 }]);
      }
    } else {
      toast.info("Please enter option");
      return;
    }

    setOption(EditorState.createEmpty());
  };

  const handleOptionsEdit = (option: options) => {
    const newOptionsArr = JSON.parse(JSON.stringify(formOptions)) as options[];
    const oldOptionIndex = newOptionsArr?.findIndex((x) =>
      x.id ? x.id === option.id : x.idx === option.idx
    );
    (oldOptionIndex || oldOptionIndex === 0) &&
      oldOptionIndex > -1 &&
      newOptionsArr?.splice(oldOptionIndex, 1, option);
    setFormOptions(newOptionsArr);
  };

  const handleOptionDelete = (id: number) => {
    const newOptionsArr = JSON.parse(JSON.stringify(formOptions)) as options[];
    const oldOptionIndex = newOptionsArr?.findIndex((x) =>
      x.id ? x.id === id : x.idx === id
    );
    (oldOptionIndex || oldOptionIndex === 0) &&
      oldOptionIndex > -1 &&
      newOptionsArr?.splice(oldOptionIndex, 1);
    setFormOptions(newOptionsArr);
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={"24px"} mt={"30px"}>
      <Formik
        initialValues={{
          question: formData?.question,
        }}
        validationSchema={QuestionValidation}
        enableReinitialize
        onSubmit={() => {}}
      >
        <Form>
          <BodyWrapper>
            <BodyField
              name={"question"}
              label={`Question ${index}`}
              value={formData?.question}
              onChange={handleQuestionChange}
              placeholder={"Enter your Question"}
              isRequired
            />
          </BodyWrapper>
          {formOptions && formOptions?.length > 0 && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"10px"}
              mt={"25px"}
            >
              <OptionsTitle>Select Correct Answer belowW</OptionsTitle>
              <OptionEntryWrapper>
                {formOptions?.map(
                  (x, idx) =>
                    x.value && (
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        width={"100%"}
                        key={idx}
                      >
                        <OptionEntry
                          key={idx}
                          onClick={() => setSelectedOptionIndex(idx)}
                          isSelected={idx === selectedOptionIndex}
                        >
                          {`${String.fromCharCode(idx + 65).toLowerCase()}.`}
                          <Entry
                            dangerouslySetInnerHTML={{
                              __html: x?.value,
                            }}
                          />
                        </OptionEntry>
                        <IconWrapper>
                          <EditIcon onClick={() => setEditOption(x)} />
                          <DeleteIcon
                            onClick={() => setDeleteOptionId(x.id || x.idx)}
                          />
                        </IconWrapper>
                      </Box>
                    )
                )}
              </OptionEntryWrapper>
            </Box>
          )}
          <Option
            setBodyState={setOption}
            value={option}
            handleAddOptions={handleAddOptions}
          />

          <AddBtn onClick={handleAddQuestion}>
            <AddIcon /> Add more question
          </AddBtn>
        </Form>
      </Formik>
      {editOption && (
        <EditOption
          handleClose={() => setEditOption(undefined)}
          handleSubmit={handleOptionsEdit}
          isOpen={!!editOption}
          option={editOption}
          width={"50vw"}
        />
      )}
      {(!!deleteOptionId || deleteOptionId === 0) && (
        <DeleteAssessObj
          onDelete={handleOptionDelete}
          handleClose={() => setDeleteOptionId(undefined)}
          isOpen={!!deleteOptionId || deleteOptionId === 0}
          id={deleteOptionId}
        />
      )}
    </Box>
  );
};

export default Question;

const AddBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  font-weight: 500;
  font-size: 17px;
  color: #fbfbfb;
  margin-top: 84px;
  height: 48px;
  background: #49b7e4;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;

  & > svg {
    height: 21px;
    margin-top: -2px;
    width: 21px;
  }
`;

const BodyWrapper = styled.div`
  width: 100%;

  .rdw-editor-main {
    min-height: 91px;
  }
`;

const OptionEntryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const OptionsTitle = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 144%;
  color: #181d0b;
`;

const OptionEntry = styled.div<{ isSelected?: boolean }>`
  display: flex;
  gap: 5px;
  width: fit-content;
  max-width: 80%;
  padding: 8px 12px;
  color: ${({ isSelected }) => (isSelected ? `#fbfbfb` : `#181d0b`)};
  background: ${({ isSelected }) => (isSelected ? `#22a2d6c7` : `#f1f1f1`)};
  cursor: pointer;
  transition: all 0.2s ease-in;
`;

const Entry = styled.span`
  font-weight: 500;
  font-size: 15px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  & > svg:first-of-type {
    fill: #43a047;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  & > svg:last-of-type {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

import { Backdrop } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputField from "../components/formik inputs/InputField";
import { CreateProjectValidation } from "./components/validation";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SelectField from "../components/formik inputs/SelectField";
import { SingleOption } from "app/types/common";
import Spinner from "app/views/Web/components/Spinner";
import { toast } from "react-toastify";
import useGetAllStates from "app/hooks/utilities/useGetAllStates";
import useGetAllCategories from "app/hooks/projects/useGetAllCategories";
import { useNavigate, useSearchParams } from "react-router-dom";
import useGetASingleProject from "app/hooks/projects/useGetASingleProject";
import useCreateProject from "app/hooks/projects/useCreateProject";
import useUpdateProject from "app/hooks/projects/useUpdateProject";

const locationDetails = {
  location: undefined,
  address: "",
  canCancel: true,
};

interface locationDetailsProps {
  location?: SingleOption;
  address: string;
  canCancel: boolean;
}

interface Props {
  open: boolean;
  handleClose: () => void;
}

const CreateProject: React.FC<Props> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("edit");
  const [categoryType, setCategoryType] = useState<SingleOption>();
  const [project, setProject] = useState("");
  const [revertedInterestRate, setRevertedInterestRate] = useState("");
  const [locationArray, setLocationArray] = useState<locationDetailsProps[]>([
    { ...locationDetails },
  ]);

  const { data: allStateData, isLoading: allStateLoading } = useGetAllStates();
  const { data: allCategoryData, isLoading: allCategoryLoading } =
    useGetAllCategories();
  const {
    data: singleProjectData,
    isFetching: singleProjectFetching,
    isError,
  } = useGetASingleProject(Number(editId));
  const { mutate: createMutate, isLoading: isCreating } = useCreateProject();
  const { mutate: updateMutate, isLoading: isUpdating } = useUpdateProject();

  const addMoreLocation = () => {
    setLocationArray((oldArray) => [
      ...oldArray,
      {
        ...locationDetails,
      },
    ]);
  };

  const cancelLocation = (index: number) => {
    setLocationArray([
      ...locationArray.slice(0, index),
      ...locationArray.slice(index + 1),
    ]);
  };

  const formatData = (data: any[] | undefined) => {
    if (!data) return;
    return data.map((ev) => {
      const temp = {
        value: ev.id,
        label: ev.name,
      };
      return temp;
    });
  };

  const onSuccess = () => {
    toast.success(`Project ${editId ? "Updated" : "Created"} Successfully!`);
    setTimeout(() => {
      navigate("/admin/projects");
    }, 1500);
  };

  const onCreateHandler = async () => {
    if (!categoryType) return;

    if (editId) {
      if (!singleProjectData) return;

      const formatLocationArray = locationArray.map((ev) => {
        return {
          location_id: ev.location?.value as number,
          address: ev.address,
        };
      });

      const finalLocationArray = formatLocationArray.map((selectedLocation) => {
        let temp: any = {
          ...selectedLocation,
        };
        singleProjectData.locations.map((location) => {
          if (selectedLocation.location_id === location.location_id) {
            temp = {
              ...selectedLocation,
              project_location_id: location.id,
            };
          }
        });

        return temp;
      });

      const result = {
        name: project,
        category_id: categoryType.value,
        locations: finalLocationArray,
        project_id: singleProjectData.id,
        reverted_interest_rate: +revertedInterestRate,
      };
      updateMutate(result, {
        onSuccess,
      });
    } else {
      const formatLocationArray = locationArray.map((ev) => {
        return {
          location_id: ev.location?.value as number,
          address: ev.address,
        };
      });

      const payload = {
        name: project,
        category_id: categoryType.value,
        locations: formatLocationArray,
        reverted_interest_rate: +revertedInterestRate,
      };

      createMutate(payload, {
        onSuccess,
      });
    }
  };

  useEffect(() => {
    if (editId && singleProjectData) {
      const { name, category, locations, category_id, reverted_interest_rate } =
        singleProjectData;
      const formatedCategory = {
        value: category_id,
        label: category,
      };
      setCategoryType(formatedCategory);
      setProject(name);
      setRevertedInterestRate(reverted_interest_rate.toString());
      const formatedLocation = locations.map((ev) => {
        const temp = {
          value: ev.location_id,
          label: ev.location,
        };
        return {
          location: temp,
          address: ev.address,
          canCancel: false,
        };
      });
      setLocationArray(formatedLocation);
    }
  }, [singleProjectData, editId]);

  return (
    <Backdrop
      sx={{
        color: "#000000",
        opacity: "0.4",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={open}
      onClick={handleClose}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <CancelIcon onClick={handleClose}>
          <HighlightOffIcon />
        </CancelIcon>
        <h6>{editId ? "Edit" : "Create"} Project</h6>
        <Formik
          initialValues={{
            categoryType,
            project,
            revertedInterestRate,
            locationArray,
          }}
          validationSchema={CreateProjectValidation}
          enableReinitialize
          onSubmit={() => {
            onCreateHandler();
          }}
        >
          <Form>
            <FormWrapper>
              {editId && singleProjectFetching && (
                <SpinnerWrapper>
                  <Spinner color="black" />
                </SpinnerWrapper>
              )}
              {editId && isError && !singleProjectFetching && (
                <SpinnerWrapper>
                  <ErrorText>An Error Occured</ErrorText>
                </SpinnerWrapper>
              )}
              {(!editId || (editId && !isError && !singleProjectFetching)) && (
                <>
                  <FlexWrapper>
                    <SelectField
                      name="categoryType"
                      label="Category"
                      value={categoryType as SingleOption}
                      onChange={(e) => setCategoryType(e as SingleOption)}
                      options={formatData(allCategoryData)}
                      selectType="normal"
                      isLoading={allCategoryLoading}
                    />
                    <InputField
                      name="project"
                      label="Project"
                      placeholder="My Country Home"
                      value={project}
                      onChange={(e) => setProject(e.target.value)}
                    />
                  </FlexWrapper>
                  <MiniWrapper>
                    <InputField
                      name="revertedInterestRate"
                      label="Reverted Interest Rate"
                      placeholder="0"
                      value={revertedInterestRate}
                      onChange={(e) => setRevertedInterestRate(e.target.value)}
                    />
                  </MiniWrapper>

                  <LocationWrapper>
                    {locationArray.map((item, i) => (
                      <div key={i}>
                        {i + 1 > 1 && item.canCancel && (
                          <Cancel onClick={() => cancelLocation(i)}>
                            <HighlightOffIcon />
                          </Cancel>
                        )}
                        <FlexWrapper>
                          <SelectField
                            name={`locationArray[${i}].location`}
                            label={`Location ${
                              locationArray.length === 1 ? "" : i + 1
                            }`}
                            value={item.location as SingleOption}
                            onChange={(e) => {
                              item.location = e as SingleOption;
                              setLocationArray([...locationArray]);
                            }}
                            options={formatData(allStateData)}
                            isLoading={allStateLoading}
                            selectType="normal"
                          />
                          <InputField
                            name={`locationArray[${i}].address`}
                            label="Address"
                            placeholder="Address"
                            value={item.address}
                            onChange={(e) => {
                              item.address = e.target.value;
                              setLocationArray([...locationArray]);
                            }}
                          />
                        </FlexWrapper>
                      </div>
                    ))}

                    <button type="button" onClick={addMoreLocation}>
                      Add another location
                    </button>
                  </LocationWrapper>
                  <SubmitBtn type="submit">
                    <>
                      {(isCreating || isUpdating) && <Spinner color="black" />}
                      {editId ? "Update" : "Create"}
                    </>
                  </SubmitBtn>
                </>
              )}
            </FormWrapper>
          </Form>
        </Formik>
      </Container>
    </Backdrop>
  );
};

export default CreateProject;

const Container = styled.div`
  min-width: 540px;
  background: #ffffff;
  padding: 24px;
  padding-bottom: 45px;
  border-radius: 8px;
  position: relative;

  & > h6 {
    font-weight: 450;
    font-size: 1.25rem;
    line-height: 140%;
    color: #181d0b;
    margin-bottom: 39px;
  }
`;

const CancelIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: -15px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
    fill: #b3261e;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

const MiniWrapper = styled.div`
  margin-top: 24px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow-y: scroll;
  padding: 0 1rem;
`;

const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 1rem;
  background: #afd34f;
  border-radius: 8px;
  padding: 12px 180px;
  border: none;
  margin-top: 40px;
  color: white;
  align-self: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
`;

const Cancel = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  right: -15px;
  top: 5px;
  background: #fbfbfb;
  cursor: pointer;
  svg {
    width: 22px;
    height: 22px;
    fill: #b3261e;
  }
`;

const LocationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;

  & > div {
    position: relative;
  }

  & > button {
    font-weight: 500;
    font-size: 1rem;
    line-height: 140%;
    color: #afd34f;
    width: max-content;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: #697f2f;
      transition: all 0.3s ease-in-out;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorText = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #5e5e5e;
`;

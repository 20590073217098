import * as Yup from "yup";
import dayjs from "dayjs";

export const CreateNewsValidation = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string()
    .required("Required")
    .max(400, "Too Long! 500 max characters"),
  coverImageId: Yup.string().required("Required"),
});

export const CreateOfferValidation = Yup.object({
  categoryType: Yup.object().required("This field is required"),
  projectType: Yup.object().required("This field is required"),
  location: Yup.object().required("This field is required"),
  packages: Yup.object().required("This field is required"),
  squaremeter: Yup.object().required("This field is required"),
  oldPrice: Yup.number()
    .positive()
    .required("Required")
    .min(1)
    .label("old price"),
  promoPrice: Yup.number()
    .positive()
    .required("Required")
    .min(1)
    .label("promo price")
    .when("oldPrice", (oldPrice: any, schema: any) => {
      return schema.test({
        test: (promoPrice: any) => {
          if (!promoPrice) return true;
          return promoPrice > oldPrice;
        },
        message: "Promo Price should be greater than Old Price",
      });
    }),
  promoStartDate: Yup.date().required("Required").nullable(),
  promoEndDate: Yup.date()
    .nullable()
    .required("Required")
    .when("promoStartDate", (promoStartDate, schema) => {
      if (promoStartDate) {
        const dayAfter = new Date(promoStartDate.getTime() + 86400000);
        return schema.min(dayAfter, "End date has to be after than start date");
      }
      return schema;
    }),
  offerDescription: Yup.string()
    .required("Required")
    .max(400, "Too Long! 400 max characters"),
});

export const CreateEventValidation = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string()
    .required("Required")
    .max(400, "Too Long! 400 max characters"),
  location: Yup.object().required("This field is required"),
  address: Yup.string().required("Required"),
  coverImageId: Yup.string().required("Required"),
  eventStartDate: Yup.date().required("Required").nullable(),
  eventEndDate: Yup.date()
    .required("Required")
    .nullable()
    .test(
      "min_end_date",
      "Event end date must not be behind event start date",
      function (value) {
        const { eventStartDate } = this.parent;
        return dayjs(value).isSame(dayjs(eventStartDate))
          ? true
          : dayjs(value).isAfter(dayjs(eventStartDate));
      }
    ),
  eventStartTime: Yup.string().required("Required").nullable(),
  eventEndTime: Yup.string()
    .required("Required")
    .nullable()
    .test(
      "min_end_time",
      "Event end time can't be the same or behind event start time",
      function (value) {
        const { eventEndDate, eventStartDate, eventStartTime } = this.parent;
        if (
          dayjs(eventStartDate).format("ddd MMM DD YYYY") ===
          dayjs(eventEndDate).format("ddd MMM DD YYYY")
        ) {
          return dayjs(value).isAfter(dayjs(eventStartTime));
        } else {
          return true;
        }
      }
    ),
});

export const CreateForumValidation = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string()
    .required("Required")
    .max(400, "Too Long! 400 max characters"),
  forumArray: Yup.array().of(Yup.string().min(1).required("Required")),
  coverImageId: Yup.string().required("Required"),
  forumStartDate: Yup.date().required("Required").nullable(),
  forumStartTime: Yup.string().required("Required").nullable(),
});

export const CreateBlogValidation = Yup.object({
  title: Yup.string().required("Required"),
  tags: Yup.array().min(1, "at least 1").required("This field is required"),
  bodyState: Yup.object()
    .test("has text", "Cannot save an empty body", (value: any) => {
      const contentState = value.getCurrentContent();
      return (
        contentState.hasText() && contentState.getPlainText().trim() !== ""
      );
    })
    .required("This field is required.")
    .nullable(),
  coverImageId: Yup.string().required("Required"),
});

export const DialogModalValidation = Yup.object({
  reasonText: Yup.string().required("Required"),
});

export const MakeOfferValidation = Yup.object({
  amount: Yup.number().positive().required("Required").min(1).label("amount"),
});

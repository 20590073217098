import { FormikProps } from "formik";
import React, { FC } from "react";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";
import { mediaObj } from "styles/utils/media";

interface Props {
  formik?: FormikProps<any>;
  label?: string;
  placeholder: string;
  name: string;
  onChange?: (value: string) => void;
  noLabel?: boolean;
}

const NumberFormatter: FC<Props> = ({
  formik,
  label,
  name,
  placeholder,
  onChange,
  noLabel,
}) => {
  return (
    <Formatter>
      <Label
        htmlFor={name}
        className={`${
          formik?.getFieldMeta(name).touched &&
          formik?.getFieldMeta(name).error &&
          "error"
        }`}
        noLabel={noLabel}
      >
        {label}
      </Label>
      <NumericFormat
        name={name}
        placeholder={placeholder}
        className={`${
          formik?.getFieldMeta(name).touched &&
          formik?.getFieldMeta(name).error &&
          "error"
        }`}
        thousandSeparator=","
        onValueChange={(values) => {
          formik?.getFieldHelpers(name).setValue(values.value);
          onChange && onChange(values.value);
        }}
      />
      {formik?.getFieldMeta(name).touched &&
      formik?.getFieldMeta(name).error ? (
        <Error>{formik?.getFieldMeta(name).error}</Error>
      ) : null}
    </Formatter>
  );
};

export default NumberFormatter;

const Formatter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 0.875rem;
  }

  & > input {
    background: #ffffff;
    border: 1px solid rgba(27, 9, 44, 0.5);
    border-radius: 3px;
    height: 3.125rem;
    padding: 1rem;

    :focus-visible {
      outline: none;
    }

    :disabled {
      background: rgba(27, 9, 44, 0.05);
    }

    &.error {
      border: 1px solid red;
    }
  }
`;

interface LabelProps {
  noLabel?: boolean;
}

const Label = styled.label<LabelProps>`
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.125rem;
  color: #1b092c;
  display: ${({ noLabel }) => noLabel && "none"};

  &:after {
    content: "*";
    font-size: 24px;
    line-height: 0;
    vertical-align: middle;
    color: #d90b2c;
  }

  ${mediaObj.mobile} {
    font-size: 14px;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 0.875rem;
`;

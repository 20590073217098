import * as Yup from "yup";

export const CreateBankAccountValidation = Yup.object({
  accountTitle: Yup.string().required("Required"),
  accountNo: Yup.string().required("Required"),
  bankName: Yup.string().required("Required"),
});

export const OfflineDialogModalValidation = Yup.object({
  reasonText: Yup.string().required("Required"),
});

export const UplaodDoaValidation = Yup.object({
  mainFileId: Yup.string().required("Required"),
});

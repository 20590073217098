import { Box } from "@mui/material";
import { clientPackages } from "app/api/client/types";
import { FC } from "react";
import styled from "styled-components";

interface props {
  data: clientPackages;
  count: number;
  itemIndex: number;
}

const PackageDetailsCard: FC<props> = ({ data, count, itemIndex }) => {
  return (
    <Container>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Header>Package Details</Header>
        <Count>
          {itemIndex} of {count}
        </Count>
      </Box>
      <Box display={"flex"} gap={1.2}>
        <Box display={"flex"} flexDirection={"column"} gap={0.4}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={1}
            bgcolor={"#CDE6F5"}
            borderRadius={"4px"}
            paddingY={1}
            paddingX={1.4}
            width={"fit-content"}
          >
            <Label>Project Type:</Label>
            <Value color={"#2a3313"}>{data.category}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Location:</Label>
            <Value>{data.location.location}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Package:</Label>
            <Value color={"#809a39"}>{data.package}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Squaremeter:</Label>
            <Value>{data.size}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Amount Remaining:</Label>
            <Value color={"#b3261e"}>
              {Number(data.price) - Number(data.amount_payed)}
            </Value>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={0.4}>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Project:</Label>
            <Value>{data.project}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Address:</Label>
            <Value>{data.location.address}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Payment Structure:</Label>
            <Value color={"#327F9E"}>{data.payment_structure}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Amount Paid:</Label>
            <Value color={"#809a39"}>{data.amount_payed}</Value>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={1} height={"32px"}>
            <Label>Payment Due Date:</Label>
            <Value>{data.payment_due_date}</Value>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PackageDetailsCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 20px 15px;
  gap: 24px;
  flex-shrink: 0;
  min-width: 480px;
  max-width: 100%;
  min-height: 240px;
  background: #f4f9ec;
  border-radius: 8px;
`;

const Header = styled.h2`
  font-weight: 500;
  font-size: 20px;
  color: #2a3313;
`;

const Count = styled.p`
  font-weight: 450;
  font-size: 13px;
  color: #464646;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #2a3313;
`;

interface valueProps {
  color?: string;
}

const Value = styled(Label)<valueProps>`
  color: ${({ color }) => (color ? color : "#777777")};
  font-size: 12px;
  text-transform: capitalize;
`;

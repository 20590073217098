import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteForum } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useDeleteForum = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteForum, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetForums]);
    },
  });
};

export default useDeleteForum;

import {
  cardTextAnimate,
  popUp,
  staggerContainer,
} from "app/utils/animationVariants";
import { motion } from "framer-motion";
import styled from "styled-components";
import mediaQueries from "../../../../../styles/utils/mediaQueries";

interface props {
  image: string;
  name: string;
  position: string;
}

const TeamCard: React.FC<props> = ({ image, name, position }) => {
  return (
    <Container
      variants={staggerContainer}
      initial="initial"
      whileInView="animate"
      viewport={{ amount: 0.4, once: true }}
    >
      <TeamImg src={image} alt="" variants={popUp} />
      <TeamName variants={cardTextAnimate}>{name}</TeamName>
      <TeamPosition variants={cardTextAnimate}>{position}</TeamPosition>
    </Container>
  );
};

export default TeamCard;

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const TeamImg = styled(motion.img)`
  width: 205px;
  height: 205px;
  border-radius: 8px;
  object-fit: cover;

  ${mediaQueries.mobile} {
    width: 180px;
    height: 170px;
  }
`;

const TeamName = styled(motion.p)`
  width: 90%;
  font-weight: 700;
  font-size: 1.15rem;
  line-height: 140%;
  color: #000000;
  margin-top: 12px;
  text-align: center;

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

const TeamPosition = styled(motion.p)`
  width: 90%;
  font-weight: 450;
  font-size: 0.875rem;
  line-height: 140%;
  color: #000000;
  text-align: center;

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

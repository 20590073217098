import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateNews } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useUpdateNews = () => {
  const queryClient = useQueryClient();

  return useMutation(updateNews, {
    onError: (error) => parseError(error),
    onSuccess: () => {
      queryClient.invalidateQueries([CommunityQueryKeys.adminGetNews]);
    },
  });
};

export default useUpdateNews;

import { useQuery } from "@tanstack/react-query";
import { getPackageAmount } from "app/api/payment";
import { parseError } from "app/utils";
import { PaymentQueryKeys } from "./types";

const useGetPackageAmount = (
  packageItemId: number,
  isEnabled: boolean,
  plotUnit?: number
) => {
  return useQuery(
    [PaymentQueryKeys.getPackageAmount, packageItemId],
    () => getPackageAmount(packageItemId, plotUnit),
    {
      enabled: !!packageItemId && !!isEnabled,
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetPackageAmount;

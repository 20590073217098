import { useMutation } from "@tanstack/react-query";
import {
  adminSendPasswordResetLink,
  clientSendPasswordResetLink,
} from "app/api/auth";
import { parseError } from "app/utils";

const useAdminSendPasswordResetLink = () => {
  return useMutation(adminSendPasswordResetLink, {
    onError: (error) => parseError(error),
  });
};

export default useAdminSendPasswordResetLink;

export const useClientSendPasswordResetLink = () => {
  return useMutation(clientSendPasswordResetLink, {
    onError: (error) => parseError(error),
  });
};

import React, { useState } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as EditIcon } from "../../../images/admin/edit-icon.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import Spinner from "app/views/Web/components/Spinner";

interface Props {
  editHandler?: () => void;
  deleteHandler?: () => void;
  isDeleting?: boolean;
  position?: "horizontal";
  marginRight?: string;
  width?: string;
  noEdit?: boolean;
  deleteText?: string;
  editText?: string;
}

const Actions: React.FC<Props> = ({
  editHandler,
  deleteHandler,
  isDeleting,
  position,
  marginRight,
  width,
  noEdit,
  deleteText,
  editText,
}) => {
  const [openActions, setOpenActions] = useState(false);

  const onEdit = () => {
    editHandler && editHandler();
    setOpenActions(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpenActions(false)}>
      <ActionWrapper
        openActions={openActions}
        position={position}
        marginRight={marginRight}
      >
        <MoreVertIcon
          onClick={() => {
            setOpenActions((prev) => !prev);
          }}
        />
        {openActions && (
          <ActionBox width={width}>
            <ActionItem onClick={onEdit} noEdit={noEdit}>
              <EditIcon /> <span>{editText || "Edit"}</span>
            </ActionItem>
            <HR noEdit={noEdit} />
            <ActionItem isDelete onClick={deleteHandler}>
              {isDeleting ? (
                <Spinner color="#B3261E" width="16px" height="16px" />
              ) : (
                <DeleteIcon />
              )}{" "}
              <span>{deleteText || "Delete"}</span>
            </ActionItem>
          </ActionBox>
        )}
      </ActionWrapper>
    </ClickAwayListener>
  );
};

export default Actions;

interface ActionWrapperProps {
  openActions: boolean;
  position?: "horizontal";
  marginRight?: string;
}

const ActionWrapper = styled.div<ActionWrapperProps>`
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "16px")};
  position: relative;
  z-index: ${({ openActions }) => (openActions ? 99 : "unset")};

  & > svg {
    cursor: pointer;
    transform: ${({ position }) =>
      position === "horizontal" ? "rotate(90deg)" : "none"};
  }
`;

interface ActionBoxProps {
  width?: string;
}

const ActionBox = styled.div<ActionBoxProps>`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.06), 0px 8px 48px #eeeeee;
  border-radius: 8px;
  width: ${({ width }) => width && width};
`;

interface HRProps {
  noEdit?: boolean;
}

const HR = styled.div<HRProps>`
  height: 1px;
  background-color: #ababab;
  display: ${({ noEdit }) => noEdit && "none"};
`;

interface ActionItemProps {
  isDelete?: boolean;
  noEdit?: boolean;
}

const ActionItem = styled.div<ActionItemProps>`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 13px 16px;
  cursor: pointer;
  display: ${({ noEdit }) => noEdit && "none"};

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ isDelete }) => (isDelete ? "#B3261E " : "#2A3313")};
  }

  span {
    font-weight: 450;
    font-size: 13px;
    line-height: 140%;
    color: ${({ isDelete }) => (isDelete ? "#B3261E " : "#2A3313")};
  }
  &:hover {
    background-color: #fbfbfb;
  }
`;

import { combineReducers } from "redux";
import auth from "./auth/authSlice";
import packages from "./packages/packageSlice";
import assessment from "./assessment/assessmentSlice";
import dashboard from "./dashboard/dashboardSlice";
import home from "./home/HomeSlice";

const RootReducer = combineReducers({
  auth,
  packages,
  assessment,
  dashboard,
  home,
});

export default RootReducer;

import { useEffect, useState } from "react";
import styled from "styled-components";
import DropDown from "./dropDown";
import {
  MonthRange,
  projectMonthDropDownOptions,
  yearDropDownOptions,
} from "../Dashboard/data";
import ApexChart from "./apexChart";
import useAdminGetProjSales from "app/hooks/sales/useAdminGetProjSales";
import { toast } from "react-toastify";

interface Props {
  applyShadow?: boolean;
}

const ProjectSales: React.FC<Props> = ({ applyShadow }) => {
  const [yearValue, setYearValue] = useState<number>(new Date().getFullYear());
  const [monthRange, setMonthRange] = useState<MonthRange>(
    MonthRange.FirstQuarter
  );
  const { data, error, isIdle, mutate } = useAdminGetProjSales();

  useEffect(() => {
    if (yearValue && monthRange) {
      const { 0: start, 1: end } = monthRange?.toString().split(".");
      mutate({
        end: Number(end),
        start: Number(start),
        year: Number(yearValue),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearValue, monthRange]);

  useEffect(() => {
    error && toast.error("Failed to fetch project sales data");
  }, [error]);

  const handleYearChange = (value: number) => {
    setYearValue(value);
  };

  const handleMonthChange = (value: MonthRange) => {
    setMonthRange(value);
  };

  return (
    <Container applyShadow={applyShadow}>
      <TopWrapper>
        <Label>Sold project chart</Label>
        <DropdownContainer>
          <DropDown
            dropDownOptions={yearDropDownOptions}
            handleChange={handleYearChange}
            height="28px"
          />
          <DropDown
            dropDownOptions={projectMonthDropDownOptions}
            handleChange={handleMonthChange}
            bgColor={"#3E4B1C"}
            height="28px"
          />
        </DropdownContainer>
      </TopWrapper>
      <ApexChart
        data={data?.data.data || []}
        monthRange={monthRange}
        isLoading={isIdle}
      />
    </Container>
  );
};

export default ProjectSales;

interface ContainerProps {
  applyShadow?: boolean;
}

const Container = styled.div<ContainerProps>`
  width: 60%;
  height: 380px;
  padding: 20px 15px 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: ${({ applyShadow }) =>
    applyShadow &&
    "0px 0px 0.849738px rgba(66, 71, 76, 0.32), 0px 3.39895px 6.79791px rgba(66, 71, 76, 0.06), 0px 6.79791px 40.7874px #EEEEEE"};
`;

const TopWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  z-index: 13;
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #181d0b;
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { clientCreateComment, createComment } from "app/api/community";
import { parseError } from "app/utils";
import { CommunityQueryKeys } from "./types";

const useCreateComment = () => {
  const queryClient = useQueryClient();

  return useMutation(createComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.getPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                commentsCount: oldQueryData.data.data.commentsCount + 1,
                comments: [
                  ...oldQueryData.data.data.comments,
                  successData.data.data,
                ],
              },
            },
          };
        }
      );
    },
  });
};

export default useCreateComment;

export const useClientCreateComment = () => {
  const queryClient = useQueryClient();

  return useMutation(clientCreateComment, {
    onError: (error) => parseError(error),
    onSuccess: (successData) => {
      queryClient.setQueriesData(
        [CommunityQueryKeys.clientGetPostByTitle],
        (oldQueryData: any) => {
          return {
            ...oldQueryData,
            data: {
              ...oldQueryData.data,
              data: {
                ...oldQueryData.data.data,
                commentsCount: oldQueryData.data.data.commentsCount + 1,
                comments: [
                  ...oldQueryData.data.data.comments,
                  successData.data.data,
                ],
              },
            },
          };
        }
      );
    },
  });
};

import { useEffect } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import { settingsRoute } from "./data";
import { SettingsTabEnum } from "app/types/common";
import UserProfile from "./UserProfile";
import Security from "./Security";
import { AnimatePresence } from "framer-motion";
import BankDetails from "./BankDetails";

const Settings = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const searchParam = searchParams.get("activeTab");

  useEffect(() => {
    !searchParam && setSearchParams({ activeTab: SettingsTabEnum.UserProfile });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (id: SettingsTabEnum) => {
    setSearchParams({ activeTab: id.toString() });
  };

  const getActive = (link: string) => {
    return searchParam === link;
  };

  return (
    <Container>
      <SideNav>
        <h6>Settings</h6>
        <NavWrapper>
          {settingsRoute.map((item, idx) => (
            <LinkWrapper
              key={idx}
              isActive={getActive(item.link)}
              onClick={() => handleTabChange(item.link)}
            >
              {item.title}
            </LinkWrapper>
          ))}
        </NavWrapper>
      </SideNav>
      <AnimatePresence exitBeforeEnter>
        <Content>
          {searchParam === SettingsTabEnum.UserProfile && <UserProfile />}
          {searchParam === SettingsTabEnum.Security && <Security />}
          {searchParam === SettingsTabEnum.BankDetails && <BankDetails />}
        </Content>
      </AnimatePresence>
    </Container>
  );
};

export default Settings;

const Container = styled.div`
  display: flex;
  gap: 15px;
  min-height: 80vh;
`;

const SideNav = styled.div`
  padding: 48px 16px;
  background-color: white;
  min-width: 190px;
  & > h6 {
    font-weight: 450;
    font-size: 1rem;
    line-height: 140%;
    color: #2a3313;
    margin-bottom: 48px;
  }
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
`;

interface LinkWrapperProps {
  isActive: boolean;
}

const LinkWrapper = styled.div<LinkWrapperProps>`
  font-weight: 500;
  font-size: 1rem;
  color: ${({ isActive }) => (isActive ? "#97B644" : "#3e4b1c")};
  border-left: ${({ isActive }) => (isActive ? "5px solid #97B644" : "none")};
  padding: 5px 20px;
  cursor: pointer;
  transition: all 0.3s ease-out;

  &:hover {
    color: #97b644;
  }
`;

const Content = styled.div`
  background-color: white;
  padding: 24px;
  flex: 1;
`;

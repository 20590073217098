import { useQuery } from "@tanstack/react-query";
import { getTotalActiveProject } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetTotalActiveProject = () => {
  return useQuery(
    [UtilitiesKeys.getTotalActiveProject],
    getTotalActiveProject,
    {
      onError: (error) => parseError(error),
      select: (data) => data.data.data,
    }
  );
};

export default useGetTotalActiveProject;

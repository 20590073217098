import React from "react";
import DynamicRenderer from "./DynamicRenderer";

interface Props {
  data: any;
  handleOpenDetailsModal: () => void;
  onApprove: () => void;
  onDecline: () => void;
  handleOpenTransferModal: () => void;
}

const SalesRenderer: React.FC<Props> = ({
  data,
  handleOpenDetailsModal,
  onApprove,
  onDecline,
  handleOpenTransferModal,
}) => {
  const offlineObj = [
    {
      name: "View",
      onClick: handleOpenDetailsModal,
    },
    {
      name: "Approve",
      nameColor: "#43A047",
      onClick: onApprove,
    },
    {
      name: "Decline",
      nameColor: "#DD1717",
      onClick: onDecline,
    },
  ];

  const onlineObj = [
    {
      name: "View",
      onClick: handleOpenDetailsModal,
    },
    {
      name: "Transfer Property",
      onClick: handleOpenTransferModal,
    },
  ];

  return (
    <>
      {data.confirmed === "approved" ? (
        <DynamicRenderer data={data} dataArray={onlineObj} />
      ) : (
        <DynamicRenderer data={data} dataArray={offlineObj} />
      )}
    </>
  );
};

export default SalesRenderer;

import styled from "styled-components";
import { FC } from "react";
import { mediaObj } from "styles/utils/media";
import YouTube from "react-youtube";

interface props {
  handleNext: () => void;
}

const StartSession: FC<props> = ({ handleNext }) => {
  const videoUrl = "https://www.youtube.com/watch?v=fISIKb-Xl48";
  const title = "STEPS ON HOW TO JOIN OUR TEAM BY HUMAN RESOURCES";
  return (
    <Wrapper>
      <Title>Application Phases</Title>
      <VideoBox videoId={videoUrl.split("=")[1]} title={title} />
      <StartBtn onClick={handleNext}>Apply here</StartBtn>
    </Wrapper>
  );
};

export default StartSession;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  ${mediaObj.mobile} {
    gap: 20px;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  color: #303030;
`;

const StartBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 10px auto 0;
  width: 175px;
  height: 50px;
  background: #afd34f;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #697f2f;
  }
`;

const VideoBox = styled(YouTube)`
  width: 100%;
  height: 350px;
  border-radius: 8px;

  & > iframe {
    width: 100%;
    height: 100%;
  }
`;

import { useQuery } from "@tanstack/react-query";
import { getAllStates } from "app/api/utilities";
import { parseError } from "app/utils";
import { UtilitiesKeys } from "./types";

const useGetAllStates = () => {
  return useQuery([UtilitiesKeys.getAllStates], getAllStates, {
    onError: (error) => parseError(error),
    select: (data) => data.data.data,
  });
};

export default useGetAllStates;
